import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";

/**
 * A module for the ViewDatasetLicenseModal Component
 * @module components/datasets/ViewDatasetLicenseModal
 */

/**
 * Displaying  modal for viewing the details of the dataset creator
 * @method ViewDatasetLicenseModal
 *
 * @return {JSX.Element}
 *
 */
const ViewDatasetLicenseModal = (props) => {
  const [datasetLicense, setDatasetLicense] = useState("");
  const [datasetLicenseContent, setDatasetLicenseContent] = useState("");

  useEffect(() => {
    if (props) {
      setDatasetLicense(props?.datasetLicense);
      setDatasetLicenseContent(props?.datasetLicenseContent);
    }
  }, [props]);

  useEffect(() => {
    if (!props.showViewDatasetLicenseModal) {
      setDatasetLicense("");
      setDatasetLicenseContent("");
    }
  }, [props.showViewDatasetLicenseModal]);

  return (
    <>
      <Modal
        show={props.showViewDatasetLicenseModal}
        onHide={props.handleViewDatasetLicenseModalClose}
        dialogClassName="modal-45w"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="bold ms-3">{datasetLicense}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-license">
          <p
            dangerouslySetInnerHTML={{
              __html:
                datasetLicenseContent === "<p><br></p>" ||
                !datasetLicenseContent
                  ? "--"
                  : datasetLicenseContent,
            }}
          ></p>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewDatasetLicenseModal;
