import { Modal, Button, Spinner, Offcanvas } from "react-bootstrap";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { removeMember } from "actions/group";
import { useMediaQuery } from "react-responsive";

/**
 * A module for the RemoveMemberModal Component
 * @module components/layout/RemoveMemberModal
 */

/**
 * Displaying menu in the left side of the system
 * @method RemoveMemberModal
 *
 * @return {JSX.Element}
 *
 */
const RemoveMemberModal = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (e) => {
    setLoading(true);
    dispatch(removeMember(props?.groupID, props?.memberEmail))
      .then((status) => {
        props.setToastStatus("error");
        props.setToastImage("/images/remove-member-success.svg");
        props.setRefreshCurrentGroupCount(props.refreshCurrentGroupCount + 1);
      })
      .catch((status) => {
        if (status === "notif-in-app-and-email-network unreachable") {
          console.log("notif-in-app-and-email-network unreachable");
          props.setToastStatus("success");
          props.setToastImage("/images/add-member-success.svg");
          props.setRefreshCurrentGroupCount(props.refreshCurrentGroupCount + 1);
        } else {
          props.setToastStatus(status);
          props.setToastImage(null);
        }
      })
      .finally(() => {
        props.setShowToast(true);
        setLoading(false);
        props.handleRemoveMemberModalClose();
      });
  };

  return (
    <>
      <Modal
        show={props.showRemoveMemberModal && !isMobile}
        onHide={props.handleRemoveMemberModalClose}
        dialogClassName={`modal-32w remove-modal ${
          loading ? "cursor-not-allowed" : ""
        }`}
        centered
        backdrop="static"
      >
        <Modal.Body>
          <p className="bold">
            Remove "{props?.memberName}" from "{props?.groupName}"?
          </p>
          <p>
            The user will no longer have access to modify the models that are
            linked to the group.
          </p>
        </Modal.Body>
        <Modal.Footer
          className={`d-flex justify-content-between ${
            loading ? "pointer-events-none" : ""
          }`}
        >
          <Button
            variant="outline-light"
            onClick={props.handleRemoveMemberModalClose}
            className="light-btn"
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleFormSubmit}
            className="submit-btn"
          >
            Remove
            {loading && (
              <Spinner
                className="spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props.showRemoveMemberModal && isMobile}
        onHide={props.handleRemoveMemberModalClose}
        placement="bottom"
        className="offcanvas-delete-profile"
        id="confirmation-message-profile"
        data-cy="confirmation-message-profile"
      >
        <Offcanvas.Body>
          <p className="bold">
            Remove "{props?.memberName}" from "{props?.groupName}"?
          </p>
          <p>
            The user will no longer have access to modify the models that are
            linked to the group.
          </p>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleRemoveMemberModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleFormSubmit}
              className="submit-btn"
              disabled={loading}
              id="remove-member-btn"
              name="remove-member-btn"
              data-cy="remove-member-btn"
            >
              Remove
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default RemoveMemberModal;
