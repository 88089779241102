import { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";

/**
 * A module for Datasets component
 * @module components/models/sections/Datasets
 */

/**
 * Datasets component
 * @method Datasets
 *
 * @return {JSX.Element}
 *
 */
const Datasets = (props) => {
  const onChangeModelTrainingDataset = (e) => {
    const value = e.target.value;
    props?.setModelTrainingDataset(value);
    props?.validate("modelTrainingDataset", value);
  };

  const onChangeModelTrainingMotivation = (e) => {
    const value = e.target.value;
    props?.setModelTrainingMotivation(value);
    props?.validate("modelTrainingMotivation", value);
  };

  const onChangeModelTrainingPreprocessing = (e) => {
    const value = e.target.value;
    props?.setModelTrainingPreprocessing(value);
    props?.validate("modelTrainingPreprocessing", value);
  };

  const onChangeModelEvaluationDataset = (e) => {
    const value = e.target.value;
    props?.setModelEvaluationDataset(value);
    props?.validate("modelEvaluationDataset", value);
  };

  const onChangeModelEvaluationMotivation = (e) => {
    const value = e.target.value;
    props?.setModelEvaluationMotivation(value);
    props?.validate("modelEvaluationMotivation", value);
  };

  const onChangeModelEvaluationPreprocessing = (e) => {
    const value = e.target.value;
    props?.setModelEvaluationPreprocessing(value);
    props?.validate("modelEvaluationPreprocessing", value);
  };

  return (
    <Container className="mw-65 ms-0">
      <p className="bold m-0">1. Evaluation Datasets</p>
      <p>
        Evaluation datasets should include datasets that are publicly available
        for third-party use. These could be existing datasets or new ones
        provided alongside the model card analyses to enable further
        benchmarking.
      </p>
      <p>
        To ensure that model cards are statistically accurate and verifiable the
        evaluation datasets should not only be representative of the model's
        typical use cases but also anticipated test scenarios and challenging
        cases.
      </p>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label className="required-field m-0">i. Dataset</Form.Label>
          <Form.Control
            type="text"
            value={props?.modelEvaluationDataset}
            onChange={onChangeModelEvaluationDataset}
            className={`input-field ${
              props?.isValid?.modelEvaluationDataset
                ? "is-valid"
                : props?.isValid.modelEvaluationDataset !== undefined
                ? "is-invalid"
                : ""
            }`}
            name="eval-dataset"
            id="eval-dataset"
            data-cy="eval-dataset"
          />
          {props?.getFormErrorMessage("modelEvaluationDataset")}
          <span className="sub-note">
            What datasets were used to evaluate the model?
          </span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="required-field m-0">ii. Motivation</Form.Label>
          <Form.Control
            type="text"
            value={props?.modelEvaluationMotivation}
            onChange={onChangeModelEvaluationMotivation}
            className={`input-field ${
              props?.isValid?.modelEvaluationMotivation
                ? "is-valid"
                : props?.isValid.modelEvaluationMotivation !== undefined
                ? "is-invalid"
                : ""
            }`}
            name="eval-motivation"
            id="eval-motivation"
            data-cy="eval-motivation"
          />
          {props?.getFormErrorMessage("modelEvaluationMotivation")}
          <span className="sub-note">
            Why were these datasets chosen for the evaluation of the model?
          </span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="required-field m-0">
            iii. Preprocessing
          </Form.Label>
          <Form.Control
            type="text"
            value={props?.modelEvaluationPreprocessing}
            onChange={onChangeModelEvaluationPreprocessing}
            className={`input-field ${
              props?.isValid?.modelEvaluationPreprocessing
                ? "is-valid"
                : props?.isValid.modelEvaluationPreprocessing !== undefined
                ? "is-invalid"
                : ""
            }`}
            name="eval-preprocessing"
            id="eval-preprocessing"
            data-cy="eval-preprocessing"
          />
          {props?.getFormErrorMessage("modelEvaluationPreprocessing")}
          <span className="sub-note">
            How were these datasets preprocessed in preparation for the
            evaluation of the model?
          </span>
        </Form.Group>
        <p className="bold m-0">2. Training Datasets</p>
        <p>
          May not be possible to provide in practice. When possible, this
          section should mirror Evaluation Dataset. If such detail is not
          possible, minimal allowable information should be provided here, such
          as details of the distribution over various factors in the training
          datasets.
        </p>
        <Form.Group className="mb-3">
          <Form.Label className="required-field m-0">i. Dataset</Form.Label>
          <Form.Control
            type="text"
            value={props?.modelTrainingDataset}
            onChange={onChangeModelTrainingDataset}
            className={`input-field ${
              props?.isValid?.modelTrainingDataset
                ? "is-valid"
                : props?.isValid.modelTrainingDataset !== undefined
                ? "is-invalid"
                : ""
            }`}
            name="training-dataset"
            id="training-dataset"
            data-cy="training-dataset"
          />
          {props?.getFormErrorMessage("modelTrainingDataset")}
          <span className="sub-note">
            What datasets were used to train the model?
          </span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="required-field m-0">ii. Motivation</Form.Label>
          <Form.Control
            type="text"
            value={props?.modelTrainingMotivation}
            onChange={onChangeModelTrainingMotivation}
            className={`input-field ${
              props?.isValid?.modelTrainingMotivation
                ? "is-valid"
                : props?.isValid.modelTrainingMotivation !== undefined
                ? "is-invalid"
                : ""
            }`}
            name="training-motivation"
            id="training-motivation"
            data-cy="training-motivation"
          />
          {props?.getFormErrorMessage("modelTrainingMotivation")}
          <span className="sub-note">
            Why were these datasets chosen for the training of the model?
          </span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="required-field m-0">
            iii. Preprocessing
          </Form.Label>
          <Form.Control
            type="text"
            value={props?.modelTrainingPreprocessing}
            onChange={onChangeModelTrainingPreprocessing}
            className={`input-field ${
              props?.isValid?.modelTrainingPreprocessing
                ? "is-valid"
                : props?.isValid.modelTrainingPreprocessing !== undefined
                ? "is-invalid"
                : ""
            }`}
            name="training-preprocessing"
            id="training-preprocessing"
            data-cy="training-preprocessing"
          />
          {props?.getFormErrorMessage("modelTrainingPreprocessing")}
          <span className="sub-note">
            How were these datasets preprocessed in preparation for the training
            of the model?
          </span>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default Datasets;
