import { Modal, Button, Offcanvas, Form } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

/**
 * A module for the DiscardModal Component
 * @module components/common-modals/DiscardModal
 */

/**
 * Discard model in the system
 * @method DiscardModal
 *
 * @return {JSX.Element}
 *
 */
const DiscardModal = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  return (
    <>
      <Modal
        show={props?.showDiscardModal && !isMobile}
        onHide={props?.handleDiscardModalClose}
        dialogClassName="modal-32w remove-modal"
        centered
        backdrop="static"
        id="alert-message"
        data-cy="alert-message"
      >
        <Modal.Body>
          <p className="bold">Discard version?</p>
          <p>All of the changes in content and files will not be saved.</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props?.handleDiscardModalClose}
            className="light-btn"
            name="go-back"
            id="go-back"
            data-cy="go-back"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => props?.onClickDiscard(true)}
            className="submit-btn button"
            name="discard"
            id="discard"
            data-cy="discard"
          >
            Discard
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props?.showDiscardModal && isMobile}
        onHide={props?.handleDiscardModalClose}
        placement="bottom"
        className="offcanvas-discard-changes"
        id="alert-message"
        data-cy="alert-message"
      >
        <Offcanvas.Body>
          <Form>
            <p className="bold">Discard version?</p>
            <p>All of the changes in content and files will not be saved.</p>
            <div className="discard-changes-form"></div>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props?.handleDiscardModalClose}
              className="light-btn"
              name="go-back"
              id="go-back"
              data-cy="go-back"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => props?.onClickDiscard()}
              className="submit-btn"
              name="discard"
              id="discard"
              data-cy="discard"
            >
              Discard
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DiscardModal;
