import {
  SET_NOTIF_COUNT_SUCCESS,
  GET_ALL_NOTIFiCATIONS_SUCCESS,
} from "../actions/types";

/**
 * A module that manages notification-related state
 * @module reducers/notification
 */

const initialState = { notifCount: 0, notifications: {} };

/**
 * Method that take the current state and an action as arguments, and return a new state result
 *
 * @method notificationReducer
 *
 * @param {Object} state - An object for the notifications state { notifCount: <integer>, notifications: <object>}
 * @param {Object} action - An object that contains type and payload
 *
 * @return {Object}
 *
 */
export default function notificationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_NOTIF_COUNT_SUCCESS:
      return {
        ...state,
        notifCount: payload,
      };
    case GET_ALL_NOTIFiCATIONS_SUCCESS:
      return {
        ...state,
        notifications: payload.notif_data,
        notifCount: payload.notif_count,
      };
    default:
      return state;
  }
}
