import { Modal, Button, Offcanvas, Spinner } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch } from "react-redux";
import { deleteGroup } from "actions/group";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";

/**
 * A module for the DeleteGroupModal Component
 * @module components/groups/DeleteGroupModal
 */

/**
 * Delete group in the system
 * @method DeleteGroupModal
 *
 * @return {JSX.Element}
 *
 */
const DeleteGroupModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (e) => {
    setLoading(true);
    dispatch(deleteGroup(props?.groupID))
      .then((status) => {
        props.setToastStatus("error");
        props.setToastImage("/images/remove-success.svg");
        navigate("/groups");
      })
      .catch((status) => {
        props.setToastStatus(status);
        props.setToastImage("/images/remove-success.svg");
      })
      .finally(() => {
        props.setShowToast(true);
        props.handleDeleteGroupModalClose();
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        show={props.showDeleteGroupModal && !isMobile}
        onHide={props.handleDeleteGroupModalClose}
        dialogClassName="modal-32w remove-modal"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <p className="bold">Delete this group?</p>
          <p>
            All of the content in this group will be deleted and members will be
            removed
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props.handleDeleteGroupModalClose}
            className="light-btn"
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleFormSubmit}
            className="submit-btn"
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props.showDeleteGroupModal && isMobile}
        onHide={props.handleDeleteGroupModalClose}
        placement="bottom"
        className="offcanvas-delete-profile"
        id="confirmation-message-profile"
        data-cy="confirmation-message-profile"
      >
        <Offcanvas.Body>
          <p className="bold">Delete this group?</p>
          <p>
            All of the content in this group will be deleted and members will be
            removed
          </p>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleDeleteGroupModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleFormSubmit}
              className="submit-btn"
              disabled={loading}
              id="delete-model-btn"
              name="delete-model-btn"
              data-cy="delete-model-btn"
            >
              Delete
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DeleteGroupModal;
