import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from "./Notification";
import Profile from "./Profile";
import { useState, useEffect } from "react";
import { getCurrentUser, logout, refreshToken } from "actions/auth";
import "styles/Header.css";
import "styles/HeaderMobile.css";

/**
 * A module for the Header Component
 * @module components/layout/Header
 */

/**
 * Header elements displaying the logo and the logout button
 * @method Header
 *
 * @param {any} props - An arbitrary inputs of components
 *
 * @return {JSX.Element}
 *
 */

const Header = (props) => {
  const { user, isLoggedIn, currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [sessionWillEnd, setSessionWillEnd] = useState(false);
  const [secondsUntilPrompt, setSecondsUntilPrompt] = useState(600); // 10 minutes
  const [secondsUntilLogout, setSecondsUntilLogout] = useState(5); // 5 seconds
  const promptTime = 600; // 10 minutes
  const logoutTime = 5; // 5 seconds

  let promptTimer;

  const decrementPromptTime = () => {
    if (secondsUntilPrompt > 0) {
      setSecondsUntilPrompt((prevTime) => prevTime - 1);
    }
  };

  const decrementLogoutTime = () => {
    if (secondsUntilLogout > 0) {
      setSecondsUntilLogout((prevTime) => prevTime - 1);
    }
  };

  const resetPromptTimer = () => {
    clearTimeout(promptTimer);
    promptTimer = setTimeout(() => {
      props?.setShowLogoutModal(true);
      setSecondsUntilLogout(logoutTime);
    }, promptTime * 1000);
    setSecondsUntilPrompt(promptTime);
  };

  useEffect(() => {
    if (props?.showLogoutModal && secondsUntilLogout === 0) {
      handleLogout();
    }
  }, [props?.showLogoutModal, secondsUntilLogout]);

  const handleLogout = () => {
    // Perform logout actions
    console.log("User has been automatically logged out.");
    dispatch(logout(isLoggedIn));
    setSessionWillEnd(false);
  };

  const handleStaySignedIn = () => {
    setSessionWillEnd(false);
    dispatch(refreshToken());
    props?.setShowLogoutModal(false);
  };

  const handleSignIn = () => {
    props?.setShowLogoutModal(false);
  };

  useEffect(() => {
    const events = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
    ];

    const resetTimerAndActivityListeners = () => {
      resetPromptTimer();
      events.forEach((event) =>
        document.addEventListener(event, resetPromptTimer)
      );
    };

    resetTimerAndActivityListeners();

    if (!isLoggedIn) {
      clearTimeout(promptTimer);
      events.forEach((event) =>
        document.removeEventListener(event, resetPromptTimer)
      );
    }

    return () => {
      clearTimeout(promptTimer);
      events.forEach((event) =>
        document.removeEventListener(event, resetPromptTimer)
      );
    };
  }, [isLoggedIn]);

  useEffect(() => {
    const countdownInterval = setInterval(decrementPromptTime, 1000);
    if (!isLoggedIn) {
      clearInterval(countdownInterval);
    }
    return () => clearInterval(countdownInterval);
  }, [secondsUntilPrompt, isLoggedIn]);

  useEffect(() => {
    const countdownInterval = setInterval(decrementLogoutTime, 1000);

    return () => clearInterval(countdownInterval);
  }, [secondsUntilLogout]);

  const handleLogoutModalClose = () => {
    props?.setShowLogoutModal(false);
  };

  /**
   * Parsing the JWT
   *
   * @method parseJwt
   *
   * @param {string} token - A string for JWT
   *
   * @return {string|null}
   *
   */
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const handleSessionEnd = () => {
    // Handle session end, e.g., renew the access token
    // You can also trigger a logout or show a modal here
    setSecondsUntilLogout(logoutTime);
    setSessionWillEnd(true);
    props?.setShowLogoutModal(true);
  };

  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    setAccessToken(localStorage.getItem("access"));
  }, [localStorage.getItem("access")]);

  const [remainingTime, setRemainingTime] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const expirationTime = parseJwt(accessToken);
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      const timeLeft = expirationTime?.exp - currentTime;
      setMinutes(Math.floor(timeLeft / 60) || 0);
      setSeconds(timeLeft % 60 || 0);

      if (timeLeft <= 300 && timeLeft > 0) {
        if (!sessionWillEnd) {
          handleSessionEnd();
        }
        if (isLoggedIn) {
          setRemainingTime(timeLeft);
        }
      } else if (timeLeft <= 0) {
        handleLogout();
        props.setShowLogoutModal(true);
        clearInterval(timer);
      } else {
        setRemainingTime(timeLeft);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [accessToken, remainingTime, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCurrentUser()).catch((err) => console.log(err));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!user) {
      handleLogout();
    }
  }, [user]);

  const navigateTo = (url) => {
    if (
      (location.pathname.includes("/version/add") ||
        location.pathname.includes("/version/edit")) &&
      props?.changesMade
    ) {
      props?.setShowDiscardChangesModal(true);
      props.setUrlToRedirected(url);
    } else {
      navigate(url);
    }
  };

  return (
    <>
      {!location.pathname.includes("signin") &&
        !location.pathname.includes("signup") &&
        !location.pathname.includes("forgot-password") &&
        !location.pathname.includes("reset") &&
        !location.pathname.includes("activate") &&
        !location.pathname.includes("404") && (
          <>
            <nav
              className={`desktop-header navbar navbar-expand-lg bg-light-color`}
            >
              <div className="container-fluid">
                <a onClick={() => navigateTo("/")} className="navbar-brand">
                  <img
                    src="/images/dimer-logo-2024.png"
                    className="logo"
                    alt="Model Store logo"
                    style={{ display: "none" }}
                    onLoad={(e) => (e.target.style.display = "unset")}
                  />
                  <span className="dimer semi-bold ms-2">DIMER</span>
                  {/* <div>
                  <p>Prompt will show in: {secondsUntilPrompt} seconds</p>
              <div>
                Session ends in: {minutes}:{seconds < 10 ? "0" : ""}
                {seconds} minutes
              </div>
                </div> */}
                </a>
                <div className="d-flex">
                  {isLoggedIn && (
                    <>
                      {/* <Button
                      variant="outline-primary"
                      className="btn-outline-light"
                    >
                      <a href="/DIMER%20User%20Guide.pdf" target="_blank">
                        <img
                          src="/images/file.svg"
                          className="img-fluid"
                          alt="File"
                        />
                      </a>
                    </Button> */}
                      <Notification 
                      setPreload={props?.setPreload}
                      setChangesMade={props?.setChangesMade}
                      urlToRedirected={props?.urlToRedirected}
                      setUrlToRedirected={props?.setUrlToRedirected}/>
                      <Profile />
                    </>
                  )}
                </div>
              </div>
            </nav>
          </>
        )}
      <Modal
        show={props?.showLogoutModal}
        onHide={handleLogoutModalClose}
        dialogClassName="modal-32w remove-modal"
        contentClassName={isLoggedIn && sessionWillEnd && "yellow"}
        centered
        backdrop="static"
      >
        <Modal.Body>
          {!sessionWillEnd && <p className="bold">Inactivity detected</p>}
          {isLoggedIn ? (
            sessionWillEnd ? (
              <p className="bold">Session will end in 5 minutes</p>
            ) : (
              <p>
                Due to inactivity, you will be signed out from the system in a
                few moment.
              </p>
            )
          ) : (
            <p>You have been signed-out from the system.</p>
          )}
          {/* Remaining Time: {secondsUntilLogout} */}
        </Modal.Body>
        <Modal.Footer className="justify-content-around">
          {isLoggedIn ? (
            <Button onClick={handleStaySignedIn} className="button submit-btn">
              Stay signed in
            </Button>
          ) : (
            <Button onClick={handleSignIn} className="button submit-btn">
              Sign In
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Header;
