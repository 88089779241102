import { Modal, Button, Form, Offcanvas } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "styles/mobile/DiscardChangesModalMobile.css";

/**
 * A module for the DiscardChangesModal Component
 * @module components/common-modals/DiscardChangesModal
 */

/**
 * Discard changes model in the system
 * @method DiscardModal
 *
 * @return {JSX.Element}
 *
 */
const DiscardChangesModal = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  return (
    <>
      <Modal
        show={props?.showDiscardModal && !isMobile}
        onHide={() => props?.onClickGoBack()}
        dialogClassName="modal-32w remove-modal"
        centered
        backdrop="static"
        id="alert-message"
        data-cy="alert-message"
      >
        <Modal.Body>
          <p className="bold">Discard changes?</p>
          <p>All of the changes in content and files will not be saved.</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={() => props?.onClickGoBack()}
            className="light-btn"
            name="go-back"
            id="go-back"
            data-cy="go-back"
          >
            Go back
          </Button>
          <Button
            variant="danger"
            onClick={() => props?.onClickDiscard()}
            className="submit-btn"
            name="discard"
            id="discard"
            data-cy="discard"
          >
            Discard
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props?.showDiscardModal && isMobile}
        onHide={() => props?.onClickGoBack()}
        placement="bottom"
        className="offcanvas-discard-changes"
        id="alert-message"
        data-cy="alert-message"
      >
        <Offcanvas.Body>
          <Form>
            <p className="bold">Discard changes?</p>
            <p>All of the changes in content and files will not be saved.</p>
            <div className="discard-changes-form"></div>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={() => props?.onClickGoBack()}
              className="light-btn"
              name="go-back"
              id="go-back"
              data-cy="go-back"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => props?.onClickDiscard()}
              className="submit-btn"
              name="discard"
              id="discard"
              data-cy="discard"
            >
              Discard
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DiscardChangesModal;
