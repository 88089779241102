import {
  DELETE_DATASET_SUCCESS,
  GET_ALL_DATASETS_FAIL,
  GET_ALL_DATASETS_SUCCESS,
  GET_ALL_DATASETS_VERSIONS_FAIL,
  GET_ALL_DATASETS_VERSIONS_SUCCESS,
  GET_ALL_SUBSCRIBE_DATASETS_SUCCESS,
  GET_DATASET_FAIL,
  GET_DATASET_SUCCESS,
  GET_DATASET_VERSION_FAIL,
  GET_DATASET_VERSION_SUCCESS,
  GET_SUBSCRIBE_DATASET_SUCCESS,
  SET_MESSAGE,
  SUBSCRIBE_DATASET_SUCCESS,
  UPDATE_DATASET_SUCCESS,
} from "./types";
import DatasetService from "services/dataset.service";
/**
 * A module that sends success and error response action for dataset-related methods
 * @module actions/dataset
 */

/**
 * Add dataset information
 * success and error response action
 *
 *
 * @method addDataset
 *
 * @param {string} datasetTitle - A string for the dataset title
 * @param {string} datasetDescription - A string for the dataset description
 *
 * @return {Promise}
 *
 */
export const addDataset = (datasetTitle, datasetDescription) => (dispatch) => {
  return DatasetService.addDataset(datasetTitle, datasetDescription).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data?.error || "Dataset profile created.",
      });

      return Promise.resolve(data?.error ? "error" : "success");
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          (error.response.data.message ||
            (error.response.data?.title && error.response.data?.title[0]) ||
            (error.response.data?.description &&
              error.response.data?.description[0]))) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject("error");
    }
  );
};

/**
 * Edit dataset information
 * success and error response action
 *
 *
 * @method editDataset
 *
 * @param {string} datasetTitle - A string for the dataset title
 * @param {string} datasetDescription - A string for the dataset description
 * @return {Promise}
 *
 */
export const editDataset =
  (datasetID, datasetTitle, datasetDescription) => (dispatch) => {
    return DatasetService.editDataset(
      datasetID,
      datasetTitle,
      datasetDescription
    ).then(
      (data) => {
        if (!data?.error) {
          dispatch({
            type: UPDATE_DATASET_SUCCESS,
            payload: data,
          });
        }

        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Dataset profile updated.",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          error.response.statusText ||
          error.response.data ||
          error.message ||
          error.toString();

        const status = error.response.data?.toString().includes("6379")
          ? "notif-in-app-network unreachable"
          : "error";

        dispatch({
          type: SET_MESSAGE,
          payload:
            status === "notif-in-app-network unreachable"
              ? "Dataset profile updated."
              : message,
        });

        return Promise.reject(status);
      }
    );
  };

export const addDatasetVersionSaveDraft =
  (
    datasetProfileID,
    datasetSubtitle,
    datasetVersionDescription,
    datasetLicense,
    datasetLicenseContent,
    datasetCitationDetails,
    datasetOtherRelevantInfo,
    datasetFile,
    datasetContributors,
    datasetKeywords,
    onUploadProgress,
    cancelToken
  ) =>
  (dispatch) => {
    return DatasetService.addDatasetVersionSaveDraft(
      datasetProfileID,
      datasetSubtitle,
      datasetVersionDescription,
      datasetLicense,
      datasetLicenseContent,
      datasetCitationDetails,
      datasetOtherRelevantInfo,
      datasetFile,
      datasetContributors,
      datasetKeywords,
      onUploadProgress,
      cancelToken
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Version saved as draft.",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          error.response.statusText ||
          error.response.data ||
          error.message ||
          error.toString();

        const status = error.response.data?.toString().includes("6379")
          ? "notif-in-app-network unreachable"
          : "error";

        dispatch({
          type: SET_MESSAGE,
          payload:
            status === "notif-in-app-network unreachable"
              ? "Version saved as draft."
              : message,
        });

        return Promise.reject(status);
      }
    );
  };

export const addDatasetVersionSaveAndPublish =
  (
    datasetProfileID,
    datasetSubtitle,
    datasetVersionDescription,
    datasetLicense,
    datasetLicenseContent,
    datasetCitationDetails,
    datasetOtherRelevantInfo,
    datasetFile,
    datasetContributors,
    datasetKeywords,
    onUploadProgress,
    cancelToken
  ) =>
  (dispatch) => {
    return DatasetService.addDatasetVersionSaveAndPublish(
      datasetProfileID,
      datasetSubtitle,
      datasetVersionDescription,
      datasetLicense,
      datasetLicenseContent,
      datasetCitationDetails,
      datasetOtherRelevantInfo,
      datasetFile,
      datasetContributors,
      datasetKeywords,
      onUploadProgress,
      cancelToken
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Version is now published.",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          error.response.data.subtitle ||
          error.response.data.description ||
          error.response.data.license ||
          error.response.data.license_content ||
          error.response.data.citation_details ||
          error.response.data.other_relevant_information ||
          error.response.data.file_attachments ||
          error.response.data.message ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject("error");
      }
    );
  };

export const uploadDatasetFile =
  (datasetFiles, onUploadProgress, cancelToken) => (dispatch) => {
    return DatasetService.uploadDatasetFile(
      datasetFiles,
      onUploadProgress,
      cancelToken
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error,
        });

        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const editDatasetVersionSaveDraft =
  (
    datasetProfileID,
    datasetVersionID,
    datasetSubtitle,
    datasetVersionDescription,
    datasetLicense,
    datasetLicenseContent,
    datasetCitationDetails,
    datasetOtherRelevantInfo,
    datasetFile,
    datasetContributors,
    datasetKeywords
  ) =>
  (dispatch) => {
    return DatasetService.editDatasetVersionSaveDraft(
      datasetProfileID,
      datasetVersionID,
      datasetSubtitle,
      datasetVersionDescription,
      datasetLicense,
      datasetLicenseContent,
      datasetCitationDetails,
      datasetOtherRelevantInfo,
      datasetFile,
      datasetContributors,
      datasetKeywords
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Version saved as draft.",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          error.response.statusText ||
          error.response.data ||
          error.message ||
          error.toString();

        const status = error.response.data?.toString().includes("6379")
          ? "notif-in-app-network unreachable"
          : "error";

        dispatch({
          type: SET_MESSAGE,
          payload:
            status === "notif-in-app-network unreachable"
              ? "Version saved as draft."
              : message,
        });

        return Promise.reject(status);
      }
    );
  };

export const editDatasetVersionSaveAndPublish =
  (
    datasetProfileID,
    datasetVersionID,
    datasetSubtitle,
    datasetVersionDescription,
    datasetLicense,
    datasetLicenseContent,
    datasetCitationDetails,
    datasetOtherRelevantInfo,
    datasetFile,
    datasetContributors,
    datasetKeywords
  ) =>
  (dispatch) => {
    return DatasetService.editDatasetVersionSaveAndPublish(
      datasetProfileID,
      datasetVersionID,
      datasetSubtitle,
      datasetVersionDescription,
      datasetLicense,
      datasetLicenseContent,
      datasetCitationDetails,
      datasetOtherRelevantInfo,
      datasetFile,
      datasetContributors,
      datasetKeywords
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Version is now published.",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          error.response.data.subtitle ||
          error.response.data.description ||
          error.response.data.license ||
          error.response.data.license_content ||
          error.response.data.citation_details ||
          error.response.data.other_relevant_information ||
          error.response.data.file_attachments ||
          error.response.data.message ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject("error");
      }
    );
  };

/**
 * Delete dataset information and dataset file path
 * success and error response action
 *
 *
 * @method deleteDataset
 *
 * @param {string} id - A string for identifying dataset
 *
 * @return {Promise}
 *
 */
export const deleteDataset = (id) => (dispatch) => {
  return DatasetService.deleteDataset(id).then(
    (data) => {
      dispatch({
        type: DELETE_DATASET_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: data?.error || "Dataset profile deleted.",
      });

      return Promise.resolve(data?.error ? "error" : "success");
    },
    (error) => {
      console.log(error.response.data?.toString().includes("6379"));
      const message =
        error.response.statusText ||
        error.response.data ||
        error.message ||
        error.toString();

      const status = error.response.data?.toString().includes("6379")
        ? "notif-in-app-network unreachable"
        : "error";

      console.log(status);
      console.log(message);
      dispatch({
        type: SET_MESSAGE,
        payload:
          status === "notif-in-app-network unreachable"
            ? "Dataset profile deleted."
            : message,
      });

      return Promise.reject(status);
    }
  );
};

/**
 * Get dataset information
 * success and error response action
 *
 *
 * @method getDataset
 *
 * @param {string} id - A string for identifying dataset
 *
 * @return {Promise}
 *
 */
export const getDataset = (id) => (dispatch) => {
  return DatasetService.getDataset(id).then(
    (data) => {
      dispatch({
        type: GET_DATASET_SUCCESS,
        payload: data,
      });

      return Promise.resolve(data);
    },
    (error) => {
      const message =
        error.response.statusText ||
        error.response.data ||
        error.message ||
        error.toString();

      const status = error.response.statusText.includes("Not Found")
        ? "not found"
        : "error";

      dispatch({
        type: GET_DATASET_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(status);
    }
  );
};

/**
 * Get dataset version information
 * success and error response action
 *
 *
 * @method getDatasetVersion
 *
 * @param {string} datasetProfileID - A string for identifying dataset profile
 * @param {string} datasetVersionID - A string for identifying dataset version
 *
 * @return {Promise}
 *
 */
export const getDatasetVersion =
  (datasetProfileID, datasetVersionID) => (dispatch) => {
    return DatasetService.getDatasetVersion(
      datasetProfileID,
      datasetVersionID
    ).then(
      (data) => {
        dispatch({
          type: GET_DATASET_VERSION_SUCCESS,
          payload: data,
        });

        return Promise.resolve(data);
      },
      (error) => {
        const message =
          error.response.statusText ||
          error.response.data ||
          error.message ||
          error.toString();

        const status =
          error.response.statusText.includes("Not Found") ||
          error.response.data?.toString().includes("expected a number")
            ? "not found"
            : "error";

        dispatch({
          type: GET_DATASET_VERSION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject(status);
      }
    );
  };

export const deleteDatasetVersion =
  (datasetProfileID, datasetVersionID) => (dispatch) => {
    return DatasetService.deleteDatasetVersion(
      datasetProfileID,
      datasetVersionID
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Version deleted",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject("error");
      }
    );
  };

/**
 * Get all datasets information
 * success and error response action
 *
 *
 * @method getAllDatasets
 *
 * @return {Promise}
 *
 */
export const getAllDatasets = () => (dispatch) => {
  return DatasetService.getAllDatasets().then(
    (data) => {
      dispatch({
        type: GET_ALL_DATASETS_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ALL_DATASETS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

/**
 * Get all dataset versions information
 * success and error response action
 *
 *
 * @method getAllDatasetVersions
 *
 * @return {Promise}
 *
 */
export const getAllDatasetVersions =
  (datasetProfileID, filterParams) => (dispatch) => {
    return DatasetService.getAllDatasetVersions(
      datasetProfileID,
      filterParams
    ).then(
      (data) => {
        dispatch({
          type: GET_ALL_DATASETS_VERSIONS_SUCCESS,
          payload: data,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: GET_ALL_DATASETS_VERSIONS_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

/**
 * Get all subscribed datasets information
 * success and error response action
 *
 *
 * @method getAllSubscribeDatasetProfile
 *
 * @return {Promise}
 *
 */
export const getAllSubscribeDatasetProfile = () => (dispatch) => {
  return DatasetService.getAllSubscribeDatasetProfile().then(
    (data) => {
      dispatch({
        type: GET_ALL_SUBSCRIBE_DATASETS_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        error.response.statusText ||
        error.response.data ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getSubscribeDatasetProfile = (datasetProfileID) => (dispatch) => {
  return DatasetService.getSubscribeDatasetProfile(datasetProfileID).then(
    (data) => {
      dispatch({
        type: GET_SUBSCRIBE_DATASET_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        error.response.statusText ||
        error.response.data ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getNextDatasetVersionNumber = (profileID) => (dispatch) => {
  return DatasetService.getNextDatasetVersionNumber(profileID).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getAllKeywords = (profileID) => (dispatch) => {
  return DatasetService.getAllKeywords(profileID).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const subscribeDatasetProfile = (profileID) => (dispatch) => {
  return DatasetService.subscribeDatasetProfile(profileID).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: "Subscription added.",
      });
      return Promise.resolve(data);
    },
    (error) => {
      const message =
        error.response.statusText ||
        error.response.data ||
        error.message ||
        error.toString();

      const status = error.response.data?.toString().includes("6379")
        ? "notif-in-app-network unreachable"
        : "error";

      dispatch({
        type: SET_MESSAGE,
        payload:
          status === "notif-in-app-network unreachable"
            ? "Subscription added."
            : message,
      });

      return Promise.reject(status);
    }
  );
};

export const unsubscribeDatasetProfile = (profileID) => (dispatch) => {
  return DatasetService.unsubscribeDatasetProfile(profileID).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: "Subscription removed.",
      });
      return Promise.resolve(data);
    },
    (error) => {
      const message =
        error.response.statusText ||
        error.response.data ||
        error.message ||
        error.toString();

      const status = error.response.data?.toString().includes("6379")
        ? "notif-in-app-network unreachable"
        : "error";

      dispatch({
        type: SET_MESSAGE,
        payload:
          status === "notif-in-app-network unreachable"
            ? "Subscription removed."
            : message,
      });

      return Promise.reject(status);
    }
  );
};

export const publishDatasetVersion = (profileID, versionID) => (dispatch) => {
  return DatasetService.publishDatasetVersion(profileID, versionID).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data?.error || "Version is now published",
      });

      return Promise.resolve(data?.error ? "error" : "success");
    },
    (error) => {
      const message =
        error.response.statusText ||
        error.response.data ||
        error.message ||
        error.toString();

      const status = error.response.data?.toString().includes("6379")
        ? "notif-in-app-network unreachable"
        : "error";

      dispatch({
        type: SET_MESSAGE,
        payload:
          status === "notif-in-app-network unreachable"
            ? "Version is now published."
            : message,
      });

      return Promise.reject(status);
    }
  );
};

export const archiveDatasetVersion =
  (profileID, versionID, archiveReason) => (dispatch) => {
    return DatasetService.archiveDatasetVersion(
      profileID,
      versionID,
      archiveReason
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Version archived",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          error.response.statusText ||
          error.response.data ||
          error.message ||
          error.toString();

        const status = error.response.data?.toString().includes("6379")
          ? "notif-in-app-network unreachable"
          : "error";

        dispatch({
          type: SET_MESSAGE,
          payload:
            status === "notif-in-app-network unreachable"
              ? "Version archived"
              : message,
        });

        return Promise.reject(status);
      }
    );
  };

export const duplicateDatasetVersion =
  (datasetProfileID, datasetVersionID) => (dispatch) => {
    return DatasetService.duplicateDatasetVersion(
      datasetProfileID,
      datasetVersionID
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Version duplicated",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject("error");
      }
    );
  };

/**
 * Download dataset version information
 * success and error response action
 *
 *
 * @method downloadDatasetVersion
 *
 * @param {string} datasetProfileID - A string for the dataset profile ID
 * @param {string} datasetVersionID - A string for the dataset version ID
 * @param {string} onDownloadProgress - A progress event that measures upload process
 * @param {string} cancelToken - A cancel the token of the current request in axios
 *
 * @return {Promise}
 *
 */
export const downloadDatasetVersion =
  (datasetProfileID, datasetVersionID, onDownloadProgress, cancelToken) =>
  (dispatch) => {
    return DatasetService.downloadDatasetVersion(
      datasetProfileID,
      datasetVersionID,
      onDownloadProgress,
      cancelToken
    ).then(
      (data) => {
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages[0].message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const addLikes = (datasetProfileID, datasetVersionID) => (dispatch) => {
  return DatasetService.addLikes(datasetProfileID, datasetVersionID).then(
    (data) => {
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteLikes =
  (datasetProfileID, datasetVersionID) => (dispatch) => {
    return DatasetService.deleteLikes(datasetProfileID, datasetVersionID).then(
      (data) => {
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };
