import { useState, useEffect } from "react";
import {
  Container,
  Tab,
  Breadcrumb,
  Tabs,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getModel, getModelVersion } from "actions/model";
import Overview from "./sections/add-and-edit/Overview";
import Details from "./sections/add-and-edit/Details";
import Datasets from "./sections/add-and-edit/Datasets";
import Analyses from "./sections/add-and-edit/Analyses";
import Recommendations from "./sections/add-and-edit/Recommendations";
import SaveAndPublishModal from "components/common-modals/SaveAndPublishModal";
import DiscardModal from "../common-modals/DiscardModal";
import { SET_MESSAGE } from "actions/types";
import { convertDate } from "common/constants";
import DiscardChangesModal from "../common-modals/DiscardChangesModal";
import { useMediaQuery } from "react-responsive";

/**
 * A module for showing Edit Model Version component
 * @module components/models/EditModelVersion
 */

/**
 * Edit Model Version component
 * @method EditModelVersion
 *
 * @return {JSX.Element}
 *
 */

const EditModelVersion = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const params = useParams();
  const id = params.id;

  /**
   * -------------------
   * * Redux store state
   * -------------------
   */
  const { currentModel, currentModelVersion } = useSelector(
    (state) => state.model
  );

  const [modelName, setModelName] = useState("");
  const [modelProfileCreator, setModelProfileCreator] = useState("");
  const [showSaveAndPublishModal, setShowSaveAndPublishModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [error, setError] = useState({});
  const [counter, setCounter] = useState(0);

  const [saveDraftDisable, setSaveDraftDisable] = useState(true);
  const [saveAndPublishDisable, setSaveAndPublishDisable] = useState(true);
  const [discardDisable, setDiscardDisable] = useState(true);
  const [modelProfileID, setModelProfileID] = useState("");
  const [duplicateCount, setDuplicateCount] = useState(0);
  const [editModelDevelopers, setEditModelDevelopers] = useState("");

  useEffect(() => {
    if (currentModelVersion?.model) {
      props.setPreload(true);
      dispatch(getModel(currentModelVersion?.model[0]?.model_profile))
        .then((res) => res?.length === 0 && navigate("/404"))
        .catch((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        })
        .finally(() => props.setPreload(false));
    }
  }, [currentModelVersion?.model]);

  useEffect(() => {
    props?.setUrlToRedirected(location.pathname);
    resetForm();
    getModelVersionDetails();
  }, [id]);

  const getModelVersionDetails = () => {
    props.setPreload(true);
    dispatch(getModelVersion(id))
      .then((res) => res?.model.length === 0 && navigate("/404"))
      .catch((status) => {
        props.setShowToast(true);
        props.setToastStatus(status);
        props.setToastImage(null);
      })
      .finally(() => props.setPreload(false));
  };

  useEffect(() => {
    setModelName(currentModel[0]?.model_profile_name);
    setModelProfileCreator(currentModel[0]?.model_created_by);
  }, [currentModel]);

  useEffect(() => {
    if (currentModelVersion?.model_file?.file_names) {
      props?.setSelectedFile(
        currentModelVersion?.model_file?.file_names?.map((file) => {
          const size = currentModelVersion?.model_file.file_sizes[file];
          return {
            name: file,
            size: size,
          };
        })
      );
    }
    if (currentModelVersion?.model) {
      setDuplicateCount(currentModelVersion?.model[0]?.duplicate_count);
      setModelProfileID(currentModelVersion?.model[0]?.model_profile);

      // props?.setModelNumber(currentModelVersion?.model[0]?.version);
      // props?.setModelVersionName(currentModelVersion?.model[0]?.name);
      props?.setModelVersionDescription(
        currentModelVersion?.model[0]?.description
      );
      props?.setModelContact(
        currentModelVersion?.model[0]?.contact_information
      );
      // props?.setIsArchive(currentModelVersion?.model[0]?.is_archive);
    }

    if (currentModelVersion?.model_details) {
      props?.setModelDevelopmentDate(
        currentModelVersion?.model_details[0]?.development_date || ""
      );
      props?.setModelDevelopers(
        currentModelVersion?.model_details[0]?.developers
      );
      setEditModelDevelopers(currentModelVersion?.model_details[0]?.developers);

      props?.setModelAccuracy(currentModelVersion?.model_details[0]?.accuracy);
      props?.setModelInput(currentModelVersion?.model_details[0]?.input);
      props?.setModelOutput(currentModelVersion?.model_details[0]?.output);
      props?.setModelType(currentModelVersion?.model_details[0]?.type);
      props?.setModelPaper(currentModelVersion?.model_details[0]?.paper);
      props?.setModelCitationDetails(
        currentModelVersion?.model_details[0]?.citation_details
      );
      props?.setModelLicense(currentModelVersion?.model_details[0]?.license);
      props?.setModelLicenseContent(
        currentModelVersion?.model_details[0]?.license_content
      );
      props?.setModelOtherRelevantInfo(
        currentModelVersion?.model_details[0]?.other_relevant_information
      );
      // props?.setIsPublish(currentModelVersion?.model_details[0]?.is_publish);
    }

    if (currentModelVersion?.dataset) {
      props?.setModelTrainingDataset(
        currentModelVersion?.dataset[0]?.td_datasets
      );
      props?.setModelTrainingMotivation(
        currentModelVersion?.dataset[0]?.td_motivation
      );
      props?.setModelTrainingPreprocessing(
        currentModelVersion?.dataset[0]?.td_preprocessing
      );
      props?.setModelEvaluationDataset(
        currentModelVersion?.dataset[0]?.ed_datasets
      );
      props?.setModelEvaluationMotivation(
        currentModelVersion?.dataset[0]?.ed_motivation
      );
      props?.setModelEvaluationPreprocessing(
        currentModelVersion?.dataset[0]?.ed_preprocessing
      );
    }

    if (currentModelVersion?.analysis) {
      props?.setModelUnitaryResults(
        currentModelVersion?.analysis[0]?.unitary_result
      );
      props?.setModelIntersectionalResults(
        currentModelVersion?.analysis[0]?.intersectional_result
      );
    }

    if (currentModelVersion?.recommendation) {
      props?.setModelDetails(currentModelVersion?.recommendation[0]?.details);
    }
  }, [currentModelVersion]);

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name, cName) => {
    return <div className={`invalid-feedback ` + cName}>{error[name]} </div>;
  };

  const handleSaveAndPublishModalClose = () => {
    setShowSaveAndPublishModal(false);
  };

  const handleDiscardModalClose = () => {
    setShowDiscardModal(false);
  };

  const [isValid, setIsValid] = useState({});
  const withFormattingOptions = [
    "modelVersionDescription",
    "modelCitationDetails",
    "modelLicenseContent",
    "modelOtherRelevantInfo",
    "modelGroups",
    "modelInstrumentation",
    "modelEnvironment",
    "modelUnitaryResults",
    "modelIntersectionalResults",
    "modelDetails",
  ];

  useEffect(() => {
    resetForm();
  }, []);

  const resetForm = () => {
    props?.setSelectedFile("");
    // props?.setModelNumber("");
    // props?.setModelVersionName("");
    props?.setModelVersionDescription("<p><br></p>");
    props?.setModelContact("");
    props?.setModelDevelopmentDate("");
    props?.setModelDevelopers("");
    props?.setModelAccuracy("");
    props?.setModelInput("");
    props?.setModelOutput("");
    props?.setModelOutput("");
    props?.setModelType("");
    props?.setModelPaper("");
    props?.setModelCitationDetails("<p><br></p>");
    props?.setModelLicense("<p><br></p>");
    props?.setModelLicenseContent("<p><br></p>");
    props?.setModelOtherRelevantInfo("<p><br></p>");
    props?.setModelTrainingDataset("");
    props?.setModelTrainingMotivation("");
    props?.setModelTrainingPreprocessing("");
    props?.setModelEvaluationDataset("");
    props?.setModelEvaluationMotivation("");
    props?.setModelEvaluationPreprocessing("");
    props?.setModelUnitaryResults("<p><br></p>");
    props?.setModelIntersectionalResults("<p><br></p>");
    props?.setModelDetails("<p><br></p>");
    setIsValid({});
    setError({});
    props?.setChangesMade(false);
  };

  useEffect(() => {
    if (
      props?.selectedFile !== "" ||
      // props?.modelNumber !== "" ||
      // props?.modelVersionName !== "" ||
      props?.modelVersionDescription !== "<p><br></p>" ||
      props?.modelContact !== "" ||
      props?.modelDevelopmentDate !== "" ||
      props?.modelDevelopers !== "" ||
      props?.modelAccuracy !== "" ||
      props?.modelInput !== "" ||
      props?.modelOutput !== "" ||
      props?.modelType !== "" ||
      props?.modelPaper !== "" ||
      props?.modelCitationDetails !== "<p><br></p>" ||
      props?.modelLicense !== "" ||
      props?.modelLicenseContent !== "<p><br></p>" ||
      props?.modelOtherRelevantInfo !== "<p><br></p>" ||
      props?.modelTrainingDataset !== "" ||
      props?.modelTrainingMotivation !== "" ||
      props?.modelTrainingPreprocessing !== "" ||
      props?.modelEvaluationDataset !== "" ||
      props?.modelEvaluationMotivation !== "" ||
      props?.modelEvaluationPreprocessing !== "" ||
      props?.modelUnitaryResults !== "<p><br></p>" ||
      props?.modelIntersectionalResults !== "<p><br></p>" ||
      props?.modelDetails !== "<p><br></p>"
    ) {
      setDiscardDisable(false);
      if (Object.keys(error).length > 0) {
        if (
          (error?.modelFile && props?.selectedFile !== "") ||
          // (error?.modelNumber && props?.modelNumber !== "") ||
          // (error?.modelVersionName && props?.modelVersionName !== "") ||
          (error?.modelVersionDescription &&
            props?.modelVersionDescription !== "<p><br></p>") ||
          (error?.modelContact && props?.modelContact !== "") ||
          (error?.modelDevelopmentDate && props?.modelDevelopmentDate !== "") ||
          (error?.modelDevelopers && props?.modelDevelopers !== "") ||
          (error?.modelAccuracy && props?.modelAccuracy !== "") ||
          (error?.modelInput && props?.modelInput !== "") ||
          (error?.modelOutput && props?.modelOutput !== "") ||
          (error?.modelType && props?.modelType !== "") ||
          (error?.modelPaper && props?.modelPaper !== "") ||
          (error?.modelCitationDetails &&
            props?.modelCitationDetails !== "<p><br></p>") ||
          (error?.modelLicense && props?.modelLicense !== "") ||
          (error?.modelLicenseContent &&
            props?.modelLicenseContent !== "<p><br></p>") ||
          (error?.modelOtherRelevantInfo &&
            props?.modelOtherRelevantInfo !== "<p><br></p>") ||
          (error?.modelTrainingDataset && props?.modelTrainingDataset !== "") ||
          (error?.modelTrainingMotivation &&
            props?.modelTrainingMotivation !== "") ||
          (error?.modelTrainingPreprocessing &&
            props?.modelTrainingPreprocessing !== "") ||
          (error?.modelEvaluationDataset &&
            props?.modelEvaluationDataset !== "") ||
          (error?.modelEvaluationMotivation &&
            props?.modelEvaluationMotivation !== "") ||
          (error?.modelEvaluationPreprocessing &&
            props?.modelEvaluationPreprocessing !== "") ||
          (error?.modelUnitaryResults &&
            props?.modelUnitaryResults !== "<p><br></p>") ||
          (error?.modelIntersectionalResults &&
            props?.modelIntersectionalResults !== "<p><br></p>") ||
          (error?.modelDetails && props?.modelDetails !== "<p><br></p>")
        ) {
          setSaveDraftDisable(true);
        } else {
          setSaveDraftDisable(false);
        }
      } else {
        setSaveDraftDisable(false);
      }
    } else {
      setSaveDraftDisable(true);
    }

    if (
      Object.keys(error).length === 0 &&
      props?.selectedFile !== "" &&
      // props?.modelNumber !== "" &&
      // props?.modelVersionName !== "" &&
      props?.modelVersionDescription !== "<p><br></p>" &&
      props?.modelContact !== "" &&
      props?.modelDevelopmentDate !== "" &&
      props?.modelDevelopers !== "" &&
      props?.modelAccuracy !== "" &&
      props?.modelInput !== "" &&
      props?.modelOutput !== "" &&
      props?.modelType !== "" &&
      props?.modelCitationDetails !== "<p><br></p>" &&
      props?.modelLicense !== "<p><br></p>" &&
      props?.modelLicenseContent !== "<p><br></p>" &&
      props?.modelTrainingDataset !== "" &&
      props?.modelTrainingMotivation !== "" &&
      props?.modelTrainingPreprocessing !== "" &&
      props?.modelEvaluationDataset !== "" &&
      props?.modelEvaluationMotivation !== "" &&
      props?.modelEvaluationPreprocessing !== ""
    ) {
      setSaveAndPublishDisable(false);
    } else {
      setSaveAndPublishDisable(true);
    }
  }, [props]);

  const validate = (name, value) => {
    props?.setChangesMade(true);
    if (
      withFormattingOptions.findIndex((field) => name === field) >= 0 &&
      value === "<p><br></p>"
    ) {
      value = "";
    }
    if (value) {
      value = value.toString().trim();
    }
    switch (name) {
      case "modelFile":
        const extensionName = value?.split(".").at(-1).toLowerCase();
        if (!value) {
          setCounter(counter + 1);
          setIsValid(Object.assign(isValid, { [name]: false }));
          setError(
            Object.assign(error, {
              [name]: "Choose your model file",
            })
          );
          props?.setSelectedFile("");
          return;
        }
        if (
          extensionName !== "pkl" &&
          extensionName !== "pickle" &&
          extensionName !== "h5" &&
          extensionName !== "pb" &&
          extensionName !== "mlmodel" &&
          extensionName !== "pt" &&
          extensionName !== "onnx" &&
          extensionName !== "pmml" &&
          extensionName !== "pth" &&
          extensionName !== "ckpt" &&
          extensionName !== "safetensors" &&
          extensionName !== "parquet" &&
          extensionName !== "nc" &&
          extensionName !== "hdf5" &&
          extensionName !== "csv" &&
          extensionName !== "npy" &&
          extensionName !== "petastorm" &&
          extensionName !== "py"
        ) {
          setCounter(counter + 1);
          setIsValid(Object.assign(isValid, { [name]: false }));
          setError(
            Object.assign(error, {
              [name]: "File type not supported",
            })
          );
          props?.setSelectedFile("");
          return;
        }
        if (
          props?.selectedFile &&
          props?.selectedFile.find((file) => file.name === value)
        ) {
          props?.setSelectedFile(props?.selectedFile);
          return;
        }
        break;
      case "modelNumber":
        setIsValid(Object.assign(isValid, { [name]: false }));
        if (!/^[a-zA-Z0-9.]+$/.test(value)) {
          setError(
            Object.assign(error, {
              [name]: "Accepts alphanumeric and period only.",
            })
          );
          return;
        }
        break;
      case "modelAuthor":
        const authorRegex = /^[a-zA-ZñÑ\s.]+$/;
        setIsValid(Object.assign(isValid, { [name]: false }));
        if (!authorRegex.test(value)) {
          setError(
            Object.assign(error, {
              [name]: "Accepts a-z, A-Z, Ñ/ñ, space, and period",
            })
          );
          return;
        }
        break;
      case "modelDevelopers":
        setIsValid(Object.assign(isValid, { [name]: false }));
        if (value.length <= 0) {
          setCounter(counter + 1);
          setError(
            Object.assign(error, {
              [name]: "Please fill out this field",
            })
          );
          return;
        }
        break;
      // case "modelDevelopers":
      //   const developersRegex = /^[a-zA-ZñÑ\s.,]+$/;
      //   setIsValid(Object.assign(isValid, { [name]: false }));
      //   if (!developersRegex.test(value)) {
      //     setError(
      //       Object.assign(error, {
      //         [name]: "Accepts a-z, A-Z, Ñ/ñ, space, comma, and period",
      //       })
      //     );
      //     return;
      //   }
      //   break;

      case "modelContact":
        const contactRegex = /^[a-zA-Z0-9ñÑ\s.@#_]+$/;
        setIsValid(Object.assign(isValid, { [name]: false }));
        if (!contactRegex.test(value)) {
          setError(
            Object.assign(error, {
              [name]:
                "Accepts a-z, A-Z, 0-9, Ñ/ñ, space, period, and special characters like @#_",
            })
          );
          return;
        }
        break;
      case "modelDevelopmentDate":
        // Parse the input date assuming it is in DD/MM/YYYY format
        const parts = value.split("/");
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);

        // Create a Date object
        const dateObject = new Date(year, month - 1, day);
        const newDate = convertDate(
          parts[2] + "-" + parts[1] + "-" + parts[0],
          "DD/MM/YYYY"
        );

        if (
          newDate.includes("NaN") ||
          newDate.includes("undefined") ||
          dateObject == "Invalid Date"
        ) {
          setIsValid(Object.assign(isValid, { [name]: false }));
          setError(
            Object.assign(error, {
              [name]: "Please match the requested format",
            })
          );
          return;
        }
        break;
      case "modelAccuracy":
        const accuracyRegex = /^[0-9\s.%]+$/;
        setIsValid(Object.assign(isValid, { [name]: false }));
        if (!accuracyRegex.test(value)) {
          setError(
            Object.assign(error, {
              [name]: "Accepts numeric and period",
            })
          );
          return;
        }
        if (value < 0 || value > 100) {
          setError(
            Object.assign(error, {
              [name]: "Accepts 0-100 only",
            })
          );
          return;
        }
        break;
      case "modelPaper":
      case "modelOtherRelevantInfo":
      case "modelGroups":
      case "modelUnitaryResults":
      case "modelIntersectionalResults":
      case "modelDetails":
      case "modelLicenseContent":
        if (value === "") {
          break;
        }
        break;
      default:
        const nameRegex =
          /^[a-zA-Z0-9ñÑ\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]+$/;
        setIsValid(Object.assign(isValid, { [name]: false }));
        if (!nameRegex.test(value)) {
          setError(
            Object.assign(error, {
              [name]:
                "Accepts a-z, A-Z, 0-9, Ñ/ñ, space, and special characters like ~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/",
            })
          );
          return;
        }
        break;
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  const validateAll = () => {
    setCounter(counter + 1);
    // validate("modelFile", props?.modelFile);
    // validate("modelNumber", props?.modelNumber);
    // validate("modelVersionName", props?.modelVersionName);
    validate("modelVersionDescription", props?.modelVersionDescription);
    validate("modelContact", props?.modelContact);
    if (props?.selectedFile.name) {
      validate("modelFile", props?.selectedFile.name);
    }
    validate(
      "modelDevelopmentDate",
      convertDate(props?.modelDevelopmentDate, "DD/MM/YYYY")
    );
    validate("modelDevelopers", props?.modelDevelopers);
    validate("modelAccuracy", props?.modelAccuracy);
    validate("modelInput", props?.modelInput);
    validate("modelOutput", props?.modelOutput);
    validate("modelType", props?.modelType);
    validate("modelPaper", props?.modelPaper);
    validate("modelCitationDetails", props?.modelCitationDetails);
    validate("modelLicense", props?.modelLicense);
    validate("modelLicenseContent", props?.modelLicenseContent);
    validate("modelOtherRelevantInfo", props?.modelOtherRelevantInfo);
    validate("modelTrainingDataset", props?.modelTrainingDataset);
    validate("modelTrainingMotivation", props?.modelTrainingMotivation);
    validate("modelTrainingPreprocessing", props?.modelTrainingPreprocessing);
    validate("modelEvaluationDataset", props?.modelEvaluationDataset);
    validate("modelEvaluationMotivation", props?.modelEvaluationMotivation);
    validate(
      "modelEvaluationPreprocessing",
      props?.modelEvaluationPreprocessing
    );
    validate("modelUnitaryResults", props?.modelUnitaryResults);
    validate("modelIntersectionalResults", props?.modelIntersectionalResults);
    validate("modelDetails", props?.modelDetails);
  };

  // useEffect(() => {
  //   if (props?.modelNumber !== "") {
  //     validate("modelNumber", props?.modelNumber);
  //   }
  //   if (props?.modelVersionName !== "") {
  //     validate("modelVersionName", props?.modelVersionName);
  //   }
  //   if (
  //     props?.modelVersionDescription !== "<p><br></p>" &&
  //     props?.modelVersionDescription !== ""
  //   ) {
  //     validate("modelVersionDescription", props?.modelVersionDescription);
  //   }
  //   if (props?.modelContact !== "") {
  //     validate("modelContact", props?.modelContact);
  //   }
  //   if (props?.selectedFile?.name) {
  //     validate("modelFile", props?.selectedFile?.name);
  //   }
  //   if (props?.modelDevelopmentDate !== "") {
  //     validate("modelDevelopmentDate", props?.modelDevelopmentDate);
  //   }
  //   if (props?.modelDevelopers !== "") {
  //     validate("modelDevelopers", props?.modelDevelopers);
  //   }
  //   if (props?.modelAccuracy !== "") {
  //     validate("modelAccuracy", props?.modelAccuracy);
  //   }
  //   if (props?.modelInput) {
  //     validate("modelInput", props?.modelInput);
  //   }
  //   if (props?.modelOutput !== "") {
  //     validate("modelOutput", props?.modelOutput);
  //   }
  //   if (props?.modelType !== "") {
  //     validate("modelType", props?.modelType);
  //   }
  //   if (props?.modelPaper !== "") {
  //     validate("modelPaper", props?.modelPaper);
  //   }
  //   if (
  //     props?.modelCitationDetails !== "<p><br></p>" &&
  //     props?.modelCitationDetails !== ""
  //   ) {
  //     validate("modelCitationDetails", props?.modelCitationDetails);
  //   }
  //   if (props?.modelLicense !== "<p><br></p>" && props?.modelLicense !== "") {
  //     validate("modelLicense", props?.modelLicense);
  //   }
  //   if (
  //     props?.modelLicenseContent !== "<p><br></p>" &&
  //     props?.modelLicenseContent !== ""
  //   ) {
  //     validate("modelLicenseContent", props?.modelLicenseContent);
  //   }
  //   if (
  //     props?.modelOtherRelevantInfo !== "<p><br></p>" &&
  //     props?.modelOtherRelevantInfo !== ""
  //   ) {
  //     validate("modelOtherRelevantInfo", props?.modelOtherRelevantInfo);
  //   }
  //   if (props?.modelTrainingDataset !== "") {
  //     validate("modelTrainingDataset", props?.modelTrainingDataset);
  //   }
  //   if (props?.modelTrainingMotivation !== "") {
  //     validate("modelTrainingMotivation", props?.modelTrainingMotivation);
  //   }
  //   if (props?.modelTrainingPreprocessing !== "") {
  //     validate("modelTrainingPreprocessing", props?.modelTrainingPreprocessing);
  //   }
  //   if (props?.modelEvaluationDataset !== "") {
  //     validate("modelEvaluationDataset", props?.modelEvaluationDataset);
  //   }
  //   if (props?.modelEvaluationMotivation !== "") {
  //     validate("modelEvaluationMotivation", props?.modelEvaluationMotivation);
  //   }
  //   if (props?.modelEvaluationPreprocessing !== "") {
  //     validate(
  //       "modelEvaluationPreprocessing",
  //       props?.modelEvaluationPreprocessing
  //     );
  //   }
  //   if (
  //     props?.modelUnitaryResults != "<p><br></p>" &&
  //     props?.modelUnitaryResults != ""
  //   ) {
  //     validate("modelUnitaryResults", props?.modelUnitaryResults);
  //   }
  //   if (
  //     props?.modelIntersectionalResults !== "<p><br></p>" &&
  //     props?.modelIntersectionalResults !== ""
  //   ) {
  //     validate("modelIntersectionalResults", props?.modelIntersectionalResults);
  //   }
  //   if (props?.modelDetails !== "") {
  //     validate("modelDetails", props?.modelDetails);
  //   }
  // }, [props]);

  const onClickSave = (isPublish) => {
    if (isPublish) {
      validateAll();
      if (Object.keys(error).length === 0) {
        if (props?.selectedFile !== "") {
          props?.handleUploadFile(true);
        } else {
          props?.handleSaveAndPublish(
            currentModelVersion?.model[0]?.model_file
          );
        }
      }
    } else {
      if (
        props?.selectedFile !== "" ||
        // props?.modelNumber !== "" ||
        // props?.modelVersionName !== "" ||
        props?.modelVersionDescription !== "<p><br></p>" ||
        props?.modelContact !== "" ||
        props?.modelDevelopmentDate !== "" ||
        props?.modelDevelopers !== "" ||
        props?.modelAccuracy !== "" ||
        props?.modelInput !== "" ||
        props?.modelOutput !== "" ||
        props?.modelOutput !== "" ||
        props?.modelType !== "" ||
        props?.modelPaper !== "" ||
        props?.modelCitationDetails !== "<p><br></p>" ||
        props?.modelLicense !== "<p><br></p>" ||
        props?.modelLicenseContent !== "<p><br></p>" ||
        props?.modelOtherRelevantInfo !== "<p><br></p>" ||
        props?.modelTrainingDataset !== "" ||
        props?.modelTrainingMotivation !== "" ||
        props?.modelTrainingPreprocessing !== "" ||
        props?.modelEvaluationDataset !== "" ||
        props?.modelEvaluationMotivation !== "" ||
        props?.modelEvaluationPreprocessing !== "" ||
        props?.modelUnitaryResults !== "<p><br></p>" ||
        props?.modelIntersectionalResults !== "<p><br></p>" ||
        props?.modelDetails !== "<p><br></p>"
      ) {
        props?.handleSaveDraft();
      }
    }
  };

  const onClickDiscard = () => {
    navigate("/models/profile/" + modelProfileID + "?tab=2");
    dispatch({
      type: SET_MESSAGE,
      payload: "Version discarded.",
    });
    props?.setShowToast(true);
    props?.setToastStatus("error");
    props?.setToastImage("/images/remove-success.svg");
  };

  const handleDiscardChangesModalClose = () => {
    props?.setShowDiscardChangesModal(false);
  };

  const onClickDiscardChangesMade = () => {
    handleDiscardChangesModalClose();
    resetForm();
    getModelVersionDetails();
    navigate(props.urlToRedirected);
  };

  const onClickGoBack = () => {
    handleDiscardChangesModalClose();
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      props?.setShowDiscardChangesModal(true);
      event.preventDefault();
      event.returnValue = ""; // Standard for most browsers
    };

    if (props?.changesMade) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [props?.changesMade]);

  const navigateTo = (url) => {
    if (
      (location.pathname.includes("/version/add") ||
        location.pathname.includes("/version/edit")) &&
      props?.changesMade
    ) {
      props?.setShowDiscardChangesModal(true);
      props.setUrlToRedirected(url);
    } else {
      navigate(url);
    }
  };

  return (
    <>
      <DiscardChangesModal
        showDiscardModal={props?.showDiscardChangesModal}
        onClickGoBack={onClickGoBack}
        onClickDiscard={onClickDiscardChangesMade}
      />
      <SaveAndPublishModal
        showSaveAndPublishModal={showSaveAndPublishModal}
        handleSaveAndPublishModalClose={handleSaveAndPublishModalClose}
        onClickSave={onClickSave}
      />
      <DiscardModal
        showDiscardModal={showDiscardModal}
        handleDiscardModalClose={handleDiscardModalClose}
        onClickDiscard={onClickDiscard}
      />
      <Container className="mw-100">
        {!isMobile && (
          <Breadcrumb>
            <Breadcrumb.Item active>Models</Breadcrumb.Item>
            <Breadcrumb.Item
              className="link"
              onClick={() => navigateTo("/models")}
            >
              Model Profiles
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="link"
              onClick={() =>
                navigateTo(`/models/profile/${modelProfileID}?tab=2`)
              }
            >
              {modelName}
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
        <p className="title">Edit Version</p>
        <div className="d-flex justify-content-between">
          <p className="required-field ms-1">
            Required fields are marked with an asterisk
          </p>
          {!isMobile && (
            <div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Save as draft</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <Button
                  disabled={saveDraftDisable}
                  variant="outline-light"
                  className="save-icon me-3 px-3 py-1"
                  onClick={() => onClickSave(false)}
                  name="draft-btn"
                  id="draft-btn"
                  data-cy="draft-btn"
                >
                  <img
                    src="/images/save_draft_2.svg"
                    className="img-fluid bg-transparent me-2"
                    alt="save as draft"
                  />
                  Save as draft
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Save and publish</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <Button
                  disabled={saveAndPublishDisable}
                  variant="outline-light"
                  className="save-icon me-3 px-3 py-1"
                  onClick={() => setShowSaveAndPublishModal(true)}
                  name="publish-btn"
                  id="publish-btn"
                  data-cy="publish-btn"
                >
                  <img
                    src="/images/save_publish_2.svg"
                    className="img-fluid bg-transparent me-2"
                    alt="save and publish"
                  />
                  Save & publish
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Discard</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <Button
                  disabled={discardDisable}
                  variant="outline-light"
                  className="save-icon me-3 px-5 py-1"
                  onClick={() => setShowDiscardModal(true)}
                  name="discard"
                  id="discard"
                  data-cy="discard"
                >
                  <img
                    src="/images/trash_2.svg"
                    className="img-fluid"
                    alt="discard"
                  />
                  Discard
                </Button>
              </OverlayTrigger>
            </div>
          )}
        </div>

        {!isMobile && (
          <Tabs
            defaultActiveKey="model-overview"
            id="model-tabs"
            className="mb-3"
          >
            <Tab
              eventKey="model-overview"
              tabClassName="tabs"
              title="Overview"
              tabAttrs={{ "data-cy": "overview-tab" }}
            >
              <Overview
                duplicateCount={duplicateCount}
                selectedFile={props?.selectedFile}
                setSelectedFile={props?.setSelectedFile}
                error={error}
                // modelNumber={props?.modelNumber}
                // modelVersionName={props?.modelVersionName}
                modelVersionDescription={props?.modelVersionDescription}
                modelAuthor={props?.modelAuthor}
                modelContact={props?.modelContact}
                // setModelNumber={props?.setModelNumber}
                // setModelVersionName={props?.setModelVersionName}
                setModelVersionDescription={props?.setModelVersionDescription}
                setModelAuthor={props?.setModelAuthor}
                setModelContact={props?.setModelContact}
                getFormErrorMessage={getFormErrorMessage}
                validate={validate}
                isValid={isValid}
                setIsValid={setIsValid}
              />
            </Tab>
            <Tab
              eventKey="model-details"
              tabClassName="tabs"
              title="Details"
              tabAttrs={{ "data-cy": "details-tab" }}
            >
              <Details
                duplicateCount={duplicateCount}
                modelProfileId={modelProfileID}
                editModelDevelopers={editModelDevelopers}
                error={error}
                modelContact={props?.modelContact}
                modelDevelopmentDate={props?.modelDevelopmentDate}
                modelDevelopers={props?.modelDevelopers}
                modelAccuracy={props?.modelAccuracy}
                modelInput={props?.modelInput}
                modelOutput={props?.modelOutput}
                modelType={props?.modelType}
                modelPaper={props?.modelPaper}
                modelCitationDetails={props?.modelCitationDetails}
                modelLicense={props?.modelLicense}
                modelLicenseContent={props?.modelLicenseContent}
                modelOtherRelevantInfo={props?.modelOtherRelevantInfo}
                modelProfileCreator={modelProfileCreator}
                setModelDevelopmentDate={props?.setModelDevelopmentDate}
                setModelDevelopers={props?.setModelDevelopers}
                setModelAccuracy={props?.setModelAccuracy}
                setModelInput={props?.setModelInput}
                setModelOutput={props?.setModelOutput}
                setModelType={props?.setModelType}
                setModelPaper={props?.setModelPaper}
                setModelCitationDetails={props?.setModelCitationDetails}
                setModelLicense={props?.setModelLicense}
                setModelLicenseContent={props?.setModelLicenseContent}
                setModelOtherRelevantInfo={props?.setModelOtherRelevantInfo}
                getFormErrorMessage={getFormErrorMessage}
                isValid={isValid}
                validate={validate}
              />
            </Tab>
            <Tab
              eventKey="model-datasets"
              tabClassName="tabs"
              title="Datasets"
              tabAttrs={{ "data-cy": "datasets-tab" }}
            >
              <Datasets
                duplicateCount={duplicateCount}
                error={error}
                modelTrainingDataset={props?.modelTrainingDataset}
                modelTrainingMotivation={props?.modelTrainingMotivation}
                modelTrainingPreprocessing={props?.modelTrainingPreprocessing}
                modelEvaluationDataset={props?.modelEvaluationDataset}
                modelEvaluationMotivation={props?.modelEvaluationMotivation}
                modelEvaluationPreprocessing={
                  props?.modelEvaluationPreprocessing
                }
                setModelTrainingDataset={props?.setModelTrainingDataset}
                setModelTrainingMotivation={props?.setModelTrainingMotivation}
                setModelTrainingPreprocessing={
                  props?.setModelTrainingPreprocessing
                }
                setModelEvaluationDataset={props?.setModelEvaluationDataset}
                setModelEvaluationMotivation={
                  props?.setModelEvaluationMotivation
                }
                setModelEvaluationPreprocessing={
                  props?.setModelEvaluationPreprocessing
                }
                getFormErrorMessage={getFormErrorMessage}
                isValid={isValid}
                validate={validate}
              />
            </Tab>
            <Tab
              eventKey="model-analyses"
              tabClassName="tabs"
              title="Analyses"
              tabAttrs={{ "data-cy": "analyses-tab" }}
            >
              <Analyses
                error={error}
                modelUnitaryResults={props?.modelUnitaryResults}
                modelIntersectionalResults={props?.modelIntersectionalResults}
                setModelUnitaryResults={props?.setModelUnitaryResults}
                setModelIntersectionalResults={
                  props?.setModelIntersectionalResults
                }
                getFormErrorMessage={getFormErrorMessage}
                isValid={isValid}
                validate={validate}
              />
            </Tab>
            <Tab
              eventKey="model-recommendations"
              tabClassName="tabs"
              title="Recommendations"
              tabAttrs={{ "data-cy": "recommendation-tab" }}
            >
              <Recommendations
                duplicateCount={duplicateCount}
                error={error}
                modelDetails={props?.modelDetails}
                setModelDetails={props?.setModelDetails}
                getFormErrorMessage={getFormErrorMessage}
                isValid={isValid}
                validate={validate}
              />
            </Tab>
          </Tabs>
        )}
        {isMobile && (
          <>
            <div className="edit-model-version-form">
              <Overview
                selectedFile={props?.selectedFile}
                setSelectedFile={props?.setSelectedFile}
                error={error}
                // modelNumber={props?.modelNumber}
                // modelVersionName={props?.modelVersionName}
                modelVersionDescription={props?.modelVersionDescription}
                modelAuthor={props?.modelAuthor}
                modelContact={props?.modelContact}
                // setModelNumber={props?.setModelNumber}
                // setModelVersionName={props?.setModelVersionName}
                setModelVersionDescription={props?.setModelVersionDescription}
                setModelAuthor={props?.setModelAuthor}
                setModelContact={props?.setModelContact}
                getFormErrorMessage={getFormErrorMessage}
                validate={validate}
                isValid={isValid}
                setIsValid={setIsValid}
              />
              <Details
                counter={counter}
                modelProfileId={id}
                error={error}
                modelContact={props?.modelContact}
                modelDevelopmentDate={props?.modelDevelopmentDate}
                modelDevelopers={props?.modelDevelopers}
                modelAccuracy={props?.modelAccuracy}
                modelInput={props?.modelInput}
                modelOutput={props?.modelOutput}
                modelType={props?.modelType}
                modelPaper={props?.modelPaper}
                modelCitationDetails={props?.modelCitationDetails}
                modelLicense={props?.modelLicense}
                modelLicenseContent={props?.modelLicenseContent}
                modelOtherRelevantInfo={props?.modelOtherRelevantInfo}
                modelProfileCreator={modelProfileCreator}
                setModelDevelopmentDate={props?.setModelDevelopmentDate}
                setModelDevelopers={props?.setModelDevelopers}
                setModelAccuracy={props?.setModelAccuracy}
                setModelInput={props?.setModelInput}
                setModelOutput={props?.setModelOutput}
                setModelType={props?.setModelType}
                setModelPaper={props?.setModelPaper}
                setModelCitationDetails={props?.setModelCitationDetails}
                setModelLicense={props?.setModelLicense}
                setModelLicenseContent={props?.setModelLicenseContent}
                setModelOtherRelevantInfo={props?.setModelOtherRelevantInfo}
                getFormErrorMessage={getFormErrorMessage}
                isValid={isValid}
                validate={validate}
              />
              <Datasets
                error={error}
                modelTrainingDataset={props?.modelTrainingDataset}
                modelTrainingMotivation={props?.modelTrainingMotivation}
                modelTrainingPreprocessing={props?.modelTrainingPreprocessing}
                modelEvaluationDataset={props?.modelEvaluationDataset}
                modelEvaluationMotivation={props?.modelEvaluationMotivation}
                modelEvaluationPreprocessing={
                  props?.modelEvaluationPreprocessing
                }
                setModelTrainingDataset={props?.setModelTrainingDataset}
                setModelTrainingMotivation={props?.setModelTrainingMotivation}
                setModelTrainingPreprocessing={
                  props?.setModelTrainingPreprocessing
                }
                setModelEvaluationDataset={props?.setModelEvaluationDataset}
                setModelEvaluationMotivation={
                  props?.setModelEvaluationMotivation
                }
                setModelEvaluationPreprocessing={
                  props?.setModelEvaluationPreprocessing
                }
                getFormErrorMessage={getFormErrorMessage}
                isValid={isValid}
                validate={validate}
              />
              <Analyses
                error={error}
                modelUnitaryResults={props?.modelUnitaryResults}
                modelIntersectionalResults={props?.modelIntersectionalResults}
                setModelUnitaryResults={props?.setModelUnitaryResults}
                setModelIntersectionalResults={
                  props?.setModelIntersectionalResults
                }
                getFormErrorMessage={getFormErrorMessage}
                isValid={isValid}
                validate={validate}
              />
              <Recommendations
                error={error}
                modelDetails={props?.modelDetails}
                setModelDetails={props?.setModelDetails}
                getFormErrorMessage={getFormErrorMessage}
                isValid={isValid}
                validate={validate}
              />
            </div>
            <div className="buttons-div">
              <Button
                disabled={discardDisable}
                variant="outline-light"
                className="save-icon px-5 py-1"
                onClick={() => setShowDiscardModal(true)}
                name="discard"
                id="discard"
                data-cy="discard"
              >
                Discard
              </Button>
              <Button
                disabled={saveDraftDisable}
                variant="outline-light"
                className="save-icon px-3 py-1"
                onClick={() => onClickSave(false)}
                name="draft-btn"
                id="draft-btn"
                data-cy="draft-btn"
              >
                Save as draft
              </Button>
              <Button
                disabled={saveAndPublishDisable}
                variant="outline-light"
                className="save-icon px-3 py-1"
                onClick={() => setShowSaveAndPublishModal(true)}
                name="publish-btn"
                id="publish-btn"
                data-cy="publish-btn"
              >
                Save & publish
              </Button>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default EditModelVersion;
