import { Modal, Button, Offcanvas } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

/**
 * A module for the ModelDemoStartModal Component
 * @module components/models/ModelDemoStartModal
 */

/**
 * Start m in the system
 * @method ModelDemoStartModal
 *
 * @return {JSX.Element}
 *
 */
const ModelDemoStartModal = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [disabled, setDisabled] = useState(true);
  const [checkbox, setCheckbox] = useState(false);

  const handleFormSubmit = (e) => {
    setDisabled(true);
    props.handleModelDemoStartModalClose();
    props?.handleStartDemoBtn();
  };

  const onChangeCheckbox = (e) => {
    setCheckbox(e.target.checked);
    if (e.target.checked) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    setDisabled(true);
    setCheckbox(false);
  }, [props.showModelDemoStartModal]);

  return (
    <>
      <Modal
        show={props.showModelDemoStartModal && !isMobile}
        onHide={props.handleModelDemoStartModalClose}
        dialogClassName="modal-32w"
        centered
        backdrop="static"
        id="disclaimer"
        data-cy="disclaimer"
      >
        <Modal.Header>
          <Modal.Title className="bold mt-3 mx-5">Disclaimer</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-5">
          <p>
            This demonstration serves as an example to showcase potential
            features, capabilities, and use cases of this AI model.
          </p>
          <p>
            We strongly recommend exercising caution from making any decisions
            solely based on the results obtained from this demo.
          </p>
          <p className="d-flex">
            <input
              type="checkbox"
              className="me-3"
              onChange={onChangeCheckbox}
              value={checkbox}
              checked={checkbox}
              name="check-disclaimer"
              id="check-disclaimer"
              data-cy="check-disclaimer"
            />
            I understand and acknowledge that by using this demo, the DIMER team
            is not liable for any damages arising from the use of this demo.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between mx-5 mb-3">
          <Button
            variant="outline-light"
            onClick={props.handleModelDemoStartModalClose}
            className="light-btn"
            name="cancel"
            id="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            onClick={handleFormSubmit}
            className="submit-btn button"
            name="start-demo"
            id="start-demo"
            data-cy="start-demo"
          >
            Start demo
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props.showModelDemoStartModal && isMobile}
        onHide={props.handleModelDemoStartModalClose}
        placement="bottom"
        className="offcanvas-delete-profile"
        id="confirmation-message-profile"
        data-cy="confirmation-message-profile"
      >
        <Offcanvas.Body>
        <p className="bold" id="modal-title">Disclaimer</p>
          <p>
            This demonstration serves as an example to showcase potential
            features, capabilities, and use cases of this AI model.
          </p>
          <p>
            We strongly recommend exercising caution from making any decisions
            solely based on the results obtained from this demo.
          </p>
          <p className="d-flex">
            <input
              type="checkbox"
              className="me-3"
              onChange={onChangeCheckbox}
              value={checkbox}
              checked={checkbox}
              name="check-disclaimer"
              id="check-disclaimer"
              data-cy="check-disclaimer"
            />
            I understand and acknowledge that by using this demo, the DIMER team
            is not liable for any damages arising from the use of this demo.
          </p>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleModelDemoStartModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              disabled={disabled}
              onClick={handleFormSubmit}
              className="submit-btn button"
              name="start-demo"
              id="start-demo"
              data-cy="start-demo"
            >
              Start demo
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ModelDemoStartModal;
