import { useNavigate } from "react-router-dom";
import "styles/PageNotFound.css";

/**
 * A module for 404 Page Not Found component
 * @module components/PageNotFound
 */

/**
 * 404 Page Not Found component
 * @method PageNotFound
 *
 * @return {JSX.Element}
 *
 */
const PageNotFound = (props) => {
  const navigate = useNavigate();

  /**
   * Navigates to home page
   * @method onClickHome
   */
  const onClickHome = () => {
    navigate("/");
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 d-flex justify-content-center align-items-center vh-100">
          <div>
            <div className="title-404">404</div>
            <div className="page-not-found">PAGE NOT FOUND</div>
            <div className="page-not-found-message my-5">
              The page you are looking for was deleted or might never existed.
            </div>
            <div className="button-404">
              <button
                type="button"
                className="button btn btn-primary px-4"
                onClick={onClickHome}
              >
                Back to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
