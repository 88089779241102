import { useState } from "react";
import { Modal, Button, Spinner, Offcanvas, Form } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch } from "react-redux";
import { deleteModel } from "actions/model";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

/**
 * A module for the DeleteModelModal Component
 * @module components/models/DeleteModelModal
 */

/**
 * Delete model in the system
 * @method DeleteModelModal
 *
 * @return {JSX.Element}
 *
 */
const DeleteModelModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (e) => {
    setLoading(true);
    dispatch(deleteModel(props?.modelID))
      .then((status) => {
        props.setToastImage("/images/remove-success.svg");
        props.setToastStatus("error");
        navigate("/models");
      })
      .catch((status) => {
        if (status === "notif-in-app-network unreachable") {
          console.log("notif-in-app-network unreachable");
          props.setToastStatus("error");
          props.setToastImage("/images/remove-success.svg");
          navigate("/models");
        } else {
          props.setToastStatus(status);
          props.setToastImage(null);
        }
      })
      .finally(() => {
        setLoading(false);
        props.setShowToast(true);
        props.handleDeleteModelModalClose();
      });
  };

  return (
    <>
      <Modal
        show={props.showDeleteModelModal && !isMobile}
        onHide={props.handleDeleteModelModalClose}
        dialogClassName="modal-32w remove-modal"
        centered
        backdrop="static"
        id="confirmation-message-profile"
        data-cy="confirmation-message-profile"
      >
        <Modal.Body>
          <p className="bold">Delete this model profile?</p>
          <p>
            All of the content and files of this model profile will be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props.handleDeleteModelModalClose}
            className="light-btn"
            id="cancel"
            name="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleFormSubmit}
            className="submit-btn"
            disabled={loading}
            id="delete-model-btn"
            name="delete-model-btn"
            data-cy="delete-model-btn"
          >
            Delete
            {loading && (
              <Spinner
                className="spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props.showDeleteModelModal && isMobile}
        onHide={props.handleDeleteModelModalClose}
        placement="bottom"
        className="offcanvas-delete-profile"
        id="confirmation-message-profile"
        data-cy="confirmation-message-profile"
      >
        <Offcanvas.Body>
          <Form>
            <p className="bold">Delete this model profile?</p>
            <p>
              All of the content and files of this model profile will be
              deleted.
            </p>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleDeleteModelModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleFormSubmit}
              className="submit-btn"
              disabled={loading}
              id="delete-model-btn"
              name="delete-model-btn"
              data-cy="delete-model-btn"
            >
              Delete
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DeleteModelModal;
