import { Modal, Button, Form, Tab, Tabs, Offcanvas } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllGroups } from "actions/group";
import { addModel, getAllModels } from "actions/model";
import tasks from "data/tasks.json";
import DiscardChangesModal from "../common-modals/DiscardChangesModal";
import { useMediaQuery } from "react-responsive";
import "styles/mobile/AddModelProfileModalMobile.css";
import Tab1 from "./tabs/profile/add-and-edit/Tab1";
import Tab2 from "./tabs/profile/add-and-edit/Tab2";
import Tab3 from "./tabs/profile/add-and-edit/Tab3";
import Tab4 from "./tabs/profile/add-and-edit/Tab4";
import Tab5 from "./tabs/profile/add-and-edit/Tab5";

/**
 * A module for the AddModelProfileModal Component
 * @module components/layout/AddModelProfileModal
 */

/**
 * Displaying menu in the left side of the system
 * @method AddModelProfileModal
 *
 * @return {JSX.Element}
 *
 */
const AddModelProfileModal = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const { groups } = useSelector((state) => state.group);
  const { user } = useSelector((state) => state.auth);

  const [modelName, setModelName] = useState("");
  const [modelTask, setModelTask] = useState("");
  const [modelTaskOthers, setModelTaskOthers] = useState("");
  const [showModelTaskOthers, setShowModelTaskOthers] = useState(false);
  const [modelDescription, setModelDescription] = useState("<p><br></p>");
  const [optionsAssignedGroup, setOptionsAssignedGroup] = useState([]);
  const [optionsTask, setOptionsTask] = useState([]);
  const [modelAssignedGroup, setModelAssignedGroup] = useState([]);

  // Intended use
  const [modelPrimaryIntendedUses, setModelPrimaryIntendedUses] =
    useState("<p><br></p>");
  const [modelPrimaryIntendedUsers, setModelPrimaryIntendedUsers] =
    useState("<p><br></p>");
  const [modelOutOfScopeUseCases, setModelOutOfScopeUseCases] =
    useState("<p><br></p>");

  // Factors
  const [modelGroups, setModelGroups] = useState("<p><br></p>");
  const [modelInstrumentation, setModelInstrumentation] =
    useState("<p><br></p>");
  const [modelEnvironment, setModelEnvironment] = useState("<p><br></p>");

  // Metrics
  const [modelPerformanceMeasures, setModelPerformanceMeasures] =
    useState("<p><br></p>");
  const [modelDecisionThresholds, setModelDecisionThresholds] =
    useState("<p><br></p>");
  const [modelApproachesToUncertainty, setModelApproachesToUncertainty] =
    useState("<p><br></p>");

  // Considerations & biases
  const [modelData, setModelData] = useState("<p><br></p>");
  const [modelHumanLife, setModelHumanLife] = useState("No");
  const [modelMitigations, setModelMitigations] = useState("<p><br></p>");
  const [modelRisksAndHarms, setModelRisksAndHarms] = useState("<p><br></p>");
  const [modelUseCases, setModelUseCases] = useState("<p><br></p>");

  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [toolbarHiddenModelDescription, setToolbarHiddenModelDescription] =
    useState(true);
  const [
    isToolbarClickedModelDescription,
    setIsToolbarClickedModelDescription,
  ] = useState(false);
  const [
    toolbarHiddenModelPrimaryIntendedUses,
    setToolbarHiddenModelPrimaryIntendedUses,
  ] = useState(true);
  const [
    isToolbarClickedModelPrimaryIntendedUses,
    setIsToolbarClickedModelPrimaryIntendedUses,
  ] = useState(false);
  const [
    toolbarHiddenModelPrimaryIntendedUsers,
    setToolbarHiddenModelPrimaryIntendedUsers,
  ] = useState(true);
  const [
    isToolbarClickedModelPrimaryIntendedUsers,
    setIsToolbarClickedModelPrimaryIntendedUsers,
  ] = useState(false);
  const [
    toolbarHiddenModelOutOfScopeUseCases,
    setToolbarHiddenModelOutOfScopeUseCases,
  ] = useState(true);
  const [
    isToolbarClickedModelOutOfScopeUseCases,
    setIsToolbarClickedModelOutOfScopeUseCases,
  ] = useState(false);
  const [toolbarHiddenModelGroups, setToolbarHiddenModelGroups] =
    useState(true);
  const [isToolbarClickedModelGroups, setIsToolbarClickedModelGroups] =
    useState(false);
  const [
    toolbarHiddenModelInstrumentation,
    setToolbarHiddenModelInstrumentation,
  ] = useState(true);
  const [
    isToolbarClickedModelInstrumentation,
    setIsToolbarClickedModelInstrumentation,
  ] = useState(false);
  const [toolbarHiddenModelEnvironment, setToolbarHiddenModelEnvironment] =
    useState(true);
  const [
    isToolbarClickedModelEnvironment,
    setIsToolbarClickedModelEnvironment,
  ] = useState(false);
  const [
    toolbarHiddenModelPerformanceMeasures,
    setToolbarHiddenModelPerformanceMeasures,
  ] = useState(true);
  const [
    isToolbarClickedModelPerformanceMeasures,
    setIsToolbarClickedModelPerformanceMeasures,
  ] = useState(false);
  const [
    toolbarHiddenModelDecisionThresholds,
    setToolbarHiddenModelDecisionThresholds,
  ] = useState(true);
  const [
    isToolbarClickedModelDecisionThresholds,
    setIsToolbarClickedModelDecisionThresholds,
  ] = useState(false);
  const [
    toolbarHiddenModelApproachesToUncertainty,
    setToolbarHiddenModelApproachesToUncertainty,
  ] = useState(true);
  const [
    isToolbarClickedModelApproachesToUncertainty,
    setIsToolbarClickedModelApproachesToUncertainty,
  ] = useState(false);
  const [toolbarHiddenModelData, setToolbarHiddenModelData] = useState(true);
  const [isToolbarClickedModelData, setIsToolbarClickedModelData] =
    useState(false);
  const [toolbarHiddenModelMitigations, setToolbarHiddenModelMitigations] =
    useState(true);
  const [
    isToolbarClickedModelMitigations,
    setIsToolbarClickedModelMitigations,
  ] = useState(false);
  const [toolbarHiddenModelRisksAndHarms, setToolbarHiddenModelRisksAndHarms] =
    useState(true);
  const [
    isToolbarClickedModelRisksAndHarms,
    setIsToolbarClickedModelRisksAndHarms,
  ] = useState(false);
  const [toolbarHiddenModelUseCases, setToolbarHiddenModelUseCases] =
    useState(true);
  const [isToolbarClickedModelUseCases, setIsToolbarClickedModelUseCases] =
    useState(false);
  const [disabled, setDisabled] = useState(true);
  const [changesMade, setChangesMade] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const withFormattingOptions = [
    "modelDescription",
    "modelPrimaryIntendedUses",
    "modelPrimaryIntendedUsers",
    "modelOutOfScopeUseCases",
    "modelGroups",
    "modelInstrumentation",
    "modelEnvironment",
    "modelPerformanceMeasures",
    "modelDecisionThresholds",
    "modelApproachesToUncertainty",
    "modelData",
    "modelMitigations",
    "modelRisksAndHarms",
    "modelUseCases",
  ];

  useEffect(() => {
    dispatch(getAllGroups()).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const groupsList = Object.values(groups)
      .filter((item) => item.group_owner === user.email)
      .map((item) => {
        return {
          id: item.id,
          name: item.group_name,
          label: item.group_name,
        };
      });
    setOptionsAssignedGroup(groupsList);
  }, [groups]);

  useEffect(() => {
    const tasksList = Object.values(tasks).map((item) => {
      return {
        id: item.id,
        name: item.name,
        label: item.name,
      };
    });
    setOptionsTask(tasksList);
  }, [tasks]);

  const [selectedAssignedGroupOptions, setSelectedAssignedGroupOptions] =
    useState([]);
  const [selectedTaskOptions, setSelectedTaskOptions] = useState([]);

  const handleSelectAssignedGroup = (selected) => {
    setSelectedAssignedGroupOptions(selected);
    validate("modelAssignedGroup", selected[0]?.name);
  };

  const handleSelectTask = (selected) => {
    setSelectedTaskOptions(selected);
    if (selected[0]?.name === "Others") {
      setShowModelTaskOthers(true);
    } else {
      setShowModelTaskOthers(false);
    }
    validate("modelTask", selected[0]?.name);
  };

  // const handleDelete = (deleted) => {
  //   setSelectedAssignedGroupOptions(selectedAssignedGroupOptions.filter((option) => option !== deleted));
  // };

  // const rowRenderer = ({ key, index, style }) => (
  //   <div key={key} style={style}>
  //     {options[index]}
  //   </div>
  // );

  const onChangeModelName = (e) => {
    const value = e.target.value;
    setModelName(value);
    validate("modelName", value);
  };

  // const onChangeModelTask = (e) => {
  //   const value = e.target.value;
  //   setModelTask(value);
  //   validate("modelTask", value);
  // };

  const onInputChangeModelTask = (e) => {
    validate("modelTask", e);
  };

  const onChangeModelTaskOthers = (e) => {
    const value = e.target.value;
    setModelTaskOthers(value);
    validate("modelTaskOthers", value);
  };

  const onChangeModelDescription = (e) => {
    setModelDescription(e);
    validate("modelDescription", e);
  };

  const onInputChangeModelAssignedGroup = (e) => {
    validate("modelAssignedGroup", e);
  };

  const onChangeModelPrimaryIntendedUses = (e) => {
    setModelPrimaryIntendedUses(e);
    validate("modelPrimaryIntendedUses", e);
  };

  const onChangeModelPrimaryIntendedUsers = (e) => {
    setModelPrimaryIntendedUsers(e);
    validate("modelPrimaryIntendedUsers", e);
  };

  const onChangeModelOutOfScopeUseCases = (e) => {
    setModelOutOfScopeUseCases(e);
    validate("modelOutOfScopeUseCases", e);
  };

  const onChangeModelGroups = (e) => {
    setModelGroups(e);
    validate("modelGroups", e);
  };

  const onChangeModelInstrumentation = (e) => {
    setModelInstrumentation(e);
    validate("modelInstrumentation", e);
  };

  const onChangeModelEnvironment = (e) => {
    setModelEnvironment(e);
    validate("modelEnvironment", e);
  };

  const onChangeModelPerformanceMeasures = (e) => {
    setModelPerformanceMeasures(e);
    validate("modelPerformanceMeasures", e);
  };

  const onChangeModelDecisionThresholds = (e) => {
    setModelDecisionThresholds(e);
    validate("modelDecisionThresholds", e);
  };

  const onChangeModelApproachesToUncertainty = (e) => {
    setModelApproachesToUncertainty(e);
    validate("modelApproachesToUncertainty", e);
  };

  const onChangeModelData = (e) => {
    setModelData(e);
    validate("modelData", e);
  };

  const onChangeModelHumanLife = (e) => {
    setModelHumanLife(e.target.value);
    validate("modelHumanLife", e.target.value);
  };

  const onChangeModelMitigations = (e) => {
    setModelMitigations(e);
    validate("modelMitigations", e);
  };

  const onChangeModelRisksAndHarms = (e) => {
    setModelRisksAndHarms(e);
    validate("modelRisksAndHarms", e);
  };

  const onChangeModelUseCases = (e) => {
    setModelUseCases(e);
    validate("modelUseCases", e);
  };

  const handleFormSubmit = (e) => {
    // console.log(selectedAssignedGroupOptions)
    // const emails = selectedAssignedGroupOptions.map((option) => {
    //   const result = Object.values(groups).filter(
    //     (user) => user.email === option.email
    //   );
    //   return result[0].email;
    // });
    dispatch(
      addModel(
        modelName,
        selectedTaskOptions.length === 0
          ? null
          : selectedTaskOptions[0].name === "Others"
          ? "Others - " + modelTaskOthers
          : selectedTaskOptions[0].name,
        modelDescription,
        selectedAssignedGroupOptions.length === 0
          ? null
          : selectedAssignedGroupOptions[0].id,
        modelPrimaryIntendedUses,
        modelPrimaryIntendedUsers,
        modelOutOfScopeUseCases,
        modelGroups,
        modelInstrumentation,
        modelEnvironment,
        modelPerformanceMeasures,
        modelDecisionThresholds,
        modelApproachesToUncertainty,
        modelData,
        modelHumanLife === "Yes" ? true : false,
        modelMitigations,
        modelRisksAndHarms,
        modelUseCases
      )
    )
      .then((status) => {
        props.setToastStatus(status);
        props.setToastImage("/images/model-success.svg");
        setDisabled(true);
        dispatch(getAllModels()).catch((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        });
        if (status !== "error") {
          props.handleModelModalClose();
        }
        resetModalForm();
      })
      .catch((status) => {
        props.setToastImage(null);
        props.setToastStatus(status);
      })
      .finally(() => {
        setDisabled(false);
        props.setShowToast(true);
        setChangesMade(false);
      });
  };

  const resetModalForm = () => {
    setModelName("");
    setSelectedTaskOptions([]);
    setModelTaskOthers("");
    setShowModelTaskOthers(false);
    setModelDescription("<p><br></p>");
    setToolbarHiddenModelDescription(true);
    setSelectedAssignedGroupOptions([]);
    setModelPrimaryIntendedUses("<p><br></p>");
    setToolbarHiddenModelPrimaryIntendedUses(true);
    setModelPrimaryIntendedUsers("<p><br></p>");
    setToolbarHiddenModelPrimaryIntendedUsers(true);
    setModelOutOfScopeUseCases("<p><br></p>");
    setToolbarHiddenModelOutOfScopeUseCases(true);
    setModelGroups("<p><br></p>");
    setToolbarHiddenModelGroups(true);
    setModelInstrumentation("<p><br></p>");
    setToolbarHiddenModelInstrumentation(true);
    setModelEnvironment("<p><br></p>");
    setToolbarHiddenModelEnvironment(true);
    setModelPerformanceMeasures("<p><br></p>");
    setToolbarHiddenModelPerformanceMeasures(true);
    setModelDecisionThresholds("<p><br></p>");
    setToolbarHiddenModelDecisionThresholds(true);
    setModelApproachesToUncertainty("<p><br></p>");
    setToolbarHiddenModelApproachesToUncertainty(true);
    setModelData("<p><br></p>");
    setToolbarHiddenModelData(true);
    setModelHumanLife("No");
    setModelMitigations("<p><br></p>");
    setToolbarHiddenModelMitigations(true);
    setModelRisksAndHarms("<p><br></p>");
    setToolbarHiddenModelRisksAndHarms(true);
    setModelUseCases("<p><br></p>");
    setToolbarHiddenModelUseCases(true);
    setDisabled(true);
    setIsValid({});
    setError({});
    setChangesMade(false);
  };

  useEffect(() => {
    if (changesMade) {
      props.setShowModelModal(true);
      setShowDiscardModal(true);
    }
  }, [props.showModelModal]);

  const handleRemoveAssignedGroupOption = (deleted) => {
    // Implement your logic to handle removing the option
    console.log("Removing option:", deleted);
    setSelectedAssignedGroupOptions(
      selectedAssignedGroupOptions.filter((option) => option !== deleted)
    );
  };

  /**
   *  Validates name and value of the input field
   * @method validate
   * @param {string} name - A string for name of the input field
   * @param {string} value - A string for the value of the input field
   *
   * @return {void}
   */
  const validate = (name, value) => {
    setChangesMade(true);
    // For input with spaces only
    if (value) {
      value = value.toString().trim();
    }
    if (withFormattingOptions.findIndex((field) => name === field) >= 0) {
      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(value, "text/html");
      const textContent = parsedHTML.body.textContent.trim();
      if (textContent === "") {
        value = "";
      } else {
        value = textContent;
      }
    }

    // For required fields
    if (name !== "modelAssignedGroup" && name !== "modelGroups" && !value) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "Please fill out this field" }));
      return;
    }

    if (value) {
      switch (name) {
        case "modelTaskOthers":
          setIsValid(Object.assign(isValid, { [name]: false }));
          // const validateNames = new RegExp("/^[ña-z .'-]+$/i");
          if (!/^[ña-z .-]+$/i.test(value)) {
            setError(
              Object.assign(error, {
                [name]: "Accepts a-z, A-Z, Ñ/ñ, space, -, and period only",
              })
            );
            return;
          }
          break;
        case "modelPrimaryIntendedUses":
        case "modelPrimaryIntendedUsers":
        case "modelOutOfScopeUseCases":
        case "modelGroups":
        case "modelInstrumentation":
        case "modelEnvironment":
        case "modelPerformanceMeasures":
        case "modelDecisionThresholds":
        case "modelApproachesToUncertainty":
        case "modelData":
        case "modelMitigations":
        case "modelRisksAndHarms":
        case "modelUseCases":
          setIsValid(Object.assign(isValid, { [name]: false }));
          // const validateNames = new RegExp("/^[ña-z .'-]+$/i");
          if (!/^[ña-z0-9 .,-]+$/i.test(value)) {
            setError(
              Object.assign(error, {
                [name]:
                  "Accepts a-z, A-Z, Ñ/ñ, 0-9, period, comma, space, and - only",
              })
            );
            return;
          }
          break;
        case "modelName":
        case "modelDescription":
          setIsValid(Object.assign(isValid, { [name]: false }));
          const regexPattern =
            /^[a-zA-Z0-9 Ññ~`!@#\$%\^&*()_\-+={}\[\]|\\:;'"<,>.?\/\s]+$/g;
          if (regexPattern.test(value) === false) {
            setError(
              Object.assign(error, {
                [name]:
                  "Accepts a-z, A-Z, 0-9, Ñ/ñ, space, and special characters like ~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/",
              })
            );
            return;
          }
          break;
        default:
        // code block
      }
    }

    if (value?.length < 2 && value?.length > 0) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Minimum of 2 characters",
        })
      );
      return;
    }

    if (name === "modelTaskOthers" && value?.length > 0 && value?.length > 50) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Maximum of 50 characters",
        })
      );
      return;
    }

    if (
      withFormattingOptions.findIndex((field) => name === field) >= 0 &&
      value?.length > 0 &&
      value?.length > 1000
    ) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Maximum of 1000 characters",
        })
      );
      return;
    }

    if (
      withFormattingOptions.findIndex((field) => name === field) < 0 &&
      value?.length > 0 &&
      value?.length > 100
    ) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Maximum of 100 characters",
        })
      );
      return;
    }

    delete error[name];

    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  /**
   * Setting the toolbar hidden of the text editor based on the name of the input field
   * @method onBlur
   *
   * @param {string} name - A string for the name of the input field
   * @param {event} e - An event object containing information about the action
   */

  const onBlurModelDescription = () => {
    if (!isToolbarClickedModelDescription) {
      setToolbarHiddenModelDescription(true);
    }
  };

  const handleMouseDownModelDescription = () => {
    setIsToolbarClickedModelDescription(true);
  };

  const handleMouseUpModelDescription = () => {
    setIsToolbarClickedModelDescription(false);
  };

  const onFocusModelDescription = () => {
    setToolbarHiddenModelDescription(false);
  };

  const onBlurModelPrimaryIntendedUses = () => {
    if (!isToolbarClickedModelPrimaryIntendedUses) {
      setToolbarHiddenModelPrimaryIntendedUses(true);
    }
  };

  const handleMouseDownModelPrimaryIntendedUses = () => {
    setIsToolbarClickedModelPrimaryIntendedUses(true);
  };

  const handleMouseUpModelPrimaryIntendedUses = () => {
    setIsToolbarClickedModelPrimaryIntendedUses(false);
  };

  const onFocusModelPrimaryIntendedUses = () => {
    setToolbarHiddenModelPrimaryIntendedUses(false);
  };

  const onBlurModelPrimaryIntendedUsers = () => {
    if (!isToolbarClickedModelPrimaryIntendedUsers) {
      setToolbarHiddenModelPrimaryIntendedUsers(true);
    }
  };

  const handleMouseDownModelPrimaryIntendedUsers = () => {
    setIsToolbarClickedModelPrimaryIntendedUsers(true);
  };

  const handleMouseUpModelPrimaryIntendedUsers = () => {
    setIsToolbarClickedModelPrimaryIntendedUsers(false);
  };

  const onFocusModelPrimaryIntendedUsers = () => {
    setToolbarHiddenModelPrimaryIntendedUsers(false);
  };

  const onBlurModelOutOfScopeUseCases = () => {
    if (!isToolbarClickedModelOutOfScopeUseCases) {
      setToolbarHiddenModelOutOfScopeUseCases(true);
    }
  };

  const handleMouseDownModelOutOfScopeUseCases = () => {
    setIsToolbarClickedModelOutOfScopeUseCases(true);
  };

  const handleMouseUpModelOutOfScopeUseCases = () => {
    setIsToolbarClickedModelOutOfScopeUseCases(false);
  };

  const onFocusModelOutOfScopeUseCases = () => {
    setToolbarHiddenModelOutOfScopeUseCases(false);
  };

  const onBlurModelGroups = () => {
    if (!isToolbarClickedModelGroups) {
      setToolbarHiddenModelGroups(true);
    }
  };

  const handleMouseDownModelGroups = () => {
    setIsToolbarClickedModelGroups(true);
  };

  const handleMouseUpModelGroups = () => {
    setIsToolbarClickedModelGroups(false);
  };

  const onFocusModelGroups = () => {
    setToolbarHiddenModelGroups(false);
  };

  const onBlurModelInstrumentation = () => {
    if (!isToolbarClickedModelInstrumentation) {
      setToolbarHiddenModelInstrumentation(true);
    }
  };

  const handleMouseDownModelInstrumentation = () => {
    setIsToolbarClickedModelInstrumentation(true);
  };

  const handleMouseUpModelInstrumentation = () => {
    setIsToolbarClickedModelInstrumentation(false);
  };

  const onFocusModelInstrumentation = () => {
    setToolbarHiddenModelInstrumentation(false);
  };

  const onBlurModelEnvironment = () => {
    if (!isToolbarClickedModelEnvironment) {
      setToolbarHiddenModelEnvironment(true);
    }
  };

  const handleMouseDownModelEnvironment = () => {
    setIsToolbarClickedModelEnvironment(true);
  };

  const handleMouseUpModelEnvironment = () => {
    setIsToolbarClickedModelEnvironment(false);
  };

  const onFocusModelEnvironment = () => {
    setToolbarHiddenModelEnvironment(false);
  };

  const onBlurModelPerformanceMeasures = () => {
    if (!isToolbarClickedModelPerformanceMeasures) {
      setToolbarHiddenModelPerformanceMeasures(true);
    }
  };

  const handleMouseDownModelPerformanceMeasures = () => {
    setIsToolbarClickedModelPerformanceMeasures(true);
  };

  const handleMouseUpModelPerformanceMeasures = () => {
    setIsToolbarClickedModelPerformanceMeasures(false);
  };

  const onFocusModelPerformanceMeasures = () => {
    setToolbarHiddenModelPerformanceMeasures(false);
  };

  const onBlurModelDecisionThresholds = () => {
    if (!isToolbarClickedModelDecisionThresholds) {
      setToolbarHiddenModelDecisionThresholds(true);
    }
  };

  const handleMouseDownModelDecisionThresholds = () => {
    setIsToolbarClickedModelDecisionThresholds(true);
  };

  const handleMouseUpModelDecisionThresholds = () => {
    setIsToolbarClickedModelDecisionThresholds(false);
  };

  const onFocusModelDecisionThresholds = () => {
    setToolbarHiddenModelDecisionThresholds(false);
  };

  const onBlurModelApproachesToUncertainty = () => {
    if (!isToolbarClickedModelApproachesToUncertainty) {
      setToolbarHiddenModelApproachesToUncertainty(true);
    }
  };

  const handleMouseDownModelApproachesToUncertainty = () => {
    setIsToolbarClickedModelApproachesToUncertainty(true);
  };

  const handleMouseUpModelApproachesToUncertainty = () => {
    setIsToolbarClickedModelApproachesToUncertainty(false);
  };

  const onFocusModelApproachesToUncertainty = () => {
    setToolbarHiddenModelApproachesToUncertainty(false);
  };

  const onBlurModelData = () => {
    if (!isToolbarClickedModelData) {
      setToolbarHiddenModelData(true);
    }
  };

  const handleMouseDownModelData = () => {
    setIsToolbarClickedModelData(true);
  };

  const handleMouseUpModelData = () => {
    setIsToolbarClickedModelData(false);
  };

  const onFocusModelData = () => {
    setToolbarHiddenModelData(false);
  };

  const onBlurModelMitigations = () => {
    if (!isToolbarClickedModelMitigations) {
      setToolbarHiddenModelMitigations(true);
    }
  };

  const handleMouseDownModelMitigations = () => {
    setIsToolbarClickedModelMitigations(true);
  };

  const handleMouseUpModelMitigations = () => {
    setIsToolbarClickedModelMitigations(false);
  };

  const onFocusModelMitigations = () => {
    setToolbarHiddenModelMitigations(false);
  };

  const onBlurModelRisksAndHarms = () => {
    if (!isToolbarClickedModelRisksAndHarms) {
      setToolbarHiddenModelRisksAndHarms(true);
    }
  };

  const handleMouseDownModelRisksAndHarms = () => {
    setIsToolbarClickedModelRisksAndHarms(true);
  };

  const handleMouseUpModelRisksAndHarms = () => {
    setIsToolbarClickedModelRisksAndHarms(false);
  };

  const onFocusModelRisksAndHarms = () => {
    setToolbarHiddenModelRisksAndHarms(false);
  };

  const onBlurModelUseCases = () => {
    if (!isToolbarClickedModelUseCases) {
      setToolbarHiddenModelUseCases(true);
    }
  };

  const handleMouseDownModelUseCases = () => {
    setIsToolbarClickedModelUseCases(true);
  };

  const handleMouseUpModelUseCases = () => {
    setIsToolbarClickedModelUseCases(false);
  };

  const onFocusModelUseCases = () => {
    setToolbarHiddenModelUseCases(false);
  };

  useEffect(() => {
    if (
      modelName !== "" &&
      selectedTaskOptions.length >= 0 &&
      modelDescription !== "<p><br></p>" &&
      modelPrimaryIntendedUses !== "<p><br></p>" &&
      modelPrimaryIntendedUsers !== "<p><br></p>" &&
      modelOutOfScopeUseCases !== "<p><br></p>" &&
      modelInstrumentation !== "<p><br></p>" &&
      modelEnvironment !== "<p><br></p>" &&
      modelPerformanceMeasures !== "<p><br></p>" &&
      modelDecisionThresholds !== "<p><br></p>" &&
      modelApproachesToUncertainty !== "<p><br></p>" &&
      modelData !== "<p><br></p>" &&
      modelHumanLife !== "" &&
      modelMitigations !== "<p><br></p>" &&
      modelRisksAndHarms !== "<p><br></p>" &&
      modelUseCases !== "<p><br></p>" &&
      Object.keys(error).length === 0
    ) {
      setDisabled(false);
      if (selectedTaskOptions[0]?.name === "Others" && modelTaskOthers === "") {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [
    modelName,
    selectedTaskOptions,
    modelTaskOthers,
    modelDescription,
    selectedAssignedGroupOptions,
    modelPrimaryIntendedUses,
    modelPrimaryIntendedUsers,
    modelOutOfScopeUseCases,
    modelGroups,
    modelInstrumentation,
    modelEnvironment,
    modelPerformanceMeasures,
    modelDecisionThresholds,
    modelApproachesToUncertainty,
    modelData,
    modelHumanLife,
    modelMitigations,
    modelRisksAndHarms,
    modelUseCases,
  ]);

  // List of ref for the element to scroll to the top
  const elementRef1 = useRef(null);
  const elementRef2 = useRef(null);
  const elementRef3 = useRef(null);
  const elementRef4 = useRef(null);
  const elementRef5 = useRef(null);

  const scrollToTop = () => {
    // Use the current property of the ref to get the DOM node
    const element1 = elementRef1.current;
    const element2 = elementRef2.current;
    const element3 = elementRef3.current;
    const element4 = elementRef4.current;
    const element5 = elementRef5.current;

    // Scroll to the top of the element
    element1.scrollTop = 0;
    element2.scrollTop = 0;
    element3.scrollTop = 0;
    element4.scrollTop = 0;
    element5.scrollTop = 0;
  };

  const handleDiscardModalClose = () => {
    setShowDiscardModal(false);
  };

  const onClickDiscard = () => {
    handleDiscardModalClose();
    props.handleModelModalClose();
    resetModalForm();
  };

  const onClickGoBack = () => {
    handleDiscardModalClose();
    props.setShowModelModal(true);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      props.setShowModelModal(true);
      setShowDiscardModal(true);
      event.preventDefault();
      event.returnValue = ""; // Standard for most browsers
    };

    if (changesMade) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [changesMade]);

  return (
    <>
      <DiscardChangesModal
        showDiscardModal={showDiscardModal}
        onClickGoBack={onClickGoBack}
        onClickDiscard={onClickDiscard}
      />
      <Modal
        show={props.showModelModal && !isMobile}
        onHide={props.handleModelModalClose}
        dialogClassName="modal-50w"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title
            className="bold ms-3"
            id="modal-title"
            data-cy="modal-title"
          >
            Add a Model Profile
          </Modal.Title>
          <Button
            variant="close"
            onClick={props.handleModelModalClose}
            name="close-btn"
            id="close-btn"
            data-cy="close-btn"
          />
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <Form>
            <p className="required-field ms-5">
              Required fields are marked with an asterisk
            </p>
            <Tabs
              defaultActiveKey="introduction"
              onClick={scrollToTop}
              className="mb-3"
            >
              <Tab
                eventKey="introduction"
                tabClassName="tabs ms-5"
                title="Introduction"
                tabAttrs={{ "data-cy": "introduction-tab" }}
              >
                <Tab1
                  disableAssignedGroup={false}
                  elementRef1={elementRef1}
                  modelName={modelName}
                  onChangeModelName={onChangeModelName}
                  isValid={isValid}
                  getFormErrorMessage={getFormErrorMessage}
                  optionsTask={optionsTask}
                  selectedTaskOptions={selectedTaskOptions}
                  handleSelectTask={handleSelectTask}
                  onInputChangeModelTask={onInputChangeModelTask}
                  showModelTaskOthers={showModelTaskOthers}
                  modelTaskOthers={modelTaskOthers}
                  onChangeModelTaskOthers={onChangeModelTaskOthers}
                  handleMouseDownModelDescription={
                    handleMouseDownModelDescription
                  }
                  handleMouseUpModelDescription={handleMouseUpModelDescription}
                  onBlurModelDescription={onBlurModelDescription}
                  onFocusModelDescription={onFocusModelDescription}
                  modelDescription={modelDescription}
                  onChangeModelDescription={onChangeModelDescription}
                  toolbarHiddenModelDescription={toolbarHiddenModelDescription}
                  modelCreatedBy={props?.modelCreatedBy}
                  optionsAssignedGroup={optionsAssignedGroup}
                  selectedAssignedGroupOptions={selectedAssignedGroupOptions}
                  handleSelectAssignedGroup={handleSelectAssignedGroup}
                  onInputChangeModelAssignedGroup={
                    onInputChangeModelAssignedGroup
                  }
                  user={user}
                />
              </Tab>
              <Tab
                eventKey="intended-use"
                tabClassName="tabs"
                title="Intended use"
                tabAttrs={{ "data-cy": "intended-use-tab" }}
              >
                <Tab2
                  elementRef2={elementRef2}
                  handleMouseDownModelPrimaryIntendedUses={
                    handleMouseDownModelPrimaryIntendedUses
                  }
                  handleMouseUpModelPrimaryIntendedUses={
                    handleMouseUpModelPrimaryIntendedUses
                  }
                  onBlurModelPrimaryIntendedUses={
                    onBlurModelPrimaryIntendedUses
                  }
                  onFocusModelPrimaryIntendedUses={
                    onFocusModelPrimaryIntendedUses
                  }
                  modelPrimaryIntendedUses={modelPrimaryIntendedUses}
                  onChangeModelPrimaryIntendedUses={
                    onChangeModelPrimaryIntendedUses
                  }
                  isValid={isValid}
                  toolbarHiddenModelPrimaryIntendedUses={
                    toolbarHiddenModelPrimaryIntendedUses
                  }
                  getFormErrorMessage={getFormErrorMessage}
                  handleMouseDownModelPrimaryIntendedUsers={
                    handleMouseDownModelPrimaryIntendedUsers
                  }
                  handleMouseUpModelPrimaryIntendedUsers={
                    handleMouseUpModelPrimaryIntendedUsers
                  }
                  onBlurModelPrimaryIntendedUsers={
                    onBlurModelPrimaryIntendedUsers
                  }
                  onFocusModelPrimaryIntendedUsers={
                    onFocusModelPrimaryIntendedUsers
                  }
                  modelPrimaryIntendedUsers={modelPrimaryIntendedUsers}
                  onChangeModelPrimaryIntendedUsers={
                    onChangeModelPrimaryIntendedUsers
                  }
                  toolbarHiddenModelPrimaryIntendedUsers={
                    toolbarHiddenModelPrimaryIntendedUsers
                  }
                  handleMouseDownModelOutOfScopeUseCases={
                    handleMouseDownModelOutOfScopeUseCases
                  }
                  handleMouseUpModelOutOfScopeUseCases={
                    handleMouseUpModelOutOfScopeUseCases
                  }
                  onBlurModelOutOfScopeUseCases={onBlurModelOutOfScopeUseCases}
                  onFocusModelOutOfScopeUseCases={
                    onFocusModelOutOfScopeUseCases
                  }
                  modelOutOfScopeUseCases={modelOutOfScopeUseCases}
                  onChangeModelOutOfScopeUseCases={
                    onChangeModelOutOfScopeUseCases
                  }
                  toolbarHiddenModelOutOfScopeUseCases={
                    toolbarHiddenModelOutOfScopeUseCases
                  }
                />
              </Tab>
              <Tab
                eventKey="factors"
                tabClassName="tabs"
                title="Factors"
                tabAttrs={{ "data-cy": "factors-tab" }}
              >
                <Tab3
                  elementRef3={elementRef3}
                  handleMouseDownModelGroups={handleMouseDownModelGroups}
                  handleMouseUpModelGroups={handleMouseUpModelGroups}
                  onBlurModelGroups={onBlurModelGroups}
                  onFocusModelGroups={onFocusModelGroups}
                  modelGroups={modelGroups}
                  onChangeModelGroups={onChangeModelGroups}
                  isValid={isValid}
                  toolbarHiddenModelGroups={toolbarHiddenModelGroups}
                  getFormErrorMessage={getFormErrorMessage}
                  handleMouseDownModelInstrumentation={
                    handleMouseDownModelInstrumentation
                  }
                  handleMouseUpModelInstrumentation={
                    handleMouseUpModelInstrumentation
                  }
                  onBlurModelInstrumentation={onBlurModelInstrumentation}
                  onFocusModelInstrumentation={onFocusModelInstrumentation}
                  modelInstrumentation={modelInstrumentation}
                  onChangeModelInstrumentation={onChangeModelInstrumentation}
                  toolbarHiddenModelInstrumentation={
                    toolbarHiddenModelInstrumentation
                  }
                  handleMouseDownModelEnvironment={
                    handleMouseDownModelEnvironment
                  }
                  handleMouseUpModelEnvironment={handleMouseUpModelEnvironment}
                  onBlurModelEnvironment={onBlurModelEnvironment}
                  onFocusModelEnvironment={onFocusModelEnvironment}
                  modelEnvironment={modelEnvironment}
                  onChangeModelEnvironment={onChangeModelEnvironment}
                  toolbarHiddenModelEnvironment={toolbarHiddenModelEnvironment}
                />
              </Tab>
              <Tab
                eventKey="metrics"
                tabClassName="tabs"
                title="Metrics"
                tabAttrs={{ "data-cy": "metrics-tab" }}
              >
                <Tab4
                  elementRef4={elementRef4}
                  handleMouseDownModelPerformanceMeasures={
                    handleMouseDownModelPerformanceMeasures
                  }
                  handleMouseUpModelPerformanceMeasures={
                    handleMouseUpModelPerformanceMeasures
                  }
                  onBlurModelPerformanceMeasures={
                    onBlurModelPerformanceMeasures
                  }
                  onFocusModelPerformanceMeasures={
                    onFocusModelPerformanceMeasures
                  }
                  modelPerformanceMeasures={modelPerformanceMeasures}
                  onChangeModelPerformanceMeasures={
                    onChangeModelPerformanceMeasures
                  }
                  isValid={isValid}
                  toolbarHiddenModelPerformanceMeasures={
                    toolbarHiddenModelPerformanceMeasures
                  }
                  getFormErrorMessage={getFormErrorMessage}
                  handleMouseDownModelDecisionThresholds={
                    handleMouseDownModelDecisionThresholds
                  }
                  handleMouseUpModelDecisionThresholds={
                    handleMouseUpModelDecisionThresholds
                  }
                  onBlurModelDecisionThresholds={onBlurModelDecisionThresholds}
                  onFocusModelDecisionThresholds={
                    onFocusModelDecisionThresholds
                  }
                  modelDecisionThresholds={modelDecisionThresholds}
                  onChangeModelDecisionThresholds={
                    onChangeModelDecisionThresholds
                  }
                  toolbarHiddenModelDecisionThresholds={
                    toolbarHiddenModelDecisionThresholds
                  }
                  handleMouseDownModelApproachesToUncertainty={
                    handleMouseDownModelApproachesToUncertainty
                  }
                  handleMouseUpModelApproachesToUncertainty={
                    handleMouseUpModelApproachesToUncertainty
                  }
                  onBlurModelApproachesToUncertainty={
                    onBlurModelApproachesToUncertainty
                  }
                  onFocusModelApproachesToUncertainty={
                    onFocusModelApproachesToUncertainty
                  }
                  modelApproachesToUncertainty={modelApproachesToUncertainty}
                  onChangeModelApproachesToUncertainty={
                    onChangeModelApproachesToUncertainty
                  }
                  toolbarHiddenModelApproachesToUncertainty={
                    toolbarHiddenModelApproachesToUncertainty
                  }
                />
              </Tab>
              <Tab
                eventKey="considerations-and-biases"
                tabClassName="tabs"
                title="Considerations & biases"
                tabAttrs={{ "data-cy": "biases-tab" }}
              >
                <Tab5
                  elementRef5={elementRef5}
                  handleMouseDownModelData={handleMouseDownModelData}
                  handleMouseUpModelData={handleMouseUpModelData}
                  onBlurModelData={onBlurModelData}
                  onFocusModelData={onFocusModelData}
                  modelData={modelData}
                  onChangeModelData={onChangeModelData}
                  isValid={isValid}
                  toolbarHiddenModelData={toolbarHiddenModelData}
                  getFormErrorMessage={getFormErrorMessage}
                  modelHumanLife={modelHumanLife}
                  onChangeModelHumanLife={onChangeModelHumanLife}
                  handleMouseDownModelMitigations={
                    handleMouseDownModelMitigations
                  }
                  handleMouseUpModelMitigations={handleMouseUpModelMitigations}
                  onBlurModelMitigations={onBlurModelMitigations}
                  onFocusModelMitigations={onFocusModelMitigations}
                  modelMitigations={modelMitigations}
                  onChangeModelMitigations={onChangeModelMitigations}
                  toolbarHiddenModelMitigations={toolbarHiddenModelMitigations}
                  handleMouseDownModelRisksAndHarms={
                    handleMouseDownModelRisksAndHarms
                  }
                  handleMouseUpModelRisksAndHarms={
                    handleMouseUpModelRisksAndHarms
                  }
                  onBlurModelRisksAndHarms={onBlurModelRisksAndHarms}
                  onFocusModelRisksAndHarms={onFocusModelRisksAndHarms}
                  modelRisksAndHarms={modelRisksAndHarms}
                  onChangeModelRisksAndHarms={onChangeModelRisksAndHarms}
                  toolbarHiddenModelRisksAndHarms={
                    toolbarHiddenModelRisksAndHarms
                  }
                  handleMouseDownModelUseCases={handleMouseDownModelUseCases}
                  handleMouseUpModelUseCases={handleMouseUpModelUseCases}
                  onBlurModelUseCases={onBlurModelUseCases}
                  onFocusModelUseCases={onFocusModelUseCases}
                  modelUseCases={modelUseCases}
                  onChangeModelUseCases={onChangeModelUseCases}
                  toolbarHiddenModelUseCases={toolbarHiddenModelUseCases}
                />
              </Tab>
            </Tabs>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props.handleModelModalClose}
            className="light-btn"
            name="cancel"
            id="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleFormSubmit}
            disabled={disabled}
            className="submit-btn button"
            name="add-model"
            id="add-model"
            data-cy="add-model"
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props.showModelModal && isMobile}
        onHide={() => props.setShowModelModal(false)}
        placement="bottom"
        className="offcanvas-add-model-profile"
      >
        <Offcanvas.Body className="profile-offcanvas">
          <Form>
            <p className="bold" id="modal-title">Add a Model Profile</p>
            <p className="required-field">
              Required fields are marked with an asterisk
            </p>
            <div className="add-model-profile-form">
              <Tab1
                disableAssignedGroup={false}
                elementRef1={elementRef1}
                modelName={modelName}
                onChangeModelName={onChangeModelName}
                isValid={isValid}
                getFormErrorMessage={getFormErrorMessage}
                optionsTask={optionsTask}
                selectedTaskOptions={selectedTaskOptions}
                handleSelectTask={handleSelectTask}
                onInputChangeModelTask={onInputChangeModelTask}
                modelTaskOthers={modelTaskOthers}
                onChangeModelTaskOthers={onChangeModelTaskOthers}
                handleMouseDownModelDescription={
                  handleMouseDownModelDescription
                }
                handleMouseUpModelDescription={handleMouseUpModelDescription}
                onBlurModelDescription={onBlurModelDescription}
                onFocusModelDescription={onFocusModelDescription}
                modelDescription={modelDescription}
                onChangeModelDescription={onChangeModelDescription}
                toolbarHiddenModelDescription={toolbarHiddenModelDescription}
                optionsAssignedGroup={optionsAssignedGroup}
                selectedAssignedGroupOptions={selectedAssignedGroupOptions}
                handleSelectAssignedGroup={handleSelectAssignedGroup}
                onInputChangeModelAssignedGroup={
                  onInputChangeModelAssignedGroup
                }
              />
              <Tab2
                elementRef2={elementRef2}
                handleMouseDownModelPrimaryIntendedUses={
                  handleMouseDownModelPrimaryIntendedUses
                }
                handleMouseUpModelPrimaryIntendedUses={
                  handleMouseUpModelPrimaryIntendedUses
                }
                onBlurModelPrimaryIntendedUses={onBlurModelPrimaryIntendedUses}
                onFocusModelPrimaryIntendedUses={
                  onFocusModelPrimaryIntendedUses
                }
                modelPrimaryIntendedUses={modelPrimaryIntendedUses}
                onChangeModelPrimaryIntendedUses={
                  onChangeModelPrimaryIntendedUses
                }
                isValid={isValid}
                toolbarHiddenModelPrimaryIntendedUses={
                  toolbarHiddenModelPrimaryIntendedUses
                }
                getFormErrorMessage={getFormErrorMessage}
                handleMouseDownModelPrimaryIntendedUsers={
                  handleMouseDownModelPrimaryIntendedUsers
                }
                handleMouseUpModelPrimaryIntendedUsers={
                  handleMouseUpModelPrimaryIntendedUsers
                }
                onBlurModelPrimaryIntendedUsers={
                  onBlurModelPrimaryIntendedUsers
                }
                onFocusModelPrimaryIntendedUsers={
                  onFocusModelPrimaryIntendedUsers
                }
                modelPrimaryIntendedUsers={modelPrimaryIntendedUsers}
                onChangeModelPrimaryIntendedUsers={
                  onChangeModelPrimaryIntendedUsers
                }
                toolbarHiddenModelPrimaryIntendedUsers={
                  toolbarHiddenModelPrimaryIntendedUsers
                }
                handleMouseDownModelOutOfScopeUseCases={
                  handleMouseDownModelOutOfScopeUseCases
                }
                handleMouseUpModelOutOfScopeUseCases={
                  handleMouseUpModelOutOfScopeUseCases
                }
                onBlurModelOutOfScopeUseCases={onBlurModelOutOfScopeUseCases}
                onFocusModelOutOfScopeUseCases={onFocusModelOutOfScopeUseCases}
                modelOutOfScopeUseCases={modelOutOfScopeUseCases}
                onChangeModelOutOfScopeUseCases={
                  onChangeModelOutOfScopeUseCases
                }
                toolbarHiddenModelOutOfScopeUseCases={
                  toolbarHiddenModelOutOfScopeUseCases
                }
              />
              <Tab3
                elementRef3={elementRef3}
                handleMouseDownModelGroups={handleMouseDownModelGroups}
                handleMouseUpModelGroups={handleMouseUpModelGroups}
                onBlurModelGroups={onBlurModelGroups}
                onFocusModelGroups={onFocusModelGroups}
                modelGroups={modelGroups}
                onChangeModelGroups={onChangeModelGroups}
                isValid={isValid}
                toolbarHiddenModelGroups={toolbarHiddenModelGroups}
                getFormErrorMessage={getFormErrorMessage}
                handleMouseDownModelInstrumentation={
                  handleMouseDownModelInstrumentation
                }
                handleMouseUpModelInstrumentation={
                  handleMouseUpModelInstrumentation
                }
                onBlurModelInstrumentation={onBlurModelInstrumentation}
                onFocusModelInstrumentation={onFocusModelInstrumentation}
                modelInstrumentation={modelInstrumentation}
                onChangeModelInstrumentation={onChangeModelInstrumentation}
                toolbarHiddenModelInstrumentation={
                  toolbarHiddenModelInstrumentation
                }
                handleMouseDownModelEnvironment={
                  handleMouseDownModelEnvironment
                }
                handleMouseUpModelEnvironment={handleMouseUpModelEnvironment}
                onBlurModelEnvironment={onBlurModelEnvironment}
                onFocusModelEnvironment={onFocusModelEnvironment}
                modelEnvironment={modelEnvironment}
                onChangeModelEnvironment={onChangeModelEnvironment}
                toolbarHiddenModelEnvironment={toolbarHiddenModelEnvironment}
              />

              <Tab4
                elementRef4={elementRef4}
                handleMouseDownModelPerformanceMeasures={
                  handleMouseDownModelPerformanceMeasures
                }
                handleMouseUpModelPerformanceMeasures={
                  handleMouseUpModelPerformanceMeasures
                }
                onBlurModelPerformanceMeasures={onBlurModelPerformanceMeasures}
                onFocusModelPerformanceMeasures={
                  onFocusModelPerformanceMeasures
                }
                modelPerformanceMeasures={modelPerformanceMeasures}
                onChangeModelPerformanceMeasures={
                  onChangeModelPerformanceMeasures
                }
                isValid={isValid}
                toolbarHiddenModelPerformanceMeasures={
                  toolbarHiddenModelPerformanceMeasures
                }
                getFormErrorMessage={getFormErrorMessage}
                handleMouseDownModelDecisionThresholds={
                  handleMouseDownModelDecisionThresholds
                }
                handleMouseUpModelDecisionThresholds={
                  handleMouseUpModelDecisionThresholds
                }
                onBlurModelDecisionThresholds={onBlurModelDecisionThresholds}
                onFocusModelDecisionThresholds={onFocusModelDecisionThresholds}
                modelDecisionThresholds={modelDecisionThresholds}
                onChangeModelDecisionThresholds={
                  onChangeModelDecisionThresholds
                }
                toolbarHiddenModelDecisionThresholds={
                  toolbarHiddenModelDecisionThresholds
                }
                handleMouseDownModelApproachesToUncertainty={
                  handleMouseDownModelApproachesToUncertainty
                }
                handleMouseUpModelApproachesToUncertainty={
                  handleMouseUpModelApproachesToUncertainty
                }
                onBlurModelApproachesToUncertainty={
                  onBlurModelApproachesToUncertainty
                }
                onFocusModelApproachesToUncertainty={
                  onFocusModelApproachesToUncertainty
                }
                modelApproachesToUncertainty={modelApproachesToUncertainty}
                onChangeModelApproachesToUncertainty={
                  onChangeModelApproachesToUncertainty
                }
                toolbarHiddenModelApproachesToUncertainty={
                  toolbarHiddenModelApproachesToUncertainty
                }
              />

              <Tab5
                elementRef5={elementRef5}
                handleMouseDownModelData={handleMouseDownModelData}
                handleMouseUpModelData={handleMouseUpModelData}
                onBlurModelData={onBlurModelData}
                onFocusModelData={onFocusModelData}
                modelData={modelData}
                onChangeModelData={onChangeModelData}
                isValid={isValid}
                toolbarHiddenModelData={toolbarHiddenModelData}
                getFormErrorMessage={getFormErrorMessage}
                modelHumanLife={modelHumanLife}
                onChangeModelHumanLife={onChangeModelHumanLife}
                handleMouseDownModelMitigations={
                  handleMouseDownModelMitigations
                }
                handleMouseUpModelMitigations={handleMouseUpModelMitigations}
                onBlurModelMitigations={onBlurModelMitigations}
                onFocusModelMitigations={onFocusModelMitigations}
                modelMitigations={modelMitigations}
                onChangeModelMitigations={onChangeModelMitigations}
                toolbarHiddenModelMitigations={toolbarHiddenModelMitigations}
                handleMouseDownModelRisksAndHarms={
                  handleMouseDownModelRisksAndHarms
                }
                handleMouseUpModelRisksAndHarms={
                  handleMouseUpModelRisksAndHarms
                }
                onBlurModelRisksAndHarms={onBlurModelRisksAndHarms}
                onFocusModelRisksAndHarms={onFocusModelRisksAndHarms}
                modelRisksAndHarms={modelRisksAndHarms}
                onChangeModelRisksAndHarms={onChangeModelRisksAndHarms}
                toolbarHiddenModelRisksAndHarms={
                  toolbarHiddenModelRisksAndHarms
                }
                handleMouseDownModelUseCases={handleMouseDownModelUseCases}
                handleMouseUpModelUseCases={handleMouseUpModelUseCases}
                onBlurModelUseCases={onBlurModelUseCases}
                onFocusModelUseCases={onFocusModelUseCases}
                modelUseCases={modelUseCases}
                onChangeModelUseCases={onChangeModelUseCases}
                toolbarHiddenModelUseCases={toolbarHiddenModelUseCases}
              />
            </div>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleModelModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleFormSubmit}
              disabled={disabled}
              className="submit-btn button"
              name="add-model"
              id="add-model"
              data-cy="add-model"
            >
              Add
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AddModelProfileModal;
