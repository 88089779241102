import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import { modules, formats } from "common/constants";
import { useRef, useEffect } from "react";

const Tab3 = (props) => {
  const factorsGroupRef = useRef();
  const instrumentationRef = useRef();
  const environmentRef = useRef();

  useEffect(() => {
    // Add data-cy attribute to the Quill editor root div after it's mounted
    if (factorsGroupRef.current) {
      const editorDiv = factorsGroupRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "factors-group");
    }

    if (instrumentationRef.current) {
      const editorDiv = instrumentationRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "instrumentation");
    }

    if (environmentRef.current) {
      const editorDiv = environmentRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "environment");
    }
  }, []);

  return (
    <div className="tab-div" ref={props.elementRef3}>
      <div>
        <span className="bold">Factors</span>
        <p>
          This section describes the demographic or phenotypic groups,
          environmental conditions, technical attributes, and instrumentation
          conditions that were considered during model development.
        </p>
      </div>
      <Form.Group
        onMouseDown={props.handleMouseDownModelGroups}
        onMouseUp={props.handleMouseUpModelGroups}
        onBlur={props.onBlurModelGroups}
        onFocus={props.onFocusModelGroups}
      >
        <Form.Label>Groups</Form.Label>
        <ReactQuill
          ref={factorsGroupRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelGroups}
          onChange={props.onChangeModelGroups}
          className={`input-field input-description form-control ${
            props.isValid?.modelGroups
              ? "is-valid"
              : props.isValid.modelGroups !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelGroups
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="factors-group"
        />
        {props.getFormErrorMessage("modelGroups")}
        <span className="sub-note">
          Provide the distinct categories with similar characteristics that are
          presnet in the evaluation of data intances. For human-centric machine
          learning model, "groups" are people who share one or multiple
          characteristics. For human-centric computer vision models, the visual
          presentation of age, gender, and skin type may be relevant.
        </span>
      </Form.Group>
      <Form.Group
        onMouseDown={props.handleMouseDownModelInstrumentation}
        onMouseUp={props.handleMouseUpModelInstrumentation}
        onBlur={props.onBlurModelInstrumentation}
        onFocus={props.onFocusModelInstrumentation}
      >
        <Form.Label className="required-field">Instrumentation</Form.Label>
        <ReactQuill
          ref={instrumentationRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelInstrumentation}
          onChange={props.onChangeModelInstrumentation}
          className={`input-field input-description form-control ${
            props.isValid?.modelInstrumentation
              ? "is-valid"
              : props.isValid.modelInstrumentation !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelInstrumentation
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="instrumentration"
        />
        {props.getFormErrorMessage("modelInstrumentation")}
        <span className="sub-note">
          Provide information about the instruments that were used to capture
          the training and evaluation datasets for model development. For
          example, for a face detection model, the instruments may include the
          type of camera used, type of lens, image stabilization method or
          dynamic range techniques used in the camera's software.
        </span>
      </Form.Group>
      <Form.Group
        onMouseDown={props.handleMouseDownModelEnvironment}
        onMouseUp={props.handleMouseUpModelEnvironment}
        onBlur={props.onBlurModelEnvironment}
        onFocus={props.onFocusModelEnvironment}
      >
        <Form.Label className="required-field">Environment</Form.Label>
        <ReactQuill
        ref={environmentRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelEnvironment}
          onChange={props.onChangeModelEnvironment}
          className={`input-field input-description form-control ${
            props.isValid?.modelEnvironment
              ? "is-valid"
              : props.isValid.modelEnvironment !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelEnvironment
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="environment"
        />
        {props.getFormErrorMessage("modelEnvironment")}
        <span className="sub-note">
          Provide information about the model's performance with respect to the
          different environmental settings considered in the development of the
          model. For example, environment settings may include level of
          humidity, amout of light, and type of light in the environment in
          which training and evaluation datasets have been captured.
        </span>
      </Form.Group>
    </div>
  );
};

export default Tab3;
