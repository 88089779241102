import GroupService from "services/group.service";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  ACTIVATE_SUCCESS,
  ACTIVATE_FAIL,
  RESEND_ACTIVATION_SUCCESS,
  RESEND_ACTIVATION_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  UPDATE_PROFILE_SUCCESS,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
} from "./types";

import AuthService from "services/auth.service";

/**
 * A module that sends success and error response action for auth-related methods
 * @module actions/auth
 */

/**
 * User registration
 * success and error response action
 *
 *
 * @method register
 *
 * @param {string} firstname - A string for the user first name
 * @param {string} middlename - A string for the user middle name
 * @param {string} lastname - A string for the user last name
 * @param {string} designation - A string for the user designation or position
 * @param {string} email - A string for the user email address
 * @param {string} organization - A string for the user organization name
 * @param {string} division - A string for the user division, section, or unit name in the organization
 * @param {string} orgAddressBuilding - A string for the user building address of the organization
 * @param {string} orgAddressStreet - A string for the user street address of the organization
 * @param {string} orgAddressSubdivision - A string for the user subdivision address of the organization
 * @param {string} orgAddressBarangay - A string for the user barangay address of the organization
 * @param {string} orgAddressMunicipality -A string for the user municipality address of the organization
 * @param {string} orgAddressProvince - A string for the user province address of the organization
 * @param {string} orgAddressZipCode - A string for the user zip code address of the organization
 * @param {string} password - A string for the user password
 * @param {string} confirmPassword - A string for the user confirm password for matching and checking
 *
 *
 * @return {Promise}
 *
 */
export const register =
  (
    firstname,
    middlename,
    lastname,
    suffix,
    orcidID,
    orgAcronym,
    organization,
    designation,
    division,
    orgAddressProvince,
    orgAddressMunicipality,
    email,
    password
  ) =>
  (dispatch) => {
    return AuthService.register(
      firstname,
      middlename,
      lastname,
      suffix,
      orcidID,
      orgAcronym,
      organization,
      designation,
      division,
      orgAddressProvince,
      orgAddressMunicipality,
      email,
      password
    ).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });

        response.message = "User successfully registered";

        dispatch({
          type: SET_MESSAGE,
          payload: response.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        const data =
          (error.response && error.response.data) ||
          error.data ||
          error.toString();

        dispatch({
          type: REGISTER_FAIL,
        });

        if (data.email) {
          dispatch({
            type: SET_MESSAGE,
            payload: data.email[0],
          });
        } else if (data.password) {
          dispatch({
            type: SET_MESSAGE,
            payload: data.password[0],
          });
        } else {
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
        }

        return Promise.reject("error");
      }
    );
  };

/**
 * Account activation
 * success and error response action
 *
 *
 * @method activate
 *
 * @param {string} uid - A string for the user ID
 * @param {string} token - A string for JWT token
 *
 *
 * @return {Promise}
 *
 */
export const activate = (uid, token) => (dispatch) => {
  return AuthService.activate(uid, token).then(
    (response) => {
      dispatch({
        type: ACTIVATE_SUCCESS,
      });
      response.message = "User successfully activated";

      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      const data =
        (error.response && error.response.data) ||
        error.data ||
        error.toString();

      dispatch({
        type: ACTIVATE_FAIL,
      });

      if (data.detail) {
        dispatch({
          type: SET_MESSAGE,
          payload: data.detail,
        });
        data.message = data.detail;
      } else if (data.uid) {
        dispatch({
          type: SET_MESSAGE,
          payload: data.uid,
        });
        data.message = data.uid;
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
      }

      return Promise.reject(data);
    }
  );
};

/**
 * Resend account activation
 * success and error response action
 *
 *
 * @method resendActivation
 *
 * @param {string} email - A string for the email
 *
 *
 * @return {Promise}
 *
 */
export const resendActivation = (email) => (dispatch) => {
  return AuthService.resendActivation(email).then(
    (response) => {
      dispatch({
        type: RESEND_ACTIVATION_SUCCESS,
      });
      response.message = "An activation link has been sent to your email";

      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });

      return Promise.resolve("success");
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data[0]) ||
        error.message ||
        error.toString();

      const data =
        (error.response && error.response.data) ||
        error.data ||
        error.toString();

      dispatch({
        type: RESEND_ACTIVATION_FAIL,
      });

      if (data.detail) {
        dispatch({
          type: SET_MESSAGE,
          payload: data.detail,
        });
        data.message = data.detail;
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
      }

      return Promise.reject("error");
    }
  );
};

/**
 * User login
 * success and error response action
 *
 *
 * @method login
 *
 * @param {string} email - A string for the user ID
 * @param {string} password - A string for JWT token
 *
 *
 * @return {Promise}
 *
 */
export const login = (email, password) => (dispatch) => {
  return AuthService.login(email, password).then(
    (data) => {
      if (data.access) {
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("access", data.access);
        localStorage.setItem("refresh", data.refresh);
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data.user,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      const data =
        (error.response && error.response.data) ||
        error.data ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      if (data.detail) {
        dispatch({
          type: SET_MESSAGE,
          payload: data.detail,
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
      }

      return Promise.reject("error");
    }
  );
};

/**
 * User check email if activated
 * success and error response action
 *
 *
 * @method checkEmail
 *
 * @param {string} email - A string for the user ID
 *
 *
 * @return {Promise}
 *
 */
export const checkEmail = (email, password) => (dispatch) => {
  return AuthService.checkEmail(email, password).then(
    (data) => {
      console.log(data)
      

      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      const data =
        (error.response && error.response.data) ||
        error.data ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      if (data.detail) {
        dispatch({
          type: SET_MESSAGE,
          payload: data.detail,
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
      }

      return Promise.reject("error");
    }
  );
};

/**
 * User logout action
 *
 *
 * @method logout
 *
 * @return {Promise}
 *
 */
export const logout = (isLoggedIn) => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  AuthService.logout(isLoggedIn);
  return;
};

/**
 * Update user profile data
 * success and error response action
 *
 *
 * @method updateProfile
 *
 * @param {string} firstname - A string for the user first name
 * @param {string} middlename - A string for the user middle name
 * @param {string} lastname - A string for the user last name
 * @param {string} suffix - A string for the user suffix
 * @param {string} orcidID - A string for the user ORCID ID
 * @param {string} introduction - A string for the user introduction
 * @param {string} skills - A string for the user skills and expertise
 * @param {string} orgAcronym - A string for the user organization's acronyms
 * @param {string} organization - A string for the user organization name
 * @param {string} designation -A string for the user designation or position
 * @param {string} division - A string for the user division, section, or unit name in the organization
 * @param {string} province - A string for the user province address of the organization
 * @param {string} city - A string for the user city address of the organization
 *
 *
 * @return {Promise}
 *
 */
export const updateProfile =
  (
    firstname,
    middlename,
    lastname,
    suffix,
    orcidID,
    introduction,
    // skills,
    orgAcronym,
    organization,
    designation,
    division,
    province,
    city,
    profileSection
  ) =>
  (dispatch) => {
    return AuthService.updateProfile({
      first_name: firstname,
      middle_name: middlename,
      last_name: lastname,
      suffix: suffix,
      orcid: orcidID,
      introduction: introduction,
      // skills_expertise: skills,
      acronym_organization: orgAcronym,
      organization: organization,
      official_designation: designation,
      division_section_unit: division,
      address_province: province,
      address_municipality: city,
    }).then(
      (data) => {
        dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          payload: data,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || profileSection + " updated.",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        const data =
          (error.response && error.response.data) ||
          error.data ||
          error.toString();

        if (data.detail) {
          dispatch({
            type: SET_MESSAGE,
            payload: data.detail,
          });
        } else {
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
        }

        return Promise.reject("error");
      }
    );
  };

/**
 * Request to send reset password link in email
 * success and error response action
 *
 *
 * @method forgotPassword
 *
 * @param {string} email - A string for the user email
 *
 *
 * @return {Promise}
 *
 */
export const forgotPassword = (email) => (dispatch) => {
  return GroupService.forgotPassword(email).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload:
          data?.error ||
          "A reset password has been sent. Please check your email account.",
      });
      return Promise.resolve(data?.error ? "error" : "success");
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data[0]) ||
        error.message ||
        error.toString();

      const data =
        (error.response && error.response.data) ||
        error.data ||
        error.toString();

      if (data.detail) {
        dispatch({
          type: SET_MESSAGE,
          payload: data.detail,
        });
        data.message = data.detail;
      } else if (data.email) {
        dispatch({
          type: SET_MESSAGE,
          payload: data.email,
        });
        data.message = data.email;
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
      }

      return Promise.reject("error");
    }
  );
};

/**
 * Request to send reset password link in email
 * success and error response action
 *
 *
 * @method resetPassword
 *
 * @param {string} uid - A string for the user ID
 * @param {string} token - A string for password reset token
 * @param {string} new_password - A string for the user new password
 * @param {string} re_new_password - A string for the user confirm new password for matching and checking
 *
 * @return {Promise}
 *
 */
export const resetPassword =
  (uid, token, new_password, re_new_password) => (dispatch) => {
    return AuthService.resetPassword(
      uid,
      token,
      new_password,
      re_new_password
    ).then(
      (response) => {
        response.message = "Password successfully updated";

        dispatch({
          type: SET_MESSAGE,
          payload: response.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        const data =
          (error.response && error.response.data) ||
          error.data ||
          error.toString();

        dispatch({
          type: ACTIVATE_FAIL,
        });

        if (data.detail) {
          dispatch({
            type: SET_MESSAGE,
            payload: data.detail,
          });
          data.message = data.detail;
        } else if (data.uid) {
          dispatch({
            type: SET_MESSAGE,
            payload: data.uid,
          });
          data.message = data.uid;
        } else if (data.token) {
          dispatch({
            type: SET_MESSAGE,
            payload: data.token,
          });
          data.message = data.token;
        } else {
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
        }

        return Promise.reject(data);
      }
    );
  };

/**
 * Request to change password
 * success and error response action
 *
 *
 * @method changePassword
 *
 * @param {string} current_password - A string for the user current password
 * @param {string} new_password - A string for the user new password
 * @param {string} re_new_password - A string for the user confirm new password for matching and checking
 *
 * @return {Promise}
 *
 */
export const changePassword =
  (current_password, new_password, re_new_password) => (dispatch) => {
    return GroupService.changePassword(
      current_password,
      new_password,
      re_new_password
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Password updated.",
        });
        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        const data =
          (error.response && error.response.data) ||
          error.data ||
          error.toString();

        if (data.current_password) {
          dispatch({
            type: SET_MESSAGE,
            payload: data.current_password,
          });
          data.message = data.current_password;
        } else {
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
        }
        return Promise.reject("error");
      }
    );
  };

export const refreshToken = () => (dispatch) => {
  return AuthService.refreshToken().then(
    (response) => {
      response.message = "Token successfully updated";

      localStorage.setItem("access", response.data.access);

      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      const data =
        (error.response && error.response.data) ||
        error.data ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getCurrentUser = () => (dispatch) => {
  return AuthService.getCurrentUser().then(
    (data) => {
      dispatch({
        type: GET_CURRENT_USER_SUCCESS,
        payload: data,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_CURRENT_USER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
