import { Modal, Button, Tabs, Tab, Spinner, Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import provincesJson from "data/provinces.json";
import cityMunicipalityJson from "data/city-mun.json";
import { updateProfile } from "actions/auth";
import { useMediaQuery } from "react-responsive";

/**
 * A module for the EditAffiliationModal Component
 * @module components/users/EditAffiliationModal
 */

/**
 * Displaying menu in the left side of the system
 * @method EditAffiliationModal
 *
 * @return {JSX.Element}
 *
 */
const EditAffiliationModal = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const [orgAcronym, setOrgAcronym] = useState("");
  const [organization, setOrganization] = useState("");
  const [designation, setDesignation] = useState("");
  const [division, setDivision] = useState("");
  const [orgAddressProvince, setOrgAddressProvince] = useState("");
  const [orgAddressMunicipality, setOrgAddressMunicipality] = useState("");

  const [counter, setCounter] = useState(0);
  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);

  const [orgAddressProvinceOptions, setOrgAddressProvinceOptions] = useState(
    []
  );
  const [orgAddressMunicipalityOptions, setOrgAddressMunicipalityOptions] =
    useState([]);
  const [
    selectedOrgAddressMunicipalityOptions,
    setSelectedOrgAddressMunicipalityOptions,
  ] = useState([]);
  const [
    selectedOrgAddressProvinceOptions,
    setSelectedOrgAddressProvinceOptions,
  ] = useState([]);

  const handleSelectOrgAddressProvince = (selected) => {
    setSelectedOrgAddressProvinceOptions(selected);
    setOrgAddressProvince(selected[0]?.name);
    validate("orgAddressProvince", selected[0]?.name);
  };
  const handleSelectOrgAddressMunicipality = (selected) => {
    setSelectedOrgAddressMunicipalityOptions(selected);
    setOrgAddressMunicipality(selected[0]?.name);
    validate("orgAddressMunicipality", selected[0]?.name);
  };

  const onInputChangeOrgAddressProvince = (e) => {
    setCounter(counter + 1);
    validate("orgAddressProvince", selectedOrgAddressProvinceOptions[0]?.name);
  };
  const onInputChangeOrgAddressMunicipality = (e) => {
    setCounter(counter + 1);
    validate(
      "orgAddressMunicipality",
      selectedOrgAddressMunicipalityOptions[0]?.name
    );
  };

  const validate = (name, value) => {
    setIsValid(Object.assign(isValid, { [name]: false }));
    if (!value) {
      setError(Object.assign(error, { [name]: "This field is required!" }));
      return;
    }

    // For input with spaces only
    if (value.toString().trim() === "") {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "Please fill out this field" }));
      return;
    }

    if (value) {
      switch (name) {
        case "orgAcronym":
          setIsValid(Object.assign(isValid, { [name]: false }));
          // const validateNames = new RegExp("/^[ña-z .'-]+$/i");
          if (!/^[ña-z .-]+$/i.test(value)) {
            setError(
              Object.assign(error, {
                [name]: "Accepts a-z, A-Z, Ñ/ñ, space, -, and period only",
              })
            );
            return;
          }
          break;
        case "organization":
        case "designation":
        case "division":
          setIsValid(Object.assign(isValid, { [name]: false }));
          // const validateNames = new RegExp("/^[ña-z .'-]+$/i");
          if (!/^[ña-z -]+$/i.test(value)) {
            setError(
              Object.assign(error, {
                [name]: "Accepts a-z, A-Z, Ñ/ñ, space, and - only",
              })
            );
            return;
          }
          break;
        default:
        // code block
      }
    }

    if (value.length < 2 && value.length > 0) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Minimum of 2 characters",
        })
      );
      return;
    }

    if (name === "organization" && value.length > 0 && value.length > 500) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Maximum of 500 characters",
        })
      );
      return;
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  /**
   * Gets value of the Acronym of Organization input field
   * @method onChangeOrgAcronym
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeOrgAcronym = (e) => {
    const orgAcronym = e.target.value;
    setOrgAcronym(orgAcronym);
    validate("orgAcronym", orgAcronym);
  };

  /**
   * Gets value of the Name of Organization input field
   * @method onChangeOrgAcronym
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeOrganization = (e) => {
    const organization = e.target.value;
    setOrganization(organization);
    validate("organization", organization);
  };

  /**
   * Gets value of the Designation input field
   * @method onChangeDesignation
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeDesignation = (e) => {
    const designation = e.target.value;
    setDesignation(designation);
    validate("designation", designation);
  };

  /**
   * Gets value of the Department/Division/Unit input field
   * @method onChangeDivision
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeDivision = (e) => {
    const division = e.target.value;
    setDivision(division);
    validate("division", division);
  };

  const showProvinceList = () => {
    const provinceList = Object.values(provincesJson)
      .map((item) => {
        return {
          id: item.prov_code,
          name: item.name,
          label: item.name,
        };
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name));
    setOrgAddressProvinceOptions(provinceList);
    if (props?.orgAddressProvince) {
      const selectedProvince = provinceList.find(
        (item) => props?.orgAddressProvince === item.name
      );

      setSelectedOrgAddressProvinceOptions(
        selectedProvince ? [selectedProvince] : []
      );
    }
  };

  const handleUpdate = () => {
    validate("orgAcronym", orgAcronym);
    validate("organization", organization);
    validate("designation", designation);
    validate("division", division);
    validate("orgAddressProvince", orgAddressProvince);
    validate("orgAddressMunicipality", orgAddressMunicipality);

    if (Object.keys(error).length === 0) {
      setLoading(true);
      setDisabled(true);
      dispatch(
        updateProfile(
          props?.firstname,
          props?.middlename,
          props?.lastname,
          props?.suffix,
          props?.orcidID,
          props?.introduction,
          // props?.skills,
          orgAcronym,
          organization,
          designation,
          division,
          orgAddressProvince,
          orgAddressMunicipality,
          "Affiliation"
        )
      )
        .then((status) => {
          props.setToastStatus(status);
          props.setToastImage("/images/account-update-success.svg");
          props.setShowToast(true);
        })
        .catch((status) => {
          props.setToastStatus(status);
          props.setToastImage(null);
          props.setShowToast(true);
        })
        .finally(() => {
          props.handleEditAffiliationModalClose();
          props?.setRefreshProfileInfoCount(
            props?.refreshPersonalInfoCount + 1
          );
        });
    }
  };

  useEffect(() => {
    if (selectedOrgAddressProvinceOptions.length > 0) {
      const cityMunicipalityList = Object.values(cityMunicipalityJson)
        .filter(
          (item) => item.prov_code === selectedOrgAddressProvinceOptions[0]?.id
        )
        .map((item) => {
          return {
            id: item.mun_code,
            name: item.name,
            label: item.name,
          };
        });
      setOrgAddressMunicipalityOptions(cityMunicipalityList);
      if (props?.orgAddressMunicipality) {
        const selectedMunicipality = cityMunicipalityList.find(
          (item) => props?.orgAddressMunicipality === item.name
        );
        setSelectedOrgAddressMunicipalityOptions(
          selectedMunicipality ? [selectedMunicipality] : []
        );
      }
    } else {
      setOrgAddressMunicipalityOptions([]);
      setSelectedOrgAddressMunicipalityOptions([]);
    }
  }, [selectedOrgAddressProvinceOptions]);

  useEffect(() => {
    if (
      orgAcronym !== "" &&
      organization !== "" &&
      designation !== "" &&
      division !== "" &&
      orgAddressProvince !== "" &&
      orgAddressMunicipality !== "" &&
      Object.keys(error).length === 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    Object.keys(error),
    orgAcronym,
    organization,
    designation,
    division,
    orgAddressProvince,
    orgAddressMunicipality,
  ]);

  const resetModalForm = () => {
    setOrgAcronym(props?.orgAcronym);
    setOrganization(props?.organization);
    setDesignation(props?.designation);
    setDivision(props?.division);
    setOrgAddressProvince(props?.orgAddressProvince);
    setOrgAddressMunicipality(props?.orgAddressMunicipality);
    showProvinceList();
    setLoading(false);
    setDisabled(false);
    setIsValid({});
    setError({});
  };

  useEffect(() => {
    resetModalForm();
  }, [props.showEditAffiliationModal]);

  return (
    <>
      <Offcanvas
        show={props.showEditAffiliationModal && isMobile}
        onHide={props.handleEditAffiliationModalClose}
        placement="bottom"
        className="offcanvas-personal-info"
        id="details-tab"
        data-cy="details-tab"
      >
        <Offcanvas.Body>
          <Form className="w-100">
            <p className="bold">Edit affiliation</p>
            <div className="personal-info-form">
              <p className="required-field">
                Required fields are marked with an asterisk
              </p>
              <Form.Group className="mb-3">
                <Form.Label className="required-field">
                  Acronym of Organization
                </Form.Label>
                <Form.Control
                  value={orgAcronym}
                  onChange={onChangeOrgAcronym}
                  className={`input-field ${
                    isValid?.orgAcronym
                      ? "is-valid"
                      : isValid.orgAcronym !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="org-acronym"
                  id="org-acronym"
                  data-cy="org-acronym"
                />
                {getFormErrorMessage("orgAcronym")}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required-field">
                  Name of Organization
                </Form.Label>
                <Form.Control
                  value={organization}
                  onChange={onChangeOrganization}
                  className={`input-field ${
                    isValid?.organization
                      ? "is-valid"
                      : isValid.organization !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="org-name"
                  id="org-name"
                  data-cy="org-name"
                />
                {getFormErrorMessage("organization")}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required-field">Designation</Form.Label>
                <Form.Control
                  value={designation}
                  onChange={onChangeDesignation}
                  className={`input-field ${
                    isValid?.designation
                      ? "is-valid"
                      : isValid.designation !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="designation"
                  id="designation"
                  data-cy="designation"
                />
                {getFormErrorMessage("organization")}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required-field">
                  Department/Division/Unit
                </Form.Label>
                <Form.Control
                  value={division}
                  onChange={onChangeDivision}
                  className={`input-field ${
                    isValid?.division
                      ? "is-valid"
                      : isValid.division !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="department"
                  id="department"
                  data-cy="department"
                />
                {getFormErrorMessage("division")}
              </Form.Group>
              <Form.Group className="mb-3 typeahead-container">
                <Form.Label className="required-field">Province</Form.Label>
                <Typeahead
                  clearButton
                  labelKey="name"
                  multiple={false}
                  options={orgAddressProvinceOptions}
                  placeholder="Choose options"
                  selected={selectedOrgAddressProvinceOptions}
                  onChange={handleSelectOrgAddressProvince}
                  onInputChange={onInputChangeOrgAddressProvince}
                  className={`input-field typeahead-input form-control ${
                    isValid?.orgAddressProvince
                      ? "is-valid"
                      : isValid.orgAddressProvince !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  id="province"
                  inputProps={{
                    "data-cy": "province", // Add data-cy attribute to input element
                  }}
                />
                {getFormErrorMessage("orgAddressProvince")}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required-field">City</Form.Label>
                <Typeahead
                  clearButton
                  labelKey="name"
                  multiple={false}
                  options={orgAddressMunicipalityOptions}
                  placeholder="Choose options"
                  selected={selectedOrgAddressMunicipalityOptions}
                  onChange={handleSelectOrgAddressMunicipality}
                  onInputChange={onInputChangeOrgAddressMunicipality}
                  className={`input-field typeahead-input form-control ${
                    isValid?.orgAddressMunicipality
                      ? "is-valid"
                      : isValid.orgAddressMunicipality !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  id="city"
                  inputProps={{
                    "data-cy": "city", // Add data-cy attribute to input element
                  }}
                />
                {getFormErrorMessage("orgAddressMunicipality")}
              </Form.Group>
            </div>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleEditAffiliationModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="submit-btn button"
              disabled={disabled}
              onClick={handleUpdate}
              name="update-btn"
              id="update-btn"
              data-cy="update-btn"
            >
              Update
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={props.showEditAffiliationModal && !isMobile}
        onHide={props.handleEditAffiliationModalClose}
        dialogClassName="modal-45w"
        centered
        backdrop="static"
        id="details-tab"
        data-cy="details-tab"
      >
        <Modal.Header>
          <Modal.Title
            className="bold ms-3"
            id="affiliation-modal"
            data-cy="affiliation-modal"
          >
            Edit affiliation
          </Modal.Title>
          <Button
            variant="close"
            onClick={props.handleEditAffiliationModalClose}
            name="close-btn"
            id="close-btn"
            data-cy="close-btn"
          />
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <Form>
            <p className="required-field ms-5">
              Required fields are marked with an asterisk
            </p>
            <Tabs defaultActiveKey="details" className="mb-3">
              <Tab
                eventKey="details"
                tabClassName="tabs ms-5"
                title="Details"
                tabAttrs={{ "data-cy": "details-tab" }}
              >
                <div>
                  <Form.Group className="mb-3 mx-5">
                    <Form.Label className="required-field">
                      Acronym of Organization
                    </Form.Label>
                    <Form.Control
                      value={orgAcronym}
                      onChange={onChangeOrgAcronym}
                      className={`input-field ${
                        isValid?.orgAcronym
                          ? "is-valid"
                          : isValid.orgAcronym !== undefined
                          ? "is-invalid"
                          : ""
                      }`}
                      name="org-acronym"
                      id="org-acronym"
                      data-cy="org-acronym"
                    />
                    {getFormErrorMessage("orgAcronym")}
                  </Form.Group>
                  <Form.Group className="mb-3 mx-5">
                    <Form.Label className="required-field">
                      Name of Organization
                    </Form.Label>
                    <Form.Control
                      value={organization}
                      onChange={onChangeOrganization}
                      className={`input-field ${
                        isValid?.organization
                          ? "is-valid"
                          : isValid.organization !== undefined
                          ? "is-invalid"
                          : ""
                      }`}
                      name="org-name"
                      id="org-name"
                      data-cy="org-name"
                    />
                    {getFormErrorMessage("organization")}
                  </Form.Group>
                  <Form.Group className="mb-3 mx-5">
                    <Form.Label className="required-field">
                      Designation
                    </Form.Label>
                    <Form.Control
                      value={designation}
                      onChange={onChangeDesignation}
                      className={`input-field ${
                        isValid?.designation
                          ? "is-valid"
                          : isValid.designation !== undefined
                          ? "is-invalid"
                          : ""
                      }`}
                      name="designation"
                      id="designation"
                      data-cy="designation"
                    />
                    {getFormErrorMessage("organization")}
                  </Form.Group>
                  <Form.Group className="mb-3 mx-5">
                    <Form.Label className="required-field">
                      Department/Division/Unit
                    </Form.Label>
                    <Form.Control
                      value={division}
                      onChange={onChangeDivision}
                      className={`input-field ${
                        isValid?.division
                          ? "is-valid"
                          : isValid.division !== undefined
                          ? "is-invalid"
                          : ""
                      }`}
                      name="department"
                      id="department"
                      data-cy="department"
                    />
                    {getFormErrorMessage("division")}
                  </Form.Group>
                </div>
              </Tab>
              <Tab
                eventKey="address-of-organization"
                tabClassName="tabs ms-3"
                title="Address of Organization"
                tabAttrs={{ "data-cy": "org-tab" }}
              >
                <div className="height-70">
                  <Form.Group className="mb-3 mx-5 typeahead-container">
                    <Form.Label className="required-field">Province</Form.Label>
                    <Typeahead
                      clearButton
                      labelKey="name"
                      multiple={false}
                      options={orgAddressProvinceOptions}
                      placeholder="Choose options"
                      selected={selectedOrgAddressProvinceOptions}
                      onChange={handleSelectOrgAddressProvince}
                      onInputChange={onInputChangeOrgAddressProvince}
                      className={`input-field typeahead-input form-control ${
                        isValid?.orgAddressProvince
                          ? "is-valid"
                          : isValid.orgAddressProvince !== undefined
                          ? "is-invalid"
                          : ""
                      }`}
                      id="province"
                      inputProps={{
                        "data-cy": "province", // Add data-cy attribute to input element
                      }}
                    />
                    {getFormErrorMessage("orgAddressProvince")}
                  </Form.Group>
                  <Form.Group className="mb-3 mx-5">
                    <Form.Label className="required-field">City</Form.Label>
                    <Typeahead
                      clearButton
                      labelKey="name"
                      multiple={false}
                      options={orgAddressMunicipalityOptions}
                      placeholder="Choose options"
                      selected={selectedOrgAddressMunicipalityOptions}
                      onChange={handleSelectOrgAddressMunicipality}
                      onInputChange={onInputChangeOrgAddressMunicipality}
                      className={`input-field typeahead-input form-control ${
                        isValid?.orgAddressMunicipality
                          ? "is-valid"
                          : isValid.orgAddressMunicipality !== undefined
                          ? "is-invalid"
                          : ""
                      }`}
                      id="city"
                      inputProps={{
                        "data-cy": "city", // Add data-cy attribute to input element
                      }}
                    />
                    {getFormErrorMessage("orgAddressMunicipality")}
                  </Form.Group>
                </div>
              </Tab>
            </Tabs>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props.handleEditAffiliationModalClose}
            className="light-btn"
            name="cancel"
            id="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="submit-btn button"
            disabled={disabled}
            onClick={handleUpdate}
            name="update-btn"
            id="update-btn"
            data-cy="update-btn"
          >
            Update
            {loading && (
              <Spinner
                className="spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditAffiliationModal;
