import axios from "axios";
import { IP_ADDRESS } from "common/constants";

/**
 * A module that manages notification-related service
 * @module services/notificationServices
 */

const API_URL = IP_ADDRESS + "/api/feature/";

/**
 * Get all notifications information service that sends request and receives response data using axios
 *
 *
 * @method getAllNotifications
 *
 * @param {string} userID - A string for user id
 *
 * @return {Promise}
 *
 */
const getAllNotifications = (userID) => {
    return axios
      .get(API_URL + "get/all/notif/" + userID, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
  };

  const readNotification = (notifID) => {
    return axios
      .put(API_URL + "read/notif/" + notifID, null, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
  };

  const notificationServices = {
    getAllNotifications, 
    readNotification
  };
  
  export default notificationServices;