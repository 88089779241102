import { Modal, Button, Spinner, Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deleteDataset } from "actions/dataset";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

/**
 * A module for the DeleteDatasetModal Component
 * @module components/datasets/DeleteDatasetModal
 */

/**
 * Delete dataset in the system
 * @method DeleteDatasetModal
 *
 * @return {JSX.Element}
 *
 */
const DeleteDatasetModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (e) => {
    setDisabled(true);
    setLoading(true);
    dispatch(deleteDataset(props?.datasetID))
      .then((status) => {
        props.setToastImage("/images/remove-success.svg");
        props.setToastStatus("error");
        navigate("/datasets");
      })
      .catch((status) => {
        if (status === "notif-in-app-network unreachable") {
          console.log("notif-in-app-network unreachable");
          props.setToastStatus("error");
          props.setToastImage("/images/remove-success.svg");
          navigate("/datasets");
        } else {
          props.setToastStatus(status);
          props.setToastImage(null);
        }
      })
      .finally(() => {
        setDisabled(false);
        setLoading(false);
        props.setShowToast(true);
        props.handleDeleteDatasetModalClose();
      });
  };

  return (
    <>
      <Modal
        show={props.showDeleteDatasetModal && !isMobile}
        onHide={props.handleDeleteDatasetModalClose}
        dialogClassName="modal-32w remove-modal"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <p className="bold">Delete this dataset profile?</p>
          <p>
            All of the content and files of this dataset profile will be
            deleted.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props.handleDeleteDatasetModalClose}
            className="light-btn"
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            disabled={disabled}
            onClick={handleFormSubmit}
            className="submit-btn"
          >
            Delete
            {loading && (
              <Spinner
                className="spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props.showDeleteDatasetModal && isMobile}
        onHide={props.handleDeleteDatasetModalClose}
        placement="bottom"
        className="offcanvas-delete-profile"
        id="confirmation-message-profile"
        data-cy="confirmation-message-profile"
      >
        <Offcanvas.Body>
          <p className="bold">Delete this dataset profile?</p>
          <p>
            All of the content and files of this dataset profile will be
            deleted.
          </p>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleDeleteDatasetModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleFormSubmit}
              className="submit-btn"
              disabled={loading}
              id="delete-model-btn"
              name="delete-model-btn"
              data-cy="delete-model-btn"
            >
              Delete
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DeleteDatasetModal;
