import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  login,
  checkEmail,
  resendActivation,
} from "actions/auth";
import { useNavigate } from "react-router-dom";
import { Container, InputGroup, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import "styles/SignIn.css";
import "styles/mobile/SignInMobile.css";
import Home from "components/Home";

/**
 * A module for the SignIn Component
 * @module components/users/SignIn
 */

/**
 * SignIn Component
 * @method SignIn
 * @return {JSX.Element}
 *
 */
const SignIn = (props) => {
  const navigate = useNavigate();
  const form = useRef();
  const dispatch = useDispatch();

  /**
   * -------------------
   * * Redux store state
   * -------------------
   */
  const { isLoggedIn } = useSelector((state) => state.auth);

  /**
   * --------------------
   * * Component state
   *  -------------------
   */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reactivate, setReactivate] = useState(false);
  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [counter, setCounter] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [successful, setSuccessful] = useState(false);

  /**
   *  Validates name and value of the input field
   * @method validate
   * @param {string} name - A string for name of the input field
   * @param {string} value - A string for the value of the input field
   *
   * @return {void}
   */
  const validate = (name, value) => {
    if (!value) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "This field is required!" }));
      return;
    }
    if (
      name === "email" &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(value)
    ) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "This is not a valid email." }));
      return;
    }
    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  /**
   * Change hidden state of password
   * @method onClickPasswordBtn
   */
  const onClickPasswordBtn = () => {
    setPasswordShow(passwordShow ? false : true);
  };

  /**
   * Navigate to the registration page
   * @method onClickRegister
   */
  const onClickRegister = () => {
    navigate("/signup/");
  };

  /**
   * Gets value of the email input field
   * @method onChangeEmail
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    validate("email", email);
  };

  /**
   * Gets value of the password input field
   * @method onChangePassword
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    validate("password", password);
  };

  /**
   * Handles login data after submitting the form
   * @method handleLogin
   *
   * @param {event} e - An event object containing information about the action
   */
  const handleLogin = (e) => {
    e.preventDefault();

    validate("email", email);
    validate("password", password);
    setCounter(counter + 1);

    if (Object.keys(error).length === 0) {
      setLoading(true);
      dispatch(login(email, password))
        .catch((status) => {
          dispatch(checkEmail(email))
            .then((res) => {
              console.log(res);
              if (res === "True") {
                setReactivate(true);
              }
            })
            .catch((err) => console.log(err));
          console.log(status);
          props?.setShowToast(true);
          props?.setToastStatus(status);
          props?.setToastImage(null);
        })
        .finally(() => {
          setLoading(false);
          setReactivate(false);
        });
    }
  };

  useEffect(() => {
    let timer;

    if (isRunning) {
      timer = setInterval(() => {
        if (seconds < 300) {
          setSeconds(seconds + 1);
        } else {
          clearInterval(timer);
          setIsRunning(false);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning, seconds]);

  const startTimer = () => {
    setIsRunning(true);
    setSeconds(0);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const onClickResend = () => {
    startTimer();
    dispatch(resendActivation(email)).catch((status) => {
      stopTimer();
      props?.setShowToast(true);
      props?.setToastStatus(status);
      props?.setToastImage(null);
    });
  };

  useEffect(() => {
    if (email !== "" && password !== "" && Object.keys(error).length === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, password]);

  /**
   *  Navigate to the model list if the user already logged in
   */
  if (isLoggedIn) {
    props?.setPreload(true);
    return <Navigate to="/models" />;
  }

  return (
    <Home>
      <section>
        {!reactivate ? (
          <Container
            fluid
            className="d-flex align-items-center justify-content-around sign-in-container-mobile"
          >
            <form onSubmit={handleLogin} ref={form} className="sign-in-form">
              <div className="text-center">
                <img
                  src="/images/dimer-logo-2024.png"
                  className="mobile-logo"
                  alt="logo"
                />
                <p className="h2 mx-1 mt-4 bold">Sign in</p>
                <p>Use your DIMER account</p>
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form3Example3">
                  Email
                </label>
                <div className="email-container mb-3">
                  <img src="/images/email.svg" className="mail" alt="email" />
                  <input
                    type="text"
                    className={`form-control email input-field ${
                      isValid?.email
                        ? "is-valid"
                        : isValid.email !== undefined
                        ? "is-invalid"
                        : ""
                    }`}
                    name="email"
                    id="email-address"
                    data-cy="email-address"
                    value={email}
                    onChange={onChangeEmail}
                  />
                  {getFormErrorMessage("email")}
                </div>
              </div>

              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="form3Example4">
                  Password
                </label>
                <div className="password-container mb-3">
                  <img
                    src="/images/password.svg"
                    className="lock"
                    alt="password"
                  />
                  <input
                    type={passwordShow ? "text" : "password"}
                    className={`form-control password input-field ${
                      isValid?.password
                        ? "is-valid"
                        : isValid.password !== undefined
                        ? "is-invalid"
                        : ""
                    }`}
                    name="password"
                    id="password"
                    data-cy="password"
                    value={password}
                    onChange={onChangePassword}
                  />
                  <img
                    onClick={onClickPasswordBtn}
                    id="show-btn"
                    data-cy="show-btn"
                    src={
                      passwordShow ? "/images/eye.svg" : "/images/eye-slash.svg"
                    }
                    className="eye"
                    alt="hide"
                  />
                  {getFormErrorMessage("password")}
                </div>
              </div>

              <div>
                <Link
                  to="/forgot-password/"
                  id="forgot-password"
                  data-cy="forgot-password"
                  className="forgot-password-link"
                >
                  Forgot password?
                </Link>
              </div>
              <div className="sign-in-button-div">
                <Button
                  variant="outline-light"
                  onClick={onClickRegister}
                  className="light-btn desktop-sign-in-btn"
                  name="create-account"
                  id="create-account-dekstop"
                  data-cy="create-account"
                >
                  Create account
                </Button>
                <Button
                  variant="outline-light"
                  onClick={onClickRegister}
                  className="light-btn mobile-sign-in-btn"
                  name="create-account"
                  id="create-account-mobile"
                  data-cy="create-account"
                >
                  Create account for free!
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={disabled}
                  className="submit-btn button"
                  name="signin"
                  id="signin"
                  data-cy="signin"
                >
                  Sign in
                  {loading && (
                    <Spinner
                      className="spinner"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </div>
            </form>
          </Container>
        ) : (
          <Container className="step-container p-5 text-center">
            <p className="h3 mx-1 mt-4 bold">Activate your DIMER Account</p>
            <div className="form-outline mb-4">
              <p>The activation link will expire in 1 hour.</p>
              <p>If no email was received, click the Resend button</p>
            </div>
            <div className="d-flex sign-in-button-div justify-content-center">
              <Button
                variant="primary"
                type="submit"
                className="submit-btn button"
                onClick={onClickResend}
                disabled={isRunning}
                name="resend"
                id="resend"
                data-cy="resend"
              >
                Resend {isRunning && "(" + (300 - seconds) + ")"}
                {loading && (
                  <Spinner
                    className="spinner"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </div>
          </Container>
        )}
      </section>
    </Home>
  );
};

export default SignIn;
