import {
  Modal,
  Button,
  Form,
  ListGroup,
  Spinner,
  Offcanvas,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, addMembers } from "actions/group";
import { useMediaQuery } from "react-responsive";
import "styles/mobile/AddMemberModalMobile.css"

/**
 * A module for the AddGroupProfileModal Component
 * @module components/layout/AddGroupProfileModal
 */

/**
 * Displaying menu in the left side of the system
 * @method AddGroupProfileModal
 *
 * @return {JSX.Element}
 *
 */
const AddMemberModal = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.group);

  const [options, setOptions] = useState([]);

  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllUsers()).catch((err) => console.log(err));
  }, [dispatch]);

  useEffect(() => {
    if (props?.groupMembers) {
      const filteredUsers = Object.values(users).filter(
        (user) =>
          !props?.groupMembers.some((member) => user.email === member.email)
      );
      const usersList = Object.values(filteredUsers).map((item) => {
        return {
          id: item.id,
          name: item.first_name + " " + item.last_name,
          label: item.first_name + " " + item.last_name,
          email: item.email,
        };
      });
      setOptions(usersList);
    }
  }, [users, props?.groupMembers]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [counter, setCounter] = useState(0);

  const handleSelect = (selected) => {
    setSelectedOptions(selected);
  };

  const onInputChangeGroupMembers = (e) => {
    validate("groupMembers", e);
    setCounter(counter + 1);
  };

  const handleFormSubmit = (e) => {
    if (selectedOptions.length) {
      const emails = selectedOptions.map((option) => {
        const result = Object.values(users).filter(
          (user) => user.email === option.email
        );
        return result[0].email;
      });
      setLoading(true);
      dispatch(addMembers(props?.groupID, emails))
        .then((status) => {
          props.setToastStatus(status);
          props.setToastImage("/images/add-member-success.svg");
          props.setRefreshCurrentGroupCount(props.refreshCurrentGroupCount + 1);
        })
        .catch((status) => {
          if (status === "notif-in-app-and-email-network unreachable") {
            console.log("notif-in-app-and-email-network unreachable");
            props.setToastStatus("success");
            props.setToastImage("/images/add-member-success.svg");
            props.setRefreshCurrentGroupCount(
              props.refreshCurrentGroupCount + 1
            );
          } else {
            props.setToastStatus(status);
            props.setToastImage(null);
          }
        })
        .finally(() => {
          props.setShowToast(true);
          setLoading(false);
          props.handleAddMemberModalClose();
          resetModalForm();
        });
    } else {
      props.handleAddMemberModalClose();
      resetModalForm();
    }
  };

  const resetModalForm = () => {
    setIsValid({});
    setError({});
    setSelectedOptions([]);
  };

  const renderToken = (option, props, index) => {
    const disableRemove = option.disableRemove;

    return (
      <div className="token" key={option.id}>
        <img src="/images/user_form.svg" className="img-fluid" alt="user" />
        <span className="token-label">{option.label}</span>
        {!disableRemove && (
          <button
            type="button"
            className="token-remove"
            onClick={() => handleRemoveOption(option)}
          >
            &times;
          </button>
        )}
      </div>
    );
  };

  const handleRemoveOption = (deleted) => {
    // Implement your logic to handle removing the option
    console.log("Removing option:", deleted);
    setSelectedOptions(
      selectedOptions.filter((option) => option.id !== deleted.id)
    );
  };

  /**
   *  Validates name and value of the input field
   * @method validate
   * @param {string} name - A string for name of the input field
   * @param {string} value - A string for the value of the input field
   *
   * @return {void}
   */
  const validate = (name, value) => {
    if (
      value !== "" &&
      name === "groupMembers" &&
      !/^[a-zA-Z0-9.]+$/.test(value)
    ) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Accepts alphanumeric and period only.",
        })
      );
      return;
    }
    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  useEffect(() => {
    if (selectedOptions.length >= 1 && Object.keys(error).length === 0) {
    }
  }, [selectedOptions]);

  return (
    <>
      <Modal
        show={props.showAddMemberModal && !isMobile}
        onHide={props.handleAddMemberModalClose}
        dialogClassName="modal-35w"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="bold">
            Add new member to "{props?.groupName}"
          </Modal.Title>
          {!loading && (
            <Button
              variant="close"
              onClick={props.handleAddMemberModalClose}
              name="close-btn"
              id="close-btn"
              data-cy="close-btn"
            />
          )}
        </Modal.Header>
        <Modal.Body className={loading ? "cursor-not-allowed" : ""}>
          <FormInputFields
            loading={loading}
            counter={counter}
            options={options}
            selectedOptions={selectedOptions}
            handleSelect={handleSelect}
            onInputChangeGroupMembers={onInputChangeGroupMembers}
            renderToken={renderToken}
            isValid={isValid}
            groupMembers={props?.groupMembers}
            getFormErrorMessage={getFormErrorMessage}
            users={users}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            disabled={loading}
            onClick={props.handleAddMemberModalClose}
            className="light-btn"
            name="cancel"
            id="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleFormSubmit}
            className="submit-btn button"
            name="done-btn"
            id="done-btn"
            data-cy="done-btn"
          >
            Done
            {loading && (
              <Spinner
                className="spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props.showAddMemberModal && isMobile}
        onHide={props.handleAddMemberModalClose}
        placement="bottom"
        className="offcanvas-add-member"
        id="confirmation-message-profile"
        data-cy="confirmation-message-profile"
      >
        <Offcanvas.Body className="profile-offcanvas">
          <p className="bold" id="modal-title">
            Add new member to "{props?.groupName}"
          </p>
          <p className="required-field">
            Required fields are marked with an asterisk
          </p>
          <div className="add-member-form">
            <FormInputFields
              loading={loading}
              counter={counter}
              options={options}
              selectedOptions={selectedOptions}
              handleSelect={handleSelect}
              onInputChangeGroupMembers={onInputChangeGroupMembers}
              renderToken={renderToken}
              isValid={isValid}
              groupMembers={props?.groupMembers}
              getFormErrorMessage={getFormErrorMessage}
              users={users}
            />
          </div>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              disabled={loading}
              onClick={props.handleAddMemberModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={loading}
              onClick={handleFormSubmit}
              className="submit-btn button"
              name="done-btn"
              id="done-btn"
              data-cy="done-btn"
            >
              Done
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const FormInputFields = (props) => {
  return (
    <>
      <Form.Group
        className={`mb-3 ${props.loading ? "pointer-events-none" : ""}`}
      >
        <input type="hidden" name="counter" value={props.counter} />
        <Typeahead
          labelKey="name"
          multiple
          options={props.options}
          placeholder="Add people"
          selected={props.selectedOptions}
          onChange={props.handleSelect}
          onInputChange={props.onInputChangeGroupMembers}
          renderToken={props.renderToken}
          className={`input-field typeahead-input form-control ${
            props.isValid?.groupMembers
              ? "is-valid"
              : props.isValid.groupMembers !== undefined
              ? "is-invalid"
              : ""
          }`}
          id="add-people"
          inputProps={{
            "data-cy": "add-people", // Add data-cy attribute to input element
          }}
        />
        {props.getFormErrorMessage("groupMembers")}
      </Form.Group>
      <p className="semi-bold">Members in your group</p>
      <ListGroup key="listgroup" className="list-group-members-modal">
        {props?.groupMembers &&
          Object.values(props?.groupMembers).map((member) => {
            const item = Object.values(props.users).filter(
              (user) => user.email === member.email
            );
            return (
              <div key={`memberListKey${item[0]?.id}`}>
                <ListGroup.Item className="d-flex justify-content-between add-member-list">
                  <div>
                    <img
                      src="/images/user_list.svg"
                      className="img-fluid icon-list"
                      alt="user"
                    />
                    {item[0]?.first_name + " " + item[0]?.last_name}
                    {props?.groupOwner === item[0]?.email && "(me)"}
                  </div>
                  <div>
                    {props?.groupOwner === item[0]?.email ? "Owner" : "Member"}
                  </div>
                </ListGroup.Item>
              </div>
            );
          })}
      </ListGroup>
    </>
  );
};
export default AddMemberModal;
