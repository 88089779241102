import { Modal, Button, Form, Spinner, Offcanvas } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import { modules, formats } from "common/constants";
import { editDataset, getDataset } from "actions/dataset";
import { useNavigate } from "react-router-dom";
import DiscardChangesModal from "components/common-modals/DiscardChangesModal";
import { useMediaQuery } from "react-responsive";
import "styles/mobile/EditDatasetProfileModalMobile.css";
import "styles/EditDatasetProfileModal.css";

/**
 * A module for the EditDatasetModal Component
 * @module components/datasers/EditDatasetModal
 */

/**
 * Edit dataset profile
 * @method EditDatasetModal
 *
 * @return {JSX.Element}
 *
 */
const EditDatasetModal = (props) => {
  const datasetDescRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const [datasetID, setDatasetID] = useState(0);
  const [datasetTitle, setDatasetTitle] = useState("");
  const [datasetDescription, setDatasetDescription] = useState("");

  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [toolbarHiddenDatasetDescription, setToolbarHiddenDatasetDescription] =
    useState(true);
  const [
    isToolbarClickedDatasetDescription,
    setIsToolbarClickedDatasetDescription,
  ] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  // useEffect(() => {
  //   resetModalForm();
  // }, [props.showEditDatasetModal, props?.datasetCreatedBy]);

  const onChangeDatasetTitle = (e) => {
    const value = e.target.value;
    setDatasetTitle(value);
    validate("datasetTitle", value);
  };

  const onChangeDatasetDescription = (e) => {
    setDatasetDescription(e);
    validate("datasetDescription", e);
  };

  const updateCurrentDataset = () => {
    dispatch(getDataset(datasetID)).catch((status) => {
      props.setShowToast(true);
      props.setToastStatus(status);
      props.setToastImage(null);
    });
  };

  const handleFormSubmit = (e) => {
    setDisabled(true);
    setLoading(true);
    dispatch(editDataset(datasetID, datasetTitle, datasetDescription))
      .then((status) => {
        props.setToastStatus(status);
        updateCurrentDataset();
        if (status !== "error") {
          props.handleEditDatasetModalClose();
        }
        props?.setToastImage("/images/edit-success.svg");
      })
      .catch((status) => {
        if (status === "notif-in-app-network unreachable") {
          console.log("notif-in-app-network unreachable");
          props.setToastStatus("success");
          props.setToastImage("/images/edit-success.svg");
          updateCurrentDataset();
          props.handleEditDatasetModalClose();
        } else {
          props.setToastStatus(status);
          props.setToastImage(null);
        }
      })
      .finally(() => {
        setDisabled(false);
        setLoading(false);
        props.setShowToast(true);
        setChangesMade(false);
      });
  };

  const resetModalForm = () => {
    setDatasetID(props?.datasetID);
    setDatasetTitle(props?.datasetTitle);
    setDatasetDescription(props?.datasetDescription);
    setToolbarHiddenDatasetDescription(true);
    setDisabled(false);
    setIsValid({});
    setError({});
    setChangesMade(false);
  };

  useEffect(() => {
    if (changesMade) {
      setShowDiscardModal(true);
      props?.setShowEditDatasetModal(true);
    } else {
      resetModalForm();
    }
  }, [props.showEditDatasetModal]);

  /**
   *  Validates name and value of the input field
   * @method validate
   * @param {string} name - A string for name of the input field
   * @param {string} value - A string for the value of the input field
   *
   * @return {void}
   */
  const validate = (name, value) => {
    setChangesMade(true);
    if (name === "datasetDescription") {
      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(value, "text/html");
      const textContent = parsedHTML.body.textContent.trim();
      if (textContent === "") {
        value = "";
      }
    }
    if (!value) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "Please fill out this field" }));
      return;
    }

    const regexPattern =
      /^[a-zA-Z0-9 Ññ~`!@#\$%\^&*()_\-+={}\[\]|\\:;'"<,>.?\/\s]+$/g;
    if (regexPattern.test(value) === false) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]:
            "Accepts a-z, A-Z, 0-9, Ñ/ñ, space, and special characters like ~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/",
        })
      );
      return;
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  /**
   * Setting the toolbar hidden of the text editor based on the name of the input field
   * @method onBlur
   *
   * @param {string} name - A string for the name of the input field
   * @param {event} e - An event object containing information about the action
   */

  const onBlurDatasetDescription = () => {
    if (!isToolbarClickedDatasetDescription) {
      setToolbarHiddenDatasetDescription(true);
    }
  };

  const handleMouseDownDatasetDescription = () => {
    setIsToolbarClickedDatasetDescription(true);
  };

  const handleMouseUpDatasetDescription = () => {
    setIsToolbarClickedDatasetDescription(false);
  };

  const onFocusDatasetDescription = () => {
    setToolbarHiddenDatasetDescription(false);
  };

  useEffect(() => {
    if (
      datasetTitle &&
      datasetDescription &&
      datasetDescription !== "<p><br></p>" &&
      Object.keys(error).length === 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [datasetTitle, datasetDescription]);

  const handleDiscardModalClose = () => {
    setShowDiscardModal(false);
  };

  const onClickDiscard = () => {
    handleDiscardModalClose();
    props.handleEditDatasetModalClose();
    resetModalForm();
  };

  const onClickGoBack = () => {
    handleDiscardModalClose();
    props?.setShowEditDatasetModal(true);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      props?.setShowEditDatasetModal(true);
      showDiscardModal(true);
      event.preventDefault();
      event.returnValue = ""; // Standard for most browsers
    };

    if (changesMade) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [changesMade]);

  useEffect(() => {
    // Add data-cy attribute to the Quill editor root div after it's mounted
    if (datasetDescRef.current) {
      const editorDiv = datasetDescRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "dataset-desc");
    }
  }, []);

  return (
    <>
      <DiscardChangesModal
        showDiscardModal={showDiscardModal}
        onClickGoBack={onClickGoBack}
        onClickDiscard={onClickDiscard}
      />
      <Modal
        show={props.showEditDatasetModal && !isMobile}
        onHide={props.handleEditDatasetModalClose}
        dialogClassName="modal-50w"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="bold ms-3"
            id="modal-title"
            data-cy="modal-title"
          >
            Edit a Dataset Profile
          </Modal.Title>
          <Button
            variant="close"
            onClick={props.handleDatasetModalClose}
            name="close-btn"
            id="close-btn"
            data-cy="close-btn"
          />
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <Form>
            <p className="required-field ms-5">
              Required fields are marked with an asterisk
            </p>
            <hr />
            <div className="edit-dataset-profile-form-div">
              <FormInputFields
                datasetDescRef={datasetDescRef}
                datasetTitle={datasetTitle}
                onChangeDatasetTitle={onChangeDatasetTitle}
                isValid={isValid}
                getFormErrorMessage={getFormErrorMessage}
                handleMouseDownDatasetDescription={
                  handleMouseDownDatasetDescription
                }
                handleMouseUpDatasetDescription={
                  handleMouseUpDatasetDescription
                }
                onBlurDatasetDescription={onBlurDatasetDescription}
                onFocusDatasetDescription={onFocusDatasetDescription}
                datasetDescription={datasetDescription}
                onChangeDatasetDescription={onChangeDatasetDescription}
                toolbarHiddenDatasetDescription={
                  toolbarHiddenDatasetDescription
                }
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props.handleEditDatasetModalClose}
            className="light-btn"
            name="cancel"
            id="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleFormSubmit}
            disabled={disabled}
            className="submit-btn button"
            name="edit-dataset"
            id="edit-dataset"
            data-cy="edit-dataset"
          >
            Update
            {loading && (
              <Spinner
                className="spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props.showEditDatasetModal && isMobile}
        onHide={props.handleEditDatasetModalClose}
        placement="bottom"
        className="offcanvas-edit-dataset-profile"
      >
        <Offcanvas.Body className="profile-offcanvas">
          <Form>
            <p className="bold" id="modal-title" data-cy="modal-title">
              Edit a Dataset Profile
            </p>
            <p className="required-field">
              Required fields are marked with an asterisk
            </p>
            <div className="edit-dataset-profile-form">
              <FormInputFields
                datasetDescRef={datasetDescRef}
                datasetTitle={datasetTitle}
                onChangeDatasetTitle={onChangeDatasetTitle}
                isValid={isValid}
                getFormErrorMessage={getFormErrorMessage}
                handleMouseDownDatasetDescription={
                  handleMouseDownDatasetDescription
                }
                handleMouseUpDatasetDescription={
                  handleMouseUpDatasetDescription
                }
                onBlurDatasetDescription={onBlurDatasetDescription}
                onFocusDatasetDescription={onFocusDatasetDescription}
                datasetDescription={datasetDescription}
                onChangeDatasetDescription={onChangeDatasetDescription}
                toolbarHiddenDatasetDescription={
                  toolbarHiddenDatasetDescription
                }
              />
            </div>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleEditDatasetModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleFormSubmit}
              disabled={disabled}
              className="submit-btn button"
              name="edit-dataset"
              id="edit-dataset"
              data-cy="edit-dataset"
            >
              Update
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const FormInputFields = (props) => {
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label className="required-field">Title</Form.Label>
        <Form.Control
          type="text"
          value={props.datasetTitle}
          onChange={props.onChangeDatasetTitle}
          className={`form-control ${
            props.isValid?.datasetTitle
              ? "is-valid"
              : props.isValid.datasetTitle !== undefined
              ? "is-invalid"
              : ""
          }`}
          name="dataset-title"
          id="dataset-title"
          data-cy="dataset-title"
        />
        {props.getFormErrorMessage("datasetTitle")}
        <span className="sub-note">
          Give a unique title to your dataset profile.
        </span>
      </Form.Group>
      <Form.Group
        className="mb-3"
        onMouseDown={props.handleMouseDownDatasetDescription}
        onMouseUp={props.handleMouseUpDatasetDescription}
        onBlur={props.onBlurDatasetDescription}
        onFocus={props.onFocusDatasetDescription}
      >
        <Form.Label className="required-field">Description</Form.Label>
        <ReactQuill
          ref={props.datasetDescRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.datasetDescription}
          onChange={props.onChangeDatasetDescription}
          className={`input-description form-control ${
            props.isValid?.datasetDescription
              ? "is-valid"
              : props.isValid.datasetDescription !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenDatasetDescription
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="dataset-desc"
        />
        {props.getFormErrorMessage("datasetDescription")}
        <span className="sub-note">
          Let people know what your dataset is about.
        </span>
      </Form.Group>
    </>
  );
};
export default EditDatasetModal;
