import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import EditBasicInfoModal from "./EditBasicInfoModal";
import EditAffiliationModal from "./EditAffiliationModal";
import { getUser } from "actions/group";
import { Link } from "react-router-dom";
import EditAboutModal from "./EditAboutModal";
import "styles/PersonalInfo.css";
import "styles/PersonalInfoMobile.css";

/**
 * A module for Personal Info Component
 * @module components/users/PersonalInfo
 */

/**
 * Personal Info Component
 * @method PersonalInfo
 *
 * @return {JSX.Element}
 *
 */
const PersonalInfo = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { currentUser } = useSelector((state) => state.group);

  const [firstname, setFirstName] = useState("");
  const [middlename, setMiddleName] = useState("");
  const [lastname, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [orcidID, setOrcidID] = useState("");

  const [introduction, setIntroduction] = useState("");
  const [orgAcronym, setOrgAcronym] = useState("");
  const [organization, setOrganization] = useState("");
  const [designation, setDesignation] = useState("");
  const [division, setDivision] = useState("");
  const [orgAddressProvince, setOrgAddressProvince] = useState("");
  const [orgAddressMunicipality, setOrgAddressMunicipality] = useState("");

  const [showEditBasicInfoModal, setShowEditBasicInfoModal] = useState(false);
  const [showEditAboutModal, setShowEditAboutModal] = useState(false);
  const [showEditAffiliationModal, setShowEditAffiliationModal] =
    useState(false);
  const [refreshPersonalInfoCount, setRefreshProfileInfoCount] = useState(0);

  const handleEditBasicInfoModalClose = () => {
    setShowEditBasicInfoModal(false);
  };

  const handleEditAboutModalClose = () => {
    setShowEditAboutModal(false);
  };

  const handleEditAffiliationModalClose = () => {
    setShowEditAffiliationModal(false);
  };

  useEffect(() => {
    if (user.id) {
      props.setPreload(true);
      dispatch(getUser(user.id))
        .catch((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        })
        .finally(() => props.setPreload(false));
    }
  }, [refreshPersonalInfoCount, user.id]);

  // useEffect(() => {
  //   if (user?.skills_expertise !== "") {
  //     setSkills(user?.skills_expertise?.split(", "));
  //   } else {
  //     setSkills([]);
  //   }
  // }, [user?.skills_expertise]);

  useEffect(() => {
    if (currentUser?.user_data) {
      setFirstName(currentUser?.user_data[0]?.first_name);
      setMiddleName(currentUser?.user_data[0]?.middle_name);
      setLastName(currentUser?.user_data[0]?.last_name);
      setSuffix(currentUser?.user_data[0]?.suffix);
      setOrcidID(currentUser?.user_data[0]?.orcid);
      setIntroduction(currentUser?.user_data[0]?.introduction);
      setOrgAcronym(currentUser?.user_data[0]?.acronym_organization);
      setOrganization(currentUser?.user_data[0]?.organization);
      setDesignation(currentUser?.user_data[0]?.official_designation);
      setDivision(currentUser?.user_data[0]?.division_section_unit);
      setOrgAddressProvince(currentUser?.user_data[0]?.address_province);
      setOrgAddressMunicipality(
        currentUser?.user_data[0]?.address_municipality
      );
    }
  }, [currentUser]);

  return (
    <>
      {currentUser && (
        <>
          <EditBasicInfoModal
            refreshPersonalInfoCount={refreshPersonalInfoCount}
            setRefreshProfileInfoCount={setRefreshProfileInfoCount}
            showEditBasicInfoModal={showEditBasicInfoModal}
            handleEditBasicInfoModalClose={handleEditBasicInfoModalClose}
            setShowToast={props?.setShowToast}
            setToastStatus={props?.setToastStatus}
            setToastImage={props?.setToastImage}
            firstname={firstname}
            middlename={middlename}
            lastname={lastname}
            suffix={suffix}
            orcidID={orcidID}
            introduction={introduction}
            orgAcronym={orgAcronym}
            organization={organization}
            designation={designation}
            division={division}
            orgAddressProvince={orgAddressProvince}
            orgAddressMunicipality={orgAddressMunicipality}
          />
          <EditAboutModal
            refreshPersonalInfoCount={refreshPersonalInfoCount}
            setRefreshProfileInfoCount={setRefreshProfileInfoCount}
            showEditAboutModal={showEditAboutModal}
            handleEditAboutModalClose={handleEditAboutModalClose}
            setShowToast={props?.setShowToast}
            setToastStatus={props?.setToastStatus}
            setToastImage={props?.setToastImage}
            firstname={firstname}
            middlename={middlename}
            lastname={lastname}
            suffix={suffix}
            orcidID={orcidID}
            introduction={introduction}
            orgAcronym={orgAcronym}
            organization={organization}
            designation={designation}
            division={division}
            orgAddressProvince={orgAddressProvince}
            orgAddressMunicipality={orgAddressMunicipality}
          />
          <EditAffiliationModal
            refreshPersonalInfoCount={refreshPersonalInfoCount}
            setRefreshProfileInfoCount={setRefreshProfileInfoCount}
            showEditAffiliationModal={showEditAffiliationModal}
            handleEditAffiliationModalClose={handleEditAffiliationModalClose}
            setShowToast={props?.setShowToast}
            setToastStatus={props?.setToastStatus}
            setToastImage={props?.setToastImage}
            firstname={firstname}
            middlename={middlename}
            lastname={lastname}
            suffix={suffix}
            orcidID={orcidID}
            introduction={introduction}
            orgAcronym={orgAcronym}
            organization={organization}
            designation={designation}
            division={division}
            orgAddressProvince={orgAddressProvince}
            orgAddressMunicipality={orgAddressMunicipality}
          />
        </>
      )}

      <Container className="personal-info-container px-3">
        <div>
          <div className="d-flex justify-content-between mb-5">
            <span className="w-75">
              <p className="personal-info-title bold">Personal Info</p>
              <p className="personal-info-desc">
                Info about you and options to manage it.
              </p>
            </span>
            <div>
              <img
                src="/images/personal_info_icon.svg"
                className="personal-info-icon"
                alt="personal_info_icon"
              />
            </div>
          </div>
          <div className="basic-information">
            <div className="d-flex justify-content-between">
              <p className="basic-information-title semi-bold">Introduction</p>
              <Button
                variant="secondary"
                className="three-dot-dropdown"
                onClick={() => setShowEditBasicInfoModal(true)}
                name="edit-intro-btn"
                id="edit-intro-btn"
                data-cy="edit-intro-btn"
              >
                <img
                  src="/images/edit_account.svg"
                  className="edit-account-icon"
                  alt="edit basic info"
                />
              </Button>
            </div>
            <Row>
              <Col xs={4} lg={4} className="medium">
                First name
              </Col>
              <Col xs={8} lg={8}>
                {firstname || "--"}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={4} lg={4} className="medium">
                Middle name
              </Col>
              <Col xs={8} lg={8}>
                {middlename || "--"}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={4} lg={4} className="medium">
                Last name
              </Col>
              <Col xs={8} lg={8}>
                {lastname || "--"}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={4} lg={4} className="medium">
                Suffix
              </Col>
              <Col xs={8} lg={8}>
                {suffix || "--"}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={4} lg={4} className="medium">
                ORCID ID
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Go to ORCID website</Tooltip>}
                  trigger={["hover", "focus"]}
                >
                  <Link to="https://orcid.org/" target="_blank">
                    <img
                      src="/images/question.svg"
                      className="question ms-2"
                      alt="ORCID ID icon"
                    />
                  </Link>
                </OverlayTrigger>
              </Col>
              <Col xs={8} lg={8}>
                {orcidID || "--"}
              </Col>
            </Row>
          </div>

          <div className="about">
            <div className="d-flex justify-content-between">
              <p className="about-title semi-bold">About</p>
              <Button
                variant="secondary"
                className="three-dot-dropdown"
                onClick={() => setShowEditAboutModal(true)}
                name="edit-about-btn"
                id="edit-about-btn"
                data-cy="edit-about-btn"
              >
                <img
                  src="/images/edit_account.svg"
                  className="icon edit-account-icon"
                  alt="edit about"
                />
              </Button>
            </div>
            <div className="my-3">{introduction || "--"}</div>
          </div>

          <div className="contributions">
            <div className="d-flex justify-content-between">
              <h5 className="contributions-title semi-bold">Contributions</h5>
            </div>
            <Row>
              <Col xs={4} lg={4} className="medium">
                Models
              </Col>
              <Col xs={8} lg={8}>
                {currentUser?.model_count || "--"}
              </Col>
            </Row>
          </div>

          <div className="affiliation">
            <div className="d-flex justify-content-between">
              <h5 className="affiliation-title semi-bold">Affiliation</h5>
              <Button
                variant="secondary"
                className="three-dot-dropdown"
                onClick={() => setShowEditAffiliationModal(true)}
                name="edit-affiliation"
                id="edit-affiliation"
                data-cy="edit-affiliation"
              >
                <img
                  src="/images/edit_account.svg"
                  className="icon edit-account-icon"
                  alt="edit basic info"
                />
              </Button>
            </div>
            <Row>
              <Col xs={4} lg={4} className="semi-bold">
                Acronym of Organization
              </Col>
              <Col xs={8} lg={8}>
                {orgAcronym || "--"}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={4} lg={4} className="semi-bold">
                Name of Organization
              </Col>
              <Col xs={8} lg={8}>
                {organization || "--"}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={4} lg={4} className="semi-bold">
                Designation
              </Col>
              <Col xs={8} lg={8}>
                {designation || "--"}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={4} lg={4} className="semi-bold">
                Department / Division / Unit
              </Col>
              <Col xs={8} lg={8}>
                {division || "--"}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={4} lg={4} className="semi-bold">
                Province
              </Col>
              <Col xs={8} lg={8}>
                {orgAddressProvince || "--"}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={4} lg={4} className="semi-bold">
                City
              </Col>
              <Col xs={8} lg={8}>
                {orgAddressMunicipality || "--"}
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PersonalInfo;
