import {
  downloadModelVersion,
  inferenceBuildingDelineationModel,
} from "actions/model";
import { IP_ADDRESS, IP_ADDRESS_3, convertDate } from "common/constants";
import CancelDemoModal from "components/models/CancelDemoModal";
import ModelDemoStartModal from "components/models/ModelDemoStartModal";
import { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CancelToken } from "axios";
import JSZip from "jszip";

const DemoBuildingDelineation = (props) => {
  const dispatch = useDispatch();
  const [modelSelectedFileImage, setModelSelectedFileImage] = useState("");
  const [demoProgressPercentage, setDemoProgressPercentage] = useState(0);
  const [opacity, setOpacity] = useState(50);
  const [counter, setCounter] = useState(0);
  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [startDemoDisabled, setStartDemoDisabled] = useState(true);
  const [demoStarted, setDemoStarted] = useState(false);
  const [computationTime, setComputationTime] = useState(0);
  const [showModelDemoStartModal, setShowModelDemoStartModal] = useState(false);
  const [versions, setVersions] = useState([]);
  const [modelFileID, setModelFileID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [visualizationMethod, setVisualizationMethod] = useState("outline");
  const [outlineColor, setOutlineColor] = useState("#d7001a");
  const [outlineThickness, setOutlineThickness] = useState("3");
  const fileInputRefImage = useRef(null);
  const [info, setInfo] = useState("");
  const [outputImage, setOutputImage] = useState("");
  const [imageInput, setImageInput] = useState("");
  const [imageOutputOverlay, setimageOutputOverlay] = useState("");
  const [outputHeight, setOutputHeight] = useState(0);
  const [userCancelled, setUserCancelled] = useState(false);
  const [shouldWarn, setShouldWarn] = useState(false);

  const cancelAction1 = useRef(null);
  const cancelAction2 = useRef(null);
  const imageInputRef = useRef(null);
  const imageOutputRef = useRef(null);

  const handleModelDemoStartModalClose = () => {
    setShowModelDemoStartModal(false);
  };

  useEffect(() => {
    const filteredVersions = props?.modelVersions;
    if (filteredVersions?.length > 0) {
      const filteredVersionsPublished = filteredVersions.filter((version) => {
        return version.status === "Published";
      });
      const filteredVersionsDraft = filteredVersions.filter((version) => {
        return version.status === "Draft";
      });
      setModelFileID(
        filteredVersionsPublished.length > 0
          ? filteredVersionsPublished[0]?.id
          : filteredVersionsDraft[0]?.id
      );
    }
    setVersions(filteredVersions);
  }, [props?.modelVersions]);

  const validate = (name, value) => {
    if (name === "modelSelectedFileImage") {
      const extensionName = value?.name?.split(".").at(-1).toLowerCase();
      if (!value) {
        setCounter(counter + 1);
        setIsValid(Object.assign(isValid, { [name]: false }));
        setError(
          Object.assign(error, {
            [name]: "Choose your image file",
          })
        );
        if (name === "modelSelectedFileImage") {
          setModelSelectedFileImage("");
        }
        return;
      }

      if (value?.size > 25000000) {
        setCounter(counter + 1);
        setIsValid(Object.assign(isValid, { [name]: false }));
        setError(
          Object.assign(error, {
            [name]: "Your file is larger than 25 MB. Try another file.",
          })
        );
        if (name === "modelSelectedFileImage") {
          setModelSelectedFileImage("");
        }
        return;
      }

      if (
        extensionName !== "jpeg" &&
        extensionName !== "jpg" &&
        extensionName !== "png" &&
        extensionName !== "gif" &&
        extensionName !== "bmp" &&
        extensionName !== "tiff" &&
        extensionName !== "raw" &&
        extensionName !== "webp" &&
        extensionName !== "svg" &&
        extensionName !== "heif" &&
        extensionName !== "heic" &&
        extensionName !== "dicom" &&
        extensionName !== "pgm" &&
        extensionName !== "ppm" &&
        extensionName !== "png" &&
        extensionName !== "pbm" &&
        extensionName !== "hdr" &&
        extensionName !== "exr"
      ) {
        setCounter(counter + 1);
        setIsValid(Object.assign(isValid, { [name]: false }));
        setError(
          Object.assign(error, {
            [name]: "File type not supported",
          })
        );
        if (name === "modelSelectedFileImage") {
          setModelSelectedFileImage("");
        }
        return;
      }
      if (
        name === "modelSelectedFileImage" &&
        Array.isArray(modelSelectedFileImage) &&
        modelSelectedFileImage?.find((file) => file.name === value.name)
      ) {
        setModelSelectedFileImage(modelSelectedFileImage);
        return;
      }
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  const handleFileChangeImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setModelSelectedFileImage(event.target.files[0]);
      const extensionName = file?.name?.split(".").at(-1).toLowerCase();
      if (
        extensionName === "jpeg" ||
        extensionName === "jpg" ||
        extensionName === "png" ||
        extensionName === "gif" ||
        extensionName === "bmp" ||
        extensionName === "tiff" ||
        extensionName === "raw" ||
        extensionName === "webp" ||
        extensionName === "svg" ||
        extensionName === "heif" ||
        extensionName === "heic" ||
        extensionName === "dicom" ||
        extensionName === "pgm" ||
        extensionName === "ppm" ||
        extensionName === "png" ||
        extensionName === "pbm" ||
        extensionName === "hdr" ||
        extensionName === "exr"
      ) {
        const url = URL.createObjectURL(file);
        setImageInput(url);
      }
      validate("modelSelectedFileImage", file);
      setCounter(counter + 1);
    }
  };

  const handleDragOverImage = (event) => {
    event.preventDefault();
  };

  const handleDropImage = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && !imageInput) {
      setModelSelectedFileImage(file);
      const extensionName = file?.name?.split(".").at(-1).toLowerCase();
      if (
        extensionName === "jpeg" ||
        extensionName === "jpg" ||
        extensionName === "png" ||
        extensionName === "gif" ||
        extensionName === "bmp" ||
        extensionName === "tiff" ||
        extensionName === "raw" ||
        extensionName === "webp" ||
        extensionName === "svg" ||
        extensionName === "heif" ||
        extensionName === "heic" ||
        extensionName === "dicom" ||
        extensionName === "pgm" ||
        extensionName === "ppm" ||
        extensionName === "png" ||
        extensionName === "pbm" ||
        extensionName === "hdr" ||
        extensionName === "exr"
      ) {
        const url = URL.createObjectURL(file);
        setImageInput(url);
      }
      validate("modelSelectedFileImage", file);
      setCounter(counter + 1);
    }
  };

  const handleClickImage = () => {
    fileInputRefImage.current.click();
  };

  const handleRemoveImageBtn = (e) => {
    validate("modelSelectedFileImage", "");
    fileInputRefImage.current.value = "";
    setImageInput("");
    setModelSelectedFileImage("");
    setStartDemoDisabled(true);
    e.stopPropagation();
  };

  const handleDownloadOutputBtn = async (e) => {
    const url = outputImage; // Replace with your file URL
    const response = await fetch(url);
    if (!response.ok) {
      // Handle error here
      console.error("Failed to download file");
      return;
    }
    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = urlBlob;
    a.download = "file.png"; // Specify the desired file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(urlBlob);
  };

  const handleClearBtn = () => {
    setStartDemoDisabled(true);
    setImageInput("");
    setOutputImage("");
    setDemoProgressPercentage(0);
    setErrorMessage("");
    setDemoStarted(false);
    setComputationTime(0);
    fileInputRefImage.current.value = "";
  };

  // Effect to continuously check the condition and compute
  useEffect(() => {
    // Define a function to check and compute
    // function checkAndCompute() {
    //   setComputationTime((prevTime) => prevTime + 1); // Increment computation time
    //   // Check the condition
    //   if (demoStarted) {
    //     setTimeout(checkAndCompute, 1000); // Adjust the delay as needed
    //   }
    // }

    let timer = setTimeout(() => {
      setComputationTime((prevTime) => prevTime + 1); // Increment computation time
    }, 1000);
    if (!demoStarted || userCancelled) {
      props?.setDisableLayout(false);
      clearTimeout(timer);
    }

    // // Cleanup function (optional)
    // return () => {
    //   // Perform cleanup tasks if necessary
    //   clearTimeout(timer);
    // };
  }, [demoStarted, computationTime, userCancelled]); // Empty dependency array ensures this effect runs only once on component mount

  useEffect(() => {
    if (demoStarted) {
      const interval = setInterval(() => {
        if (demoProgressPercentage < 100) {
          setDemoProgressPercentage((prevProgress) => prevProgress + 1);
        } else {
          clearInterval(interval);
        }
      }, 50); // Adjust the interval for smoother or faster progress

      return () => clearInterval(interval);
    } else {
      setDemoProgressPercentage(0);
    }
  }, [demoStarted]);

  const resetValues = () => {
    props?.setDisableLayout(false);
    setDemoStarted(false);
    // setDemoProgressPercentage(0);
    setStartDemoDisabled(true);
    setOpacity(50);
    setShouldWarn(false);
    setUserCancelled(false);
  };

  const startInference = (modelFile) => {
    const imageRect = imageInputRef.current.getBoundingClientRect();
    setOutputHeight(Math.round(imageRect.height) + 5);
    const color = outlineColor;
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);
    const rgbColor = `${r},${g},${b}`;
    dispatch(
      inferenceBuildingDelineationModel(
        modelSelectedFileImage,
        modelFile,
        visualizationMethod === "outline" ? "False" : "True",
        outlineThickness,
        rgbColor,
        new CancelToken((cancel) => (cancelAction2.current = cancel))
      )
    )
      .then((response) => {
        console.log(response);
        setOutputImage(IP_ADDRESS_3 + response.result_image);
        setInfo(response.contours);
        resetValues();
      })
      .catch((err) => {
        console.log(err);
        resetValues();
        setErrorMessage(err);
      });
  };

  const handleStartDemoBtn = () => {
    setComputationTime(0);
    setDemoStarted(true);
    setStartDemoDisabled(true);
    setOutputImage("");
    setErrorMessage("");
    props?.setDisableLayout(true);
    setimageOutputOverlay(imageInput);
    setShouldWarn(true);

    //     props?.setDisableLayout(false);
    //     setDemoStarted(false);
    //     setDemoProgressPercentage(0);
    //     setStartDemoDisabled(true);
    //     setOpacity(50);
    //     setShouldWarn(false);
    //     setUserCancelled(false);

    dispatch(
      downloadModelVersion(
        modelFileID,
        (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // console.log(percentage);
        },
        new CancelToken((cancel) => (cancelAction1.current = cancel))
      )
    )
      .then((content) => {
        if (!userCancelled) {
          const zipFile = new File([content], "output.zip", {
            type: content.type,
          });
          if (zipFile) {
            const reader = new FileReader();
            reader.onload = async (e) => {
              const arrayBuffer = e.target.result;
              try {
                const zip = await JSZip.loadAsync(arrayBuffer);
                await extractZipFile(zip);
              } catch (error) {
                console.error("Failed to load ZIP file:", error);
              }
            };
            reader.readAsArrayBuffer(zipFile);
          }
        } else {
          resetValues();
        }
      })
      .catch((err) => {
        console.log(err);
        resetValues();
      });
  };

  const extractZipFile = async (zip) => {
    if (!userCancelled) {
      for (const fileName of Object.keys(zip.files)) {
        const file = zip.files[fileName];
        if (!file.dir && fileName.endsWith(".zip")) {
          try {
            const nestedZipArrayBuffer = await file.async("arraybuffer");
            const nestedZip = await JSZip.loadAsync(nestedZipArrayBuffer);
            await extractNestedZipFile(nestedZip);
          } catch (error) {
            console.error("Failed to load nested ZIP file:", error);
            resetValues();
          }
        }
      }
    } else {
      resetValues();
    }
  };

  const extractNestedZipFile = async (zip) => {
    if (!userCancelled) {
      for (const fileName of Object.keys(zip.files)) {
        const file = zip.files[fileName];
        if (!file.dir && fileName.endsWith(".pth")) {
          try {
            const content = await file.async("arraybuffer");
            const modelFile = new File([content], "model.pt", {
              type: "application/octet-stream",
            });
            startInference(modelFile);
          } catch (error) {
            console.error("Failed to extract .pt file:", error);
            resetValues();
          }
        }
      }
    } else {
      resetValues();
    }
  };

  const onChangeOpacity = (e) => {
    setOpacity(e.target.value);
  };

  const onChangeRange = (e) => {
    setOpacity(e.target.value);
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  useEffect(() => {
    if (
      modelSelectedFileImage !== "" &&
      imageInput !== "" &&
      Object.keys(error).length === 0
    ) {
      setStartDemoDisabled(false);
    } else {
      setStartDemoDisabled(true);
    }
  }, [modelSelectedFileImage, imageInput, error]);

  const onChangeVersion = (e) => {
    setModelFileID(e.target.value);
  };

  const onChangeVisualizationMethod = (e) => {
    setVisualizationMethod(e.target.value);
    handleClearBtn();
    validate("visualizationMethod", e.target.value);
  };

  const onChangeOutlineColor = (e) => {
    setOutlineColor(e.target.value);
  };

  const onChangeOutlineThickness = (e) => {
    setOutlineThickness(e.target.value);
  };

  const cancelInferencing = () => {
    if (cancelAction1.current) {
      setUserCancelled(true);
      console.log("User has canceled the action 1");
      cancelAction1.current("User has canceled the action.");
    }
    if (cancelAction2.current) {
      setUserCancelled(true);
      console.log("User has canceled the action 2");
      cancelAction2.current("User has canceled the action.");
    }
  };

  const onClickCancelDemo = () => {
    cancelInferencing();
    props?.handleCancelDemoModalClose();
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      props?.setShowCancelDemoModal(true);
      event.preventDefault();
      event.returnValue = ""; // Standard for most browsers
    };

    if (shouldWarn) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("unload", cancelInferencing);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", cancelInferencing);
    };
  }, [shouldWarn]);

  useEffect(() => {
    if (userCancelled) {
      setErrorMessage("User cancelled the action");
    }
  }, [userCancelled]);

  const handleSampleInput = (method) => {
    handleClearBtn();
    const input1 = "/inferencing-inputs/building-delineation/building1.png";
    setImageInput(input1);
    simulateFileInput(input1);
    setVisualizationMethod(method);
    if (method === "outline") {
      setOutlineColor("#d7001a"); // 215,0,26
      setOutlineThickness("3");
    }
  };

  const simulateFileInput = async (input) => {
    const fetched = await fetch(`${process.env.PUBLIC_URL + input}`);
    const blob = await fetched.blob();
    const simulatedFile = new File([blob], "building1.png", {
      type: blob.type,
    });
    // Create a new DataTransfer object and add the file
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(simulatedFile);

    // Set the files property of the input element
    if (fileInputRefImage.current) {
      fileInputRefImage.current.files = dataTransfer.files;
      setModelSelectedFileImage(fileInputRefImage.current.files[0]);
      // console.log(fileInputRefImage.current.files[0])

      // Trigger the change event
      const event = new Event("change", { bubbles: true });
      fileInputRefImage.current.dispatchEvent(event);
    }
  };

  return (
    <>
      <CancelDemoModal
        showCancelDemoModal={props?.showCancelDemoModal}
        handleCancelDemoModalClose={props?.handleCancelDemoModalClose}
        onClickCancelDemo={onClickCancelDemo}
      />
      <ModelDemoStartModal
        // modelID={id}
        handleStartDemoBtn={handleStartDemoBtn}
        showModelDemoStartModal={showModelDemoStartModal}
        handleModelDemoStartModalClose={handleModelDemoStartModalClose}
        setShowToast={props?.setShowToast}
        setToastStatus={props?.setToastStatus}
        setToastImage={props?.setToastImage}
      />
      <div className="demo-main-div">
        <div
          className="demo-div-1"
          style={{
            pointerEvents: "auto",
            cursor: demoStarted ? "not-allowed" : "unset",
          }}
        >
          <div
            className={`${
              demoStarted ? "cursor-not-allowed pointer-events-none" : ""
            }`}
          >
            <p className="bold mb-0">Demo "{props?.modelName}"</p>
            <p>{props?.modelTask}</p>
            <p className="mb-0">Version</p>
            <Form.Select
              className="input-field"
              onChange={onChangeVersion}
              value={modelFileID}
              name="demo-version"
              id="demo-version"
              data-cy="demo-version"
            >
              {versions?.map((version) => {
                return (
                  <option value={version?.id} key={version?.id}>
                    {version?.status} - {version?.version}
                  </option>
                );
              })}
            </Form.Select>
            <span className="sub-note semi-bold">
              Select a version for demo.
            </span>
            <Form.Group className="mt-3">
              <Form.Label>Visualization method</Form.Label>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  value="outline"
                  checked={visualizationMethod === "outline"}
                  onChange={onChangeVisualizationMethod}
                  label="Outline"
                  className="me-3"
                  id="outline"
                  name="outline"
                  data-cy="outline"
                />
                <Form.Check
                  type="radio"
                  value="segment"
                  checked={visualizationMethod === "segment"}
                  onChange={onChangeVisualizationMethod}
                  label="Segment map"
                  id="segment-map"
                  name="segment-map"
                  data-cy="segment-map"
                />
              </div>
              <span className="sub-note semi-bold">
                Select a visualization method for demo output.
              </span>
            </Form.Group>
            {visualizationMethod === "outline" && (
              <>
                <Form.Group className="mt-3">
                  <Form.Label>Outline color</Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      type="color"
                      value={outlineColor}
                      onChange={onChangeOutlineColor}
                      className="me-3 outline-color"
                      name="outline-color"
                      id="outline-color"
                      data-cy="outline-color"
                    />
                  </div>
                </Form.Group>
                <p className="mt-3">Outline Thickness</p>
                <Form.Select
                  className="input-field"
                  onChange={onChangeOutlineThickness}
                  value={outlineThickness}
                  name="outline-thickness"
                  id="outline-thickness"
                  data-cy="outline-thickness"
                >
                  <option value="3">3</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </>
            )}
          </div>
          <div className="flex-1"></div>
          <div className="mt-5">
            <p className="sub-note semi-bold">
              Attach a supported image format before starting the demo
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="demo-supported-files">
                    <ul>
                      <li>JPEG (Joint Photographic Experts Group)</li>
                      <li>PNG (Portable Network Graphics)</li>
                      <li>GIF (Graphics Interchange Format)</li>
                      <li>BMP (Bitmap)</li>
                      <li>TIFF (Tagged Image File Format)</li>
                      <li>RAW (Camera RAW Image)</li>
                      <li>WebP (Web Picture format)</li>
                      <li>SVG (Scalable Vector Graphics)</li>
                      <li>HEIF / HEIC (High Efficiency Image Format)</li>
                      <li>
                        DICOM (Digital Imaging and Communications in Medicine)
                      </li>
                      <li>PGM (Portable Gray Map)</li>
                      <li>PPM (Portable Pixmap)</li>
                      <li>PBM (Portable Bitmap)</li>
                      <li>HDR (High Dynamic Range)</li>
                      <li>EXR (OpenEXR)</li>
                    </ul>
                  </Tooltip>
                }
                trigger={["hover", "focus", "click"]}
              >
                <img
                  src="/images/question-gray.svg"
                  className="ms-1 img-fluid"
                  alt="question"
                />
              </OverlayTrigger>
            </p>
            <Button
              className="button w-100 m-2"
              onClick={() => setShowModelDemoStartModal(true)}
              disabled={startDemoDisabled}
              name="start"
              id="start"
              data-cy="start"
            >
              Start demo
            </Button>
            {imageInput && (
              <Button
                variant="outline-light"
                className="save-icon w-100 mt-2"
                onClick={handleClearBtn}
                name="clear"
                id="clear"
                data-cy="clear"
              >
                Clear
              </Button>
            )}
          </div>
        </div>
        <div
          className="demo-div-2"
          style={{
            pointerEvents: "auto",
            cursor: demoStarted ? "not-allowed" : "unset",
          }}
        >
          <div className="input-output-div">
            <div
              className={`input-demo-div ${
                demoStarted ? "cursor-not-allowed pointer-events-none" : ""
              }`}
            >
              <div className="input-image-div mb-4">
                <p>Input</p>
                <input type="hidden" value={counter} readOnly />
                <div
                  onDragOver={handleDragOverImage}
                  onDrop={handleDropImage}
                  onClick={handleClickImage}
                  className={`file-drop-area-demo form-control ${
                    isValid?.modelSelectedFileImage
                      ? "is-valid"
                      : isValid?.modelSelectedFileImage !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  id="demo-input1"
                  data-cy="demo-input1"
                >
                  {imageInput ? (
                    <div className="position-relative">
                      <img
                        src={imageInput}
                        ref={imageInputRef}
                        className="w-100"
                      />
                      <div
                        className="demo-remove-span"
                        onClick={handleRemoveImageBtn}
                        id="remove-input"
                        data-cy="remove-input"
                      >
                        <span>REMOVE</span>
                      </div>
                    </div>
                  ) : (
                    <p>
                      Drag and drop a supported image file here, or click to
                      browse from your device up to 25 mb
                    </p>
                  )}
                  <input
                    ref={fileInputRefImage}
                    type="file"
                    onChange={handleFileChangeImage}
                    style={{ display: "none" }}
                    accept="
                .jpeg,
                .jpg,
                .png,
                .gif,
                .bmp,
                .tiff,
                .raw,
                .webp,
                .svg,
                .heif,
                .heic,
                .dicom,
                .pgm,
                .ppm,
                .pbm,
                .hdr,
                .exr"
                  />
                </div>
                {getFormErrorMessage("modelSelectedFileImage")}
              </div>
            </div>
            <div className="output-demo-div">
              <p>Output</p>
              <div className="output-demo-image-div mb-3">
                <div
                  className={`${
                    demoProgressPercentage > 0 && !outputImage
                      ? "bg-light-color vh-20"
                      : ""
                  }`}
                  style={{
                    width:
                      demoProgressPercentage > 0 && demoProgressPercentage < 100
                        ? `${demoProgressPercentage}%`
                        : "100%",
                  }}
                >
                  {outputImage && (
                    <div className="position-relative">
                      <img
                        src={imageOutputOverlay}
                        ref={imageOutputRef}
                        style={{
                          opacity: (100 - opacity) / 100,
                          position: "absolute",
                          left: 0,
                        }}
                        className="w-100"
                      />
                      <div
                        className="demo-remove-span"
                        onClick={handleDownloadOutputBtn}
                        id="download-output"
                        data-cy="download-output"
                      >
                        <span>DOWNLOAD</span>
                      </div>
                    </div>
                  )}

                  {outputImage && (
                    <img
                      src={outputImage}
                      className="w-100"
                      style={{
                        height: outputHeight + "px",
                      }}
                      id="demo-output"
                      data-cy="demo-output"
                    />
                  )}
                  {demoProgressPercentage === 0 && !outputImage && (
                    <span
                      style={{
                        position: "absolute",
                        bottom: "50%",
                        right: "50%",
                      }}
                    >
                      <img src="/images/default-output-demo-icon.svg" />
                    </span>
                  )}
                  {demoProgressPercentage > 0 && !outputImage && (
                    <div>
                      <Spinner
                        className="spinner-model"
                        as="span"
                        animation="border"
                        size="md"
                        role="status"
                        aria-hidden="true"
                      />
                      <p>Model result is loading...</p>
                    </div>
                  )}
                </div>
              </div>
              {(outputImage || errorMessage) && (
                <div className="output-demo-summary-div">
                  <p className="bold mb-0">Summary</p>
                  <p>Computation time: {computationTime} s</p>
                  <p className="bold mt-3 mb-0">Building count:</p>
                  <p>{info}</p>
                  {outputImage ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <p className="semi-bold">Opacity</p>
                        <div>
                          <input
                            className="model-output-opacity"
                            type="number"
                            value={opacity}
                            min={0}
                            max={100}
                            onChange={onChangeOpacity}
                          />
                          %
                        </div>
                      </div>
                      <input
                        type="range"
                        onChange={onChangeRange}
                        value={opacity}
                        className="model-output-range"
                      />
                      <p className="sub-note">
                        Change the opacity of the output layer to compare with
                        the input 1 image.
                      </p>
                    </>
                  ) : (
                    <p className="error-demo-message">{errorMessage}</p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="demo-sample-inputs">
            <div>Try out these sample input!</div>
            <Table
              bordered
              hover
              className={`input-demo-table ${
                demoStarted ? "cursor-not-allowed pointer-events-none" : ""
              }`}
              onClick={() => handleSampleInput("outline")}
              id="sample-input"
              data-cy="sample-input"
            >
              <tbody>
                <tr>
                  <td className="bd-image-1">
                    <div>Image</div>
                    <img
                      src="/inferencing-inputs/building-delineation/building1.png"
                      className="w-100"
                    />
                  </td>
                  <td className="bd-visualization-1">
                    <div>Visualization method</div>Outline
                  </td>
                  <td className="bd-outline-color-1">
                    <div>Outline color</div>
                    <span className="d-flex justify-content-center">
                      <div className="outline-color-sample"></div>
                    </span>
                  </td>
                  <td className="bd-outline-thickness-1">
                    <div>Outline thickness</div>3
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table
              bordered
              hover
              className={`input-demo-table ${
                demoStarted ? "cursor-not-allowed pointer-events-none" : ""
              }`}
              onClick={() => handleSampleInput("segment")}
              id="sample-input"
              data-cy="sample-input"
            >
              <tbody>
                <tr>
                  <td className="bd-image-2">
                    <div>Image</div>
                    <img
                      src="/inferencing-inputs/building-delineation/building1.png"
                      className="w-100"
                    />
                  </td>
                  <td className="bd-visualization-2">
                    <div>Visualization method</div>Segment map
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoBuildingDelineation;
