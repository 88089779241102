import { useEffect, useRef, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { modules, formats } from "common/constants";
import ReactQuill from "react-quill";

/**
 * A module for Analyses component
 * @module components/models/sections/Analyses
 */

/**
 * Analyses component
 * @method Analyses
 *
 * @return {JSX.Element}
 *
 */
const Analyses = (props) => {
  const unitaryResultsRef = useRef();
  const intersectionalResultsRef = useRef();
  const [toolbarHiddenUnitaryResults, setToolbarHiddenUnitaryResults] =
    useState(true);
  const [isToolbarClickedUnitaryResults, setIsToolbarClickedUnitaryResults] =
    useState(false);
  const [
    toolbarHiddenIntersectionalResults,
    setToolbarHiddenIntersectionalResults,
  ] = useState(true);
  const [
    isToolbarClickedIntersectionalResults,
    setIsToolbarClickedIntersectionalResults,
  ] = useState(false);

  const onChangeModelUnitaryResults = (e) => {
    props?.setModelUnitaryResults(e);
    props?.validate("modelUnitaryResults", e);
  };

  const onChangeModelIntersectionalResults = (e) => {
    props?.setModelIntersectionalResults(e);
    props?.validate("modelIntersectionalResults", e);
  };

  /**
   * Setting the toolbar hidden of the text editor based on the name of the input field
   * @method onBlur
   *
   * @param {string} name - A string for the name of the input field
   * @param {event} e - An event object containing information about the action
   */
  const onBlurUnitaryResults = () => {
    if (!isToolbarClickedUnitaryResults) {
      setToolbarHiddenUnitaryResults(true);
    }
  };

  const handleMouseDownUnitaryResults = () => {
    setIsToolbarClickedUnitaryResults(true);
  };

  const handleMouseUpUnitaryResults = () => {
    setIsToolbarClickedUnitaryResults(false);
  };

  const onFocusUnitaryResults = () => {
    setToolbarHiddenUnitaryResults(false);
  };

  /**
   * Setting the toolbar hidden of the text editor based on the name of the input field
   * @method onBlur
   *
   * @param {string} name - A string for the name of the input field
   * @param {event} e - An event object containing information about the action
   */
  const onBlurIntersectionalResults = () => {
    if (!isToolbarClickedIntersectionalResults) {
      setToolbarHiddenIntersectionalResults(true);
    }
  };

  const handleMouseDownIntersectionalResults = () => {
    setIsToolbarClickedIntersectionalResults(true);
  };

  const handleMouseUpIntersectionalResults = () => {
    setIsToolbarClickedIntersectionalResults(false);
  };

  const onFocusIntersectionalResults = () => {
    setToolbarHiddenIntersectionalResults(false);
  };

  useEffect(() => {
    setToolbarHiddenUnitaryResults(true);
    setToolbarHiddenIntersectionalResults(true);
  }, []);

  useEffect(() => {
    // Add data-cy attribute to the Quill editor root div after it's mounted
    if (unitaryResultsRef.current) {
      const editorDiv = unitaryResultsRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "unitary-results");
    }

    if (intersectionalResultsRef.current) {
      const editorDiv = intersectionalResultsRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "intersectional-results");
    }
  }, []);

  return (
    <Container className="mw-65 ms-0">
      <p className="bold m-0">Quantitative Analyses</p>
      <p>
        Provide information on some quantitative analyses that were applied to
        this version of the model. Disaggregate the analyses into the chosen
        factors on which the model is evaluated. Provide results of evaluating
        the model according to the chosen performance metrics with confidence
        interval if possible.
      </p>
      <p>
        Demonstrate performance metric variation, e.g. with error bars, in these
        analyses. e.g. Details of quantitative analyses may include separate
        charts for False Positive Rates, False Negative Rates, False Discovery
        Rates, and False Omission Rates for all factors considered (e.g. young,
        young adult, adult, old, male, female, all) with mean and error bars.
      </p>
      <Form>
        <Form.Group
          className="mb-3"
          onMouseDown={handleMouseDownUnitaryResults}
          onMouseUp={handleMouseUpUnitaryResults}
          onBlur={onBlurUnitaryResults}
          onFocus={onFocusUnitaryResults}
        >
          <Form.Label className="m-0">Unitary results</Form.Label>
          <ReactQuill
            ref={unitaryResultsRef}
            theme="snow"
            modules={modules}
            formats={formats}
            value={props?.modelUnitaryResults}
            onChange={onChangeModelUnitaryResults}
            className={`input-description input-field form-control ${
              props?.isValid?.modelUnitaryResults
                ? "is-valid"
                : props?.isValid.modelUnitaryResults !== undefined
                ? "is-invalid"
                : ""
            } ${
              toolbarHiddenUnitaryResults
                ? "ql-editor-expanded toolbar-hidden"
                : "ql-editor-shortened toolbar-display"
            }`}
            id="unitary-results"
          />
          {props?.getFormErrorMessage("modelUnitaryResults")}
          <span className="sub-note">
            How did the model perform with respect to each factor considered in
            model development?
          </span>
        </Form.Group>
        <Form.Group
          className="mb-3"
          onMouseDown={handleMouseDownIntersectionalResults}
          onMouseUp={handleMouseUpIntersectionalResults}
          onBlur={onBlurIntersectionalResults}
          onFocus={onFocusIntersectionalResults}
        >
          <Form.Label className="m-0">Intersectional results</Form.Label>
          <ReactQuill
            ref={intersectionalResultsRef}
            theme="snow"
            modules={modules}
            formats={formats}
            value={props?.modelIntersectionalResults}
            onChange={onChangeModelIntersectionalResults}
            className={`input-description input-field form-control ${
              props?.isValid?.modelIntersectionalResults
                ? "is-valid"
                : props?.isValid.modelIntersectionalResults !== undefined
                ? "is-invalid"
                : ""
            } ${
              toolbarHiddenIntersectionalResults
                ? "ql-editor-expanded toolbar-hidden"
                : "ql-editor-shortened toolbar-display"
            }`}
            id="intersectional-results"
          />
          {props?.getFormErrorMessage("modelIntersectionalResults")}
          <span className="sub-note">
            How did the model perform with respect to the intersection of the
            identified factors?
          </span>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default Analyses;
