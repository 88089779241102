import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import { modules, formats } from "common/constants";
import { useRef, useEffect } from "react";

const Tab4 = (props) => {
  const performanceMeasuresRef = useRef();
  const decisionThresholdsRef = useRef()
  const approachesVariabilityRef = useRef()

  useEffect(() => {
    // Add data-cy attribute to the Quill editor root div after it's mounted
    if (performanceMeasuresRef.current) {
      const editorDiv = performanceMeasuresRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "performance-measures");
    }
    if (decisionThresholdsRef.current) {
      const editorDiv = decisionThresholdsRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "decision-thresholds");
    }
    if (approachesVariabilityRef.current) {
      const editorDiv = approachesVariabilityRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "approaches-variability");
    }
  }, []);

  return (
    <div className="tab-div" ref={props.elementRef4}>
      <div>
        <span className="bold">Metrics</span>
        <p>
          This section reflects potential real-world impacts of the model.
          Metrics should be determined based on the model's structure and
          intended use.
        </p>
      </div>
      <Form.Group
        onMouseDown={props.handleMouseDownModelPerformanceMeasures}
        onMouseUp={props.handleMouseUpModelPerformanceMeasures}
        onBlur={props.onBlurModelPerformanceMeasures}
        onFocus={props.onFocusModelPerformanceMeasures}
      >
        <Form.Label className="required-field">Performance Measures</Form.Label>
        <ReactQuill
          ref={performanceMeasuresRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelPerformanceMeasures}
          onChange={props.onChangeModelPerformanceMeasures}
          className={`input-field input-description form-control ${
            props.isValid?.modelPerformanceMeasures
              ? "is-valid"
              : props.isValid.modelPerformanceMeasures !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelPerformanceMeasures
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="performance-measures"
        />
        {props.getFormErrorMessage("modelPerformanceMeasures")}
        <span className="sub-note">
          What measures of model performance are being reported, and why were
          they selected over other measures of model performance?
        </span>
      </Form.Group>
      <Form.Group
        onMouseDown={props.handleMouseDownModelDecisionThresholds}
        onMouseUp={props.handleMouseUpModelDecisionThresholds}
        onBlur={props.onBlurModelDecisionThresholds}
        onFocus={props.onFocusModelDecisionThresholds}
      >
        <Form.Label className="required-field">Decision thresholds</Form.Label>
        <ReactQuill
          ref={decisionThresholdsRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelDecisionThresholds}
          onChange={props.onChangeModelDecisionThresholds}
          className={`input-field input-description form-control ${
            props.isValid?.modelDecisionThresholds
              ? "is-valid"
              : props.isValid.modelDecisionThresholds !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelDecisionThresholds
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="decision-thresholds"
        />
        {props.getFormErrorMessage("modelDecisionThresholds")}
        <span className="sub-note">
          Cite all the decision thresholds that were applied in developing the
          model. For example, the accuracy thresholds that was set for the model
          was at least of 95% accuracy due to its target application in the
          medical domain.
        </span>
      </Form.Group>
      <Form.Group
        onMouseDown={props.handleMouseDownModelApproachesToUncertainty}
        onMouseUp={props.handleMouseUpModelApproachesToUncertainty}
        onBlur={props.onBlurModelApproachesToUncertainty}
        onFocus={props.onFocusModelApproachesToUncertainty}
      >
        <Form.Label className="required-field">
          Approaches to uncertainty and variability
        </Form.Label>
        <ReactQuill
        ref={approachesVariabilityRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelApproachesToUncertainty}
          onChange={props.onChangeModelApproachesToUncertainty}
          className={`input-field input-description form-control ${
            props.isValid?.modelApproachesToUncertainty
              ? "is-valid"
              : props.isValid.modelApproachesToUncertainty !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelApproachesToUncertainty
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="approaches-variability"
        />
        {props.getFormErrorMessage("modelApproachesToUncertainty")}
        <span className="sub-note">
          How are the measurements and estimations of these metrics calculated?
          For example, this may include standard deviation, variance, confidence
          intervals, or KL divergence. Details of how these values are
          approximated should also be included (e.g., average of 5 runs, 10-fold
          cross-validation).
        </span>
      </Form.Group>
    </div>
  );
};

export default Tab4;
