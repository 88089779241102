import { Modal, Button, Offcanvas, Form } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "styles/mobile/DeleteVersionModalMobile.css"

/**
 * A module for the DeleteVersionModal Component
 * @module components/common-modals/DeleteVersionModal
 */

/**
 * Delete version in the system
 * @method DeleteVersionModal
 *
 * @return {JSX.Element}
 *
 */
const DeleteVersionModal = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  return (
    <>
      <Modal
        show={props?.showDeleteVersionModal && !isMobile}
        onHide={props?.handleDeleteVersionModalClose}
        dialogClassName="modal-32w remove-modal"
        centered
        backdrop="static"
        id="delete-version-confirmation"
        data-cy="delete-version-confirmation"
      >
        <Modal.Body>
          <p className="bold">Delete this version?</p>
          <p>All of the content and files of this version will be deleted.</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props?.handleDeleteVersionModalClose}
            className="light-btn"
            name="cancel"
            id="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={props?.onClickDeleteVersion}
            className="submit-btn"
            name="delete-version-btn"
            id="delete-version-btn"
            data-cy="delete-version-btn"
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props?.showDeleteVersionModal && isMobile}
        onHide={props?.handleDeleteVersionModalClose}
        placement="bottom"
        className="offcanvas-delete-version"
        id="delete-version-confirmation"
        data-cy="delete-version-confirmation"
      >
        <Offcanvas.Body>
          <Form>
            <p className="bold">Delete this version?</p>
            <p>All of the content and files of this version will be deleted.</p>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props?.handleDeleteVersionModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={props?.onClickDeleteVersion}
              className="submit-btn"
              name="delete-version-btn"
              id="delete-version-btn"
              data-cy="delete-version-btn"
            >
              Delete
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DeleteVersionModal;
