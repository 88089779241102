import React, { useState, useMemo, useEffect } from "react";
import {
  Container,
  Tab,
  Breadcrumb,
  Tabs,
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Offcanvas,
  Spinner,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGroup } from "actions/group";
import CustomPagination from "../layout/CustomPagination";
import AddMemberModal from "./AddMemberModal";
import EditGroupModal from "./EditGroupModal";
import RemoveMemberModal from "./RemoveMemberModal";
import DeleteGroupModal from "./DeleteGroupModal";
import { compareValues, convertDate } from "common/constants";
import { getAllModels } from "actions/model";
import "styles/GroupProfile.css";
import "styles/mobile/GroupProfileMobile.css";
import { useMediaQuery } from "react-responsive";
import MobileLeftSidebar from "components/layout/MobileLeftSidebar";

/**
 * A module for showing Group profile component
 * @module components/groups/GroupProfile
 */

/**
 * Group Profile component
 * @method GroupProfile
 *
 * @return {JSX.Element}
 *
 */

const GroupProfile = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const params = useParams();
  const id = params.id;

  /**
   * -------------------
   * * Redux store state
   * -------------------
   */
  const { models } = useSelector((state) => state.model);
  const { users, currentGroup } = useSelector((state) => state.group);
  const { user } = useSelector((state) => state.auth);

  const [modelNames, setModelNames] = useState([]);
  const [memberName, setMemberName] = useState("");
  const [memberEmail, setMemberEmail] = useState("");
  const [refreshCurrentGroupCount, setRefreshCurrentGroupCount] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);

  const [sortDirectionName, setSortDirectionName] = useState("asc");
  const [sortDirectionAddedBy, setSortDirectionAddedBy] = useState("asc");
  const [sortDirectionDateAdded, setSortDirectionDateAdded] = useState("desc");

  const [sortDirection, setSortDirection] = useState("desc");
  const [sortColumn, setSortColumn] = useState("Date added");
  const [expandedName, setExpandedName] = useState(false);
  const [expandedAddedBy, setExpandedAddedBy] = useState(false);
  const [expandedDateAdded, setExpandedDateAdded] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [expandedMemberID, setExpandedMemberID] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupDateCreated, setGroupDateCreated] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [expandedGroupProfileBtnMobile, setExpandedGroupProfileBtnMobile] =
    useState(false);

  const handleToggleExpand = (column) => {
    switch (column) {
      case "name":
        setSortDirectionName(sortDirectionName === "asc" ? "desc" : "asc");
        setSortColumn("Name");
        break;
      case "added_by":
        setSortDirectionAddedBy(
          sortDirectionAddedBy === "asc" ? "desc" : "asc"
        );
        setSortColumn("Added by");
        break;
      case "date_added":
        setSortDirectionDateAdded(
          sortDirectionDateAdded === "asc" ? "desc" : "asc"
        );
        setSortColumn("Date added");
        break;
      default:
      //
    }
  };

  const handleEditGroupModalClose = () => {
    setShowEditGroupModal(false);
  };

  const handleDeleteGroupModalClose = () => {
    setShowDeleteGroupModal(false);
  };

  const handleAddMemberModalClose = () => {
    setShowAddMemberModal(false);
  };

  const handleRemoveMemberModalClose = () => {
    setShowRemoveMemberModal(false);
  };

  const handleRemoveMemberModalShow = (name, email) => {
    setMemberEmail(email);
    setMemberName(name);
    setShowRemoveMemberModal(true);
  };

  useEffect(() => {
    props.setPreload(true);
    dispatch(getGroup(id))
      .then((res) => {
        res?.length === 0 && navigate("/404");
      })
      .catch((status) => {
        if (status === "not found") {
          navigate("/404");
        } else {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        }
      })
      .finally(() => props.setPreload(false));
    dispatch(getAllModels()).catch((error) => console.log(error));
  }, [id, refreshCurrentGroupCount]);

  useEffect(() => {
    setGroupName(currentGroup[0]?.group_name);
    if (currentGroup[0]?.group_created_at) {
      const formattedDate = convertDate(
        currentGroup[0]?.group_created_at,
        isMobile ? "MM DD, YYYY" : "MM/DD/YYYY"
      );
      setGroupDateCreated(formattedDate);
    }
    setGroupDescription(currentGroup[0]?.group_description);
  }, [currentGroup]);

  useEffect(() => {
    setGroupName("");
    setGroupDescription("");
  }, []);

  /**
   * Columns template and configuration
   */
  const columns = [
    {
      name: (
        <div
          onClick={() => handleToggleExpand("name")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Name</span>
          {sortColumn === "Name" && (
            <img
              src={
                sortDirectionName === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      width: "50%",
      cell: (row) => (
        <div className="flex d-flex justify-content-between align-items-center">
          <div>
            <img
              src="/images/user_list.svg"
              className="img-fluid icon-list"
              alt="user"
            />
            {row.name}
          </div>
          {currentGroup[0]?.group_owner === user.email &&
            currentGroup[0]?.group_owner !== row.email && (
              <>
                <Dropdown
                  show={
                    !isMobile && expandedMemberID === row.id && expanded
                      ? true
                      : false
                  }
                  onToggle={() => handleToggleExpandStatus(row.id)}
                  drop="end"
                >
                  <Dropdown.Toggle
                    variant="secondary"
                    className="three-dot-dropdown-table"
                  >
                    <img
                      src="/images/three_dot_menu.svg"
                      className="mobile-three-dot-icon-list"
                      alt="menu"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        handleRemoveMemberModalShow(row.name, row.email)
                      }
                    >
                      Remove member
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Offcanvas
                  show={isMobile && expandedMemberID === row.id && expanded}
                  onHide={() => {
                    handleToggleExpandStatus(row.id);
                  }}
                  placement="bottom"
                  className="offcanvas-choose-action"
                >
                  <Offcanvas.Body>
                    <p className="choose-action-title bold">{row.name}</p>
                    <p className="choose-action-subtitle">Choose an action</p>
                    <ul className="choose-action-ul p-0">
                      <li
                        onClick={() =>
                          onClickRemoveMemberBtn(row.name, row.email)
                        }
                      >
                        <img
                          src="/images/remove-x-icon.svg"
                          className="img-fluid icon-list"
                          alt="remove"
                        />
                        Remove member
                      </li>
                    </ul>
                  </Offcanvas.Body>
                </Offcanvas>
              </>
            )}
        </div>
      ),
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("added_by")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Added by</span>
          {sortColumn === "Added by" && (
            <img
              src={
                sortDirectionAddedBy === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      selector: (row) => {
        let added_by = "";
        const icon = (
          <img
            src="/images/user_list.svg"
            className="img-fluid icon-list"
            alt="user"
          />
        );

        added_by = (
          <span key={`addedByKey${row.id}`}>
            {icon} {row.added_by}
          </span>
        );

        return (
          <div className="row-version-added-by">
            <span>{isMobile && "Added by:"}</span> {added_by}
          </div>
        );
      },
      width: "30%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("date_added")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Date added</span>
          {sortColumn === "Date added" && (
            <img
              src={
                sortDirectionDateAdded === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      selector: (row) => (
        <div className="row-version-date-added">
          <span>{isMobile && "Date added:"} </span>
          {convertDate(row.added_date, "MM DD, YYYY")}
        </div>
      ),
      width: "20%",
    },
  ];

  useEffect(() => {
    setModelNames(
      Object.values(models).filter(
        (model) => model?.model_profile_group === currentGroup[0]?.id
      )
    );
    if (currentGroup[0] && Object.keys(users).length > 0) {
      const admin = Object.values(currentGroup[0]?.group_members)
        .map((member) => {
          const name = Object.values(users).filter(
            (user) => user.email === member.email
          );
          const added_by = Object.values(users).filter(
            (user) => user.email === member.added_by
          );
          const row = {
            id: name.length ? name[0].id : "---",
            name: name.length
              ? name[0].first_name + " " + name[0].last_name
              : "---",
            email: member.email,
            added_by: added_by.length
              ? added_by[0].first_name + " " + added_by[0].last_name
              : "---",
            added_date: member.added_date,
          };
          return row;
        })
        .filter((row) => row.email === user.email);

      const members = Object.values(currentGroup[0]?.group_members)
        .map((member) => {
          const name = Object.values(users).filter(
            (user) => user.email === member.email
          );
          const added_by = Object.values(users).filter(
            (user) => user.email === member.added_by
          );
          const row = {
            id: name.length ? name[0].id : "---",
            name: name.length
              ? name[0].first_name + " " + name[0].last_name
              : "---",
            email: member.email,
            added_by: added_by.length
              ? added_by[0].first_name + " " + added_by[0].last_name
              : "---",
            added_date: member.added_date,
          };
          return row;
        })
        .filter((row) => {
          const formattedDate = convertDate(row.date_created, "MM/DD/YYYY");
          return (
            (row.name.toLowerCase().includes(filterText.toLowerCase()) ||
              row.added_by.toLowerCase().includes(filterText.toLowerCase()) ||
              formattedDate.toLowerCase().includes(filterText.toLowerCase())) &&
            row.email !== user.email
          );
        })
        .sort((a, b) => {
          switch (sortColumn) {
            case "Name":
              return compareValues(a.name, b.name, sortDirection);
            case "Added by":
              return sortDirection === "asc"
                ? a.added_by.toLowerCase().localeCompare(b.added_by)
                : b.added_by.toLowerCase().localeCompare(a.added_by);
            default:
              return sortDirection === "asc"
                ? a.added_date.toLowerCase().localeCompare(b.added_date)
                : b.added_date.toLowerCase().localeCompare(a.added_date);
          }
        });
      setFilteredItems(admin.concat(members));
    }
  }, [currentGroup[0], filterText, users, sortDirection, sortColumn, models]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <>
        <div className="version-list-div w-100">
          <div className="search-input-container">
            <input
              type="text"
              placeholder="Search members"
              onChange={(e) => setFilterText(e.target.value)}
              className="search-input"
              name="search-member"
              id="search-member"
              data-cy="search-member"
            />
            <img
              src="/images/search-icon-gray.svg"
              className="search-icon img-fluid icon-list"
              alt="group"
            />
          </div>
          {currentGroup[0]?.group_owner === user.email && (
            <Button
              className="add-member-btn button"
              onClick={() => setShowAddMemberModal(true)}
              name="add-member"
              id="add-member"
              data-cy="add-member"
            >
              Add members
            </Button>
          )}
        </div>
      </>
    );
  }, [currentGroup[0]]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [data, setData] = useState([]); // your data array
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filteredItems, setFilteredItems] = useState([]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (selectedItemsPerPage) => {
    setCurrentPage(1);
    setItemsPerPage(selectedItemsPerPage);
  };

  const handleToggleExpandStatus = (groupMemberID) => {
    setExpanded(!expanded);
    setExpandedMemberID(groupMemberID);
  };

  useEffect(() => {
    // Simulating data fetching
    // Replace this with your actual data fetching logic

    const fetchedData = filteredItems; // Replace this with your fetched data
    setData(fetchedData);
    setTotalItems(fetchedData.length);
  }, [filteredItems]);

  useEffect(() => {
    let slicedData = data;
    if (itemsPerPage !== totalItems) {
      const start = (currentPage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      slicedData = data.slice(start, end);
    }
    setPaginatedData(slicedData);
  }, [currentPage, itemsPerPage, data, totalItems]);

  const onClickEditProfileBtn = () => {
    setShowEditGroupModal(true);
    setExpandedGroupProfileBtnMobile(false);
  };

  const onClickDeleteProfileBtn = () => {
    setShowDeleteGroupModal(true);
    setExpandedGroupProfileBtnMobile(false);
  };

  const onClickRemoveMemberBtn = (name, email) => {
    setExpanded(false);
    handleRemoveMemberModalShow(name, email);
  };
  return (
    <>
      {currentGroup[0] && currentGroup[0]?.group_owner === user.email && (
        <>
          <AddMemberModal
            groupID={id}
            groupName={currentGroup[0]?.group_name}
            groupOwner={currentGroup[0]?.group_owner}
            groupMembers={paginatedData}
            showAddMemberModal={showAddMemberModal}
            handleAddMemberModalClose={handleAddMemberModalClose}
            setShowToast={props?.setShowToast}
            setToastStatus={props?.setToastStatus}
            setToastImage={props?.setToastImage}
            setRefreshCurrentGroupCount={setRefreshCurrentGroupCount}
            refreshCurrentGroupCount={refreshCurrentGroupCount}
          />

          <EditGroupModal
            groupID={id}
            groupName={currentGroup[0]?.group_name}
            groupDescription={currentGroup[0]?.group_description}
            groupMembers={currentGroup[0]?.group_members}
            showEditGroupModal={showEditGroupModal}
            handleEditGroupModalClose={handleEditGroupModalClose}
            setShowToast={props?.setShowToast}
            setToastStatus={props?.setToastStatus}
            setToastImage={props?.setToastImage}
          />

          <RemoveMemberModal
            groupID={id}
            groupName={currentGroup[0]?.group_name}
            memberName={memberName}
            memberEmail={memberEmail}
            showRemoveMemberModal={showRemoveMemberModal}
            handleRemoveMemberModalClose={handleRemoveMemberModalClose}
            setShowToast={props?.setShowToast}
            setToastStatus={props?.setToastStatus}
            setToastImage={props?.setToastImage}
            setRefreshCurrentGroupCount={setRefreshCurrentGroupCount}
            refreshCurrentGroupCount={refreshCurrentGroupCount}
          />
          <DeleteGroupModal
            groupID={id}
            showDeleteGroupModal={showDeleteGroupModal}
            handleDeleteGroupModalClose={handleDeleteGroupModalClose}
            setShowToast={props?.setShowToast}
            setToastStatus={props?.setToastStatus}
            setToastImage={props?.setToastImage}
          />
        </>
      )}
      <div className="mw-100 group-profile-container">
        <div className="group-profile-mobile-header">
          <MobileLeftSidebar
            setUrlToRedirected={props.setUrlToRedirected}
            setShowDiscardChangesModal={props.setShowDiscardChangesModal}
            changesMade={props.changesMade}
          />
          <Breadcrumb id="group-breadcrumb" data-cy="group-breadcrumb">
            <Breadcrumb.Item active>Groups</Breadcrumb.Item>
            <Breadcrumb.Item
              className="link"
              linkAs={Link}
              linkProps={{ to: "/groups" }}
            >
              Group Profiles
            </Breadcrumb.Item>
          </Breadcrumb>
          {isMobile && (
            <>
              <p
                className="bold group-profile-title"
                id="group-name"
                data-cy="group-name"
              >
                {groupName}
              </p>
              {currentGroup[0]?.group_owner === user.email && (
                <div>
                  <img
                    src="/images/three_dot_menu.svg"
                    className="mobile-three-dot-icon"
                    alt="menu"
                    onClick={() => setExpandedGroupProfileBtnMobile(true)}
                    id="more-btn"
                    data-cy="more-btn"
                  />
                  <Offcanvas
                    show={expandedGroupProfileBtnMobile}
                    onHide={() => setExpandedGroupProfileBtnMobile(false)}
                    placement="bottom"
                    className="offcanvas-choose-action"
                  >
                    <Offcanvas.Body>
                      <p
                        className="choose-action-title bold"
                        id="group-name"
                        data-cy="group-name"
                      >
                        {groupName}
                      </p>
                      <p className="choose-action-subtitle">Choose an action</p>
                      <ul className="choose-action-ul p-0">
                        <li
                          onClick={onClickEditProfileBtn}
                          id="edit-group"
                          data-cy="edit-group"
                        >
                          Edit group card
                        </li>
                        {modelNames?.length < 1 && (
                          <li
                            onClick={onClickDeleteProfileBtn}
                            id="delete-group"
                            data-cy="delete-group"
                          >
                            Delete group
                          </li>
                        )}
                      </ul>
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
              )}
            </>
          )}
        </div>
        {!isMobile && (
          <div className="d-flex justify-content-between">
            <p className="name-profile" id="group-name" data-cy="group-name">
              {groupName}
            </p>
            {currentGroup[0]?.group_owner === user.email && (
              <Dropdown>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>More</Tooltip>}
                  trigger={["hover", "focus"]}
                >
                  <Dropdown.Toggle
                    variant="secondary"
                    className="three-dot-dropdown"
                    id="more-btn"
                    data-cy="more-btn"
                  >
                    <img
                      src="/images/three_dot_menu.svg"
                      className="img-fluid"
                      alt="menu"
                    />
                  </Dropdown.Toggle>
                </OverlayTrigger>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => setShowEditGroupModal(true)}
                    id="edit-group"
                    data-cy="edit-group"
                  >
                    Edit group card
                  </Dropdown.Item>
                  {modelNames?.length < 1 && (
                    <Dropdown.Item
                      onClick={() => setShowDeleteGroupModal(true)}
                      id="delete-group"
                      data-cy="delete-group"
                    >
                      Delete group
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        )}
        {!isMobile && (
          <div className="d-flex labels">
            {modelNames?.length > 0 &&
              Object.values(modelNames).map((modelName) => (
                <div>
                  <span>{modelName.model_profile_name}</span>
                </div>
              ))}
          </div>
        )}
        <Tabs
          defaultActiveKey="group-card"
          id="group-tabs"
          className="group-profile-tabs pt-85-px"
        >
          <Tab
            eventKey="group-card"
            tabClassName="tabs"
            title="Group card"
            tabAttrs={{ "data-cy": "group-card-tab" }}
          >
            {isMobile && (
              <>
                <div
                  className="bold mobile-group-profile-name mb-2"
                  id="group-profile-name"
                  data-cy="group-profile-name"
                >
                  {groupName}
                </div>
                {modelNames?.length > 0 && <p>This group is assigned to:</p>}

                <div className="d-flex labels">
                  {modelNames?.length > 0 &&
                    Object.values(modelNames).map((modelName, index) => (
                      <div key={index}>
                        <span>{modelName.model_profile_name}</span>
                      </div>
                    ))}
                </div>
              </>
            )}
            <p className="bold">Date created</p>
            <p>{groupDateCreated}</p>
            <p className="bold">Description</p>
            <div className="ql-snow">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: groupDescription,
                }}
              ></div>
            </div>
          </Tab>
          <Tab
            eventKey="group-members"
            tabClassName="tabs"
            title="Group members"
            tabAttrs={{ "data-cy": "group-members-tab" }}
          >
            <div className="data-table-div">
              <p className="bold mt-3 ms-3">Members</p>

              <DataTable
                className="group-members-datatable"
                columns={columns}
                data={paginatedData}
                pagination={false}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                noTableHead={isMobile}
              />
              <div className="mb-4 d-flex justify-content-center">
                {loading && (
                  <Spinner
                    className="spinner"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </div>
              {!isMobile && (
                <CustomPagination
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onChange={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default GroupProfile;
