import DemoChangeDetection from "components/layout/DemoChangeDetection";
import DemoObjectDetection from "components/layout/DemoObjectDetection";
import DemoBuildingDelineation from "components/layout/DemoBuildingDelineation";
import "styles/ModelDemo.css";
import "styles/mobile/ModelDemoMobile.css";

const ModelDemo = (props) => {
  return (
    <>
      {props?.demoToInference === "change detection" && (
        <DemoChangeDetection
          modelVersions={props?.modelVersions}
          modelName={props?.modelName}
          modelTask={props?.modelTask}
          setDisableLayout={props?.setDisableLayout}
          showCancelDemoModal={props?.showCancelDemoModal}
          setShowCancelDemoModal={props?.setShowCancelDemoModal}
          handleCancelDemoModalClose={props?.handleCancelDemoModalClose}
        />
      )}
      {props?.demoToInference === "object detection" && (
        <DemoObjectDetection
          modelVersions={props?.modelVersions}
          modelName={props?.modelName}
          modelTask={props?.modelTask}
          setDisableLayout={props?.setDisableLayout}
          showCancelDemoModal={props?.showCancelDemoModal}
          setShowCancelDemoModal={props?.setShowCancelDemoModal}
          handleCancelDemoModalClose={props?.handleCancelDemoModalClose}
        />
      )}
      {props?.demoToInference === "building delineation" && (
        <DemoBuildingDelineation
          modelVersions={props?.modelVersions}
          modelName={props?.modelName}
          modelTask={props?.modelTask}
          setDisableLayout={props?.setDisableLayout}
          showCancelDemoModal={props?.showCancelDemoModal}
          setShowCancelDemoModal={props?.setShowCancelDemoModal}
          handleCancelDemoModalClose={props?.handleCancelDemoModalClose}
        />
      )}
    </>
  );
};

export default ModelDemo;
