import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import "styles/Home.css"
import "styles/mobile/HomeMobile.css"

/**
 * A module for Home Page component
 * @module components/Home
 */

/**
 * Home Page component
 * @method Home
 *
 * @return {JSX.Element}
 *
 */
const Home = (props) => {
  /**
   * -------------------
   * * Redux store state
   * -------------------
   */
  const { isLoggedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  /**
   *  Navigate to the model list if the user already logged in
   */
  if (isLoggedIn) {
    return <Navigate to="/models" />;
  }

  return (
    <div className="home-container-1">
      <span className="animated-square"></span>
      <img
        src="/images/animated-triangle.svg"
        className="animated-triangle"
        alt="triangle"
      />
       <img
        src="/images/animated-circle.svg"
        className="animated-circle"
        alt="circle"
      />
      <div className="home-row-1">
        <div className="col-md-7">
          <div className="lp-left">
            <div className="lp-r1 d-flex align-items-center">
              <img
                src="/images/dimer-logo-2024.png"
                className="dimer-logo"
                alt="logo"
              />
              <span className="medium ms-2">DIMER</span>
            </div>
            <div className="lp-r2">
              <p className="medium">
                Your AI models and datasets all in one place.
              </p>
            </div>
            <div className="lp-r3">
              <p>
                Decentralized Intelligent Model Exchange Repository (DIMER) is
                an online model store that aims to democratize access to
                advanced AI technologies that will allow end-users to contribute
                and utilize optimized models for their unique applications.
              </p>
            </div>
            <div className="lp-r4">
              <p className="medium">Create an account for free!</p>
            </div>
            <div className="lp-r5 d-flex align-items-center">
              <div className="position-relative">
                <div
                  style={{
                    position: "absolute",
                    top: "-10px",
                    left: 0,
                  }}
                >
                  <div className="position-relative">
                    <img
                      src="/images/lp-circle.svg"
                      className=""
                      alt="circle"
                    />
                    <img
                      src="/images/lp-icon-1.png"
                      className=""
                      alt="icon-1"
                      style={{
                        position: "absolute",
                        top: "12px",
                        left: "11px",
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  </div>
                </div>
                <div className="lp-icon-label">
                  <p>CONTRIBUTE MODELS & DATASETS</p>
                </div>
              </div>
              <img src="/images/lp-line.svg" className="lp-line" alt="line" />
              <div className="position-relative">
                <div
                  style={{
                    position: "absolute",
                    top: "-10px",
                    left: 0,
                  }}
                >
                  <div className="position-relative">
                    <img
                      src="/images/lp-circle.svg"
                      className=""
                      alt="circle"
                    />
                    <img
                      src="/images/lp-icon-2.png"
                      className=""
                      alt="icon-1"
                      style={{
                        position: "absolute",
                        top: "12px",
                        left: "11px",
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  </div>
                </div>
                <div className="lp-icon-label">
                  <p>UTILIZE OPTIMIZED MODELS & DATASETS </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 home-col-2">
          <div className="lp-right">
            {props?.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
