import { Modal, Button } from "react-bootstrap";

/**
 * A module for the DownloadModal Component
 * @module components/datasets/DownloadModal
 */

/**
 * Download dataset version in the system
 * @method DownloadModal
 *
 * @return {JSX.Element}
 *
 */
const DownloadModal = (props) => {
  const onClickDownload = (datasetProfileID, datasetVersionID) => {
    props?.downloadDatasetVersion(datasetProfileID, datasetVersionID);
    props?.handleDownloadModalClose();
  };

  return (
    <>
      <Modal
        show={props?.showDownloadModal}
        onHide={props?.handleDownloadModalClose}
        dialogClassName="modal-32w"
        centered
        backdrop="static"
      >
        <Modal.Body className="p-5">
          <p className="bold text-center">Download this version?</p>
          <p>
            By downloading this file, you acknowledge that it is your
            responsibility to contact the owner for any queries.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props?.handleDownloadModalClose}
            className="light-btn"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              onClickDownload(props?.datasetProfileID, props?.datasetVersionID)
            }
            className="submit-btn button"
          >
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DownloadModal;
