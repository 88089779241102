import Moment from "react-moment";

const backEndProtocol = "https"; // back-end internet protocol
const ip = backEndProtocol + "://";
export const version = "y4-v1.1b"

// const ipAddress = "localhost"; // local
const ipAddress = "202.90.159.111" // test server
// const ipAddress = "192.168.15.78" // test server
// const ipAddress = "192.168.15.99" // for staging

export const IP_ADDRESS = ip + ipAddress + ":8005"; // main backend
export const IP_ADDRESS_2 = ip + ipAddress + ":8001"; // object detection
export const IP_ADDRESS_3 = ip + ipAddress + ":8002"; // building delienation
export const IP_ADDRESS_4 = ip + ipAddress + ":8003"; // change detection

// // DNS names for backend services
// const mainBackendDNS = "dimer1.example.com"; // DNS for main backend
// const objectDetectionDNS = "dimer2.example.com"; // DNS for object detection
// const buildingDelineationDNS = "dimer3.example.com"; // DNS for building delineation
// const changeDetectionDNS = "dimer4.example.com"; // DNS for change detection

// export const IP_ADDRESS = ip + mainBackendDNS + ":8000"; // main backend
// export const IP_ADDRESS_2 = ip + objectDetectionDNS + ":8002"; // object detection
// export const IP_ADDRESS_3 = ip + buildingDelineationDNS + ":8003"; // building delineation
// export const IP_ADDRESS_4 = ip + changeDetectionDNS + ":8004"; // change detection

export const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    // ['link', 'image'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    // ["link", "image"],
    ["clean"], // remove formatting button
  ],
};

export const formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "intent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  // "link",
  // "image",
  // "video",
];

export const compareValues = (a, b, sortDirection) => {
  const regex = /(\D+)|(\d+)/g; // Regular expression to match non-digits and digits globally
  const partsA = a.match(regex); // Extract all non-digit and digit parts from string 'a'
  const partsB = b.match(regex); // Extract all non-digit and digit parts from string 'b'

  // Compare each part separately
  for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
    const partA = partsA[i];
    const partB = partsB[i];

    const isDigitA = !isNaN(parseInt(partA)); // Check if partA is a digit
    const isDigitB = !isNaN(parseInt(partB)); // Check if partB is a digit

    if (isDigitA && isDigitB) {
      const intA = parseInt(partA);
      const intB = parseInt(partB);

      if (intA !== intB) {
        return sortDirection === "asc" ? intA - intB : intB - intA; // Compare numeric parts
      }
    } else if (!isDigitA && !isDigitB) {
      const cmp =
        sortDirection === "asc"
          ? partA.localeCompare(partB)
          : partB.localeCompare(partA);

      if (cmp !== 0) {
        return cmp; // Compare non-numeric parts
      }
    } else {
      // Numeric parts should come before non-numeric parts
      return (sortDirection === "asc" ? isDigitA : isDigitB) ? -1 : 1;
    }
  }

  // If all parts are the same, compare the original strings
  return sortDirection === "asc" ? a.localeCompare(b) : b.localeCompare(a);
};

export const convertDate = (param, option) => {
  const dateString = param;
  const date = new Date(dateString);

  if (option === "MM DD, YYYY") {
    const month = date.toLocaleString("default", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear());

    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
  } else if (option === "DD/MM/YYYY") {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear());

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  } else if (option === "YYYY-MM-DD") {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear());

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  } else if (option === "MM/DD/YYYY") {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear());

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }
};

export const convertBytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0) return "0 Byte";

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(100 * (bytes / Math.pow(1024, i))) / 100 + " " + sizes[i];
};

export const displayNotifications = (
  id,
  title,
  message,
  status,
  date,
  url,
  onClickNotification
) => {
  let image = "";
  let result = "";
  const titleLowercase = title.toLowerCase();

  if (titleLowercase.includes("subscription add")) {
    // const msg = message.split("from");
    // result = (
    //   <>
    //     {msg[0]} from <span className="bold">{msg[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-subscription-added.svg"
        alt="heart"
        className="me-3"
      />
    );
  } else if (titleLowercase.includes("subscription remove")) {
    // const msg = message.split("from");
    // result = (
    //   <>
    //     {msg[0]} from <span className="bold">{msg[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-subscription-removed.svg"
        alt="heart"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("profile update") &&
    titleLowercase.includes("model")
  ) {
    // const msg = message.split("was updated");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> was updated
    //   </>
    // );
    image = (
      <img
        src="/images/notification-model-profile-updated.svg"
        alt="heart"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("profile delete") &&
    titleLowercase.includes("model")
  ) {
    // const msg = message.split("deleted the model");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> deleted the model{" "}
    //     <span className="bold">{msg[1]}</span>
    //   </>
    // );
    image = (
      <>
        <img
          src="/images/notification-model-profile-deleted-box.svg"
          alt="box"
        />
        <img
          src="/images/notification-model-profile-deleted-trash.svg"
          alt="trash"
          className="me-3"
        />
      </>
    );
  } else if (
    titleLowercase.includes("version add") &&
    titleLowercase.includes("model")
  ) {
    // const msg = message.split("added");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> added
    //     <span className="bold">{msg[1].split("for model")[0]}</span> for model
    //     <span className="bold">{msg[1].split("for model")[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-version-added.svg"
        alt="file"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("version was update") &&
    titleLowercase.includes("model")
  ) {
    // const msg = message.split("updated");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> updated
    //     <span className="bold">{msg[1].split("for model")[0]}</span> for model
    //     <span className="bold">{msg[1].split("for model")[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-version-updated.svg"
        alt="file"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("version was published") &&
    titleLowercase.includes("model")
  ) {
    // const msg = message.split("publish");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> published
    //     <span className="bold">{msg[1].split("for model")[0]}</span> for model
    //     <span className="bold">{msg[1].split("for model")[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-version-published.svg"
        alt="file"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("version was archived") &&
    titleLowercase.includes("model")
  ) {
    // const msg = message.split("archive");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> archived
    //     <span className="bold">{msg[1].split("for model")[0]}</span> for model
    //     <span className="bold">{msg[1].split("for model")[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-version-archived.svg"
        alt="file"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("version was deleted") &&
    titleLowercase.includes("model")
  ) {
    // const msg = message.split("delete");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> deleted
    //     <span className="bold">{msg[1].split("for model")[0]}</span> for model
    //     <span className="bold">{msg[1].split("for model")[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-version-deleted.svg"
        alt="file"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("profile update") &&
    titleLowercase.includes("dataset")
  ) {
    // const msg = message.split("was updated");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> was updated
    //   </>
    // );
    image = (
      <img
        src="/images/notification-model-profile-updated.svg"
        alt="heart"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("profile delete") &&
    titleLowercase.includes("dataset")
  ) {
    // const msg = message.split("deleted the model");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> deleted the model{" "}
    //     <span className="bold">{msg[1]}</span>
    //   </>
    // );
    image = (
      <>
        <img
          src="/images/notification-model-profile-deleted-box.svg"
          alt="box"
        />
        <img
          src="/images/notification-model-profile-deleted-trash.svg"
          alt="trash"
          className="me-3"
        />
      </>
    );
  } else if (
    titleLowercase.includes("version add") &&
    titleLowercase.includes("dataset")
  ) {
    // const msg = message.split("added");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> added
    //     <span className="bold">{msg[1].split("for model")[0]}</span> for model
    //     <span className="bold">{msg[1].split("for model")[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-version-added.svg"
        alt="file"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("version was update") &&
    titleLowercase.includes("dataset")
  ) {
    // const msg = message.split("updated");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> updated
    //     <span className="bold">{msg[1].split("for model")[0]}</span> for model
    //     <span className="bold">{msg[1].split("for model")[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-version-updated.svg"
        alt="file"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("version was published") &&
    titleLowercase.includes("dataset")
  ) {
    // const msg = message.split("publish");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> published
    //     <span className="bold">{msg[1].split("for model")[0]}</span> for model
    //     <span className="bold">{msg[1].split("for model")[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-version-published.svg"
        alt="file"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("version was archived") &&
    titleLowercase.includes("dataset")
  ) {
    // const msg = message.split("archive");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> archived
    //     <span className="bold">{msg[1].split("for model")[0]}</span> for model
    //     <span className="bold">{msg[1].split("for model")[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-version-archived.svg"
        alt="file"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("version was deleted") &&
    titleLowercase.includes("dataset")
  ) {
    // const msg = message.split("delete");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> deleted
    //     <span className="bold">{msg[1].split("for model")[0]}</span> for model
    //     <span className="bold">{msg[1].split("for model")[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-version-deleted.svg"
        alt="file"
        className="me-3"
      />
    );
  } else if (titleLowercase.includes("added to the group")) {
    // const msg = message.split("added");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> added
    //     <span className="bold">{msg[1].split("to the group")[0]}</span> to the group
    //     <span className="bold">{msg[1].split("to the group")[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-added-member.svg"
        alt="member"
        className="me-3"
      />
    );
  } else if (
    titleLowercase.includes("removed") &&
    titleLowercase.includes("group")
  ) {
    // const msg = message.split("removed");
    // result = (
    //   <>
    //     <span className="bold">{msg[0]}</span> removed
    //     <span className="bold">{msg[1].split("to the group")[0]}</span> from the group
    //     <span className="bold">{msg[1].split("to the group")[1]}</span>
    //   </>
    // );
    image = (
      <img
        src="/images/notification-removed-member.svg"
        alt="member"
        className="me-3"
      />
    );
  }

  if (title.includes("deleted")) {
    url = "/404";
  }
  // Create a new notification item element
  const newElement = (
    <div
      className="d-flex p-3 notification-item"
      key={date}
      onClick={() => onClickNotification(id, url)}
    >
      <div>{image}</div>
      <div className="w-100">
        <p className="semi-bold mb-0 notification-message d-flex justify-content-between align-items-center">
          {title}
          {!status && (
            <img src="/images/notification-unread-dot.svg" alt="unread" />
          )}
        </p>
        <p className="mb-0 notification-message">{message}</p>
        <p className="pb-0 notification-time mb-0">
          <Moment fromNow>{date}</Moment>
        </p>
      </div>
    </div>
  );

  return newElement;
};

// export const capitalizeFirstLetter = (word) => {
//   return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
// }
