import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import { modules, formats } from "common/constants";
import { useRef, useEffect } from "react";

const Tab2 = (props) => {
  const intendedUsesRef = useRef();
  const intendedUsersRef = useRef();
  const outScopeRef = useRef();

  useEffect(() => {
    // Add data-cy attribute to the Quill editor root div after it's mounted
    if (intendedUsesRef.current) {
      const editorDiv = intendedUsesRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "intended-uses");
    }

    if (intendedUsersRef.current) {
      const editorDiv = intendedUsersRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "intended-users");
    }

    if (outScopeRef.current) {
      const editorDiv = outScopeRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "out-scope");
    }
  }, []);

  return (
    <div className="tab-div" ref={props.elementRef2}>
      <div>
        <span className="bold">Intended use and limitations</span>
        <p>Provide use cases that were envisioned during model development.</p>
      </div>
      <Form.Group
        onMouseDown={props.handleMouseDownModelPrimaryIntendedUses}
        onMouseUp={props.handleMouseUpModelPrimaryIntendedUses}
        onBlur={props.onBlurModelPrimaryIntendedUses}
        onFocus={props.onFocusModelPrimaryIntendedUses}
      >
        <Form.Label className="required-field">
          Primary intended uses
        </Form.Label>
        <ReactQuill
          ref={intendedUsesRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelPrimaryIntendedUses}
          onChange={props.onChangeModelPrimaryIntendedUses}
          className={`input-field input-description form-control ${
            props.isValid?.modelPrimaryIntendedUses
              ? "is-valid"
              : props.isValid.modelPrimaryIntendedUses !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelPrimaryIntendedUses
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="intended-uses"
        />
        {props.getFormErrorMessage("modelPrimaryIntendedUses")}
        <span className="sub-note">
          Describe the intended general or specific machine learning tasks in
          mind. Use cases may be as broadly or narrowly defined as the
          developers intended. For example, if the model was built simply to
          label images, then this task should be indicated as the primary
          intended use case.
        </span>
      </Form.Group>
      <Form.Group
        onMouseDown={props.handleMouseDownModelPrimaryIntendedUsers}
        onMouseUp={props.handleMouseUpModelPrimaryIntendedUsers}
        onBlur={props.onBlurModelPrimaryIntendedUsers}
        onFocus={props.onFocusModelPrimaryIntendedUsers}
      >
        <Form.Label className="required-field">
          Primary intended users
        </Form.Label>
        <ReactQuill
          ref={intendedUsersRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelPrimaryIntendedUsers}
          onChange={props.onChangeModelPrimaryIntendedUsers}
          className={`input-field input-description form-control ${
            props.isValid?.modelPrimaryIntendedUsers
              ? "is-valid"
              : props.isValid.modelPrimaryIntendedUsers !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelPrimaryIntendedUsers
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="intended-users"
        />
        {props.getFormErrorMessage("modelPrimaryIntendedUsers")}
        <span className="sub-note">
          Describe the primary intended users of the model. This helps users
          gain insight into how robust the model may be to different kinds of
          inputs. For example, was the model developed for entertainment
          purposes, for hobbyists, or enterprise solutions?
        </span>
      </Form.Group>
      <Form.Group
        onMouseDown={props.handleMouseDownModelOutOfScopeUseCases}
        onMouseUp={props.handleMouseUpModelOutOfScopeUseCases}
        onBlur={props.onBlurModelOutOfScopeUseCases}
        onFocus={props.onFocusModelOutOfScopeUseCases}
      >
        <Form.Label className="required-field">
          Out-of-scope use cases
        </Form.Label>
        <ReactQuill
          ref={outScopeRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelOutOfScopeUseCases}
          onChange={props.onChangeModelOutOfScopeUseCases}
          className={`input-field input-description form-control ${
            props.isValid?.modelOutOfScopeUseCases
              ? "is-valid"
              : props.isValid.modelOutOfScopeUseCases !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelOutOfScopeUseCases
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="out-scope"
        />
        {props.getFormErrorMessage("modelOutOfScopeUseCases")}
        <span className="sub-note">
          Describe possible usages of the model that are outside the scope
          intended by its developers. This is inspired by warning labels on food
          and toys, and similar disclaimers presented in electronic datasheets.
          For example, "not for use on text examples shorter than 100 tokens".
        </span>
      </Form.Group>
    </div>
  );
};

export default Tab2;
