import { useState, useMemo, useEffect, useRef } from "react";
import { Breadcrumb, Button, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "actions/group";
import CustomPagination from "components/layout/CustomPagination";
import FilterDropdown from "components/layout/FilterDropdown";
import {
  editSubscribeModelProfile,
  getAllModels,
  getAllSubscribeModelProfile,
  getModel,
  getSubscribeModelProfile,
} from "actions/model";
import { compareValues, convertDate } from "common/constants";
import "styles/ModelList.css";
import "styles/ModelListMobile.css";
import { useMediaQuery } from "react-responsive";
import MobileLeftSidebar from "components/layout/MobileLeftSidebar";

/**
 * A module for Listing Models component
 * @module components/models/Models
 */

/**
 * Listing Models component
 * @method Models
 *
 * @return {JSX.Element}
 *
 */

const ModelList = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const scrollRef = useRef(null);

  /**
   * -------------------
   * * Redux store state
   * -------------------
   */
  const { models, subscribedModels, currentSubscribedModel, currentModel } =
    useSelector((state) => state.model);
  const { groups, users } = useSelector((state) => state.group);
  const { user } = useSelector((state) => state.auth);

  const [sortDirection, setSortDirection] = useState("desc");
  const [sortDirectionName, setSortDirectionName] = useState("asc");
  const [sortDirectionTask, setSortDirectionTask] = useState("asc");
  const [sortDirectionGroup, setSortDirectionGroup] = useState("asc");
  const [sortDirectionCreatedBy, setSortDirectionCreatedBy] = useState("asc");
  const [sortDirectionDateCreated, setSortDirectionDateCreated] =
    useState("desc");
  const [sortColumn, setSortColumn] = useState("");
  const [refreshSubscribe, setRefreshSubscribe] = useState(0);
  const [reloadCounter, setReloadCounter] = useState(0);
  const [displayMobileSearchList, setDisplayMobileSearchList] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggleExpand = (column) => {
    switch (column) {
      case "name":
        setSortDirectionName(sortDirectionName === "asc" ? "desc" : "asc");
        setSortColumn("Name");
        break;
      case "task":
        setSortDirectionTask(sortDirectionTask === "asc" ? "desc" : "asc");
        setSortColumn("Task");
        break;
      case "group":
        setSortDirectionGroup(sortDirectionGroup === "asc" ? "desc" : "asc");
        setSortColumn("Group");
        break;
      case "created_by":
        setSortDirectionCreatedBy(
          sortDirectionCreatedBy === "asc" ? "desc" : "asc"
        );
        setSortColumn("Created by");
        break;
      case "date_created":
        setSortDirectionDateCreated(
          sortDirectionDateCreated === "asc" ? "desc" : "asc"
        );
        setSortColumn("Date created");
        break;
      default:
      //
    }
  };

  const handleSubscribe = (id, isSubscribed, event) => {
    console.log("subscribe")
    dispatch(getModel(id))
      .then((res) => {
        res?.length === 0 && navigate("/404");
      })
      .catch((status) => {
        props.setShowToast(true);
        props.setToastStatus(status);
        props.setToastImage(null);
      });
    if (isSubscribed) {
      dispatch(editSubscribeModelProfile(id))
        .then((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage("/images/subscription-removed-success.svg");
          setRefreshSubscribe(refreshSubscribe + 1);
        })
        .catch((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        });
    } else {
      dispatch(editSubscribeModelProfile(id))
        .then((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage("/images/subscription-added-success.svg");
          setRefreshSubscribe(refreshSubscribe + 1);
        })
        .catch((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        });
    }
    event.stopPropagation();
  };
  /**
   * Columns template and configuration
   */
  const columns = [
    {
      name: (
        <div
          onClick={() => handleToggleExpand("name")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Name</span>
          {sortColumn === "Name" && (
            <img
              src={
                sortDirectionName === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      cell: (row) => (
        <div
          className={`flex d-flex justify-content-between align-items-center row-profile-name`}
          onClick={() => handleRowClick(row)}
        >
          <div>{row.model_profile_name}</div>
          <div
            onClick={(event) =>
              handleSubscribe(row.id, row.model_subscribe, event)
            }
            id={`subscribe-${row.id}`}
            data-cy="subscribe"
            className="heart-div"
          >
            <img
              src={
                row?.model_subscribe
                  ? "/images/subscribed.svg"
                  : "/images/not-subscribed.svg"
              }
              className="img-fluid heart"
              alt="menu"
            />
          </div>
        </div>
      ),
      width: "37%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("task")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Task</span>
          {sortColumn === "Task" && (
            <img
              src={
                sortDirectionTask === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      cell: (row) => (
        <div className="row-profile-task">{row.model_profile_task}</div>
      ),
      width: "15%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("created_by")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Created by</span>
          {sortColumn === "Created by" && (
            <img
              src={
                sortDirectionCreatedBy === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      selector: (row) => {
        const model_user = Object.values(users).find(
          (user) => user.email === row.model_created_by
        );
        const icon = (
          <img
            src="/images/user_list.svg"
            className="img-fluid icon-list"
            alt="user"
          />
        );
        const model_user_name =
          user.email === row.model_created_by
            ? "me"
            : model_user?.first_name + " " + model_user?.last_name;

        return (
          <div className="row-profile-owner">
            <span
              onClick={() => handleRowClick(row)}
              key={`createdByKey${row.id}`}
            >
              {icon}
              {model_user_name}
            </span>
          </div>
        );
      },
      width: "15%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("group")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Group</span>
          {sortColumn === "Group" && (
            <img
              src={
                sortDirectionGroup === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      selector: (row) => {
        const model_group = Object.values(groups).find(
          (group) => group.id === row.model_profile_group
        );
        return (
          <div className="row-profile-group">
            {model_group?.group_name || "Unassigned"}
          </div>
        );
      },
      width: "15%",
    },

    {
      name: (
        <div
          onClick={() => handleToggleExpand("date_created")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Date created</span>
          {sortColumn === "Date created" && (
            <img
              src={
                sortDirectionDateCreated === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      selector: (row) => {
        const dateString = row.model_date_create;
        const date = new Date(dateString);
        const formattedDate = isMobile
          ? convertDate(date, "MM DD, YYYY")
          : convertDate(date, "MM/DD/YYYY");

        return <div className="row-profile-date-created">{formattedDate}</div>;
      },
      width: "18%",
    },
  ];

  /**
   * -------------------------
   * * Component state
   *  ------------------------
   */
  const [filterText, setFilterText] = useState("");

  const [selectedOptionsTasks, setSelectedOptionsTasks] = useState([]);
  const [selectedOptionsCreatedBy, setSelectedOptionsCreatedBy] = useState([]);
  const [selectedOptionsSubscriptions, setSelectedOptionsSubscriptions] =
    useState([]);

  const [clearFilter, setClearFilter] = useState(false);

  const handleClearFilterBtnClick = () => {
    setSelectedOptionsTasks([]);
    setSelectedOptionsCreatedBy([]);
    setSelectedOptionsSubscriptions([]);
    setClearFilter(true);
  };

  useEffect(() => {
    // handleItemsPerPageChange(itemsPerPage);
    setFilteredItems(
      Object.values(models)
        .map((item) => {
          let model_subscribe = false;
          if (subscribedModels?.subscribe_user?.length > 0) {
            const subscribe = Object.values(
              subscribedModels?.subscribe_user
            ).find((i) => i === item.id);
            model_subscribe = subscribe ? true : false;
          }
          const updatedItem = {
            id: item.id,
            model_created_by: item.model_created_by,
            model_date_create: item.model_date_create,
            model_profile_description: item.model_profile_description,
            model_profile_group: item.model_profile_group,
            model_profile_name: item.model_profile_name,
            model_profile_task: item.model_profile_task,
            model_subscribe: model_subscribe,
          };

          return updatedItem;
        })
        .filter((item) => {
          const dateString = item.model_date_create;
          const date = new Date(dateString);

          const formattedDate = isMobile
            ? convertDate(date, "MM DD, YYYY")
            : convertDate(date, "MM/DD/YYYY");

          return (
            (item.model_profile_name
              .toLowerCase()
              .includes(filterText.toLowerCase()) ||
              item.model_profile_task
                .toLowerCase()
                .includes(filterText.toLowerCase()) ||
              Object.values(groups)
                .find((group) => group.id === item.model_profile_group)
                ?.group_name.toLowerCase()
                .includes(filterText.toLowerCase()) ||
              formattedDate.toLowerCase().includes(filterText.toLowerCase()) ||
              Object.values(users)
                .find((user) => user.email === item.model_created_by)
                ?.first_name.concat(
                  " ",
                  Object.values(users).find(
                    (user) => user.email === item.model_created_by
                  )?.last_name
                )
                .toLowerCase()
                .includes(filterText.toLowerCase())) &&
            (selectedOptionsTasks.length > 0
              ? selectedOptionsTasks.includes(item.model_profile_task)
              : true) &&
            (selectedOptionsCreatedBy.length > 0
              ? selectedOptionsCreatedBy.includes(item.model_created_by)
              : true) &&
            (selectedOptionsSubscriptions.length > 0
              ? selectedOptionsSubscriptions.includes(item.model_profile_name)
              : true)
          );
        })
        .sort((a, b) => {
          if (filterText) {
            return compareValues(
              a.model_profile_name,
              b.model_profile_name,
              "asc"
            );
          }
          switch (sortColumn) {
            case "Name":
              return compareValues(
                a.model_profile_name,
                b.model_profile_name,
                sortDirectionName
              );
            case "Task":
              return compareValues(
                a.model_profile_task,
                b.model_profile_task,
                sortDirectionTask
              );
            case "Group":
              const groupA =
                Object.values(groups).find(
                  (group) => group.id === a.model_profile_group
                )?.group_name || "Unassigned";
              const groupB =
                Object.values(groups).find(
                  (group) => group.id === b.model_profile_group
                )?.group_name || "Unassigned";

              return compareValues(groupA, groupB, sortDirectionGroup);
            case "Created by":
              return compareValues(
                a.model_created_by,
                b.model_created_by,
                sortDirectionCreatedBy
              );
            default:
              return sortDirectionDateCreated === "asc"
                ? a.model_date_create
                    .toLowerCase()
                    .localeCompare(b.model_date_create)
                : b.model_date_create
                    .toLowerCase()
                    .localeCompare(a.model_date_create);
          }
        })
    );
  }, [
    models,
    filterText,
    selectedOptionsTasks,
    selectedOptionsCreatedBy,
    selectedOptionsSubscriptions,
    sortDirectionName,
    sortDirectionTask,
    sortDirectionCreatedBy,
    sortDirectionGroup,
    sortDirectionDateCreated,
    sortColumn,
    subscribedModels,
    isMobile,
  ]);

  useEffect(() => {
    if (reloadCounter > 0) {
      props.setModelCurrentPage(1);
    }
    setReloadCounter(reloadCounter + 1);
  }, [
    selectedOptionsTasks.length,
    selectedOptionsCreatedBy.length,
    selectedOptionsSubscriptions.length,
    filterText,
    sortColumn,
    sortDirectionName,
    sortDirectionTask,
    sortDirectionGroup,
    sortDirectionCreatedBy,
    sortDirectionDateCreated,
  ]);

  const subHeaderComponentMemo = useMemo(() => {
    // Create an empty Set to store unique tasks
    const uniqueTasks = new Set();
    const uniqueUsers = new Set();
    const modelProfiles = new Set();
    const createdByArray = [];

    // Iterate through the array and add each task to the Set
    Object.values(models).forEach((item) =>
      uniqueTasks.add(item.model_profile_task)
    );

    // Iterate through the array and add each model to the Set
    Object.values(models).forEach((item) => {
      const model_user = Object.values(users).find(
        (user) => user.email === item.model_created_by
      );
      const model_user_name =
        user.email === item.model_created_by
          ? "me"
          : model_user?.first_name + " " + model_user?.last_name;

      if (!uniqueUsers.has(item.model_created_by)) {
        uniqueUsers.add(item.model_created_by);
        createdByArray.push({
          email: item.model_created_by,
          created_by: model_user_name,
        });
      }
    });

    if (subscribedModels?.subscribe_user?.length > 0) {
      // Iterate through the inputArray and add each subscribed model profiles to the Set
      Object.values(subscribedModels?.subscribe_user).forEach(
        (subscribedID) => {
          const model = Object.values(models).find(
            (model) => model.id === subscribedID
          );
          model && modelProfiles.add(model.model_profile_name);
        }
      );
    }

    // Convert the Set back to an array
    const filteredTasks = Array.from(uniqueTasks);
    const filteredCreatedBy = Array.from(createdByArray);
    const filteredSubscriptions = Array.from(modelProfiles);

    return (
      <div className="list-header">
        <div className="search-input-container">
          <input
            type="text"
            placeholder="Search models"
            onChange={(e) => setFilterText(e.target.value)}
            className="search-input"
            name="search-model"
            id="search-model"
            data-cy="search-model"
          />
          <img
            src="/images/search-icon-gray.svg"
            className="search-icon img-fluid icon-list"
            alt="model"
          />
        </div>
        <div className="search-filter-dropdowns-div">
          <FilterDropdown
            setSelectedOptions={setSelectedOptionsTasks}
            clearFilter={clearFilter}
            setClearFilter={setClearFilter}
            filterTitle="Task"
            options={filteredTasks}
            name="tasks"
            isMobile={isMobile}
            dropdownToggleID="task-drp"
          />
          <FilterDropdown
            setSelectedOptions={setSelectedOptionsCreatedBy}
            clearFilter={clearFilter}
            setClearFilter={setClearFilter}
            filterTitle="Created by"
            options={filteredCreatedBy}
            name="created_by"
            isMobile={isMobile}
            dropdownToggleID="filter-created-by"
          />
          <FilterDropdown
            setSelectedOptions={setSelectedOptionsSubscriptions}
            clearFilter={clearFilter}
            setClearFilter={setClearFilter}
            filterTitle="Subscriptions"
            options={filteredSubscriptions}
            name="subscriptions_model"
            isMobile={isMobile}
            dropdownToggleID="filter-subscription"
          />
          <div>
            {selectedOptionsTasks.length +
              selectedOptionsCreatedBy.length +
              selectedOptionsSubscriptions.length >
              0 && (
              <Button
                className="ms-3 button clear-filter-btn"
                onClick={() => handleClearFilterBtnClick()}
                name="clear-filter"
                id="clear-filter"
                data-cy="clear-filter"
              >
                Clear filter
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }, [
    isMobile,
    models,
    users,
    subscribedModels,
    clearFilter,
    selectedOptionsTasks.length,
    selectedOptionsCreatedBy.length,
    selectedOptionsSubscriptions.length,
  ]);

  useEffect(() => {
    props?.setPreload(true);
    dispatch(getAllModels())
      .catch((status) => {
        props.setShowToast(true);
        props.setToastStatus(status);
        props.setToastImage(null);
      })
      .finally(() => props.setPreload(false));
  }, []);

  useEffect(() => {
    dispatch(getAllUsers()).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    dispatch(getAllSubscribeModelProfile()).catch((err) => console.log(err));
  }, [refreshSubscribe]);

  // const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [data, setData] = useState([]); // your data array
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filteredItems, setFilteredItems] = useState([]);

  const handlePageChange = (pageNumber) => {
    props.setModelCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (selectedItemsPerPage) => {
    props.setModelCurrentPage(1);
    setItemsPerPage(selectedItemsPerPage);
  };

  useEffect(() => {
    // Simulating data fetching
    // Replace this with your actual data fetching logic
    const fetchedData = filteredItems; // Replace this with your fetched data
    setData(fetchedData);
    setTotalItems(fetchedData.length);
  }, [filteredItems, sortDirection]);

  useEffect(() => {
    let slicedData = data;
    if (itemsPerPage !== totalItems) {
      const start = (props.modelCurrentPage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      slicedData = data.slice(start, end);
    }
    setPaginatedData(slicedData);
  }, [props.modelCurrentPage, itemsPerPage, data, totalItems]);

  useEffect(() => {
    if (scrollRef.current) {
      // Target the rdt_Table div and add custom attributes
      const rdtTableDiv = scrollRef.current.querySelector(".rdt_Table");
      if (rdtTableDiv) {
        rdtTableDiv.setAttribute("id", "models-table");
        rdtTableDiv.setAttribute("data-cy", "models-table");
      }
      // Target the rdt_TableRow divs and add custom attributes
      const rdtTableRows = scrollRef.current.querySelectorAll('.rdt_TableRow');
      rdtTableRows.forEach((row, index) => {
        row.setAttribute("id", `model-profile-items-${paginatedData[index].id}`);
        row.setAttribute("data-cy", "model-profile-items");
      });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        if (itemsPerPage <= totalItems && isMobile) {
          if (scrollTop + clientHeight >= scrollHeight) {
            setLoading(true);
            // Simulate a network request
            setTimeout(() => {
              setLoading(false);
              // Load more content or perform any other actions
              setItemsPerPage(itemsPerPage + 25);
            }, 2000);
          }
        }
      }
    };

    const scrollDiv = scrollRef.current;
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [itemsPerPage, totalItems, isMobile]);

  const handleRowClick = (row) => {
    // Handle row click event and navigate to a different page
    navigate("/models/profile/" + row.id);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.id === currentModel[0]?.id,
      style: {
        backgroundColor: "#CFCEE3",
      },
    },
  ];

  const onClickMobileSearchIcon = () => {
    setDisplayMobileSearchList(true);
  };

  const onClickMobileBackIcon = () => {
    setDisplayMobileSearchList(false);
  };

  return (
    <>
      <div className="mw-100 model-list-container">
        <div className="model-list-mobile-header">
          {!displayMobileSearchList ? (
            <>
              <MobileLeftSidebar
                setUrlToRedirected={props.setUrlToRedirected}
                setShowDiscardChangesModal={props.setShowDiscardChangesModal}
                changesMade={props.changesMade}
              />
              <Breadcrumb>
                <Breadcrumb.Item active>Models</Breadcrumb.Item>
              </Breadcrumb>
              {isMobile && (
                <>
                  <p className="bold model-list-title">Model Profiles</p>
                  <img
                    src="/images/mobile-search-icon.svg"
                    alt="search"
                    className="mobile-search-list-icon"
                    onClick={onClickMobileSearchIcon}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {isMobile && (
                <div>
                  <img
                    src="/images/mobile-back-icon.svg"
                    alt="back"
                    className="mobile-search-back-icon"
                    onClick={onClickMobileBackIcon}
                  />
                  {subHeaderComponentMemo}
                </div>
              )}
            </>
          )}
        </div>

        <div
          ref={scrollRef}
          className={`data-table-div data-table-scrollable ${
            displayMobileSearchList ? "pt-150-px" : "pt-95-px"
          }`}
        >
          <DataTable
            className="model-list-datatable"
            title={"Model Profiles"}
            conditionalRowStyles={conditionalRowStyles}
            columns={columns}
            data={paginatedData}
            pagination={false}
            subHeader={!isMobile}
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            onRowClicked={handleRowClick}
            noTableHead={isMobile}
            id="models-table"
            data-cy="models-table"
          />
          <div className="mb-4 d-flex justify-content-center">
            {loading && (
              <Spinner
                className="spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </div>
          {!isMobile && (
            <CustomPagination
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={props.modelCurrentPage}
              onChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ModelList;
