import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Dropdown, Tabs, Tab, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import AddGroupProfileModal from "components/groups/AddGroupProfileModal";
import AddModelProfileModal from "components/models/AddModelProfileModal";
import AddDatasetProfileModal from "components/datasets/AddDatasetProfileModal";
import Security from "components/users/Security";
import PersonalInfo from "components/users/PersonalInfo";
import "styles/Sidebar.css";
import "styles/SidebarMobile.css";
import { useMediaQuery } from "react-responsive";

/**
 * A module for the Sidebar Component
 * @module components/layout/Sidebar
 */

/**
 * Displaying menu in the left side of the system
 * @method Sidebar
 *
 * @return {JSX.Element}
 *
 */
const Sidebar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const { notifCount } = useSelector((state) => state.notification);

  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showModelModal, setShowModelModal] = useState(false);
  const [showDatasetModal, setShowDatasetModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedNewBtnMobile, setExpandedNewBtnMobile] = useState(false);

  const handleGroupModalClose = () => {
    setShowGroupModal(false);
  };

  const handleModelModalClose = () => {
    setShowModelModal(false);
  };

  const handleDatasetModalClose = () => {
    setShowDatasetModal(false);
  };

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const navigateTo = (url) => {
    if (
      (location.pathname.includes("/version/add") ||
        location.pathname.includes("/version/edit")) &&
      props?.changesMade
    ) {
      props?.setShowDiscardChangesModal(true);
      props.setUrlToRedirected(url);
    } else {
      navigate(url);
    }
  };

  const onClickAddModelProfileBtn = () => {
    setShowModelModal(true);
    setExpandedNewBtnMobile(false);
  };

  const onClickAddDatasetProfileBtn = () => {
    setShowDatasetModal(true);
    setExpandedNewBtnMobile(false);
  };

  const onClickAddGroupProfileBtn = () => {
    setShowGroupModal(true);
    setExpandedNewBtnMobile(false);
  };

  return location.pathname.includes("myaccount") ? (
    <>
      <Col className="desktop-manage-account-sidebar sidebar-col col-md-2 col-sm-2 bg-light-color">
        <div className="sidebar p-3">
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <span
                onClick={() => navigateTo("/models")}
                className="sidebar-item nav-link d-flex align-items-center"
              >
                <img
                  src="/images/sidebar_back.svg"
                  className="icon"
                  alt="models"
                />
                <span>Back to Dashboard</span>
              </span>
            </li>
            <hr />
            <p className="bold">MY ACCOUNT</p>
            <li className="nav-item">
              <span
                onClick={() => navigateTo("/myaccount/personalinfo")}
                className={`sidebar-item nav-link d-flex align-items-center ${
                  location.pathname.includes("personalinfo") && "active"
                }`}
                id="personal-info"
                data-cy="personal-info"
              >
                <img
                  src={`/images/${
                    location.pathname.includes("personalinfo")
                      ? "sidebar_personal_info.svg"
                      : "sidebar_personal_info_gray.svg"
                  }`}
                  className="icon"
                  alt="personal info"
                />
                <span>Personal Info</span>
              </span>
            </li>
            <li className="nav-item">
              <span
                onClick={() => navigateTo("/myaccount/security")}
                className={`sidebar-item nav-link ${
                  location.pathname.includes("security") && "active"
                }`}
                id="security"
                data-cy="security"
              >
                <img
                  src={`/images/${
                    location.pathname.includes("security")
                      ? "sidebar_security.svg"
                      : "sidebar_security_gray.svg"
                  }`}
                  className="icon"
                  alt="security"
                />
                <span>Security</span>
              </span>
            </li>
          </ul>
        </div>
      </Col>
      <div className="mobile-manage-account-sidebar">
        <div className="mobile-manage-account-title ps-3">
          <img
            onClick={() => navigateTo("/models")}
            src="/images/mobile-arrow-back.svg"
            alt="back"
          />
          <span className="bold ms-3">Manage Account</span>
        </div>
        <Tabs
          defaultActiveKey="personal-info"
          id="manage-account-tabs"
          className="manage-account-tabs mb-3"
        >
          <Tab
            eventKey="personal-info"
            tabClassName="tabs ms-3"
            title="Personal Info"
            tabAttrs={{ "data-cy": "personal-info" }}
          >
            <PersonalInfo
              setPreload={props.setPreload}
              setShowToast={props.setShowToast}
              setToastStatus={props.setToastStatus}
              setToastImage={props.setToastImage}
            />
          </Tab>
          <Tab
            eventKey="security"
            tabClassName="tabs"
            title="Security"
            tabAttrs={{ "data-cy": "personal-info" }}
          >
            <Security
              setPreload={props.setPreload}
              setShowToast={props.setShowToast}
              setToastStatus={props.setToastStatus}
              setToastImage={props.setToastImage}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  ) : (
    <>
      <AddGroupProfileModal
        showGroupModal={showGroupModal}
        setShowGroupModal={setShowGroupModal}
        handleGroupModalClose={handleGroupModalClose}
        setShowToast={props?.setShowToast}
        setToastStatus={props?.setToastStatus}
        setToastImage={props?.setToastImage}
      />
      <AddModelProfileModal
        showModelModal={showModelModal}
        setShowModelModal={setShowModelModal}
        handleModelModalClose={handleModelModalClose}
        setShowToast={props?.setShowToast}
        setToastStatus={props?.setToastStatus}
        setToastImage={props?.setToastImage}
      />
      <AddDatasetProfileModal
        showDatasetModal={showDatasetModal}
        setShowDatasetModal={setShowDatasetModal}
        handleDatasetModalClose={handleDatasetModalClose}
        setShowToast={props?.setShowToast}
        setToastStatus={props?.setToastStatus}
        setToastImage={props?.setToastImage}
      />
      <Col className="desktop-main-sidebar sidebar-col col-md-2 col-sm-2 bg-light-color">
        <div className="sidebar p-3">
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <Dropdown
                className="mb-9"
                show={expanded}
                onToggle={handleToggleExpand}
              >
                <Dropdown.Toggle
                  className={`new mb-2 px-4 ${
                    expanded ? "button" : "btn-outline-light"
                  }`}
                  variant="outline-primary"
                  id="new-btn"
                  data-cy="new-btn"
                >
                  <FontAwesomeIcon className="plus icon" icon={solid("plus")} />
                  <span>New</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    className="ps-4 py-2 pe-5"
                    onClick={() => setShowModelModal(true)}
                    id="model-profile"
                    data-cy="model-profile"
                  >
                    <img
                      src="/images/new_dropdown_model.svg"
                      className="icon"
                      alt="model"
                    />
                    Model Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="ps-4 py-2 pe-5"
                    onClick={() => setShowDatasetModal(true)}
                    id="dataset-profile"
                    data-cy="dataset-profile"
                  >
                    <img
                      src="/images/new_dropdown_dataset.svg"
                      className="icon"
                      alt="dataset"
                    />
                    Dataset Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="ps-4 py-2 pe-5"
                    onClick={() => setShowGroupModal(true)}
                    id="group-profile"
                    data-cy="group-profile"
                  >
                    <img
                      src="/images/new_dropdown_group.svg"
                      className="icon"
                      alt="group"
                    />
                    Group
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <span
                onClick={() => navigateTo("/models")}
                className={`sidebar-item nav-link ${
                  location.pathname.includes("models") && "active"
                }`}
                id="models"
                data-cy="models"
              >
                <img
                  src={`/images/${
                    location.pathname.includes("models")
                      ? "sidebar_models.svg"
                      : "sidebar_models_gray.svg"
                  }`}
                  className="icon"
                  alt="models"
                />
                <span>Models</span>
              </span>
            </li>
            <li className="nav-item">
              <span
                onClick={() => navigateTo("/datasets")}
                className={`sidebar-item nav-link ${
                  location.pathname.includes("datasets") && "active"
                }`}
                id="datasets"
                data-cy="datasets"
              >
                <img
                  src={`/images/${
                    location.pathname.includes("datasets")
                      ? "sidebar_datasets.svg"
                      : "sidebar_datasets_gray.svg"
                  }`}
                  className="icon"
                  alt="datasets"
                />
                <span>Datasets</span>
              </span>
            </li>
            <li className="nav-item">
              <span
                onClick={() => navigateTo("/groups")}
                className={`sidebar-item nav-link ${
                  location.pathname.includes("groups") && "active"
                }`}
                id="groups"
                data-cy="groups"
              >
                <img
                  src={`/images/${
                    location.pathname.includes("groups")
                      ? "sidebar_groups.svg"
                      : "sidebar_groups_gray.svg"
                  }`}
                  className="icon"
                  alt="groups"
                />
                <span>Groups</span>
              </span>
            </li>
          </ul>
        </div>
      </Col>
      {!location.pathname.includes("version/add") &&
        !location.pathname.includes("version/edit") &&
        isMobile && (
          <div className="mobile-bottom-sidebar">
            <div>
              <span
                onClick={() => navigateTo("/models")}
                className={`item-link ${
                  location.pathname.includes("models") && "active"
                }`}
                id="models"
                data-cy="models"
              >
                <div>
                  <img
                    src={`/images/${
                      location.pathname.includes("models")
                        ? "sidebar_models.svg"
                        : "sidebar_models_gray.svg"
                    }`}
                    alt="models"
                  />
                </div>
                <p>Models</p>
              </span>
            </div>
            <div>
              <span
                onClick={() => navigateTo("/datasets")}
                className={`item-link ${
                  location.pathname.includes("datasets") && "active"
                }`}
                id="datasets"
                data-cy="datasets"
              >
                <div>
                  <img
                    src={`/images/${
                      location.pathname.includes("datasets")
                        ? "sidebar_datasets.svg"
                        : "sidebar_datasets_gray.svg"
                    }`}
                    alt="datasets"
                  />
                </div>
                <p>Datasets</p>
              </span>
            </div>
            <div>
              <span
                onClick={() => setExpandedNewBtnMobile(true)}
                className="item-link"
                id="new-btn"
                data-cy="new-btn"
              >
                <FontAwesomeIcon icon={solid("plus")} /> <p>New</p>
              </span>
              <Offcanvas
                show={expandedNewBtnMobile && isMobile}
                onHide={() => setExpandedNewBtnMobile(false)}
                placement="bottom"
                className="offcanvas-choose-action"
              >
                <Offcanvas.Body>
                  <p className="choose-action-title bold">Add new</p>
                  <p className="choose-action-subtitle">Choose</p>
                  <ul className="choose-action-ul p-0">
                    <li
                      onClick={onClickAddModelProfileBtn}
                      id="model-profile"
                      data-cy="model-profile"
                    >
                      <img
                        src="/images/mobile-add-model-profile.svg"
                        className="icon"
                        alt="model"
                      />
                      Model Profile
                    </li>
                    <li
                      onClick={onClickAddDatasetProfileBtn}
                      id="dataset-profile"
                      data-cy="dataset-profile"
                    >
                      <img
                        src="/images/mobile-add-dataset-profile.svg"
                        className="icon"
                        alt="dataset"
                      />
                      Dataset Profile
                    </li>
                    <li
                      onClick={onClickAddGroupProfileBtn}
                      id="group-profile"
                      data-cy="group-profile"
                    >
                      <img
                        src="/images/mobile-add-group-profile.svg"
                        className="icon"
                        alt="group"
                      />
                      Group profile
                    </li>
                  </ul>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
            <div>
              <span
                onClick={() => navigateTo("/groups")}
                className={`item-link ${
                  location.pathname.includes("groups") && "active"
                }`}
                id="groups"
                data-cy="groups"
              >
                <div>
                  <img
                    src={`/images/${
                      location.pathname.includes("groups")
                        ? "sidebar_groups.svg"
                        : "sidebar_groups_gray.svg"
                    }`}
                    alt="groups"
                  />
                </div>
                <p>Groups</p>
              </span>
            </div>
            <div>
              <span
                onClick={() => {
                  props?.setPreload(true);
                  navigateTo("/notifications");
                }}
                className={`item-link ${
                  location.pathname.includes("notifications") && "active"
                }`}
                id="notifications"
                data-cy="notifications"
              >
                <div>
                  <img
                    src={`/images/${
                      location.pathname.includes("notifications")
                        ? "sidebar_notifications.svg"
                        : "sidebar_notifications_gray.svg"
                    }`}
                    alt="notifications"
                  />
                  {notifCount > 0 && (
                    <div className="notif-counter">{notifCount}</div>
                  )}
                </div>
                <p>Notifications</p>
              </span>
            </div>
          </div>
        )}
    </>
  );
};

export default Sidebar;
