/**
 * @file Manages constant status
 */

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const ACTIVATE_SUCCESS = "ACTIVATE_SUCCESS";
export const ACTIVATE_FAIL = "ACTIVATE_FAIL";
export const RESEND_ACTIVATION_SUCCESS = "RESEND_ACTIVATION_SUCCESS";
export const RESEND_ACTIVATION_FAIL = "RESEND_ACTIVATION_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS"
export const GET_CURRENT_USER_FAIL = "GET_CURRENT_USER_FAIL"

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL";

export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";

export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_FAIL = "GET_GROUP_FAIL";

export const GET_ALL_GROUPS_SUCCESS = "GET_ALL_GROUPS_SUCCESS";
export const GET_ALL_GROUPS_FAIL = "GET_ALL_GROUPS_FAIL";

export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

export const GET_MODEL_SUCCESS = "GET_MODEL_SUCCESS";
export const GET_MODEL_FAIL = "GET_MODEL_FAIL";

export const GET_MODEL_VERSION_SUCCESS = "GET_MODEL_VERSION_SUCCESS";
export const GET_MODEL_VERSION_FAIL = "GET_MODEL_VERSION_FAIL";

export const GET_ALL_MODELS_SUCCESS = "GET_ALL_MODELS_SUCCESS";
export const GET_ALL_MODELS_FAIL = "GET_ALL_MODELS_FAIL";

export const GET_ALL_MODELS_VERSIONS_SUCCESS = "GET_ALL_MODELS_VERSIONS_SUCCESS";
export const GET_ALL_MODELS_VERSIONS_FAIL = "GET_ALL_MODELS_VERSIONS_FAIL";

export const GET_ALL_ACCURACY_SUCCESS = "GET_ALL_ACCURACY_SUCCESS";
export const GET_ALL_ACCURACY_FAIL = "GET_ALL_ACCURACY_FAIL";

export const DELETE_MODEL_SUCCESS = "DELETE_MODEL_SUCCESS";

export const UPDATE_MODEL_SUCCESS = "UPDATE_MODEL_SUCCESS";

export const UPDATE_MODEL_VERSION_SUCCESS = "UPDATE_MODEL_VERSION_SUCCESS";

export const SUBSCRIBE_MODEL_SUCCESS = "SUBSCRIBE_MODEL_SUCCESS";

export const GET_ALL_SUBSCRIBE_MODELS_SUCCESS = "GET_ALL_SUBSCRIBE_MODELS_SUCCESS";
export const GET_SUBSCRIBE_MODEL_SUCCESS = "GET_SUBSCRIBE_MODEL_SUCCESS";

export const GET_GROUP_MEMBERS_SUCCESS = "GET_GROUP_MEMBERS_SUCCESS";

export const ADD_DOWNLOADS_SUCCESS = "ADD_DOWNLOADS_SUCCESS";

export const ADD_LIKES_SUCCESS = "ADD_LIKES_SUCCESS";

export const GET_LIKES_SUCCESS = "GET_LIKES_SUCCESS";

export const SET_NOTIF_COUNT_SUCCESS = "SET_NOTIF_COUNT_SUCCESS";
export const GET_ALL_NOTIFiCATIONS_SUCCESS = "GET_ALL_NOTIFiCATIONS_SUCCESS";

export const UPDATE_DATASET_SUCCESS = "UPDATE_DATASET_SUCCESS";
export const DELETE_DATASET_SUCCESS = "DELETE_DATASET_SUCCESS";
export const GET_DATASET_SUCCESS = "GET_DATASET_SUCCESS";
export const GET_DATASET_FAIL = "GET_DATASET_FAIL";

export const GET_ALL_DATASETS_SUCCESS = "GET_ALL_DATASETS_SUCCESS";
export const GET_ALL_DATASETS_FAIL = "GET_ALL_DATASETS_FAIL";

export const GET_ALL_DATASETS_VERSIONS_SUCCESS = "GET_ALL_DATASETS_VERSIONS_SUCCESS";
export const GET_ALL_DATASETS_VERSIONS_FAIL = "GET_ALL_DATASETS_VERSIONS_FAIL";

export const GET_DATASET_VERSION_SUCCESS = "GET_DATASET_VERSION_SUCCESS";
export const GET_DATASET_VERSION_FAIL = "GET_DATASET_VERSION_FAIL";

export const SUBSCRIBE_DATASET_SUCCESS = "SUBSCRIBE_DATASET_SUCCESS";
export const GET_ALL_SUBSCRIBE_DATASETS_SUCCESS = "GET_ALL_SUBSCRIBE_DATASETS_SUCCESS";
export const GET_SUBSCRIBE_DATASET_SUCCESS = "GET_SUBSCRIBE_DATASET_SUCCESS";
