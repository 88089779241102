import { Modal, Button } from "react-bootstrap";

/**
 * A module for the CancelDemoModal Component
 * @module components/models/CancelDemoModal
 */

/**
 * CancelDemo model in the system
 * @method CancelDemoModal
 *
 * @return {JSX.Element}
 *
 */
const CancelDemoModal = (props) => {
  return (
    <>
      <Modal
        show={props?.showCancelDemoModal}
        onHide={props?.handleCancelDemoModalClose}
        dialogClassName="modal-32w remove-modal"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <p className="bold">Cancel model demo?</p>
          <p>
            You won't be able to see the result of the model demo if you move
            away from this page.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props?.handleCancelDemoModalClose}
            className="light-btn"
          >
            Go Back
          </Button>
          <Button
            variant="primary"
            onClick={props?.onClickCancelDemo}
            className="submit-btn button"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CancelDemoModal;
