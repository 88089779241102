import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import ReactQuill from "react-quill";
import { modules, formats } from "common/constants";
import { useRef, useEffect } from "react";

const Tab1 = (props) => {
  const modelDescRef = useRef();

  useEffect(() => {
    // Add data-cy attribute to the Quill editor root div after it's mounted
    if (modelDescRef.current) {
      const editorDiv = modelDescRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "model-description");
    }
  }, []);

  return (
    <div className="tab-div" ref={props.elementRef1}>
      <Form.Group>
        <Form.Label className="required-field">Name</Form.Label>
        <Form.Control
          value={props.modelName}
          onChange={props.onChangeModelName}
          className={`input-field ${
            props.isValid?.modelName
              ? "is-valid"
              : props.isValid.modelName !== undefined
              ? "is-invalid"
              : ""
          }`}
          name="model-name"
          id="model-name"
          data-cy="model-name"
        />
        {props.getFormErrorMessage("modelName")}
        <span className="sub-note">
          Give a unique name to your model profile.
        </span>
      </Form.Group>
      <Form.Group>
        <Form.Label className="required-field">Task</Form.Label>
        <Typeahead
          labelKey="name"
          multiple={false}
          options={props.optionsTask}
          placeholder="Select a task"
          selected={props.selectedTaskOptions}
          onChange={props.handleSelectTask}
          onInputChange={props.onInputChangeModelTask}
          className={`input-field typeahead-input form-control ${
            props.isValid?.modelTask
              ? "is-valid"
              : props.isValid.modelTask !== undefined
              ? "is-invalid"
              : ""
          }`}
          id="model-task"
          inputProps={{
            "data-cy": "model-task", // Add data-cy attribute to input element
          }}
        />
        {props.getFormErrorMessage("modelTask")}
        {props.showModelTaskOthers && (
          <>
            <Form.Control
              value={props.modelTaskOthers}
              onChange={props.onChangeModelTaskOthers}
              className={`input-field mt-3 ${
                props.isValid?.modelTaskOthers
                  ? "is-valid"
                  : props.isValid.modelTaskOthers !== undefined
                  ? "is-invalid"
                  : ""
              }`}
              name="model-task-others"
              id="model-task-others"
              data-cy="model-task-others"
            />
            {props.getFormErrorMessage("modelTaskOthers")}
          </>
        )}
        <span className="sub-note">
          Provide a machine learning task that your model tackles.
        </span>
      </Form.Group>
      <Form.Group
        onMouseDown={props.handleMouseDownModelDescription}
        onMouseUp={props.handleMouseUpModelDescription}
        onBlur={props.onBlurModelDescription}
        onFocus={props.onFocusModelDescription}
      >
        <Form.Label className="required-field">Description</Form.Label>
        <ReactQuill
          ref={modelDescRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelDescription}
          onChange={props.onChangeModelDescription}
          className={`input-field input-description form-control ${
            props.isValid?.modelDescription
              ? "is-valid"
              : props.isValid.modelDescription !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelDescription
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="model-description"
        />
        {props.getFormErrorMessage("modelDescription")}
        <span className="sub-note">
          Discuss clearly the idea of your model.
        </span>
      </Form.Group>
      <Form.Group>
        <Form.Label>Assign a Group</Form.Label>
        <Typeahead
          disabled={
            props?.disableAssignedGroup &&
            props?.modelCreatedBy !== props?.user?.email
              ? true
              : false
          }
          clearButton={
            props?.modelCreatedBy === props?.user?.email ? true : false
          }
          labelKey="name"
          multiple={false}
          options={props.optionsAssignedGroup}
          placeholder="Select a group"
          selected={props.selectedAssignedGroupOptions}
          onChange={props.handleSelectAssignedGroup}
          onInputChange={props.onInputChangeModelAssignedGroup}
          className={`input-field typeahead-input form-control ${
            props.isValid?.modelAssignedGroup
              ? "is-valid"
              : props.isValid.modelAssignedGroup !== undefined
              ? "is-invalid"
              : ""
          } ${props?.modelCreatedBy !== props?.user?.email && "pe-0"}`}
          id="model-group"
          inputProps={{
            "data-cy": "model-group", // Add data-cy attribute to input element
          }}
        />
        {props.getFormErrorMessage("modelAssignedGroup")}
        <span className="sub-note">
          Members of the assigned group will be able to work together on this
          model.
        </span>
      </Form.Group>
    </div>
  );
};

export default Tab1;
