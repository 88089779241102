import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";

/**
 * A module for the ViewModelLicenseModal Component
 * @module components/models/ViewModelLicenseModal
 */

/**
 * Displaying  modal for viewing the details of the model creator
 * @method ViewModelLicenseModal
 *
 * @return {JSX.Element}
 *
 */
const ViewModelLicenseModal = (props) => {

  const [modelLicense, setModelLicense] = useState("");
  const [modelLicenseContent, setModelLicenseContent] = useState("");


  useEffect(() => {
    if (props) {
      setModelLicense(props?.modelLicense);
      setModelLicenseContent(props?.modelLicenseContent);
    }
  }, [props]);

  useEffect(() => {
    if(!props.showViewModelLicenseModal) {
        setModelLicense("");
        setModelLicenseContent("");
    }
    
  }, [props.showViewModelLicenseModal]);

  return (
    <>
      <Modal
        show={props.showViewModelLicenseModal}
        onHide={props.handleViewModelLicenseModalClose}
        dialogClassName="modal-45w"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="bold ms-3">{modelLicense}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-license">
            <p
              dangerouslySetInnerHTML={{
                __html:
                  modelLicenseContent === "<p><br></p>" ||
                  !modelLicenseContent
                    ? "--"
                    : modelLicenseContent,
              }}
            ></p>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewModelLicenseModal;
