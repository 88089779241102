import { Modal, Button, Offcanvas, Form } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "styles/mobile/PublishModalMobile.css"

/**
 * A module for the SaveAndPublishModal Component
 * @module components/common-modals/SaveAndPublishModal
 */

/**
 * Save and publish model version in the system
 * @method SaveAndPublishModal
 *
 * @return {JSX.Element}
 *
 */
const SaveAndPublishModal = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  return (
    <>
      <Modal
        show={props?.showSaveAndPublishModal && !isMobile}
        onHide={props?.handleSaveAndPublishModalClose}
        dialogClassName="modal-32w remove-modal"
        centered
        backdrop="static"
        id="publish-version-confirmation"
        data-cy="publish-version-confirmation"
      >
        <Modal.Body>
          <p className="bold">Publish this version?</p>
          <p>
            Further modifications to this version can no longer be made and
            other users will have access to it.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props?.handleSaveAndPublishModalClose}
            className="light-btn"
            name="cancel"
            id="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => props?.onClickSave(true)}
            className="submit-btn button"
            name="publish-version-btn"
            id="publish-version-btn"
            data-cy="publish-version-btn"
          >
            Publish
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props?.showSaveAndPublishModal && isMobile}
        onHide={props?.handleSaveAndPublishModalClose}
        placement="bottom"
        className="offcanvas-publish-version"
        id="publish-version-confirmation"
        data-cy="publish-version-confirmation"
      >
        <Offcanvas.Body>
          <Form>
            <p className="bold">Publish this version?</p>
            <p>
              Further modifications to this version can no longer be made and
              other users will have access to it.
            </p>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props?.handleSaveAndPublishModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => props?.onClickSave(true)}
              className="submit-btn button"
              name="publish-version-btn"
              id="publish-version-btn"
              data-cy="publish-version-btn"
            >
              Publish
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SaveAndPublishModal;
