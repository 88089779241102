import { Offcanvas } from "react-bootstrap";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "actions/auth";
import "styles/mobile/MobileLeftSidebar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { version } from "common/constants";

const MobileLeftSidebar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoggedIn } = useSelector((state) => state.auth);
  // location.pathname.includes("myaccount")

  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const handleCloseMobileSidebar = () => setShowMobileSidebar(false);
  const handleShowMobileSidebar = () => setShowMobileSidebar(true);

  /**
   *  Navigates to the user account
   * @method onClickManageAccount
   */
  const onClickManageAccount = () => {
    navigate("/myaccount/personalinfo");
    setShowMobileSidebar(false);
  };

  /**
   *  Navigates to the login after clicking logout button
   * @method onClickLogout
   */
  const onClickLogout = () => {
    dispatch(logout(isLoggedIn));
    setShowMobileSidebar(false);
  };

  const navigateTo = (url) => {
    if (
      (location.pathname.includes("/version/add") ||
        location.pathname.includes("/version/edit")) &&
      props?.changesMade
    ) {
      props?.setShowDiscardChangesModal(true);
      props.setUrlToRedirected(url);
    } else {
      navigate(url);
    }
  };

  return (
    <div className="mobile-header">
      <div
        className="mobile-hamburger-div"
        onClick={handleShowMobileSidebar}
        id="my-account"
        data-cy="my-account"
      >
        <img src="/images/mobile-hamburger-icon.svg" alt="hamburger" />
      </div>
      <Offcanvas
        show={showMobileSidebar}
        onHide={handleCloseMobileSidebar}
        className="mobile-sidebar-offcanvas"
      >
        <Offcanvas.Header className="mobile-sidebar-header">
          <Offcanvas.Title>
            <a onClick={() => navigateTo("/")} className="ms-2 navbar-brand">
              <img
                src="/images/dimer-logo-2024.png"
                className="logo"
                alt="Model Store logo"
                style={{ display: "none" }}
                onLoad={(e) => (e.target.style.display = "unset")}
              />
              <span className="dimer-mobile-logo bold ms-4">DIMER</span>
            </a>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="mobile-sidebar-account d-flex m-4">
            <div>
              <img src="/images/profile-image-icon.svg" alt="Profile" />
            </div>
            <div className="ms-4">
              <div>
                {user?.first_name + " " + user?.last_name + " " + user?.suffix}
              </div>
              <p className="sub-note m-0">{user?.email}</p>
            </div>
          </div>
          <hr />
          <div
            className="mobile-sidebar-manage-account"
            onClick={onClickManageAccount}
            id="manage-account"
            data-cy="manage-account"
          >
            <p className="m-4">Manage Account</p>
          </div>
          <div
            className="mobile-sidebar-sign-out"
            onClick={onClickLogout}
            id="signout"
            data-cy="signout"
          >
            <span className="ms-4">Sign out</span>
          </div>
          <span className="version-mobile">{version}</span>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default MobileLeftSidebar;
