import { Modal, Button, Form, Offcanvas } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import { modules, formats } from "common/constants";
import { addDataset, getAllDatasets } from "actions/dataset";
import DiscardChangesModal from "components/common-modals/DiscardChangesModal";
import { useMediaQuery } from "react-responsive";
import "styles/mobile/AddDatasetProfileModalMobile.css";

/**
 * A module for the AddDatasetProfileModal Component
 * @module components/dataset/AddDatasetProfileModal
 */

/**
 * Displaying menu in the left side of the system
 * @method AddDatasetProfileModal
 *
 * @return {JSX.Element}
 *
 */
const AddDatasetProfileModal = (props) => {
  const datasetDescRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const dispatch = useDispatch();

  const [datasetTitle, setDatasetTitle] = useState("");
  const [datasetDescription, setDatasetDescription] = useState("<p><br></p>");

  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [toolbarHidden, setToolbarHidden] = useState(true);
  const [isToolbarClicked, setIsToolbarClicked] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [changesMade, setChangesMade] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const onChangeDatasetTitle = (e) => {
    const value = e.target.value;
    setDatasetTitle(value);
    validate("datasetTitle", value);
  };

  const onChangeDatasetDescription = (e) => {
    setDatasetDescription(e);
    validate("datasetDescription", e);
  };

  const handleFormSubmit = (e) => {
    dispatch(addDataset(datasetTitle, datasetDescription))
      .then((status) => {
        props.setToastStatus(status);
        props.setToastImage("/images/dataset-success.svg");
        setDisabled(true);
        dispatch(getAllDatasets()).catch((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        });
        if (status !== "error") {
          props.handleDatasetModalClose();
        }
        resetModalForm();
      })
      .catch((status) => {
        props.setToastStatus(status);
        props.setToastImage(null);
      })
      .finally(() => {
        setDisabled(false);
        props.setShowToast(true);
        setChangesMade(false);
      });
  };

  const resetModalForm = () => {
    setDatasetTitle("");
    setDatasetDescription("<p><br></p>");
    setDisabled(true);
    setIsValid({});
    setError({});
    setToolbarHidden(true);
    setChangesMade(false);
  };

  useEffect(() => {
    if (changesMade) {
      props?.setShowDatasetModal(true);
      setShowDiscardModal(true);
    }
  }, [props.showDatasetModal]);

  /**
   *  Validates name and value of the input field
   * @method validate
   * @param {string} name - A string for name of the input field
   * @param {string} value - A string for the value of the input field
   *
   * @return {void}
   */
  const validate = (name, value) => {
    setChangesMade(true);
    if (name === "datasetDescription") {
      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(value, "text/html");
      const textContent = parsedHTML.body.textContent.trim();
      if (textContent === "") {
        value = "";
      }
    }
    if (!value) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "Please fill out this field" }));
      return;
    }

    const regexPattern =
      /^[a-zA-Z0-9 Ññ~`!@#\$%\^&*()_\-+={}\[\]|\\:;'"<,>.?\/\s]+$/g;
    if (regexPattern.test(value) === false) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]:
            "Accepts a-z, A-Z, 0-9, Ñ/ñ, space, and special characters like ~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/",
        })
      );
      return;
    }

    if (value.length < 2 && value.length > 0) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Minimum of 2 characters",
        })
      );
      return;
    }

    if (name === "datasetTitle" && value.length > 0 && value.length > 100) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Maximum of 100 characters",
        })
      );
      return;
    }

    if (
      name === "datasetDescription" &&
      value.length > 0 &&
      value.length > 1000
    ) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Maximum of 1000 characters",
        })
      );
      return;
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  /**
   * Setting the toolbar hidden of the text editor based on the name of the input field
   * @method onBlur
   *
   * @param {string} name - A string for the name of the input field
   * @param {event} e - An event object containing information about the action
   */
  const onBlur = () => {
    if (!isToolbarClicked) {
      setToolbarHidden(true);
    }
  };

  const handleMouseDown = () => {
    setIsToolbarClicked(true);
  };

  const handleMouseUp = () => {
    setIsToolbarClicked(false);
  };

  const onFocus = () => {
    setToolbarHidden(false);
  };

  useEffect(() => {
    if (
      datasetTitle !== "" &&
      datasetDescription !== "<p><br></p>" &&
      Object.keys(error).length === 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [datasetTitle, datasetDescription]);

  const handleDiscardModalClose = () => {
    setShowDiscardModal(false);
  };

  const onClickDiscard = () => {
    handleDiscardModalClose();
    props.handleDatasetModalClose();
    resetModalForm();
  };

  const onClickGoBack = () => {
    handleDiscardModalClose();
    props?.setShowDatasetModal(true);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      props?.setShowDatasetModal(true);
      setShowDiscardModal(true);
      event.preventDefault();
      event.returnValue = ""; // Standard for most browsers
    };

    if (changesMade) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [changesMade]);

  useEffect(() => {
    // Add data-cy attribute to the Quill editor root div after it's mounted
    if (datasetDescRef.current) {
      const editorDiv = datasetDescRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "dataset-desc");
    }
  }, []);

  return (
    <>
      <DiscardChangesModal
        showDiscardModal={showDiscardModal}
        onClickGoBack={onClickGoBack}
        onClickDiscard={onClickDiscard}
      />
      <Modal
        show={props.showDatasetModal && !isMobile}
        onHide={props.handleDatasetModalClose}
        dialogClassName="modal-50w"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="bold ms-3"
            id="modal-title"
            data-cy="modal-title"
          >
            Add a Dataset Profile
          </Modal.Title>
          <Button
            variant="close"
            onClick={props.handleDatasetModalClose}
            name="close-btn"
            id="close-btn"
            data-cy="close-btn"
          />
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <Form>
            <p className="required-field ms-5">
              Required fields are marked with an asterisk
            </p>
            <hr />
            <div className="mx-5">
              <FormInputFields
                datasetDescRef={datasetDescRef}
                datasetTitle={datasetTitle}
                onChangeDatasetTitle={onChangeDatasetTitle}
                isValid={isValid}
                getFormErrorMessage={getFormErrorMessage}
                handleMouseDown={handleMouseDown}
                handleMouseUp={handleMouseUp}
                onBlur={onBlur}
                onFocus={onFocus}
                datasetDescription={datasetDescription}
                onChangeDatasetDescription={onChangeDatasetDescription}
                toolbarHidden={toolbarHidden}
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props.handleDatasetModalClose}
            className="light-btn"
            name="cancel"
            id="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleFormSubmit}
            disabled={disabled}
            className="submit-btn button"
            name="add-dataset"
            id="add-dataset"
            data-cy="add-dataset"
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props.showDatasetModal && isMobile}
        onHide={() => props.setShowDatasetModal(false)}
        placement="bottom"
        className="offcanvas-add-dataset-profile"
      >
        <Offcanvas.Body className="profile-offcanvas">
          <Form>
            <p className="bold" id="modal-title" data-cy="modal-title">
              Add a Dataset Profile
            </p>
            <p className="required-field">
              Required fields are marked with an asterisk
            </p>
            <div className="add-dataset-profile-form">
              <FormInputFields
                datasetDescRef={datasetDescRef}
                datasetTitle={datasetTitle}
                onChangeDatasetTitle={onChangeDatasetTitle}
                isValid={isValid}
                getFormErrorMessage={getFormErrorMessage}
                handleMouseDown={handleMouseDown}
                handleMouseUp={handleMouseUp}
                onBlur={onBlur}
                onFocus={onFocus}
                datasetDescription={datasetDescription}
                onChangeDatasetDescription={onChangeDatasetDescription}
                toolbarHidden={toolbarHidden}
              />
            </div>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleDatasetModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleFormSubmit}
              disabled={disabled}
              className="submit-btn button"
              name="add-dataset"
              id="add-dataset"
              data-cy="add-dataset"
            >
              Add
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const FormInputFields = (props) => {
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label className="required-field">Title</Form.Label>
        <Form.Control
          type="text"
          value={props.datasetTitle}
          onChange={props.onChangeDatasetTitle}
          className={`form-control ${
            props.isValid?.datasetTitle
              ? "is-valid"
              : props.isValid.datasetTitle !== undefined
              ? "is-invalid"
              : ""
          }`}
          name="dataset-title"
          id="dataset-title"
          data-cy="dataset-title"
        />
        {props.getFormErrorMessage("datasetTitle")}
        <span className="sub-note">
          Give a unique title to your dataset profile.
        </span>
      </Form.Group>
      <Form.Group
        className="mb-3"
        onMouseDown={props.handleMouseDown}
        onMouseUp={props.handleMouseUp}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      >
        <Form.Label className="required-field">Description</Form.Label>
        <ReactQuill
          ref={props.datasetDescRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.datasetDescription}
          onChange={props.onChangeDatasetDescription}
          className={`input-description form-control ${
            props.isValid?.datasetDescription
              ? "is-valid"
              : props.isValid.datasetDescription !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHidden
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shrink toolbar-display"
          }`}
          id="dataset-desc"
        />
        {props.getFormErrorMessage("datasetDescription")}
        <span className="sub-note">
          Let people know what your dataset is about.
        </span>
      </Form.Group>
    </>
  );
};

export default AddDatasetProfileModal;
