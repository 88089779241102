import { convertDate } from "common/constants";
import ViewModelLicenseModal from "components/models/ViewModelLicenseModal";
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";

/**
 * A module for Details component
 * @module components/models/sections/Details
 */

/**
 * Details component
 * @method Details
 *
 * @return {JSX.Element}
 *
 */
const Details = (props) => {
  const { users } = useSelector((state) => state.group);
  const [modelDevelopers, setModelDevelopers] = useState("");
  const [showViewModelLicenseModal, setShowViewModelLicenseModal] =
    useState(false);

  useEffect(() => {
    if (props?.modelDevelopers && props?.modelDevelopers.length > 0) {
      const filteredUsers = Object.values(users).filter((user) =>
        props?.modelDevelopers.some((member) => user.email === member.email)
      );
      const usersList = Object.values(filteredUsers).map((item) => {
        return {
          id: item.id,
          name: item.first_name + " " + item.last_name,
          label: item.first_name + " " + item.last_name,
          email: item.email,
        };
      });
      const result = usersList.map((user) => user.name).join(", ");
      setModelDevelopers(result);
    }
  }, [props?.modelDevelopers]);

  const handleViewModelLicenseModalClose = () => {
    setShowViewModelLicenseModal(false);
  };

  return (
    <>
      <ViewModelLicenseModal
        showViewModelLicenseModal={showViewModelLicenseModal}
        handleViewModelLicenseModalClose={handleViewModelLicenseModalClose}
        modelLicense={props?.modelLicense}
        modelLicenseContent={props?.modelLicenseContent}
      />
      <Container className="mw-65 ms-0">
        <span className="bold">Version details</span>
        <p>More information about this version.</p>

        <div className="mb-3">
          <label className="semi-bold">Development date</label>
          <p>
            {props?.modelDevelopmentDate
              ? convertDate(props?.modelDevelopmentDate, "MM DD, YYYY")
              : "--"}
          </p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">Developers</label>
          <p>{modelDevelopers || "--"}</p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">Accuracy</label>
          <p>{props?.modelAccuracy ? props?.modelAccuracy + "%" : "--"}</p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">Input</label>
          <p>{props?.modelInput || "--"}</p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">Output</label>
          <p>{props?.modelOutput || "--"}</p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">Type</label>
          <p>{props?.modelType || "--"}</p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">
            Paper or other resource for information
          </label>
          <p>{props?.modelPaper || "--"}</p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">Citation details</label>
          <p
            dangerouslySetInnerHTML={{
              __html:
                props?.modelCitationDetails === "<p><br></p>" ||
                !props?.modelCitationDetails
                  ? "--"
                  : props?.modelCitationDetails,
            }}
          ></p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">Other relevant information</label>
          <p
            dangerouslySetInnerHTML={{
              __html:
                props?.modelOtherRelevantInfo === "<p><br></p>" ||
                !props?.modelOtherRelevantInfo
                  ? "--"
                  : props?.modelOtherRelevantInfo,
            }}
          ></p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">License</label>
          <div>
            {props?.modelLicense ? (
              <Button
                variant="outline-light"
                className="light-btn"
                onClick={() => setShowViewModelLicenseModal(true)}
              >
                {props?.modelLicense}
              </Button>
            ) : (
              "--"
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Details;
