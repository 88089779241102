import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { activate } from "../../actions/auth";
import { Button, Container } from "react-bootstrap";
import Home from "components/Home";

/**
 * A module for Account Activate Component
 * @module components/AccountActivate
 */

/**
 * Account Activate Component
 * @method AccountActivate
 *
 * @return {JSX.Element}
 *
 */
const AccountActivate = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const uid = params.uid;
  const token = params.token;

  /**
   * --------------------
   * * Component state
   *  -------------------
   */
  const [success, setSuccess] = useState(null);
  const [contentMessage, setContentMessage] = useState("");

  /**
   * Activate user account
   */
  useEffect(() => {
    dispatch(activate(uid, token))
      .then(() => {
        setContentMessage("User successfully activated");
        setSuccess(true);
      })
      .catch((err) => {
        setSuccess(false);
        setContentMessage(err.message);
      });
  }, []);

  /**
   * Navigates to the corresponding login after clicking back button
   * @method onClickSignIn
   */
  const onClickSignIn = () => {
    navigate("/signin");
  };

  return (
    <Home>
      <Container className="text-center">
        {success ? (
          <>
            <p className="h3 mx-1 mt-4 bold">Account activated</p>
            <div className="form-outline mb-4">
              <p>
                Congratulations! Your account has been succesfully activated
              </p>
              <p className="py-5">You can now Sign in and start using DIMER.</p>
            </div>
            <div className="d-flex buttons-div justify-content-center">
              <Button
                variant="primary"
                type="submit"
                className="submit-btn button"
                name="signin"
                id="signin"
                data-cy="signin"
                onClick={onClickSignIn}
              >
                Sign in
              </Button>
            </div>
          </>
        ) : (
          <>
            <p className="h3 mx-1 mt-4 bold">Activation failed</p>
            <div className="form-outline mb-4">
              <p>Activation link is invalid or expired.</p>
              <p className="pt-5">
                The activation link is valid for only 1 hour.
              </p>
              <p>Sign in again to retry activating your account.</p>
            </div>
          </>
        )}
      </Container>
    </Home>
  );
};

export default AccountActivate;
