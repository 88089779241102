import { useEffect, useState, useRef } from "react";
import {
  Container,
  Form,
  OverlayTrigger,
  Tooltip,
  Table,
  Button,
} from "react-bootstrap";
import { modules, formats, convertBytesToSize } from "common/constants";
import ReactQuill from "react-quill";
import ReactDOMServer from "react-dom/server";
import getLicense from "components/datasets/sections/add-and-edit/getLicenses";
import licensesJson from "data/dataset-licenses.json";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "actions/auth";
import "styles/Overview.css";

/**
 * A module for Overview component
 * @module components/models/sections/Overview
 */

/**
 * Overview component
 * @method Overview
 *
 * @return {JSX.Element}
 *
 */
const Overview = (props) => {
  const dispatch = useDispatch();
  const descriptionRef = useRef();
  const citationDetailsRef = useRef();
  const relevantInfoRef = useRef();
  const licenseOthersRef = useRef();

  const { users } = useSelector((state) => state.group);
  const { user, currentUser } = useSelector((state) => state.auth);
  const [
    toolbarHiddenDatasetVersionDescription,
    setToolbarHiddenDatasetVersionDescription,
  ] = useState(true);
  const [
    toolbarHiddenDatasetCitationDetails,
    setToolbarHiddenDatasetCitationDetails,
  ] = useState(true);
  const [
    toolbarHiddenDatasetOtherRelevantInfo,
    setToolbarHiddenDatasetOtherRelevantInfo,
  ] = useState(true);
  const [
    isToolbarClickedDatasetVersionDescription,
    setIsToolbarClickedDatasetVersionDescription,
  ] = useState(false);
  const [
    isToolbarClickedDatasetCitationDetails,
    setIsToolbarClickedDatasetCitationDetails,
  ] = useState(false);
  const [
    isToolbarClickedDatasetOtherRelevantInfo,
    setIsToolbarClickedDatasetOtherRelevantInfo,
  ] = useState(false);
  const [counter, setCounter] = useState(0);
  const inputRef = useRef(null);

  const [datasetLicense, setDatasetLicense] = useState("");
  const [toolbarHiddenDatasetLicense, setToolbarHiddenDatasetLicense] =
    useState(true);
  const [isToolbarClickedDatasetLicense, setIsToolbarClickedDatasetLicense] =
    useState(false);

  const [optionsDatasetContributor, setOptionsDatasetContributor] = useState(
    []
  );
  const [
    selectedDatasetContributorOptions,
    setSelectedDatasetContributorOptions,
  ] = useState([]);
  const [keywords, setKeywords] = useState("");

  const onChangeDatasetSubtitle = (e) => {
    const value = e.target.value;
    props?.setDatasetSubtitle(value);
    props?.validate("datasetSubtitle", value);
  };

  const onChangeDatasetVersionDescription = (e) => {
    props?.setDatasetVersionDescription(e);
    props?.validate("datasetVersionDescription", e);
  };

  const onChangeDatasetContributor = (e) => {
    const value = e.target.value;
    props?.setDatasetContributor(value);
    props?.validate("datasetContributor", value);
  };

  // const onChangeDatasetKeywords = (e) => {
  //   const value = e.target.value;
  //   props?.setDatasetKeywords(value);
  //   const updatedValue = value.split(",");
  //   renderKeywords(updatedValue);
  //   props?.validate("datasetKeywords", value);
  // };

  const onChangeDatasetCitationDetails = (e) => {
    props?.setDatasetCitationDetails(e);
    props?.validate("datasetCitationDetails", e);
  };

  const onChangeDatasetOtherRelevantInfo = (e) => {
    props?.setDatasetOtherRelevantInfo(e);
    props?.validate("datasetOtherRelevantInfo", e);
  };

  const onChangeDatasetLicense = (e) => {
    props?.setDatasetLicenseContent("");
    props?.setDatasetLicense(e.target.value);
    props?.validate("datasetLicense", e.target.value);
  };

  useEffect(() => {
    if (parseInt(props.datasetLicense)) {
      if (props.datasetLicense !== "26") {
        props?.setDatasetLicenseContent(
          ReactDOMServer.renderToString(getLicense(props?.datasetLicense))
        );
      } else {
        props?.setDatasetLicenseContent("");
      }
    }
  }, [props?.datasetLicense]);

  useEffect(() => {
    const license = Object.values(licensesJson).find(
      (license) => license.name === props.datasetLicense
    );
    setDatasetLicense(license?.id.toString());
  }, [props.datasetLicense]);

  const onChangeDatasetLicenseContent = (e) => {
    props?.setDatasetLicenseContent(e);
    props?.validate("datasetLicenseContent", e);
  };

  /**
   * Setting the toolbar hidden of the text editor based on the name of the input field
   * @method onBlur
   *
   * @param {string} name - A string for the name of the input field
   * @param {event} e - An event object containing information about the action
   */
  const onBlurDatasetVersionDescription = () => {
    if (!isToolbarClickedDatasetVersionDescription) {
      setToolbarHiddenDatasetVersionDescription(true);
    }
  };

  const handleMouseDownDatasetVersionDescription = () => {
    setIsToolbarClickedDatasetVersionDescription(true);
  };

  const handleMouseUpDatasetVersionDescription = () => {
    setIsToolbarClickedDatasetVersionDescription(false);
  };

  const onFocusDatasetVersionDescription = () => {
    setToolbarHiddenDatasetVersionDescription(false);
  };

  /**
   * Setting the toolbar hidden of the text editor based on the name of the input field
   * @method onBlur
   *
   * @param {string} name - A string for the name of the input field
   * @param {event} e - An event object containing information about the action
   */
  const onBlurDatasetCitationDetails = () => {
    if (!isToolbarClickedDatasetCitationDetails) {
      setToolbarHiddenDatasetCitationDetails(true);
    }
  };

  const handleMouseDownDatasetCitationDetails = () => {
    setIsToolbarClickedDatasetCitationDetails(true);
  };

  const handleMouseUpDatasetCitationDetails = () => {
    setIsToolbarClickedDatasetCitationDetails(false);
  };

  const onFocusDatasetCitationDetails = () => {
    setToolbarHiddenDatasetCitationDetails(false);
  };

  /**
   * Setting the toolbar hidden of the text editor based on the name of the input field
   * @method onBlur
   *
   * @param {string} name - A string for the name of the input field
   * @param {event} e - An event object containing information about the action
   */
  const onBlurDatasetOtherRelevantInfo = () => {
    if (!isToolbarClickedDatasetOtherRelevantInfo) {
      setToolbarHiddenDatasetOtherRelevantInfo(true);
    }
  };

  const handleMouseDownDatasetOtherRelevantInfo = () => {
    setIsToolbarClickedDatasetOtherRelevantInfo(true);
  };

  const handleMouseUpDatasetOtherRelevantInfo = () => {
    setIsToolbarClickedDatasetOtherRelevantInfo(false);
  };

  const onFocusDatasetOtherRelevantInfo = () => {
    setToolbarHiddenDatasetOtherRelevantInfo(false);
  };

  /**
   * Setting the toolbar hidden of the text editor based on the name of the input field
   * @method onBlur
   *
   * @param {string} name - A string for the name of the input field
   * @param {event} e - An event object containing information about the action
   */
  const onBlurDatasetLicense = () => {
    if (!isToolbarClickedDatasetLicense) {
      setToolbarHiddenDatasetLicense(true);
    }
  };

  const handleMouseDownDatasetLicense = () => {
    setIsToolbarClickedDatasetLicense(true);
  };

  const handleMouseUpDatasetLicense = () => {
    setIsToolbarClickedDatasetLicense(false);
  };

  const onFocusDatasetLicense = () => {
    setToolbarHiddenDatasetLicense(false);
  };

  const handleSelectDatasetContributor = (selected) => {
    setSelectedDatasetContributorOptions(selected);
    props?.setDatasetContributor(selected);
    props?.validate("datasetContributor", selected);
  };

  const onInputChangeDatasetContributor = (e) => {
    props?.validate("datasetContributor", selectedDatasetContributorOptions);
  };

  const renderKeywords = (arrayVal) => {
    setKeywords(
      arrayVal.map((element, index) => {
        return (
          <div className="token" key={index}>
            <span className="token-label">{element}</span>
            {/* {!disableRemove && (
            <button
              className="token-remove"
              onClick={() => handleRemoveOption(option)}
            >
              &times;
            </button>
          )} */}
          </div>
        );
      })
    );
  };

  const [inputValue, setInputValue] = useState("");
  const [lastValue, setLastValue] = useState("");
  const [keywordList, setKeywordList] = useState([]);
  const inputDatasetKeywordsRef = useRef(null);

  const onChangeDatasetKeywords = (e) => {
    const value = e.target.value;
    updateDatasetKeywords(value);
  };

  const updateDatasetKeywords = (value) => {
    setLastValue(value.split(",").slice(-1));

    const newVal = inputValue + value;
    if (
      value.charAt(value.length - 1) === "," &&
      newVal.charAt(newVal.length - 2) !== ","
    ) {
      setInputValue(newVal);
      let words = newVal.split(",");
      words = words.filter((str) => str.trim() !== "").map((str) => str.trim());
      props?.setDatasetKeywords(words);
      setKeywordList(words);
    }
  };

  const onBlurDatasetKeywords = (e) => {
    const value = e.target.value;
    setLastValue("");

    const newVal = inputValue + value;
    if (value.charAt(value.length - 1) !== "," && value.length > 0) {
      setInputValue(newVal + ",");
      let words = newVal.split(",");
      words = words.filter((str) => str.trim() !== "").map((str) => str.trim());
      props?.setDatasetKeywords(words);
      setKeywordList(words);
    }
  };

  const onKeyDownDatasetKeywords = (e) => {
    if (
      e.code === "Backspace" &&
      e.target.value === "" &&
      inputValue.length > 0
    ) {
      e.preventDefault();
      setLastValue(keywordList.slice(-1));
      let words = keywordList;
      words.pop();
      setKeywordList(words);
      props?.setDatasetKeywords(words);
      setInputValue(words.join(", ") + ",");
      // setInputValue(inputValue.replace(/,[^,]*$/, '').trim())
    }
  };

  const onClickDatasetKeywordsDiv = (e) => {
    inputDatasetKeywordsRef.current.focus();
  };

  const handleRemoveOption = (deleted) => {
    // Implement your logic to handle removing the option
    console.log("Removing option:", deleted);
    setSelectedDatasetContributorOptions(
      selectedDatasetContributorOptions.filter(
        (option) => option.id !== deleted.id
      )
    );
    props?.setDatasetContributor(
      selectedDatasetContributorOptions.filter(
        (option) => option.id !== deleted.id
      )
    );
  };

  const renderToken = (option, props, index) => {
    const disableRemove = option.disableRemove;

    return (
      <div
        className={disableRemove ? "token-disabled" : "token"}
        key={`token${option.id}`}
      >
        {/* {`token${option.id}`} */}
        <img src="/images/user_form.svg" className="img-fluid" alt="user" />
        <span className="token-label">{option.label}</span>
        {!disableRemove && (
          <button
            type="button"
            className="token-remove"
            onClick={() => handleRemoveOption(option)}
          >
            &times;
          </button>
        )}
      </div>
    );
  };

  useEffect(() => {
    const contributorsList = Object.values(users)
      .map((item) => {
        return {
          id: item.id,
          name: `${item.first_name} ${item.last_name} ${item.suffix || ""} (${
            item.email
          })`,
          label: item.first_name + " " + item.last_name,
          email: item.email,
          disableRemove:
            currentUser?.email === item.email ||
            props?.datasetProfileOwner === item.email
              ? true
              : false,
        };
      })
      .sort((a, b) => {
        if (a.email === props?.datasetProfileOwner) {
          return -1; // Move specific value to the front
        } else if (b.id === currentUser?.id) {
          return 1;
        } else {
          return b.disableRemove - a.disableRemove;
          // return a.id - b.id; // Sort by ID for other elements
        }
      });

    setOptionsDatasetContributor(contributorsList);
    const versionContributor = contributorsList.find(
      (member) => currentUser?.email === member.email
    );

    const profileOwner = contributorsList.find(
      (member) => props?.datasetProfileOwner === member.email
    );

    if (
      profileOwner &&
      versionContributor &&
      profileOwner !== versionContributor
    ) {
      const datasetContributor =
        props?.editDatasetContributors?.length > 0
          ? Object.values(contributorsList)?.filter((user) =>
              props?.editDatasetContributors?.find(
                (member) => user.email === member.email
              )
            )
          : [profileOwner, versionContributor];
      setSelectedDatasetContributorOptions(datasetContributor);
      props?.setDatasetContributor(datasetContributor);
    }
    if (profileOwner && profileOwner === versionContributor) {
      const datasetContributor =
        props?.editDatasetContributors?.length > 0
          ? Object.values(contributorsList)?.filter((user) =>
              props?.editDatasetContributors?.find(
                (member) => user.email === member.email
              )
            )
          : [profileOwner];
      setSelectedDatasetContributorOptions(datasetContributor);
      props?.setDatasetContributor(datasetContributor);
    }
  }, [users, currentUser, props?.datasetProfileOwner]);

  useEffect(() => {
    dispatch(getCurrentUser()).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (props?.editDatasetKeywords === props?.datasetKeywords) {
      updateDatasetKeywords(props?.editDatasetKeywords.toString() + ",");
    }
  }, [props?.editDatasetKeywords]);

  /***********************************/
  /***********************************/
  /***********************************/

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    Object.values(event.target.files).forEach((file) => {
      props?.setDatasetSelectedFile((prevElements) => [...prevElements, file]);
      props?.validate("datasetFile", file.name);
    });
    setCounter(counter + 1);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    Object.values(event.dataTransfer.files).forEach((file) => {
      props?.setDatasetSelectedFile((prevElements) => [...prevElements, file]);
      props?.validate("datasetFile", file.name);
    });
    setCounter(counter + 1);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    setToolbarHiddenDatasetVersionDescription(true);
    setToolbarHiddenDatasetCitationDetails(true);
    setToolbarHiddenDatasetOtherRelevantInfo(true);
    setToolbarHiddenDatasetLicense(true);
  }, []);

  const onClickDelete = (fileIndex) => {
    let files = props?.datasetSelectedFile;
    files.splice(fileIndex, 1);
    props?.setDatasetSelectedFile(files);
    setCounter(counter + 1);
    if (props?.datasetSelectedFile.length <= 0) {
      fileInputRef.current.value = "";
      props?.validate("datasetFile", "");
    }
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const onChangeSelectAllFileName = (e) => {
    if (e.target.checked) {
      Object.values(props?.datasetSelectedFile).forEach((file, index) => {
        setSelectedCheckboxes((prevElements) => [...prevElements, index]);
      });
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const onClickDeleteSelectedBtn = () => {
    let files = props?.datasetSelectedFile;
    console.log(files);
    // selectedCheckboxes.forEach((item) => {
    //   files.splice(item, 1);
    //   setCounter(counter + 1);
    // });
    let outputArray = files.filter(
      (item, index) => !selectedCheckboxes.includes(index)
    );
    console.log(outputArray);
    props?.setDatasetSelectedFile(outputArray);
    if (outputArray.length <= 0) {
      fileInputRef.current.value = "";
      props?.validate("datasetFile", "");
    }

    setSelectedCheckboxes([]);
  };

  const onChangeCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedCheckboxes((prevElements) => [
        ...prevElements,
        parseInt(e.target.value),
      ]);
    } else {
      let outputArray = selectedCheckboxes.filter(
        (item) => parseInt(e.target.value) !== item
      );
      setSelectedCheckboxes(outputArray);
    }
    setCounter(counter + 1);
  };

  useEffect(() => {
    // Add data-cy attribute to the Quill editor root div after it's mounted
    if (descriptionRef.current) {
      const editorDiv = descriptionRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "description");
    }
    if (citationDetailsRef.current) {
      const editorDiv = citationDetailsRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "citation-details");
    }
    if (relevantInfoRef.current) {
      const editorDiv = relevantInfoRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "relevant-info");
    }
    if (licenseOthersRef.current) {
      const editorDiv = licenseOthersRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "license-others");
    }
  }, []);

  return (
    <Container className="mw-65 ms-0">
      <p className="bold m-0">Overview</p>
      <p>Describe this version in general and include your dataset file.</p>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label className="required-field m-0">Subtitle</Form.Label>
          <Form.Control
            type="text"
            value={props?.datasetSubtitle}
            onChange={onChangeDatasetSubtitle}
            className={`input-field ${
              props?.isValid?.datasetSubtitle
                ? "is-valid"
                : props?.isValid.datasetSubtitle !== undefined
                ? "is-invalid"
                : ""
            }`}
            name="subtitle"
            id="subtitle"
            data-cy="subtitle"
          />
          <span className="d-flex justify-content-between">
            {props?.getFormErrorMessage("datasetSubtitle")}
          </span>
          <span className="sub-note">
            Give a subtitle to your dataset version.
          </span>
        </Form.Group>
        <Form.Group
          className="mb-3"
          onMouseDown={handleMouseDownDatasetVersionDescription}
          onMouseUp={handleMouseUpDatasetVersionDescription}
          onBlur={onBlurDatasetVersionDescription}
          onFocus={onFocusDatasetVersionDescription}
        >
          <Form.Label className="required-field m-0">Description</Form.Label>
          <ReactQuill
            ref={descriptionRef}
            theme="snow"
            modules={modules}
            formats={formats}
            value={props?.datasetVersionDescription}
            onChange={onChangeDatasetVersionDescription}
            className={`input-description input-field form-control ${
              props?.isValid?.datasetVersionDescription
                ? "is-valid"
                : props?.isValid.datasetVersionDescription !== undefined
                ? "is-invalid"
                : ""
            } ${
              toolbarHiddenDatasetVersionDescription
                ? "ql-editor-expanded toolbar-hidden"
                : "ql-editor-shortened toolbar-display"
            }`}
            id="description"
          />
          {props?.getFormErrorMessage("datasetVersionDescription")}
          <span className="sub-note">
            Tell us about the general idea of this version or how this version
            of the dataset differ from its earlier version/s.
          </span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="required-field m-0">Contributor</Form.Label>
          <div className="test" ref={inputRef}>
            <Typeahead
              labelKey="name"
              multiple
              options={optionsDatasetContributor}
              selected={selectedDatasetContributorOptions}
              onChange={handleSelectDatasetContributor}
              onInputChange={onInputChangeDatasetContributor}
              renderToken={renderToken}
              className={`input-field typeahead-input typeahead-input-developers form-control ${
                props?.isValid?.datasetContributor
                  ? "is-valid"
                  : props?.isValid.datasetContributor !== undefined
                  ? "is-invalid"
                  : ""
              }`}
              id="contributor"
              inputProps={{
                "data-cy": "contributor", // Add data-cy attribute to input element
              }}
            />
            {props?.getFormErrorMessage("datasetContributor")}
          </div>
          <span className="sub-note">
            The person who contributed to this version who played a significant
            role in the version's success. Providing accurate contributor
            information helps acknowledge and give credit to those who have
            contributed to the version. (If more than one, separate by comma)
          </span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="required-field m-0">Keywords</Form.Label>
          {/* <Form.Control
            type="text"
            value={props?.datasetKeywords}
            onChange={onChangeDatasetKeywords}
            className={`input-field ${
              props?.isValid?.datasetKeywords
                ? "is-valid"
                : props?.isValid.datasetKeywords !== undefined
                ? "is-invalid"
                : ""
            }`}
          /> */}
          <div
            className={`input-field form-control ${
              props?.isValid?.datasetKeywords
                ? "is-valid"
                : props?.isValid.datasetKeywords !== undefined
                ? "is-invalid"
                : ""
            }`}
            onClick={onClickDatasetKeywordsDiv}
          >
            <div className="word-container">
              {keywordList.map((word, index) => (
                <div key={index} className="d-inline-block">
                  <div className="word-box d-inline-block">{word}</div>
                  <span>,</span>
                </div>
              ))}
              <input
                ref={inputDatasetKeywordsRef}
                type="text"
                value={lastValue}
                onChange={onChangeDatasetKeywords}
                onBlur={onBlurDatasetKeywords}
                onKeyDown={onKeyDownDatasetKeywords}
                className="input-box"
                name="keywords"
                id="keywords"
                data-cy="keywords"
              />
            </div>
          </div>
          <span className="d-flex justify-content-between">
            {props?.getFormErrorMessage("datasetKeywords")}
          </span>
          <span className="sub-note">
            Provide keyword/s to indicate the contents of the dataset. (If more
            than one, separate by comma)
          </span>
        </Form.Group>
        <Form.Group
          className="mb-3"
          onMouseDown={handleMouseDownDatasetCitationDetails}
          onMouseUp={handleMouseUpDatasetCitationDetails}
          onBlur={onBlurDatasetCitationDetails}
          onFocus={onFocusDatasetCitationDetails}
        >
          <Form.Label className="required-field m-0">
            Citation details
          </Form.Label>
          <ReactQuill
            ref={citationDetailsRef}
            theme="snow"
            modules={modules}
            formats={formats}
            value={props?.datasetCitationDetails}
            onChange={onChangeDatasetCitationDetails}
            className={`input-description input-field form-control ${
              props?.isValid?.datasetCitationDetails
                ? "is-valid"
                : props?.isValid.datasetCitationDetails !== undefined
                ? "is-invalid"
                : ""
            } ${
              toolbarHiddenDatasetCitationDetails
                ? "ql-editor-expanded toolbar-hidden"
                : "ql-editor-shortened toolbar-display"
            }`}
            id="citation-details"
          />
          {props?.getFormErrorMessage("datasetCitationDetails")}
          <span className="sub-note">How to cite the dataset version?</span>
        </Form.Group>
        <Form.Group
          className="mb-3"
          onMouseDown={handleMouseDownDatasetOtherRelevantInfo}
          onMouseUp={handleMouseUpDatasetOtherRelevantInfo}
          onBlur={onBlurDatasetOtherRelevantInfo}
          onFocus={onFocusDatasetOtherRelevantInfo}
        >
          <Form.Label className="required-field m-0">
            Other relevant information
          </Form.Label>
          <ReactQuill
            ref={relevantInfoRef}
            theme="snow"
            modules={modules}
            formats={formats}
            value={props?.datasetOtherRelevantInfo}
            onChange={onChangeDatasetOtherRelevantInfo}
            className={`input-description input-field form-control ${
              props?.isValid?.datasetOtherRelevantInfo
                ? "is-valid"
                : props?.isValid.datasetOtherRelevantInfo !== undefined
                ? "is-invalid"
                : ""
            } ${
              toolbarHiddenDatasetOtherRelevantInfo
                ? "ql-editor-expanded toolbar-hidden"
                : "ql-editor-shortened toolbar-display"
            }`}
            id="relevant-info"
          />
          {props?.getFormErrorMessage("datasetOtherRelevantInfo")}
          <span className="sub-note">Cite other relevant information.</span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="required-field m-0">
            Choose dataset file to be uploaded
          </Form.Label>
          <input type="hidden" value={counter} readOnly />
          <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleClick}
            className={`file-drop-area form-control ${
              props?.isValid?.datasetFile
                ? "is-valid"
                : props?.isValid.datasetFile !== undefined
                ? "is-invalid"
                : ""
            }`}
            id="dataset-file"
            data-cy="dataset-file"
          >
            <p>Drag and drop files, or Browse</p>
            <input
              ref={fileInputRef}
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept="
              .rar,
              .rar4,
              .zip,
              .zipx,
              .cab,
              .7z,
              .ace,
              .arj,
              .bz2,
              .gz,
              .lha,
              .lzh,
              .csv,
              .vrp,
              .png,
              .tif,
              .tiff,
              .xml,
              .json,
              .yaml"
              multiple
            />
            <span className="sub-note">
              Supports various file formats.
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="add-version-supported-files">
                    <ul>
                      <li>.rar</li>
                      <li>.rar4</li>
                      <li>.zip</li>
                      <li>.zipx</li>
                      <li>.cab</li>
                      <li>.7z</li>
                      <li>.ace</li>
                      <li>.arj</li>
                      <li>.bz2</li>
                      <li>.gz</li>
                      <li>.lha</li>
                      <li>.lzh</li>
                      <li>.csv</li>
                      <li>.vrp</li>
                      <li>.png</li>
                      <li>.tif</li>
                      <li>.tiff</li>
                      <li>.xml</li>
                      <li>.json</li>
                      <li>.yaml</li>
                    </ul>
                  </Tooltip>
                }
                trigger={["hover", "focus", "click"]}
              >
                <img
                  src="/images/question-gray.svg"
                  className="ms-1 img-fluid"
                  alt="question"
                />
              </OverlayTrigger>
            </span>
          </div>
          <div className="vertical-line"></div>
          <Table bordered hover className="file-name-table">
            <thead>
              <tr>
                <th colSpan={2}>
                  <input
                    type="checkbox"
                    className="me-3"
                    value="all"
                    onChange={onChangeSelectAllFileName}
                    name="select-all-files"
                    id="select-all-files"
                    data-cy="select-all-files"
                  />
                  File name
                  {props?.datasetSelectedFile.length > 0 && (
                    <Button
                      variant="primary"
                      onClick={onClickDeleteSelectedBtn}
                      // disabled={disabled}
                      className="button"
                      style={{ float: "right" }}
                      name="delete-selected"
                      id="delete-selected"
                      data-cy="delete-selected"
                    >
                      Delete Selected
                    </Button>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {props?.datasetSelectedFile ? (
                Object.values(props?.datasetSelectedFile).map((file, index) => {
                  return (
                    <tr key={file.name + index}>
                      <td className="d-flex justify-content-between">
                        <span>
                          <input
                            type="checkbox"
                            className="me-3"
                            onChange={onChangeCheckbox}
                            value={index}
                            checked={
                              selectedCheckboxes.find(
                                (item) => item === index
                              ) >= 0
                                ? true
                                : false
                            }
                            id={`select-file-${index}`}
                            data-cy="select-file"
                          />
                          {file.name}
                        </span>
                        <span>{convertBytesToSize(file.size)}</span>
                      </td>
                      <td
                        onClick={() => onClickDelete(index)}
                        className="delete-icon-row"
                        id="delete-file"
                        data-cy="delete-file"
                      >
                        <img
                          src="/images/delete-file-name-icon.svg"
                          alt="Delete"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr></tr>
              )}
            </tbody>
          </Table>
          {props?.getFormErrorMessage("datasetFile")}
        </Form.Group>
        <Form.Label className="required-field m-0">License</Form.Label>
        <Form.Select
          className="input-field"
          onChange={onChangeDatasetLicense}
          value={datasetLicense}
          name="license"
          id="license"
          data-cy="license"
        >
          <option value="" disabled hidden>
            Select a License
          </option>
          <option value="1">CC0-1.0</option>
          <option value="2">CC-BY-SA-3.0</option>
          <option value="3">CC-BY-SA-4.0</option>
          <option value="4">CC-BY-NC-SA-4.0</option>
          <option value="5">GPL-2.0</option>
          <option value="6">ODbL-1.0</option>
          <option value="7">DbCL-1.0</option>
          <option value="8">CC-BY-4.0</option>
          <option value="9">CC-BY-NC-4.0</option>
          <option value="10">PDDL</option>
          <option value="11">CC-BY-3.0</option>
          <option value="12">CC-BY-3.0-IGO</option>
          <option value="13">US-Government-Works</option>
          <option value="14">CC-BY-NC-SA-3.0-IGO</option>
          <option value="15">CDLA-Permissive-1.0</option>
          <option value="16">CDLA-Sharing-1.0</option>
          <option value="17">CC-BY-ND-4.0</option>
          <option value="18">CC-BY-NC-ND-4.0</option>
          <option value="19">ODC-BY-1.0</option>
          <option value="20">LGPL-3.0</option>
          <option value="21">AGPL-3.0</option>
          <option value="22">FDL-1.3</option>
          <option value="23">EU-ODP-Legal-Notice</option>
          <option value="24">apache-2.0</option>
          <option value="25">Others</option>
          <option value="26">N/A</option>
        </Form.Select>
        <Form.Group
          className="mb-3"
          onMouseDown={handleMouseDownDatasetLicense}
          onMouseUp={handleMouseUpDatasetLicense}
          onBlur={onBlurDatasetLicense}
          onFocus={onFocusDatasetLicense}
        >
          {props?.datasetLicense && props?.datasetLicense !== "26" && (
            <ReactQuill
              ref={licenseOthersRef}
              theme="snow"
              modules={modules}
              formats={formats}
              value={props?.datasetLicenseContent}
              onChange={onChangeDatasetLicenseContent}
              className={`input-description input-field form-control ${
                props?.isValid?.datasetLicenseContent
                  ? "is-valid"
                  : props?.isValid.datasetLicenseContent !== undefined
                  ? "is-invalid"
                  : ""
              } ${
                toolbarHiddenDatasetLicense === true
                  ? "ql-editor-expanded toolbar-hidden"
                  : "ql-editor-shortened toolbar-display"
              }`}
              id="license-others"
            />
          )}
          {props?.getFormErrorMessage("datasetLicenseContent")}
          <span className="sub-note">
            Provide details of the license assigned to the dataset.
          </span>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default Overview;
