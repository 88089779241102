import { useState, useEffect } from "react";
import {
  Container,
  Tab,
  Breadcrumb,
  Tabs,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Overview from "./sections/add-and-edit/Overview";
import SaveAndPublishModal from "components/common-modals/SaveAndPublishModal";
import { SET_MESSAGE } from "actions/types";
import { getDataset, getNextDatasetVersionNumber } from "actions/dataset";
import DiscardModal from "components/common-modals/DiscardModal";
import DiscardChangesModal from "components/common-modals/DiscardChangesModal";
import { useMediaQuery } from "react-responsive";
import "styles/AddDatasetVersion.css";
import "styles/mobile/AddDatasetVersionMobile.css";

/**
 * A module for showing Add Dataset Version component
 * @module components/datasets/AddDatasetVersion
 */

/**
 * Add Dataset Version component
 * @method AddDatasetVersion
 *
 * @return {JSX.Element}
 *
 */

const AddDatasetVersion = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const params = useParams();
  const id = params.id;

  /**
   * -------------------
   * * Redux store state
   * -------------------
   */
  const { currentDataset } = useSelector((state) => state.dataset);
  const { user } = useSelector((state) => state.auth);
  const { currentGroup } = useSelector((state) => state.group);

  const [datasetName, setDatasetName] = useState("");
  const [datasetProfileOwner, setDatasetProfileOwner] = useState("");
  const [dasetProfileVersion, setDatasetProfileVersion] = useState("");
  const [datasetVersionNumber, setDatasetVersionNumber] = useState("");
  const [showSaveAndPublishModal, setShowSaveAndPublishModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [error, setError] = useState({});
  const [counter, setCounter] = useState(0);

  const [saveDraftDisable, setSaveDraftDisable] = useState(true);
  const [saveAndPublishDisable, setSaveAndPublishDisable] = useState(true);
  const [discardDisable, setDiscardDisable] = useState(true);

  useEffect(() => {
    props?.setUrlToRedirected(location.pathname);
    resetForm();
    getDatasetDetails();
  }, [id]);

  const getDatasetDetails = () => {
    props.setPreload(true);
    dispatch(getDataset(id))
      .then((res) => {
        res.length === 0 && navigate("/404");
      })
      .catch((status) => {
        props.setShowToast(true);
        props.setToastStatus(status);
        props.setToastImage(null);
      })
      .finally(() => props.setPreload(false));
  };

  useEffect(() => {
    setDatasetName(currentDataset.title);
    setDatasetProfileOwner(currentDataset.author?.email);
    setDatasetProfileVersion(currentDataset.profile_id);
  }, [currentDataset]);

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name, cName) => {
    return <div className={`invalid-feedback ` + cName}>{error[name]} </div>;
  };

  const handleSaveAndPublishModalClose = () => {
    setShowSaveAndPublishModal(false);
  };

  const handleDiscardModalClose = () => {
    setShowDiscardModal(false);
  };

  const [isValid, setIsValid] = useState({});
  const withFormattingOptions = [
    "daatasetVersionDescription",
    "datasetCitationDetails",
    "datasetLicenseContent",
    "datasetOtherRelevantInfo",
  ];

  const resetForm = () => {
    props?.setDatasetSelectedFile("");
    props?.setDatasetSubtitle("");
    props?.setDatasetVersionDescription("<p><br></p>");
    props?.setDatasetContributor("");
    props?.setDatasetKeywords("");
    props?.setDatasetCitationDetails("<p><br></p>");
    props?.setDatasetOtherRelevantInfo("<p><br></p>");
    props?.setDatasetLicense("");
    props?.setDatasetLicenseContent("<p><br></p>");
    setIsValid({});
    setError({});
    props?.setChangesMade(false);
  };

  useEffect(() => {
    if (
      props?.datasetSelectedFile !== "" ||
      props?.datasetSubtitle !== "" ||
      props?.datasetVersionDescription !== "<p><br></p>" ||
      props?.datasetContributor !== "" ||
      props?.datasetKeywords !== "" ||
      props?.datasetCitationDetails !== "<p><br></p>" ||
      props?.datasetOtherRelevantInfo !== "<p><br></p>" ||
      props?.datasetLicense !== "" ||
      props?.datasetLicenseContent !== "<p><br></p>"
    ) {
      setDiscardDisable(false);
      if (Object.keys(error).length > 0) {
        if (
          (error?.datasetFile && props?.datasetSelectedFile !== "") ||
          (error?.datasetSubtitle && props?.datasetSubtitle !== "") ||
          (error?.datasetVersionDescription &&
            props?.datasetVersionDescription !== "<p><br></p>") ||
          (error?.datasetContributor && props?.datasetContributor !== "") ||
          (error?.datasetKeywords && props?.datasetKeywords !== "") ||
          (error?.datasetCitationDetails &&
            props?.datasetCitationDetails !== "<p><br></p>") ||
          (error?.datasetOtherRelevantInfo &&
            props?.datasetOtherRelevantInfo !== "<p><br></p>") ||
          (error?.datasetLicense && props?.datasetLicense !== "") ||
          (error?.datasetLicenseContent &&
            props?.datasetLicenseContent !== "<p><br></p>")
        ) {
          setSaveDraftDisable(true);
        } else {
          setSaveDraftDisable(false);
        }
      } else {
        setSaveDraftDisable(false);
      }
    } else {
      setSaveDraftDisable(true);
    }

    if (
      Object.keys(error).length === 0 &&
      props?.datasetSelectedFile.length > 0 &&
      props?.datasetSubtitle !== "" &&
      props?.datasetVersionDescription !== "<p><br></p>" &&
      props?.datasetContributor.length > 0 &&
      props?.datasetKeywords.length > 0 &&
      props?.datasetCitationDetails !== "<p><br></p>" &&
      props?.datasetOtherRelevantInfo !== "<p><br></p>" &&
      props?.datasetLicense !== "" &&
      props?.datasetLicenseContent !== "<p><br></p>"
    ) {
      setSaveAndPublishDisable(false);
    } else {
      setSaveAndPublishDisable(true);
    }
  }, [props]);

  const validate = (name, value) => {
    props?.setChangesMade(true);
    if (
      withFormattingOptions.findIndex((field) => name === field) >= 0 &&
      value === "<p><br></p>"
    ) {
      value = "";
    }
    if (value) {
      value = value.toString().trim();
    }
    switch (name) {
      case "datasetFile":
        const extensionName = value?.split(".").at(-1).toLowerCase();
        if (!value) {
          setCounter(counter + 1);
          setIsValid(Object.assign(isValid, { [name]: false }));
          setError(
            Object.assign(error, {
              [name]: "Choose your dataset file",
            })
          );
          props?.setDatasetSelectedFile("");
          return;
        }
        if (
          extensionName !== "rar" &&
          extensionName !== "rar4" &&
          extensionName !== "zip" &&
          extensionName !== "zipx" &&
          extensionName !== "cab" &&
          extensionName !== "7z" &&
          extensionName !== "ace" &&
          extensionName !== "arj" &&
          extensionName !== "bz2" &&
          extensionName !== "gz" &&
          extensionName !== "lha" &&
          extensionName !== "lzh" &&
          extensionName !== "csv" &&
          extensionName !== "vrp" &&
          extensionName !== "png" &&
          extensionName !== "tif" &&
          extensionName !== "tiff" &&
          extensionName !== "xml" &&
          extensionName !== "json" &&
          extensionName !== "yaml"
        ) {
          setCounter(counter + 1);
          setIsValid(Object.assign(isValid, { [name]: false }));
          setError(
            Object.assign(error, {
              [name]: "File type not supported",
            })
          );
          props?.setDatasetSelectedFile(props?.datasetSelectedFile);
          return;
        }
        if (
          props?.datasetSelectedFile &&
          props?.datasetSelectedFile?.find((file) => file.name === value)
        ) {
          props?.setDatasetSelectedFile(props?.datasetSelectedFile);
          return;
        }
        break;
      case "datasetKeywords":
      case "datasetContributor":
        setIsValid(Object.assign(isValid, { [name]: false }));
        if (value.length <= 0) {
          setCounter(counter + 1);
          setError(
            Object.assign(error, {
              [name]: "Please fill out this field",
            })
          );
          return;
        }
        break;
      case "datasetLicenseContent":
        if (value === "") {
          break;
        }
        break;
      default:
        const nameRegex =
          /^[a-zA-Z0-9ñÑ\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]+$/;
        setIsValid(Object.assign(isValid, { [name]: false }));
        if (!nameRegex.test(value)) {
          setError(
            Object.assign(error, {
              [name]:
                "Accepts a-z, A-Z, 0-9, Ñ/ñ, space, and special characters like ~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/",
            })
          );
          return;
        }
        break;
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  const validateAll = () => {
    setCounter(counter + 1);
    validate("datasetSubtitle", props?.datasetSubtitle);
    validate("datasetVersionDescription", props?.datasetVersionDescription);
    props?.datasetSelectedFile.map((file) => {
      validate("datasetFile", file.name);
    });
    validate("datasetContributor", props?.datasetContributor);
    validate("datasetKeywords", props?.datasetKeywords);
    validate("datasetCitationDetails", props?.datasetCitationDetails);
    validate("datasetOtherRelevantInfo", props?.datasetOtherRelevantInfo);
    validate("datasetLicense", props?.datasetLicense);
    validate("datasetLicenseContent", props?.datasetLicenseContent);
  };

  const onClickSave = (isPublish) => {
    if (isPublish) {
      validateAll();
      if (Object.keys(error).length === 0) {
        props?.handleSaveAndPublishDataset();
      }
    } else {
      if (
        props?.datasetSelectedFile !== "" ||
        props?.datasetSubtitle !== "" ||
        props?.datasetVersionDescription !== "<p><br></p>" ||
        props?.datasetContributor.length > 0 ||
        props?.datasetKeywords.length > 0 ||
        props?.datasetCitationDetails !== "<p><br></p>" ||
        props?.datasetOtherRelevantInfo !== "<p><br></p>" ||
        props?.datasetLicense !== "" ||
        props?.datasetLicenseContent !== "<p><br></p>"
      ) {
        props?.handleSaveDraft();
      }
    }
  };

  const onClickDiscard = () => {
    navigate("/datasets/profile/" + id + "?tab=2");
    dispatch({
      type: SET_MESSAGE,
      payload: "Version discarded.",
    });
    props?.setShowToast(true);
    props?.setToastStatus("error");
    props?.setToastImage("/images/remove-success.svg");
  };

  useEffect(() => {
    dispatch(getNextDatasetVersionNumber(id))
      .then((res) => {
        setDatasetVersionNumber(
          `"${
            res.next_version_id < 10
              ? "0" + res.next_version_id
              : res.next_version_id.toString()
          }"`
        );
      })
      .catch((err) => console.log(err));
  }, [id, dasetProfileVersion]);

  const handleDiscardChangesModalClose = () => {
    props?.setShowDiscardChangesModal(false);
  };

  const onClickDiscardChangesMade = () => {
    handleDiscardChangesModalClose();
    resetForm();
    getDatasetDetails();
    navigate(props.urlToRedirected);
  };

  const onClickGoBack = () => {
    handleDiscardChangesModalClose();
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      props?.setShowDiscardChangesModal(true);
      event.preventDefault();
      event.returnValue = ""; // Standard for most browsers
    };

    if (props?.changesMade) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [props?.changesMade]);

  const navigateTo = (url) => {
    if (
      (location.pathname.includes("/version/add") ||
        location.pathname.includes("/version/edit")) &&
      props?.changesMade
    ) {
      props?.setShowDiscardChangesModal(true);
      props.setUrlToRedirected(url);
    } else {
      navigate(url);
    }
  };

  return (
    <>
      <DiscardChangesModal
        showDiscardModal={props?.showDiscardChangesModal}
        onClickGoBack={onClickGoBack}
        onClickDiscard={onClickDiscardChangesMade}
      />
      <SaveAndPublishModal
        showSaveAndPublishModal={showSaveAndPublishModal}
        handleSaveAndPublishModalClose={handleSaveAndPublishModalClose}
        onClickSave={onClickSave}
      />
      <DiscardModal
        showDiscardModal={showDiscardModal}
        handleDiscardModalClose={handleDiscardModalClose}
        onClickDiscard={onClickDiscard}
      />
      <Container className="mw-100 add-dataset-version-container">
        {!isMobile && (
          <Breadcrumb>
            <Breadcrumb.Item active>Datasets</Breadcrumb.Item>
            <Breadcrumb.Item
              className="link"
              onClick={() => navigateTo("/datasets")}
              // linkAs={Link}
              // linkProps={{ to: "/datasets" }}
            >
              Dataset Profiles
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="link"
              onClick={() => navigateTo(`/datasets/profile/${id}`)}
              // linkAs={Link}
              // linkProps={{ to: `/datasets/profile/${id}` }}
            >
              {datasetName}
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
        <p className="title">Add Version {datasetVersionNumber}</p>
        <div className="d-flex justify-content-between">
          <p className="required-field">
            Required fields are marked with an asterisk
          </p>
          {!isMobile && (
            <div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Save as draft</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <Button
                  disabled={saveDraftDisable}
                  variant="outline-light"
                  className="save-icon me-3 px-3 py-1"
                  onClick={() => onClickSave(false)}
                  name="draft-btn"
                  id="draft-btn"
                  data-cy="draft-btn"
                >
                  <img
                    src="/images/save_draft_2.svg"
                    className="img-fluid bg-transparent me-2"
                    alt="save as draft"
                  />
                  Save as draft
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Save and publish</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <Button
                  disabled={saveAndPublishDisable}
                  variant="outline-light"
                  className="save-icon me-3 px-3 py-1"
                  onClick={() => setShowSaveAndPublishModal(true)}
                  name="publish-btn"
                  id="publish-btn"
                  data-cy="publish-btn"
                >
                  <img
                    src="/images/save_publish_2.svg"
                    className="img-fluid bg-transparent me-2"
                    alt="save and publish"
                  />
                  Save & publish
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Discard</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <Button
                  disabled={discardDisable}
                  variant="outline-light"
                  className="save-icon me-3 px-5 py-1"
                  onClick={() => setShowDiscardModal(true)}
                  name="discard"
                  id="discard"
                  data-cy="discard"
                >
                  <img
                    src="/images/trash_2.svg"
                    className="img-fluid"
                    alt="discard"
                  />
                  Discard
                </Button>
              </OverlayTrigger>
            </div>
          )}
        </div>
        {!isMobile && (
          <Tabs
            defaultActiveKey="model-overview"
            id="dataset-tabs"
            className="mb-3"
          >
            <Tab eventKey="model-overview" tabClassName="tabs" title="Overview" tabAttrs={{"data-cy": "overview-tab"}}>
              <Overview
                datasetProfileOwner={datasetProfileOwner}
                datasetSelectedFile={props?.datasetSelectedFile}
                setDatasetSelectedFile={props?.setDatasetSelectedFile}
                error={error}
                datasetProfileID={id}
                dasetProfileVersion={dasetProfileVersion}
                datasetSubtitle={props?.datasetSubtitle}
                datasetVersionDescription={props?.datasetVersionDescription}
                datasetContributor={props?.datasetContributor}
                datasetKeywords={props?.datasetKeywords}
                datasetCitationDetails={props?.datasetCitationDetails}
                datasetOtherRelevantInfo={props?.datasetOtherRelevantInfo}
                datasetLicense={props?.datasetLicense}
                datasetLicenseContent={props?.datasetLicenseContent}
                setDatasetSubtitle={props?.setDatasetSubtitle}
                setDatasetVersionDescription={
                  props?.setDatasetVersionDescription
                }
                setDatasetContributor={props?.setDatasetContributor}
                setDatasetKeywords={props?.setDatasetKeywords}
                setDatasetCitationDetails={props?.setDatasetCitationDetails}
                setDatasetOtherRelevantInfo={props?.setDatasetOtherRelevantInfo}
                setDatasetLicense={props?.setDatasetLicense}
                setDatasetLicenseContent={props?.setDatasetLicenseContent}
                getFormErrorMessage={getFormErrorMessage}
                validate={validate}
                isValid={isValid}
                setIsValid={setIsValid}
              />
            </Tab>
          </Tabs>
        )}
        {isMobile && (
          <>
            <div className="add-dataset-version-form">
              <Overview
                datasetProfileOwner={datasetProfileOwner}
                datasetSelectedFile={props?.datasetSelectedFile}
                setDatasetSelectedFile={props?.setDatasetSelectedFile}
                error={error}
                datasetProfileID={id}
                dasetProfileVersion={dasetProfileVersion}
                datasetSubtitle={props?.datasetSubtitle}
                datasetVersionDescription={props?.datasetVersionDescription}
                datasetContributor={props?.datasetContributor}
                datasetKeywords={props?.datasetKeywords}
                datasetCitationDetails={props?.datasetCitationDetails}
                datasetOtherRelevantInfo={props?.datasetOtherRelevantInfo}
                datasetLicense={props?.datasetLicense}
                datasetLicenseContent={props?.datasetLicenseContent}
                setDatasetSubtitle={props?.setDatasetSubtitle}
                setDatasetVersionDescription={
                  props?.setDatasetVersionDescription
                }
                setDatasetContributor={props?.setDatasetContributor}
                setDatasetKeywords={props?.setDatasetKeywords}
                setDatasetCitationDetails={props?.setDatasetCitationDetails}
                setDatasetOtherRelevantInfo={props?.setDatasetOtherRelevantInfo}
                setDatasetLicense={props?.setDatasetLicense}
                setDatasetLicenseContent={props?.setDatasetLicenseContent}
                getFormErrorMessage={getFormErrorMessage}
                validate={validate}
                isValid={isValid}
                setIsValid={setIsValid}
              />
            </div>
            <div className="buttons-div">
              <Button
                disabled={discardDisable}
                variant="outline-light"
                className="save-icon px-5 py-1"
                onClick={() => setShowDiscardModal(true)}
                name="discard"
                id="discard"
                data-cy="discard"
              >
                Discard
              </Button>
              <Button
                disabled={saveDraftDisable}
                variant="outline-light"
                className="save-icon px-3 py-1"
                onClick={() => onClickSave(false)}
                name="draft-btn"
                id="draft-btn"
                data-cy="draft-btn"
              >
                Save as draft
              </Button>
              <Button
                disabled={saveAndPublishDisable}
                variant="outline-light"
                className="save-icon px-3 py-1"
                onClick={() => setShowSaveAndPublishModal(true)}
                name="publish-btn"
                id="publish-btn"
                data-cy="publish-btn"
              >
                Save & publish
              </Button>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default AddDatasetVersion;
