import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner, Button, Container } from "react-bootstrap";
import { forgotPassword, resendActivation } from "actions/auth";
import { clearMessage } from "actions/message";
import Home from "components/Home";

/**
 * A module for Forgot Password Page component
 * @module components/ForgotPassword
 */

/**
 * Forgot Password Page component
 * @method ForgotPassword
 *
 * @return {JSX.Element}
 *
 */
const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useRef();

  const { message } = useSelector((state) => state.message);

  /**
   * --------------------
   * * Component state
   *  -------------------
   */
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [successful, setSuccessful] = useState(false);
  const [emailSuccessMessage, setEmailSuccessMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  /**
   * Validates name and value of the input field
   * @method validate
   *
   * @param {string} name - A string for name of the input field
   * @param {string} value - A string for the value of the input field
   *
   * @return {void}
   */
  const validate = (name, value) => {
    setIsValid(Object.assign(isValid, { [name]: false }));
    if (!value) {
      setError(Object.assign(error, { [name]: "This field is required!" }));
      return;
    }
    if (!/^(?=.{1,}@)/.test(value)) {
      setError(
        Object.assign(error, {
          [name]: "Minimum of 1 character before the @ symbol",
        })
      );
      return;
    }
    if (!/^(?=.{1,64}@)/.test(value)) {
      setError(
        Object.assign(error, {
          [name]: "Maximum of 64 characters before the @ symbol",
        })
      );
      return;
    }
    if (!/^[a-zA-Z0-9Ññ@._]+$/i.test(value)) {
      setError(
        Object.assign(error, {
          [name]: "Accepts a-z, A-Z, 0-9, Ñ/ñ, @, period, underscore only.",
        })
      );
      return;
    }

    if (
      !/[\wÑñ]+([.-]?[\wÑñ]+)*@[\wÑñ]+([.-]?[\wÑñ]+)*(\.\w{2,3})+$/.test(value)
    ) {
      setError(Object.assign(error, { [name]: "This is not a valid email" }));
      return;
    }
    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   *
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  /**
   * Gets value of the email input field
   * @method onChangeEmail
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    validate("email", email);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setDisabled(true);
    setLoading(true);
    dispatch(forgotPassword(email))
      .then(() => {
        setSuccessful(true);
      })
      .catch((status) => {
        props?.setToastStatus(status);
        props?.setShowToast(true);
        props?.setToastImage(null);
      })
      .finally(() => {
        setLoading(false);
        setDisabled(false);
      });
  };

  /**
   * Navigates to sign in page
   * @method onClickCancel
   */
  const onClickCancel = () => {
    navigate("/signin");
  };

  useEffect(() => {
    if (email !== "" && Object.keys(error).length === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email]);

  useEffect(() => {
    let timer;

    if (isRunning) {
      timer = setInterval(() => {
        if (seconds < 300) {
          setSeconds(seconds + 1);
        } else {
          clearInterval(timer);
          setIsRunning(false);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning, seconds]);

  const startTimer = () => {
    setIsRunning(true);
    setSeconds(0);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const onClickResend = () => {
    startTimer();
    dispatch(forgotPassword(email)).catch((status) => {
      stopTimer();
      setSuccessful(false);
      props?.setShowToast(true);
      props?.setToastStatus(status);
      props?.setToastImage(null);
    });
  };

  return (
    <Home>
      <div className="d-flex align-items-center justify-content-around h-75">
        {!successful ? (
          <Container className="mx-5">
            <div className="text-left">
              <p className="h3 mx-1 mt-4 bold">Forgot password</p>
              <p>
                Enter the email associated with your account and we'll send an
                email notification to reset your password
              </p>
            </div>
            <label className="form-label" htmlFor="form3Example3">
              Email
            </label>
            <div className="email-container mb-3">
              <img src="/images/email.svg" className="mail" alt="email" />
              <input
                type="text"
                className={`form-control email input-field ${
                  isValid?.email
                    ? "is-valid"
                    : isValid.email !== undefined
                    ? "is-invalid"
                    : ""
                }`}
                name="email"
                id="email-address"
                data-cy="email-address"
                value={email}
                onChange={onChangeEmail}
              />
              {getFormErrorMessage("email")}
            </div>

            <div className="buttons-div pt-5">
              <Button
                variant="outline-light"
                onClick={onClickCancel}
                className="light-btn"
                name="cancel"
                id="cancel"
                data-cy="cancel"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="submit-btn button"
                name="send"
                id="send"
                data-cy="send"
                disabled={disabled}
                onClick={handleForgotPassword}
              >
                Send
                {loading && (
                  <Spinner
                    className="spinner"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </div>
          </Container>
        ) : (
          <Container className="text-left mx-5">
            <p className="h3 mx-1 mt-4 bold">Check your email</p>
            <div className="form-outline mb-4">
              <p>We have sent an email notification to reset your password</p>
            </div>
            <div className="d-flex justify-content-around pt-5">
              <Button
                variant="primary"
                type="submit"
                className="submit-btn button"
                onClick={onClickResend}
                disabled={isRunning}
                name="resend"
                id="resend"
                data-cy="resend"
              >
                Resend {isRunning && "(" + (300 - seconds) + ")"}
                {loading && (
                  <Spinner
                    className="spinner"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </div>
          </Container>
        )}
      </div>
    </Home>
  );
};

export default ForgotPassword;
