import { useState, useEffect } from "react";
import {
  Container,
  Tab,
  Breadcrumb,
  Tabs,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Overview from "./sections/view/Overview";
import SaveAndPublishModal from "components/common-modals/SaveAndPublishModal";
import ArchiveModal from "./ArchiveModal";
import { SET_MESSAGE } from "actions/types";
import "styles/ViewModelVersion.css";
import DiscardModal from "components/common-modals/DiscardModal";
import {
  addLikes,
  deleteLikes,
  getDataset,
  getDatasetVersion,
} from "actions/dataset";
import { getAllUsers } from "actions/group";
import DeleteVersionModal from "components/common-modals/DeleteVersionModal";
import DownloadModal from "./DownloadModal";
import { useMediaQuery } from "react-responsive";
import "styles/ViewDatasetVersion.css";
import "styles/mobile/ViewDatasetVersionMobile.css";
import MobileLeftSidebar from "components/layout/MobileLeftSidebar";

/**
 * A module for showing View Dataset Version component
 * @module components/datasets/ViewDatasetVersion
 */

/**
 * View Dataset Version component
 * @method ViewDatasetVersion
 *
 * @return {JSX.Element}
 *
 */

const ViewDatasetVersion = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const params = useParams();
  const id = params.id;
  const profileID = params.profileID;

  /**
   * -------------------
   * * Redux store state
   * -------------------
   */
  const { user } = useSelector((state) => state.auth);
  const { users } = useSelector((state) => state.group);
  const { currentDataset, currentDatasetVersion } = useSelector(
    (state) => state.dataset
  );

  // Overview
  const [datasetProfileID, setDatasetProfileID] = useState("");
  const [datasetVersion, setDatasetVersion] = useState("");
  const [datasetVersionID, setDatasetVersionID] = useState("");
  const [datasetSubtitle, setDatasetSubtitle] = useState("");
  const [datasetVersionDescription, setDatasetVersionDescription] =
    useState("");
  const [datasetContributor, setDatasetContributor] = useState("");
  const [datasetKeywords, setDatasetKeywords] = useState("");
  const [datasetCitationDetails, setDatasetCitationDetails] = useState("");
  const [datasetOtherRelevantInfo, setDatasetOtherRelevantInfo] = useState("");
  const [datasetFile, setDatasetFile] = useState("");
  const [datasetFileID, setDatasetFileID] = useState("");
  const [datasetLicense, setDatasetLicense] = useState("");
  const [datasetLicenseContent, setDatasetLicenseContent] = useState("");
  const [datasetStatus, setDatasetStatus] = useState("");
  const [datasetDownloadCounter, setDatasetDownloadCounter] = useState(0);
  const [datasetLikeCounter, setDatasetLikeCounter] = useState(0);
  const [datasetLikeStatus, setDatasetLikeStatus] = useState(0);
  const [datasetArchiveReason, setDatasetArchiveReason] = useState("");
  const [refreshCurrentVersionCount, setRefreshCurrentVersionCount] =
    useState(0);

  const [expanded, setExpanded] = useState(false);

  const [showSaveAndPublishModal, setShowSaveAndPublishModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showDeleteVersionModal, setShowDeleteVersionModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const [error, setError] = useState({});
  const [userAllow, setUserAllow] = useState(false);
  const [publishAllow, setPublishAllow] = useState(false);

  const withFormattingOptions = [
    "datasetVersionDescription",
    "datasetCitationDetails",
    "datasetLicenseContent",
    "datasetOtherRelevantInfo",
  ];

  useEffect(() => {
    dispatch(getDataset(profileID))
      .then((res) => {
        res.length === 0 && navigate("/404");
      })
      .catch((status) => {
        props.setShowToast(true);
        props.setToastStatus(status);
        props.setToastImage(null);
      });
    dispatch(getAllUsers()).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (
      currentDataset?.author?.email === user?.email ||
      currentDatasetVersion?.contributors?.find(
        (contributor) => contributor.email === user?.email
      )
    ) {
      setUserAllow(true);
    } else {
      setUserAllow(false);
    }
  }, [currentDataset?.author?.email, currentDatasetVersion]);

  useEffect(() => {
    props.setPreload(true);
    dispatch(getDatasetVersion(profileID, id))
      .then((res) => res?.id === 0 && navigate("/404"))
      .catch((status) => {
        console.log(status);
        if (status === "not found") {
          navigate("/404");
        } else {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        }
      })
      .finally(() => props.setPreload(false));
  }, [id, props?.refreshDatasetVersionsCount, refreshCurrentVersionCount]);

  useEffect(() => {
    setDatasetProfileID(profileID);
    setDatasetVersion(currentDatasetVersion?.version_id);
    setDatasetVersionID(currentDatasetVersion?.id);
    setDatasetSubtitle(currentDatasetVersion?.subtitle);
    setDatasetVersionDescription(currentDatasetVersion?.description);
    if (currentDatasetVersion?.contributors?.length > 0) {
      const filteredUsers = Object.values(users).filter((user) =>
        currentDatasetVersion?.contributors.some(
          (contributor) => user.email === contributor.email
        )
      );
      const usersList = Object.values(filteredUsers).map((item) => {
        return item.first_name + " " + item.last_name;
      });
      setDatasetContributor(usersList);
    }
    setDatasetKeywords(currentDatasetVersion?.keywords);
    setDatasetCitationDetails(currentDatasetVersion?.citation_details);
    setDatasetOtherRelevantInfo(
      currentDatasetVersion?.other_relevant_information
    );
    setDatasetFile(currentDatasetVersion?.file_attachments);
    // setDatasetFileID(currentDatasetVersion?.file?.id);
    setDatasetLicense(currentDatasetVersion?.license);
    setDatasetLicenseContent(currentDatasetVersion?.license_content);
    setDatasetStatus(currentDatasetVersion?.status);

    setDatasetLikeCounter(currentDatasetVersion?.likers?.length);
    setDatasetLikeStatus(
      currentDatasetVersion?.likers?.find((liker) => liker === user.id)
        ? true
        : false
    );
    setDatasetDownloadCounter(currentDatasetVersion?.downloads);
    setDatasetArchiveReason(currentDatasetVersion?.reason);
  }, [currentDatasetVersion, users, user]);

  useEffect(() => {
    setDatasetProfileID("");
    setDatasetVersion("");
    setDatasetSubtitle("");
    setDatasetVersionDescription("");
    setDatasetContributor("");
    setDatasetCitationDetails("");
    setDatasetOtherRelevantInfo("");
    setDatasetFile("");
    setDatasetFileID("");
    setDatasetLicense("");
    setDatasetLicenseContent("");
    setDatasetDownloadCounter(0);
    setDatasetArchiveReason("");
    setDatasetStatus("");
  }, []);

  const handleSaveAndPublishModalClose = () => {
    setShowSaveAndPublishModal(false);
  };

  const handleArchiveModalClose = () => {
    setShowArchiveModal(false);
  };

  const handleDownloadModalClose = () => {
    setShowDownloadModal(false);
  };

  const handleDeleteVersionModalClose = () => {
    setShowDeleteVersionModal(false);
  };

  const handleDiscardModalClose = () => {
    setShowDiscardModal(false);
  };

  const onClickSave = (isPublish) => {
    if (isPublish) {
      props?.saveAndPublish(datasetProfileID, id);
    }
  };

  const onClickDiscard = () => {
    navigate(
      "/datasets/profile/" + datasetProfileID + "version/" + id + "?tab=2"
    );
    dispatch({
      type: SET_MESSAGE,
      payload: "Version discarded.",
    });
    props?.setShowToast(true);
    props?.setToastStatus("error");
    props?.setToastImage("/images/remove-success.svg");
  };

  const handleToggleExpand = (e) => {
    setExpanded(!expanded);
  };

  const onClickEdit = () => {
    navigate("/datasets/profile/" + profileID + "/version/edit/" + id);
  };

  const validate = (name, value) => {
    if (
      withFormattingOptions.findIndex((field) => name === field) >= 0 &&
      value === "<p><br></p>"
    ) {
      value = "";
    }
    if (value) {
      value = value.toString().trim();
    }
    switch (name) {
      case "datasetFile":
        const extensionName = value?.split(".").at(-1).toLowerCase();
        if (!value) {
          setError(
            Object.assign(error, {
              [name]: "Choose your dataset file",
            })
          );
          return;
        }
        if (
          extensionName !== "rar" &&
          extensionName !== "rar4" &&
          extensionName !== "zip" &&
          extensionName !== "zipx" &&
          extensionName !== "cab" &&
          extensionName !== "7z" &&
          extensionName !== "ace" &&
          extensionName !== "arj" &&
          extensionName !== "bz2" &&
          extensionName !== "gz" &&
          extensionName !== "lha" &&
          extensionName !== "lzh" &&
          extensionName !== "csv" &&
          extensionName !== "vrp" &&
          extensionName !== "png" &&
          extensionName !== "tif" &&
          extensionName !== "tiff" &&
          extensionName !== "xml" &&
          extensionName !== "json" &&
          extensionName !== "yaml"
        ) {
          setError(
            Object.assign(error, {
              [name]: "File type not supported",
            })
          );

          return;
        }
        break;
      case "datasetKeywords":
      case "datasetContributor":
        if (value?.length <= 0) {
          setError(
            Object.assign(error, {
              [name]: "Please fill out this field",
            })
          );
          return;
        }
        break;
      case "datasetLicenseContent":
        if (value === "") {
          break;
        }
        break;
      default:
        const nameRegex =
          /^[a-zA-Z0-9ñÑ\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]+$/;

        if (!nameRegex.test(value)) {
          setError(
            Object.assign(error, {
              [name]:
                "Accepts a-z, A-Z, 0-9, Ñ/ñ, space, and special characters like ~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/",
            })
          );
          return;
        }
        break;
    }

    delete error[name];
  };

  const validateAll = () => {
    // validate("modelFile", props?.modelFile);
    validate("datasetVersion", datasetVersion);
    validate("datasetSubtitle", datasetSubtitle);
    validate("datasetVersionDescription", datasetVersionDescription);
    validate("datasetContributor", datasetContributor);
    validate("datasetKeywords", datasetKeywords);
    validate("datasetCitationDetails", datasetCitationDetails);
    validate("datasetOtherRelevantInfo", datasetOtherRelevantInfo);
    if (datasetFile) {
      datasetFile.map((file) => {
        validate("datasetFile", file.file_attachment);
      });
    }
    validate("datasetLicense", datasetLicense);
    validate("datasetLicenseContent", datasetLicenseContent);
    // navigate("/datasets/version/edit/" + id);
  };

  useEffect(() => {
    validateAll();
    if (
      Object.keys(error).length === 0 &&
      datasetVersion !== "" &&
      datasetSubtitle !== "" &&
      datasetVersionDescription !== "<p><br></p>" &&
      datasetContributor !== "" &&
      datasetKeywords !== "" &&
      datasetCitationDetails !== "<p><br></p>" &&
      datasetOtherRelevantInfo !== "<p><br></p>" &&
      datasetFile.length > 0 &&
      datasetLicense !== "" &&
      datasetLicenseContent !== "<p><br></p>"
    ) {
      setPublishAllow(true);
    } else {
      setPublishAllow(false);
    }
  }, [
    datasetVersion,
    datasetSubtitle,
    datasetVersionDescription,
    datasetContributor,
    datasetKeywords,
    datasetCitationDetails,
    datasetOtherRelevantInfo,
    datasetFile,
    datasetLicense,
    datasetLicenseContent,
  ]);

  const onClickDeleteVersion = () => {
    props?.deleteDatasetVersion(profileID, id);
  };

  const onClickLike = () => {
    if (datasetLikeStatus) {
      dispatch(deleteLikes(datasetProfileID, datasetVersionID))
        .catch((error) => console.log(error))
        .finally(() => {
          setRefreshCurrentVersionCount(refreshCurrentVersionCount + 1);
        });
    } else {
      dispatch(addLikes(datasetProfileID, datasetVersionID))
        .catch((error) => console.log(error))
        .finally(() => {
          setRefreshCurrentVersionCount(refreshCurrentVersionCount + 1);
        });
    }
  };

  const onClickDuplicateModelVersion = () => {
    props?.duplicateDatasetVersion(datasetProfileID, datasetVersionID);
  };

  return (
    <>
      {publishAllow && (
        <SaveAndPublishModal
          showSaveAndPublishModal={showSaveAndPublishModal}
          handleSaveAndPublishModalClose={handleSaveAndPublishModalClose}
          onClickSave={onClickSave}
        />
      )}
      {currentDatasetVersion?.version_id !== "" && (
        <ArchiveModal
          datasetProfileID={datasetProfileID}
          datasetVersionID={datasetVersionID}
          setShowToast={props?.setShowToast}
          setToastStatus={props?.setToastStatus}
          setToastImage={props?.setToastImage}
          setRefreshDatasetVersionsCount={props?.setRefreshDatasetVersionsCount}
          refreshDatasetVersionsCount={props?.refreshDatasetVersionsCount}
          showArchiveModal={showArchiveModal}
          handleArchiveModalClose={handleArchiveModalClose}
        />
      )}

      {currentDatasetVersion?.version_id !== "" && (
        <DownloadModal
          datasetProfileID={datasetProfileID}
          datasetVersionID={datasetVersionID}
          showDownloadModal={showDownloadModal}
          downloadDatasetVersion={props?.downloadDatasetVersion}
          handleDownloadModalClose={handleDownloadModalClose}
        />
      )}
      <DeleteVersionModal
        showDeleteVersionModal={showDeleteVersionModal}
        handleDeleteVersionModalClose={handleDeleteVersionModalClose}
        onClickDeleteVersion={onClickDeleteVersion}
      />
      <DiscardModal
        showDiscardModal={showDiscardModal}
        handleDiscardModalClose={handleDiscardModalClose}
        onClickDiscard={onClickDiscard}
      />
      <div className="mw-100 view-dataset-version-container">
        <div className="view-model-version-mobile-header">
          <MobileLeftSidebar />
          <Breadcrumb>
            <Breadcrumb.Item active>Datasets</Breadcrumb.Item>
            <Breadcrumb.Item
              className="link"
              linkAs={Link}
              linkProps={{ to: "/datasets" }}
            >
              Dataset Profiles
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="link"
              linkAs={Link}
              linkProps={{
                to: `/datasets/profile/${datasetProfileID}?tab=2`,
              }}
            >
              {currentDataset?.title}
            </Breadcrumb.Item>
          </Breadcrumb>
          {isMobile && datasetStatus !== "ARCHIVED" && (
            <>
              <p className="bold dataset-version-title">
                {datasetVersion} {datasetSubtitle}
              </p>
              <LabelVersionStatus
                datasetStatus={datasetStatus}
                datasetArchiveReason={datasetArchiveReason}
              />
              {userAllow && (
                <img
                  src="/images/three_dot_menu.svg"
                  className="mobile-three-dot-icon"
                  alt="menu"
                  onClick={handleToggleExpand}
                  id="more-btn"
                  data-cy="more-btn"
                />
              )}
            </>
          )}
        </div>

        <div className="d-flex justify-content-between pt-85-px">
          <p className="title">
            {datasetVersion} {datasetSubtitle}
          </p>
          <div className="d-flex">
            {!isMobile && userAllow && datasetStatus !== "ARCHIVED" && (
              <Dropdown
                className="action-dropdown"
                show={expanded}
                onToggle={handleToggleExpand}
              >
                <Dropdown.Toggle
                  variant="secondary"
                  className="three-dot-dropdown-table"
                  id="more-btn"
                  data-cy="more-btn"
                >
                  <img
                    src="/images/three_dot_menu.svg"
                    className="img-fluid"
                    alt="menu"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="action-dropdown-menu">
                  {datasetStatus !== "PUBLISHED" && (
                    <>
                      {publishAllow && (
                        <Dropdown.Item
                          onClick={() => setShowSaveAndPublishModal(true)}
                          id="publish-dataset-version"
                          data-cy="publish-dataset-version"
                        >
                          <img
                            src="/images/save_publish.svg"
                            className="img-fluid icon-list"
                            alt="save and publish"
                          />
                          Publish
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                  {datasetStatus === "DRAFT" && (
                    <>
                      <Dropdown.Item
                        onClick={onClickEdit}
                        id="edit-dataset-version"
                        data-cy="edit-dataset-version"
                      >
                        <img
                          src="/images/edit.svg"
                          className="img-fluid icon-list"
                          alt="edit"
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => setShowDeleteVersionModal(true)}
                        id="delete-dataset-version"
                        data-cy="delete-dataset-version"
                      >
                        <img
                          src="/images/trash.svg"
                          className="img-fluid icon-list"
                          alt="discard"
                        />
                        Delete
                      </Dropdown.Item>
                    </>
                  )}
                  {datasetStatus === "PUBLISHED" && (
                    <>
                      <Dropdown.Item
                        onClick={onClickDuplicateModelVersion}
                        id="duplicate-dataset-version"
                        data-cy="duplicate-dataset-version"
                      >
                        <img
                          src="/images/duplicate.svg"
                          className="img-fluid icon-list"
                          alt="duplicate"
                        />
                        Make a copy
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => setShowArchiveModal(true)}
                        id="archive-dataset-version"
                        data-cy="archive-dataset-version"
                      >
                        <img
                          src="/images/archive.svg"
                          className="img-fluid icon-list"
                          alt="archive"
                        />
                        Archive
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
            <Offcanvas
              show={expanded && isMobile}
              onHide={handleToggleExpand}
              placement="bottom"
              className="offcanvas-choose-action"
            >
              <Offcanvas.Body>
                <p className="choose-action-title bold">
                  {datasetVersion} {datasetSubtitle}
                </p>
                <p className="choose-action-subtitle">Choose an action</p>
                <ul className="choose-action-ul p-0">
                  {datasetStatus !== "PUBLISHED" && (
                    <>
                      {publishAllow && (
                        <li
                          onClick={() => setShowSaveAndPublishModal(true)}
                          id="publish-dataset-version"
                          data-cy="publish-dataset-version"
                        >
                          <img
                            src="/images/save_publish.svg"
                            className="img-fluid icon-list"
                            alt="save and publish"
                          />
                          Publish
                        </li>
                      )}
                    </>
                  )}
                  {datasetStatus === "DRAFT" && (
                    <>
                      <li
                        onClick={onClickEdit}
                        id="edit-dataset-version"
                        data-cy="edit-dataset-version"
                      >
                        <img
                          src="/images/edit.svg"
                          className="img-fluid icon-list"
                          alt="edit"
                        />
                        Edit
                      </li>
                      <hr />
                      <li
                        onClick={() => setShowDeleteVersionModal(true)}
                        id="delete-dataset-version"
                        data-cy="delete-dataset-version"
                      >
                        <img
                          src="/images/trash.svg"
                          className="img-fluid icon-list"
                          alt="discard"
                        />
                        Delete
                      </li>
                    </>
                  )}
                  {datasetStatus === "PUBLISHED" && (
                    <>
                      <li
                        onClick={onClickDuplicateModelVersion}
                        id="duplicate-dataset-version"
                        data-cy="duplicate-dataset-version"
                      >
                        <img
                          src="/images/duplicate.svg"
                          className="img-fluid icon-list"
                          alt="duplicate"
                        />
                        Make a copy
                      </li>
                      <hr />
                      <li
                        onClick={() => setShowArchiveModal(true)}
                        id="archive-dataset-version"
                        data-cy="archive-dataset-version"
                      >
                        <img
                          src="/images/archive.svg"
                          className="img-fluid icon-list"
                          alt="archive"
                        />
                        Archive
                      </li>
                    </>
                  )}
                </ul>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
        <div className="d-flex align-items-center labels">
          {!isMobile && (
            <LabelVersionStatus
              datasetStatus={datasetStatus}
              datasetArchiveReason={datasetArchiveReason}
            />
          )}
          {datasetStatus === "PUBLISHED" && (
            <>
              <div className="d-flex">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Download</Tooltip>}
                  trigger={["hover", "focus"]}
                >
                  <Button
                    variant="outline-light"
                    className="input-btn"
                    onClick={() => setShowDownloadModal(true)}
                    name="download-version"
                    id="download-version"
                    data-cy="download-version"
                  >
                    <img src="/images/download-btn-icon.svg" alt="download" />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>No. of downloads</Tooltip>}
                  trigger={["hover", "focus"]}
                >
                  <span className="input-counter">
                    {datasetDownloadCounter}
                  </span>
                </OverlayTrigger>
              </div>
              <div className="d-flex">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Like</Tooltip>}
                  trigger={["hover", "focus"]}
                >
                  <Button
                    variant="outline-light"
                    className="input-btn"
                    onClick={() => onClickLike()}
                    style={
                      datasetLikeStatus
                        ? { backgroundColor: "#cfcee3" }
                        : { backgroundColor: "#fff" }
                    }
                    name="like-version"
                    id="like-version"
                    data-cy="like-version"
                  >
                    <img
                      src={
                        datasetLikeStatus
                          ? "/images/liked.svg"
                          : "/images/like.svg"
                      }
                      alt="like"
                    />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>No. of Likes</Tooltip>}
                  trigger={["hover", "focus"]}
                >
                  <span className="input-counter">{datasetLikeCounter}</span>
                </OverlayTrigger>
              </div>
            </>
          )}
        </div>
        {!isMobile && (
          <Tabs
            defaultActiveKey="dataset-overview"
            id="dataset-tabs"
            className="mb-3"
          >
            <Tab
              eventKey="dataset-overview"
              tabClassName="tabs"
              title="Overview"
              tabAttrs={{ "data-cy": "overview-tab" }}
            >
              {currentDatasetVersion && (
                <Overview
                  datasetProfileID={datasetProfileID}
                  datasetVersion={datasetVersion}
                  datasetSubtitle={datasetSubtitle}
                  datasetVersionDescription={datasetVersionDescription}
                  datasetContributor={datasetContributor}
                  datasetKeywords={datasetKeywords}
                  datasetCitationDetails={datasetCitationDetails}
                  datasetOtherRelevantInfo={datasetOtherRelevantInfo}
                  datasetFile={datasetFile}
                  datasetLicense={datasetLicense}
                  datasetLicenseContent={datasetLicenseContent}
                />
              )}
            </Tab>
          </Tabs>
        )}
        {isMobile && (
          <>
            <div className="view-dataset-version-form">
              {currentDatasetVersion && (
                <Overview
                  datasetProfileID={datasetProfileID}
                  datasetVersion={datasetVersion}
                  datasetSubtitle={datasetSubtitle}
                  datasetVersionDescription={datasetVersionDescription}
                  datasetContributor={datasetContributor}
                  datasetKeywords={datasetKeywords}
                  datasetCitationDetails={datasetCitationDetails}
                  datasetOtherRelevantInfo={datasetOtherRelevantInfo}
                  datasetFile={datasetFile}
                  datasetLicense={datasetLicense}
                  datasetLicenseContent={datasetLicenseContent}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const LabelVersionStatus = (props) => {
  return (
    <div className="label-version-status-div">
      <div>
        {props.datasetStatus === "PUBLISHED" && (
          <span
            className="dataset-status"
            style={{ backgroundColor: "#99e6a1" }}
          >
            Published version
          </span>
        )}
        {props.datasetStatus === "DRAFT" && (
          <span
            className="dataset-status"
            style={{ backgroundColor: "#35ceff" }}
          >
            Draft version
          </span>
        )}
        {props.datasetStatus === "ARCHIVED" && (
          <span
            className="dataset-status"
            style={{ backgroundColor: "#2e2e2e", color: "#fff" }}
          >
            Archived version
          </span>
        )}
      </div>
      {props.datasetStatus === "ARCHIVED" && (
        <span className="d-flex">
          <img src="/images/information.svg" className="information me-1" />
          <p className="info-text">
            Version is archived for the reason:{" "}
            {props.datasetArchiveReason || "--"}
          </p>
        </span>
      )}
    </div>
  );
};

export default ViewDatasetVersion;
