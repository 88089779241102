import { Modal, Button, Spinner, Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { changePassword, getCurrentUser } from "actions/auth";
import { useMediaQuery } from "react-responsive";
import "styles/UpdatePasswordMobile.css";

/**
 * A module for the UpdatePasswordModal Component
 * @module components/users/UpdatePasswordModal
 */

/**
 * Displaying menu in the left side of the system
 * @method UpdatePasswordModal
 *
 * @return {JSX.Element}
 *
 */
const UpdatePasswordModal = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordShow, setNewPasswordShow] = useState(false);

  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const validate = (name, value) => {
    setIsValid(Object.assign(isValid, { [name]: false }));
    if (!value) {
      setError(Object.assign(error, { [name]: "This field is required!" }));
      return;
    }

    const regexPattern = /^[a-zA-Z0-9 Ññ~`!@#$%^&*()_\-+={[}\]|\\:;'"<,>.?/]+$/;
    if (regexPattern.test(value) === false) {
      setError(
        Object.assign(error, {
          [name]:
            "Accepts a-z, A-Z, 0-9, Ñ/ñ, space, and special characters like ~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/",
        })
      );
      return;
    }
    const validatePassword =
      /^(?=.*[ Ññ~`!@#$%^&*()_\-+={}[\]|\\:;'"<,>.?/])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9 Ññ~`!@#$%^&*()_\-+={}[\]|\\:;'"<,>.?/]{8,}$/; // Minimum of 8 characters with atleast 1 symbol, 1 uppercase letter and 1 number
    const validatePassword2 =
      /^(?=.*[ Ññ~`!@#$%^&*()_\-+={[}\]|\\:;'"<,>.?/])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9 Ññ~`!@#$%^&*()_\-+={[}\]|\\:;'"<,>.?/]{8,64}$/;
    if (validatePassword.test(value) === false) {
      setError(
        Object.assign(error, {
          [name]:
            "Minimum of 8 characters with atleast 1 symbol, 1 uppercase letter and 1 number",
        })
      );
      return;
    }

    if (validatePassword2.test(value) === false) {
      setError(
        Object.assign(error, {
          [name]:
            "Maximum of 64 characters with atleast 1 symbol, 1 uppercase letter and 1 number",
        })
      );
      return;
    }

    if (name === "newPassword" && value === oldPassword) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Must not be equal to the your current password",
        })
      );
      return;
    }
    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  const handleFormSubmit = () => {
    setLoading(true);
    setDisabled(true);
    dispatch(changePassword(oldPassword, newPassword, newPassword))
      .then((status) => {
        props.setToastStatus(status);
        props.setToastImage("/images/password_change_success.png");
        dispatch(getCurrentUser()).catch((error) => {
          console.log(error);
        });
      })
      .catch((status) => {
        props.setToastStatus(status);
        props.setToastImage(null);
      })
      .finally(() => {
        props.setShowToast(true);
        props.handleUpdatePasswordModalClose();
        resetModalForm();
      });
  };

  const resetModalForm = () => {
    setOldPassword("");
    setNewPassword("");
    setLoading(false);
    setDisabled(true);
    setIsValid({});
    setError({});
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  /**
   * Change hidden state of password
   * @method onClickOldPasswordBtn
   */
  const onClickOldPasswordBtn = () => {
    setOldPasswordShow(oldPasswordShow ? false : true);
  };

  /**
   * Change hidden state of password
   * @method onClickNewPasswordBtn
   */
  const onClickNewPasswordBtn = () => {
    setNewPasswordShow(newPasswordShow ? false : true);
  };

  /**
   * Gets value of the old password input field
   * @method onChangeOldPassword
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeOldPassword = (e) => {
    const oldPassword = e.target.value;
    setOldPassword(oldPassword);
    validate("oldPassword", oldPassword);
  };

  /**
   * Gets value of the new password input field
   * @method onChangeNewPassword
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeNewPassword = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    validate("newPassword", newPassword);
  };

  useEffect(() => {
    if (
      oldPassword !== "" &&
      newPassword !== "" &&
      Object.keys(error).length === 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [oldPassword, newPassword, error]);

  useEffect(() => {
    resetModalForm();
  }, [props.showUpdatePasswordModal]);

  return (
    <>
      <Offcanvas
        show={props.showUpdatePasswordModal && isMobile}
        onHide={props.handleUpdatePasswordModalClose}
        placement="bottom"
        className="offcanvas-update-password"
      >
        <Offcanvas.Body>
          <Form className="mb-5">
            <p className="bold">Update your password</p>
            <div className="personal-info-form">
              <p>
                Minimum of 8 characters with atleast 1 symbol, 1 uppercase
                letter and 1 number
              </p>
              <Form.Group className="password-container mb-3">
                <Form.Label className="semi-bold">Current password</Form.Label>
                <Form.Control
                  type={oldPasswordShow ? "text" : "password"}
                  value={oldPassword}
                  onChange={onChangeOldPassword}
                  className={`form-control input-field ${
                    isValid?.oldPassword
                      ? "is-valid"
                      : isValid.oldPassword !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="current-password"
                  id="current-password"
                  datta-cy="current-password"
                />
                <img
                  onClick={onClickOldPasswordBtn}
                  src={
                    oldPasswordShow
                      ? "/images/eye-2.svg"
                      : "/images/eye-slash-2.svg"
                  }
                  className="eye-security"
                  alt="hide"
                  id="current-pass-show-btn"
                  data-cy="current-pass-show-btn"
                />
                {getFormErrorMessage("oldPassword")}
              </Form.Group>
              <Form.Group className="password-container mb-3">
                <Form.Label className="semi-bold">New password</Form.Label>
                <Form.Control
                  type={newPasswordShow ? "text" : "password"}
                  value={newPassword}
                  onChange={onChangeNewPassword}
                  className={`form-control input-field ${
                    isValid?.newPassword
                      ? "is-valid"
                      : isValid.newPassword !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="new-password"
                  id="new-password"
                  data-cy="new-password"
                />
                <img
                  onClick={onClickNewPasswordBtn}
                  src={
                    newPasswordShow
                      ? "/images/eye-2.svg"
                      : "/images/eye-slash-2.svg"
                  }
                  className="eye-security"
                  alt="hide"
                  id="new-pass-show-btn"
                  data-cy="new-pass-show-btn"
                />
                {getFormErrorMessage("newPassword")}
              </Form.Group>
            </div>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleUpdatePasswordModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleFormSubmit}
              className="submit-btn button"
              disabled={disabled}
              name="update-btn"
              id="update-btn"
              data-cy="update-btn"
            >
              Update
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={props.showUpdatePasswordModal && !isMobile}
        onHide={props.handleUpdatePasswordModalClose}
        dialogClassName="modal-32w"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="bold">Update your password</Modal.Title>
          <Button
            variant="close"
            onClick={props.handleUpdatePasswordModalClose}
            name="close-btn"
            id="close-btn"
            data-cy="close-btn"
          />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p>
              Minimum of 8 characters with atleast 1 symbol, 1 uppercase letter
              and 1 number
            </p>
            <Form.Group className="password-container mb-3">
              <Form.Label className="semi-bold">Current password</Form.Label>
              <Form.Control
                type={oldPasswordShow ? "text" : "password"}
                value={oldPassword}
                onChange={onChangeOldPassword}
                className={`form-control input-field ${
                  isValid?.oldPassword
                    ? "is-valid"
                    : isValid.oldPassword !== undefined
                    ? "is-invalid"
                    : ""
                }`}
                name="current-password"
                id="current-password"
                data-cy="current-password"
              />
              <img
                onClick={onClickOldPasswordBtn}
                src={
                  oldPasswordShow
                    ? "/images/eye-2.svg"
                    : "/images/eye-slash-2.svg"
                }
                className="eye-security"
                alt="hide"
                id="current-pass-show-btn"
                data-cy="current-pass-show-btn"
              />
              {getFormErrorMessage("oldPassword")}
            </Form.Group>
            <Form.Group className="password-container mb-3">
              <Form.Label className="semi-bold">New password</Form.Label>
              <Form.Control
                type={newPasswordShow ? "text" : "password"}
                value={newPassword}
                onChange={onChangeNewPassword}
                className={`form-control input-field ${
                  isValid?.newPassword
                    ? "is-valid"
                    : isValid.newPassword !== undefined
                    ? "is-invalid"
                    : ""
                }`}
                name="new-password"
                id="new-password"
                data-cy="new-password"
              />
              <img
                onClick={onClickNewPasswordBtn}
                src={
                  newPasswordShow
                    ? "/images/eye-2.svg"
                    : "/images/eye-slash-2.svg"
                }
                className="eye-security"
                alt="hide"
                id="new-pass-show-btn"
                data-cy="new-pass-show-btn"
              />
              {getFormErrorMessage("newPassword")}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props.handleUpdatePasswordModalClose}
            className="light-btn"
            name="cancel"
            id="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleFormSubmit}
            className="submit-btn button"
            disabled={disabled}
            name="update-btn"
            id="update-btn"
            data-cy="update-btn"
          >
            Update
            {loading && (
              <Spinner
                className="spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdatePasswordModal;
