import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import { modules, formats } from "common/constants";
import { useRef, useEffect } from "react";

const Tab5 = (props) => {
  const modelDataRef = useRef();
  const mitigationsRef = useRef();
  const risksAndHarmsRef = useRef();
  const useCasesRef = useRef();

  useEffect(() => {
    // Add data-cy attribute to the Quill editor root div after it's mounted
    if (modelDataRef.current) {
      const editorDiv = modelDataRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "model-data");
    }

    if (mitigationsRef.current) {
      const editorDiv = mitigationsRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "mitigations");
    }

    if (risksAndHarmsRef.current) {
      const editorDiv = risksAndHarmsRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "risks-and-harms");
    }

    if (useCasesRef.current) {
      const editorDiv = useCasesRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "usecases");
    }
  }, []);

  return (
    <div className="tab-div" ref={props.elementRef5}>
      <div>
        <span className="bold">Ethical considerations and biases</span>
        <p>
          Information about the ethical considerations that went into model
          development, surfacing ethical challenges and solutions to
          stakeholders. Include other ethical considerations such as review by
          an external board or testing with a specific group for clearing.
        </p>
      </div>
      <Form.Group
        onMouseDown={props.handleMouseDownModelData}
        onMouseUp={props.handleMouseUpModelData}
        onBlur={props.onBlurModelData}
        onFocus={props.onFocusModelData}
      >
        <Form.Label className="required-field">Data</Form.Label>
        <ReactQuill
          ref={modelDataRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelData}
          onChange={props.onChangeModelData}
          className={`input-field input-description form-control ${
            props.isValid?.modelData
              ? "is-valid"
              : props.isValid.modelData !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelData
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="model-data"
        />
        {props.getFormErrorMessage("modelData")}
        <span className="sub-note">
          Does the model use any sensitive data. For example, classified data.
        </span>
      </Form.Group>
      <Form.Group>
        <Form.Label className="required-field">Human Life</Form.Label>
        <div key="inline-radio">
          <input
            name="modelHumanLife"
            type="radio"
            value="Yes"
            checked={props.modelHumanLife === "Yes"}
            onChange={props.onChangeModelHumanLife}
            id="life-yes"
            data-cy="life-yes"
          />
          <span className="ms-3 me-5">Yes</span>
          <input
            name="modelHumanLife"
            type="radio"
            value="No"
            checked={props.modelHumanLife === "No"}
            onChange={props.onChangeModelHumanLife}
            id="life-no"
            data-cy="life-no"
          />
          <span className="ms-3">No</span>
          {props.getFormErrorMessage("modelHumanLife")}
        </div>
        <span className="sub-note">
          Is the model intended to perform in a situation for decision-making in
          matters that are central to human life or flourishing. For example,
          criminal sentence, health, and safety.
        </span>
      </Form.Group>
      <Form.Group
        onMouseDown={props.handleMouseDownModelMitigations}
        onMouseUp={props.handleMouseUpModelMitigations}
        onBlur={props.onBlurModelMitigations}
        onFocus={props.onFocusModelMitigations}
      >
        <Form.Label className="required-field">Mitigations</Form.Label>
        <ReactQuill
          ref={mitigationsRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelMitigations}
          onChange={props.onChangeModelMitigations}
          className={`input-field input-description form-control ${
            props.isValid?.modelMitigations
              ? "is-valid"
              : props.isValid.modelMitigations !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelMitigations
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="mitigations"
        />
        {props.getFormErrorMessage("modelMitigations")}
        <span className="sub-note">
          What were the risk mitigations employed during model development?
        </span>
      </Form.Group>
      <Form.Group
        onMouseDown={props.handleMouseDownModelRisksAndHarms}
        onMouseUp={props.handleMouseUpModelRisksAndHarms}
        onBlur={props.onBlurModelRisksAndHarms}
        onFocus={props.onFocusModelRisksAndHarms}
      >
        <Form.Label className="required-field">Risks and harms</Form.Label>
        <ReactQuill
          ref={risksAndHarmsRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelRisksAndHarms}
          onChange={props.onChangeModelRisksAndHarms}
          className={`input-field input-description form-control ${
            props.isValid?.modelRisksAndHarms
              ? "is-valid"
              : props.isValid.modelRisksAndHarms !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelRisksAndHarms
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="risks-and-harms"
        />
        {props.getFormErrorMessage("modelRisksAndHarms")}
        <span className="sub-note">
          What risks may occur when using the model? Identify potential
          recipients of risks, likelihood of risks, and magnitude of harms
          brought by the risks.
        </span>
      </Form.Group>
      <Form.Group
        onMouseDown={props.handleMouseDownModelUseCases}
        onMouseUp={props.handleMouseUpModelUseCases}
        onBlur={props.onBlurModelUseCases}
        onFocus={props.onFocusModelUseCases}
      >
        <Form.Label className="required-field">Use cases</Form.Label>
        <ReactQuill
        ref={useCasesRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.modelUseCases}
          onChange={props.onChangeModelUseCases}
          className={`input-field input-description form-control ${
            props.isValid?.modelUseCases
              ? "is-valid"
              : props.isValid.modelUseCases !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHiddenModelUseCases
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="usecases"
        />
        {props.getFormErrorMessage("modelUseCases")}
        <span className="sub-note">
          Are there any model use cases that are considered as disturbing?
        </span>
      </Form.Group>
    </div>
  );
};

export default Tab5;
