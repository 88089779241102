import { useEffect, useRef, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { modules, formats } from "common/constants";
import ReactQuill from "react-quill";

/**
 * A module for Recommendations component
 * @module components/models/sections/Recommendations
 */

/**
 * Recommendations component
 * @method Recommendations
 *
 * @return {JSX.Element}
 *
 */
const Recommendations = (props) => {
  const recoDetailsRef = useRef();
  const [disabled, setDisabled] = useState(false);
  const [toolbarHiddenDetails, setToolbarHiddenDetails] = useState(true);
  const [isToolbarClickedDetails, setIsToolbarClickedDetails] = useState(false);

  const onChangeModelDetails = (e) => {
    props?.setModelDetails(e);
    props?.validate("modelDetails", e);
  };

  /**
   * Setting the toolbar hidden of the text editor based on the name of the input field
   * @method onBlur
   *
   * @param {string} name - A string for the name of the input field
   * @param {event} e - An event object containing information about the action
   */
  const onBlurDetails = () => {
    if (!isToolbarClickedDetails) {
      setToolbarHiddenDetails(true);
    }
  };

  const handleMouseDownDetails = () => {
    setIsToolbarClickedDetails(true);
  };

  const handleMouseUpDetails = () => {
    setIsToolbarClickedDetails(false);
  };

  const onFocusDetails = () => {
    setToolbarHiddenDetails(false);
  };

  useEffect(() => {
    setToolbarHiddenDetails(true);
  }, []);

  useEffect(() => {
    setDisabled(props?.duplicateCount > 0 ? true : false);
  }, [props?.duplicateCount]);

  useEffect(() => {
    // Add data-cy attribute to the Quill editor root div after it's mounted
    if (recoDetailsRef.current) {
      const editorDiv = recoDetailsRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "reco-details");
    }
  }, []);

  return (
    <Container className="mw-65 ms-0">
      <p className="bold m-0">Caveats and Recommendations</p>
      <p>
        List additional concerns that were not covered in the other sections.
      </p>
      <Form>
        <Form.Group
          className="mb-3"
          onMouseDown={handleMouseDownDetails}
          onMouseUp={handleMouseUpDetails}
          onBlur={onBlurDetails}
          onFocus={onFocusDetails}
        >
          <Form.Label className="m-0">Details</Form.Label>
          <ReactQuill
            ref={recoDetailsRef}
            theme="snow"
            modules={modules}
            formats={formats}
            value={props?.modelDetails}
            onChange={onChangeModelDetails}
            className={`input-description input-field form-control ${
              props?.isValid?.modelDetails
                ? "is-valid"
                : props?.isValid.modelDetails !== undefined
                ? "is-invalid"
                : ""
            } ${
              toolbarHiddenDetails
                ? "ql-editor-expanded toolbar-hidden"
                : "ql-editor-shortened toolbar-display"
            }`}
            id="reco-details"
          />
          {props?.getFormErrorMessage("modelDetails")}
          <span className="sub-note">
            Questions to answer include: Did the model performance evaluation
            results indicate the need for further testing of the model? Are
            there any other groups that were not included in the evaluation
            dataset? Are there additional recommendations for the use of the
            model? What are ideal characteristics of an evaluation dataset for
            this model?
          </span>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default Recommendations;
