import { useEffect, useState, useRef } from "react";
import {
  Container,
  Form,
  OverlayTrigger,
  Tooltip,
  Table,
  Button,
} from "react-bootstrap";
import { modules, formats, convertBytesToSize } from "common/constants";
import ReactQuill from "react-quill";
import "styles/Overview.css";

/**
 * A module for Overview component
 * @module components/models/sections/Overview
 */

/**
 * Overview component
 * @method Overview
 *
 * @return {JSX.Element}
 *
 */
const Overview = (props) => {
  const versionDescRef = useRef();
  const [toolbarHidden, setToolbarHidden] = useState(true);
  const [isToolbarClicked, setIsToolbarClicked] = useState(false);
  const [counter, setCounter] = useState(0);

  const onChangeModelNumber = (e) => {
    const value = e.target.value;
    props?.setModelNumber(value);
    props?.validate("modelNumber", value);
  };

  const onChangeModelVersionName = (e) => {
    const value = e.target.value;
    props?.setModelVersionName(value);
    props?.validate("modelVersionName", value);
  };

  const onChangeModelVersionDescription = (e) => {
    props?.setModelVersionDescription(e);
    props?.validate("modelVersionDescription", e);
  };

  const onChangeModelAuthor = (e) => {
    const value = e.target.value;
    props?.setModelAuthor(value);
    props?.validate("modelAuthor", value);
  };

  const onChangeModelContact = (e) => {
    const value = e.target.value;
    props?.setModelContact(value);
    props?.validate("modelContact", value);
  };
  /**
   * Setting the toolbar hidden of the text editor based on the name of the input field
   * @method onBlur
   *
   * @param {string} name - A string for the name of the input field
   * @param {event} e - An event object containing information about the action
   */
  const onBlur = () => {
    if (!isToolbarClicked) {
      setToolbarHidden(true);
    }
  };

  const handleMouseDown = () => {
    setIsToolbarClicked(true);
  };

  const handleMouseUp = () => {
    setIsToolbarClicked(false);
  };

  const onFocus = () => {
    setToolbarHidden(false);
  };

  /***********************************/
  /***********************************/
  /***********************************/

  // const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    Object.values(event.target.files).forEach((file) => {
      props?.setSelectedFile((prevElements) => [...prevElements, file]);
      props?.validate("modelFile", file.name);
    });
    setCounter(counter + 1);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    Object.values(event.dataTransfer.files).forEach((file) => {
      props?.setSelectedFile((prevElements) => [...prevElements, file]);
      props?.validate("modelFile", file.name);
    });
    setCounter(counter + 1);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    setToolbarHidden(true);
  }, []);

  const [isInputFocusedModelNumber, setIsInputFocusedModelNumber] =
    useState(false);
  const handleInputFocusModelNumber = () => {
    setIsInputFocusedModelNumber(true);
  };

  const handleInputBlurModelNumber = () => {
    setIsInputFocusedModelNumber(false);
  };

  const onClickDelete = (fileIndex) => {
    let files = props?.selectedFile;
    files.splice(fileIndex, 1);
    props?.setSelectedFile(files);
    setCounter(counter + 1);
    if (props?.selectedFile.length <= 0) {
      fileInputRef.current.value = "";
      props?.validate("modelFile", "");
    }
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const onChangeSelectAllFileName = (e) => {
    if (e.target.checked) {
      Object.values(props?.selectedFile).forEach((file, index) => {
        setSelectedCheckboxes((prevElements) => [...prevElements, index]);
      });
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const onClickDeleteSelectedBtn = () => {
    let files = props?.selectedFile;
    let outputArray = files.filter(
      (item, index) => !selectedCheckboxes.includes(index)
    );
    props?.setSelectedFile(outputArray);
    if (outputArray.length <= 0) {
      fileInputRef.current.value = "";
      props?.validate("modelFile", "");
    }

    setSelectedCheckboxes([]);
  };

  const onChangeCheckbox = (e) => {
    if (e.target.checked) {
      console.log("checked");
      setSelectedCheckboxes((prevElements) => [
        ...prevElements,
        parseInt(e.target.value),
      ]);
    } else {
      let outputArray = selectedCheckboxes.filter(
        (item) => parseInt(e.target.value) !== item
      );
      setSelectedCheckboxes(outputArray);
    }
    setCounter(counter + 1);
  };

  useEffect(() => {
    // Add data-cy attribute to the Quill editor root div after it's mounted
    if (versionDescRef.current) {
      const editorDiv = versionDescRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "version-desc");
    }
  }, []);

  return (
    <Container className="mw-65 ms-0">
      <p className="bold m-0">Overview</p>
      <p>Describe this version in general and include your model file.</p>
      <Form>
        <Form.Group
          className="mb-3"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          <Form.Label className="required-field m-0">Description</Form.Label>
          <ReactQuill
            ref={versionDescRef}
            theme="snow"
            modules={modules}
            formats={formats}
            value={props?.modelVersionDescription}
            onChange={onChangeModelVersionDescription}
            className={`input-description input-field form-control ${
              props?.isValid?.modelVersionDescription
                ? "is-valid"
                : props?.isValid.modelVersionDescription !== undefined
                ? "is-invalid"
                : ""
            } ${
              toolbarHidden
                ? "ql-editor-expanded toolbar-hidden"
                : "ql-editor-shortened toolbar-display"
            }`}
            id="version-desc"
          />
          {props?.getFormErrorMessage("modelVersionDescription")}
          <span className="sub-note">
            Tell us about the general idea of this version or how this version
            of the model differ from its earlier version/s.
          </span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="required-field semi-bold m-0">
            Choose model file to be uploaded
          </Form.Label>
          <input type="hidden" value={counter} readOnly />
          <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleClick}
            className={`file-drop-area form-control ${
              props?.isValid?.modelFile
                ? "is-valid"
                : props?.isValid.modelFile !== undefined
                ? "is-invalid"
                : ""
            }`}
            id="version-attachment"
            data-cy="version-attachment"
          >
            <p>Drag and drop files, or Browse</p>
            <input
              ref={fileInputRef}
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept="
              .pkl, 
              .pickle,
              .h5,
              .pb,
              .mlmodel,
              .pt,
              .onnx,
              .pmml,
              .pth,
              .ckpt,
              .safetensors,
              .parquet,
              .rc,
              .petastorm,
              .nc,
              .hdf5,
              .csv,
              .npy,
              .petastorm,
              .py"
              multiple
            />
            <span className="sub-note">
              Supports various file formats.
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="add-version-supported-files">
                    <ul>
                      <li>Pickled Files (.pkl, .pickle)</li>
                      <li>Keras / HDF5 (.h5)</li>
                      <li>Tensorflow protocol buffer (.pb)</li>
                      <li>Apple (.mlmodel)</li>
                      <li>Torch (.pt)</li>
                      <li>MLleap</li>
                      <li>ONNX (.onnx)</li>
                      <li>SaveModel format</li>
                      <li>PMML (.pmml)</li>
                      <li>PyTorch (.pth)</li>
                      <li>Tensorflow (.ckpt)</li>
                      <li>Stable Diffusion / Hugging Face(.safetensors)</li>
                      <li>Parquet (.parquet)</li>
                      <li>Orc (.rc)</li>
                      <li>Avro (.avro)</li>
                      <li>Petastorm (.petastorm)</li>
                      <li>NetCDF (.nc)</li>
                      <li>HDF5 (.h5 or .hdf5)</li>
                      <li>Excel (.csv)</li>
                      <li>Numpy (.npy)</li>
                      <li>Python (.py)</li>
                    </ul>
                  </Tooltip>
                }
                trigger={["hover", "focus", "click"]}
              >
                <img
                  src="/images/question-gray.svg"
                  className="ms-1 img-fluid"
                  alt="question"
                />
              </OverlayTrigger>
            </span>
          </div>
          <div className="vertical-line"></div>
          <Table bordered hover className="file-name-table">
            <thead>
              <tr>
                <th colSpan={2}>
                  <input
                    type="checkbox"
                    className="me-3"
                    value="all"
                    onChange={onChangeSelectAllFileName}
                    name="select-all-files"
                    id="select-all-files"
                    data-cy="select-all-files"
                  />
                  File name
                  {props?.selectedFile && (
                    <Button
                      variant="primary"
                      onClick={onClickDeleteSelectedBtn}
                      // disabled={disabled}
                      className="button"
                      style={{ float: "right" }}
                      name="delete-selected"
                      id="delete-selected"
                      data-cy="delete-selected"
                    >
                      Delete Selected
                    </Button>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {props?.selectedFile ? (
                Object.values(props?.selectedFile).map((file, index) => {
                  return (
                    <tr key={file.name + index}>
                      <td className="d-flex justify-content-between">
                        <span>
                          <input
                            type="checkbox"
                            className="me-3"
                            onChange={onChangeCheckbox}
                            value={index}
                            checked={
                              selectedCheckboxes.find(
                                (item) => item === index
                              ) >= 0
                                ? true
                                : false
                            }
                            id={`select-file-${index}`}
                            data-cy="select-file"
                          />
                          {file.name}
                        </span>
                        <span>{convertBytesToSize(file.size)}</span>
                      </td>
                      <td
                        onClick={() => onClickDelete(index)}
                        className="delete-icon-row"
                        id="delete-file"
                        data-cy="delete-file"
                      >
                        <img
                          src="/images/delete-file-name-icon.svg"
                          alt="Delete"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr></tr>
              )}
            </tbody>
          </Table>
          {props?.getFormErrorMessage("modelFile")}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="required-field semi-bold m-0">
            Contact Information
          </Form.Label>
          <div className="email-container">
            <img src="/images/email.svg" className="mail" alt="email" />
            <Form.Control
              type="text"
              value={props?.modelContact}
              onChange={onChangeModelContact}
              className={`email ${
                props?.isValid?.modelContact
                  ? "is-valid"
                  : props?.isValid.modelContact !== undefined
                  ? "is-invalid"
                  : ""
              }`}
              disabled
            />
            {props?.getFormErrorMessage("modelContact")}
          </div>
          <span className="sub-note">
            Users will use this as a mean to contact you.
          </span>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default Overview;
