import React, { useState, useMemo, useEffect, useRef } from "react";
import { Breadcrumb, Button, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "components/layout/CustomPagination";
import FilterDropdown from "components/layout/FilterDropdown";
import { getAllGroups, getAllUsers } from "actions/group";
import { compareValues, convertDate } from "common/constants";
import { useMediaQuery } from "react-responsive";
import MobileLeftSidebar from "components/layout/MobileLeftSidebar";
import "styles/GroupList.css";
import "styles/mobile/GroupListMobile.css";

/**
 * A module for Listing Groups component
 * @module components/groups/GroupList
 */

/**
 * Listing Groups component
 * @method GroupList
 *
 * @return {JSX.Element}
 *
 */

const GroupList = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const scrollRef = useRef(null);

  /**
   * -------------------
   * * Redux store state
   * -------------------
   */
  const { users, groups, currentGroup } = useSelector((state) => state.group);
  const { user } = useSelector((state) => state.auth);

  const [sortDirection, setSortDirection] = useState("desc");
  const [sortDirectionName, setSortDirectionName] = useState("asc");
  const [sortDirectionDescription, setSortDirectionDescription] =
    useState("asc");
  const [sortDirectionMembers, setSortDirectionMembers] = useState("asc");
  const [sortColumn, setSortColumn] = useState("Date created");
  const [expandedName, setExpandedName] = useState(false);
  const [expandedDescription, setExpandedDescription] = useState(false);
  const [expandedMembers, setExpandedMembers] = useState(false);
  const [reloadCounter, setReloadCounter] = useState(0);
  const [displayMobileSearchList, setDisplayMobileSearchList] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggleExpand = (column) => {
    switch (column) {
      case "name":
        setSortDirectionName(sortDirectionName === "asc" ? "desc" : "asc");
        setSortColumn("Name");
        break;
      case "description":
        setSortDirectionDescription(
          sortDirectionDescription === "asc" ? "desc" : "asc"
        );
        setSortColumn("Description");
        break;
      case "members":
        setSortDirectionMembers(
          sortDirectionMembers === "asc" ? "desc" : "asc"
        );
        setSortColumn("Members");
        break;
      //
    }
  };

  /**
   * Columns template and configuration
   */
  const columns = [
    {
      name: (
        <div
          onClick={() => handleToggleExpand("name")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Name</span>
          {sortColumn === "Name" && (
            <img
              src={
                sortDirectionName === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      selector: (row) => {
        let group_name = "";
        const icon = (
          <img
            src="/images/groups_list.svg"
            className="img-fluid icon-list"
            alt="group"
          />
        );

        group_name = (
          <div onClick={() => handleRowClick(row)}>
            <span key={`groupNameKey${row.id}`}>
              {icon} {row.group_name}
            </span>
          </div>
        );

        return <div className="row-profile-group-name">{group_name}</div>;
      },
      width: "40%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("description")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Description</span>
          {sortColumn === "Description" && (
            <img
              src={
                sortDirectionDescription === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      selector: (row) => {
        if (isMobile) {
          return (
            <div
              className="row-profile-group-description"
              onClick={() => handleRowClick(row)}
            >
              {row.group_members_name}
            </div>
          );
        } else {
          return (
            <div
              className="row-profile-group-description"
              onClick={() => handleRowClick(row)}
            >
              {row.group_description_content}
            </div>
          );
        }
      },
      width: "30%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("members")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Members</span>
          {sortColumn === "Members" && (
            <img
              src={
                sortDirectionMembers === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      selector: (row) => {
        if (isMobile) {
          return (
            <div className="row-profile-group-members">
              {row.group_created_at_formatted}
            </div>
          );
        } else {
          return (
            <div
              className="row-profile-group-members"
              onClick={() => handleRowClick(row)}
            >
              {row.group_members_name}
            </div>
          );
        }
      },
      width: "30%",
    },
  ];

  /**
   * -------------------------
   * * Component state
   *  ------------------------
   */
  const [filterText, setFilterText] = useState("");

  const paginationComponentOptions = {
    selectAllRowsItem: true,
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [clearFilter, setClearFilter] = useState(false);

  const handleClearFilterBtnClick = () => {
    setSelectedOptions([]);
    setClearFilter(true);
  };

  useEffect(() => {
    // handleItemsPerPageChange(itemsPerPage);
    setFilteredItems(
      Object.values(groups)
        .map((item) => {
          const group_owner_name =
            user.email === item.group_owner
              ? "me"
              : Object.values(users)
                  .find((user) => user.email === item.group_owner)
                  ?.first_name.concat(
                    " ",
                    Object.values(users).find(
                      (user) => user.email === item.group_owner
                    )?.last_name
                  );

          const dateString = item.group_created_at;
          const date = new Date(dateString);
          const formattedDate = isMobile
            ? convertDate(date, "MM DD, YYYY")
            : convertDate(date, "MM/DD/YYYY");

          const result = Object.values(item.group_members).map((member) => {
            return Object.values(users).filter(
              (user) => user.email === member.email
            )[0];
          });
          let member_name = "";
          const icon = (
            <img
              src="/images/user_list.svg"
              className="img-fluid icon-list"
              alt="user"
            />
          );
          if (user.email === result[0]?.email) {
            member_name = (
              <span key={`memberKey${result[0]?.id}`}>
                {icon}me
                {item.group_members.length - 1 !== 0 && (
                  <span className="plus-members-count">
                    +{item.group_members.length - 1}
                  </span>
                )}
              </span>
            );
          } else {
            if (result[0]) {
              member_name = (
                <span key={`memberKey${result[0]?.id}`}>
                  {icon}
                  {result[0]?.first_name + " " + result[0]?.last_name}
                  {item.group_members.length - 1 !== 0 && (
                    <span className="plus-members-count">
                      +{item.group_members.length - 1}
                    </span>
                  )}
                </span>
              );
            } else {
              member_name = "--";
            }
          }

          const parser = new DOMParser();
          const parsedHTML = parser.parseFromString(
            item.group_description,
            "text/html"
          );
          const textContent = parsedHTML.body.textContent
            .substring(0, 40)
            .trim();

          return {
            ...item,
            group_owner_name: group_owner_name,
            group_created_at_formatted: formattedDate,
            group_members_name: member_name,
            group_description_content: textContent,
          };
        })
        .filter(
          (item) =>
            (item.group_name.toLowerCase().includes(filterText.toLowerCase()) ||
              item.group_description
                .toLowerCase()
                .includes(filterText.toLowerCase()) ||
              Object.values(users)
                .find((user) => user.email === item.group_members[0].email)
                ?.first_name.concat(
                  " ",
                  Object.values(users).find(
                    (user) => user.email === item.group_members[0].email
                  )?.last_name
                )
                .toLowerCase()
                .includes(filterText.toLowerCase())) &&
            (selectedOptions.length > 0
              ? selectedOptions.includes(item.group_name)
              : true)
        )
        .sort((a, b) => {
          if (filterText) {
            return compareValues(a.group_name, b.group_name, "asc");
          }
          const parser = new DOMParser();
          const parsedHTMLA = parser.parseFromString(
            a.group_description,
            "text/html"
          );
          const parsedHTMLB = parser.parseFromString(
            b.group_description,
            "text/html"
          );
          const textContentA = parsedHTMLA.body.textContent.trim();
          const textContentB = parsedHTMLB.body.textContent.trim();
          switch (sortColumn) {
            case "Name":
              return compareValues(
                a.group_name,
                b.group_name,
                sortDirectionName
              );
            case "Description":
              return compareValues(
                textContentA,
                textContentB,
                sortDirectionDescription
              );
            case "Members":
              return compareValues(
                a.group_owner_name,
                b.group_owner_name,
                sortDirectionMembers
              );
            default:
              return compareValues(
                a.group_created_at,
                b.group_created_at,
                sortDirection
              );
          }
        })
    );
  }, [
    groups,
    filterText,
    selectedOptions,
    sortDirectionName,
    sortDirectionDescription,
    sortDirectionMembers,
  ]);

  useEffect(() => {
    if (reloadCounter > 0) {
      props.setGroupCurrentPage(1);
    }
    setReloadCounter(reloadCounter + 1);
  }, [filterText, selectedOptions, sortDirection, sortColumn]);

  const subHeaderComponentMemo = useMemo(() => {
    const filteredGroups = Object.values(groups).filter((group) => {
      const group_members = group.group_members;
      return group_members?.some((member) => member.email === user.email);
    });

    return (
      <div className="list-header">
        <div className="search-input-container">
          <input
            type="text"
            placeholder="Search groups"
            onChange={(e) => setFilterText(e.target.value)}
            className="search-input"
            name="search-group"
            id="search-group"
            data-cy="search-group"
          />
          <img
            src="/images/search-icon-gray.svg"
            className="search-icon img-fluid icon-list"
            alt="search"
          />
        </div>
        <FilterDropdown
          setSelectedOptions={setSelectedOptions}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          filterTitle="My groups"
          options={filteredGroups}
          name="groups"
          isMobile={isMobile}
          dropdownToggleID="filter-my-groups"
        />
        {selectedOptions.length > 0 && (
          <Button
            className="ms-3 button"
            onClick={() => handleClearFilterBtnClick()}
            name="clear-filter"
            id="clear-filter"
            data-cy="clear-filter"
          >
            Clear filter
          </Button>
        )}
      </div>
    );
  }, [isMobile, groups, user, clearFilter, selectedOptions.length]);

  useEffect(() => {
    props.setPreload(true);
    dispatch(getAllGroups())
      .catch((status) => {
        props.setShowToast(true);
        props.setToastStatus(status);
        props.setToastImage(null);
      })
      .finally(() => props.setPreload(false));
  }, [currentGroup]);

  useEffect(() => {
    dispatch(getAllUsers()).catch((err) => console.log(err));
  }, []);

  // const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [data, setData] = useState([]); // your data array
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filteredItems, setFilteredItems] = useState([]);

  const handlePageChange = (pageNumber) => {
    props.setGroupCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (selectedItemsPerPage) => {
    props.setGroupCurrentPage(1);
    setItemsPerPage(selectedItemsPerPage);
  };

  useEffect(() => {
    // Simulating data fetching
    // Replace this with your actual data fetching logic
    const fetchedData = filteredItems; // Replace this with your fetched data
    setData(fetchedData);
    setTotalItems(fetchedData.length);
  }, [filteredItems, sortDirection]);

  useEffect(() => {
    let slicedData = data;
    if (itemsPerPage !== totalItems) {
      const start = (props.groupCurrentPage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      slicedData = data.slice(start, end);
    }
    setPaginatedData(slicedData);
  }, [props.groupCurrentPage, itemsPerPage, data, totalItems]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        if (itemsPerPage <= totalItems && isMobile) {
          if (scrollTop + clientHeight >= scrollHeight) {
            setLoading(true);
            // Simulate a network request
            setTimeout(() => {
              setLoading(false);
              // Load more content or perform any other actions
              setItemsPerPage(itemsPerPage + 25);
            }, 2000);
          }
        }
      }
    };

    const scrollDiv = scrollRef.current;
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [itemsPerPage, totalItems, isMobile]);

  const handleRowClick = (row) => {
    // Handle row click event and navigate to a different page
    navigate("/groups/profile/" + row.id);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.id === currentGroup[0]?.id,
      style: {
        backgroundColor: "#CFCEE3",
      },
    },
  ];

  // useEffect(() => {
  //   // Get the screen height
  //   console.log(window.innerHeight)
  //   const screenHeight = window.innerHeight;

  //   // Set the height of your element to match the screen height
  //   const yourElement = document.querySelector('.rdt_TableBody');
  //   console.log(yourElement)
  //   if (yourElement) {
  //     yourElement.style.height = `${screenHeight - 290}px`;
  //   }
  // }, [window, document.querySelector('.rdt_TableBody')]);

  const onClickMobileSearchIcon = () => {
    setDisplayMobileSearchList(true);
  };

  const onClickMobileBackIcon = () => {
    setDisplayMobileSearchList(false);
  };

  useEffect(() => {
    if (scrollRef.current) {
      // Target the rdt_Table div and add custom attributes
      const rdtTableDiv = scrollRef.current.querySelector(".rdt_Table");
      if (rdtTableDiv) {
        rdtTableDiv.setAttribute("id", "groups-table");
        rdtTableDiv.setAttribute("data-cy", "groups-table");
      }
      // Target the rdt_TableRow divs and add custom attributes
      const rdtTableRows = scrollRef.current.querySelectorAll(".rdt_TableRow");
      rdtTableRows.forEach((row, index) => {
        row.setAttribute("id", `group-row-item-${paginatedData[index].id}`);
        row.setAttribute("data-cy", "group-row-item");
      });
    }
  }, []);

  return (
    <>
      <div className="mw-100 group-list-container">
        <div className="group-list-mobile-header">
          {!displayMobileSearchList ? (
            <>
              <MobileLeftSidebar
                setUrlToRedirected={props.setUrlToRedirected}
                setShowDiscardChangesModal={props.setShowDiscardChangesModal}
                changesMade={props.changesMade}
              />
              <Breadcrumb>
                <Breadcrumb.Item active>Groups</Breadcrumb.Item>
              </Breadcrumb>
              {isMobile && (
                <>
                  <p className="bold group-list-title">Group Profiles</p>
                  <img
                    src="/images/mobile-search-icon.svg"
                    alt="search"
                    className="mobile-search-list-icon"
                    onClick={onClickMobileSearchIcon}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {isMobile && (
                <div>
                  <img
                    src="/images/mobile-back-icon.svg"
                    alt="back"
                    className="mobile-search-back-icon"
                    onClick={onClickMobileBackIcon}
                  />
                  {subHeaderComponentMemo}
                </div>
              )}
            </>
          )}
        </div>
        <div
          ref={scrollRef}
          className={`data-table-div data-table-scrollable ${
            displayMobileSearchList ? "pt-150-px" : "pt-95-px"
          }`}
        >
          <DataTable
            className="group-list-datatable"
            title={"Group Profiles"}
            conditionalRowStyles={conditionalRowStyles}
            columns={columns}
            data={paginatedData}
            pagination={false}
            subHeader={!isMobile}
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            onRowClicked={handleRowClick}
            noTableHead={isMobile}
          />
          <div className="mb-4 d-flex justify-content-center">
            {loading && (
              <Spinner
                className="spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </div>
          {!isMobile && (
            <CustomPagination
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={props.groupCurrentPage}
              onChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default GroupList;
