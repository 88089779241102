import { archiveDatasetVersion } from "actions/dataset";
import { useEffect, useState } from "react";
import { Modal, Button, Form, Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import "styles/mobile/ArchiveVersionModalMobile.css";

/**
 * A module for the ArchiveModal Component
 * @module components/models/ArchiveModal
 */

/**
 * Archive model version in the system
 * @method ArchiveModal
 *
 * @return {JSX.Element}
 *
 */
const ArchiveModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const [archiveReason, setArchiveReason] = useState("");
  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [disabled, setDisabled] = useState(true);

  /**
   *  Validates name and value of the input field
   * @method validate
   * @param {string} name - A string for name of the input field
   * @param {string} value - A string for the value of the input field
   *
   * @return {void}
   */
  const validate = (name, value) => {
    // For input with spaces only
    if (value) {
      value = value.toString().trim();
    }
    // For required fields
    if (!value) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "Please fill out this field" }));
      return;
    }
    const regexPattern =
      /^[a-zA-Z0-9 Ññ~`!@#\$%\^&*()_\-+={}\[\]|\\:;'"<,>.?\/\s]+$/g;
    if (regexPattern.test(value) === false) {
      setIsValid(Object.assign(isValid, { [name]: false }));

      setError(
        Object.assign(error, {
          [name]:
            "Accepts a-z, A-Z, 0-9, Ñ/ñ, space, and special characters like ~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/",
        })
      );
      return;
    }

    if (value.length < 2 && value.length > 0) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Minimum of 2 characters",
        })
      );
      return;
    }

    if (value.length > 0 && value.length > 100) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Maximum of 100 characters",
        })
      );
      return;
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  useEffect(() => {
    if (archiveReason !== "" && Object.keys(error).length === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [archiveReason]);

  const onClickArchive = (
    datasetProfileID,
    datasetVersionID,
    archiveReason
  ) => {
    dispatch(
      archiveDatasetVersion(datasetProfileID, datasetVersionID, archiveReason)
    )
      .then((status) => {
        props?.setToastStatus(status);
        props?.setToastImage("/images/archive-success.svg");
      })
      .catch((status) => {
        if (status === "notif-in-app-network unreachable") {
          console.log("notif-in-app-network unreachable");
          props.setToastStatus("success");
          props.setToastImage("/images/archive-success.svg");
        } else {
          props.setToastStatus(status);
          props.setToastImage(null);
        }
      })
      .finally(() => {
        props?.setShowToast(true);
        props?.setRefreshDatasetVersionsCount(
          props?.refreshDatasetVersionsCount + 1
        );
      });
    props?.handleArchiveModalClose();
    navigate("/datasets/profile/" + props?.datasetProfileID + "?tab=2");
  };

  const onChangeArchiveReason = (e) => {
    const value = e.target.value;
    setArchiveReason(value);
    validate("archiveReason", value);
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  const resetModalForm = () => {
    setArchiveReason("");
    setDisabled(true);
    setIsValid({});
    setError({});
  };

  useEffect(() => {
    resetModalForm();
  }, [props?.showArchiveModal]);
  return (
    <>
      <Modal
        show={props?.showArchiveModal && !isMobile}
        onHide={props?.handleArchiveModalClose}
        dialogClassName="modal-32w"
        centered
        backdrop="static"
        id="archive-version-confirmation"
        data-cy="archive-version-confirmation"
      >
        <Modal.Body className="p-5">
          <p className="bold text-center">Archive this version?</p>
          <p>
            Once a model version is archived, it can no longer be downloaded or
            modified.
          </p>

          <Form.Group>
            <Form.Label className="required-field">
              Please indicate the reason for archiving this version
            </Form.Label>
            <Form.Control
              value={archiveReason}
              onChange={onChangeArchiveReason}
              className={`input-field ${
                isValid?.archiveReason
                  ? "is-valid"
                  : isValid.archiveReason !== undefined
                  ? "is-invalid"
                  : ""
              }`}
            />
            {getFormErrorMessage("archiveReason")}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props?.handleArchiveModalClose}
            className="light-btn"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              onClickArchive(
                props?.datasetProfileID,
                props?.datasetVersionID,
                archiveReason
              )
            }
            className="submit-btn button"
            disabled={disabled}
            name="archive-version-btn"
            id="archive-version-btn"
            data-cy="archive-version-btn"
          >
            Archive
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props?.showArchiveModal && isMobile}
        onHide={props?.handleArchiveModalClose}
        placement="bottom"
        className="offcanvas-archive-version"
        id="archive-version-confirmation"
        data-cy="archive-version-confirmation"
      >
        <Offcanvas.Body>
          <Form>
            <p className="bold">Archive this version?</p>
            <p>
              Once a dataset version is archived, it can no longer be downloaded
              or modified.
            </p>
            <Form.Group className="mb-2">
              <Form.Label className="required-field">
                Please indicate the reason for archiving this version
              </Form.Label>
              <Form.Control
                value={archiveReason}
                onChange={onChangeArchiveReason}
                className={`input-field ${
                  isValid?.archiveReason
                    ? "is-valid"
                    : isValid.archiveReason !== undefined
                    ? "is-invalid"
                    : ""
                }`}
              />
              {getFormErrorMessage("archiveReason")}
            </Form.Group>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props?.handleArchiveModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                onClickArchive(
                  props?.datasetProfileID,
                  props?.datasetVersionID,
                  archiveReason
                )
              }
              disabled={disabled}
              className="submit-btn button"
              name="archive-version-btn"
              id="archive-version-btn"
              data-cy="archive-version-btn"
            >
              Archive
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ArchiveModal;
