import { Modal, Button, Spinner, Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { updateProfile } from "actions/auth";
import { useMediaQuery } from "react-responsive";

/**
 * A module for the EditAboutModal Component
 * @module components/users/EditAboutModal
 */

/**
 * Displaying  modal for editing account about
 * @method EditAboutModal
 *
 * @return {JSX.Element}
 *
 */
const EditAboutModal = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const [introduction, setIntroduction] = useState("");

  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const optionalFields = ["introduction"];

  const validate = (name, value) => {
    // For input with spaces only
    if (value) {
      value = value.toString().trim();
    }

    if (optionalFields.findIndex((field) => name === field) < 0 && !value) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "Please fill out this field" }));
      return;
    }

    if (value) {
      switch (name) {
        case "introduction":
          setIsValid(Object.assign(isValid, { [name]: false }));
          const regexPattern =
            /^[a-zA-Z0-9 Ññ~`!@#\$%\^&*()_\-+={}\[\]|\\:;'"<,>.?\/\s]+$/g;
          if (regexPattern.test(value) === false) {
            setError(
              Object.assign(error, {
                [name]:
                  "Accepts a-z, A-Z, 0-9, Ñ/ñ, space, and special characters like ~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/",
              })
            );
            return;
          }
          break;
        default:
        // code block
      }
    }

    if (name === "introduction" && value.length > 0 && value.length > 300) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Maximum of 300 characters",
        })
      );
      return;
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  /**
   * Gets value of the introduction input field
   * @method onChangeIntroduction
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeIntroduction = (e) => {
    const introduction = e.target.value;
    setIntroduction(introduction);
    validate("introduction", introduction);
  };

  const handleUpdate = () => {
    validate("introduction", introduction);

    if (Object.keys(error).length === 0) {
      setLoading(true);
      setDisabled(true);
      dispatch(
        updateProfile(
          props?.firstname,
          props?.middlename,
          props?.lastname,
          props?.suffix,
          props?.orcidID,
          introduction,
          // skills,
          props?.orgAcronym,
          props?.organization,
          props?.designation,
          props?.division,
          props?.orgAddressProvince,
          props?.orgAddressMunicipality,
          "About"
        )
      )
        .then((status) => {
          props.setToastStatus(status);
          props.setToastImage("/images/account-update-success.svg");
        })
        .catch((status) => {
          props.setToastStatus(status);
          props.setToastImage(null);
        })
        .finally(() => {
          props.setShowToast(true);
          props.handleEditAboutModalClose();
          props?.setRefreshProfileInfoCount(
            props?.refreshPersonalInfoCount + 1
          );
        });
    }
  };

  useEffect(() => {
    if (Object.keys(error).length === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [Object.keys(error), introduction]);

  const resetModalForm = () => {
    setIntroduction(props?.introduction);
    setLoading(false);
    setDisabled(false);
    setIsValid({});
    setError({});
  };

  useEffect(() => {
    resetModalForm();
  }, [props.showEditAboutModal]);

  return (
    <>
      <Offcanvas
        show={props.showEditAboutModal && isMobile}
        onHide={props.handleEditAboutModalClose}
        placement="bottom"
        className="offcanvas-personal-info"
      >
        <Offcanvas.Body>
          <Form>
            <p className="bold" id="about-modal" data-cy="about-modal">
              Edit about
            </p>
            <div className="personal-info-form">
              <p>
                Feel free to share insights into your years of expertise,
                industry background, or skill set.
              </p>

              <Form.Group>
                <Form.Label>Tell us something about yourself.</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={8}
                  value={introduction}
                  onChange={onChangeIntroduction}
                  className={`input-field ${
                    isValid?.introduction
                      ? "is-valid"
                      : isValid.introduction !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="about"
                  id="about"
                  data-cy="about"
                />
                {getFormErrorMessage("introduction")}
              </Form.Group>
            </div>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleEditAboutModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="submit-btn button"
              disabled={disabled}
              onClick={handleUpdate}
              name="update-btn"
              id="update-btn"
              data-cy="update-btn"
            >
              Update
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={props.showEditAboutModal && !isMobile}
        onHide={props.handleEditAboutModalClose}
        dialogClassName="modal-45w"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title
            className="bold ms-3"
            id="about-modal"
            data-cy="about-modal"
          >
            Edit about
          </Modal.Title>
          <Button
            variant="close"
            onClick={props.handleEditAboutModalClose}
            name="close-btn"
            id="close-btn"
            data-cy="close-btn"
          />
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <Form>
            <p className="ms-5">
              Feel free to share insights into your years of expertise, industry
              background, or skill set.
            </p>
            <hr />
            <div>
              <Form.Group className="mb-3 mx-5">
                <Form.Label>Tell us something about yourself.</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={8}
                  value={introduction}
                  onChange={onChangeIntroduction}
                  className={`input-field ${
                    isValid?.introduction
                      ? "is-valid"
                      : isValid.introduction !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="about"
                  id="about"
                  data-cy="about"
                />
                {getFormErrorMessage("introduction")}
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props.handleEditAboutModalClose}
            className="light-btn"
            name="cancel"
            id="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="submit-btn button"
            disabled={disabled}
            onClick={handleUpdate}
            name="update-btn"
            id="update-btn"
            data-cy="update-btn"
          >
            Update
            {loading && (
              <Spinner
                className="spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditAboutModal;
