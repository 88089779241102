import { Container, Table } from "react-bootstrap";
import { convertBytesToSize } from "common/constants";
import "styles/Overview.css";

/**
 * A module for Overview component
 * @module components/models/sections/Overview
 */

/**
 * Overview component
 * @method Overview
 *
 * @return {JSX.Element}
 *
 */
const Overview = (props) => {
  return (
    <Container className="ms-0">
      <span className="bold">Overview</span>
      <p>Basic information about this version.</p>

      <div className="mb-3">
        <label className="semi-bold">Description</label>
        <p
          dangerouslySetInnerHTML={{
            __html:
              props?.modelVersionDescription === "<p><br></p>" ||
              !props?.modelVersionDescription
                ? "--"
                : props?.modelVersionDescription,
          }}
        ></p>
      </div>
      <div className="mb-3">
        <label className="semi-bold">Attached file</label>
        {props?.modelFile?.length > 0 ? (
          <Table bordered>
            <thead>
              <tr>
                <th colSpan={2}>File name</th>
              </tr>
            </thead>
            <tbody>
              {props?.modelFile?.map((file, index) => {
                return (
                  <tr key={index}>
                    <td>{file.name}</td>
                    <td>{convertBytesToSize(file.size)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p>--</p>
        )}
      </div>
      <div className="mb-3">
        <label className="semi-bold">Contact Information</label>
        <p>{props?.modelContact || "--"}</p>
      </div>
    </Container>
  );
};

export default Overview;
