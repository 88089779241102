import { Modal, Button, Form, Tabs, Tab, Offcanvas } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch, useSelector } from "react-redux";
import { addGroup, getAllUsers, getAllGroups } from "actions/group";
import ReactQuill from "react-quill";
import { modules, formats } from "common/constants";
import { useMediaQuery } from "react-responsive";
import "styles/AddGroupProfileModal.css";
import "styles/mobile/AddGroupProfileModalMobile.css";

/**
 * A module for the AddGroupProfileModal Component
 * @module components/layout/AddGroupProfileModal
 */

/**
 * Displaying menu in the left side of the system
 * @method AddGroupProfileModal
 *
 * @return {JSX.Element}
 *
 */
const AddGroupProfileModal = (props) => {
  const dispatch = useDispatch();
  const descriptionRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const { users } = useSelector((state) => state.group);
  const { user } = useSelector((state) => state.auth);

  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [options, setOptions] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);

  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [toolbarHidden, setToolbarHidden] = useState(true);
  const [isToolbarClicked, setIsToolbarClicked] = useState(false);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    dispatch(getAllUsers()).catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    const usersList = Object.values(users).map((item) => {
      if (user.email === item.email) {
        setSelectedOptions([
          {
            id: item.id,
            name: item.first_name + " " + item.last_name,
            label: item.first_name + " " + item.last_name,
            email: item.email,
            disableRemove: true,
          },
        ]);
      }
      return {
        id: item.id,
        name: item.first_name + " " + item.last_name,
        label: item.first_name + " " + item.last_name,
        email: item.email,
        disableRemove: user.email === item.email ? true : false,
      };
    });
    setOptions(usersList);
  }, [users]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelect = (selected) => {
    // console.log(user.first_name + " " + user.last_name === selected[0])=
    setSelectedOptions(selected);
  };

  // const handleDelete = (deleted) => {
  //   setSelectedOptions(selectedOptions.filter((option) => option !== deleted));
  // };

  // const rowRenderer = ({ key, index, style }) => (
  //   <div key={key} style={style}>
  //     {options[index]}
  //   </div>
  // );

  const onChangeGroupName = (e) => {
    const value = e.target.value;
    setGroupName(value);
    validate("groupName", value);
  };

  const onChangeGroupDescription = (e) => {
    setGroupDescription(e);
    validate("groupDescription", e);
  };

  const onInputChangeGroupMembers = (e) => {
    validate("groupMembers", e);
  };

  const handleFormSubmit = (e) => {
    const emails = selectedOptions.map((option) => {
      const result = Object.values(users).filter(
        (user) => user.email === option.email
      );
      return result[0].email;
    });
    dispatch(addGroup(groupName, groupDescription, emails))
      .then((status) => {
        props.setToastStatus(status);
        props.setToastImage("/images/group-success.svg");
        setDisabled(true);
        dispatch(getAllGroups()).catch((err) => console.log(err));
        if (status !== "error") {
          props.handleGroupModalClose();
        }
      })
      .catch((status) => {
        props.setToastStatus(status);
        props.setToastImage(null);
      })
      .finally(() => {
        setDisabled(false);
        props.setShowToast(true);
      });
  };

  const resetModalForm = () => {
    setGroupName("");
    setGroupDescription("<p><br></p>");
    setDisabled(true);
    setIsValid({});
    setError({});
    setSelectedOptions([selectedOptions[0]]);
    setToolbarHidden(true);
  };

  const renderToken = (option, props, index) => {
    const disableRemove = option.disableRemove;
    return (
      <div className={index === 0 ? "token-disabled" : "token"} key={option.id}>
        <img src="/images/user_form.svg" className="img-fluid" alt="user" />
        <span className="token-label">{option.label}</span>
        {!disableRemove && (
          <button
            type="button"
            className="token-remove"
            onClick={() => handleRemoveOption(option)}
          >
            &times;
          </button>
        )}
      </div>
    );
  };

  const handleRemoveOption = (deleted) => {
    // Implement your logic to handle removing the option
    console.log("Removing option:", deleted);
    setSelectedOptions(
      selectedOptions.filter((option) => option.id !== deleted.id)
    );
  };

  /**
   *  Validates name and value of the input field
   * @method validate
   * @param {string} name - A string for name of the input field
   * @param {string} value - A string for the value of the input field
   *
   * @return {void}
   */
  const validate = (name, value) => {
    if (name === "groupDescription") {
      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(value, "text/html");
      const textContent = parsedHTML.body.textContent.trim();
      if (textContent === "") {
        value = "";
      }
    }
    if (!value) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "Please fill out this field" }));
      return;
    }
    if (name === "groupMembers" && !/^[a-zA-Z0-9 .]+$/.test(value)) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Accepts alphanumeric and period only.",
        })
      );
      return;
    }
    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  /**
   * Setting the toolbar hidden of the text editor based on the name of the input field
   * @method onBlur
   *
   * @param {string} name - A string for the name of the input field
   * @param {event} e - An event object containing information about the action
   */
  const onBlur = () => {
    if (!isToolbarClicked) {
      setToolbarHidden(true);
    }
  };

  const handleMouseDown = () => {
    setIsToolbarClicked(true);
  };

  const handleMouseUp = () => {
    setIsToolbarClicked(false);
  };

  const onFocus = () => {
    setToolbarHidden(false);
  };

  useEffect(() => {
    resetModalForm();
  }, [props.showGroupModal]);

  useEffect(() => {
    if (
      groupName !== "" &&
      groupDescription !== "<p><br></p>" &&
      selectedOptions.length >= 1 &&
      Object.keys(error).length === 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [groupName, groupDescription, selectedOptions]);

  useEffect(() => {
    // Add data-cy attribute to the Quill editor root div after it's mounted
    if (descriptionRef.current) {
      const editorDiv = descriptionRef.current.getEditor().root; // Access the Quill editor DOM node
      editorDiv.setAttribute("data-cy", "description");
    }
  }, []);

  return (
    <>
      <Modal
        show={props.showGroupModal && !isMobile}
        onHide={props.handleGroupModalClose}
        dialogClassName="modal-50w"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="bold">Add a Group Profile</Modal.Title>
          <Button
            variant="close"
            onClick={props.handleGroupModalClose}
            name="close-btn"
            id="close-btn"
            data-cy="close-btn"
          />
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <Form>
            <p className="required-field ms-5">
              Required fields are marked with an asterisk
            </p>
            <Tabs defaultActiveKey="introduction" className="mb-3">
              <Tab
                eventKey="introduction"
                tabClassName="tabs ms-5"
                title="Introduction"
              >
                <Tab1
                  descriptionRef={descriptionRef}
                  groupName={groupName}
                  onChangeGroupName={onChangeGroupName}
                  isValid={isValid}
                  getFormErrorMessage={getFormErrorMessage}
                  handleMouseDown={handleMouseDown}
                  handleMouseUp={handleMouseUp}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  groupDescription={groupDescription}
                  onChangeGroupDescription={onChangeGroupDescription}
                  toolbarHidden={toolbarHidden}
                  options={options}
                  selectedOptions={selectedOptions}
                  handleSelect={handleSelect}
                  onInputChangeGroupMembers={onInputChangeGroupMembers}
                  renderToken={renderToken}
                />
              </Tab>
            </Tabs>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props.handleGroupModalClose}
            className="light-btn"
            name="cancel"
            id="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleFormSubmit}
            disabled={disabled}
            className="submit-btn button"
            name="add-group"
            id="add-group"
            data-cy="add-group"
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={props.showGroupModal && isMobile}
        onHide={() => props.setShowGroupModal(false)}
        placement="bottom"
        className="offcanvas-add-group-profile"
      >
        <Offcanvas.Body className="profile-offcanvas">
          <p className="bold">Add a Group Profile</p>
          <p className="required-field">
            Required fields are marked with an asterisk
          </p>
          <div className="add-group-profile-form">
            <Tab1
              descriptionRef={descriptionRef}
              groupName={groupName}
              onChangeGroupName={onChangeGroupName}
              isValid={isValid}
              getFormErrorMessage={getFormErrorMessage}
              handleMouseDown={handleMouseDown}
              handleMouseUp={handleMouseUp}
              onBlur={onBlur}
              onFocus={onFocus}
              groupDescription={groupDescription}
              onChangeGroupDescription={onChangeGroupDescription}
              toolbarHidden={toolbarHidden}
              options={options}
              selectedOptions={selectedOptions}
              handleSelect={handleSelect}
              onInputChangeGroupMembers={onInputChangeGroupMembers}
              renderToken={renderToken}
            />
          </div>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleGroupModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleFormSubmit}
              disabled={disabled}
              className="submit-btn button"
              name="add-group"
              id="add-group"
              data-cy="add-group"
            >
              Add
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const Tab1 = (props) => {
  return (
    <div className="group-tab-div">
      <Form.Group className="mb-3">
        <Form.Label className="required-field">Group name</Form.Label>
        <Form.Control
          type="text"
          value={props.groupName}
          onChange={props.onChangeGroupName}
          className={`form-control ${
            props.isValid?.groupName
              ? "is-valid"
              : props.isValid.groupName !== undefined
              ? "is-invalid"
              : ""
          }`}
          name="group-name"
          id="group-name"
          data-cy="group-name"
        />
        {props.getFormErrorMessage("groupName")}
        <span className="sub-note">Give a name to your group profile.</span>
      </Form.Group>
      <Form.Group
        className="mb-3"
        onMouseDown={props.handleMouseDown}
        onMouseUp={props.handleMouseUp}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      >
        <Form.Label className="required-field">Description</Form.Label>
        <ReactQuill
          ref={props.descriptionRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.groupDescription}
          onChange={props.onChangeGroupDescription}
          className={`input-description form-control ${
            props.isValid?.groupDescription
              ? "is-valid"
              : props.isValid.groupDescription !== undefined
              ? "is-invalid"
              : ""
          } ${
            props.toolbarHidden
              ? "ql-editor-expanded toolbar-hidden"
              : "ql-editor-shortened toolbar-display"
          }`}
          id="description"
        />
        {props.getFormErrorMessage("groupDescription")}
        <span className="sub-note">
          Let people know what your group is about.
        </span>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Members</Form.Label>
        <Typeahead
          labelKey="name"
          multiple
          options={props.options}
          placeholder="Choose options"
          selected={props.selectedOptions}
          onChange={props.handleSelect}
          onInputChange={props.onInputChangeGroupMembers}
          renderToken={props.renderToken}
          className={`input-field typeahead-input form-control ${
            props.isValid?.groupMembers
              ? "is-valid"
              : props.isValid.groupMembers !== undefined
              ? "is-invalid"
              : ""
          }`}
          id="group-members"
          inputProps={{
            "data-cy": "group-members", // Add data-cy attribute to input element
          }}
        />
        {props.getFormErrorMessage("groupMembers")}
        <span className="sub-note">
          Add members to your group or just you. You can always add more members
          later.
        </span>
      </Form.Group>
    </div>
  );
};

export default AddGroupProfileModal;
