import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { register, resendActivation } from "actions/auth";
import { clearMessage } from "actions/message";
import {
  Spinner,
  Button,
  Form,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import provincesJson from "data/provinces.json";
import cityMunicipalityJson from "data/city-mun.json";
import barangaysJson from "data/barangays.json";
import zipcodesJson from "data/zipcodes.json";
import Home from "components/Home";
import "styles/SignUp.css";
import "styles/mobile/SignUpMobile.css";

/**
 * A module for the SignUp Component
 * @module components/users/SignUp
 */

/**
 * SignUp2 Component
 * @method SignUp
 * @return {JSX.Element}
 *
 */
const SignUp = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * --------------------
   * * Component state
   *  -------------------
   */
  const [firstname, setFirstName] = useState("");
  const [middlename, setMiddleName] = useState("");
  const [lastname, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [orcidID, setOrcidID] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [orgAcronym, setOrgAcronym] = useState("");
  const [division, setDivision] = useState("");
  const [orgAddressMunicipality, setOrgAddressMunicipality] = useState("");
  const [orgAddressProvince, setOrgAddressProvince] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabledNext1, setDisabledNext1] = useState(true);
  const [disabledNext2, setDisabledNext2] = useState(true);
  const [disabledCreateAccount, setDisabledCreateAccount] = useState(true);
  const [disabledCircle1, setDisabledCircle1] = useState(true);
  const [disabledCircle2, setDisabledCircle2] = useState(true);
  const [disabledCircle3, setDisabledCircle3] = useState(true);
  const [show, setShow] = useState(false);
  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const stepInputFields = [
    "firstname",
    "middlename",
    "lastname",
    "suffix",
    "orcidID",
    "orgAcronym",
    "organization",
    "designation",
    "division",
    "orgAddressProvince",
    "orgAddressMunicipality",
    "email",
    "password",
  ];

  const optionalFields = ["middlename", "suffix", "orcidID"];

  /**
   * Validates name and value of the input field
   * @method validate
   *
   * @param {string} name - A string for name of the input field
   * @param {string} value - A string for the value of the input field
   *
   * @return {void}
   */
  const validate = (name, value) => {
    if (optionalFields.findIndex((field) => name === field) < 0 && !value) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "Please fill out this field" }));
      return;
    }

    // For input with spaces only
    if (
      optionalFields.findIndex((field) => name === field) < 0 &&
      value.toString().trim() === ""
    ) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "Please fill out this field" }));
      return;
    }

    if (value) {
      switch (name) {
        case "firstname":
        case "middlename":
        case "orgAcronym":
          setIsValid(Object.assign(isValid, { [name]: false }));
          // const validateNames = new RegExp("/^[ña-z .'-]+$/i");
          if (!/^[ña-z .-]+$/i.test(value)) {
            setError(
              Object.assign(error, {
                [name]: "Accepts a-z, A-Z, Ñ/ñ, space, -, and period only",
              })
            );
            return;
          }
          break;
        case "lastname":
        case "organization":
        case "designation":
        case "division":
          setIsValid(Object.assign(isValid, { [name]: false }));
          // const validateNames = new RegExp("/^[ña-z .'-]+$/i");
          if (!/^[ña-z -]+$/i.test(value)) {
            setError(
              Object.assign(error, {
                [name]: "Accepts a-z, A-Z, Ñ/ñ, space, and - only",
              })
            );
            return;
          }
          break;
        case "suffix":
          setIsValid(Object.assign(isValid, { [name]: false }));
          if (!/^[ña-z .]+$/i.test(value)) {
            setError(
              Object.assign(error, {
                [name]: "Accepts a-z, A-Z, Ñ/ñ, and period only",
              })
            );
            return;
          }
          break;
        case "orcidID":
          setIsValid(Object.assign(isValid, { [name]: false }));
          // const validateNames = new RegExp("/^[ña-z .'-]+$/i");
          if (
            !/^[0-9]{0,4}(-[0-9]{0,4}(-[0-9]{0,4}(-[0-9]{0,4}(-)?)?)?)?$/.test(
              value
            )
          ) {
            setError(
              Object.assign(error, {
                [name]: "Accepts numeric (0-9) numbers only",
              })
            );
            return;
          }
          if (value.length < 19) {
            setError(
              Object.assign(error, {
                [name]: "Accepts only 16 digits",
              })
            );
            return;
          }
          break;
        case "email":
          setIsValid(Object.assign(isValid, { [name]: false }));
          if (!/^(?=.{1,}@)/.test(value)) {
            setError(
              Object.assign(error, {
                [name]: "Minimum of 1 character before the @ symbol",
              })
            );
            return;
          }
          if (!/^(?=.{1,64}@)/.test(value)) {
            setError(
              Object.assign(error, {
                [name]: "Maximum of 64 characters before the @ symbol",
              })
            );
            return;
          }
          if (!/^[a-zA-Z0-9Ññ@._]+$/i.test(value)) {
            setError(
              Object.assign(error, {
                [name]:
                  "Accepts a-z, A-Z, 0-9, Ñ/ñ, @, period, underscore only.",
              })
            );
            return;
          }

          if (
            !/[\wÑñ]+([.-]?[\wÑñ]+)*@[\wÑñ]+([.-]?[\wÑñ]+)*(\.\w{2,3})+$/.test(
              value
            )
          ) {
            setError(
              Object.assign(error, { [name]: "This is not a valid email" })
            );
            return;
          }
          break;
        case "password":
          setIsValid(Object.assign(isValid, { [name]: false }));

          const regexPattern =
            /^[a-zA-Z0-9 Ññ~`!@#$%^&*()_\-+={[}\]|\\:;'"<,>.?/]+$/;
          if (regexPattern.test(value) === false) {
            setError(
              Object.assign(error, {
                [name]:
                  "Accepts a-z, A-Z, 0-9, Ñ/ñ, space, and special characters like ~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/",
              })
            );
            return;
          }

          const validatePassword =
            /^(?=.*[ Ññ~`!@#$%^&*()_\-+={[}\]|\\:;'"<,>.?/])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9 Ññ~`!@#$%^&*()_\-+={[}\]|\\:;'"<,>.?/]{8,}$/; // Minimum of 8 characters with atleast 1 symbol, 1 uppercase letter and 1 number
          const validatePassword2 =
            /^(?=.*[ Ññ~`!@#$%^&*()_\-+={[}\]|\\:;'"<,>.?/])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9 Ññ~`!@#$%^&*()_\-+={[}\]|\\:;'"<,>.?/]{8,64}$/;
          if (validatePassword.test(value) === false) {
            setError(
              Object.assign(error, {
                [name]:
                  "Minimum of 8 characters with atleast 1 symbol, 1 uppercase letter and 1 number",
              })
            );
            return;
          }

          if (validatePassword2.test(value) === false) {
            setError(
              Object.assign(error, {
                [name]:
                  "Maximum of 64 characters with atleast 1 symbol, 1 uppercase letter and 1 number",
              })
            );
            return;
          }
          break;
        case "confirmPassword":
          setIsValid(Object.assign(isValid, { [name]: false }));
          if (value !== password) {
            setError(
              Object.assign(error, {
                [name]: "The confirm password does not match the password.",
              })
            );
            return;
          }
          break;
        default:
        // code block
      }
    }

    if (
      name !== "orcidID" &&
      name !== "password" &&
      name !== "confirmPassword" &&
      value.length < 2 &&
      value.length > 0
    ) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Minimum of 2 characters",
        })
      );
      return;
    }

    // if (
    //   name !== "password" &&
    //   name !== "confirmPassword" &&
    //   value.length > 0 &&
    //   value.length > 50
    // ) {
    //   setIsValid(Object.assign(isValid, { [name]: false }));
    //   setError(
    //     Object.assign(error, {
    //       [name]: "Maximum of 50 characters",
    //     })
    //   );
    //   return;
    // }

    if (name === "organization" && value.length > 0 && value.length > 500) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Maximum of 500 characters",
        })
      );
      return;
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   *
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  /**
   * Change hidden state of password
   * @method onClickPasswordBtn
   */
  const onClickPasswordBtn = () => {
    setPasswordShow(passwordShow ? false : true);
  };

  /**
   * Gets value of the first name input field
   * @method onChangeFirstName
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeFirstName = (e) => {
    const firstname = e.target.value;
    setFirstName(firstname);
    validate("firstname", firstname);
  };

  /**
   * Gets value of the middle name input field
   * @method onChangeMiddleName
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeMiddleName = (e) => {
    const middlename = e.target.value;
    setMiddleName(middlename);
    validate("middlename", middlename);
  };

  /**
   * Gets value of the last name input field
   * @method onChangeLastName
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeLastName = (e) => {
    const lastname = e.target.value;
    setLastName(lastname);
    validate("lastname", lastname);
  };

  /**
   * Gets value of the suffix name input field
   * @method onChangeSuffix
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeSuffix = (e) => {
    const suffix = e.target.value;
    setSuffix(suffix);
    validate("suffix", suffix);
  };

  /**
   * Gets value of the ORCID ID input field
   * @method onChangeOrcidID
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeOrcidID = (e) => {
    const orcidID = e.target.value;

    // Create a regular expression pattern to match only digits and hyphen
    var digitPattern = /^[\d-]+$/;

    // Use the test method to check if the input matches the pattern
    if (digitPattern.test(orcidID)) {
      const cleanedValue = orcidID.replace(/[^0-9]/g, "").slice(0, 16);
      let formattedValue = "";

      for (let i = 0; i < cleanedValue.length; i++) {
        if (i > 0 && i % 4 === 0) {
          formattedValue += "-";
        }
        formattedValue += cleanedValue[i];
      }

      setOrcidID(formattedValue);
      validate("orcidID", formattedValue);
    } else {
      setOrcidID(orcidID);
      validate("orcidID", orcidID);
    }
  };

  /**
   * Gets value of the destination input field
   * @method onChangeDesignation
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeDesignation = (e) => {
    const designation = e.target.value;
    setDesignation(designation);
    validate("designation", designation);
  };

  /**
   * Gets value of the email address input field
   * @method onChangeEmail
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    validate("email", email);
  };

  /**
   * Gets value of the acronym organization input field
   * @method onChangeOrgAcronym
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeOrgAcronym = (e) => {
    const orgAcronym = e.target.value;
    setOrgAcronym(orgAcronym);
    validate("orgAcronym", orgAcronym);
  };

  /**
   * Gets value of the organization input field
   * @method onChangeOrganization
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeOrganization = (e) => {
    const organization = e.target.value;
    setOrganization(organization);
    validate("organization", organization);
  };

  /**
   * Gets value of the division, section, or unit name input field
   * @method onChangeDivision
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeDivision = (e) => {
    const division = e.target.value;
    setDivision(division);
    validate("division", division);
  };

  /**
   * Gets value of the password input field
   * @method onChangePassword
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    validate("password", password);
  };

  /**
   * Handles register data after submitting the form
   * @method handleRegister
   *
   * @param {event} e - An event object containing information about the action
   */
  const handleRegister = (e) => {
    setSuccessful(false);
    validate("firstname", firstname);
    validate("middlename", middlename);
    validate("lastname", lastname);
    validate("suffix", suffix);
    validate("orcidID", orcidID);
    validate("orgAcronym", orgAcronym);
    validate("organization", organization);
    validate("designation", designation);
    validate("division", division);
    validate("orgAddressProvince", orgAddressProvince);
    validate("orgAddressMunicipality", orgAddressMunicipality);
    validate("email", email);
    validate("password", password);
    setCounter(counter + 1);

    if (Object.keys(error).length === 0) {
      setLoading(true);
      dispatch(
        register(
          firstname,
          middlename,
          lastname,
          suffix,
          orcidID,
          orgAcronym,
          organization,
          designation,
          division,
          orgAddressProvince,
          orgAddressMunicipality,
          email,
          password
        )
      )
        .then(() => {
          setActiveStep(4);
          // setSuccessful(true);
          // setEmailActivationMessage(
          //   "An activation link has been sent. Please check your email account."
          // );
        })
        .catch((status) => {
          setSuccessful(false);
          props?.setShowToast(true);
          props?.setToastStatus(status);
          props?.setToastImage(null);
        })
        .finally(() => {
          // setShow(true);
          setLoading(false);
          // clearMessageNotification();
        });
    }
  };

  const clearMessageNotification = () => {
    const timeout = setTimeout(() => {
      setShow(false);
      dispatch(clearMessage());
    }, 5000); // 5 seconds

    return () => clearTimeout(timeout);
  };

  const [orgAddressMunicipalityOptions, setOrgAddressMunicipalityOptions] =
    useState([]);
  const [
    selectedOrgAddressMunicipalityOptions,
    setSelectedOrgAddressMunicipalityOptions,
  ] = useState([]);

  const handleSelectOrgAddressMunicipality = (selected) => {
    setSelectedOrgAddressMunicipalityOptions(selected);
    setOrgAddressMunicipality(selected[0]?.name);
    validate("orgAddressMunicipality", selected[0]?.name);
  };

  const onInputChangeOrgAddressMunicipality = (e) => {
    setCounter(counter + 1);
    validate(
      "orgAddressMunicipality",
      selectedOrgAddressMunicipalityOptions[0]?.name
    );
  };

  const [orgAddressProvinceOptions, setOrgAddressProvinceOptions] = useState(
    []
  );
  const [
    selectedOrgAddressProvinceOptions,
    setSelectedOrgAddressProvinceOptions,
  ] = useState([]);

  const handleSelectOrgAddressProvince = (selected) => {
    setSelectedOrgAddressProvinceOptions(selected);
    setOrgAddressProvince(selected[0]?.name);
    validate("orgAddressProvince", selected[0]?.name);
    if (selected[0]?.name === undefined) {
      setSelectedOrgAddressMunicipalityOptions([]);
      validate("orgAddressMunicipality", "");
    }
  };

  const onInputChangeOrgAddressProvince = (e) => {
    setCounter(counter + 1);
    validate("orgAddressProvince", selectedOrgAddressProvinceOptions[0]?.name);
  };

  const [orgAddressBarangayOptions, setOrgAddressBarangayOptions] = useState(
    []
  );
  const [
    selectedOrgAddressBarangayOptions,
    setSelectedOrgAddressBarangayOptions,
  ] = useState([]);

  const handleSelectOrgAddressBarangay = (selected) => {
    setSelectedOrgAddressBarangayOptions(selected);
  };

  const onInputChangeOrgAddressBarangay = (e) => {
    console.log(e);
  };

  useEffect(() => {
    if (selectedOrgAddressProvinceOptions.length > 0) {
      const cityMunicipalityList = Object.values(cityMunicipalityJson)
        .filter(
          (item) => item.prov_code === selectedOrgAddressProvinceOptions[0]?.id
        )
        .map((item) => {
          return {
            id: item.mun_code,
            name: item.name,
            label: item.name,
          };
        });
      setOrgAddressMunicipalityOptions(cityMunicipalityList);
    } else {
      setOrgAddressMunicipality("");
      setOrgAddressMunicipalityOptions([]);
      setSelectedOrgAddressMunicipalityOptions([]);
    }
  }, [selectedOrgAddressProvinceOptions]);

  useEffect(() => {
    if (selectedOrgAddressMunicipalityOptions.length > 0) {
      const barangayList = Object.values(barangaysJson)
        .filter(
          (item) =>
            item.mun_code === selectedOrgAddressMunicipalityOptions[0]?.id
        )
        .map((item, index) => {
          return {
            id: index + item.mun_code,
            name: item.name,
            label: item.name,
          };
        });
      setOrgAddressBarangayOptions(barangayList);
    } else {
      setOrgAddressBarangayOptions([]);
      setSelectedOrgAddressBarangayOptions([]);
    }
  }, [selectedOrgAddressMunicipalityOptions]);

  useEffect(() => {
    if (selectedOrgAddressProvinceOptions.length > 0) {
      // console.log(
      //   findRecordWithWord(selectedOrgAddressProvinceOptions[0]?.name)
      // );
    }
    if (selectedOrgAddressMunicipalityOptions.length > 0) {
      // console.log(selectedOrgAddressMunicipalityOptions[0]?.name);
      // console.log(
      //   findRecordWithWord(selectedOrgAddressMunicipalityOptions[0]?.name)
      // );
    } else if (selectedOrgAddressBarangayOptions.length > 0) {
      // console.log(findRecordWithWord(selectedOrgAddressBarangayOptions[0]?.name))
    }
  }, [
    selectedOrgAddressProvinceOptions,
    selectedOrgAddressMunicipalityOptions,
    selectedOrgAddressBarangayOptions,
  ]);

  const findRecordWithWord = (searchWord) => {
    for (const key in zipcodesJson) {
      if (zipcodesJson.hasOwnProperty(key)) {
        const value = zipcodesJson[key];
        if (
          typeof value === "string" &&
          value.toLowerCase().includes(searchWord.toLowerCase())
        ) {
          return { [key]: value };
        } else if (Array.isArray(value)) {
          for (const subValue of value) {
            if (typeof subValue === "string" && subValue.includes(searchWord)) {
              return { [key]: subValue };
            }
          }
        }
      }
    }
    return null; // If not found
  };

  const [activeStep, setActiveStep] = useState(1);
  const [doneStep1, setDoneStep1] = useState(false);
  const [doneStep2, setDoneStep2] = useState(false);
  const [doneStep3, setDoneStep3] = useState(false);

  const handleStepClick = (step) => {
    setActiveStep(step);
  };

  /**
   * Navigates to sign in page
   * @method onClickSignIn
   */
  const onClickSignIn = () => {
    navigate("/signin");
  };

  useEffect(() => {
    const provinceList = Object.values(provincesJson)
      .map((item) => {
        return {
          id: item.prov_code,
          name: item.name,
          label: item.name,
        };
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name));
    setOrgAddressProvinceOptions(provinceList);
  }, []);

  useEffect(() => {
    if (selectedOrgAddressProvinceOptions.length > 0) {
      const cityMunicipalityList = Object.values(cityMunicipalityJson)
        .filter(
          (item) => item.prov_code === selectedOrgAddressProvinceOptions[0]?.id
        )
        .map((item) => {
          return {
            id: item.mun_code,
            name: item.name,
            label: item.name,
          };
        })
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name));
      setOrgAddressMunicipalityOptions(cityMunicipalityList);
    } else {
      setOrgAddressMunicipalityOptions([]);
      setSelectedOrgAddressMunicipalityOptions([]);
    }
  }, [selectedOrgAddressProvinceOptions]);

  useEffect(() => {
    if (firstname !== "" && lastname !== "") {
      setDisabledNext1(false);
      setDisabledCircle1(false);
      setDisabledCircle2(false);
    }

    if (
      orgAcronym !== "" &&
      organization !== "" &&
      designation !== "" &&
      division !== "" &&
      orgAddressProvince !== "" &&
      orgAddressMunicipality !== ""
    ) {
      setDisabledNext2(false);
      setDisabledCircle1(false);
      setDisabledCircle2(false);
      setDisabledCircle3(false);
    }

    if (email !== "" && password !== "") {
      setDisabledCreateAccount(false);
      setDisabledCircle1(false);
      setDisabledCircle2(false);
      setDisabledCircle3(false);
    }

    stepInputFields.forEach((field) => {
      if (Object.keys(error).includes(field)) {
        setDisabledCircle1(true);
        setDisabledCircle2(true);
        setDisabledCircle3(true);
        setDisabledNext1(true);
        setDisabledNext2(true);
        setDisabledCreateAccount(true);
      }
    });
  }, [
    firstname,
    lastname,
    orgAcronym,
    organization,
    designation,
    division,
    orgAddressProvince,
    orgAddressMunicipality,
    email,
    password,
    stepInputFields,
    error,
  ]);

  useEffect(() => {
    switch (activeStep) {
      case 2:
        setDoneStep1(true);
        setDoneStep2(false);
        setDoneStep3(false);
        break;
      case 3:
        setDoneStep1(true);
        setDoneStep2(true);
        setDoneStep3(false);
        break;
      case 1:
        setDoneStep1(false);
        setDoneStep2(false);
        setDoneStep3(false);
        break;
      default:
        setDoneStep1(true);
        setDoneStep2(true);
        setDoneStep3(true);
    }
  }, [activeStep]);

  useEffect(() => {
    let timer;

    if (isRunning) {
      timer = setInterval(() => {
        if (seconds < 300) {
          setSeconds(seconds + 1);
        } else {
          clearInterval(timer);
          setIsRunning(false);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning, seconds]);

  const startTimer = () => {
    setIsRunning(true);
    setSeconds(0);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const onClickResend = () => {
    startTimer();
    dispatch(resendActivation(email)).catch((status) => {
      stopTimer();
      setSuccessful(false);
      props?.setShowToast(true);
      props?.setToastStatus(status);
      props?.setToastImage(null);
    });
  };

  return (
    <Home>
      <div className="clickable-circles">
        <div className="circle">
          <button
            className={`step-button ${activeStep === 1 ? "active" : ""} ${
              doneStep1 ? "done-step" : ""
            }`}
            onClick={() => {
              handleStepClick(1);
            }}
            disabled={disabledCircle1}
          >
            {doneStep1 ? (
              <img
                src="/images/white_check.svg"
                className="white-check"
                alt="white-check"
              />
            ) : (
              1
            )}
          </button>
          <p>Basic Info</p>
        </div>
        <div className={`line ${activeStep >= 2 ? "active" : ""}`}></div>
        <div className="circle">
          <button
            className={`step-button ${activeStep === 2 ? "active" : ""} ${
              doneStep2 ? "done-step" : ""
            }`}
            onClick={() => handleStepClick(2)}
            disabled={disabledCircle2}
          >
            {doneStep2 ? (
              <img
                src="/images/white_check.svg"
                className="white-check"
                alt="white-check"
              />
            ) : (
              2
            )}
          </button>
          <p>Affiliation</p>
        </div>
        <div className={`line ${activeStep === 3 ? "active" : ""}`}></div>
        <div className="circle">
          <button
            className={`step-button ${activeStep === 3 ? "active" : ""} ${
              doneStep3 ? "done-step" : ""
            }`}
            onClick={() => handleStepClick(3)}
            disabled={disabledCircle3}
          >
            {doneStep3 ? (
              <img
                src="/images/white_check.svg"
                className="white-check"
                alt="white-check"
              />
            ) : (
              3
            )}
          </button>
          <p className="how-youll-sign-in">How you'll sign in</p>
        </div>
      </div>

      {activeStep === 1 && (
        <Container className="step-container p-5">
          <p className="text-center h3 mx-1 mt-4 bold">Basic information</p>
          <div className="form-outline">
            <Form.Group className="mb-3">
              <Form.Label className="required-field">First name</Form.Label>
              <Form.Control
                type="text"
                name="first-name"
                id="first-name"
                data-cy="first-name"
                value={firstname}
                onChange={onChangeFirstName}
                className={`input-field ${
                  isValid?.firstname
                    ? "is-valid"
                    : isValid.firstname !== undefined
                    ? "is-invalid"
                    : ""
                }`}
              />
              {getFormErrorMessage("firstname")}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Middle name</Form.Label>
              <Form.Control
                type="text"
                name="middle-name"
                id="middle-name"
                data-cy="middle-name"
                value={middlename}
                onChange={onChangeMiddleName}
                className={`input-field ${
                  isValid?.middlename
                    ? "is-valid"
                    : isValid.middlename !== undefined
                    ? "is-invalid"
                    : ""
                }`}
              />
              {getFormErrorMessage("middlename")}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required-field">Last name</Form.Label>
              <Form.Control
                type="text"
                name="last-name"
                id="last-name"
                data-cy="last-name"
                value={lastname}
                onChange={onChangeLastName}
                className={`input-field ${
                  isValid?.lastname
                    ? "is-valid"
                    : isValid.lastname !== undefined
                    ? "is-invalid"
                    : ""
                }`}
              />
              {getFormErrorMessage("lastname")}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Suffix</Form.Label>
              <Form.Control
                type="text"
                name="suffix"
                id="suffix"
                data-cy="suffix"
                value={suffix}
                onChange={onChangeSuffix}
                className={`input-field ${
                  isValid?.suffix
                    ? "is-valid"
                    : isValid.suffix !== undefined
                    ? "is-invalid"
                    : ""
                }`}
              />
              {getFormErrorMessage("suffix")}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                ORCID ID
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Go to ORCID website</Tooltip>}
                  trigger={["hover", "focus"]}
                >
                  <Link to="https://orcid.org/" target="_blank">
                    <img
                      src="/images/question.svg"
                      className="question ms-2"
                      alt="ORCID ID icon"
                    />
                  </Link>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="text"
                name="orcid"
                id="orcid"
                data-cy="orcid"
                value={orcidID}
                onChange={onChangeOrcidID}
                placeholder="0000-0000-0000-0000"
                maxLength="19"
                className={`input-field ${
                  isValid?.orcidID
                    ? "is-valid"
                    : isValid.orcidID !== undefined
                    ? "is-invalid"
                    : ""
                }`}
              />
              {getFormErrorMessage("orcidID")}
            </Form.Group>
          </div>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={onClickSignIn}
              className="light-btn"
              name="signin-instead"
              id="signin-instead"
              data-cy="signin-instead"
            >
              Sign in instead
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={disabledNext1}
              className="submit-btn button"
              name="next"
              id="next"
              data-cy="next"
              onClick={() => handleStepClick(2)}
            >
              Next
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Container>
      )}

      {activeStep === 2 && (
        <Container className="step-container p-5">
          <p className="text-center h3 mx-1 pt-4 bold">Affiliation</p>
          <div className="form-outline">
            <Form.Group className="mb-3">
              <Form.Label className="required-field">
                Acronym of Organization
              </Form.Label>
              <Form.Control
                type="text"
                name="or-acronym"
                id="or-acronym"
                data-cy="or-acronym"
                value={orgAcronym}
                onChange={onChangeOrgAcronym}
                className={`input-field ${
                  isValid?.orgAcronym
                    ? "is-valid"
                    : isValid.orgAcronym !== undefined
                    ? "is-invalid"
                    : ""
                }`}
              />
              {getFormErrorMessage("orgAcronym")}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required-field">
                Name of Organization
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={organization}
                onChange={onChangeOrganization}
                className={`input-field ${
                  isValid?.organization
                    ? "is-valid"
                    : isValid.organization !== undefined
                    ? "is-invalid"
                    : ""
                }`}
                name="org-name"
                id="org-name"
                data-cy="org-name"
              />
              {getFormErrorMessage("organization")}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required-field">Designation</Form.Label>
              <Form.Control
                type="text"
                value={designation}
                onChange={onChangeDesignation}
                className={`input-field ${
                  isValid?.designation
                    ? "is-valid"
                    : isValid.designation !== undefined
                    ? "is-invalid"
                    : ""
                }`}
                name="designation"
                id="designation"
                data-cy="designation"
              />
              {getFormErrorMessage("designation")}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required-field">
                Department / Division / Unit
              </Form.Label>
              <Form.Control
                type="text"
                name="department"
                id="department"
                data-cy="department"
                value={division}
                onChange={onChangeDivision}
                className={`input-field ${
                  isValid?.division
                    ? "is-valid"
                    : isValid.division !== undefined
                    ? "is-invalid"
                    : ""
                }`}
              />
              {getFormErrorMessage("division")}
            </Form.Group>
            <Form.Group className="mb-3 typeahead-container">
              <Form.Label className="required-field">Province</Form.Label>
              <Typeahead
                clearButton
                labelKey="name"
                multiple={false}
                options={orgAddressProvinceOptions}
                placeholder="Choose options"
                selected={selectedOrgAddressProvinceOptions}
                onChange={handleSelectOrgAddressProvince}
                onInputChange={onInputChangeOrgAddressProvince}
                className={`input-field typeahead-input form-control ${
                  isValid?.orgAddressProvince
                    ? "is-valid"
                    : isValid.orgAddressProvince !== undefined
                    ? "is-invalid"
                    : ""
                }`}
                id="province"
                inputProps={{
                  "data-cy": "province", // Add data-cy attribute to input element
                }}
              />
              {getFormErrorMessage("orgAddressProvince")}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required-field">City</Form.Label>
              <Typeahead
                clearButton
                labelKey="name"
                multiple={false}
                options={orgAddressMunicipalityOptions}
                placeholder="Choose options"
                selected={selectedOrgAddressMunicipalityOptions}
                onChange={handleSelectOrgAddressMunicipality}
                onInputChange={onInputChangeOrgAddressMunicipality}
                className={`input-field typeahead-input form-control ${
                  isValid?.orgAddressMunicipality
                    ? "is-valid"
                    : isValid.orgAddressMunicipality !== undefined
                    ? "is-invalid"
                    : ""
                }`}
                id="city"
                inputProps={{
                  "data-cy": "city", // Add data-cy attribute to input element
                }}
              />
              {getFormErrorMessage("orgAddressMunicipality")}
            </Form.Group>
          </div>
          <div className="d-flex buttons-div justify-content-between">
            <Button
              variant="outline-light"
              onClick={onClickSignIn}
              className="light-btn"
              id="signin-instead"
              name="signin-instead"
              data-cy="signin-instead"
            >
              Sign in instead
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={disabledNext2}
              className="submit-btn button"
              id="next"
              name="next"
              data-cy="next"
              onClick={() => handleStepClick(3)}
            >
              Next
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Container>
      )}

      {activeStep === 3 && (
        <Container className="step-container p-5">
          <p className="text-center h3 mx-1 mt-4 bold">
            How you'll sign in to DIMER
          </p>
          <div className="form-outline">
            <Form.Group className="mb-3">
              <Form.Label className="required-field">Email</Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={onChangeEmail}
                className={`input-field ${
                  isValid?.email
                    ? "is-valid"
                    : isValid.email !== undefined
                    ? "is-invalid"
                    : ""
                }`}
                id="email-address"
                name="email-address"
                data-cy="email-address"
              />
              {getFormErrorMessage("email")}
            </Form.Group>
            <Form.Group className="password-container mb-3">
              <Form.Label className="required-field">Password</Form.Label>
              <Form.Control
                type={passwordShow ? "text" : "password"}
                className={`input-field ${
                  isValid?.password
                    ? "is-valid"
                    : isValid.password !== undefined
                    ? "is-invalid"
                    : ""
                }`}
                id="password"
                name="password"
                data-cy="password"
                value={password}
                onChange={onChangePassword}
              />
              <img
                onClick={onClickPasswordBtn}
                src={passwordShow ? "/images/eye.svg" : "/images/eye-slash.svg"}
                className="eye-sign-up"
                alt="hide"
                id="show-btn"
                data-cy="show-btn"
              />
              {getFormErrorMessage("password")}
              <span>
                Minimum of 8 characters with atleast 1 symbol, 1 uppercase
                letter and 1 number
              </span>
            </Form.Group>
          </div>
          <div className="d-flex buttons-div justify-content-between">
            <Button
              variant="outline-light"
              onClick={onClickSignIn}
              className="light-btn"
              id="signin-instead"
              name="signin-instead"
              data-cy="signin-instead"
            >
              Sign in instead
            </Button>
            <Button
              variant="primary"
              disabled={disabledCreateAccount}
              className="submit-btn button"
              onClick={handleRegister}
              id="create-account"
              name="create-account"
              data-cy="create-account"
            >
              Create Account
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Container>
      )}

      {activeStep === 4 && (
        <Container className="step-container p-5 text-center">
          <p className="h3 mx-1 mt-4 bold">Activate your DIMER Account</p>
          <div className="form-outline">
            <p>An activation link has been sent to your email</p>
            <p>
              The activation link will expire in 1 hour.
              <br />
              If no email was received, click the Resend button
            </p>
          </div>
          <div className="d-flex buttons-div justify-content-center">
            <Button
              variant="primary"
              type="submit"
              className="submit-btn button"
              id="resend"
              name="resend"
              data-cy="resend"
              onClick={onClickResend}
              disabled={isRunning}
            >
              Resend {isRunning && "(" + (300 - seconds) + ")"}
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Container>
      )}
    </Home>
  );
};

export default SignUp;
