import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Tab,
  Breadcrumb,
  Tabs,
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Offcanvas,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllGroups, getAllUsers, getGroup } from "actions/group";
import CustomPagination from "../layout/CustomPagination";
import {
  editSubscribeModelProfile,
  getAllAccuracy,
  getAllModelVersions,
  getAllSubscribeModelProfile,
  getModel,
  getSubscribeModelProfile,
} from "actions/model";
import DeleteModelModal from "./DeleteModelModal";
import { compareValues, convertDate } from "common/constants";
import EditModelModal from "./EditModelProfileModal";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Dot,
  Label,
} from "recharts";
import "styles/ModelProfile.css";
import "styles/mobile/ModelProfileMobile.css";
import ViewModelCreatorModal from "./ViewModelCreatorModal";
import { GET_MODEL_FAIL } from "actions/types";
import ModelDemo from "./ModelDemo";
import FilterDropdown from "components/layout/FilterDropdown";
import SaveAndPublishModal from "../common-modals/SaveAndPublishModal";
import DeleteVersionModal from "components/common-modals/DeleteVersionModal";
import ArchiveModal from "components/models/ArchiveModal";
import MobileLeftSidebar from "components/layout/MobileLeftSidebar";
import { useMediaQuery } from "react-responsive";

/**
 * A module for showing Model profile component
 * @module components/models/ModelProfile
 */

/**
 * Model Profile component
 * @method ModelProfile
 *
 * @return {JSX.Element}
 *
 */
const LinearAreaChart = (props) => {
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState({});
  useEffect(() => {
    if (props?.modelsAccuracy) {
      const resultPublished = Object.values(
        props?.modelsAccuracy?.model_details_publish
      ).map((item) => {
        const modelVersion = Object.values(
          props?.modelsAccuracy?.model_version_publish
        ).find((version) => item.model_file === version.model_file);
        const model_user = Object.values(props?.users).find(
          (user) => user.email === modelVersion?.contact_information
        );

        const model_user_name =
          props?.user.email === modelVersion?.contact_information
            ? "me"
            : model_user?.first_name + " " + model_user?.last_name;

        const row = {
          id: item?.model_file,
          version: modelVersion?.version,
          name: modelVersion?.name,
          accuracy: item?.accuracy,
          date_created: modelVersion?.date_created,
          author: model_user_name,
          status: "Published",
        };
        return row;
      });

      const resultArchived = Object.values(
        props?.modelsAccuracy?.model_details_archive
      ).map((item) => {
        const modelVersion = Object.values(
          props?.modelsAccuracy?.model_version_archive
        ).find((version) => item.model_file === version.model_file);
        const model_user = Object.values(props?.users).find(
          (user) => user.email === modelVersion?.contact_information
        );

        const model_user_name =
          props?.user.email === modelVersion?.contact_information
            ? "me"
            : model_user?.first_name + " " + model_user?.last_name;

        const row = {
          id: item?.model_file,
          version: modelVersion?.version,
          name: modelVersion?.name,
          accuracy: item?.accuracy,
          date_created: modelVersion?.date_created,
          author: model_user_name,
          status: "Archived",
        };
        return row;
      });

      const result1 = resultPublished.filter(
        (item) => item?.version !== undefined
      );

      const result2 = resultArchived.filter(
        (item) => item?.version !== undefined
      );

      if (result2.length > 0) {
        result2.forEach((res2) => {
          result1.push(res2);
        });
      }

      const sortedResult = result1.sort((a, b) => {
        return compareValues(a.date_created, b.date_created, "asc");
      });
      sortedResult.unshift({ x: 0, y: 0 });
      sortedResult.push({ x: 0, y: 0 });
      setGraphData(sortedResult);
    }
  }, [props?.modelsAccuracy]);

  const [activeDot, setActiveDot] = useState(false);
  const [activeDotRadius, setActiveDotRadius] = useState(4);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [name, setName] = useState("");
  const [accuracy, setAccuracy] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [author, setAuthor] = useState("");
  const [status, setStatus] = useState("");
  const [id, setID] = useState(0);

  const handleLearnMoreBtn = (versionId) => {
    navigate("/models/profile/" + id + "/version/" + versionId);
  };

  const CustomTooltip = (props) => {
    return (
      <div
        onMouseEnter={props?.onMouseEnter}
        className="custom-tooltip"
        style={{
          position: "absolute",
          top: positionY + 150,
          left: positionX + 120,
        }}
      >
        <p className="semi-bold">{props?.name}</p>
        <span className="d-flex justify-content-between">
          {props?.status === "Published" && (
            <div className="labels">
              <div className="m-0 p-1" style={{ backgroundColor: "#99e6a1" }}>
                <span>{props?.status}</span>
              </div>
            </div>
          )}
          {props?.status === "Archived" && (
            <div className="labels">
              <div
                className="m-0 p-1"
                style={{ backgroundColor: "#2e2e2e", color: "#fff" }}
              >
                <span>{props?.status}</span>
              </div>
            </div>
          )}
        </span>
        <span className="d-flex justify-content-between">
          <span>Accuracy:</span>
          <span>{props?.accuracy}%</span>
        </span>
        <span className="d-flex justify-content-between">
          <span>Date created:</span>
          <span>{convertDate(props?.dateCreated, "MM/DD/YYYY")}</span>
        </span>
        <span className="d-flex justify-content-between">
          <span>Author:</span>
          <span>{props?.author}</span>
        </span>
        <div
          className="d-flex justify-content-center align-items-center"
          content={<CustomTooltip />}
        >
          <Button
            className="button custom-button"
            onClick={() => handleLearnMoreBtn(props?.id)}
          >
            Learn more
          </Button>
        </div>
      </div>
    );
  };

  const onMouseEnter = (data, label) => {
    setID(data?.id);
    setName(data?.name);
    setAccuracy(data?.accuracy);
    setDateCreated(data?.dateCreated);
    setAuthor(data?.author);
    setStatus(data?.status);
    setPositionX(data?.cx - 150);
    setPositionY(data?.cy + 25);
    setActiveDot(true);
    setActiveDotRadius(8);
  };

  // Custom dot renderer
  const renderCustomDot = (props) => {
    const { key, cx, cy, r, payload } = props;
    const { y, id, name, accuracy, date_created, author, status } = payload; // Get the y value of the data point

    let dotRadius = 5; // Default dot radius

    // Customize dot thickness based on y value
    if (y > 25) {
      dotRadius = 7;
    } else if (y > 15) {
      dotRadius = 6;
    }

    return (
      <Dot
        id={id}
        name={name}
        accuracy={accuracy}
        dateCreated={date_created}
        author={author}
        status={status}
        key={key}
        cx={cx}
        cy={cy}
        r={activeDotRadius}
        onMouseEnter={onMouseEnter}
      />
    );
  };
  const elementRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const getElementPosition = () => {
      if (elementRef.current) {
        const elementRect = elementRef.current.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft =
          window.pageXOffset || document.documentElement.scrollLeft;

        setPosition({
          y: elementRect.top + scrollTop - 210,
          x: elementRect.left + scrollLeft + 10,
        });
      }
    };

    // Call the function once on mount
    getElementPosition();

    // Add an event listener to handle position changes due to resize or scroll
    window.addEventListener("resize", getElementPosition);
    window.addEventListener("scroll", getElementPosition);

    // Clean up the event listeners on unmount
    return () => {
      window.removeEventListener("resize", getElementPosition);
      window.removeEventListener("scroll", getElementPosition);
    };
  }, []);

  const onMouseLeave = () => {
    setActiveDot(false);
  };

  const onMouseEnterCustomToolTip = () => {
    setActiveDot(true);
  };

  return (
    <>
      <div ref={elementRef}>
        <AreaChart
          onMouseLeave={onMouseLeave}
          width={1000}
          height={700}
          data={graphData}
          margin={{ top: 100, right: 30, bottom: 50, left: 100 }}
        >
          {/* Add X and Y axes, and the CartesianGrid */}
          {/* <XAxis dataKey="x" /> */}
          <XAxis dataKey="version" axisLine={false} tickLine={false}>
            <Label value="Version" dy={30} />
          </XAxis>
          {/* <YAxis axisLine={false} tickLine={false} label="accuracy"/> */}
          <YAxis axisLine={false} tickLine={false}>
            <Label value="Accuracy" dx={-50} />
          </YAxis>
          <CartesianGrid
            strokeDasharray="7 3"
            stroke="#969696"
            vertical={false}
          />

          {/* Render the area with the defined gradient */}
          <Area
            type="linear"
            dataKey="accuracy"
            stroke="#262262"
            strokeWidth={5}
            fill="url(#colorGradient)" // Apply the gradient fill here
            isAnimationActive={false}
            dot={renderCustomDot}
          />
          {/* Define the linear gradient */}
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
              <stop offset="100%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
        </AreaChart>
      </div>
      {activeDot && (
        <CustomTooltip
          id={id}
          name={name}
          accuracy={accuracy}
          dateCreated={dateCreated}
          author={author}
          status={status}
          onMouseEnter={onMouseEnterCustomToolTip}
        />
      )}
    </>
  );
};

const ModelProfile = (props) => {
  const scrollRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const params = useParams();
  const id = params.id;
  const modelProfileID = params.id;
  const searchParams = new URLSearchParams(location.search);

  const tab = searchParams.get("tab");
  /**
   * -------------------
   * * Redux store state
   * -------------------
   */
  const {
    currentModel,
    modelVersions,
    modelsAccuracy,
    currentSubscribedModel,
  } = useSelector((state) => state.model);
  const { groups, currentGroup, users } = useSelector((state) => state.group);
  const { user } = useSelector((state) => state.auth);

  const [modelName, setModelName] = useState("");
  const [modelTask, setModelTask] = useState("");
  const [modelDescription, setModelDescription] = useState("");
  const [modelGroup, setModelGroup] = useState("");
  const [modelCreatedBy, setModelCreatedBy] = useState("");
  const [modelDateCreated, setModelDateCreated] = useState("");

  // Intended use
  const [modelPrimaryIntendedUses, setModelPrimaryIntendedUses] = useState("");
  const [modelPrimaryIntendedUsers, setModelPrimaryIntendedUsers] =
    useState("");
  const [modelOutOfScopeUseCases, setModelOutOfScopeUseCases] = useState("");

  // Factors
  const [modelGroups, setModelGroups] = useState("");
  const [modelInstrumentation, setModelInstrumentation] = useState("");
  const [modelEnvironment, setModelEnvironment] = useState("");

  // Metrics
  const [modelPerformanceMeasures, setModelPerformanceMeasures] = useState("");
  const [modelDecisionThresholds, setModelDecisionThresholds] = useState("");
  const [modelApproachesToUncertainty, setModelApproachesToUncertainty] =
    useState("");

  // Considerations & biases
  const [modelData, setModelData] = useState("");
  const [modelHumanLife, setModelHumanLife] = useState("");
  const [modelMitigations, setModelMitigations] = useState("");
  const [modelRisksAndHarms, setModelRisksAndHarms] = useState("");
  const [modelUseCases, setModelUseCases] = useState("");

  const [selectedOptionsStatuses, setSelectedOptionsStatuses] = useState([]);
  const [clearFilter, setClearFilter] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [refreshCurrentModelCount, setRefreshCurrentModelCount] = useState(0);
  const [demoToInference, setDemoToInference] = useState(0);
  const [showDeleteModelModal, setShowDeleteModelModal] = useState(false);
  const [showEditModelModal, setShowEditModelModal] = useState(false);
  const [showViewModelCreatorModal, setShowViewModelCreatorModal] =
    useState(false);
  const [refreshSubscribe, setRefreshSubscribe] = useState(0);
  const [subscribed, setSubscribed] = useState(false);
  const [subscribedCount, setSubscribedCount] = useState(0);

  const [userAllow, setUserAllow] = useState(false);

  const [sortDirectionAccuracy, setSortDirectionAccuracy] = useState("asc");
  const [sortDirectionStatus, setSortDirectionStatus] = useState("asc");
  const [sortDirectionDateCreated, setSortDirectionDateCreated] =
    useState("desc");

  const [expanded, setExpanded] = useState(false);
  const [expandedVersionID, setExpandedVersionID] = useState("");
  const [showSaveAndPublishModal, setShowSaveAndPublishModal] = useState(false);
  const [showDeleteVersionModal, setShowDeleteVersionModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [currentRowID, setCurrentRowID] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDeleteModelModalClose = () => {
    setShowDeleteModelModal(false);
  };

  const handleEditModelModalClose = () => {
    setShowEditModelModal(false);
  };

  const handleViewModelCreatorModalClose = () => {
    setShowViewModelCreatorModal(false);
  };

  useEffect(() => {
    props.setPreload(true);
    dispatch(getModel(id))
      .then((res) => {
        res?.length === 0 && navigate("/404");
      })
      .catch((status) => {
        props.setShowToast(true);
        props.setToastStatus(status);
        props.setToastImage(null);
      })
      .finally(() => props.setPreload(false));
    dispatch(getAllModelVersions(id)).catch((error) => console.log(error));
    dispatch(getAllAccuracy(id)).catch((error) => console.log(error));
  }, [id, props?.refreshModelVersionsCount, refreshCurrentModelCount]);

  useEffect(() => {
    if (
      currentModel[0]?.model_created_by === user.email ||
      (modelGroup !== "Unassigned" &&
        currentGroup[0]?.group_members &&
        Object.values(currentGroup[0]?.group_members).find(
          (group) => group.email === user.email
        ))
    ) {
      setUserAllow(true);
    } else {
      setUserAllow(false);
    }
  }, [currentModel, currentGroup, modelGroup, user]);

  useEffect(() => {
    dispatch(getSubscribeModelProfile(id))
      // .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    setSubscribed(currentSubscribedModel.subscribe_status);
    setSubscribedCount(currentSubscribedModel.subscribe_count || 0);
  }, [currentSubscribedModel]);

  useEffect(() => {
    dispatch(getAllUsers()).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    resetValues();
    setModelName(currentModel[0]?.model_profile_name);
    setModelTask(currentModel[0]?.model_profile_task);
    setModelDescription(currentModel[0]?.model_profile_description);
    setModelPrimaryIntendedUses(currentModel[0]?.primary_intended_uses);
    setModelPrimaryIntendedUsers(currentModel[0]?.primary_intended_user);
    setModelOutOfScopeUseCases(currentModel[0]?.out_of_scope_use_cases);
    setModelGroups(currentModel[0]?.groups);
    setModelInstrumentation(currentModel[0]?.instrumentation);
    setModelEnvironment(currentModel[0]?.environment);
    setModelPerformanceMeasures(currentModel[0]?.performance_measures);
    setModelDecisionThresholds(currentModel[0]?.decision_thresholds);
    setModelApproachesToUncertainty(currentModel[0]?.approaches_to_uncertainty);
    setModelData(currentModel[0]?.data);
    setModelHumanLife(currentModel[0]?.human_life);
    setModelMitigations(currentModel[0]?.mitigations);
    setModelRisksAndHarms(currentModel[0]?.risks_and_harms);
    setModelUseCases(currentModel[0]?.use_cases);
    const model_user = Object.values(users).find(
      (user) => user.email === currentModel[0]?.model_created_by
    );
    if (model_user) {
      setModelCreatedBy(model_user?.first_name + " " + model_user?.last_name);
    }

    if (currentModel[0]?.model_date_create) {
      const formattedDate = convertDate(
        currentModel[0]?.model_date_create,
        isMobile ? "MM DD, YYYY" : "MM/DD/YYYY"
      );
      setModelDateCreated(formattedDate);
    }

    const group_id =
      currentModel[0]?.model_profile_group ||
      currentModel[0]?.model_profile_group_id;

    const groupName =
      Object.values(groups).find((group) => group.id === group_id)
        ?.group_name || "Unassigned";
    setModelGroup(groupName);

    if (group_id) {
      dispatch(getGroup(group_id)).catch((error) => console.log(error));
    }
    // if (subscribedModel.length > 0) {
    //   const subscribe = Object.values(
    //     subscribedModel[0]?.subscribe_model_profile
    //   ).find((i) => i === currentModel[0]?.id);

    // }
    setSubscribed(currentSubscribedModel?.subscribe_status);
  }, [users, currentModel, currentSubscribedModel]);

  const resetValues = () => {
    setSubscribed(false);
    setModelName("");
    setModelTask("");
    setModelDescription("");
    setModelGroup("");
    setModelCreatedBy("");
    setModelDateCreated("");
    setModelPrimaryIntendedUses("");
    setModelPrimaryIntendedUsers("");
    setModelOutOfScopeUseCases("");
    setModelGroups("");
    setModelInstrumentation("");
    setModelEnvironment("");
    setModelPerformanceMeasures("");
    setModelDecisionThresholds("");
    setModelApproachesToUncertainty("");
    setModelData("");
    setModelHumanLife("");
    setModelMitigations("");
    setModelRisksAndHarms("");
    setModelUseCases("");
  };

  useEffect(() => {
    dispatch({
      type: GET_MODEL_FAIL,
    });
    resetValues();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [data, setData] = useState([]); // your data array
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filteredItems, setFilteredItems] = useState([]);
  const [demoFilteredVersions, setDemoFilteredVersions] = useState([]);
  const [expandedModelProfileBtnMobile, setExpandedModelProfileBtnMobile] =
    useState(false);

  // const [sortDirection, setSortDirection] = useState("desc");
  const [sortColumn, setSortColumn] = useState("Date created");
  // const [expandedDateCreated, setExpandedDateCreated] = useState(false);
  // const [expandedStatus, setExpandedStatus] = useState(false);

  // const CustomDropdownToggle = React.forwardRef(
  //   ({ children, onClick }, ref) => {
  //     return (
  //       <span
  //         ref={ref}
  //         onClick={(e) => {
  //           e.preventDefault();
  //           onClick(e);
  //         }}
  //       >
  //         {children}
  //       </span>
  //     );
  //   }
  // );

  const handleClearFilterBtnClick = () => {
    setSelectedOptionsStatuses([]);
    setClearFilter(true);
  };

  const handleToggleExpand = (column) => {
    switch (column) {
      case "accuracy":
        setSortDirectionAccuracy(
          sortDirectionAccuracy === "asc" ? "desc" : "asc"
        );
        setSortColumn("Accuracy");
        break;
      case "status":
        setSortDirectionStatus(sortDirectionStatus === "asc" ? "desc" : "asc");
        setSortColumn("Status");
        break;
      case "date_created":
        setSortDirectionDateCreated(
          sortDirectionDateCreated === "asc" ? "desc" : "asc"
        );
        setSortColumn("Date created");
        break;
      default:
      //
    }
  };

  // // Define your custom header component for Name column
  // const CustomHeader = (props) => {
  //   const handleSortToggle = (direction) => {
  //     props?.setSortDirection(direction);
  //     props?.setSortColumn(props?.columnName);
  //   };

  //   return (
  //     <Dropdown
  //       show={props?.expanded}
  //       className="dropdown-version-list"
  //       drop="down"
  //     >
  //       <Dropdown.Toggle
  //         as={CustomDropdownToggle}
  //         variant="secondary"
  //         id="dropdown-basic"
  //       >
  //         {props?.columnName}
  //       </Dropdown.Toggle>

  //       <Dropdown.Menu
  //         className={
  //           props?.expanded ? "dropdown-menu slide-down" : "dropdown-menu"
  //         }
  //       >
  //         <Dropdown.Item onClick={() => handleSortToggle("asc")}>
  //           {props?.columnName === "Date created" ? "Older to newer" : "A to Z"}
  //         </Dropdown.Item>
  //         <Dropdown.Item onClick={() => handleSortToggle("desc")}>
  //           {props?.columnName === "Date created" ? "Newer to older" : "Z to A"}
  //         </Dropdown.Item>
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );
  // };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (selectedItemsPerPage) => {
    setCurrentPage(1);
    setItemsPerPage(selectedItemsPerPage);
  };

  const handleToggleExpandStatus = (modelVersionID) => {
    setExpanded(!expanded);
    setExpandedVersionID(modelVersionID);
  };

  const onClickEdit = (modelVersionID) => {
    navigate(
      "/models/profile/" + modelProfileID + "/version/edit/" + modelVersionID
    );
  };

  const onClickDeleteVersion = (modelVersionID) => {
    props?.deleteModelVersion(modelVersionID);
    setShowDeleteVersionModal(false);
  };

  const onClickSave = (isPublish, modelVersionID) => {
    if (isPublish) {
      props?.saveAndPublish(modelVersionID);
      setShowSaveAndPublishModal(false);
    }
  };

  const onClickDuplicateModelVersion = (modelVersionID) => {
    props?.duplicateModelVersion(modelProfileID, modelVersionID);
  };

  const handleSaveAndPublishModalClose = () => {
    setShowSaveAndPublishModal(false);
  };

  const handleArchiveModalClose = () => {
    setShowArchiveModal(false);
  };

  const handleDeleteVersionModalClose = () => {
    setShowDeleteVersionModal(false);
  };

  const subHeaderComponentMemo = useMemo(() => {
    const options = !userAllow
      ? ["Published", "Archived"]
      : ["Draft", "Published", "Archived"];

    return (
      <>
        <div className="version-list-div w-100">
          <div className="search-input-container">
            <input
              type="text"
              placeholder="Search version"
              onChange={(e) => setFilterText(e.target.value)}
              className="search-input"
              name="search-model-version"
              id="search-model-version"
              data-cy="search-model-version"
            />
            <img
              src="/images/search-icon-gray.svg"
              className="search-icon img-fluid icon-list"
              alt="group"
            />
          </div>
          {userAllow && (
            <Button
              className="add-version-btn button"
              onClick={() => handleAddVersionBtnClick()}
              name="add-model-version"
              id="add-model-version"
              data-cy="add-model-version"
            >
              Add version
            </Button>
          )}
          {!isMobile && (
            <FilterDropdown
              setSelectedOptions={setSelectedOptionsStatuses}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              filterTitle="Status"
              options={options}
              name="status"
              dropdownToggleID="filter-status"
            />
          )}
          {selectedOptionsStatuses.length > 0 && (
            <Button
              className="ms-3 button"
              onClick={() => handleClearFilterBtnClick()}
              name="clear-filter"
              id="clear-filter"
              data-cy="clear-filter"
            >
              Clear filter
            </Button>
          )}
        </div>
      </>
    );
  }, [userAllow, clearFilter, selectedOptionsStatuses.length]);

  const icon = (
    <img src="/images/user_list.svg" className="icon-list" alt="user" />
  );

  /**
   * Columns template and configuration
   */
  const columns1 = [
    {
      name: <span className="th">No.</span>,
      cell: (row) => <div className="row-version-num">{row.version}</div>,
      width: "10%",
    },
    {
      name: <span className="th">Description</span>,
      cell: (row) => <div className="row-version-desc">{row.description}</div>,
      width: "25%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("accuracy")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Accuracy</span>
          {sortColumn === "Accuracy" && (
            <img
              src={
                sortDirectionAccuracy === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      cell: (row) => <div className="row-version-accuracy">{row.accuracy}</div>,
      width: "15%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("date_created")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Date created</span>
          {sortColumn === "Date created" && (
            <img
              src={
                sortDirectionDateCreated === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      cell: (row) => {
        if (!isMobile) {
          return (
            <div className="row-version-date-created">{row.date_created}</div>
          );
        } else {
          return (
            <span className="row-version-added-by">
              {icon} {row.added_by_full_name}
            </span>
          );
        }
      },
      width: "17%",
    },
    {
      name: <span className="th">Added by</span>,
      selector: (row) => {
        if (isMobile) {
          return (
            <div className="row-version-date-created">{row.date_created}</div>
          );
        } else {
          return (
            <span className="row-version-added-by">
              {icon} {row.added_by_full_name}
            </span>
          );
        }
      },
      width: "17%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("status")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Status</span>
          {sortColumn === "Status" && (
            <img
              src={
                sortDirectionStatus === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      selector: (row) => {
        let statusElement = "";
        switch (row.status) {
          case "Published": {
            statusElement = (
              <div className="labels">
                <div
                  className="m-0 p-1"
                  style={{ backgroundColor: "#99e6a1", color: "#2e2e2e" }}
                >
                  <span>
                    {row.status}
                    {userAllow && (
                      <img
                        src={
                          expandedVersionID === row.id && expanded
                            ? "/images/arrow-down-white.svg"
                            : "/images/arrow-down.svg"
                        }
                        className="arrow-down img-fluid"
                        alt="arrow down"
                      />
                    )}
                  </span>
                </div>
              </div>
            );
            break;
          }
          case "Draft": {
            statusElement = (
              <div className="labels">
                <div
                  className="m-0 p-1"
                  style={{ backgroundColor: "#35ceff", color: "#2e2e2e" }}
                >
                  <span>
                    {row.status}
                    <img
                      src={
                        expandedVersionID === row.id && expanded
                          ? "/images/arrow-down-white.svg"
                          : "/images/arrow-down.svg"
                      }
                      className="arrow-down img-fluid"
                      alt="arrow down"
                    />
                  </span>
                </div>
              </div>
            );
            break;
          }
          default: {
            statusElement = (
              <div className="labels">
                <div
                  className="m-0 p-1"
                  style={{ backgroundColor: "#2e2e2e", color: "#fff" }}
                >
                  <span>
                    {row.status}
                    {userAllow && (
                      <img
                        src={
                          expandedVersionID === row.id && expanded
                            ? "/images/arrow-down-white.svg"
                            : "/images/arrow-down.svg"
                        }
                        className="arrow-down img-fluid"
                        alt="arrow down"
                      />
                    )}
                  </span>
                </div>
              </div>
            );
            break;
          }
        }

        return (
          <>
            <Dropdown
              className="action-dropdown"
              show={
                !isMobile &&
                expandedVersionID === row.id &&
                row.status !== "Archived" &&
                expanded
                  ? true
                  : false
              }
              onToggle={() => {
                row.status !== "Archived" && userAllow
                  ? handleToggleExpandStatus(row.id)
                  : setExpanded(false);
              }}
            >
              <Dropdown.Toggle
                variant="secondary"
                className="three-dot-dropdown-table"
                id={`version-row-status-${row.id}`}
                data-cy="version-row-status"
              >
                {statusElement}
              </Dropdown.Toggle>
              <Dropdown.Menu className="action-dropdown-menu">
                {row.status === "Draft" && (
                  <>
                    {row.publishAllow && (
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentRowID(row.id);
                          setShowSaveAndPublishModal(true);
                        }}
                        id={`publish-model-version-${row.id}`}
                        data-cy="publish-model-version"
                      >
                        <img
                          src="/images/save_publish.svg"
                          className="img-fluid icon-list"
                          alt="save and publish"
                        />
                        Publish
                      </Dropdown.Item>
                    )}
                  </>
                )}
                {row.status === "Draft" && (
                  <>
                    <Dropdown.Item onClick={() => onClickEdit(row.id)}>
                      <img
                        src="/images/edit.svg"
                        className="img-fluid icon-list"
                        alt="edit"
                        id={`edit-model-version-${row.id}`}
                        data-cy="edit-model-version"
                      />
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        setCurrentRowID(row.id);
                        setShowDeleteVersionModal(true);
                      }}
                      id={`delete-model-version-${row.id}`}
                      data-cy="delete-model-version"
                    >
                      <img
                        src="/images/trash.svg"
                        className="img-fluid icon-list"
                        alt="delete"
                      />
                      Delete
                    </Dropdown.Item>
                  </>
                )}
                {row.status === "Published" && (
                  <>
                    <Dropdown.Item
                      onClick={() => onClickDuplicateModelVersion(row.id)}
                      id={`duplicate-model-version-${row.id}`}
                      data-cy="duplicate-model-version"
                    >
                      <img
                        src="/images/duplicate.svg"
                        className="img-fluid icon-list"
                        alt="duplicate"
                      />
                      Make a copy
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        setCurrentRowID(row.id);
                        setShowArchiveModal(true);
                      }}
                      id={`archive-model-version-${row.id}`}
                      data-cy="archive-model-version"
                    >
                      <img
                        src="/images/archive.svg"
                        className="img-fluid icon-list"
                        alt="archive"
                      />
                      Archive
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Offcanvas
              show={
                isMobile &&
                expandedVersionID === row.id &&
                row.status !== "Archived" &&
                expanded
                  ? true
                  : false
              }
              onHide={() => {
                row.status !== "Archived"
                  ? handleToggleExpandStatus(row.id)
                  : setExpanded(false);
              }}
              placement="bottom"
              className="offcanvas-choose-action"
            >
              <Offcanvas.Body>
                <p className="choose-action-title bold">
                  {row.version} {row.name}
                </p>
                <p className="choose-action-subtitle">Choose an action</p>
                <ul className="choose-action-ul p-0">
                  {row.status === "Draft" && (
                    <>
                      {row.publishAllow && (
                        <li
                          onClick={() => {
                            setCurrentRowID(row.id);
                            setExpanded(false);
                            setShowSaveAndPublishModal(true);
                          }}
                          id={`publish-model-version-${row.id}`}
                          data-cy="publish-model-version"
                        >
                          <img
                            src="/images/save_publish.svg"
                            className="img-fluid icon-list"
                            alt="save and publish"
                          />
                          Publish
                        </li>
                      )}
                    </>
                  )}
                  {row.status === "Draft" && (
                    <>
                      <li
                        onClick={() => onClickEdit(row.id)}
                        id={`edit-model-version-${row.id}`}
                        data-cy="edit-model-version"
                      >
                        <img
                          src="/images/edit.svg"
                          className="img-fluid icon-list"
                          alt="edit"
                        />
                        Edit
                      </li>
                      <hr />
                      <li
                        onClick={() => {
                          setCurrentRowID(row.id);
                          setExpanded(false);
                          setShowDeleteVersionModal(true);
                        }}
                        id={`delete-model-version-${row.id}`}
                        data-cy="delete-model-version"
                      >
                        <img
                          src="/images/trash.svg"
                          className="img-fluid icon-list"
                          alt="discard"
                        />
                        Delete
                      </li>
                    </>
                  )}
                  {row.status === "Published" && (
                    <>
                      <li
                        onClick={() => onClickDuplicateModelVersion(row.id)}
                        id={`duplicate-model-version-${row.id}`}
                        data-cy="duplicate-model-version"
                      >
                        <img
                          src="/images/duplicate.svg"
                          className="img-fluid icon-list"
                          alt="duplicate"
                        />
                        Make a copy
                      </li>
                      <hr />
                      <li
                        onClick={() => {
                          setCurrentRowID(row.id);
                          setExpanded(false);
                          setShowArchiveModal(true);
                        }}
                        id={`archive-model-version-${row.id}`}
                        data-cy="archive-model-version"
                      >
                        <img
                          src="/images/archive.svg"
                          className="img-fluid icon-list"
                          alt="archive"
                        />
                        Archive
                      </li>
                    </>
                  )}
                </ul>
              </Offcanvas.Body>
            </Offcanvas>
          </>
        );
      },
      width: "16%",
    },
  ];

  const handleAddVersionBtnClick = () => {
    navigate("/models/profile/" + id + "/version/add/");
  };

  useEffect(() => {
    if (modelVersions?.model_file) {
      const filteredArray = Object.values(modelVersions?.model_file)
        .map((model_file) => {
          const details = Object.values(modelVersions?.model_details).find(
            (item) => item.model_file === model_file.id
          );

          const model = Object.values(modelVersions?.model).find(
            (item) => item.model_file === model_file.id
          );

          if (details && model) {
            const parser = new DOMParser();
            const parsedHTML = parser.parseFromString(
              details?.description,
              "text/html"
            );
            const textContent = parsedHTML.body.textContent.trim();
            const arrVersionID = model_file.version_id.split("-");
            const publishAllow = model.is_complete;
            const addedBy = !details.contact_information
              ? "---"
              : details.contact_information;
            const model_user = Object.values(users).find(
              (user) => user.email === addedBy
            );
            const model_user_name =
              user.email === addedBy
                ? "me"
                : model_user?.first_name + " " + model_user?.last_name;

            const row = {
              id: model_file.id,
              file: model_file.file,
              version: !model_file.version_id
                ? "---"
                : arrVersionID[arrVersionID.length - 1],
              description: !textContent ? "---" : textContent,
              accuracy: !model.accuracy ? "---" : model.accuracy + " %",
              date_created: convertDate(
                details.date_created,
                isMobile ? "MM DD, YYYY" : "MM/DD/YYYY"
              ),
              date_created_raw: details.date_created,
              added_by: !details.contact_information
                ? "---"
                : details.contact_information,
              added_by_full_name: model_user_name,
              status: details?.is_archive
                ? "Archived"
                : model?.is_publish
                ? "Published"
                : "Draft",
              publishAllow: publishAllow,
            };
            return row;
          } else {
            return;
          }
        })
        .filter((row) => {
          if (row) {
            return (
              (row.version.toLowerCase().includes(filterText.toLowerCase()) ||
                row.description
                  .toLowerCase()
                  .includes(filterText.toLowerCase()) ||
                row?.accuracy
                  ?.toLowerCase()
                  .includes(filterText.toLowerCase()) ||
                row.date_created
                  .toLowerCase()
                  .includes(filterText.toLowerCase()) ||
                row.added_by.toLowerCase().includes(filterText.toLowerCase()) ||
                row.status.toLowerCase().includes(filterText.toLowerCase())) &&
              (!userAllow
                ? row.status.toLowerCase().includes("published") ||
                  row.status.toLowerCase().includes("archived")
                : row.status.toLowerCase().includes("draft") ||
                  row.status.toLowerCase().includes("archived") ||
                  row.status.toLowerCase().includes("published"))
            );
          }
        })
        .sort((a, b) => {
          switch (sortColumn) {
            case "Accuracy":
              return sortDirectionAccuracy === "asc"
                ? a.accuracy.toLowerCase().localeCompare(b.accuracy)
                : b.accuracy.toLowerCase().localeCompare(a.accuracy);
            case "Status":
              return sortDirectionStatus === "asc"
                ? a.status.toLowerCase().localeCompare(b.status)
                : b.status.toLowerCase().localeCompare(a.status);
            default:
              return compareValues(
                a.date_created_raw,
                b.date_created_raw,
                sortDirectionDateCreated
              );
          }
        });
      setFilteredItems(
        selectedOptionsStatuses?.length > 0
          ? filteredArray.filter((item) =>
              selectedOptionsStatuses.includes(item.status)
            )
          : filteredArray
      );
    }
  }, [
    modelVersions,
    filterText,
    sortDirectionAccuracy,
    sortDirectionDateCreated,
    sortDirectionStatus,
    sortColumn,
    userAllow,
    clearFilter,
    selectedOptionsStatuses,
  ]);

  useEffect(() => {
    // Simulating data fetching
    // Replace this with your actual data fetching logic
    const fetchedData = filteredItems; // Replace this with your fetched data
    setData(fetchedData);
    setTotalItems(fetchedData.length);
    const filteredVersions = Object.values(filteredItems)
      .filter((version) => {
        return (
          version.status !== "Archived" &&
          version?.file !== null &&
          (version?.description
            ?.toLowerCase()
            .includes("sam change detection") ||
            version?.description
              ?.toLowerCase()
              .includes("ethyl object detection") ||
            version?.description
              ?.toLowerCase()
              .includes("sam building delineation"))
        );
      })
      .map((version) => {
        if (
          version?.description?.toLowerCase().includes("sam change detection")
        ) {
          setDemoToInference("change detection");
        } else if (
          version?.description?.toLowerCase().includes("ethyl object detection")
        ) {
          setDemoToInference("object detection");
        } else if (
          version?.description
            ?.toLowerCase()
            .includes("sam building delineation")
        ) {
          setDemoToInference("building delineation");
        }
        return version;
      })
      .sort((a, b) => {
        return b.date_created_raw
          ?.toLowerCase()
          .localeCompare(a.date_created_raw);
      });
    setDemoFilteredVersions(filteredVersions);
  }, [filteredItems]);

  useEffect(() => {
    let slicedData = data;
    if (itemsPerPage !== totalItems) {
      const start = (currentPage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      slicedData = data.slice(start, end);
    }
    setPaginatedData(slicedData);
  }, [currentPage, itemsPerPage, data, totalItems]);

  const handleRowClick = (row) => {
    // Handle row click event and navigate to a different page
    navigate("/models/profile/" + id + "/version/" + row.id);
  };

  const handleSubscribe = () => {
    const modelProfileID = parseInt(id);
    if (subscribed) {
      dispatch(editSubscribeModelProfile(modelProfileID))
        .then((status) => {
          setSubscribed(!subscribed);
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage("/images/subscription-removed-success.svg");
          setRefreshSubscribe(refreshSubscribe + 1);
        })
        .catch((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        });
    } else {
      dispatch(editSubscribeModelProfile(modelProfileID))
        .then((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage("/images/subscription-added-success.svg");
          setSubscribed(!subscribed);
          setRefreshSubscribe(refreshSubscribe + 1);
        })
        .catch((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        });
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      // Target the rdt_Table div and add custom attributes
      const rdtTableDiv = scrollRef.current.querySelector(".rdt_Table");
      if (rdtTableDiv) {
        rdtTableDiv.setAttribute("id", "versions-table");
        rdtTableDiv.setAttribute("data-cy", "versions-table");
      }
      // Target the rdt_TableRow divs and add custom attributes
      const rdtTableRows = scrollRef.current.querySelectorAll(".rdt_TableRow");
      rdtTableRows.forEach((row, index) => {
        row.setAttribute("id", `version-row-item-${paginatedData[index].id}`);
        row.setAttribute("data-cy", "version-row-item");
      });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        if (itemsPerPage <= totalItems && isMobile) {
          if (scrollTop + clientHeight >= scrollHeight) {
            setLoading(true);
            // Simulate a network request
            setTimeout(() => {
              setLoading(false);
              // Load more content or perform any other actions
              setItemsPerPage(itemsPerPage + 25);
            }, 2000);
          }
        }
      }
    };

    const scrollDiv = scrollRef.current;
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [itemsPerPage, totalItems, isMobile]);

  const onClickEditProfileBtn = () => {
    setShowEditModelModal(true);
    setExpandedModelProfileBtnMobile(false);
  };

  const onClickDeleteProfileBtn = () => {
    setShowDeleteModelModal(true);
    setExpandedModelProfileBtnMobile(false);
  };

  return (
    <>
      <SaveAndPublishModal
        showSaveAndPublishModal={showSaveAndPublishModal}
        handleSaveAndPublishModalClose={handleSaveAndPublishModalClose}
        onClickSave={() => onClickSave(true, currentRowID)}
      />
      <DeleteVersionModal
        showDeleteVersionModal={showDeleteVersionModal}
        handleDeleteVersionModalClose={handleDeleteVersionModalClose}
        onClickDeleteVersion={() => onClickDeleteVersion(currentRowID)}
      />
      <ArchiveModal
        modelProfileID={modelProfileID}
        modelFileID={currentRowID}
        setShowToast={props?.setShowToast}
        setToastStatus={props?.setToastStatus}
        setToastImage={props?.setToastImage}
        setRefreshModelVersionsCount={props?.setRefreshModelVersionsCount}
        refreshModelVersionsCount={props?.refreshModelVersionsCount}
        showArchiveModal={showArchiveModal}
        handleArchiveModalClose={handleArchiveModalClose}
      />
      {currentModel[0] && currentModel[0]?.model_created_by === user.email && (
        <DeleteModelModal
          modelID={id}
          showDeleteModelModal={showDeleteModelModal}
          handleDeleteModelModalClose={handleDeleteModelModalClose}
          setShowToast={props?.setShowToast}
          setToastStatus={props?.setToastStatus}
          setToastImage={props?.setToastImage}
        />
      )}
      {userAllow && (
        <EditModelModal
          modelID={id}
          modelName={currentModel[0]?.model_profile_name}
          modelTask={currentModel[0]?.model_profile_task}
          modelDescription={currentModel[0]?.model_profile_description}
          modelGroup={
            currentModel[0]?.model_profile_group ||
            currentModel[0]?.model_profile_group_id
          }
          modelPrimaryIntendedUses={currentModel[0]?.primary_intended_uses}
          modelPrimaryIntendedUsers={currentModel[0]?.primary_intended_user}
          modelOutOfScopeUseCases={currentModel[0]?.out_of_scope_use_cases}
          modelGroups={currentModel[0]?.groups}
          modelInstrumentation={currentModel[0]?.instrumentation}
          modelEnvironment={currentModel[0]?.environment}
          modelPerformanceMeasures={currentModel[0]?.performance_measures}
          modelDecisionThresholds={currentModel[0]?.decision_thresholds}
          modelApproachesToUncertainty={
            currentModel[0]?.approaches_to_uncertainty
          }
          modelData={currentModel[0]?.data}
          modelHumanLife={currentModel[0]?.human_life}
          modelMitigations={currentModel[0]?.mitigations}
          modelRisksAndHarms={currentModel[0]?.risks_and_harms}
          modelUseCases={currentModel[0]?.use_cases}
          modelCreatedBy={currentModel[0]?.model_created_by}
          showEditModelModal={showEditModelModal}
          setShowEditModelModal={setShowEditModelModal}
          handleEditModelModalClose={handleEditModelModalClose}
          setShowToast={props?.setShowToast}
          setToastStatus={props?.setToastStatus}
          setToastImage={props?.setToastImage}
          setRefreshCurrentModelCount={setRefreshCurrentModelCount}
          refreshCurrentModelCount={refreshCurrentModelCount}
        />
      )}
      {currentModel[0] && (
        <ViewModelCreatorModal
          modelCreatedBy={currentModel[0]?.model_created_by}
          showViewModelCreatorModal={showViewModelCreatorModal}
          handleViewModelCreatorModalClose={handleViewModelCreatorModalClose}
          setShowToast={props?.setShowToast}
          setToastStatus={props?.setToastStatus}
          setToastImage={props?.setToastImage}
        />
      )}
      <div className="mw-100 model-profile-container">
        <div className="model-profile-mobile-header">
          <>
            <MobileLeftSidebar
              setUrlToRedirected={props.setUrlToRedirected}
              setShowDiscardChangesModal={props.setShowDiscardChangesModal}
              changesMade={props.changesMade}
            />
            <Breadcrumb id="model-breadcrumbs" data-cy="model-breadcrumbs">
              <Breadcrumb.Item active>Models</Breadcrumb.Item>
              <Breadcrumb.Item
                className="link"
                linkAs={Link}
                linkProps={{ to: "/models" }}
              >
                Model Profiles
              </Breadcrumb.Item>
            </Breadcrumb>
            {isMobile && (
              <>
                <p
                  className="bold model-profile-title"
                  id="model-profile-name"
                  datacy="model-profile-name"
                >
                  {modelName}
                </p>
                {userAllow && (
                  <div>
                    <img
                      src="/images/three_dot_menu.svg"
                      className="mobile-three-dot-icon"
                      alt="menu"
                      onClick={() => setExpandedModelProfileBtnMobile(true)}
                      id="more-btn"
                      data-cy="more-btn"
                    />
                    <Offcanvas
                      show={expandedModelProfileBtnMobile}
                      onHide={() => setExpandedModelProfileBtnMobile(false)}
                      placement="bottom"
                      className="offcanvas-choose-action"
                    >
                      <Offcanvas.Body>
                        <p
                          className="choose-action-title bold"
                          id="model-profile-name"
                          data-cy="model-profile-name"
                        >
                          {modelName}
                        </p>
                        <p className="choose-action-subtitle">
                          Choose an action
                        </p>
                        <ul className="choose-action-ul p-0">
                          <li
                            onClick={onClickEditProfileBtn}
                            id="edit-model"
                            data-cy="edit-model"
                          >
                            <img
                              src="/images/edit.svg"
                              className="img-fluid icon-list"
                              alt="edit"
                            />
                            Edit profile card
                          </li>
                          {(filteredItems?.find(
                            (item) =>
                              item.status === "Published" ||
                              item.status === "Archived"
                          ) === undefined ||
                            filteredItems.length === 0) &&
                            currentModel[0] &&
                            currentModel[0]?.model_created_by ===
                              user.email && (
                              <li
                                onClick={onClickDeleteProfileBtn}
                                id="delete-model"
                                data-cy="delete-model"
                              >
                                <img
                                  src="/images/trash.svg"
                                  className="img-fluid icon-list"
                                  alt="discard"
                                />
                                Delete Profile
                              </li>
                            )}
                        </ul>
                      </Offcanvas.Body>
                    </Offcanvas>
                  </div>
                )}
              </>
            )}
          </>
        </div>

        {!isMobile && (
          <div className="d-flex justify-content-between">
            <p
              className="name-profile"
              id="model-profile-name"
              data-cy="model-profile-name"
            >
              {modelName}
            </p>
            <span className="d-flex align-items-center">
              {userAllow && (
                <Dropdown>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>More</Tooltip>}
                    trigger={["hover", "focus"]}
                  >
                    <Dropdown.Toggle
                      variant="secondary"
                      className="three-dot-dropdown"
                      id="more-btn"
                      data-cy="more-btn"
                    >
                      <img
                        src="/images/three_dot_menu.svg"
                        className="img-fluid"
                        alt="menu"
                      />
                    </Dropdown.Toggle>
                  </OverlayTrigger>

                  <Dropdown.Menu className="three-dot-dropdown-menu">
                    <Dropdown.Item
                      onClick={() => setShowEditModelModal(true)}
                      id="edit-model"
                      data-cy="edit-model"
                    >
                      <img
                        src="/images/edit.svg"
                        className="img-fluid icon-list"
                        alt="edit"
                      />
                      Edit profile card
                    </Dropdown.Item>
                    {(filteredItems?.find(
                      (item) =>
                        item.status === "Published" ||
                        item.status === "Archived"
                    ) === undefined ||
                      filteredItems.length === 0) &&
                      currentModel[0] &&
                      currentModel[0]?.model_created_by === user.email && (
                        <Dropdown.Item
                          onClick={() => setShowDeleteModelModal(true)}
                          id="delete-model"
                          data-cy="delete-model"
                        >
                          <img
                            src="/images/trash.svg"
                            className="img-fluid icon-list"
                            alt="discard"
                          />
                          Delete profile
                        </Dropdown.Item>
                      )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </span>
          </div>
        )}
        {!isMobile && (
          <div className="d-flex labels">
            <div>
              <span>{modelTask}</span>
            </div>
            <div>
              <span>{modelGroup}</span>
            </div>
            <div
              className="model-creator"
              onClick={() => setShowViewModelCreatorModal(true)}
              id="owner"
              data-cy="owner"
            >
              <span>
                <img
                  src="/images/user_list.svg"
                  className="img-fluid icon-list"
                  alt="user"
                />
                {modelCreatedBy}
              </span>
            </div>
            <div>
              <span>{modelDateCreated}</span>
            </div>
            <div>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>{subscribed ? "Unsubscribe" : "Subscribe"}</Tooltip>
                }
                trigger={["hover", "focus"]}
              >
                <Button
                  variant="outline-light"
                  className="input-btn"
                  onClick={handleSubscribe}
                  name="subscribe"
                  id="subscribe"
                  data-cy="subscribe"
                >
                  <img
                    src={
                      subscribed
                        ? "/images/subscribed.svg"
                        : "/images/not-subscribed.svg"
                    }
                    className="heart-15p"
                    alt="subscribe"
                  />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>No. of subscriptions</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <span className="input-counter">{subscribedCount}</span>
              </OverlayTrigger>
            </div>
          </div>
        )}
        <Tabs
          defaultActiveKey={
            tab === "2"
              ? "model-files-and-versions"
              : tab === "3"
              ? "model-demo"
              : "model-profile-card"
          }
          id="model-tabs"
          className="model-profile-tabs pt-85-px"
        >
          <Tab
            eventKey="model-profile-card"
            tabClassName="tabs"
            title="Profile card"
            tabAttrs={{ "data-cy": "profileCardTab" }}
          >
            {isMobile && (
              <div>
                <div
                  className="bold mobile-model-profile-name mb-2"
                  id="model-profile-name"
                  data-cy="model-profile-name"
                >
                  {modelName}
                </div>

                <div className="mb-2">
                  <div className="subscribe-div">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          {subscribed ? "Unsubscribe" : "Subscribe"}
                        </Tooltip>
                      }
                      trigger={["hover", "focus"]}
                    >
                      <Button
                        variant="outline-light"
                        className="input-btn"
                        onClick={handleSubscribe}
                        name="subscribe"
                        id="subscribe"
                        data-cy="subscribe"
                      >
                        <img
                          src={
                            subscribed
                              ? "/images/subscribed.svg"
                              : "/images/not-subscribed.svg"
                          }
                          className="heart-10p"
                          alt="subscribe"
                        />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>No. of subscriptions</Tooltip>}
                      trigger={["hover", "focus"]}
                    >
                      <span className="input-counter">{subscribedCount}</span>
                    </OverlayTrigger>
                  </div>
                </div>
                <div>
                  <div className="bold">Task</div>
                  <p>{modelTask}</p>
                </div>
                <div onClick={() => setShowViewModelCreatorModal(true)}>
                  <div className="bold">Created by</div>
                  <div
                    className="model-creator-span p-1"
                    id="owner"
                    data-cy="owner"
                  >
                    <img
                      src="/images/user_list.svg"
                      className="icon-list account-10p"
                      alt="user"
                    />
                    {modelCreatedBy}
                  </div>
                </div>
                <div className="mt-2">
                  <div className="bold">Created on</div>
                  <p>{modelDateCreated}</p>
                </div>
                <div>
                  <div className="bold">Assigned to</div>
                  <p>{modelGroup}</p>
                </div>
              </div>
            )}
            <div className="bold mt-3">Description</div>
            <div className="ql-snow">
              <div
                className="ql-editor px-0 pt-1"
                dangerouslySetInnerHTML={{
                  __html: modelDescription,
                }}
              ></div>
            </div>
            <p className="bold">Intended use and limitations</p>
            <ul>
              <li>
                <span className="bold">Primary intended uses</span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: modelPrimaryIntendedUses,
                    }}
                  ></div>
                </div>
              </li>
              <li>
                <span className="bold">Primary intended users</span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: modelPrimaryIntendedUsers,
                    }}
                  ></div>
                </div>
              </li>
              <li>
                <span className="bold">Out-of-scope use cases</span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: modelOutOfScopeUseCases,
                    }}
                  ></div>
                </div>
              </li>
            </ul>
            <p className="bold">Factors</p>
            <ul>
              <li>
                <span className="bold">Groups</span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html:
                        modelGroups === "<p><br></p>" || !modelGroups
                          ? "--"
                          : modelGroups,
                    }}
                  ></div>
                </div>
              </li>
              <li>
                <span className="bold">Instrumentation</span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: modelInstrumentation,
                    }}
                  ></div>
                </div>
              </li>
              <li>
                <span className="bold">Environment</span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: modelEnvironment,
                    }}
                  ></div>
                </div>
              </li>
            </ul>
            <p className="bold">Metrics</p>
            <ul>
              <li>
                <span className="bold">Performance measures</span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: modelPerformanceMeasures,
                    }}
                  ></div>
                </div>
              </li>
              <li>
                <span className="bold">Decision thresholds</span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: modelDecisionThresholds,
                    }}
                  ></div>
                </div>
              </li>
              <li>
                <span className="bold">
                  Approaches to uncertainty and variability
                </span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: modelApproachesToUncertainty,
                    }}
                  ></div>
                </div>
              </li>
            </ul>
            <p className="bold">Ethical considerations and biases</p>
            <ul>
              <li>
                <span className="bold">Data</span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: modelData,
                    }}
                  ></div>
                </div>
              </li>
              <li>
                <span className="bold">Human life</span>
                <div className="pt-1 pb-2">{modelHumanLife ? "Yes" : "No"}</div>
              </li>
              <li>
                <span className="bold">Mitigations</span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: modelMitigations,
                    }}
                  ></div>
                </div>
              </li>
              <li>
                <span className="bold">Risks and harms</span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: modelRisksAndHarms,
                    }}
                  ></div>
                </div>
              </li>
              <li>
                <span className="bold">Use cases</span>
                <div className="ql-snow">
                  <div
                    className="ql-editor px-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: modelUseCases,
                    }}
                  ></div>
                </div>
              </li>
            </ul>

            {modelsAccuracy?.model_details_publish?.length +
              modelsAccuracy?.model_details_archive?.length >
              1 && (
              <>
                <p className="bold">Model lineage</p>
                <div>
                  <LinearAreaChart
                    modelsAccuracy={modelsAccuracy}
                    users={users}
                    user={user}
                  />
                </div>
              </>
            )}
          </Tab>
          <Tab
            eventKey="model-files-and-versions"
            tabClassName="tabs"
            title="Versions"
            tabAttrs={{ "data-cy": "versionsTab" }}
          >
            <div ref={scrollRef} className="data-table-div">
              <p className="bold mt-3 ms-3">Versions</p>
              <DataTable
                className="model-versions-datatable"
                // columns={userAllow ? columns1 : columns2}
                columns={columns1}
                data={paginatedData}
                pagination={false}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                onRowClicked={handleRowClick}
                noTableHead={isMobile}
              />
              <div className="mb-4 d-flex justify-content-center">
                {loading && (
                  <Spinner
                    className="spinner"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </div>
              {!isMobile && (
                <CustomPagination
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onChange={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              )}
            </div>
          </Tab>
          {demoFilteredVersions.length > 0 && (
            <Tab
              eventKey="model-demo"
              tabClassName="tabs"
              title="Demo"
              tabAttrs={{ "data-cy": "demoTab" }}
            >
              <ModelDemo
                modelVersions={demoFilteredVersions}
                modelName={modelName}
                modelTask={modelTask}
                setDisableLayout={props?.setDisableLayout}
                demoToInference={demoToInference}
                showCancelDemoModal={props.showCancelDemoModal}
                setShowCancelDemoModal={props.setShowCancelDemoModal}
                handleCancelDemoModalClose={props.handleCancelDemoModalClose}
              />
            </Tab>
          )}
        </Tabs>
      </div>
    </>
  );
};

export default ModelProfile;
