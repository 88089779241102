import {
  downloadModelVersion,
  inferenceObjectDetectionModel,
} from "actions/model";
import ModelDemoStartModal from "components/models/ModelDemoStartModal";
import { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CancelToken } from "axios";
import JSZip from "jszip";
import {
  TransformStreamImplementation,
  TransformerImplementation,
} from "transformers";
import CancelDemoModal from "components/models/CancelDemoModal";
import { IP_ADDRESS, IP_ADDRESS_2 } from "common/constants";

const DemoObjectDetection = (props) => {
  const dispatch = useDispatch();
  const [modelSelectedFileImageInput, setModelSelectedFileImageInput] =
    useState("");
  const [modelSelectedFileVideoInput, setModelSelectedFileVideoInput] =
    useState("");
  const [inputType, setInputType] = useState("image");
  const [imageSize, setImageSize] = useState(640);
  const [confidence, setConfidence] = useState(0.2);
  const [iou, setIoU] = useState(0.7);
  const [demoProgressPercentage, setDemoProgressPercentage] = useState(0);
  const [counter, setCounter] = useState(0);
  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [startDemoDisabled, setStartDemoDisabled] = useState(true);
  const [demoStarted, setDemoStarted] = useState(false);
  const [computationTime, setComputationTime] = useState(0);
  const [showModelDemoStartModal, setShowModelDemoStartModal] = useState(false);
  const [versions, setVersions] = useState([]);
  const [modelFileID, setModelFileID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [info, setInfo] = useState("");

  const [shouldWarn, setShouldWarn] = useState(false);

  const fileInputRefImageInput = useRef(null);
  const [outputImage, setOutputImage] = useState("");
  const [outputVideo, setOutputVideo] = useState("");
  const [imageInput, setImageInput] = useState("");
  const [videoInput, setVideoInput] = useState("");
  const [userCancelled, setUserCancelled] = useState(false);

  const imageRef = useRef(null);
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const outputRef = useRef(null);
  const cancelAction1 = useRef(null);
  const cancelAction2 = useRef(null);

  const lineRef = useRef(null);

  const circle1Ref = useRef(null);
  const [circle1Pos, setCircle1Pos] = useState({ x: 0, y: 0 });
  const [circle1Dragging, setCircle1Dragging] = useState(false);

  const circle2Ref = useRef(null);
  const [circle2Pos, setCircle2Pos] = useState({ x: 0, y: 0 });
  const [circle2Dragging, setCircle2Dragging] = useState(false);

  const [circlesVidPos, setCirclesVidPos] = useState({
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });

  const handleModelDemoStartModalClose = () => {
    setShowModelDemoStartModal(false);
  };

  useEffect(() => {
    const filteredVersions = props?.modelVersions;
    if (filteredVersions?.length > 0) {
      const filteredVersionsPublished = filteredVersions.filter((version) => {
        return version.status === "Published";
      });
      const filteredVersionsDraft = filteredVersions.filter((version) => {
        return version.status === "Draft";
      });
      setModelFileID(
        filteredVersionsPublished.length > 0
          ? filteredVersionsPublished[0]?.id
          : filteredVersionsDraft[0]?.id
      );
    }
    setVersions(filteredVersions);
  }, [props?.modelVersions]);

  const validate = (name, value) => {
    if (
      name === "modelSelectedFileImageInput" ||
      name === "modelSelectedFileVideoInput"
    ) {
      const extensionName = value?.name?.split(".").at(-1).toLowerCase();
      if (!value) {
        setCounter(counter + 1);
        setIsValid(Object.assign(isValid, { [name]: false }));
        const validationMessage =
          name === "modelSelectedFileImageInput"
            ? "Choose your image file"
            : "Choose your video file";
        setError(
          Object.assign(error, {
            [name]: validationMessage,
          })
        );
        if (name === "modelSelectedFileImageInput") {
          setModelSelectedFileImageInput("");
        } else {
          setModelSelectedFileVideoInput("");
          setOutputVideo("");
          if (outputRef.current) {
            const oldUrl = outputRef.current.src;
            URL.revokeObjectURL(oldUrl);
          }
        }
        return;
      }

      if (value?.size > 25000000) {
        setCounter(counter + 1);
        setIsValid(Object.assign(isValid, { [name]: false }));
        setError(
          Object.assign(error, {
            [name]: "Your file is larger than 25 MB. Try another file.",
          })
        );
        if (name === "modelSelectedFileImageInput") {
          setModelSelectedFileImageInput("");
        } else {
          setModelSelectedFileVideoInput("");
          if (fileInputRefVideoInput.current) {
            fileInputRefVideoInput.current.value = "";
          }
          setVideoInput("");
          if (videoRef.current) {
            const oldUrl = videoRef.current.src;
            URL.revokeObjectURL(oldUrl);
          }
        }
        return;
      }

      if (name === "modelSelectedFileImageInput") {
        if (
          extensionName !== "jpeg" &&
          extensionName !== "jpg" &&
          extensionName !== "png" &&
          extensionName !== "gif" &&
          extensionName !== "bmp" &&
          extensionName !== "tiff" &&
          extensionName !== "raw" &&
          extensionName !== "webp" &&
          extensionName !== "svg" &&
          extensionName !== "heif" &&
          extensionName !== "heic" &&
          extensionName !== "dicom" &&
          extensionName !== "pgm" &&
          extensionName !== "ppm" &&
          extensionName !== "png" &&
          extensionName !== "pbm" &&
          extensionName !== "hdr" &&
          extensionName !== "exr"
        ) {
          setCounter(counter + 1);
          setIsValid(Object.assign(isValid, { [name]: false }));
          setError(
            Object.assign(error, {
              [name]: "File type not supported",
            })
          );
          setModelSelectedFileImageInput("");
          return;
        }
      } else {
        if (
          extensionName !== "mp4" &&
          extensionName !== "avi" &&
          extensionName !== "mov" &&
          extensionName !== "mkv" &&
          extensionName !== "wmv" &&
          extensionName !== "flv" &&
          extensionName !== "webm" &&
          extensionName !== "mpeg" &&
          extensionName !== "ts" &&
          extensionName !== "3gp" &&
          extensionName !== "m4v" &&
          extensionName !== "vob" &&
          extensionName !== "asf" &&
          extensionName !== "mpg" &&
          extensionName !== "rm"
        ) {
          setCounter(counter + 1);
          setIsValid(Object.assign(isValid, { [name]: false }));
          setError(
            Object.assign(error, {
              [name]: "File type not supported",
            })
          );
          setModelSelectedFileVideoInput("");
          return;
        }
      }
      if (
        name === "modelSelectedFileImageInput" &&
        Array.isArray(modelSelectedFileImageInput) &&
        modelSelectedFileImageInput?.find((file) => file.name === value.name)
      ) {
        setModelSelectedFileImageInput(modelSelectedFileImageInput);
        return;
      }
      if (
        name === "modelSelectedFileVideoInput" &&
        Array.isArray(modelSelectedFileVideoInput) &&
        modelSelectedFileVideoInput?.find((file) => file.name === value.name)
      ) {
        setModelSelectedFileVideoInput(modelSelectedFileVideoInput);
        return;
      }
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  const handleFileChangeImageInput = (event) => {
    const file = event.target.files[0];
    if (file) {
      setModelSelectedFileImageInput(event.target.files[0]);
      const extensionName = file?.name?.split(".").at(-1).toLowerCase();
      if (
        extensionName === "jpeg" ||
        extensionName === "jpg" ||
        extensionName === "png" ||
        extensionName === "gif" ||
        extensionName === "bmp" ||
        extensionName === "tiff" ||
        extensionName === "raw" ||
        extensionName === "webp" ||
        extensionName === "svg" ||
        extensionName === "heif" ||
        extensionName === "heic" ||
        extensionName === "dicom" ||
        extensionName === "pgm" ||
        extensionName === "ppm" ||
        extensionName === "png" ||
        extensionName === "pbm" ||
        extensionName === "hdr" ||
        extensionName === "exr"
      ) {
        const url = URL.createObjectURL(file);
        setImageInput(url);
      }
      validate("modelSelectedFileImageInput", file);
    }
    setCounter(counter + 1);
  };

  const handleDragOverImageInput = (event) => {
    event.preventDefault();
  };

  const handleDropImageInput = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && !imageInput) {
      setModelSelectedFileImageInput(file);
      const extensionName = file?.name?.split(".").at(-1).toLowerCase();
      if (
        extensionName === "jpeg" ||
        extensionName === "jpg" ||
        extensionName === "png" ||
        extensionName === "gif" ||
        extensionName === "bmp" ||
        extensionName === "tiff" ||
        extensionName === "raw" ||
        extensionName === "webp" ||
        extensionName === "svg" ||
        extensionName === "heif" ||
        extensionName === "heic" ||
        extensionName === "dicom" ||
        extensionName === "pgm" ||
        extensionName === "ppm" ||
        extensionName === "png" ||
        extensionName === "pbm" ||
        extensionName === "hdr" ||
        extensionName === "exr"
      ) {
        const url = URL.createObjectURL(file);
        setImageInput(url);
      }
      validate("modelSelectedFileImageInput", file);
      setCounter(counter + 1);
    }
  };

  const handleClickImageInput = () => {
    fileInputRefImageInput.current.click();
  };

  const fileInputRefVideoInput = useRef(null);

  const handleFileChangeVideoInput = (event) => {
    const file = event.target.files[0];
    if (file) {
      setModelSelectedFileVideoInput(file);
      const extensionName = file?.name?.split(".").at(-1).toLowerCase();
      if (
        extensionName === "mp4" ||
        extensionName === "avi" ||
        extensionName === "mov" ||
        extensionName === "mkv" ||
        extensionName === "wmv" ||
        extensionName === "flv" ||
        extensionName === "webm" ||
        extensionName === "mpeg" ||
        extensionName === "ts" ||
        extensionName === "3gp" ||
        extensionName === "m4v" ||
        extensionName === "vob" ||
        extensionName === "asf" ||
        extensionName === "mpg" ||
        extensionName === "rm"
      ) {
        const url = URL.createObjectURL(file);
        setVideoInput(url);
      }
      validate("modelSelectedFileVideoInput", file);
      setCounter(counter + 1);
    }
  };

  const handleDragOverVideoInput = (event) => {
    event.preventDefault();
  };

  const handleDropVideoInput = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && !videoInput) {
      setModelSelectedFileVideoInput(file);
      const extensionName = file?.name?.split(".").at(-1).toLowerCase();
      if (
        extensionName === "mp4" ||
        extensionName === "avi" ||
        extensionName === "mov" ||
        extensionName === "mkv" ||
        extensionName === "wmv" ||
        extensionName === "flv" ||
        extensionName === "webm" ||
        extensionName === "mpeg" ||
        extensionName === "ts" ||
        extensionName === "3gp" ||
        extensionName === "m4v" ||
        extensionName === "vob" ||
        extensionName === "asf" ||
        extensionName === "mpg" ||
        extensionName === "rm"
      ) {
        const url = URL.createObjectURL(file);
        setVideoInput(url);
      }
      validate("modelSelectedFileVideoInput", file);
      setCounter(counter + 1);
    }
  };

  const handleClickVideoInput = () => {
    fileInputRefVideoInput.current.click();
  };

  const handleRemoveImageInputBtn = (e) => {
    validate("modelSelectedFileImageInput", "");
    fileInputRefImageInput.current.value = "";
    setImageInput("");
    setModelSelectedFileImageInput("");
    setStartDemoDisabled(true);
    e.stopPropagation();
  };

  const handleRemoveVideoInputBtn = (e) => {
    validate("modelSelectedFileVideoInput", "");
    fileInputRefVideoInput.current.value = "";
    setVideoInput("");
    setModelSelectedFileVideoInput("");
    setStartDemoDisabled(true);
    e.stopPropagation();
  };

  const handleClearBtn = () => {
    setCounter(counter + 1);
    setStartDemoDisabled(true);
    setImageInput("");
    setVideoInput("");
    setOutputImage("");
    setOutputVideo("");
    setDemoProgressPercentage(0);
    setErrorMessage("");
    setDemoStarted(false);
    setComputationTime(0);
    setInfo("");
    setError({});
    setIsValid({});
    if (outputRef.current) {
      outputRef.current.src = "";
    }
    if (imageRef.current) {
      imageRef.current.src = "";
    }
    if (videoRef.current) {
      videoRef.current.src = "";
    }
    if (fileInputRefImageInput?.current) {
      fileInputRefImageInput.current.value = "";
    }

    if (fileInputRefVideoInput?.current) {
      fileInputRefVideoInput.current.value = "";
    }
  };

  const handleResetCoordinatesBtn = () => {
    if (videoRef.current) {
      const inputVideoRect = videoRef.current.getBoundingClientRect();
      if (inputVideoRect) {
        const cx1 = inputVideoRect.width * 0.1;
        const cy1 = inputVideoRect.height * 0.5;
        const cx2 = inputVideoRect.width * 0.9;
        const cy2 = inputVideoRect.height * 0.5;

        const x1 =
          (Math.max(0, cx1) * videoRef.current.videoWidth) /
          inputVideoRect.width;
        const y1 =
          (Math.max(0, cy1) * videoRef.current.videoHeight) /
          inputVideoRect.height;
        const x2 =
          (Math.min(inputVideoRect.right, cx2) * videoRef.current.videoWidth) /
          inputVideoRect.width;
        const y2 =
          (Math.min(inputVideoRect.bottom, cy2) *
            videoRef.current.videoHeight) /
          inputVideoRect.height;
        setCirclesVidPos({ x1: x1, y1: y1, x2: x2, y2: y2 });
        setCircle1Pos({ x: cx1, y: cy1 });
        setCircle2Pos({ x: cx2, y: cy2 });

        lineRef.current.style.width = inputVideoRect.width * 0.82 + "px";
      }
    }
  };

  useEffect(() => {
    // console.log(circlesVidPos);
    // console.log(videoRef?.current?.src);
    // console.log(videoRef?.current?.videoWidth);
    // console.log(videoRef?.current?.videoHeight);
    // console.log(videoRef);
  }, [circlesVidPos, videoRef]);

  // Effect to continuously check the condition and compute
  useEffect(() => {
    // Define a function to check and compute
    // function checkAndCompute() {
    //   setComputationTime((prevTime) => prevTime + 1); // Increment computation time
    //   // Check the condition
    //   if (demoStarted) {
    //     setTimeout(checkAndCompute, 1000); // Adjust the delay as needed
    //   }
    // }

    let timer = setTimeout(() => {
      setComputationTime((prevTime) => prevTime + 1); // Increment computation time
    }, 1000);
    if (!demoStarted || userCancelled) {
      props?.setDisableLayout(false);
      clearTimeout(timer);
    }

    // // Cleanup function (optional)
    // return () => {
    //   // Perform cleanup tasks if necessary
    //   clearTimeout(timer);
    // };
  }, [demoStarted, computationTime, userCancelled]); // Empty dependency array ensures this effect runs only once on component mount

  useEffect(() => {
    if (demoStarted) {
      const interval = setInterval(() => {
        if (demoProgressPercentage < 100) {
          setDemoProgressPercentage((prevProgress) => prevProgress + 1);
        } else {
          clearInterval(interval);
        }
      }, 50); // Adjust the interval for smoother or faster progress

      return () => clearInterval(interval);
    } else {
      setDemoProgressPercentage(0);
    }
  }, [demoStarted]);

  const handleStartDemoBtn = () => {
    setComputationTime(0);
    setDemoStarted(true);
    setStartDemoDisabled(true);
    setOutputImage("");
    setOutputVideo("");
    setErrorMessage("");
    props?.setDisableLayout(true);
    setShouldWarn(true);
    dispatch(
      downloadModelVersion(
        modelFileID,
        (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // console.log(percentage);
        },
        new CancelToken((cancel) => (cancelAction1.current = cancel))
      )
    )
      .then((content) => {
        if (!userCancelled) {
          const zipFile = new File([content], "output.zip", {
            type: content.type,
          });
          if (zipFile) {
            const reader = new FileReader();
            reader.onload = async (e) => {
              const arrayBuffer = e.target.result;
              try {
                const zip = await JSZip.loadAsync(arrayBuffer);
                await extractZipFile(zip);
              } catch (error) {
                console.error("Failed to load ZIP file:", error);
              }
            };
            reader.readAsArrayBuffer(zipFile);
          }
        } else {
          resetValues();
        }
      })
      .catch((err) => {
        console.log(err);
        resetValues();
      });
  };

  const extractZipFile = async (zip) => {
    if (!userCancelled) {
      for (const fileName of Object.keys(zip.files)) {
        const file = zip.files[fileName];
        if (!file.dir && fileName.endsWith(".zip")) {
          try {
            const nestedZipArrayBuffer = await file.async("arraybuffer");
            const nestedZip = await JSZip.loadAsync(nestedZipArrayBuffer);
            await extractNestedZipFile(nestedZip);
          } catch (error) {
            console.error("Failed to load nested ZIP file:", error);
            resetValues();
          }
        }
      }
    } else {
      resetValues();
    }
  };

  const extractNestedZipFile = async (zip) => {
    if (!userCancelled) {
      for (const fileName of Object.keys(zip.files)) {
        const file = zip.files[fileName];
        if (
          !file.dir &&
          (fileName.endsWith(".pt") || fileName.endsWith(".pth"))
        ) {
          try {
            const content = await file.async("arraybuffer");
            const modelFile = new File([content], "model.pt", {
              type: "application/octet-stream",
            });
            startInference(modelFile);
          } catch (error) {
            console.error("Failed to extract .pt file:", error);
            resetValues();
          }
        } else {
          setErrorMessage("Internal Server Error");
          resetValues();
        }
      }
    } else {
      resetValues();
    }
  };

  const startInference = async (modelFile) => {
    dispatch(
      inferenceObjectDetectionModel(
        inputType === "image"
          ? modelSelectedFileImageInput
          : modelSelectedFileVideoInput,
        modelFile,
        confidence,
        iou,
        imageSize,
        [
          circlesVidPos.x1,
          circlesVidPos.y1,
          circlesVidPos.x2,
          circlesVidPos.y2,
        ],
        new CancelToken((cancel) => (cancelAction2.current = cancel))
      )
    )
      .then(async (response) => {
        console.log(response);
        const jsonResponse = response;
        if (inputType === "video") {
          const response = await fetch(IP_ADDRESS_2 + jsonResponse.result);

          if (!response.ok) {
            // Handle error
            console.error("Failed to fetch file");
            return;
          }

          const blob = await response.blob();
          const urlBlob = URL.createObjectURL(blob);
          setOutputVideo(urlBlob);
        } else {
          setOutputImage(IP_ADDRESS_2 + jsonResponse.result);
        }
        setInfo(JSON.parse(jsonResponse.info));
      })
      .catch((err) => {
        setErrorMessage(err);
      })
      .finally(() => {
        resetValues();
      });
  };

  const resetValues = () => {
    props?.setDisableLayout(false);
    setDemoStarted(false);
    // setDemoProgressPercentage(0);
    setStartDemoDisabled(true);
    setShouldWarn(false);
    setUserCancelled(false);
  };

  const handleDownloadOutputBtn = async (e) => {
    const url = inputType === "image" ? outputImage : outputVideo; // Replace with your file URL
    const response = await fetch(url);

    if (!response.ok) {
      // Handle error here
      console.error("Failed to download file");
      return;
    }

    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = urlBlob;
    a.download = inputType === "image" ? "file.png" : "file.mp4"; // Specify the desired file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(urlBlob);
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  useEffect(() => {
    if (
      (imageInput !== "" && modelSelectedFileImageInput !== "") ||
      (videoInput !== "" &&
        modelSelectedFileVideoInput !== "" &&
        Object.keys(error).length === 0)
    ) {
      setStartDemoDisabled(false);
    } else {
      setStartDemoDisabled(true);
    }
  }, [
    modelSelectedFileImageInput,
    modelSelectedFileVideoInput,
    imageInput,
    videoInput,
    error,
  ]);

  const onChangeVersion = (e) => {
    setModelFileID(e.target.value);
  };

  const onChangeInputType = (e) => {
    setInputType(e.target.value);
    handleClearBtn();
    validate("demoInputType", e.target.value);
  };

  const onChangeImageSize = (e) => {
    setImageSize(e.target.value);
  };

  const onChangeConfidence = (e) => {
    setConfidence(e.target.value);
  };

  const onChangeIoU = (e) => {
    setIoU(e.target.value);
  };

  // stop the dragging of circle 1 and circle 2 on mouse up
  useEffect(() => {
    const onMouseUp = () => {
      setCircle1Dragging(false);
      setCircle2Dragging(false);
    };

    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mouseup", onMouseUp);
    };
  });

  useEffect(() => {
    if (lineRef.current && circle1Ref.current && circle2Ref.current) {
      const x1 = circle1Pos.x;
      const y1 = circle1Pos.y;
      const x2 = circle2Pos.x;
      const y2 = circle2Pos.y;

      const circle1Rect = circle1Ref.current.getBoundingClientRect();
      const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
      const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);

      lineRef.current.style.width = length + "px";
      lineRef.current.style.left = x1 + circle1Rect.width / 2 + "px";
      lineRef.current.style.top = y1 + circle1Rect.height / 2 + "px";
      lineRef.current.style.transform = "rotate(" + angle + "deg)";
      lineRef.current.style.transformOrigin = "0 0"; // Ensure rotation starts from the correct point
    }
  }, [
    lineRef,
    circle1Ref,
    circle2Ref,
    circle1Pos,
    circle2Pos,
    videoRef.current,
  ]);

  // const inputFileChangedHandler = (file) => {
  //   onSelectedFileChanged(file);
  // };

  const [offset1, setOffset1] = useState({ x: 0, y: 0 });
  const [offset2, setOffset2] = useState({ x: 0, y: 0 });

  // Handle touch start
  const handleTouchStart = (circle, e) => {
    const touch = e.touches[0];
    if (circle === "circle1") {
      setOffset1({
        x: touch.clientX - circle1Pos.x,
        y: touch.clientY - circle1Pos.y,
      });
      setCircle1Dragging(true);
    } else {
      setOffset2({
        x: touch.clientX - circle2Pos.x,
        y: touch.clientY - circle2Pos.y,
      });
      setCircle2Dragging(true);
    }
  };

  const handleMouseMove = (e) => {
    const divRect = e.currentTarget.getBoundingClientRect();
    if (circle1Ref.current && circle1Dragging) {
      const circle1Rect = circle1Ref.current.getBoundingClientRect();
      const newX =
        Math.max(0, e.clientX - divRect.left) - circle1Rect.width / 2;
      const newY =
        Math.min(divRect.bottom, e.clientY - divRect.top) -
        circle1Rect.height / 2;
      setCircle1Pos({ x: newX, y: newY });
    }

    if (circle2Ref.current && circle2Dragging) {
      const circle2Rect = circle2Ref.current.getBoundingClientRect();
      const newX =
        Math.max(0, e.clientX - divRect.left) - circle2Rect.width / 2;
      const newY =
        Math.min(divRect.bottom, e.clientY - divRect.top) -
        circle2Rect.height / 2;
      setCircle2Pos({ x: newX, y: newY });
    }
    const inputVideoRect = videoRef.current.getBoundingClientRect();

    const x1 =
      (Math.max(0, circle1Pos.x) * videoRef.current.videoWidth) /
      inputVideoRect.width;
    const y1 =
      (Math.max(0, circle1Pos.y) * videoRef.current.videoHeight) /
      inputVideoRect.height;
    const x2 =
      (Math.min(inputVideoRect.right, circle2Pos.x) *
        videoRef.current.videoWidth) /
      inputVideoRect.width;
    const y2 =
      (Math.min(inputVideoRect.bottom, circle2Pos.y) *
        videoRef.current.videoHeight) /
      inputVideoRect.height;
    setCirclesVidPos({ x1: x1, y1: y1, x2: x2, y2: y2 });
  };

  useEffect(() => {
    handleResetCoordinatesBtn();
  }, [videoRef?.current, videoInput]);

  useEffect(() => {
    if (userCancelled) {
      setErrorMessage("User cancelled the action");
    }
  }, [userCancelled]);

  const cancelInferencing = () => {
    if (cancelAction1.current) {
      setUserCancelled(true);
      console.log("User has canceled the action 1");
      cancelAction1.current("User has canceled the action.");
    }
    if (cancelAction2.current) {
      setUserCancelled(true);
      console.log("User has canceled the action 2");
      cancelAction2.current("User has canceled the action.");
    }
  };

  const handleSampleInput = () => {
    handleClearBtn();
    const input =
      inputType === "image"
        ? "/inferencing-inputs/object-detection/image-input.png"
        : "/inferencing-inputs/object-detection/11-sec-vid.mp4";

    setImageSize(640);
    setConfidence(0.2);
    setIoU(0.7);
    // inputType === "image" ? setImageInput(input) : setVideoInput(input);
    simulateFileInput(input);
  };

  const simulateFileInput = async (input) => {
    const fetched = await fetch(`${process.env.PUBLIC_URL + input}`);
    const blob = await fetched.blob();
    const simulatedFile = new File(
      [blob],
      inputType === "image" ? "image-input.png" : "11-sec-vid.mp4",
      {
        type: blob.type,
      }
    );
    // Create a new DataTransfer object and add the file
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(simulatedFile);

    if (inputType === "image") {
      // Set the files property of the input element
      if (fileInputRefImageInput.current) {
        fileInputRefImageInput.current.files = dataTransfer.files;
        setModelSelectedFileImageInput(fileInputRefImageInput.current.files[0]);

        // Trigger the change event
        const event = new Event("change", { bubbles: true });
        fileInputRefImageInput.current.dispatchEvent(event);
      }
    } else {
      if (fileInputRefVideoInput.current) {
        fileInputRefVideoInput.current.files = dataTransfer.files;
        setModelSelectedFileVideoInput(fileInputRefVideoInput.current.files[0]);

        // Trigger the change event
        const event = new Event("change", { bubbles: true });
        fileInputRefVideoInput.current.dispatchEvent(event);
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      props?.setShowCancelDemoModal(true);
      event.preventDefault();
      event.returnValue = ""; // Standard for most browsers
    };

    if (shouldWarn) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("unload", cancelInferencing);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", cancelInferencing);
    };
  }, [shouldWarn]);

  const onClickCancelDemo = () => {
    cancelInferencing();
    props?.handleCancelDemoModalClose();
  };

  useEffect(() => {
    const preventScroll = (e) => {
      if (videoRef.current) {
        const videoDivRect = videoRef.current.getBoundingClientRect();

        if (circle1Dragging) {
          const circle1Rect = circle1Ref.current.getBoundingClientRect();
          const touch = e.touches[0];
          // let clientX = touch.clientX - offset2.x;
          // let clientY = touch.clientY - offset2.y;
          const newX =
            Math.max(0, touch.clientX - videoDivRect.left) -
            circle1Rect.width / 2;

          let computeY = Math.min(
            videoDivRect.bottom,
            touch.clientY - videoDivRect.top
          );

          if (computeY < 0) {
            computeY = 0;
          }

          if (computeY > videoDivRect.height) {
            computeY = videoDivRect.height;
          }

          const newY = computeY - circle1Rect.height / 2;
          setCircle1Pos({ x: newX, y: newY });

          e.preventDefault();
        }

        if (circle2Dragging) {
          const circle2Rect = circle2Ref.current.getBoundingClientRect();
          const touch = e.touches[0];
          // let clientX = touch.clientX - offset2.x;
          // let clientY = touch.clientY - offset2.y;

          var computeX = Math.max(0, touch.clientX - videoDivRect.left);

          if (computeX < 0) {
            computeX = 0;
          }

          if (computeX > videoDivRect.width) {
            computeX = videoDivRect.width;
          }

          const newX = computeX - circle2Rect.width / 2;

          var computeY = Math.min(
            videoDivRect.bottom,
            touch.clientY - videoDivRect.top
          );

          if (computeY < 0) {
            computeY = 0;
          }

          if (computeY > videoDivRect.height) {
            computeY = videoDivRect.height;
          }

          const newY = computeY - circle2Rect.height / 2;
          setCircle2Pos({ x: newX, y: newY });
          e.preventDefault();
        }
      }
    };
    document.addEventListener("touchmove", preventScroll, { passive: false });

    return () => {
      document.removeEventListener("touchmove", preventScroll);
    };
  }, [
    circle1Dragging,
    circle2Dragging,
    videoRef,
    circle1Ref,
    circle2Ref,
    window.innerWidth,
  ]);

  useEffect(() => {
    if (videoRef.current) {
      const videoDivRect = videoRef.current.getBoundingClientRect();
      const x1 =
        (Math.max(0, circle1Pos.x) * videoRef.current.videoWidth) /
        videoDivRect.width;
      const y1 =
        (Math.max(0, circle1Pos.y) * videoRef.current.videoHeight) /
        videoDivRect.height;
      const x2 =
        (Math.min(videoDivRect.right, circle2Pos.x) *
          videoRef.current.videoWidth) /
        videoDivRect.width;
      const y2 =
        (Math.min(videoDivRect.bottom, circle2Pos.y) *
          videoRef.current.videoHeight) /
        videoDivRect.height;
      setCirclesVidPos({ x1: x1, y1: y1, x2: x2, y2: y2 });
    }
  }, [circle1Pos.x, circle1Pos.y, circle2Pos.x, circle2Pos.y]);

  return (
    <>
      <CancelDemoModal
        showCancelDemoModal={props?.showCancelDemoModal}
        handleCancelDemoModalClose={props?.handleCancelDemoModalClose}
        onClickCancelDemo={onClickCancelDemo}
      />
      <ModelDemoStartModal
        // modelID={id}
        handleStartDemoBtn={handleStartDemoBtn}
        showModelDemoStartModal={showModelDemoStartModal}
        handleModelDemoStartModalClose={handleModelDemoStartModalClose}
        setShowToast={props?.setShowToast}
        setToastStatus={props?.setToastStatus}
        setToastImage={props?.setToastImage}
      />
      <div className="demo-main-div">
        <div
          className="demo-div-1"
          style={{
            pointerEvents: "auto",
            cursor: demoStarted ? "not-allowed" : "unset",
          }}
        >
          <div
            className={`${
              demoStarted ? "cursor-not-allowed pointer-events-none" : ""
            }`}
          >
            <p className="bold mb-0">Demo "{props?.modelName}"</p>
            <p>{props?.modelTask}</p>
            <p className="mb-0">Version</p>
            <Form.Select
              className="input-field"
              onChange={onChangeVersion}
              value={modelFileID}
              name="demo-version"
              id="demo-version"
              data-cy="demo-version"
            >
              {versions?.map((version) => {
                return (
                  <option value={version?.id} key={version?.id}>
                    {version?.status} - {version?.version}
                  </option>
                );
              })}
            </Form.Select>
            <span className="sub-note semi-bold">
              Select a version for demo.
            </span>

            <Form.Group className="mt-3">
              <Form.Label>Input type</Form.Label>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  value="image"
                  checked={inputType === "image"}
                  onChange={onChangeInputType}
                  label="Image"
                  className="me-5"
                  name="input-image"
                  id="input-image"
                  data-cy="input-image"
                />
                <Form.Check
                  type="radio"
                  value="video"
                  checked={inputType === "video"}
                  onChange={onChangeInputType}
                  label="Video"
                  name="input-image"
                  id="input-image"
                  data-cy="input-image"
                />
              </div>
            </Form.Group>
            <Form.Group className="mt-3">
              <div className="d-flex justify-content-between">
                <Form.Label>Image Size</Form.Label>
                <Form.Label>{imageSize}</Form.Label>
              </div>
              <input
                data-cy="image-size"
                type="range"
                onChange={onChangeImageSize}
                value={imageSize}
                className="demo-image-size"
                min="320"
                max="1280"
                step="32"
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <div className="d-flex justify-content-between">
                <Form.Label>Confidence threshold</Form.Label>
                <Form.Label>{confidence}</Form.Label>
              </div>
              <input
                data-cy="confidence"
                type="range"
                onChange={onChangeConfidence}
                value={confidence}
                className="demo-image-size"
                min="0.1"
                max="1.0"
                step="0.1"
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <div className="d-flex justify-content-between">
                <Form.Label>IoU threshold</Form.Label>
                <Form.Label>{iou}</Form.Label>
              </div>
              <input
                data-cy="iou-threshold"
                type="range"
                onChange={onChangeIoU}
                value={iou}
                className="demo-image-size"
                min="0.1"
                max="1.0"
                step="0.1"
              />
            </Form.Group>
          </div>
          <div className="flex-1"></div>
          <div className="mt-5">
            <p className="sub-note semi-bold">
              Attach a supported image format before starting the demo
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="demo-supported-files">
                    <table className="m-3">
                      <tbody>
                        <tr>
                          <td colSpan={2} className="bold pb-2">
                            Image
                          </td>
                        </tr>
                        <tr>
                          <td>• JPEG</td>
                          <td className="ps-3">
                            (Joint Photographic Experts Group)
                          </td>
                        </tr>
                        <tr>
                          <td>• PNG</td>
                          <td className="ps-3">(Portable Network Graphics)</td>
                        </tr>
                        <tr>
                          <td>• GIF</td>
                          <td className="ps-3">
                            (Graphics Interchange Format)
                          </td>
                        </tr>
                        <tr>
                          <td>• BMP</td>
                          <td className="ps-3">(Bitmap)</td>
                        </tr>
                        <tr>
                          <td>• TIFF</td>
                          <td className="ps-3">(Tagged Image File Format)</td>
                        </tr>
                        <tr>
                          <td>• RAW</td>
                          <td className="ps-3">(Camera RAW Image)</td>
                        </tr>
                        <tr>
                          <td>• WebP</td>
                          <td className="ps-3">(Web Picture format)</td>
                        </tr>
                        <tr>
                          <td>• SVG</td>
                          <td className="ps-3">(Scalable Vector Graphics)</td>
                        </tr>
                        <tr>
                          <td>• HEIF / HEIC</td>
                          <td className="ps-3">
                            (High Efficiency Image Format)
                          </td>
                        </tr>
                        <tr>
                          <td>• DICOM</td>
                          <td className="ps-3">
                            (Digital Imaging and Communications in Medicine)
                          </td>
                        </tr>
                        <tr>
                          <td>• PGM</td>
                          <td className="ps-3">(Portable Gray Map)</td>
                        </tr>
                        <tr>
                          <td>• PPM</td>
                          <td className="ps-3">(Portable Pixmap)</td>
                        </tr>
                        <tr>
                          <td>• PBM</td>
                          <td className="ps-3">(Portable Bitmap)</td>
                        </tr>
                        <tr>
                          <td>• HDR</td>
                          <td className="ps-3">(High Dynamic Range)</td>
                        </tr>
                        <tr>
                          <td>• EXR</td>
                          <td className="ps-3">(OpenEXR)</td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="m-3">
                      <tbody>
                        <tr>
                          <td colSpan={2} className="bold pb-2">
                            Video
                          </td>
                        </tr>
                        <tr>
                          <td>• MP4</td>
                          <td className="ps-5">(MPEG-4 Part 14)</td>
                        </tr>
                        <tr>
                          <td>• AVI</td>
                          <td className="ps-5">(Audio Video Interleave)</td>
                        </tr>
                        <tr>
                          <td>• MOV</td>
                          <td className="ps-5">(QuickTime File Format)</td>
                        </tr>
                        <tr>
                          <td>• MKV</td>
                          <td className="ps-5">
                            (Matroska Multimedia Container)
                          </td>
                        </tr>
                        <tr>
                          <td>• WMV</td>
                          <td className="ps-5">(Windows Media Video)</td>
                        </tr>
                        <tr>
                          <td>• FLV</td>
                          <td className="ps-5">(Flash Video)</td>
                        </tr>
                        <tr>
                          <td>• WebM</td>
                          <td className="ps-5">(WebM Project)</td>
                        </tr>
                        <tr>
                          <td>• MPEG</td>
                          <td className="ps-5">
                            (Moving Picture Experts Group)
                          </td>
                        </tr>
                        <tr>
                          <td>• TS</td>
                          <td className="ps-5">(MPEG Transport Stream)</td>
                        </tr>
                        <tr>
                          <td>• 3GP</td>
                          <td className="ps-5">
                            (3rd Generation Partnership Project)
                          </td>
                        </tr>
                        <tr>
                          <td>• M4V</td>
                          <td className="ps-5">(MPEG-4 Video File)</td>
                        </tr>
                        <tr>
                          <td>• VOB</td>
                          <td className="ps-5">(Video Object)</td>
                        </tr>
                        <tr>
                          <td>• ASF</td>
                          <td className="ps-5">(Advanced Systems Format)</td>
                        </tr>
                        <tr>
                          <td>• MPG</td>
                          <td className="ps-5">(MPEG Video File)</td>
                        </tr>
                        <tr>
                          <td>• RM</td>
                          <td className="ps-5">(RealMedia)</td>
                        </tr>
                      </tbody>
                    </table>
                  </Tooltip>
                }
                trigger={["hover", "focus", "click"]}
              >
                <img
                  src="/images/question-gray.svg"
                  className="ms-1 img-fluid"
                  alt="question"
                />
              </OverlayTrigger>
            </p>
            <Button
              className="button w-100"
              onClick={() => setShowModelDemoStartModal(true)}
              disabled={startDemoDisabled}
              name="start"
              id="start"
              data-cy="start"
            >
              Start demo
            </Button>
            {(imageInput || videoInput) && (
              <Button
                variant="outline-light"
                className="save-icon w-100 mt-2"
                onClick={handleClearBtn}
                name="clear"
                id="clear"
                data-cy="clear"
              >
                Clear
              </Button>
            )}
          </div>
        </div>
        <div
          className="demo-div-2"
          style={{
            pointerEvents: "auto",
            cursor: demoStarted ? "not-allowed" : "unset",
          }}
        >
          <div className="input-output-div">
            <div
              className={`input-demo-div ${
                demoStarted ? "cursor-not-allowed pointer-events-none" : ""
              }`}
            >
              {inputType === "image" ? (
                <div className="image-1-div mb-4">
                  <p>Input: image</p>
                  <input type="hidden" value={counter} readOnly />
                  <div
                    onDragOver={handleDragOverImageInput}
                    onDrop={handleDropImageInput}
                    onClick={handleClickImageInput}
                    className={`file-drop-area-demo form-control ${
                      isValid?.modelSelectedFileImageInput
                        ? "is-valid"
                        : isValid?.modelSelectedFileImageInput !== undefined
                        ? "is-invalid"
                        : ""
                    }`}
                    id="demo-input1"
                    data-cy="demo-input1"
                  >
                    {imageInput ? (
                      <div className="position-relative">
                        <img
                          ref={imageRef}
                          src={imageInput}
                          className="w-100"
                        />
                        <div
                          className="demo-remove-span"
                          onClick={handleRemoveImageInputBtn}
                          id="remove-input"
                          data-cy="remove-input"
                        >
                          <span>REMOVE</span>
                        </div>
                      </div>
                    ) : (
                      <p>
                        Drag and drop a supported image file here, or click to
                        browse from your device up to 25 MB
                      </p>
                    )}
                    <input
                      data-cy="image-input"
                      ref={fileInputRefImageInput}
                      type="file"
                      onChange={handleFileChangeImageInput}
                      style={{ display: "none" }}
                      accept="
                      .jpeg,
                      .jpg,
                      .png,
                      .gif,
                      .bmp,
                      .tiff,
                      .raw,
                      .webp,
                      .svg,
                      .heif,
                      .heic,
                      .dicom,
                      .pgm,
                      .ppm,
                      .pbm,
                      .hdr,
                      .exr"
                    />
                  </div>
                  {getFormErrorMessage("modelSelectedFileImageInput")}
                </div>
              ) : (
                <div className="video-2-div">
                  <p>Input: video</p>
                  <div
                    onDragOver={handleDragOverVideoInput}
                    onDrop={handleDropVideoInput}
                    onClick={handleClickVideoInput}
                    className={`file-drop-area-demo form-control ${
                      isValid?.modelSelectedFileVideoInput
                        ? "is-valid"
                        : isValid?.modelSelectedFileVideoInput !== undefined
                        ? "is-invalid"
                        : ""
                    }`}
                    id="demo-input2"
                    data-cy="demo-input2"
                    ref={videoContainerRef}
                  >
                    {videoInput !== "" ? (
                      <div className="position-relative">
                        <div ref={lineRef} className="circle-line"></div>
                        <div
                          id="circle1"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="circle-demo absolute z-10"
                          ref={circle1Ref}
                          style={{
                            left: circle1Pos.x + "px",
                            top: circle1Pos.y + "px",
                          }}
                          onMouseDown={() => {
                            setCircle1Dragging(true);
                          }}
                          onTouchStart={(e) => handleTouchStart("circle1", e)}
                          onTouchEnd={() => {
                            setCircle1Dragging(false);
                          }}
                        ></div>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          id="circle2"
                          className="circle-demo absolute z-10"
                          ref={circle2Ref}
                          style={{
                            left: circle2Pos.x + "px",
                            top: circle2Pos.y + "px",
                          }}
                          onMouseDown={() => {
                            setCircle2Dragging(true);
                          }}
                          onTouchStart={(e) => handleTouchStart("circle2", e)}
                          onTouchEnd={() => {
                            setCircle2Dragging(false);
                          }}
                        ></div>
                        <video
                          ref={videoRef}
                          src={videoInput}
                          className="w-100"
                          onMouseMove={handleMouseMove}
                        ></video>
                        <div
                          className="demo-remove-span"
                          onClick={handleRemoveVideoInputBtn}
                          id="remove-input"
                          data-cy="remove-input"
                        >
                          <span>REMOVE</span>
                        </div>
                      </div>
                    ) : (
                      <p>
                        Drag and drop a supported video file here, or click to
                        browse from your device up to 25 MB
                      </p>
                    )}
                    <input
                      data-cy="video-input"
                      ref={fileInputRefVideoInput}
                      type="file"
                      onChange={handleFileChangeVideoInput}
                      style={{ display: "none" }}
                      accept="
                      .mp4,
                      .avi,
                      .mov,
                      .mkv,
                      .wmv,
                      .flv,
                      .webm,
                      .mpeg,
                      .ts,
                      .3gp,
                      .m4v,
                      .vob,
                      .asf,
                      .mpg,
                      .rm"
                    />
                  </div>
                  {getFormErrorMessage("modelSelectedFileVideoInput")}
                </div>
              )}
              {inputType === "video" && videoInput && (
                <div>
                  Click and drag the two points above to adjust the reference
                  line for the model.
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td colSpan={2}>Point A</td>
                        <td colSpan={2}>Point B</td>
                      </tr>
                      <tr>
                        <td>X:</td>
                        <td>{Math.round(circle1Pos.x)}</td>
                        <td>X:</td>
                        <td>{Math.round(circle2Pos.x)}</td>
                      </tr>
                      <tr>
                        <td>Y:</td>
                        <td>{Math.round(circle1Pos.y)}</td>
                        <td>Y:</td>
                        <td>{Math.round(circle2Pos.y)}</td>
                      </tr>
                    </tbody>
                  </table>
                  <Button
                    variant="outline-light"
                    className="save-icon w-100 my-2"
                    onClick={handleResetCoordinatesBtn}
                  >
                    Reset Coordinates
                  </Button>
                </div>
              )}
            </div>
            <div className="output-demo-div">
              <p>Output</p>
              <div className="output-demo-image-div mb-3">
                <div
                  className={`${
                    demoProgressPercentage > 0 ? "bg-light-color vh-20" : ""
                  }`}
                  style={{
                    width:
                      demoProgressPercentage > 0 && demoProgressPercentage < 100
                        ? `${demoProgressPercentage}%`
                        : "100%",
                  }}
                >
                  {inputType === "image" && outputImage && (
                    <div className="position-relative">
                      <img
                        src={outputImage}
                        className="image w-100"
                        id="demo-output"
                        data-cy="demo-output"
                      />
                      <div
                        className="demo-remove-span"
                        onClick={handleDownloadOutputBtn}
                        id="download-output"
                        data-cy="download-output"
                      >
                        <span>DOWNLOAD</span>
                      </div>
                    </div>
                  )}

                  {inputType === "video" && outputVideo && (
                    <div className="position-relative">
                      <video
                        src={outputVideo}
                        className="video w-100"
                        controls={true}
                        autoPlay={false}
                        controlsList="nodownload"
                        id="demo-output"
                        data-cy="demo-output"
                      />
                      <div
                        className="demo-remove-span"
                        onClick={handleDownloadOutputBtn}
                        id="download-output"
                        data-cy="download-output"
                      >
                        <span>DOWNLOAD</span>
                      </div>
                    </div>
                  )}

                  {demoProgressPercentage === 0 &&
                    !outputImage &&
                    !outputVideo && (
                      <span
                        style={{
                          position: "absolute",
                          bottom: "50%",
                          right: "50%",
                        }}
                      >
                        <img src="/images/default-output-demo-icon.svg" />
                      </span>
                    )}
                  {demoProgressPercentage > 0 &&
                    !outputImage &&
                    !outputVideo &&
                    !userCancelled && (
                      <div>
                        <Spinner
                          className="spinner-model"
                          as="span"
                          animation="border"
                          size="md"
                          role="status"
                          aria-hidden="true"
                        />
                        <p>Model result is loading...</p>
                      </div>
                    )}
                </div>
              </div>
              {(outputImage || outputVideo || errorMessage) && (
                <div className="output-demo-summary-div">
                  <p className="bold mb-0">Summary</p>
                  <p>Computation time: {computationTime} s</p>
                  {(inputType === "image" && outputImage) ||
                  (inputType === "video" && outputVideo) ? (
                    <>
                      {inputType === "video" && outputVideo
                        ? Object.values(info).length > 0 && (
                            <Table bordered>
                              <thead>
                                <tr>
                                  <td>Vehicle class</td>
                                  <td>IN</td>
                                  <td>OUT</td>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.entries(info.counts).map(
                                  ([vehicle, totalCount]) => (
                                    <tr key={vehicle}>
                                      <td>{vehicle}</td>
                                      <td>{totalCount.IN}</td>
                                      <td>{totalCount.OUT}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          )
                        : Object.values(info).length > 0 && (
                            <Table bordered>
                              <thead>
                                <tr>
                                  <td>Vehicle class</td>
                                  <td>COUNT</td>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.entries(info.objects).map(
                                  ([vehicle, count]) => (
                                    <tr key={vehicle}>
                                      <td>{vehicle}</td>
                                      <td>{count}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          )}
                    </>
                  ) : (
                    <p className="error-demo-message">{errorMessage}</p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="demo-sample-inputs">
            <div>Try out these sample input!</div>
            <Table
              bordered
              hover
              className={`input-demo-table ${
                demoStarted ? "cursor-not-allowed pointer-events-none" : ""
              }`}
              onClick={handleSampleInput}
              id="sample-input"
              data-cy="sample-input"
            >
              <tbody>
                <tr>
                  <td className="od-image p-5">
                    <div>{inputType === "image" ? "Image" : "Video"}</div>
                    {inputType === "image" ? (
                      <img
                        src="/inferencing-inputs/object-detection/image-input.png"
                        className="w-100"
                      />
                    ) : (
                      <video
                        src="/inferencing-inputs/object-detection/11-sec-vid.mp4"
                        className="w-100"
                      ></video>
                    )}
                  </td>
                  <td className="od-image-size">
                    <div>Image size</div>
                    640
                  </td>
                  <td className="od-confidence">
                    <div>Confidence threshold</div>0.2
                  </td>
                  <td className="od-iou">
                    <div>IoU threshold</div>0.7
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoObjectDetection;
