import {
  DELETE_DATASET_SUCCESS,
  GET_DATASET_SUCCESS,
  GET_DATASET_FAIL,
  GET_DATASET_VERSION_SUCCESS,
  GET_DATASET_VERSION_FAIL,
  GET_ALL_DATASETS_SUCCESS,
  GET_ALL_DATASETS_FAIL,
  GET_ALL_DATASETS_VERSIONS_FAIL,
  GET_ALL_DATASETS_VERSIONS_SUCCESS,
  UPDATE_DATASET_SUCCESS,
  SUBSCRIBE_DATASET_SUCCESS,
  GET_SUBSCRIBE_DATASET_SUCCESS,
  GET_ALL_SUBSCRIBE_DATASETS_SUCCESS,
} from "../actions/types";
/**
 * A module that manages dataset-related state
 * @module reducers/dataset
 */

const initialState = {
  datasets: {},
  currentDataset: {},
  datasetVersions: {},
  currentDatasetVersion: {},
  currentSubscribedDataset: {},
  subscribedDatasets: {},
};

/**
 * Method that take the current state and an action as arguments, and return a new state result
 *
 * @method datasetReducer
 *
 * @param {Object} state - An object for the datasets state { datasets: <object>, currentDataset: <object>}
 * @param {Object} action - An object that contains type and payload
 *
 * @return {Object}
 *
 */
export default function datasetReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_DATASET_SUCCESS:
      return {
        ...state,
        currentDataset: [payload],
      };
    case DELETE_DATASET_SUCCESS:
      return {
        ...state,
        currentDataset: {},
      };
    case GET_DATASET_SUCCESS:
      return {
        ...state,
        currentDataset: payload,
      };
    case GET_DATASET_FAIL:
      return {
        ...state,
        currentDataset: {},
      };
    case GET_DATASET_VERSION_SUCCESS:
      return {
        ...state,
        currentDatasetVersion: payload,
      };
    case GET_DATASET_VERSION_FAIL:
      return {
        ...state,
        currentDatasetVersion: {},
      };
    case GET_ALL_DATASETS_SUCCESS:
      return {
        ...state,
        datasets: payload.results,
      };
    case GET_ALL_DATASETS_FAIL:
      return {
        ...state,
        datasets: {},
      };
    case GET_ALL_DATASETS_VERSIONS_SUCCESS:
      return {
        ...state,
        datasetVersions: payload,
      };
    case GET_ALL_DATASETS_VERSIONS_FAIL:
      return {
        ...state,
        datasetVersions: {},
      };
    case SUBSCRIBE_DATASET_SUCCESS:
      return {
        ...state,
        currentSubscribedDataset: payload,
      };
    case GET_SUBSCRIBE_DATASET_SUCCESS:
      return {
        ...state,
        currentSubscribedDataset: payload,
      };
    case GET_ALL_SUBSCRIBE_DATASETS_SUCCESS:
      return {
        ...state,
        subscribedDatasets: payload,
      };
    default:
      return state;
  }
}
