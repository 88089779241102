import { compareValues } from "common/constants";
import React, { useEffect, useState } from "react";
import {
  Form,
  FormControl,
  FormCheck,
  Dropdown,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "styles/FilterDropdown.css";
import "styles/mobile/FilterDropdownMobile.css";

const FilterDropdown = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [showFullList, setShowFullList] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [emptyListMessage, setEmptyListMessage] = useState("");
  const [mobileFilterMessage, setMobileFilterMessage] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (selectedOptions.length === 0 && !expanded) {
      setSearchTerm("");
      setShowFullList(false);
      setStartIndex(0);
    }
  }, [selectedOptions.length, expanded]);

  const handleToggleExpand = (e) => {
    setExpanded(!expanded);
  };

  const handleToggleFullList = () => {
    setShowFullList(true);
    setStartIndex(0);
  };

  const handleNextPage = () => {
    const nextPageIndex = startIndex + 10;
    setStartIndex(nextPageIndex);
  };

  const handlePrevPage = () => {
    const prevPageIndex = startIndex - 10;
    setStartIndex(prevPageIndex);
  };

  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleClearSelection = () => {
    setSelectedOptions([]);
  };

  useEffect(() => {
    if (props?.name === "created_by") {
      const resultsArray = Object.values(selectedOptions).map(
        (option) =>
          Object.values(props?.options).find((opt) => opt.created_by === option)
            ?.email
      );
      props?.setSelectedOptions(resultsArray);
    } else {
      props?.setSelectedOptions(selectedOptions);
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (props?.options) {
      if (props?.name === "groups") {
        setFilteredOptions(
          Object.values(props?.options)
            .filter((option) =>
              option.group_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((item) => item.group_name)
            .sort((a, b) => {
              return compareValues(a, b, "asc");
            })
        );
      } else if (props?.name === "created_by") {
        setFilteredOptions(
          Object.values(props?.options)
            .filter((option) =>
              option.created_by.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((item) => item.created_by)
            .sort((a, b) => {
              return compareValues(a, b, "asc");
            })
        );
      } else {
        setFilteredOptions(
          Object.values(props?.options)
            .filter((option) =>
              option.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .sort((a, b) => {
              return compareValues(a, b, "asc");
            })
        );
      }
    }
  }, [props?.options, searchTerm]);

  useEffect(() => {
    if (props?.name === "groups") {
      setEmptyListMessage("You don't have any groups.");
    } else if (props?.name === "subscriptions_model") {
      setEmptyListMessage("You have no model subscriptions");
    } else if (props?.name === "subscriptions_dataset") {
      setEmptyListMessage("You have no dataset subscriptions");
    } else {
      setEmptyListMessage("No results.");
    }

    if (props?.name === "tasks") {
      setMobileFilterMessage("Filter by task");
    } else if (props?.name === "created_by") {
      setMobileFilterMessage("Filter by owner");
    } else if (props?.name === "subscriptions_model") {
      setMobileFilterMessage("Filter by your subscriptions");
    }
  }, [props?.name]);

  useEffect(() => {
    if (props?.options.length < 1) {
      setSelectedOptions([]);
    }
    // else {
    //   const updatedSelectedOptions = selectedOptions.filter((selected) =>
    //     props?.options.find((option) => option === selected)
    //   );
    //   setSelectedOptions(updatedSelectedOptions);
    // }
  }, [props?.options]);

  useEffect(() => {
    if (props?.clearFilter) {
      handleClearSelection();
      props?.setClearFilter(false);
    }
  }, [props?.clearFilter]);

  const totalOptions = filteredOptions.length;
  const pageSize = 10;
  const visibleOptions = showFullList
    ? filteredOptions.slice(0, startIndex + pageSize)
    : filteredOptions.slice(0, 5);

  return (
    <>
      <Dropdown
        show={expanded && !props.isMobile}
        onToggle={handleToggleExpand}
        className="search-filter-dropdown"
      >
        <Dropdown.Toggle
          variant="outline-primary"
          className={
            expanded
              ? "button"
              : selectedOptions[0]
              ? "button show"
              : "search-filter-btn btn-outline-light"
          }
          id={props?.dropdownToggleID}
          data-cy={props?.dropdownToggleID}
        >
          {props?.filterTitle}
          {selectedOptions[0] && ": " + selectedOptions[0]}
          {selectedOptions.length > 1 && (
            <span className="plus-filters-count">
              +{selectedOptions.length - 1}
            </span>
          )}
          <img
            src={
              expanded
                ? "/images/arrow-down-white.svg"
                : "/images/arrow-down.svg"
            }
            className="arrow-down img-fluid"
            alt="arrow down"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className="search-filter-dropdown-menu">
          <Form>
            {filteredOptions.length === 0 ? (
              props.options.length === 0 ? (
                <p className="m-5 text-center">{emptyListMessage}</p>
              ) : (
                <>
                  <FormControl
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="input-search"
                  />
                  <p className="m-5 text-center">No matched results</p>
                </>
              )
            ) : (
              <>
                <FormControl
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="input-search"
                />
                <div className="filter-list-checkboxes">
                  {visibleOptions.map((option) => (
                    <FormCheck
                      key={option}
                      label={option}
                      checked={selectedOptions.includes(option)}
                      onChange={() => handleCheckboxChange(option)}
                    />
                  ))}
                </div>
                {totalOptions > 5 && !showFullList && (
                  <div className="show-full-list">
                    <Button
                      variant="link"
                      className="gray-link"
                      onClick={handleToggleFullList}
                    >
                      Show full list
                    </Button>
                  </div>
                )}
                {showFullList && (
                  <div className="show-more">
                    {/* {startIndex > 0 && (
                    <Button variant="link" onClick={handlePrevPage}>
                      Show previous
                    </Button>
                  )} */}
                    {startIndex + pageSize < totalOptions && (
                      <Button
                        variant="link"
                        className="gray-link"
                        onClick={handleNextPage}
                      >
                        Show more
                      </Button>
                    )}
                  </div>
                )}
                {!isMobile && (
                  <div className="options-footer d-flex justify-content-between">
                    <div>
                      {selectedOptions.length > 0 && (
                        <Button
                          variant="link"
                          className="gray-link"
                          onClick={handleClearSelection}
                        >
                          Clear Selection
                        </Button>
                      )}
                    </div>
                    {showFullList && (
                      <p className="showing-items">
                        {/* Showing {0 + 1} -{" "} */}
                        {Math.min(startIndex + pageSize, totalOptions)} of{" "}
                        {totalOptions}
                      </p>
                    )}
                  </div>
                )}
              </>
            )}
          </Form>
        </Dropdown.Menu>
      </Dropdown>
      <Offcanvas
        show={expanded && props.isMobile}
        onHide={() => setExpanded(false)}
        placement="bottom"
        className="offcanvas-filter-dropdown"
      >
        <Offcanvas.Body className="p-0">
          <Form>
            {filteredOptions.length === 0 ? (
              props.options.length === 0 ? (
                <p className="m-5 text-center">{emptyListMessage}</p>
              ) : (
                <>
                  <FormControl
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="input-search"
                  />
                  <p className="m-5 text-center">No matched results</p>
                </>
              )
            ) : (
              <>
                <FormControl
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="input-search"
                />
                <div className="p-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="py-1">{mobileFilterMessage}</div>
                    <div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {selectedOptions.length > 0 && (
                            <Button
                              variant="link"
                              className="py-1 gray-link"
                              onClick={handleClearSelection}
                            >
                              Clear All
                            </Button>
                          )}
                        </div>
                        {showFullList && (
                          <p className="showing-items">
                            {/* Showing {0 + 1} -{" "} */}
                            {Math.min(
                              startIndex + pageSize,
                              totalOptions
                            )} of {totalOptions}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-list-checkboxes">
                    {visibleOptions.map((option) => (
                      <FormCheck
                        key={option}
                        label={option}
                        checked={selectedOptions.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                      />
                    ))}
                  </div>
                  {totalOptions > 5 && !showFullList && (
                    <div className="show-full-list">
                      <Button
                        variant="link"
                        className="gray-link"
                        onClick={handleToggleFullList}
                      >
                        Show full list
                      </Button>
                    </div>
                  )}
                  {showFullList && (
                    <div className="show-more">
                      {/* {startIndex > 0 && (
                    <Button variant="link" onClick={handlePrevPage}>
                      Show previous
                    </Button>
                  )} */}
                      {startIndex + pageSize < totalOptions && (
                        <Button
                          variant="link"
                          className="gray-link"
                          onClick={handleNextPage}
                        >
                          Show more
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default FilterDropdown;
