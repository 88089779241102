import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import "styles/Security.css";
import "styles/SecurityMobile.css";
import UpdatePasswordModal from "./UpdatePasswordModal";
import { useDispatch, useSelector } from "react-redux";
import { convertDate } from "common/constants";
import { getUser } from "actions/group";
import { getCurrentUser } from "actions/auth";

/**
 * A module for Security Component
 * @module components/users/Security
 */

/**
 * Security Component
 * @method Security
 *
 * @return {JSX.Element}
 *
 */
const Security = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);

  const handleUpdatePasswordModalClose = () => {
    setShowUpdatePasswordModal(false);
  };

  useEffect(() => {
    props.setPreload(true);
    dispatch(getCurrentUser())
      .catch((status) => {
        props.setShowToast(true);
        props.setToastStatus(status);
        props.setToastImage(null);
      })
      .finally(() => props.setPreload(false));
  }, []);

  return (
    <>
      <UpdatePasswordModal
        showUpdatePasswordModal={showUpdatePasswordModal}
        handleUpdatePasswordModalClose={handleUpdatePasswordModalClose}
        setShowToast={props?.setShowToast}
        setToastStatus={props?.setToastStatus}
        setToastImage={props?.setToastImage}
      />
      <Container className="security-container px-3">
        <div>
          <div className="d-flex justify-content-between mb-5">
            <span className="w-75">
              <p className="security-title bold">Security</p>
              <p className="security-desc">
                Always keep this information up to date to access your account
              </p>
            </span>
            <div>
              <img
                src="/images/security_lock.svg"
                className="security-icon"
                alt="security_lock"
              />
            </div>
          </div>
          <div className="how-you-sign-in">
            <h5 className="security-title semi-bold">
              How you sign in to DIMER
            </h5>
            <Row>
              <Col xs={4} lg={4} className="medium">
                Email Address
              </Col>
              <Col xs={8} lg={8}>
                {currentUser?.email}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={4} lg={4} className="medium">
                Password
              </Col>
              <Col xs={8} lg={8} className="security-password">
                <p className="me-2 mb-3">
                  {currentUser?.last_password_change
                    ? "Last changed " +
                      convertDate(
                        currentUser?.last_password_change,
                        "MM DD, YYYY"
                      )
                    : "--"}
                </p>
                <Button
                  className="button btn btn-primary"
                  onClick={() => setShowUpdatePasswordModal(true)}
                  name="change-password"
                  id="change-password"
                  data-cy="change-password"
                >
                  Change password
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Security;
