import { Container, Button, Table } from "react-bootstrap";
import { convertBytesToSize } from "common/constants";
import { useState } from "react";
import ViewDatasetLicenseModal from "components/datasets/ViewDatasetLicense";
import "styles/Overview.css";

/**
 * A module for Overview component
 * @module components/datasets/sections/Overview
 */

/**
 * Overview component
 * @method Overview
 *
 * @return {JSX.Element}
 *
 */
const Overview = (props) => {
  const [showViewDatasetLicenseModal, setShowViewDatasetLicenseModal] =
    useState(false);

  const handleViewDatasetLicenseModalClose = () => {
    setShowViewDatasetLicenseModal(false);
  };

  return (
    <>
      <ViewDatasetLicenseModal
        showViewDatasetLicenseModal={showViewDatasetLicenseModal}
        handleViewDatasetLicenseModalClose={handleViewDatasetLicenseModalClose}
        datasetLicense={props?.datasetLicense}
        datasetLicenseContent={props?.datasetLicenseContent}
      />
      <Container className="ms-0">
        <span className="bold">Overview</span>
        <p>Basic information about this version.</p>

        <div className="mb-3">
          <label className="semi-bold">Description</label>
          <p
            dangerouslySetInnerHTML={{
              __html:
                props?.datasetVersionDescription === "<p><br></p>" ||
                !props?.datasetVersionDescription
                  ? "--"
                  : props?.datasetVersionDescription,
            }}
          ></p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">Contributor</label>
          <p>
            {(props?.datasetContributor?.length > 0 &&
              props?.datasetContributor?.join(", ").replace(/,/g, ", ")) ||
              "--"}
          </p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">Keywords</label>
          <p>
            {(props?.datasetKeywords?.length > 0 &&
              props?.datasetKeywords?.join(", ").replace(/,/g, ", ")) ||
              "--"}
          </p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">Citation details</label>
          <p
            dangerouslySetInnerHTML={{
              __html:
                props?.datasetCitationDetails === "<p><br></p>" ||
                !props?.datasetCitationDetails
                  ? "--"
                  : props?.datasetCitationDetails,
            }}
          ></p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">Other relevant information</label>
          <p
            dangerouslySetInnerHTML={{
              __html:
                props?.datasetOtherRelevantInfo === "<p><br></p>" ||
                !props?.datasetOtherRelevantInfo
                  ? "--"
                  : props?.datasetOtherRelevantInfo,
            }}
          ></p>
        </div>
        <div className="mb-3">
          <label className="semi-bold">Attached file</label>
          {props?.datasetFile?.length > 0 ? (
            <Table bordered>
              <thead>
                <tr>
                  <th colSpan={2}>File name</th>
                </tr>
              </thead>
              <tbody>
                {props?.datasetFile?.map((file, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {file?.original_file_name ||
                          file?.file_attachment.split("/media/datasets/")[1]}
                      </td>
                      <td>{convertBytesToSize(file?.size)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p>--</p>
          )}
        </div>
        <div className="mb-3">
          <label className="semi-bold">License</label>
          <div>
            {props?.datasetLicense ? (
              <Button
                variant="outline-light"
                className="light-btn"
                onClick={() => setShowViewDatasetLicenseModal(true)}
              >
                {props?.datasetLicense}
              </Button>
            ) : (
              "--"
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Overview;
