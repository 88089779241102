import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllNotifications,
  readNotification,
  setNotifCount,
} from "actions/notification";
import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import "styles/Notification.css";
import "styles/mobile/NotificationMobile.css";
import {
  IP_ADDRESS,
  compareValues,
  displayNotifications,
} from "common/constants";
import { useMediaQuery } from "react-responsive";
import MobileLeftSidebar from "components/layout/MobileLeftSidebar";

// on socket open
const openSocket = (event) => {
  console.log("Socket successfully connected.");
};

const closeSocket = (event) => {
  console.log("Socket closed unexpectedly");
};

// Error handling
const errorSocket = (error) => {
  console.error("WebSocket error:", error);
};

/**
 * A module for the Notification Component
 * @module components/layout/Notification
 */

/**
 * Notification elements displaying the logo and the logout button
 * @method Notification
 *
 * @param {any} props - An arbitrary inputs of components
 *
 * @return {JSX.Element}
 *
 */
const Notification = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const location = useLocation();
  // import("../../styles/Notification.css");

  const { notifCount, notifications } = useSelector(
    (state) => state.notification
  );
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [elements, setElements] = useState([]);

  const [expanded, setExpanded] = useState(false);
  const [notifCounter, setNotifCounter] = useState(0);
  const [preload, setPreload] = useState(false);

  const handleToggleExpand = (e) => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setElements([]);
    Object.values(notifications)
      .sort((a, b) => {
        return compareValues(a.date_created, b.date_created, "desc");
      })
      .forEach((notification) => {
        const newElement = displayNotifications(
          notification.id,
          notification.title,
          notification.message,
          notification.notif_status,
          notification.date_created,
          notification.url,
          onClickNotification
        );
        setElements((prevElements) => [...prevElements, newElement]);
      });
  }, [notifications, notifCount]);

  // on receiving message on group
  const messageSocket = (e) => {
    console.log("Received a message:", e.data);
    const data = JSON.parse(e.data);
    // const message = data.message;
    // const title = data.title;
    // Call the setMessage function to add the new li element
    setMessage(data);
  };

  const setMessage = (data) => {
    console.log("setMessage called with message:", data);
    dispatch(setNotifCount(notifCount));
    // Update state to include the new element
    // const newElement = displayNotifications(
    //   data.title,
    //   data.message,
    //   true,
    //   new Date()
    // );
    dispatch(getAllNotifications(user.id)).catch((err) => console.log(err));
    // setElements((prevElements) => [...prevElements, newElement]);
  };

  useEffect(() => {
    const notifySocket = new WebSocket(
      "ws://" + IP_ADDRESS?.split("://")[1] + `/ws/notify/${user.id}/`
    );

    dispatch(getAllNotifications(user.id))
      .catch((err) => console.log(err))
      .finally(() => {
        props?.setPreload(false);
      });

    notifySocket.addEventListener("open", openSocket);
    notifySocket.addEventListener("close", closeSocket);
    notifySocket.addEventListener("error", errorSocket);
    notifySocket.addEventListener("message", messageSocket);

    return () => {
      // Remove event listeners here
      notifySocket.removeEventListener("open", openSocket);
      notifySocket.removeEventListener("close", closeSocket);
      notifySocket.removeEventListener("error", errorSocket);
      notifySocket.removeEventListener("message", messageSocket);
      if (notifySocket && notifySocket.readyState === WebSocket.OPEN) {
        notifySocket.close();
      }
    };
  }, []);

  const onClickNotification = (notifID, url) => {
    dispatch(readNotification(notifID))
      .then(() => {
        navigate(url);
        setExpanded(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {!isMobile ? (
        <Dropdown
          show={expanded}
          onToggle={handleToggleExpand}
          className="notifications-dropdown"
        >
          <Dropdown.Toggle
            variant="outline-primary"
            className={
              expanded
                ? "button"
                : "position-relative btn-outline-light icon-toggle"
            }
            id="notifications"
            data-cy="notifications"
          >
            <img
              src="/images/bell.svg"
              className="img-fluid"
              alt="Notification"
            />
            {notifCount > 0 && (
              <div className="notif-counter">{notifCount}</div>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu className="notifications-dropdown-menu py-2">
            <div className="bold notification-title ms-3">Notifications</div>
            {/* <Tabs defaultActiveKey="all" id="notification-tabs" className="mb-3">
            <Tab eventKey="all" tabClassName="tabs" title="All">
              <p className="bold">All</p>
            </Tab>
            <Tab eventKey="unread" tabClassName="tabs" title="Unread">
              <p className="bold">Unread</p>
              <Container className="mw-100"></Container>
            </Tab>
          </Tabs> */}
            <div className="notification-list">
              {/* Render the elements based on the state */}
              {elements.length > 0 ? (
                elements.map((element) => element)
              ) : (
                <p className="no-notif">You have no notifications</p>
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <>
          <div className="notification-list-mobile-header">
            <MobileLeftSidebar
              setUrlToRedirected={props.setUrlToRedirected}
              setShowDiscardChangesModal={props.setShowDiscardChangesModal}
              changesMade={props.changesMade}
            />
            <p className="bold notification-list-title">Notifications</p>
          </div>
          <div className="notification-list-mobile">
            {/* Render the elements based on the state */}
            {elements.length > 0 ? (
              elements.map((element) => element)
            ) : (
              <p className="no-notif">You have no notifications</p>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Notification;
