import { useState, useRef, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Spinner, Toast, ToastContainer } from "react-bootstrap";
import { CancelToken } from "axios";
import PageNotFound from "components/PageNotFound";
import SignIn from "components/users/SignIn";
import SignUp from "components/users/SignUp";
import RequireAuth from "components/users/RequireAuth";
import ForgotPassword from "components/users/ForgotPassword";
import SetPassword from "components/users/SetPassword";
import Security from "components/users/Security";
import PersonalInfo from "components/users/PersonalInfo";
import AccountActivate from "components/users/AccountActivate";
import Header from "components/layout/Header";
import Sidebar from "components/layout/Sidebar";
import CustomProgressBar from "components/layout/CustomProgressBar";
import GroupList from "components/groups/GroupList";
import GroupProfile from "components/groups/GroupProfile";
import ModelList from "components/models/ModelList";
import ModelProfile from "components/models/ModelProfile";
import AddModelVersion from "components/models/AddModelVersion";
import ViewModelVersion from "components/models/ViewModelVersion";
import EditModelVersion from "components/models/EditModelVersion";
import {
  addDownloads,
  addModelVersionSaveAndPublish,
  addModelVersionSaveDraft,
  archiveModelVersion,
  deleteModelVersion,
  downloadModelVersion,
  duplicateModelVersion,
  editModelVersion,
  editUploadModelFile,
  publishModelVersion,
  uploadModelFile,
} from "actions/model";
import { GET_GROUP_FAIL, GET_MODEL_FAIL } from "actions/types";
import modelLicensesJson from "data/model-licenses.json";
import datasetLicensesJson from "data/dataset-licenses.json";

import "./App.css";
import "./AppMobile.css";
import "react-quill/dist/quill.snow.css";
import { version } from "common/constants";

/**Bootstrap Imports*/
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import DatasetList from "components/datasets/DatasetList";
import DatasetProfile from "components/datasets/DatasetProfile";
import AddDatasetVersion from "components/datasets/AddDatasetVersion";
import {
  addDatasetVersionSaveAndPublish,
  addDatasetVersionSaveDraft,
  deleteDatasetVersion,
  downloadDatasetVersion,
  duplicateDatasetVersion,
  editDatasetVersionSaveAndPublish,
  editDatasetVersionSaveDraft,
  publishDatasetVersion,
  uploadDatasetFile,
} from "actions/dataset";
import ViewDatasetVersion from "components/datasets/ViewDatasetVersion";
import EditDatasetVersion from "components/datasets/EditDatasetVersion";
import Notification from "components/layout/Notification";

/**
 * A module for App main component
 * @module App
 */

/**
 * App main component that list all routes to redirect to their corresponding components
 * @method App
 *
 * @return {JSX.Element}
 *
 */
const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const { currentModel, currentModelVersion } = useSelector(
    (state) => state.model
  );
  const { currentDataset, currentDatasetVersion } = useSelector(
    (state) => state.dataset
  );

  const [modelCurrentPage, setModelCurrentPage] = useState(1);
  const [datasetCurrentPage, setDatasetCurrentPage] = useState(1);
  const [groupCurrentPage, setGroupCurrentPage] = useState(1);
  const [showToast, setShowToast] = useState(null);
  const [toastStatus, setToastStatus] = useState(null);
  const [toastImage, setToastImage] = useState(null);
  const [disableLayout, setDisableLayout] = useState(false);
  const [urlToRedirected, setUrlToRedirected] = useState("/");
  const [changesMade, setChangesMade] = useState(false);
  const [preload, setPreload] = useState(false);
  const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);

  // Function to handle toast dismissal
  const handleDismiss = () => {
    setShowToast(false);
  };

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  // Overview
  const [modelNumber, setModelNumber] = useState("");
  const [modelVersionName, setModelVersionName] = useState("");
  const [modelAuthor, setModelAuthor] = useState("");
  const [modelContact, setModelContact] = useState("");
  const [modelVersionDescription, setModelVersionDescription] = useState("");

  // Details
  const [modelDevelopmentDate, setModelDevelopmentDate] = useState("");
  const [modelDevelopers, setModelDevelopers] = useState("");
  const [modelAccuracy, setModelAccuracy] = useState("");
  const [modelInput, setModelInput] = useState("");
  const [modelOutput, setModelOutput] = useState("");
  const [modelType, setModelType] = useState("");
  const [modelPaper, setModelPaper] = useState("");
  const [modelCitationDetails, setModelCitationDetails] = useState("");
  const [modelLicense, setModelLicense] = useState("");
  const [modelLicenseContent, setModelLicenseContent] = useState("");
  const [modelOtherRelevantInfo, setModelOtherRelevantInfo] = useState("");

  // Intended use
  const [modelPrimaryIntendedUses, setModelPrimaryIntendedUses] = useState("");
  const [modelPrimaryIntendedUsers, setModelPrimaryIntendedUsers] =
    useState("");
  const [modelOutOfScopeUseCases, setModelOutOfScopeUseCases] = useState("");

  // Factors
  const [modelGroups, setModelGroups] = useState("");
  const [modelInstrumentation, setModelInstrumentation] = useState("");
  const [modelEnvironment, setModelEnvironment] = useState("");

  // Metrics
  const [modelPerformanceMeasures, setModelPerformanceMeasures] = useState("");
  const [modelDecisionThresholds, setModelDecisionThresholds] = useState("");
  const [modelApproachesToUncertainty, setModelApproachesToUncertainty] =
    useState("");

  // Datasets
  const [modelTrainingDataset, setModelTrainingDataset] = useState("");
  const [modelTrainingMotivation, setModelTrainingMotivation] = useState("");
  const [modelTrainingPreprocessing, setModelTrainingPreprocessing] =
    useState("");
  const [modelEvaluationDataset, setModelEvaluationDataset] = useState("");
  const [modelEvaluationMotivation, setModelEvaluationMotivation] =
    useState("");
  const [modelEvaluationPreprocessing, setModelEvaluationPreprocessing] =
    useState("");

  // Analyses
  const [modelUnitaryResults, setModelUnitaryResults] = useState("");
  const [modelIntersectionalResults, setModelIntersectionalResults] =
    useState("");

  // Considerations and Biases
  const [modelData, setModelData] = useState("");
  const [modelHumanLife, setModelHumanLife] = useState("");
  const [modelMitigations, setModelMitigations] = useState("");
  const [modelRisksAndHarms, setModelRisksAndHarms] = useState("");
  const [modelUseCases, setModelUseCases] = useState("");

  // Recommendations
  const [modelDetails, setModelDetails] = useState("");

  // Datasets Overview
  const [datasetVersionID, setDatasetVersionID] = useState("");
  const [datasetVersion, setDatasetVersion] = useState("");
  const [datasetSubtitle, setDatasetSubtitle] = useState("");
  const [datasetVersionDescription, setDatasetVersionDescription] =
    useState("");
  const [datasetContributor, setDatasetContributor] = useState("");
  const [datasetKeywords, setDatasetKeywords] = useState("");
  const [datasetCitationDetails, setDatasetCitationDetails] = useState("");
  const [datasetOtherRelevantInfo, setDatasetOtherRelevantInfo] = useState("");
  const [datasetLicense, setDatasetLicense] = useState("");
  const [datasetLicenseContent, setDatasetLicenseContent] = useState("");
  const [datasetSelectedFile, setDatasetSelectedFile] = useState("");

  const [selectedFile, setSelectedFile] = useState("");
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progressBarPercentage, setProgressBarPercentage] = useState(0);
  const [cancelFileUpload, setCancelFileUpload] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const cancelAction = useRef(null);

  const [refreshModelVersionsCount, setRefreshModelVersionsCount] = useState(0);
  const [refreshDatasetVersionsCount, setRefreshDatasetVersionsCount] =
    useState(0);

  const cancelProgressBar = () => {
    if (cancelAction.current) {
      cancelAction.current("User has canceled the action.");
      setCancelFileUpload(true);
      setProgressBarPercentage(0);
    }
  };

  const handleOnCloseProgressBar = () => {
    setShowProgressBar(false);
    if (progressBarPercentage !== 100) {
      cancelProgressBar();
    }
  };

  const handleUploadFile = (clickedPublishBtn) => {
    navigate("/models/profile/" + currentModel[0]?.id + "?tab=2");
    if (selectedFile) {
      setShowProgressBar(true);
      setCancelFileUpload(false);
      dispatch(
        uploadModelFile(
          currentModel[0]?.id,
          selectedFile,
          (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (percentage >= 95) {
              setProgressBarPercentage(95);
            } else {
              setProgressBarPercentage(percentage);
            }
          },
          new CancelToken((cancel) => (cancelAction.current = cancel))
        )
      )
        .then((res) => {
          setShowProgressBar(false);
          if (isPublish || clickedPublishBtn) {
            handleSaveAndPublish(res.id);
          } else {
            saveDraft(res.id);
          }
          setProgressBarPercentage(100);
        })
        .catch((err) => {
          console.log(err);
          setProgressBarPercentage(0);
          setShowProgressBar(false);
        });
    }
  };

  const handleSaveDraft = () => {
    setIsPublish(false);
    if (selectedFile) {
      handleUploadFile(false);
    } else {
      saveDraft(0);
      navigate("/models/profile/" + currentModel[0]?.id + "?tab=2");
    }
  };

  const [isPublishDataset, setIsPublishDataset] = useState(false);

  const [showProgressBarDataset, setShowProgressBarDataset] = useState(false);
  const [progressBarPercentageDataset, setProgressBarPercentageDataset] =
    useState(0);
  const [cancelFileUploadDataset, setCancelFileUploadDataset] = useState(false);
  const cancelActionDataset = useRef(null);

  const cancelProgressBarDataset = () => {
    if (cancelActionDataset.current) {
      cancelActionDataset.current("User has canceled the action.");
      setCancelFileUploadDataset(true);
      setProgressBarPercentageDataset(0);
    }
  };

  const handleOnCloseProgressBarDataset = () => {
    setShowProgressBarDataset(false);
    if (progressBarPercentageDataset !== 100) {
      cancelProgressBarDataset();
    }
  };

  const handleSaveDraftDataset = () => {
    setIsPublishDataset(false);
    if (datasetSelectedFile) {
      setShowProgressBarDataset(true);
      setCancelFileUploadDataset(false);
    }
    dispatch(
      addDatasetVersionSaveDraft(
        currentDataset.id,
        datasetSubtitle,
        datasetVersionDescription,
        Object.values(datasetLicensesJson).find(
          (license) => license.id.toString() === datasetLicense
        )?.name || "",
        datasetLicenseContent,
        datasetCitationDetails,
        datasetOtherRelevantInfo,
        datasetSelectedFile,
        datasetContributor.length > 0
          ? datasetContributor.map((contributor) => {
              return contributor.id;
            })
          : [],
        datasetKeywords.length > 0 ? datasetKeywords : [],
        (progressEvent) => {
          if (datasetSelectedFile) {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgressBarPercentageDataset(percentage);
          }
        },
        new CancelToken((cancel) => (cancelActionDataset.current = cancel))
      )
    )
      .then((status) => {
        if (datasetSelectedFile) {
          setShowProgressBarDataset(false);
        }
        setToastStatus(status);
        setToastImage("/images/add-version-draft-success.svg");
      })
      .catch((status) => {
        if (status === "notif-in-app-network unreachable") {
          console.log("notif-in-app-network unreachable");
          setToastStatus("success");
          setToastImage("/images/add-version-draft-success.svg");
        } else {
          setToastStatus(status);
          setToastImage(null);
        }
      })
      .finally(() => {
        setShowToast(true);
        setRefreshDatasetVersionsCount(refreshDatasetVersionsCount + 1);
      });
    navigate("/datasets/profile/" + currentDataset.id + "?tab=2");
  };

  const handleSaveAndPublishDataset = () => {
    setIsPublishDataset(true);
    if (datasetSelectedFile) {
      setShowProgressBarDataset(true);
      setCancelFileUploadDataset(false);
    }
    dispatch(
      addDatasetVersionSaveAndPublish(
        currentDataset.id,
        datasetSubtitle,
        datasetVersionDescription,
        Object.values(datasetLicensesJson).find(
          (license) => license.id.toString() === datasetLicense
        )?.name || "",
        datasetLicenseContent,
        datasetCitationDetails,
        datasetOtherRelevantInfo,
        datasetSelectedFile,
        datasetContributor.length > 0
          ? datasetContributor.map((contributor) => {
              return contributor.id;
            })
          : [],
        datasetKeywords.length > 0 ? datasetKeywords : [],
        (progressEvent) => {
          if (datasetSelectedFile) {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgressBarPercentageDataset(percentage);
          }
        },
        new CancelToken((cancel) => (cancelActionDataset.current = cancel))
      )
    )
      .then((status) => {
        if (datasetSelectedFile) {
          setShowProgressBarDataset(false);
        }

        setToastStatus(status);
        setToastImage("/images/add-version-publish-success.svg");
      })
      .catch((status) => {
        if (status === "notif-in-app-network unreachable") {
          console.log("notif-in-app-network unreachable");
          setToastStatus("success");
          setToastImage("/images/add-version-publish-success.svg");
        } else {
          setToastStatus(status);
          setToastImage(null);
        }
      })
      .finally(() => {
        setShowToast(true);
        setRefreshDatasetVersionsCount(refreshDatasetVersionsCount + 1);
      });
    navigate("/datasets/profile/" + currentDataset.id + "?tab=2");
  };

  const [isPublishDataset_E, setIsPublishDataset_E] = useState(false);

  const [showProgressBarDataset_E, setShowProgressBarDataset_E] =
    useState(false);
  const [progressBarPercentageDataset_E, setProgressBarPercentageDataset_E] =
    useState(0);
  const [cancelFileUploadDataset_E, setCancelFileUploadDataset_E] =
    useState(false);
  const cancelActionDataset_E = useRef(null);

  const cancelProgressBarDataset_E = () => {
    if (cancelActionDataset_E.current) {
      cancelActionDataset_E.current("User has canceled the action.");
      setCancelFileUploadDataset_E(true);
      setProgressBarPercentageDataset_E(0);
    }
  };

  const handleOnCloseProgressBarDataset_E = () => {
    setShowProgressBarDataset_E(false);
    if (progressBarPercentageDataset_E !== 100) {
      cancelProgressBarDataset_E();
    }
  };

  const handleSaveDraftDataset_E = () => {
    setIsPublishDataset_E(false);
    const filteredOlderDatasetSelectedFiles =
      datasetSelectedFile && datasetSelectedFile?.filter((file) => !file.id);
    if (filteredOlderDatasetSelectedFiles.length > 0) {
      handleUploadFileDataset_E(false);
    } else {
      saveDraftDataset_E(
        datasetSelectedFile &&
          datasetSelectedFile?.filter((file) => file.id).map((file) => file.id)
      );
      navigate("/datasets/profile/" + currentDataset.id + "?tab=2");
    }
  };

  const handleUploadFileDataset_E = (clickedPublishBtn) => {
    navigate("/datasets/profile/" + currentDataset.id + "?tab=2");
    const filteredOlderDatasetSelectedFiles = datasetSelectedFile?.filter(
      (file) => file.id
    );
    const filteredDatasetSelectedFiles = datasetSelectedFile?.filter(
      (file) => !file.id
    );
    if (filteredDatasetSelectedFiles.length > 0) {
      setShowProgressBarDataset_E(true);
      setCancelFileUploadDataset_E(false);
      dispatch(
        uploadDatasetFile(
          filteredDatasetSelectedFiles,
          (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgressBarPercentageDataset_E(percentage);
          },
          new CancelToken((cancel) => (cancelActionDataset_E.current = cancel))
        )
      )
        .then((res) => {
          setShowProgressBarDataset_E(false);
          res.results.map((file) => {
            filteredOlderDatasetSelectedFiles.push(file);
          });
          if (isPublishDataset_E || clickedPublishBtn) {
            saveAndPublishDataset_E(
              filteredOlderDatasetSelectedFiles.map((file) => file.id)
            );
          } else {
            // setDatasetSelectedFile(filteredOlderDatasetSelectedFiles);
            saveDraftDataset_E(
              filteredOlderDatasetSelectedFiles.map((file) => file.id)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const saveDraftDataset_E = (filteredOlderDatasetSelectedFiles) => {
    dispatch(
      editDatasetVersionSaveDraft(
        currentDataset.id,
        datasetVersionID,
        datasetSubtitle,
        datasetVersionDescription,
        Object.values(datasetLicensesJson).find(
          (license) =>
            license.id.toString() === datasetLicense ||
            license.name.toString() === datasetLicense
        )?.name || "",
        datasetLicenseContent,
        datasetCitationDetails,
        datasetOtherRelevantInfo,
        filteredOlderDatasetSelectedFiles,
        datasetContributor.length > 0
          ? datasetContributor.map((contributor) => {
              return contributor.id;
            })
          : [],
        datasetKeywords.length > 0 ? datasetKeywords : []
      )
    )
      .then((status) => {
        // if (datasetSelectedFile) {
        //   setShowProgressBar(false);
        // }

        setToastStatus(status);
        setToastImage("/images/add-version-draft-success.svg");
      })
      .catch((status) => {
        if (status === "notif-in-app-network unreachable") {
          console.log("notif-in-app-network unreachable");
          setToastStatus("success");
          setToastImage("/images/add-version-draft-success.svg");
        } else {
          setToastStatus(status);
          setToastImage(null);
        }
      })
      .finally(() => {
        setShowToast(true);
        setRefreshDatasetVersionsCount(refreshDatasetVersionsCount + 1);
      });
    navigate("/datasets/profile/" + currentDataset.id + "?tab=2");
  };

  const handleSaveAndPublishDataset_E = () => {
    setIsPublishDataset_E(true);
    const filteredOlderDatasetSelectedFiles = datasetSelectedFile?.filter(
      (file) => !file.id
    );
    if (filteredOlderDatasetSelectedFiles.length > 0) {
      handleUploadFileDataset_E(true);
    } else {
      saveAndPublishDataset_E(
        datasetSelectedFile.filter((file) => file.id).map((file) => file.id)
      );
      navigate("/datasets/profile/" + currentDataset.id + "?tab=2");
    }
  };

  const saveAndPublishDataset_E = (filteredOlderDatasetSelectedFiles) => {
    dispatch(
      editDatasetVersionSaveAndPublish(
        currentDataset.id,
        datasetVersionID,
        datasetSubtitle,
        datasetVersionDescription,
        Object.values(datasetLicensesJson).find(
          (license) =>
            license.id.toString() === datasetLicense ||
            license.name.toString() === datasetLicense
        )?.name || "",
        datasetLicenseContent,
        datasetCitationDetails,
        datasetOtherRelevantInfo,
        filteredOlderDatasetSelectedFiles,
        datasetContributor.length > 0
          ? datasetContributor.map((contributor) => {
              return contributor.id;
            })
          : [],
        datasetKeywords.length > 0 ? datasetKeywords : []
      )
    )
      .then((status) => {
        // if (datasetSelectedFile) {
        //   setShowProgressBar(false);
        // }
        setShowToast(true);
        setToastStatus(status);
        setToastImage("/images/add-version-publish-success.svg");
      })
      .catch((status) => {
        setShowToast(true);
        setToastStatus(status);
        setToastImage(null);
      })
      .finally(() => {
        setRefreshDatasetVersionsCount(refreshDatasetVersionsCount + 1);
      });
    navigate("/datasets/profile/" + currentDataset.id + "?tab=2");
  };

  const saveDraft = (modelFileID) => {
    dispatch(
      addModelVersionSaveDraft(
        modelFileID,
        currentModel[0]?.id,
        modelNumber,
        modelVersionName,
        modelVersionDescription,
        modelContact,
        modelDevelopmentDate === "" ? null : modelDevelopmentDate,
        modelDevelopers,
        modelAccuracy,
        modelInput,
        modelOutput,
        modelType,
        modelPaper,
        modelCitationDetails,
        Object.values(modelLicensesJson).find(
          (license) => license.id.toString() === modelLicense
        )?.name,
        modelLicenseContent,
        modelOtherRelevantInfo,
        modelTrainingDataset,
        modelTrainingMotivation,
        modelTrainingPreprocessing,
        modelEvaluationDataset,
        modelEvaluationMotivation,
        modelEvaluationPreprocessing,
        modelUnitaryResults,
        modelIntersectionalResults,
        modelDetails
      )
    )
      .then((status) => {
        setToastStatus(status);
        setToastImage("/images/add-version-draft-success.svg");
      })
      .catch((status) => {
        if (status === "notif-in-app-network unreachable") {
          console.log("notif-in-app-network unreachable");
          setToastStatus("success");
          setToastImage("/images/add-version-draft-success.svg");
        } else {
          setToastStatus(status);
          setToastImage(null);
        }
      })
      .finally(() => {
        setShowToast(true);
        setRefreshModelVersionsCount(refreshModelVersionsCount + 1);
      });
  };

  const handleSaveAndPublish = (modelFileID) => {
    dispatch(
      addModelVersionSaveAndPublish(
        modelFileID,
        currentModel[0]?.id,
        modelNumber,
        modelVersionName,
        modelVersionDescription,
        modelContact,
        modelDevelopmentDate === "" ? null : modelDevelopmentDate,
        modelDevelopers,
        modelAccuracy,
        modelInput,
        modelOutput,
        modelType,
        modelPaper,
        modelCitationDetails,
        Object.values(modelLicensesJson).find(
          (license) => license.id.toString() === modelLicense
        )?.name,
        modelLicenseContent,
        modelOtherRelevantInfo,
        modelTrainingDataset,
        modelTrainingMotivation,
        modelTrainingPreprocessing,
        modelEvaluationDataset,
        modelEvaluationMotivation,
        modelEvaluationPreprocessing,
        modelUnitaryResults,
        modelIntersectionalResults,
        modelDetails
      )
    )
      .then((status) => {
        setToastStatus(status);
        setToastImage("/images/add-version-publish-success.svg");
      })
      .catch((status) => {
        if (status === "notif-in-app-network unreachable") {
          console.log("notif-in-app-network unreachable");
          setToastStatus("success");
          setToastImage("/images/add-version-publish-success.svg");
        } else {
          setToastStatus(status);
          setToastImage(null);
        }
      })
      .finally(() => {
        setShowToast(true);
        setRefreshModelVersionsCount(refreshModelVersionsCount + 1);
      });
  };

  // Overview
  const [modelNumber_E, setModelNumber_E] = useState("");
  const [modelVersionName_E, setModelVersionName_E] = useState("");
  const [modelAuthor_E, setModelAuthor_E] = useState("");
  const [modelContact_E, setModelContact_E] = useState("");
  const [modelVersionDescription_E, setModelVersionDescription_E] =
    useState("");

  // Details
  const [modelDevelopmentDate_E, setModelDevelopmentDate_E] = useState("");
  const [modelDevelopers_E, setModelDevelopers_E] = useState("");
  const [modelAccuracy_E, setModelAccuracy_E] = useState("");
  const [modelInput_E, setModelInput_E] = useState("");
  const [modelOutput_E, setModelOutput_E] = useState("");
  const [modelType_E, setModelType_E] = useState("");
  const [modelPaper_E, setModelPaper_E] = useState("");
  const [modelCitationDetails_E, setModelCitationDetails_E] = useState("");
  const [modelLicense_E, setModelLicense_E] = useState("");
  const [modelLicenseContent_E, setModelLicenseContent_E] = useState("");
  const [modelOtherRelevantInfo_E, setModelOtherRelevantInfo_E] = useState("");

  // Intended use
  const [modelPrimaryIntendedUses_E, setModelPrimaryIntendedUses_E] =
    useState("");
  const [modelPrimaryIntendedUsers_E, setModelPrimaryIntendedUsers_E] =
    useState("");
  const [modelOutOfScopeUseCases_E, setModelOutOfScopeUseCases_E] =
    useState("");

  // Factors
  const [modelGroups_E, setModelGroups_E] = useState("");
  const [modelInstrumentation_E, setModelInstrumentation_E] = useState("");
  const [modelEnvironment_E, setModelEnvironment_E] = useState("");

  // Metrics
  const [modelPerformanceMeasures_E, setModelPerformanceMeasures_E] =
    useState("");
  const [modelDecisionThresholds_E, setModelDecisionThresholds_E] =
    useState("");
  const [modelApproachesToUncertainty_E, setModelApproachesToUncertainty_E] =
    useState("");

  // Datasets
  const [modelTrainingDataset_E, setModelTrainingDataset_E] = useState("");
  const [modelTrainingMotivation_E, setModelTrainingMotivation_E] =
    useState("");
  const [modelTrainingPreprocessing_E, setModelTrainingPreprocessing_E] =
    useState("");
  const [modelEvaluationDataset_E, setModelEvaluationDataset_E] = useState("");
  const [modelEvaluationMotivation_E, setModelEvaluationMotivation_E] =
    useState("");
  const [modelEvaluationPreprocessing_E, setModelEvaluationPreprocessing_E] =
    useState("");

  // Analyses
  const [modelUnitaryResults_E, setModelUnitaryResults_E] = useState("");
  const [modelIntersectionalResults_E, setModelIntersectionalResults_E] =
    useState("");

  // Considerations and Biases
  const [modelData_E, setModelData_E] = useState("");
  const [modelHumanLife_E, setModelHumanLife_E] = useState("");
  const [modelMitigations_E, setModelMitigations_E] = useState("");
  const [modelRisksAndHarms_E, setModelRisksAndHarms_E] = useState("");
  const [modelUseCases_E, setModelUseCases_E] = useState("");

  // Recommendations
  const [modelDetails_E, setModelDetails_E] = useState("");

  const [selectedFile_E, setSelectedFile_E] = useState(null);
  const [showProgressBar_E, setShowProgressBar_E] = useState(false);
  const [progressBarPercentage_E, setProgressBarPercentage_E] = useState(0);
  const [cancelFileUpload_E, setCancelFileUpload_E] = useState(false);
  const [isPublish_E, setIsPublish_E] = useState(false);
  const cancelAction_E = useRef(null);

  const cancelProgressBar_E = () => {
    if (cancelAction_E.current) {
      cancelAction_E.current("User has canceled the action.");
      setCancelFileUpload_E(true);
      setProgressBarPercentage_E(0);
    }
  };

  const handleOnCloseProgressBar_E = () => {
    setShowProgressBar_E(false);
    if (progressBarPercentage_E !== 100) {
      cancelProgressBar_E();
    }
  };

  const handleUploadFile_E = (clickedPublishBtn) => {
    navigate("/models/profile/" + currentModel[0]?.id + "?tab=2");
    if (selectedFile_E.length > 0) {
      setShowProgressBar_E(true);
      setCancelFileUpload_E(false);
      setProgressBarPercentage_E(0);
      dispatch(
        editUploadModelFile(
          // currentModelVersion?.model_file[0]?.id,
          currentModelVersion?.model[0]?.model_file,
          selectedFile_E,
          (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgressBarPercentage_E(percentage);
          },
          new CancelToken((cancel) => (cancelAction_E.current = cancel))
        )
      )
        .then((res) => {
          setShowProgressBar_E(false);
          if (isPublish_E || clickedPublishBtn) {
            handleSaveAndPublish_E(currentModelVersion?.model[0]?.model_file);
          } else {
            saveDraft_E(currentModelVersion?.model[0]?.model_file);
          }
        })
        .catch((err) => {
          console.log(err);
          setProgressBarPercentage_E(0);
          setShowProgressBar_E(false);
        });
    }
  };

  const handleSaveDraft_E = () => {
    setIsPublish_E(false);
    if (
      selectedFile_E.length > 0 &&
      currentModelVersion?.model_file?.file_names?.length !==
        selectedFile_E.length
    ) {
      handleUploadFile_E(false);
    } else {
      saveDraft_E(currentModelVersion?.model[0]?.model_file);
      navigate("/models/profile/" + currentModel[0]?.id + "?tab=2");
    }
  };

  const saveDraft_E = (modelFileID) => {
    dispatch(
      editModelVersion(
        modelFileID,
        currentModel[0]?.id,
        modelNumber_E,
        modelVersionName_E,
        modelVersionDescription_E,
        modelContact_E,
        modelDevelopmentDate_E === "" ? null : modelDevelopmentDate_E,
        modelDevelopers_E,
        modelAccuracy_E,
        modelInput_E,
        modelOutput_E,
        modelType_E,
        modelPaper_E,
        modelCitationDetails_E,
        Object.values(modelLicensesJson).find(
          (license) =>
            license.id.toString() === modelLicense_E ||
            license.name.toString() === modelLicense_E
        )?.name,
        modelLicenseContent_E,
        modelOtherRelevantInfo_E,
        modelTrainingDataset_E,
        modelTrainingMotivation_E,
        modelTrainingPreprocessing_E,
        modelEvaluationDataset_E,
        modelEvaluationMotivation_E,
        modelEvaluationPreprocessing_E,
        modelUnitaryResults_E,
        modelIntersectionalResults_E,
        modelDetails_E
      )
    )
      .then((status) => {
        setToastStatus(status);
        setToastImage("/images/edit-success.svg");
      })
      .catch((status) => {
        if (status === "notif-in-app-network unreachable") {
          console.log("notif-in-app-network unreachable");
          setToastStatus("success");
          setToastImage("/images/add-version-draft-success.svg");
        } else {
          setToastStatus(status);
          setToastImage(null);
        }
      })
      .finally(() => {
        setShowToast(true);
        setRefreshModelVersionsCount(refreshModelVersionsCount + 1);
      });
  };

  const handleSaveAndPublish_E = (modelFileID) => {
    saveDraft_E(modelFileID);
    saveAndPublish(modelFileID);
  };

  const saveAndPublish = (modelFileID) => {
    dispatch(publishModelVersion(modelFileID))
      .then((status) => {
        setToastStatus(status);
        setToastImage("/images/publish-version-success.svg");
      })
      .catch((status) => {
        if (status === "notif-in-app-network unreachable") {
          console.log("notif-in-app-network unreachable");
          setToastStatus("success");
          setToastImage("/images/publish-version-success.svg");
        } else {
          setToastStatus(status);
          setToastImage(null);
        }
      })
      .finally(() => {
        setShowToast(true);
        setRefreshModelVersionsCount(refreshModelVersionsCount + 1);
      });
    navigate("/models/profile/" + currentModel[0]?.id + "?tab=2");
  };

  const handleDatasetSaveAndPublish = (datasetProfileID, datasetVersionID) => {
    dispatch(publishDatasetVersion(datasetProfileID, datasetVersionID))
      .then((status) => {
        setToastStatus(status);
        setToastImage("/images/publish-version-success.svg");
      })
      .catch((status) => {
        if (status === "notif-in-app-network unreachable") {
          console.log("notif-in-app-network unreachable");
          setToastStatus("success");
          setToastImage("/images/publish-version-success.svg");
        } else {
          setToastStatus(status);
          setToastImage(null);
        }
      })
      .finally(() => {
        setShowToast(true);
        setRefreshDatasetVersionsCount(refreshDatasetVersionsCount + 1);
      });
    navigate("/datasets/profile/" + datasetProfileID + "?tab=2");
  };

  const handleDeleteModelVersion = (modelFileID) => {
    dispatch(deleteModelVersion(modelFileID))
      .then((status) => {
        setShowToast(true);
        setToastStatus("error");
        setToastImage("/images/remove-success.svg");
        navigate("/models/profile/" + currentModel[0]?.id + "?tab=2");
      })
      .catch((status) => {
        setShowToast(true);
        setToastStatus(status);
        setToastImage(null);
      })
      .finally(() => {
        setRefreshModelVersionsCount(refreshModelVersionsCount + 1);
      });
  };

  const handleDeleteDatasetVersion = (datasetProfileID, datasetVersionID) => {
    dispatch(deleteDatasetVersion(datasetProfileID, datasetVersionID))
      .then((status) => {
        setShowToast(true);
        setToastStatus("error");
        setToastImage("/images/remove-success.svg");
        navigate("/datasets/profile/" + currentDataset?.id + "?tab=2");
      })
      .catch((status) => {
        setShowToast(true);
        setToastStatus(status);
        setToastImage(null);
      })
      .finally(() => {
        setRefreshDatasetVersionsCount(refreshDatasetVersionsCount + 1);
      });
  };

  const duplicateVersion = (modelProfileID, modelFileID) => {
    dispatch(duplicateModelVersion(modelProfileID, modelFileID))
      .then((status) => {
        setShowToast(true);
        setToastStatus(status);
        setToastImage("/images/duplicate-version-success.svg");
        navigate("/models/profile/" + currentModel[0]?.id + "?tab=2");
      })
      .catch((status) => {
        setShowToast(true);
        setToastStatus(status);
        setToastImage(null);
      })
      .finally(() => {
        setRefreshModelVersionsCount(refreshModelVersionsCount + 1);
      });
  };

  const handleDuplicateDatasetVersion = (
    datasetProfileID,
    datasetVersionID
  ) => {
    dispatch(duplicateDatasetVersion(datasetProfileID, datasetVersionID))
      .then((status) => {
        setShowToast(true);
        setToastStatus(status);
        setToastImage("/images/duplicate-version-success.svg");
        navigate("/datasets/profile/" + currentDataset?.id + "?tab=2");
      })
      .catch((status) => {
        setShowToast(true);
        setToastStatus(status);
        setToastImage(null);
      })
      .finally(() => {
        setRefreshDatasetVersionsCount(refreshDatasetVersionsCount + 1);
      });
  };

  const [showProgressBar_D, setShowProgressBar_D] = useState(false);
  const cancelAction_D = useRef(null);
  const [progressBarPercentage_D, setProgressBarPercentage_D] = useState(0);
  const [selectedFile_D, setSelectedFile_D] = useState(null);
  const [cancelFileUpload_D, setCancelFileUpload_D] = useState(false);

  const cancelProgressBar_D = () => {
    if (cancelAction_D.current) {
      cancelAction_D.current("User has canceled the action.");
      setCancelFileUpload_D(true);
      setProgressBarPercentage_D(0);
    }
  };

  const handleOnCloseProgressBar_D = () => {
    setShowProgressBar_D(false);
    if (progressBarPercentage_D !== 100) {
      cancelProgressBar_D();
    }
  };

  const downloadVersion = () => {
    setShowProgressBar_D(true);
    setCancelFileUpload_D(false);
    setSelectedFile_D(
      currentModel[0]?.model_profile_name +
        "_" +
        currentModelVersion?.model[0]?.version
    );
    dispatch(
      downloadModelVersion(
        currentModelVersion?.model[0]?.model_file,
        (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgressBarPercentage_D(percentage);
        },
        new CancelToken((cancel) => (cancelAction_D.current = cancel))
      )
    )
      .then((content) => {
        // setShowToast(true);
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(content);
        a.href = url;
        const arrVersionID =
          currentModelVersion?.model_file_details[0]?.version_id.split("-");
        const versionID = arrVersionID[arrVersionID.length - 1];
        a.download =
          currentModel[0]?.model_profile_name + "_" + versionID + ".zip";
        a.click();
        setShowProgressBar_D(false);
        setProgressBarPercentage_D(0);
        dispatch(addDownloads(currentModelVersion?.model[0]?.model_file)).catch(
          (err) => console.log(err)
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setRefreshModelVersionsCount(refreshModelVersionsCount + 1);
      });
  };

  const [showProgressBarDataset_D, setShowProgressBarDataset_D] =
    useState(false);
  const cancelActionDataset_D = useRef(null);
  const [progressBarPercentageDataset_D, setProgressBarPercentageDataset_D] =
    useState(0);
  const [selectedFileDataset_D, setSelectedFileDataset_D] = useState(null);
  const [cancelFileUploadDataset_D, setCancelFileUploadDataset_D] =
    useState(false);

  const handleDownloadDatasetVersion = () => {
    setShowProgressBarDataset_D(true);
    setCancelFileUploadDataset_D(false);
    // setSelectedFileDataset_D(
    //   currentDatasetVersion?.model_file[0]?.file.replace("/media/", "")
    // );
    dispatch(
      downloadDatasetVersion(
        currentDataset.id,
        currentDatasetVersion.id,
        (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgressBarPercentageDataset_D(percentage);
        },
        new CancelToken((cancel) => (cancelActionDataset_D.current = cancel))
      )
    )
      .then((content) => {
        // setShowToast(true);
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(content);
        a.href = url;
        // Naming convention should be "[DatasetProfileName]_[VersionNum]_[Subtitle]"
        a.download =
          currentDataset?.title +
          "_" +
          currentDatasetVersion?.version_id +
          "_" +
          currentDatasetVersion?.subtitle +
          ".zip";
        a.click();
        setShowProgressBarDataset_D(false);
        setProgressBarPercentageDataset_D(0);
        // dispatch(addDownloads(currentModelVersion?.model[0]?.model_file)).catch(
        //   (err) => console.log(err)
        // );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setRefreshDatasetVersionsCount(refreshDatasetVersionsCount + 1);
      });
  };

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/signin");
    }
    if (!location.pathname.includes("models")) {
      dispatch({
        type: GET_MODEL_FAIL,
      });
      setModelCurrentPage(1);
    }

    if (!location.pathname.includes("groups")) {
      dispatch({
        type: GET_GROUP_FAIL,
      });
      setGroupCurrentPage(1);
    }
  }, [location.pathname]);

  useEffect(() => {
    setShowProgressBar(false);
    setShowProgressBar_D(false);
    setShowProgressBar_E(false);
    setShowProgressBarDataset(false);
    setShowProgressBarDataset_E(false);
  }, [isLoggedIn]);

  const [showCancelDemoModal, setShowCancelDemoModal] = useState(false);

  const handleCancelDemoModalClose = () => {
    setShowCancelDemoModal(false);
  };

  return (
    <>
      <div
        className={`spinner-preload ${
          preload ? "display-spinner" : "hide-spinner"
        }`}
      >
        <Spinner
          className="spinner"
          as="span"
          animation="border"
          size="md"
          role="status"
          aria-hidden="true"
        />
        <p className="semi-bold">Loading</p>
      </div>
      <div className={disableLayout ? "cursor-not-allowed" : ""}>
        <div className={`layout ${disableLayout ? "pointer-events-none" : ""}`}>
          <Header
            showLogoutModal={showLogoutModal}
            setShowLogoutModal={setShowLogoutModal}
            setUrlToRedirected={setUrlToRedirected}
            setShowDiscardChangesModal={setShowDiscardChangesModal}
            changesMade={changesMade}
            setPreload={setPreload}
            setChangesMade={setChangesMade}
            urlToRedirected={urlToRedirected}
          />
          <div className="d-flex">
            {isLoggedIn && !location.pathname.includes("404") && (
              <Sidebar
                setPreload={setPreload}
                setShowToast={setShowToast}
                setToastStatus={setToastStatus}
                setToastImage={setToastImage}
                setUrlToRedirected={setUrlToRedirected}
                setShowDiscardChangesModal={setShowDiscardChangesModal}
                changesMade={changesMade}
              />
            )}
            <Col
              id={
                !location.pathname.includes("signin") &&
                !location.pathname.includes("signup") &&
                !location.pathname.includes("forgot-password") &&
                !location.pathname.includes("reset") &&
                !location.pathname.includes("activate") &&
                !location.pathname.includes("404")
                  ? "page-content-wrapper-2"
                  : "page-content-wrapper"
              }
            >
              <Routes>
                {/* PROTECTED ROUTES */}
                <Route element={<RequireAuth />}>
                  <Route
                    path="/groups"
                    element={
                      <GroupList
                        setPreload={setPreload}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                        setGroupCurrentPage={setGroupCurrentPage}
                        groupCurrentPage={groupCurrentPage}
                      />
                    }
                  />
                  <Route
                    path="/models"
                    element={
                      <ModelList
                        setPreload={setPreload}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                        setModelCurrentPage={setModelCurrentPage}
                        modelCurrentPage={modelCurrentPage}
                        setUrlToRedirected={setUrlToRedirected}
                        setShowDiscardChangesModal={setShowDiscardChangesModal}
                        changesMade={changesMade}
                      />
                    }
                  />
                  <Route
                    path="/datasets"
                    element={
                      <DatasetList
                        setPreload={setPreload}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                        setDatasetCurrentPage={setDatasetCurrentPage}
                        datasetCurrentPage={datasetCurrentPage}
                        setUrlToRedirected={setUrlToRedirected}
                        setShowDiscardChangesModal={setShowDiscardChangesModal}
                        changesMade={changesMade}
                      />
                    }
                  />
                  <Route
                    path="/groups/profile/:id"
                    element={
                      <GroupProfile
                        setPreload={setPreload}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                      />
                    }
                  />
                  <Route
                    path="/models/profile/:id"
                    element={
                      <ModelProfile
                        setPreload={setPreload}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                        refreshModelVersionsCount={refreshModelVersionsCount}
                        setRefreshModelVersionsCount={
                          setRefreshModelVersionsCount
                        }
                        setModelCurrentPage={setModelCurrentPage}
                        setDisableLayout={setDisableLayout}
                        showCancelDemoModal={showCancelDemoModal}
                        setShowCancelDemoModal={setShowCancelDemoModal}
                        handleCancelDemoModalClose={handleCancelDemoModalClose}
                        saveAndPublish={saveAndPublish}
                        deleteModelVersion={handleDeleteModelVersion}
                        duplicateModelVersion={duplicateVersion}
                      />
                    }
                  />
                  <Route
                    path="/datasets/profile/:id"
                    element={
                      <DatasetProfile
                        setPreload={setPreload}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                        refreshDatasetVersionsCount={
                          refreshDatasetVersionsCount
                        }
                        setRefreshDatasetVersionsCount={
                          setRefreshDatasetVersionsCount
                        }
                        setDatasetCurrentPage={setDatasetCurrentPage}
                        saveAndPublish={handleDatasetSaveAndPublish}
                        deleteDatasetVersion={handleDeleteDatasetVersion}
                        duplicateDatasetVersion={handleDuplicateDatasetVersion}
                      />
                    }
                  />
                  <Route
                    path="/models/profile/:profileID/version/:id"
                    element={
                      <ViewModelVersion
                        setPreload={setPreload}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                        refreshModelVersionsCount={refreshModelVersionsCount}
                        setRefreshModelVersionsCount={
                          setRefreshModelVersionsCount
                        }
                        saveAndPublish={saveAndPublish}
                        deleteModelVersion={handleDeleteModelVersion}
                        downloadModelVersion={downloadVersion}
                        duplicateModelVersion={duplicateVersion}
                      />
                    }
                  />
                  <Route
                    path="/datasets/profile/:profileID/version/:id"
                    element={
                      <ViewDatasetVersion
                        setPreload={setPreload}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                        refreshDatasetVersionsCount={
                          refreshDatasetVersionsCount
                        }
                        setRefreshDatasetVersionsCount={
                          setRefreshDatasetVersionsCount
                        }
                        saveAndPublish={handleDatasetSaveAndPublish}
                        deleteDatasetVersion={handleDeleteDatasetVersion}
                        downloadDatasetVersion={handleDownloadDatasetVersion}
                        duplicateDatasetVersion={handleDuplicateDatasetVersion}
                      />
                    }
                  />
                  <Route
                    path="/models/profile/:profileID/version/add/"
                    element={
                      <AddModelVersion
                        setPreload={setPreload}
                        showDiscardChangesModal={showDiscardChangesModal}
                        setShowDiscardChangesModal={setShowDiscardChangesModal}
                        changesMade={changesMade}
                        setChangesMade={setChangesMade}
                        urlToRedirected={urlToRedirected}
                        setUrlToRedirected={setUrlToRedirected}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        handleSaveDraft={handleSaveDraft}
                        handleUploadFile={handleUploadFile}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                        modelNumber={modelNumber}
                        modelVersionName={modelVersionName}
                        modelVersionDescription={modelVersionDescription}
                        modelAuthor={modelAuthor}
                        modelContact={modelContact}
                        setModelNumber={setModelNumber}
                        setModelVersionName={setModelVersionName}
                        setModelVersionDescription={setModelVersionDescription}
                        setModelAuthor={setModelAuthor}
                        setModelContact={setModelContact}
                        modelDevelopmentDate={modelDevelopmentDate}
                        modelDevelopers={modelDevelopers}
                        modelAccuracy={modelAccuracy}
                        modelInput={modelInput}
                        modelOutput={modelOutput}
                        modelType={modelType}
                        modelPaper={modelPaper}
                        modelCitationDetails={modelCitationDetails}
                        modelLicense={modelLicense}
                        modelLicenseContent={modelLicenseContent}
                        modelOtherRelevantInfo={modelOtherRelevantInfo}
                        setModelDevelopmentDate={setModelDevelopmentDate}
                        setModelDevelopers={setModelDevelopers}
                        setModelAccuracy={setModelAccuracy}
                        setModelInput={setModelInput}
                        setModelOutput={setModelOutput}
                        setModelType={setModelType}
                        setModelPaper={setModelPaper}
                        setModelCitationDetails={setModelCitationDetails}
                        setModelLicense={setModelLicense}
                        setModelLicenseContent={setModelLicenseContent}
                        setModelOtherRelevantInfo={setModelOtherRelevantInfo}
                        modelPrimaryIntendedUses={modelPrimaryIntendedUses}
                        modelPrimaryIntendedUsers={modelPrimaryIntendedUsers}
                        modelOutOfScopeUseCases={modelOutOfScopeUseCases}
                        setModelPrimaryIntendedUses={
                          setModelPrimaryIntendedUses
                        }
                        setModelPrimaryIntendedUsers={
                          setModelPrimaryIntendedUsers
                        }
                        setModelOutOfScopeUseCases={setModelOutOfScopeUseCases}
                        modelGroups={modelGroups}
                        modelInstrumentation={modelInstrumentation}
                        modelEnvironment={modelEnvironment}
                        setModelGroups={setModelGroups}
                        setModelInstrumentation={setModelInstrumentation}
                        setModelEnvironment={setModelEnvironment}
                        modelPerformanceMeasures={modelPerformanceMeasures}
                        modelDecisionThresholds={modelDecisionThresholds}
                        modelApproachesToUncertainty={
                          modelApproachesToUncertainty
                        }
                        setModelPerformanceMeasures={
                          setModelPerformanceMeasures
                        }
                        setModelDecisionThresholds={setModelDecisionThresholds}
                        setModelApproachesToUncertainty={
                          setModelApproachesToUncertainty
                        }
                        modelTrainingDataset={modelTrainingDataset}
                        modelTrainingMotivation={modelTrainingMotivation}
                        modelTrainingPreprocessing={modelTrainingPreprocessing}
                        modelEvaluationDataset={modelEvaluationDataset}
                        modelEvaluationMotivation={modelEvaluationMotivation}
                        modelEvaluationPreprocessing={
                          modelEvaluationPreprocessing
                        }
                        setModelTrainingDataset={setModelTrainingDataset}
                        setModelTrainingMotivation={setModelTrainingMotivation}
                        setModelTrainingPreprocessing={
                          setModelTrainingPreprocessing
                        }
                        setModelEvaluationDataset={setModelEvaluationDataset}
                        setModelEvaluationMotivation={
                          setModelEvaluationMotivation
                        }
                        setModelEvaluationPreprocessing={
                          setModelEvaluationPreprocessing
                        }
                        modelUnitaryResults={modelUnitaryResults}
                        modelIntersectionalResults={modelIntersectionalResults}
                        setModelUnitaryResults={setModelUnitaryResults}
                        setModelIntersectionalResults={
                          setModelIntersectionalResults
                        }
                        modelData={modelData}
                        modelHumanLife={modelHumanLife}
                        modelMitigations={modelMitigations}
                        modelRisksAndHarms={modelRisksAndHarms}
                        modelUseCases={modelUseCases}
                        setModelData={setModelData}
                        setModelHumanLife={setModelHumanLife}
                        setModelMitigations={setModelMitigations}
                        setModelRisksAndHarms={setModelRisksAndHarms}
                        setModelUseCases={setModelUseCases}
                        modelDetails={modelDetails}
                        setModelDetails={setModelDetails}
                      />
                    }
                  />
                  <Route
                    path="/datasets/version/add/:id"
                    element={
                      <AddDatasetVersion
                        setPreload={setPreload}
                        showDiscardChangesModal={showDiscardChangesModal}
                        setShowDiscardChangesModal={setShowDiscardChangesModal}
                        changesMade={changesMade}
                        setChangesMade={setChangesMade}
                        urlToRedirected={urlToRedirected}
                        setUrlToRedirected={setUrlToRedirected}
                        datasetSelectedFile={datasetSelectedFile}
                        setDatasetSelectedFile={setDatasetSelectedFile}
                        handleSaveDraft={handleSaveDraftDataset}
                        handleSaveAndPublishDataset={
                          handleSaveAndPublishDataset
                        }
                        handleUploadFile={handleUploadFile}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                        datasetVersion={datasetVersion}
                        datasetSubtitle={datasetSubtitle}
                        datasetVersionDescription={datasetVersionDescription}
                        datasetContributor={datasetContributor}
                        datasetKeywords={datasetKeywords}
                        datasetCitationDetails={datasetCitationDetails}
                        datasetOtherRelevantInfo={datasetOtherRelevantInfo}
                        datasetLicense={datasetLicense}
                        datasetLicenseContent={datasetLicenseContent}
                        setDatasetVersion={setDatasetVersion}
                        setDatasetSubtitle={setDatasetSubtitle}
                        setDatasetVersionDescription={
                          setDatasetVersionDescription
                        }
                        setDatasetContributor={setDatasetContributor}
                        setDatasetKeywords={setDatasetKeywords}
                        setDatasetCitationDetails={setDatasetCitationDetails}
                        setDatasetOtherRelevantInfo={
                          setDatasetOtherRelevantInfo
                        }
                        setDatasetLicense={setDatasetLicense}
                        setDatasetLicenseContent={setDatasetLicenseContent}
                      />
                    }
                  />
                  <Route
                    path="/models/profile/:profileID/version/edit/:id"
                    element={
                      <EditModelVersion
                        setPreload={setPreload}
                        showDiscardChangesModal={showDiscardChangesModal}
                        setShowDiscardChangesModal={setShowDiscardChangesModal}
                        changesMade={changesMade}
                        setChangesMade={setChangesMade}
                        urlToRedirected={urlToRedirected}
                        setUrlToRedirected={setUrlToRedirected}
                        selectedFile={selectedFile_E}
                        setSelectedFile={setSelectedFile_E}
                        handleSaveDraft={handleSaveDraft_E}
                        handleUploadFile={handleUploadFile_E}
                        handleSaveAndPublish={handleSaveAndPublish_E}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                        modelNumber={modelNumber_E}
                        modelVersionName={modelVersionName_E}
                        modelVersionDescription={modelVersionDescription_E}
                        modelAuthor={modelAuthor_E}
                        modelContact={modelContact_E}
                        setModelNumber={setModelNumber_E}
                        setModelVersionName={setModelVersionName_E}
                        setModelVersionDescription={
                          setModelVersionDescription_E
                        }
                        setModelAuthor={setModelAuthor_E}
                        setModelContact={setModelContact_E}
                        modelDevelopmentDate={modelDevelopmentDate_E}
                        modelDevelopers={modelDevelopers_E}
                        modelAccuracy={modelAccuracy_E}
                        modelInput={modelInput_E}
                        modelOutput={modelOutput_E}
                        modelType={modelType_E}
                        modelPaper={modelPaper_E}
                        modelCitationDetails={modelCitationDetails_E}
                        modelLicense={modelLicense_E}
                        modelLicenseContent={modelLicenseContent_E}
                        modelOtherRelevantInfo={modelOtherRelevantInfo_E}
                        setModelDevelopmentDate={setModelDevelopmentDate_E}
                        setModelDevelopers={setModelDevelopers_E}
                        setModelAccuracy={setModelAccuracy_E}
                        setModelInput={setModelInput_E}
                        setModelOutput={setModelOutput_E}
                        setModelType={setModelType_E}
                        setModelPaper={setModelPaper_E}
                        setModelCitationDetails={setModelCitationDetails_E}
                        setModelLicense={setModelLicense_E}
                        setModelLicenseContent={setModelLicenseContent_E}
                        setModelOtherRelevantInfo={setModelOtherRelevantInfo_E}
                        modelPrimaryIntendedUses={modelPrimaryIntendedUses_E}
                        modelPrimaryIntendedUsers={modelPrimaryIntendedUsers_E}
                        modelOutOfScopeUseCases={modelOutOfScopeUseCases_E}
                        setModelPrimaryIntendedUses={
                          setModelPrimaryIntendedUses_E
                        }
                        setModelPrimaryIntendedUsers={
                          setModelPrimaryIntendedUsers_E
                        }
                        setModelOutOfScopeUseCases={
                          setModelOutOfScopeUseCases_E
                        }
                        modelGroups={modelGroups_E}
                        modelInstrumentation={modelInstrumentation_E}
                        modelEnvironment={modelEnvironment_E}
                        setModelGroups={setModelGroups_E}
                        setModelInstrumentation={setModelInstrumentation_E}
                        setModelEnvironment={setModelEnvironment_E}
                        modelPerformanceMeasures={modelPerformanceMeasures_E}
                        modelDecisionThresholds={modelDecisionThresholds_E}
                        modelApproachesToUncertainty={
                          modelApproachesToUncertainty_E
                        }
                        setModelPerformanceMeasures={
                          setModelPerformanceMeasures_E
                        }
                        setModelDecisionThresholds={
                          setModelDecisionThresholds_E
                        }
                        setModelApproachesToUncertainty={
                          setModelApproachesToUncertainty_E
                        }
                        modelTrainingDataset={modelTrainingDataset_E}
                        modelTrainingMotivation={modelTrainingMotivation_E}
                        modelTrainingPreprocessing={
                          modelTrainingPreprocessing_E
                        }
                        modelEvaluationDataset={modelEvaluationDataset_E}
                        modelEvaluationMotivation={modelEvaluationMotivation_E}
                        modelEvaluationPreprocessing={
                          modelEvaluationPreprocessing_E
                        }
                        setModelTrainingDataset={setModelTrainingDataset_E}
                        setModelTrainingMotivation={
                          setModelTrainingMotivation_E
                        }
                        setModelTrainingPreprocessing={
                          setModelTrainingPreprocessing_E
                        }
                        setModelEvaluationDataset={setModelEvaluationDataset_E}
                        setModelEvaluationMotivation={
                          setModelEvaluationMotivation_E
                        }
                        setModelEvaluationPreprocessing={
                          setModelEvaluationPreprocessing_E
                        }
                        modelUnitaryResults={modelUnitaryResults_E}
                        modelIntersectionalResults={
                          modelIntersectionalResults_E
                        }
                        setModelUnitaryResults={setModelUnitaryResults_E}
                        setModelIntersectionalResults={
                          setModelIntersectionalResults_E
                        }
                        modelData={modelData_E}
                        modelHumanLife={modelHumanLife_E}
                        modelMitigations={modelMitigations_E}
                        modelRisksAndHarms={modelRisksAndHarms_E}
                        modelUseCases={modelUseCases_E}
                        setModelData={setModelData_E}
                        setModelHumanLife={setModelHumanLife_E}
                        setModelMitigations={setModelMitigations_E}
                        setModelRisksAndHarms={setModelRisksAndHarms_E}
                        setModelUseCases={setModelUseCases_E}
                        modelDetails={modelDetails_E}
                        setModelDetails={setModelDetails_E}
                      />
                    }
                  />
                  <Route
                    path="/datasets/profile/:profileID/version/edit/:id"
                    element={
                      <EditDatasetVersion
                        setPreload={setPreload}
                        showDiscardChangesModal={showDiscardChangesModal}
                        setShowDiscardChangesModal={setShowDiscardChangesModal}
                        changesMade={changesMade}
                        setChangesMade={setChangesMade}
                        urlToRedirected={urlToRedirected}
                        setUrlToRedirected={setUrlToRedirected}
                        datasetSelectedFile={datasetSelectedFile}
                        setDatasetSelectedFile={setDatasetSelectedFile}
                        handleSaveDraft={handleSaveDraftDataset_E}
                        handleSaveAndPublishDataset={
                          handleSaveAndPublishDataset_E
                        }
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                        datasetVersionID={datasetVersionID}
                        datasetVersion={datasetVersion}
                        datasetSubtitle={datasetSubtitle}
                        datasetVersionDescription={datasetVersionDescription}
                        datasetContributor={datasetContributor}
                        datasetKeywords={datasetKeywords}
                        datasetCitationDetails={datasetCitationDetails}
                        datasetOtherRelevantInfo={datasetOtherRelevantInfo}
                        datasetLicense={datasetLicense}
                        datasetLicenseContent={datasetLicenseContent}
                        setDatasetVersionID={setDatasetVersionID}
                        setDatasetVersion={setDatasetVersion}
                        setDatasetSubtitle={setDatasetSubtitle}
                        setDatasetVersionDescription={
                          setDatasetVersionDescription
                        }
                        setDatasetContributor={setDatasetContributor}
                        setDatasetKeywords={setDatasetKeywords}
                        setDatasetCitationDetails={setDatasetCitationDetails}
                        setDatasetOtherRelevantInfo={
                          setDatasetOtherRelevantInfo
                        }
                        setDatasetLicense={setDatasetLicense}
                        setDatasetLicenseContent={setDatasetLicenseContent}
                      />
                    }
                  />
                  <Route
                    path="/myaccount/security"
                    element={
                      <Security
                        setPreload={setPreload}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                      />
                    }
                  />
                  <Route
                    path="/myaccount/personalinfo"
                    element={
                      <PersonalInfo
                        setPreload={setPreload}
                        setShowToast={setShowToast}
                        setToastStatus={setToastStatus}
                        setToastImage={setToastImage}
                      />
                    }
                  />
                  <Route
                    path="/notifications"
                    element={
                      <Notification
                        setPreload={setPreload}
                        setChangesMade={setChangesMade}
                        urlToRedirected={urlToRedirected}
                        setUrlToRedirected={setUrlToRedirected}
                      />
                    }
                  />
                </Route>
                <Route
                  path="/signin"
                  element={
                    <SignIn
                      setPreload={setPreload}
                      setShowToast={setShowToast}
                      setToastStatus={setToastStatus}
                      setToastImage={setToastImage}
                    />
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <SignUp
                      setShowToast={setShowToast}
                      setToastStatus={setToastStatus}
                      setToastImage={setToastImage}
                    />
                  }
                />
                <Route
                  path="/activate/:uid/:token"
                  element={<AccountActivate />}
                />
                <Route
                  path="/forgot-password"
                  element={
                    <ForgotPassword
                      setShowToast={setShowToast}
                      setToastStatus={setToastStatus}
                      setToastImage={setToastImage}
                    />
                  }
                />
                <Route
                  path="/password/reset/confirm/:uid/:token"
                  element={
                    <SetPassword
                      setShowToast={setShowToast}
                      setToastStatus={setToastStatus}
                      setToastImage={setToastImage}
                    />
                  }
                />
                <Route path="/404" element={<PageNotFound />} />
                <Route path="*" element={<Navigate to="/404" />} />
              </Routes>
            </Col>
          </div>
          {showProgressBarDataset && (
            <CustomProgressBar
              upload={true}
              handleOnCloseProgressBar={handleOnCloseProgressBarDataset}
              handleUploadFile={
                isPublishDataset
                  ? handleSaveAndPublishDataset
                  : handleSaveDraftDataset
              }
              cancelFileUpload={cancelFileUploadDataset}
              cancelProgressBar={cancelProgressBarDataset}
              progressBarPercentage={progressBarPercentageDataset}
              filename={datasetSelectedFile?.name}
            />
          )}
          {showProgressBarDataset_E && (
            <CustomProgressBar
              upload={true}
              handleOnCloseProgressBar={handleOnCloseProgressBarDataset_E}
              handleUploadFile={handleUploadFileDataset_E}
              cancelFileUpload={cancelFileUploadDataset_E}
              cancelProgressBar={cancelProgressBarDataset_E}
              progressBarPercentage={progressBarPercentageDataset_E}
              filename={datasetSelectedFile?.name}
            />
          )}
          {showProgressBar && (
            <CustomProgressBar
              upload={true}
              handleOnCloseProgressBar={handleOnCloseProgressBar}
              handleUploadFile={handleUploadFile}
              cancelFileUpload={cancelFileUpload}
              cancelProgressBar={cancelProgressBar}
              progressBarPercentage={progressBarPercentage}
              filename={selectedFile?.name}
            />
          )}
          {showProgressBar_D && (
            <CustomProgressBar
              upload={false}
              handleOnCloseProgressBar={handleOnCloseProgressBar_D}
              downloadVersion={downloadVersion}
              cancelFileUpload={cancelFileUpload_D}
              cancelProgressBar={cancelProgressBar_D}
              progressBarPercentage={progressBarPercentage_D}
              filename={selectedFile_D}
            />
          )}
          {showProgressBar_E && (
            <CustomProgressBar
              upload={true}
              handleOnCloseProgressBar={handleOnCloseProgressBar_E}
              handleUploadFile={handleUploadFile_E}
              cancelFileUpload={cancelFileUpload_E}
              cancelProgressBar={cancelProgressBar_E}
              progressBarPercentage={progressBarPercentage_E}
              filename={selectedFile_E?.name}
            />
          )}
          {message && (
            <ToastContainer
              className="p-3 position-fixed bottom-0 start-0"
              style={{ zIndex: 9999 }}
            >
              <Toast
                show={showToast}
                onClose={handleDismiss}
                delay={3000}
                autohide
                className={`message d-flex justify-content-between align-items-center p-3 ${
                  toastStatus === "success" ? "success" : "error"
                }`}
              >
                <span>
                  {toastImage && (
                    <img
                      src={toastImage}
                      className="me-3 img-fluid"
                      alt="logo"
                    />
                  )}
                  <span>{message}</span>
                </span>
                <img
                  onClick={() => setShowToast(false)}
                  src="/images/close-toast.svg"
                  className="img-fluid"
                  alt="logo"
                />
              </Toast>
            </ToastContainer>
          )}
        </div>
      </div>
      <span className="version">{version}</span>
    </>
  );
};
export default App;
