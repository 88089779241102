"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformStreamImplementation = exports.TransformerImplementation = void 0;
var TransformerImplementation = /** @class */ (function () {
    function TransformerImplementation(boundaryDelimiter) {
        this.textDecoder = new TextDecoder('ascii');
        this.buffer = new Uint8Array(0);
        this.boundaryDelimiter = '--' + boundaryDelimiter;
    }
    TransformerImplementation.prototype.transform = function (chunk, controller) {
        var oldBuffer = this.buffer;
        this.buffer = new Uint8Array(oldBuffer.byteLength + chunk.byteLength);
        this.buffer.set(oldBuffer);
        this.buffer.set(chunk, oldBuffer.byteLength);
        while (true) {
            var decodedData = this.textDecoder.decode(this.buffer);
            var delimiter = this.boundaryDelimiter;
            var delimiterIndex = decodedData.indexOf(delimiter);
            var startString = '\r\n\r\n';
            var partStartIndex = decodedData.indexOf(startString, delimiterIndex + delimiter.length) + startString.length;
            var partEndIndex = decodedData.indexOf(delimiter, partStartIndex);
            // data is incomplete; defer the processing
            if (partStartIndex == -1 || partEndIndex == -1)
                break;
            partEndIndex -= 1; // move the index to the left to properly split the data
            // parse content type header
            var contentTypeHeader = decodedData.slice(delimiterIndex + delimiter.length + 2, partStartIndex);
            var contentType = contentTypeHeader.split(':')[1].trim();
            var data = this.buffer.slice(partStartIndex, partEndIndex);
            if (contentType === 'application/json') {
                var textEncoder = new TextDecoder();
                var decodedText = textEncoder.decode(data);
                controller.enqueue(JSON.parse(decodedText));
                this.buffer = this.buffer.slice(partEndIndex + 1);
            }
            else if (contentType === 'image/jpeg') {
                var blob = new Blob([data], { type: contentType });
                controller.enqueue(blob);
                this.buffer = this.buffer.slice(partEndIndex + 1);
            }
            else {
                this.buffer = this.buffer.slice(partEndIndex + 1);
            }
        }
    };
    return TransformerImplementation;
}());
exports.TransformerImplementation = TransformerImplementation;
// eslint-disable-next-line
var TransformStreamImplementation = /** @class */ (function (_super) {
    __extends(TransformStreamImplementation, _super);
    function TransformStreamImplementation(transformer) {
        return _super.call(this, transformer) || this;
    }
    return TransformStreamImplementation;
}(TransformStream));
exports.TransformStreamImplementation = TransformStreamImplementation;
