import ReactDOMServer from "react-dom/server";

const getLicense = (license) => {
  switch (license) {
    case "1": {
      return (
        <>
          <p>Copyright [yyyy] [name of copyright owner]</p>
          <p>
            Licensed under the Apache License, Version 2.0 (the "License"); you
            may not use this file except in compliance with the License. You may
            obtain a copy of the License at
          </p>
          <br />
          <br />
          <p>http://www.apache.org/licenses/LICENSE-2.0</p>
          <p>
            Unless required by applicable law or agreed to in writing, software
            distributed under the License is distributed on an "AS IS" BASIS,
            WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or
            implied. See the License for the specific language governing
            permissions and limitations under the License.
          </p>
        </>
      );
    }
    case "2": {
      return (
        <>
          <p>
            1. Definitions.
            <br />
            1.1. Contributor means each individual or entity that creates or
            contributes to the creation of Modifications.
            <br />
            1.2. Contributor Version means the combination of the Original
            Software, prior Modifications used by a Contributor (if any), and
            the Modifications made by that particular Contributor.
            <br />
            1.3. Covered Software means (a) the Original Software, or (b)
            Modifications, or (c) the combination of files containing Original
            Software with files containing Modifications, in each case including
            portions thereof.
            <br />
            1.4. Executable means the Covered Software in any form other than
            Source Code.
            <br />
            1.5. Initial Developer means the individual or entity that first
            makes Original Software available under this License.
            <br />
            1.6. Larger Work means a work which combines Covered Software or
            portions thereof with code not governed by the terms of this
            License.
            <br />
            1.7. License means this document.
            <br />
            1.8. Licensable means having the right to grant, to the maximum
            extent possible, whether at the time of the initial grant or
            subsequently acquired, any and all of the rights conveyed herein.
            <br />
            1.9. Modifications means the Source Code and Executable form of any
            of the following:
            <br />
            A. Any file that results from an addition to, deletion from or
            modification of the contents of a file containing Original Software
            or previous Modifications;
            <br />
            B. Any new file that contains any part of the Original Software or
            previous Modification; or
            <br />
            C. Any new file that is contributed or otherwise made available
            under the terms of this License.
            <br />
            1.10. Original Software means the Source Code and Executable form of
            computer software code that is originally released under this
            License.
            <br />
            1.11. Patent Claims means any patent claim(s), now owned or
            hereafter acquired, including without limitation, method, process,
            and apparatus claims, in any patent Licensable by grantor.
            <br />
            1.12. Source Code means (a) the common form of computer software
            code in which modifications are made and (b) associated
            documentation included in or with such code.
            <br />
            1.13. You (or Your) means an individual or a legal entity exercising
            rights under, and complying with all of the terms of, this License.
            For legal entities, You includes any entity which controls, is
            controlled by, or is under common control with You. For purposes of
            this definition, control means (a) the power, direct or indirect, to
            cause the direction or management of such entity, whether by
            contract or otherwise, or (b) ownership of more than fifty percent
            (50%) of the outstanding shares or beneficial ownership of such
            entity.
            <br />
            2. License Grants.
            <br />
            2.1. The Initial Developer Grant.Conditioned upon Your compliance
            with Section 3.1 below and subject to third party intellectual
            property claims, the Initial Developer hereby grants You a
            world-wide, royalty-free, non-exclusive license:
            <br />
            (a) under intellectual property rights (other than patent or
            trademark) Licensable by Initial Developer, to use, reproduce,
            modify, display, perform, sublicense and distribute the Original
            Software (or portions thereof), with or without Modifications,
            and/or as part of a Larger Work; and
            <br />
            (b) under Patent Claims infringed by the making, using or selling of
            Original Software, to make, have made, use, practice, sell, and
            offer for sale, and/or otherwise dispose of the Original Software
            (or portions thereof).
            <br />
            (c) The licenses granted in Sections 2.1(a) and (b) are effective on
            the date Initial Developer first distributes or otherwise makes the
            Original Software available to a third party under the terms of this
            License.
            <br />
            (d) Notwithstanding Section 2.1(b) above, no patent license is
            granted: (1) for code that You delete from the Original Software, or
            (2) for infringements caused by: (i) the modification of the
            Original Software, or (ii) the combination of the Original Software
            with other software or devices.
            <br />
            2.2. Contributor Grant.Conditioned upon Your compliance with Section
            3.1 below and subject to third party intellectual property claims,
            each Contributor hereby grants You a world-wide, royalty-free,
            non-exclusive license:
            <br />
            (a) under intellectual property rights (other than patent or
            trademark) Licensable by Contributor to use, reproduce, modify,
            display, perform, sublicense and distribute the Modifications
            created by such Contributor (or portions thereof), either on an
            unmodified basis, with other Modifications, as Covered Software
            and/or as part of a Larger Work; and
            <br />
            (b) under Patent Claims infringed by the making, using, or selling
            of Modifications made by that Contributor either alone and/or in
            combination with its Contributor Version (or portions of such
            combination), to make, use, sell, offer for sale, have made, and/or
            otherwise dispose of: (1) Modifications made by that Contributor (or
            portions thereof); and (2) the combination of Modifications made by
            that Contributor with its Contributor Version (or portions of such
            combination).
            <br />
            (c) The licenses granted in Sections 2.2(a) and 2.2(b) are effective
            on the date Contributor first distributes or otherwise makes the
            Modifications available to a third party.
            <br />
            (d) Notwithstanding Section 2.2(b) above, no patent license is
            granted: (1) for any code that Contributor has deleted from the
            Contributor Version; (2) for infringements caused by: (i) third
            party modifications of Contributor Version, or (ii) the combination
            of Modifications made by that Contributor with other software
            (except as part of the Contributor Version) or other devices; or (3)
            under Patent Claims infringed by Covered Software in the absence of
            Modifications made by that Contributor.
            <br />
            3. Distribution Obligations.
            <br />
            3.1. Availability of Source Code.Any Covered Software that You
            distribute or otherwise make available in Executable form must also
            be made available in Source Code form and that Source Code form must
            be distributed only under the terms of this License. You must
            include a copy of this License with every copy of the Source Code
            form of the Covered Software You distribute or otherwise make
            available. You must inform recipients of any such Covered Software
            in Executable form as to how they can obtain such Covered Software
            in Source Code form in a reasonable manner on or through a medium
            customarily used for software exchange.
            <br />
            3.2. Modifications.The Modifications that You create or to which You
            contribute are governed by the terms of this License. You represent
            that You believe Your Modifications are Your original creation(s)
            and/or You have sufficient rights to grant the rights conveyed by
            this License.
            <br />
            3.3. Required Notices.You must include a notice in each of Your
            Modifications that identifies You as the Contributor of the
            Modification. You may not remove or alter any copyright, patent or
            trademark notices contained within the Covered Software, or any
            notices of licensing or any descriptive text giving attribution to
            any Contributor or the Initial Developer.
            <br />
            3.4. Application of Additional Terms.You may not offer or impose any
            terms on any Covered Software in Source Code form that alters or
            restricts the applicable version of this License or the recipients
            rights hereunder. You may choose to offer, and to charge a fee for,
            warranty, support, indemnity or liability obligations to one or more
            recipients of Covered Software. However, you may do so only on Your
            own behalf, and not on behalf of the Initial Developer or any
            Contributor. You must make it absolutely clear that any such
            warranty, support, indemnity or liability obligation is offered by
            You alone, and You hereby agree to indemnify the Initial Developer
            and every Contributor for any liability incurred by the Initial
            Developer or such Contributor as a result of warranty, support,
            indemnity or liability terms You offer.
            <br />
            3.5. Distribution of Executable Versions.You may distribute the
            Executable form of the Covered Software under the terms of this
            License or under the terms of a license of Your choice, which may
            contain terms different from this License, provided that You are in
            compliance with the terms of this License and that the license for
            the Executable form does not attempt to limit or alter the
            recipients rights in the Source Code form from the rights set forth
            in this License. If You distribute the Covered Software in
            Executable form under a different license, You must make it
            absolutely clear that any terms which differ from this License are
            offered by You alone, not by the Initial Developer or Contributor.
            You hereby agree to indemnify the Initial Developer and every
            Contributor for any liability incurred by the Initial Developer or
            such Contributor as a result of any such terms You offer.
            <br />
            3.6. Larger Works.You may create a Larger Work by combining Covered
            Software with other code not governed by the terms of this License
            and distribute the Larger Work as a single product. In such a case,
            You must make sure the requirements of this License are fulfilled
            for the Covered Software.
            <br />
            4. Versions of the License.
            <br />
            4.1. New Versions.Sun Microsystems, Inc. is the initial license
            steward and may publish revised and/or new versions of this License
            from time to time. Each version will be given a distinguishing
            version number. Except as provided in Section 4.3, no one other than
            the license steward has the right to modify this License.
            <br />
            4.2. Effect of New Versions.You may always continue to use,
            distribute or otherwise make the Covered Software available under
            the terms of the version of the License under which You originally
            received the Covered Software. If the Initial Developer includes a
            notice in the Original Software prohibiting it from being
            distributed or otherwise made available under any subsequent version
            of the License, You must distribute and make the Covered Software
            available under the terms of the version of the License under which
            You originally received the Covered Software. Otherwise, You may
            also choose to use, distribute or otherwise make the Covered
            Software available under the terms of any subsequent version of the
            License published by the license steward.
            <br />
            4.3. Modified Versions.When You are an Initial Developer and You
            want to create a new license for Your Original Software, You may
            create and use a modified version of this License if You: (a) rename
            the license and remove any references to the name of the license
            steward (except to note that the license differs from this License);
            and (b) otherwise make it clear that the license contains terms
            which differ from this License.
            <br />
            5. DISCLAIMER OF WARRANTY.COVERED SOFTWARE IS PROVIDED UNDER THIS
            LICENSE ON AN AS IS BASIS, WITHOUT WARRANTY OF ANY KIND, EITHER
            EXPRESSED OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES THAT
            THE COVERED SOFTWARE IS FREE OF DEFECTS, MERCHANTABLE, FIT FOR A
            PARTICULAR PURPOSE OR NON-INFRINGING. THE ENTIRE RISK AS TO THE
            QUALITY AND PERFORMANCE OF THE COVERED SOFTWARE IS WITH YOU. SHOULD
            ANY COVERED SOFTWARE PROVE DEFECTIVE IN ANY RESPECT, YOU (NOT THE
            INITIAL DEVELOPER OR ANY OTHER CONTRIBUTOR) ASSUME THE COST OF ANY
            NECESSARY SERVICING, REPAIR OR CORRECTION. THIS DISCLAIMER OF
            WARRANTY CONSTITUTES AN ESSENTIAL PART OF THIS LICENSE. NO USE OF
            ANY COVERED SOFTWARE IS AUTHORIZED HEREUNDER EXCEPT UNDER THIS
            DISCLAIMER.
            <br />
            6. TERMINATION.
            <br />
            6.1. This License and the rights granted hereunder will terminate
            automatically if You fail to comply with terms herein and fail to
            cure such breach within 30 days of becoming aware of the breach.
            Provisions which, by their nature, must remain in effect beyond the
            termination of this License shall survive.
            <br />
            6.2. If You assert a patent infringement claim (excluding
            declaratory judgment actions) against Initial Developer or a
            Contributor (the Initial Developer or Contributor against whom You
            assert such claim is referred to as Participant) alleging that the
            Participant Software (meaning the Contributor Version where the
            Participant is a Contributor or the Original Software where the
            Participant is the Initial Developer) directly or indirectly
            infringes any patent, then any and all rights granted directly or
            indirectly to You by such Participant, the Initial Developer (if the
            Initial Developer is not the Participant) and all Contributors under
            Sections 2.1 and/or 2.2 of this License shall, upon 60 days notice
            from Participant terminate prospectively and automatically at the
            expiration of such 60 day notice period, unless if within such 60
            day period You withdraw Your claim with respect to the Participant
            Software against such Participant either unilaterally or pursuant to
            a written agreement with Participant.
            <br />
            6.3. In the event of termination under Sections 6.1 or 6.2 above,
            all end user licenses that have been validly granted by You or any
            distributor hereunder prior to termination (excluding licenses
            granted to You by any distributor) shall survive termination.
            <br />
            7. LIMITATION OF LIABILITY.UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL
            THEORY, WHETHER TORT (INCLUDING NEGLIGENCE), CONTRACT, OR OTHERWISE,
            SHALL YOU, THE INITIAL DEVELOPER, ANY OTHER CONTRIBUTOR, OR ANY
            DISTRIBUTOR OF COVERED SOFTWARE, OR ANY SUPPLIER OF ANY OF SUCH
            PARTIES, BE LIABLE TO ANY PERSON FOR ANY INDIRECT, SPECIAL,
            INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY CHARACTER INCLUDING,
            WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK
            STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR ANY AND ALL OTHER
            COMMERCIAL DAMAGES OR LOSSES, EVEN IF SUCH PARTY SHALL HAVE BEEN
            INFORMED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION OF
            LIABILITY SHALL NOT APPLY TO LIABILITY FOR DEATH OR PERSONAL INJURY
            RESULTING FROM SUCH PARTYS NEGLIGENCE TO THE EXTENT APPLICABLE LAW
            PROHIBITS SUCH LIMITATION. SOME JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
            THIS EXCLUSION AND LIMITATION MAY NOT APPLY TO YOU.
            <br />
            8. U.S. GOVERNMENT END USERS.The Covered Software is a commercial
            item, as that term is defined in 48 C.F.R. 2.101 (Oct. 1995),
            consisting of commercial computer software (as that term is defined
            at 48 C.F.R. 252.227-7014(a)(1)) and commercial computer software
            documentation as such terms are used in 48 C.F.R. 12.212 (Sept.
            1995). Consistent with 48 C.F.R. 12.212 and 48 C.F.R. 227.7202-1
            through 227.7202-4 (June 1995), all U.S. Government End Users
            acquire Covered Software with only those rights set forth herein.
            This U.S. Government Rights clause is in lieu of, and supersedes,
            any other FAR, DFAR, or other clause or provision that addresses
            Government rights in computer software under this License.
            <br />
            9. MISCELLANEOUS.This License represents the complete agreement
            concerning subject matter hereof. If any provision of this License
            is held to be unenforceable, such provision shall be reformed only
            to the extent necessary to make it enforceable. This License shall
            be governed by the law of the jurisdiction specified in a notice
            contained within the Original Software (except to the extent
            applicable law, if any, provides otherwise), excluding such
            jurisdictions conflict-of-law provisions. Any litigation relating to
            this License shall be subject to the jurisdiction of the courts
            located in the jurisdiction and venue specified in a notice
            contained within the Original Software, with the losing party
            responsible for costs, including, without limitation, court costs
            and reasonable attorneys fees and expenses. The application of the
            United Nations Convention on Contracts for the International Sale of
            Goods is expressly excluded. Any law or regulation which provides
            that the language of a contract shall be construed against the
            drafter shall not apply to this License. You agree that You alone
            are responsible for compliance with the United States export
            administration regulations (and the export control laws and
            regulation of any other countries) when You use, distribute or
            otherwise make available any Covered Software.
            <br />
            10. RESPONSIBILITY FOR CLAIMS.As between Initial Developer and the
            Contributors, each party is responsible for claims and damages
            arising, directly or indirectly, out of its utilization of rights
            under this License and You agree to work with Initial Developer and
            Contributors to distribute such responsibility on an equitable
            basis. Nothing herein is intended or shall be deemed to constitute
            any admission of liability.
            <br />
          </p>
        </>
      );
    }
    case "3": {
      return (
        <>
          <p>
            THE ACCOMPANYING PROGRAM IS PROVIDED UNDER THE TERMS OF THIS ECLIPSE
            PUBLIC LICENSE (&ldquo;AGREEMENT&rdquo;). ANY USE, REPRODUCTION OR
            DISTRIBUTION OF THE PROGRAM CONSTITUTES RECIPIENT&rsquo;S ACCEPTANCE
            OF THIS AGREEMENT.
            <br />
            1. DEFINITIONS
            <br />
            &ldquo;Contribution&rdquo; means:
            <br />
            a) in the case of the initial Contributor, the initial content
            Distributed under this Agreement, and
            <br />
            b) in the case of each subsequent Contributor:
            <br />
            i) changes to the Program, and
            <br />
            ii) additions to the Program;
            <br />
            where such changes and/or additions to the Program originate from
            and are Distributed by that particular Contributor. A Contribution
            &ldquo;originates&rdquo; from a Contributor if it was added to the
            Program by such Contributor itself or anyone acting on such
            Contributor&rsquo;s behalf. Contributions do not include changes or
            additions to the Program that are not Modified Works.
            <br />
            &ldquo;Contributor&rdquo; means any person or entity that
            Distributes the Program.
            <br />
            &ldquo;Licensed Patents&rdquo; mean patent claims licensable by a
            Contributor which are necessarily infringed by the use or sale of
            its Contribution alone or when combined with the Program.
            <br />
            &ldquo;Program&rdquo; means the Contributions Distributed in
            accordance with this Agreement.
            <br />
            &ldquo;Recipient&rdquo; means anyone who receives the Program under
            this Agreement or any Secondary License (as applicable), including
            Contributors.
            <br />
            &ldquo;Derivative Works&rdquo; shall mean any work, whether in
            Source Code or other form, that is based on (or derived from) the
            Program and for which the editorial revisions, annotations,
            elaborations, or other modifications represent, as a whole, an
            original work of authorship.
            <br />
            &ldquo;Modified Works&rdquo; shall mean any work in Source Code or
            other form that results from an addition to, deletion from, or
            modification of the contents of the Program, including, for purposes
            of clarity any new file in Source Code form that contains any
            contents of the Program. Modified Works shall not include works that
            contain only declarations, interfaces, types, classes, structures,
            or files of the Program solely in each case in order to link to,
            bind by name, or subclass the Program or Modified Works thereof.
            <br />
            &ldquo;Distribute&rdquo; means the acts of a) distributing or b)
            making available in any manner that enables the transfer of a copy.
            <br />
            &ldquo;Source Code&rdquo; means the form of a Program preferred for
            making modifications, including but not limited to software source
            code, documentation source, and configuration files.
            <br />
            &ldquo;Secondary License&rdquo; means either the GNU General Public
            License, Version 2.0, or any later versions of that license,
            including any exceptions or additional permissions as identified by
            the initial Contributor.
            <br />
            2. GRANT OF RIGHTS
            <br />
            a) Subject to the terms of this Agreement, each Contributor hereby
            grants Recipient a non-exclusive, worldwide, royalty-free copyright
            license to reproduce, prepare Derivative Works of, publicly display,
            publicly perform, Distribute and sublicense the Contribution of such
            Contributor, if any, and such Derivative Works.
            <br />
            b) Subject to the terms of this Agreement, each Contributor hereby
            grants Recipient a non-exclusive, worldwide, royalty-free patent
            license under Licensed Patents to make, use, sell, offer to sell,
            import and otherwise transfer the Contribution of such Contributor,
            if any, in Source Code or other form. This patent license shall
            apply to the combination of the Contribution and the Program if, at
            the time the Contribution is added by the Contributor, such addition
            of the Contribution causes such combination to be covered by the
            Licensed Patents. The patent license shall not apply to any other
            combinations which include the Contribution. No hardware per se is
            licensed hereunder.
            <br />
            c) Recipient understands that although each Contributor grants the
            licenses to its Contributions set forth herein, no assurances are
            provided by any Contributor that the Program does not infringe the
            patent or other intellectual property rights of any other entity.
            Each Contributor disclaims any liability to Recipient for claims
            brought by any other entity based on infringement of intellectual
            property rights or otherwise. As a condition to exercising the
            rights and licenses granted hereunder, each Recipient hereby assumes
            sole responsibility to secure any other intellectual property rights
            needed, if any. For example, if a third party patent license is
            required to allow Recipient to Distribute the Program, it is
            Recipient&rsquo;s responsibility to acquire that license before
            distributing the Program.
            <br />
            d) Each Contributor represents that to its knowledge it has
            sufficient copyright rights in its Contribution, if any, to grant
            the copyright license set forth in this Agreement.
            <br />
            e) Notwithstanding the terms of any Secondary License, no
            Contributor makes additional grants to any Recipient (other than
            those set forth in this Agreement) as a result of such
            Recipient&rsquo;s receipt of the Program under the terms of a
            Secondary License (if permitted under the terms of Section 3).
            <br />
            3. REQUIREMENTS
            <br />
            3.1 If a Contributor Distributes the Program in any form, then:
            <br />
            a) the Program must also be made available as Source Code, in
            accordance with section 3.2, and the Contributor must accompany the
            Program with a statement that the Source Code for the Program is
            available under this Agreement, and informs Recipients how to obtain
            it in a reasonable manner on or through a medium customarily used
            for software exchange; and
            <br />
            b) the Contributor may Distribute the Program under a license
            different than this Agreement, provided that such license:
            <br />
            i) effectively disclaims on behalf of all other Contributors all
            warranties and conditions, express and implied, including warranties
            or conditions of title and non-infringement, and implied warranties
            or conditions of merchantability and fitness for a particular
            purpose;
            <br />
            ii) effectively excludes on behalf of all other Contributors all
            liability for damages, including direct, indirect, special,
            incidental and consequential damages, such as lost profits;
            <br />
            iii) does not attempt to limit or alter the recipients&rsquo; rights
            in the Source Code under section 3.2; and
            <br />
            iv) requires any subsequent distribution of the Program by any party
            to be under a license that satisfies the requirements of this
            section 3.
            <br />
            3.2 When the Program is Distributed as Source Code:
            <br />
            a) it must be made available under this Agreement, or if the Program
            (i) is combined with other material in a separate file or files made
            available under a Secondary License, and (ii) the initial
            Contributor attached to the Source Code the notice described in
            Exhibit A of this Agreement, then the Program may be made available
            under the terms of such Secondary Licenses, and
            <br />
            b) a copy of this Agreement must be included with each copy of the
            Program.
            <br />
            3.3 Contributors may not remove or alter any copyright, patent,
            trademark, attribution notices, disclaimers of warranty, or
            limitations of liability (&lsquo;notices&rsquo;) contained within
            the Program from any copy of the Program which they Distribute,
            provided that Contributors may add their own appropriate notices.
            <br />
            4. COMMERCIAL DISTRIBUTION
            <br />
            Commercial distributors of software may accept certain
            responsibilities with respect to end users, business partners and
            the like. While this license is intended to facilitate the
            commercial use of the Program, the Contributor who includes the
            Program in a commercial product offering should do so in a manner
            which does not create potential liability for other Contributors.
            Therefore, if a Contributor includes the Program in a commercial
            product offering, such Contributor (&ldquo;Commercial
            Contributor&rdquo;) hereby agrees to defend and indemnify every
            other Contributor (&ldquo;Indemnified Contributor&rdquo;) against
            any losses, damages and costs (collectively &ldquo;Losses&rdquo;)
            arising from claims, lawsuits and other legal actions brought by a
            third party against the Indemnified Contributor to the extent caused
            by the acts or omissions of such Commercial Contributor in
            connection with its distribution of the Program in a commercial
            product offering. The obligations in this section do not apply to
            any claims or Losses relating to any actual or alleged intellectual
            property infringement. In order to qualify, an Indemnified
            Contributor must: a) promptly notify the Commercial Contributor in
            writing of such claim, and b) allow the Commercial Contributor to
            control, and cooperate with the Commercial Contributor in, the
            defense and any related settlement negotiations. The Indemnified
            Contributor may participate in any such claim at its own expense.
            <br />
            For example, a Contributor might include the Program in a commercial
            product offering, Product X. That Contributor is then a Commercial
            Contributor. If that Commercial Contributor then makes performance
            claims, or offers warranties related to Product X, those performance
            claims and warranties are such Commercial Contributor&rsquo;s
            responsibility alone. Under this section, the Commercial Contributor
            would have to defend claims against the other Contributors related
            to those performance claims and warranties, and if a court requires
            any other Contributor to pay any damages as a result, the Commercial
            Contributor must pay those damages.
            <br />
            5. NO WARRANTY
            <br />
            EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, AND TO THE EXTENT
            PERMITTED BY APPLICABLE LAW, THE PROGRAM IS PROVIDED ON AN &ldquo;AS
            IS&rdquo; BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
            EITHER EXPRESS OR IMPLIED INCLUDING, WITHOUT LIMITATION, ANY
            WARRANTIES OR CONDITIONS OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY
            OR FITNESS FOR A PARTICULAR PURPOSE. Each Recipient is solely
            responsible for determining the appropriateness of using and
            distributing the Program and assumes all risks associated with its
            exercise of rights under this Agreement, including but not limited
            to the risks and costs of program errors, compliance with applicable
            laws, damage to or loss of data, programs or equipment, and
            unavailability or interruption of operations.
            <br />
            6. DISCLAIMER OF LIABILITY
            <br />
            EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, AND TO THE EXTENT
            PERMITTED BY APPLICABLE LAW, NEITHER RECIPIENT NOR ANY CONTRIBUTORS
            SHALL HAVE ANY LIABILITY FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT
            LIMITATION LOST PROFITS), HOWEVER CAUSED AND ON ANY THEORY OF
            LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
            NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OR
            DISTRIBUTION OF THE PROGRAM OR THE EXERCISE OF ANY RIGHTS GRANTED
            HEREUNDER, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            <br />
            7. GENERAL
            <br />
            If any provision of this Agreement is invalid or unenforceable under
            applicable law, it shall not affect the validity or enforceability
            of the remainder of the terms of this Agreement, and without further
            action by the parties hereto, such provision shall be reformed to
            the minimum extent necessary to make such provision valid and
            enforceable.
            <br />
            If Recipient institutes patent litigation against any entity
            (including a cross-claim or counterclaim in a lawsuit) alleging that
            the Program itself (excluding combinations of the Program with other
            software or hardware) infringes such Recipient&rsquo;s patent(s),
            then such Recipient&rsquo;s rights granted under Section 2(b) shall
            terminate as of the date such litigation is filed.
            <br />
            All Recipient&rsquo;s rights under this Agreement shall terminate if
            it fails to comply with any of the material terms or conditions of
            this Agreement and does not cure such failure in a reasonable period
            of time after becoming aware of such noncompliance. If all
            Recipient&rsquo;s rights under this Agreement terminate, Recipient
            agrees to cease use and distribution of the Program as soon as
            reasonably practicable. However, Recipient&rsquo;s obligations under
            this Agreement and any licenses granted by Recipient relating to the
            Program shall continue and survive.
            <br />
            Everyone is permitted to copy and distribute copies of this
            Agreement, but in order to avoid inconsistency the Agreement is
            copyrighted and may only be modified in the following manner. The
            Agreement Steward reserves the right to publish new versions
            (including revisions) of this Agreement from time to time. No one
            other than the Agreement Steward has the right to modify this
            Agreement. The Eclipse Foundation is the initial Agreement Steward.
            The Eclipse Foundation may assign the responsibility to serve as the
            Agreement Steward to a suitable separate entity. Each new version of
            the Agreement will be given a distinguishing version number. The
            Program (including Contributions) may always be Distributed subject
            to the version of the Agreement under which it was received. In
            addition, after a new version of the Agreement is published,
            Contributor may elect to Distribute the Program (including its
            Contributions) under the new version.
            <br />
            Except as expressly stated in Sections 2(a) and 2(b) above,
            Recipient receives no rights or licenses to the intellectual
            property of any Contributor under this Agreement, whether expressly,
            by implication, estoppel or otherwise. All rights in the Program not
            expressly granted under this Agreement are reserved. Nothing in this
            Agreement is intended to be enforceable by any entity that is not a
            Contributor or Recipient. No third-party beneficiary rights are
            created under this Agreement.
            <br />
            Exhibit A &ndash; Form of Secondary Licenses Notice
            <br />
            &ldquo;This Source Code may also be made available under the
            following Secondary Licenses when the conditions for such
            availability set forth in the Eclipse Public License, v. 2.0 are
            satisfied: &#123;name license(s), version(s), and exceptions or
            additional permissions here&#123;.&rdquo;
            <br />
            Simply including a copy of this Agreement, including this Exhibit A
            is not sufficient to license the Source Code under Secondary
            Licenses.If it is not possible or desirable to put the notice in a
            particular file, then You may include the notice in a location (such
            as a LICENSE file in a relevant directory) where a recipient would
            be likely to look for such a notice.You may add additional accurate
            notices of copyright ownership.
          </p>
        </>
      );
    }
    case "4": {
      return (
        <>
          <p>
            Copyright (C) 1989, 1991 Free Software Foundation, Inc.
            <br />
            51 Franklin Street, Fifth Floor, Boston, MA 02110-1301 USA
            <br />
            Everyone is permitted to copy and distribute verbatim copies
            <br />
            of this license document, but changing it is not allowed.
            <br />
            Preamble
            <br />
            The licenses for most software are designed to take away your
            freedom to share and change it. By contrast, the GNU General Public
            License is intended to guarantee your freedom to share and change
            free software&ndash;to make sure the software is free for all its
            users. This General Public License applies to most of the Free
            Software Foundation&rsquo;s software and to any other program whose
            authors commit to using it. (Some other Free Software Foundation
            software is covered by the GNU Library General Public License
            instead.) You can apply it to your programs, too.
            <br />
            When we speak of free software, we are referring to freedom, not
            price. Our General Public Licenses are designed to make sure that
            you have the freedom to distribute copies of free software (and
            charge for this service if you wish), that you receive source code
            or can get it if you want it, that you can change the software or
            use pieces of it in new free programs; and that you know you can do
            these things.
            <br />
            To protect your rights, we need to make restrictions that forbid
            anyone to deny you these rights or to ask you to surrender the
            rights. These restrictions translate to certain responsibilities for
            you if you distribute copies of the software, or if you modify it.
            <br />
            For example, if you distribute copies of such a program, whether
            gratis or for a fee, you must give the recipients all the rights
            that you have. You must make sure that they, too, receive or can get
            the source code. And you must show them these terms so they know
            their rights.
            <br />
            We protect your rights with two steps: (1) copyright the software,
            and (2) offer you this license which gives you legal permission to
            copy, distribute and/or modify the software.
            <br />
            Also, for each author&rsquo;s protection and ours, we want to make
            certain that everyone understands that there is no warranty for this
            free software. If the software is modified by someone else and
            passed on, we want its recipients to know that what they have is not
            the original, so that any problems introduced by others will not
            reflect on the original authors&rsquo; reputations.
            <br />
            Finally, any free program is threatened constantly by software
            patents. We wish to avoid the danger that redistributors of a free
            program will individually obtain patent licenses, in effect making
            the program proprietary. To prevent this, we have made it clear that
            any patent must be licensed for everyone&rsquo;s free use or not
            licensed at all.
            <br />
            The precise terms and conditions for copying, distribution and
            modification follow.
            <br />
            TERMS AND CONDITIONS FOR COPYING, DISTRIBUTION AND MODIFICATION
            <br />
            0. This License applies to any program or other work which contains
            a notice placed by the copyright holder saying it may be distributed
            under the terms of this General Public License. The
            &ldquo;Program&rdquo;, below, refers to any such program or work,
            and a &ldquo;work based on the Program&rdquo; means either the
            Program or any derivative work under copyright law: that is to say,
            a work containing the Program or a portion of it, either verbatim or
            with modifications and/or translated into another language.
            (Hereinafter, translation is included without limitation in the term
            &ldquo;modification&rdquo;.) Each licensee is addressed as
            &ldquo;you&rdquo;.
            <br />
            Activities other than copying, distribution and modification are not
            covered by this License; they are outside its scope. The act of
            running the Program is not restricted, and the output from the
            Program is covered only if its contents constitute a work based on
            the Program (independent of having been made by running the
            Program). Whether that is true depends on what the Program does.
            <br />
            1. You may copy and distribute verbatim copies of the
            Program&rsquo;s source code as you receive it, in any medium,
            provided that you conspicuously and appropriately publish on each
            copy an appropriate copyright notice and disclaimer of warranty;
            keep intact all the notices that refer to this License and to the
            absence of any warranty; and give any other recipients of the
            Program a copy of this License along with the Program.
            <br />
            You may charge a fee for the physical act of transferring a copy,
            and you may at your option offer warranty protection in exchange for
            a fee.
            <br />
            2. You may modify your copy or copies of the Program or any portion
            of it, thus forming a work based on the Program, and copy and
            distribute such modifications or work under the terms of Section 1
            above, provided that you also meet all of these conditions:
            <br />
            a) You must cause the modified files to carry prominent notices
            stating that you changed the files and the date of any change.
            <br />
            b) You must cause any work that you distribute or publish, that in
            whole or in part contains or is derived from the Program or any part
            thereof, to be licensed as a whole at no charge to all third parties
            under the terms of this License.
            <br />
            c) If the modified program normally reads commands interactively
            when run, you must cause it, when started running for such
            interactive use in the most ordinary way, to print or display an
            announcement including an appropriate copyright notice and a notice
            that there is no warranty (or else, saying that you provide a
            warranty) and that users may redistribute the program under these
            conditions, and telling the user how to view a copy of this License.
            (Exception: if the Program itself is interactive but does not
            normally print such an announcement, your work based on the Program
            is not required to print an announcement.)
            <br />
            These requirements apply to the modified work as a whole. If
            identifiable sections of that work are not derived from the Program,
            and can be reasonably considered independent and separate works in
            themselves, then this License, and its terms, do not apply to those
            sections when you distribute them as separate works. But when you
            distribute the same sections as part of a whole which is a work
            based on the Program, the distribution of the whole must be on the
            terms of this License, whose permissions for other licensees extend
            to the entire whole, and thus to each and every part regardless of
            who wrote it.
            <br />
            Thus, it is not the intent of this section to claim rights or
            contest your rights to work written entirely by you; rather, the
            intent is to exercise the right to control the distribution of
            derivative or collective works based on the Program.
            <br />
            In addition, mere aggregation of another work not based on the
            Program with the Program (or with a work based on the Program) on a
            volume of a storage or distribution medium does not bring the other
            work under the scope of this License.
            <br />
            3. You may copy and distribute the Program (or a work based on it,
            under Section 2) in object code or executable form under the terms
            of Sections 1 and 2 above provided that you also do one of the
            following:
            <br />
            a) Accompany it with the complete corresponding machine-readable
            source code, which must be distributed under the terms of Sections 1
            and 2 above on a medium customarily used for software interchange;
            or,
            <br />
            b) Accompany it with a written offer, valid for at least three
            years, to give any third party, for a charge no more than your cost
            of physically performing source distribution, a complete
            machine-readable copy of the corresponding source code, to be
            distributed under the terms of Sections 1 and 2 above on a medium
            customarily used for software interchange; or,
            <br />
            c) Accompany it with the information you received as to the offer to
            distribute corresponding source code. (This alternative is allowed
            only for noncommercial distribution and only if you received the
            program in object code or executable form with such an offer, in
            accord with Subsection b above.)
            <br />
            The source code for a work means the preferred form of the work for
            making modifications to it. For an executable work, complete source
            code means all the source code for all modules it contains, plus any
            associated interface definition files, plus the scripts used to
            control compilation and installation of the executable. However, as
            a special exception, the source code distributed need not include
            anything that is normally distributed (in either source or binary
            form) with the major components (compiler, kernel, and so on) of the
            operating system on which the executable runs, unless that component
            itself accompanies the executable.
            <br />
            If distribution of executable or object code is made by offering
            access to copy from a designated place, then offering equivalent
            access to copy the source code from the same place counts as
            distribution of the source code, even though third parties are not
            compelled to copy the source along with the object code.
            <br />
            4. You may not copy, modify, sublicense, or distribute the Program
            except as expressly provided under this License. Any attempt
            otherwise to copy, modify, sublicense or distribute the Program is
            void, and will automatically terminate your rights under this
            License. However, parties who have received copies, or rights, from
            you under this License will not have their licenses terminated so
            long as such parties remain in full compliance.
            <br />
            5. You are not required to accept this License, since you have not
            signed it. However, nothing else grants you permission to modify or
            distribute the Program or its derivative works. These actions are
            prohibited by law if you do not accept this License. Therefore, by
            modifying or distributing the Program (or any work based on the
            Program), you indicate your acceptance of this License to do so, and
            all its terms and conditions for copying, distributing or modifying
            the Program or works based on it.
            <br />
            6. Each time you redistribute the Program (or any work based on the
            Program), the recipient automatically receives a license from the
            original licensor to copy, distribute or modify the Program subject
            to these terms and conditions. You may not impose any further
            restrictions on the recipients&rsquo; exercise of the rights granted
            herein. You are not responsible for enforcing compliance by third
            parties to this License.
            <br />
            7. If, as a consequence of a court judgment or allegation of patent
            infringement or for any other reason (not limited to patent issues),
            conditions are imposed on you (whether by court order, agreement or
            otherwise) that contradict the conditions of this License, they do
            not excuse you from the conditions of this License. If you cannot
            distribute so as to satisfy simultaneously your obligations under
            this License and any other pertinent obligations, then as a
            consequence you may not distribute the Program at all. For example,
            if a patent license would not permit royalty-free redistribution of
            the Program by all those who receive copies directly or indirectly
            through you, then the only way you could satisfy both it and this
            License would be to refrain entirely from distribution of the
            Program.
            <br />
            If any portion of this section is held invalid or unenforceable
            under any particular circumstance, the balance of the section is
            intended to apply and the section as a whole is intended to apply in
            other circumstances.
            <br />
            It is not the purpose of this section to induce you to infringe any
            patents or other property right claims or to contest validity of any
            such claims; this section has the sole purpose of protecting the
            integrity of the free software distribution system, which is
            implemented by public license practices. Many people have made
            generous contributions to the wide range of software distributed
            through that system in reliance on consistent application of that
            system; it is up to the author/donor to decide if he or she is
            willing to distribute software through any other system and a
            licensee cannot impose that choice.
            <br />
            This section is intended to make thoroughly clear what is believed
            to be a consequence of the rest of this License.
            <br />
            8. If the distribution and/or use of the Program is restricted in
            certain countries either by patents or by copyrighted interfaces,
            the original copyright holder who places the Program under this
            License may add an explicit geographical distribution limitation
            excluding those countries, so that distribution is permitted only in
            or among countries not thus excluded. In such case, this License
            incorporates the limitation as if written in the body of this
            License.
            <br />
            9. The Free Software Foundation may publish revised and/or new
            versions of the General Public License from time to time. Such new
            versions will be similar in spirit to the present version, but may
            differ in detail to address new problems or concerns.
            <br />
            Each version is given a distinguishing version number. If the
            Program specifies a version number of this License which applies to
            it and &ldquo;any later version&rdquo;, you have the option of
            following the terms and conditions either of that version or of any
            later version published by the Free Software Foundation. If the
            Program does not specify a version number of this License, you may
            choose any version ever published by the Free Software Foundation.
            <br />
            10. If you wish to incorporate parts of the Program into other free
            programs whose distribution conditions are different, write to the
            author to ask for permission. For software which is copyrighted by
            the Free Software Foundation, write to the Free Software Foundation;
            we sometimes make exceptions for this. Our decision will be guided
            by the two goals of preserving the free status of all derivatives of
            our free software and of promoting the sharing and reuse of software
            generally.
            <br />
            NO WARRANTY
            <br />
            11. BECAUSE THE PROGRAM IS LICENSED FREE OF CHARGE, THERE IS NO
            WARRANTY FOR THE PROGRAM, TO THE EXTENT PERMITTED BY APPLICABLE LAW.
            EXCEPT WHEN OTHERWISE STATED IN WRITING THE COPYRIGHT HOLDERS AND/OR
            OTHER PARTIES PROVIDE THE PROGRAM &ldquo;AS IS&rdquo; WITHOUT
            WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
            FITNESS FOR A PARTICULAR PURPOSE. THE ENTIRE RISK AS TO THE QUALITY
            AND PERFORMANCE OF THE PROGRAM IS WITH YOU. SHOULD THE PROGRAM PROVE
            DEFECTIVE, YOU ASSUME THE COST OF ALL NECESSARY SERVICING, REPAIR OR
            CORRECTION.
            <br />
            12. IN NO EVENT UNLESS REQUIRED BY APPLICABLE LAW OR AGREED TO IN
            WRITING WILL ANY COPYRIGHT HOLDER, OR ANY OTHER PARTY WHO MAY MODIFY
            AND/OR REDISTRIBUTE THE PROGRAM AS PERMITTED ABOVE, BE LIABLE TO YOU
            FOR DAMAGES, INCLUDING ANY GENERAL, SPECIAL, INCIDENTAL OR
            CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE THE
            PROGRAM (INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR DATA BEING
            RENDERED INACCURATE OR LOSSES SUSTAINED BY YOU OR THIRD PARTIES OR A
            FAILURE OF THE PROGRAM TO OPERATE WITH ANY OTHER PROGRAMS), EVEN IF
            SUCH HOLDER OR OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES.
            <br />
            END OF TERMS AND CONDITIONS
            <br />
            How to Apply These Terms to Your New Programs
            <br />
            If you develop a new program, and you want it to be of the greatest
            possible use to the public, the best way to achieve this is to make
            it free software which everyone can redistribute and change under
            these terms.
            <br />
            To do so, attach the following notices to the program. It is safest
            to attach them to the start of each source file to most effectively
            convey the exclusion of warranty; and each file should have at least
            the &ldquo;copyright&rdquo; line and a pointer to where the full
            notice is found.
            <br />
            One line to give the program&rsquo;s name and a brief idea of what
            it does.
            <br />
            Copyright (C) [yyyy] [name of author]
            <br />
            This program is free software; you can redistribute it and/or modify
            it under the terms of the GNU General Public License as published by
            the Free Software Foundation; either version 2 of the License, or
            (at your option) any later version.
            <br />
            This program is distributed in the hope that it will be useful, but
            WITHOUT ANY WARRANTY; without even the implied warranty of
            MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
            General Public License for more details.
            <br />
            You should have received a copy of the GNU General Public License
            along with this program; if not, write to the Free Software
            Foundation, Inc., 59 Temple Place, Suite 330, Boston, MA 02111-1307
            USA
            <br />
            Also add information on how to contact you by electronic and paper
            mail.
            <br />
            If the program is interactive, make it output a short notice like
            this when it starts in an interactive mode:
            <br />
            Gnomovision version 69, Copyright (C) year name of author
            Gnomovision comes with ABSOLUTELY NO WARRANTY; for details type
            &#96;show w&rsquo;. This is free software, and you are welcome to
            redistribute it under certain conditions; type &#96;show c&rsquo;
            for details.
            <br />
            The hypothetical commands &#96;show w&rsquo; and &#96;show c&rsquo;
            should show the appropriate parts of the General Public License. Of
            course, the commands you use may be called something other than
            &#96;show w&rsquo; and &#96;show c&rsquo;; they could even be
            mouse-clicks or menu items&ndash;whatever suits your program.
            <br />
            You should also get your employer (if you work as a programmer) or
            your school, if any, to sign a &ldquo;copyright disclaimer&rdquo;
            for the program, if necessary. Here is a sample; alter the names:
            <br />
            Yoyodyne, Inc., hereby disclaims all copyright interest in the
            program &#96;Gnomovision&rsquo; (which makes passes at compilers)
            written by James Hacker.
            <br />
            signature of Ty Coon, 1 April 1989
            <br />
            Ty Coon, President of Vice
            <br />
            This General Public License does not permit incorporating your
            program into proprietary programs. If your program is a subroutine
            library, you may consider it more useful to permit linking
            proprietary applications with the library. If this is what you want
            to do, use the GNU Library General Public License instead of this
            License.
          </p>
        </>
      );
    }
    case "5": {
      return (
        <>
          <p>
            Preamble
            <br />
            The GNU General Public License is a free, copyleft license for
            software and other kinds of works.
            <br />
            The licenses for most software and other practical works are
            designed to take away your freedom to share and change the works. By
            contrast, the GNU General Public License is intended to guarantee
            your freedom to share and change all versions of a program&ndash;to
            make sure it remains free software for all its users. We, the Free
            Software Foundation, use the GNU General Public License for most of
            our software; it applies also to any other work released this way by
            its authors. You can apply it to your programs, too.
            <br />
            When we speak of free software, we are referring to freedom, not
            price. Our General Public Licenses are designed to make sure that
            you have the freedom to distribute copies of free software (and
            charge for them if you wish), that you receive source code or can
            get it if you want it, that you can change the software or use
            pieces of it in new free programs, and that you know you can do
            these things.
            <br />
            To protect your rights, we need to prevent others from denying you
            these rights or asking you to surrender the rights. Therefore, you
            have certain responsibilities if you distribute copies of the
            software, or if you modify it: responsibilities to respect the
            freedom of others.
            <br />
            For example, if you distribute copies of such a program, whether
            gratis or for a fee, you must pass on to the recipients the same
            freedoms that you received. You must make sure that they, too,
            receive or can get the source code. And you must show them these
            terms so they know their rights.
            <br />
            Developers that use the GNU GPL protect your rights with two steps:
            (1) assert copyright on the software, and (2) offer you this License
            giving you legal permission to copy, distribute and/or modify it.
            <br />
            For the developers&rsquo; and authors&rsquo; protection, the GPL
            clearly explains that there is no warranty for this free software.
            For both users&rsquo; and authors&rsquo; sake, the GPL requires that
            modified versions be marked as changed, so that their problems will
            not be attributed erroneously to authors of previous versions.
            <br />
            Some devices are designed to deny users access to install or run
            modified versions of the software inside them, although the
            manufacturer can do so. This is fundamentally incompatible with the
            aim of protecting users&rsquo; freedom to change the software. The
            systematic pattern of such abuse occurs in the area of products for
            individuals to use, which is precisely where it is most
            unacceptable. Therefore, we have designed this version of the GPL to
            prohibit the practice for those products. If such problems arise
            substantially in other domains, we stand ready to extend this
            provision to those domains in future versions of the GPL, as needed
            to protect the freedom of users.
            <br />
            Finally, every program is threatened constantly by software patents.
            States should not allow patents to restrict development and use of
            software on general-purpose computers, but in those that do, we wish
            to avoid the special danger that patents applied to a free program
            could make it effectively proprietary. To prevent this, the GPL
            assures that patents cannot be used to render the program non-free.
            <br />
            The precise terms and conditions for copying, distribution and
            modification follow.
            <br />
            TERMS AND CONDITIONS
            <br />
            0. Definitions.
            <br />
            &ldquo;This License&rdquo; refers to version 3 of the GNU General
            Public License.
            <br />
            &ldquo;Copyright&rdquo; also means copyright-like laws that apply to
            other kinds of works, such as semiconductor masks.
            <br />
            &ldquo;The Program&rdquo; refers to any copyrightable work licensed
            under this License. Each licensee is addressed as &ldquo;you&rdquo;.
            &ldquo;Licensees&rdquo; and &ldquo;recipients&rdquo; may be
            individuals or organizations.
            <br />
            To &ldquo;modify&rdquo; a work means to copy from or adapt all or
            part of the work in a fashion requiring copyright permission, other
            than the making of an exact copy. The resulting work is called a
            &ldquo;modified version&rdquo; of the earlier work or a work
            &ldquo;based on&rdquo; the earlier work.
            <br />
            A &ldquo;covered work&rdquo; means either the unmodified Program or
            a work based on the Program.
            <br />
            To &ldquo;propagate&rdquo; a work means to do anything with it that,
            without permission, would make you directly or secondarily liable
            for infringement under applicable copyright law, except executing it
            on a computer or modifying a private copy. Propagation includes
            copying, distribution (with or without modification), making
            available to the public, and in some countries other activities as
            well.
            <br />
            To &ldquo;convey&rdquo; a work means any kind of propagation that
            enables other parties to make or receive copies. Mere interaction
            with a user through a computer network, with no transfer of a copy,
            is not conveying.
            <br />
            An interactive user interface displays &ldquo;Appropriate Legal
            Notices&rdquo; to the extent that it includes a convenient and
            prominently visible feature that (1) displays an appropriate
            copyright notice, and (2) tells the user that there is no warranty
            for the work (except to the extent that warranties are provided),
            that licensees may convey the work under this License, and how to
            view a copy of this License. If the interface presents a list of
            user commands or options, such as a menu, a prominent item in the
            list meets this criterion.
            <br />
            1. Source Code.
            <br />
            The &ldquo;source code&rdquo; for a work means the preferred form of
            the work for making modifications to it. &ldquo;Object code&rdquo;
            means any non-source form of a work.
            <br />
            A &ldquo;Standard Interface&rdquo; means an interface that either is
            an official standard defined by a recognized standards body, or, in
            the case of interfaces specified for a particular programming
            language, one that is widely used among developers working in that
            language.
            <br />
            The &ldquo;System Libraries&rdquo; of an executable work include
            anything, other than the work as a whole, that (a) is included in
            the normal form of packaging a Major Component, but which is not
            part of that Major Component, and (b) serves only to enable use of
            the work with that Major Component, or to implement a Standard
            Interface for which an implementation is available to the public in
            source code form. A &ldquo;Major Component&rdquo;, in this context,
            means a major essential component (kernel, window system, and so on)
            of the specific operating system (if any) on which the executable
            work runs, or a compiler used to produce the work, or an object code
            interpreter used to run it.
            <br />
            The &ldquo;Corresponding Source&rdquo; for a work in object code
            form means all the source code needed to generate, install, and (for
            an executable work) run the object code and to modify the work,
            including scripts to control those activities. However, it does not
            include the work&rsquo;s System Libraries, or general-purpose tools
            or generally available free programs which are used unmodified in
            performing those activities but which are not part of the work. For
            example, Corresponding Source includes interface definition files
            associated with source files for the work, and the source code for
            shared libraries and dynamically linked subprograms that the work is
            specifically designed to require, such as by intimate data
            communication or control flow between those subprograms and other
            parts of the work.
            <br />
            The Corresponding Source need not include anything that users can
            regenerate automatically from other parts of the Corresponding
            Source.
            <br />
            The Corresponding Source for a work in source code form is that same
            work.
            <br />
            2. Basic Permissions.
            <br />
            All rights granted under this License are granted for the term of
            copyright on the Program, and are irrevocable provided the stated
            conditions are met. This License explicitly affirms your unlimited
            permission to run the unmodified Program. The output from running a
            covered work is covered by this License only if the output, given
            its content, constitutes a covered work. This License acknowledges
            your rights of fair use or other equivalent, as provided by
            copyright law.
            <br />
            You may make, run and propagate covered works that you do not
            convey, without conditions so long as your license otherwise remains
            in force. You may convey covered works to others for the sole
            purpose of having them make modifications exclusively for you, or
            provide you with facilities for running those works, provided that
            you comply with the terms of this License in conveying all material
            for which you do not control copyright. Those thus making or running
            the covered works for you must do so exclusively on your behalf,
            under your direction and control, on terms that prohibit them from
            making any copies of your copyrighted material outside their
            relationship with you.
            <br />
            Conveying under any other circumstances is permitted solely under
            the conditions stated below. Sublicensing is not allowed; section 10
            makes it unnecessary.
            <br />
            3. Protecting Users&rsquo; Legal Rights From Anti-Circumvention Law.
            <br />
            No covered work shall be deemed part of an effective technological
            measure under any applicable law fulfilling obligations under
            article 11 of the WIPO copyright treaty adopted on 20 December 1996,
            or similar laws prohibiting or restricting circumvention of such
            measures.
            <br />
            When you convey a covered work, you waive any legal power to forbid
            circumvention of technological measures to the extent such
            circumvention is effected by exercising rights under this License
            with respect to the covered work, and you disclaim any intention to
            limit operation or modification of the work as a means of enforcing,
            against the work&rsquo;s users, your or third parties&rsquo; legal
            rights to forbid circumvention of technological measures.
            <br />
            4. Conveying Verbatim Copies.
            <br />
            You may convey verbatim copies of the Program&rsquo;s source code as
            you receive it, in any medium, provided that you conspicuously and
            appropriately publish on each copy an appropriate copyright notice;
            keep intact all notices stating that this License and any
            non-permissive terms added in accord with section 7 apply to the
            code; keep intact all notices of the absence of any warranty; and
            give all recipients a copy of this License along with the Program.
            <br />
            You may charge any price or no price for each copy that you convey,
            and you may offer support or warranty protection for a fee.
            <br />
            5. Conveying Modified Source Versions.
            <br />
            You may convey a work based on the Program, or the modifications to
            produce it from the Program, in the form of source code under the
            terms of section 4, provided that you also meet all of these
            conditions:
            <br />
            a) The work must carry prominent notices stating that you modified
            it, and giving a relevant date.
            <br />
            b) The work must carry prominent notices stating that it is released
            under this License and any conditions added under section 7. This
            requirement modifies the requirement in section 4 to &ldquo;keep
            intact all notices&rdquo;.
            <br />
            c) You must license the entire work, as a whole, under this License
            to anyone who comes into possession of a copy. This License will
            therefore apply, along with any applicable section 7 additional
            terms, to the whole of the work, and all its parts, regardless of
            how they are packaged. This License gives no permission to license
            the work in any other way, but it does not invalidate such
            permission if you have separately received it.
            <br />
            d) If the work has interactive user interfaces, each must display
            Appropriate Legal Notices; however, if the Program has interactive
            interfaces that do not display Appropriate Legal Notices, your work
            need not make them do so.
            <br />
            A compilation of a covered work with other separate and independent
            works, which are not by their nature extensions of the covered work,
            and which are not combined with it such as to form a larger program,
            in or on a volume of a storage or distribution medium, is called an
            &ldquo;aggregate&rdquo; if the compilation and its resulting
            copyright are not used to limit the access or legal rights of the
            compilation&rsquo;s users beyond what the individual works permit.
            Inclusion of a covered work in an aggregate does not cause this
            License to apply to the other parts of the aggregate.
            <br />
            6. Conveying Non-Source Forms.
            <br />
            You may convey a covered work in object code form under the terms of
            sections 4 and 5, provided that you also convey the machine-readable
            Corresponding Source under the terms of this License, in one of
            these ways:
            <br />
            a) Convey the object code in, or embodied in, a physical product
            (including a physical distribution medium), accompanied by the
            Corresponding Source fixed on a durable physical medium customarily
            used for software interchange.
            <br />
            b) Convey the object code in, or embodied in, a physical product
            (including a physical distribution medium), accompanied by a written
            offer, valid for at least three years and valid for as long as you
            offer spare parts or customer support for that product model, to
            give anyone who possesses the object code either (1) a copy of the
            Corresponding Source for all the software in the product that is
            covered by this License, on a durable physical medium customarily
            used for software interchange, for a price no more than your
            reasonable cost of physically performing this conveying of source,
            or (2) access to copy the Corresponding Source from a network server
            at no charge.
            <br />
            c) Convey individual copies of the object code with a copy of the
            written offer to provide the Corresponding Source. This alternative
            is allowed only occasionally and noncommercially, and only if you
            received the object code with such an offer, in accord with
            subsection 6b.
            <br />
            d) Convey the object code by offering access from a designated place
            (gratis or for a charge), and offer equivalent access to the
            Corresponding Source in the same way through the same place at no
            further charge. You need not require recipients to copy the
            Corresponding Source along with the object code. If the place to
            copy the object code is a network server, the Corresponding Source
            may be on a different server (operated by you or a third party) that
            supports equivalent copying facilities, provided you maintain clear
            directions next to the object code saying where to find the
            Corresponding Source. Regardless of what server hosts the
            Corresponding Source, you remain obligated to ensure that it is
            available for as long as needed to satisfy these requirements.
            <br />
            e) Convey the object code using peer-to-peer transmission, provided
            you inform other peers where the object code and Corresponding
            Source of the work are being offered to the general public at no
            charge under subsection 6d.
            <br />
            A separable portion of the object code, whose source code is
            excluded from the Corresponding Source as a System Library, need not
            be included in conveying the object code work.
            <br />
            A &ldquo;User Product&rdquo; is either (1) a &ldquo;consumer
            product&rdquo;, which means any tangible personal property which is
            normally used for personal, family, or household purposes, or (2)
            anything designed or sold for incorporation into a dwelling. In
            determining whether a product is a consumer product, doubtful cases
            shall be resolved in favor of coverage. For a particular product
            received by a particular user, &ldquo;normally used&rdquo; refers to
            a typical or common use of that class of product, regardless of the
            status of the particular user or of the way in which the particular
            user actually uses, or expects or is expected to use, the product. A
            product is a consumer product regardless of whether the product has
            substantial commercial, industrial or non-consumer uses, unless such
            uses represent the only significant mode of use of the product.
            <br />
            &ldquo;Installation Information&rdquo; for a User Product means any
            methods, procedures, authorization keys, or other information
            required to install and execute modified versions of a covered work
            in that User Product from a modified version of its Corresponding
            Source. The information must suffice to ensure that the continued
            functioning of the modified object code is in no case prevented or
            interfered with solely because modification has been made.
            <br />
            If you convey an object code work under this section in, or with, or
            specifically for use in, a User Product, and the conveying occurs as
            part of a transaction in which the right of possession and use of
            the User Product is transferred to the recipient in perpetuity or
            for a fixed term (regardless of how the transaction is
            characterized), the Corresponding Source conveyed under this section
            must be accompanied by the Installation Information. But this
            requirement does not apply if neither you nor any third party
            retains the ability to install modified object code on the User
            Product (for example, the work has been installed in ROM).
            <br />
            The requirement to provide Installation Information does not include
            a requirement to continue to provide support service, warranty, or
            updates for a work that has been modified or installed by the
            recipient, or for the User Product in which it has been modified or
            installed. Access to a network may be denied when the modification
            itself materially and adversely affects the operation of the network
            or violates the rules and protocols for communication across the
            network.
            <br />
            Corresponding Source conveyed, and Installation Information
            provided, in accord with this section must be in a format that is
            publicly documented (and with an implementation available to the
            public in source code form), and must require no special password or
            key for unpacking, reading or copying.
            <br />
            7. Additional Terms.
            <br />
            &ldquo;Additional permissions&rdquo; are terms that supplement the
            terms of this License by making exceptions from one or more of its
            conditions. Additional permissions that are applicable to the entire
            Program shall be treated as though they were included in this
            License, to the extent that they are valid under applicable law. If
            additional permissions apply only to part of the Program, that part
            may be used separately under those permissions, but the entire
            Program remains governed by this License without regard to the
            additional permissions.
            <br />
            When you convey a copy of a covered work, you may at your option
            remove any additional permissions from that copy, or from any part
            of it. (Additional permissions may be written to require their own
            removal in certain cases when you modify the work.) You may place
            additional permissions on material, added by you to a covered work,
            for which you have or can give appropriate copyright permission.
            <br />
            Notwithstanding any other provision of this License, for material
            you add to a covered work, you may (if authorized by the copyright
            holders of that material) supplement the terms of this License with
            terms:
            <br />
            a) Disclaiming warranty or limiting liability differently from the
            terms of sections 15 and 16 of this License; or
            <br />
            b) Requiring preservation of specified reasonable legal notices or
            author attributions in that material or in the Appropriate Legal
            Notices displayed by works containing it; or
            <br />
            c) Prohibiting misrepresentation of the origin of that material, or
            requiring that modified versions of such material be marked in
            reasonable ways as different from the original version; or
            <br />
            d) Limiting the use for publicity purposes of names of licensors or
            authors of the material; or
            <br />
            e) Declining to grant rights under trademark law for use of some
            trade names, trademarks, or service marks; or
            <br />
            f) Requiring indemnification of licensors and authors of that
            material by anyone who conveys the material (or modified versions of
            it) with contractual assumptions of liability to the recipient, for
            any liability that these contractual assumptions directly impose on
            those licensors and authors.
            <br />
            All other non-permissive additional terms are considered
            &ldquo;further restrictions&rdquo; within the meaning of section 10.
            If the Program as you received it, or any part of it, contains a
            notice stating that it is governed by this License along with a term
            that is a further restriction, you may remove that term. If a
            license document contains a further restriction but permits
            relicensing or conveying under this License, you may add to a
            covered work material governed by the terms of that license
            document, provided that the further restriction does not survive
            such relicensing or conveying.
            <br />
            If you add terms to a covered work in accord with this section, you
            must place, in the relevant source files, a statement of the
            additional terms that apply to those files, or a notice indicating
            where to find the applicable terms.
            <br />
            Additional terms, permissive or non-permissive, may be stated in the
            form of a separately written license, or stated as exceptions; the
            above requirements apply either way.
            <br />
            8. Termination.
            <br />
            You may not propagate or modify a covered work except as expressly
            provided under this License. Any attempt otherwise to propagate or
            modify it is void, and will automatically terminate your rights
            under this License (including any patent licenses granted under the
            third paragraph of section 11).
            <br />
            However, if you cease all violation of this License, then your
            license from a particular copyright holder is reinstated (a)
            provisionally, unless and until the copyright holder explicitly and
            finally terminates your license, and (b) permanently, if the
            copyright holder fails to notify you of the violation by some
            reasonable means prior to 60 days after the cessation.
            <br />
            Moreover, your license from a particular copyright holder is
            reinstated permanently if the copyright holder notifies you of the
            violation by some reasonable means, this is the first time you have
            received notice of violation of this License (for any work) from
            that copyright holder, and you cure the violation prior to 30 days
            after your receipt of the notice.
            <br />
            Termination of your rights under this section does not terminate the
            licenses of parties who have received copies or rights from you
            under this License. If your rights have been terminated and not
            permanently reinstated, you do not qualify to receive new licenses
            for the same material under section 10.
            <br />
            9. Acceptance Not Required for Having Copies.
            <br />
            You are not required to accept this License in order to receive or
            run a copy of the Program. Ancillary propagation of a covered work
            occurring solely as a consequence of using peer-to-peer transmission
            to receive a copy likewise does not require acceptance. However,
            nothing other than this License grants you permission to propagate
            or modify any covered work. These actions infringe copyright if you
            do not accept this License. Therefore, by modifying or propagating a
            covered work, you indicate your acceptance of this License to do so.
            <br />
            10. Automatic Licensing of Downstream Recipients.
            <br />
            Each time you convey a covered work, the recipient automatically
            receives a license from the original licensors, to run, modify and
            propagate that work, subject to this License. You are not
            responsible for enforcing compliance by third parties with this
            License.
            <br />
            An &ldquo;entity transaction&rdquo; is a transaction transferring
            control of an organization, or substantially all assets of one, or
            subdividing an organization, or merging organizations. If
            propagation of a covered work results from an entity transaction,
            each party to that transaction who receives a copy of the work also
            receives whatever licenses to the work the party&rsquo;s predecessor
            in interest had or could give under the previous paragraph, plus a
            right to possession of the Corresponding Source of the work from the
            predecessor in interest, if the predecessor has it or can get it
            with reasonable efforts.
            <br />
            You may not impose any further restrictions on the exercise of the
            rights granted or affirmed under this License. For example, you may
            not impose a license fee, royalty, or other charge for exercise of
            rights granted under this License, and you may not initiate
            litigation (including a cross-claim or counterclaim in a lawsuit)
            alleging that any patent claim is infringed by making, using,
            selling, offering for sale, or importing the Program or any portion
            of it.
            <br />
            11. Patents.
            <br />
            A &ldquo;contributor&rdquo; is a copyright holder who authorizes use
            under this License of the Program or a work on which the Program is
            based. The work thus licensed is called the contributor&rsquo;s
            &ldquo;contributor version&rdquo;.
            <br />
            A contributor&rsquo;s &ldquo;essential patent claims&rdquo; are all
            patent claims owned or controlled by the contributor, whether
            already acquired or hereafter acquired, that would be infringed by
            some manner, permitted by this License, of making, using, or selling
            its contributor version, but do not include claims that would be
            infringed only as a consequence of further modification of the
            contributor version. For purposes of this definition,
            &ldquo;control&rdquo; includes the right to grant patent sublicenses
            in a manner consistent with the requirements of this License.
            <br />
            Each contributor grants you a non-exclusive, worldwide, royalty-free
            patent license under the contributor&rsquo;s essential patent
            claims, to make, use, sell, offer for sale, import and otherwise
            run, modify and propagate the contents of its contributor version.
            <br />
            In the following three paragraphs, a &ldquo;patent license&rdquo; is
            any express agreement or commitment, however denominated, not to
            enforce a patent (such as an express permission to practice a patent
            or covenant not to sue for patent infringement). To
            &ldquo;grant&rdquo; such a patent license to a party means to make
            such an agreement or commitment not to enforce a patent against the
            party.
            <br />
            If you convey a covered work, knowingly relying on a patent license,
            and the Corresponding Source of the work is not available for anyone
            to copy, free of charge and under the terms of this License, through
            a publicly available network server or other readily accessible
            means, then you must either (1) cause the Corresponding Source to be
            so available, or (2) arrange to deprive yourself of the benefit of
            the patent license for this particular work, or (3) arrange, in a
            manner consistent with the requirements of this License, to extend
            the patent license to downstream recipients. &ldquo;Knowingly
            relying&rdquo; means you have actual knowledge that, but for the
            patent license, your conveying the covered work in a country, or
            your recipient&rsquo;s use of the covered work in a country, would
            infringe one or more identifiable patents in that country that you
            have reason to believe are valid.
            <br />
            If, pursuant to or in connection with a single transaction or
            arrangement, you convey, or propagate by procuring conveyance of, a
            covered work, and grant a patent license to some of the parties
            receiving the covered work authorizing them to use, propagate,
            modify or convey a specific copy of the covered work, then the
            patent license you grant is automatically extended to all recipients
            of the covered work and works based on it.
            <br />
            A patent license is &ldquo;discriminatory&rdquo; if it does not
            include within the scope of its coverage, prohibits the exercise of,
            or is conditioned on the non-exercise of one or more of the rights
            that are specifically granted under this License. You may not convey
            a covered work if you are a party to an arrangement with a third
            party that is in the business of distributing software, under which
            you make payment to the third party based on the extent of your
            activity of conveying the work, and under which the third party
            grants, to any of the parties who would receive the covered work
            from you, a discriminatory patent license (a) in connection with
            copies of the covered work conveyed by you (or copies made from
            those copies), or (b) primarily for and in connection with specific
            products or compilations that contain the covered work, unless you
            entered into that arrangement, or that patent license was granted,
            prior to 28 March 2007.
            <br />
            Nothing in this License shall be construed as excluding or limiting
            any implied license or other defenses to infringement that may
            otherwise be available to you under applicable patent law.
            <br />
            12. No Surrender of Others&rsquo; Freedom.
            <br />
            If conditions are imposed on you (whether by court order, agreement
            or otherwise) that contradict the conditions of this License, they
            do not excuse you from the conditions of this License. If you cannot
            convey a covered work so as to satisfy simultaneously your
            obligations under this License and any other pertinent obligations,
            then as a consequence you may not convey it at all. For example, if
            you agree to terms that obligate you to collect a royalty for
            further conveying from those to whom you convey the Program, the
            only way you could satisfy both those terms and this License would
            be to refrain entirely from conveying the Program.
            <br />
            13. Use with the GNU Affero General Public License.
            <br />
            Notwithstanding any other provision of this License, you have
            permission to link or combine any covered work with a work licensed
            under version 3 of the GNU Affero General Public License into a
            single combined work, and to convey the resulting work. The terms of
            this License will continue to apply to the part which is the covered
            work, but the special requirements of the GNU Affero General Public
            License, section 13, concerning interaction through a network will
            apply to the combination as such.
            <br />
            14. Revised Versions of this License.
            <br />
            The Free Software Foundation may publish revised and/or new versions
            of the GNU General Public License from time to time. Such new
            versions will be similar in spirit to the present version, but may
            differ in detail to address new problems or concerns.
            <br />
            Each version is given a distinguishing version number. If the
            Program specifies that a certain numbered version of the GNU General
            Public License &ldquo;or any later version&rdquo; applies to it, you
            have the option of following the terms and conditions either of that
            numbered version or of any later version published by the Free
            Software Foundation. If the Program does not specify a version
            number of the GNU General Public License, you may choose any version
            ever published by the Free Software Foundation.
            <br />
            If the Program specifies that a proxy can decide which future
            versions of the GNU General Public License can be used, that
            proxy&rsquo;s public statement of acceptance of a version
            permanently authorizes you to choose that version for the Program.
            <br />
            Later license versions may give you additional or different
            permissions. However, no additional obligations are imposed on any
            author or copyright holder as a result of your choosing to follow a
            later version.
            <br />
            15. Disclaimer of Warranty.
            <br />
            THERE IS NO WARRANTY FOR THE PROGRAM, TO THE EXTENT PERMITTED BY
            APPLICABLE LAW. EXCEPT WHEN OTHERWISE STATED IN WRITING THE
            COPYRIGHT HOLDERS AND/OR OTHER PARTIES PROVIDE THE PROGRAM &ldquo;AS
            IS&rdquo; WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. THE ENTIRE
            RISK AS TO THE QUALITY AND PERFORMANCE OF THE PROGRAM IS WITH YOU.
            SHOULD THE PROGRAM PROVE DEFECTIVE, YOU ASSUME THE COST OF ALL
            NECESSARY SERVICING, REPAIR OR CORRECTION.
            <br />
            16. Limitation of Liability.
            <br />
            IN NO EVENT UNLESS REQUIRED BY APPLICABLE LAW OR AGREED TO IN
            WRITING WILL ANY COPYRIGHT HOLDER, OR ANY OTHER PARTY WHO MODIFIES
            AND/OR CONVEYS THE PROGRAM AS PERMITTED ABOVE, BE LIABLE TO YOU FOR
            DAMAGES, INCLUDING ANY GENERAL, SPECIAL, INCIDENTAL OR CONSEQUENTIAL
            DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE THE PROGRAM
            (INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR DATA BEING RENDERED
            INACCURATE OR LOSSES SUSTAINED BY YOU OR THIRD PARTIES OR A FAILURE
            OF THE PROGRAM TO OPERATE WITH ANY OTHER PROGRAMS), EVEN IF SUCH
            HOLDER OR OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES.
            <br />
            17. Interpretation of Sections 15 and 16.
            <br />
            If the disclaimer of warranty and limitation of liability provided
            above cannot be given local legal effect according to their terms,
            reviewing courts shall apply local law that most closely
            approximates an absolute waiver of all civil liability in connection
            with the Program, unless a warranty or assumption of liability
            accompanies a copy of the Program in return for a fee.
            <br />
            END OF TERMS AND CONDITIONS
            <br />
            How to Apply These Terms to Your New Programs
            <br />
            If you develop a new program, and you want it to be of the greatest
            possible use to the public, the best way to achieve this is to make
            it free software which everyone can redistribute and change under
            these terms.
            <br />
            To do so, attach the following notices to the program. It is safest
            to attach them to the start of each source file to most effectively
            state the exclusion of warranty; and each file should have at least
            the &ldquo;copyright&rdquo; line and a pointer to where the full
            notice is found.
            <br />
            [one line to give the program&rsquo;s name and a brief idea of what
            it does.] Copyright (C) [year] [name of author] This program is free
            software: you can redistribute it and/or modify it under the terms
            of the GNU General Public License as published by the Free Software
            Foundation, either version 3 of the License, or (at your option) any
            later version. This program is distributed in the hope that it will
            be useful, but WITHOUT ANY WARRANTY; without even the implied
            warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See
            the GNU General Public License for more details. You should have
            received a copy of the GNU General Public License along with this
            program. If not, see &#60;http://www.gnu.org/licenses/&#62;.
            <br />
            Also add information on how to contact you by electronic and paper
            mail.
            <br />
            If the program does terminal interaction, make it output a short
            notice like this when it starts in an interactive mode:
            <br />
            [program] Copyright (C) [year] [name of author] This program comes
            with ABSOLUTELY NO WARRANTY; for details type &#96;show w&rsquo;.
            This is free software, and you are welcome to redistribute it under
            certain conditions; type &#96;show c&rsquo; for details.
            <br />
            The hypothetical commands &#96;show w&rsquo; and &#96;show c&rsquo;
            should show the appropriate parts of the General Public License. Of
            course, your program&rsquo;s commands might be different; for a GUI
            interface, you would use an &ldquo;about box&rdquo;.
            <br />
            You should also get your employer (if you work as a programmer) or
            school, if any, to sign a &ldquo;copyright disclaimer&rdquo; for the
            program, if necessary. For more information on this, and how to
            apply and follow the GNU GPL, see
            &#60;http://www.gnu.org/licenses/&#62;.
            <br />
            The GNU General Public License does not permit incorporating your
            program into proprietary programs. If your program is a subroutine
            library, you may consider it more useful to permit linking
            proprietary applications with the library. If this is what you want
            to do, use the GNU Lesser General Public License instead of this
            License. But first, please read
            &#60;http://www.gnu.org/philosophy/why-not-lgpl.html&#62;.
          </p>
        </>
      );
    }
    case "6": {
      return (
        <>
          <p>
            Copyright (C) 1991, 1999 Free Software Foundation, Inc. 59 Temple
            Place, Suite 330, Boston, MA 02111-1307 USA Everyone is permitted to
            copy and distribute verbatim copies of this license document, but
            changing it is not allowed.
            <br />
            [This is the first released version of the Lesser GPL. It also
            counts as the successor of the GNU Library Public License, version
            2, hence the version number 2.1.]
            <br />
            Preamble
            <br />
            The licenses for most software are designed to take away your
            freedom to share and change it. By contrast, the GNU General Public
            Licenses are intended to guarantee your freedom to share and change
            free software&ndash;to make sure the software is free for all its
            users.
            <br />
            This license, the Lesser General Public License, applies to some
            specially designated software packages&ndash;typically
            libraries&ndash;of the Free Software Foundation and other authors
            who decide to use it. You can use it too, but we suggest you first
            think carefully about whether this license or the ordinary General
            Public License is the better strategy to use in any particular case,
            based on the explanations below.
            <br />
            When we speak of free software, we are referring to freedom of use,
            not price. Our General Public Licenses are designed to make sure
            that you have the freedom to distribute copies of free software (and
            charge for this service if you wish); that you receive source code
            or can get it if you want it; that you can change the software and
            use pieces of it in new free programs; and that you are informed
            that you can do these things.
            <br />
            To protect your rights, we need to make restrictions that forbid
            distributors to deny you these rights or to ask you to surrender
            these rights. These restrictions translate to certain
            responsibilities for you if you distribute copies of the library or
            if you modify it.
            <br />
            For example, if you distribute copies of the library, whether gratis
            or for a fee, you must give the recipients all the rights that we
            gave you. You must make sure that they, too, receive or can get the
            source code. If you link other code with the library, you must
            provide complete object files to the recipients, so that they can
            relink them with the library after making changes to the library and
            recompiling it. And you must show them these terms so they know
            their rights.
            <br />
            We protect your rights with a two-step method: (1) we copyright the
            library, and (2) we offer you this license, which gives you legal
            permission to copy, distribute and/or modify the library.
            <br />
            To protect each distributor, we want to make it very clear that
            there is no warranty for the free library. Also, if the library is
            modified by someone else and passed on, the recipients should know
            that what they have is not the original version, so that the
            original author&rsquo;s reputation will not be affected by problems
            that might be introduced by others.
            <br />
            Finally, software patents pose a constant threat to the existence of
            any free program. We wish to make sure that a company cannot
            effectively restrict the users of a free program by obtaining a
            restrictive license from a patent holder. Therefore, we insist that
            any patent license obtained for a version of the library must be
            consistent with the full freedom of use specified in this license.
            <br />
            Most GNU software, including some libraries, is covered by the
            ordinary GNU General Public License. This license, the GNU Lesser
            General Public License, applies to certain designated libraries, and
            is quite different from the ordinary General Public License. We use
            this license for certain libraries in order to permit linking those
            libraries into non-free programs.
            <br />
            When a program is linked with a library, whether statically or using
            a shared library, the combination of the two is legally speaking a
            combined work, a derivative of the original library. The ordinary
            General Public License therefore permits such linking only if the
            entire combination fits its criteria of freedom. The Lesser General
            Public License permits more lax criteria for linking other code with
            the library.
            <br />
            We call this license the &ldquo;Lesser&rdquo; General Public License
            because it does Less to protect the user&rsquo;s freedom than the
            ordinary General Public License. It also provides other free
            software developers Less of an advantage over competing non-free
            programs. These disadvantages are the reason we use the ordinary
            General Public License for many libraries. However, the Lesser
            license provides advantages in certain special circumstances.
            <br />
            For example, on rare occasions, there may be a special need to
            encourage the widest possible use of a certain library, so that it
            becomes a de-facto standard. To achieve this, non-free programs must
            be allowed to use the library. A more frequent case is that a free
            library does the same job as widely used non-free libraries. In this
            case, there is little to gain by limiting the free library to free
            software only, so we use the Lesser General Public License.
            <br />
            In other cases, permission to use a particular library in non-free
            programs enables a greater number of people to use a large body of
            free software. For example, permission to use the GNU C Library in
            non-free programs enables many more people to use the whole GNU
            operating system, as well as its variant, the GNU/Linux operating
            system.
            <br />
            Although the Lesser General Public License is Less protective of the
            users&rsquo; freedom, it does ensure that the user of a program that
            is linked with the Library has the freedom and the wherewithal to
            run that program using a modified version of the Library.
            <br />
            The precise terms and conditions for copying, distribution and
            modification follow. Pay close attention to the difference between a
            &ldquo;work based on the library&rdquo; and a &ldquo;work that uses
            the library&rdquo;. The former contains code derived from the
            library, whereas the latter must be combined with the library in
            order to run.
            <br />
            TERMS AND CONDITIONS FOR COPYING, DISTRIBUTION AND MODIFICATION
            <br />
            0. This License Agreement applies to any software library or other
            program which contains a notice placed by the copyright holder or
            other authorized party saying it may be distributed under the terms
            of this Lesser General Public License (also called &ldquo;this
            License&rdquo;). Each licensee is addressed as &ldquo;you&rdquo;.
            <br />
            A &ldquo;library&rdquo; means a collection of software functions
            and/or data prepared so as to be conveniently linked with
            application programs (which use some of those functions and data) to
            form executables.
            <br />
            The &ldquo;Library&rdquo;, below, refers to any such software
            library or work which has been distributed under these terms. A
            &ldquo;work based on the Library&rdquo; means either the Library or
            any derivative work under copyright law: that is to say, a work
            containing the Library or a portion of it, either verbatim or with
            modifications and/or translated straightforwardly into another
            language. (Hereinafter, translation is included without limitation
            in the term &ldquo;modification&rdquo;.)
            <br />
            &ldquo;Source code&rdquo; for a work means the preferred form of the
            work for making modifications to it. For a library, complete source
            code means all the source code for all modules it contains, plus any
            associated interface definition files, plus the scripts used to
            control compilation and installation of the library.
            <br />
            Activities other than copying, distribution and modification are not
            covered by this License; they are outside its scope. The act of
            running a program using the Library is not restricted, and output
            from such a program is covered only if its contents constitute a
            work based on the Library (independent of the use of the Library in
            a tool for writing it). Whether that is true depends on what the
            Library does and what the program that uses the Library does.
            <br />
            1. You may copy and distribute verbatim copies of the
            Library&rsquo;s complete source code as you receive it, in any
            medium, provided that you conspicuously and appropriately publish on
            each copy an appropriate copyright notice and disclaimer of
            warranty; keep intact all the notices that refer to this License and
            to the absence of any warranty; and distribute a copy of this
            License along with the Library.
            <br />
            You may charge a fee for the physical act of transferring a copy,
            and you may at your option offer warranty protection in exchange for
            a fee.
            <br />
            2. You may modify your copy or copies of the Library or any portion
            of it, thus forming a work based on the Library, and copy and
            distribute such modifications or work under the terms of Section 1
            above, provided that you also meet all of these conditions:
            <br />
            a) The modified work must itself be a software library.
            <br />
            b) You must cause the files modified to carry prominent notices
            stating that you changed the files and the date of any change.
            <br />
            c) You must cause the whole of the work to be licensed at no charge
            to all third parties under the terms of this License.
            <br />
            d) If a facility in the modified Library refers to a function or a
            table of data to be supplied by an application program that uses the
            facility, other than as an argument passed when the facility is
            invoked, then you must make a good faith effort to ensure that, in
            the event an application does not supply such function or table, the
            facility still operates, and performs whatever part of its purpose
            remains meaningful.
            <br />
            (For example, a function in a library to compute square roots has a
            purpose that is entirely well-defined independent of the
            application. Therefore, Subsection 2d requires that any
            application-supplied function or table used by this function must be
            optional: if the application does not supply it, the square root
            function must still compute square roots.)
            <br />
            These requirements apply to the modified work as a whole. If
            identifiable sections of that work are not derived from the Library,
            and can be reasonably considered independent and separate works in
            themselves, then this License, and its terms, do not apply to those
            sections when you distribute them as separate works. But when you
            distribute the same sections as part of a whole which is a work
            based on the Library, the distribution of the whole must be on the
            terms of this License, whose permissions for other licensees extend
            to the entire whole, and thus to each and every part regardless of
            who wrote it.
            <br />
            Thus, it is not the intent of this section to claim rights or
            contest your rights to work written entirely by you; rather, the
            intent is to exercise the right to control the distribution of
            derivative or collective works based on the Library.
            <br />
            In addition, mere aggregation of another work not based on the
            Library with the Library (or with a work based on the Library) on a
            volume of a storage or distribution medium does not bring the other
            work under the scope of this License.
            <br />
            3. You may opt to apply the terms of the ordinary GNU General Public
            License instead of this License to a given copy of the Library. To
            do this, you must alter all the notices that refer to this License,
            so that they refer to the ordinary GNU General Public License,
            version 2, instead of to this License. (If a newer version than
            version 2 of the ordinary GNU General Public License has appeared,
            then you can specify that version instead if you wish.) Do not make
            any other change in these notices.
            <br />
            Once this change is made in a given copy, it is irreversible for
            that copy, so the ordinary GNU General Public License applies to all
            subsequent copies and derivative works made from that copy.
            <br />
            This option is useful when you wish to copy part of the code of the
            Library into a program that is not a library.
            <br />
            4. You may copy and distribute the Library (or a portion or
            derivative of it, under Section 2) in object code or executable form
            under the terms of Sections 1 and 2 above provided that you
            accompany it with the complete corresponding machine-readable source
            code, which must be distributed under the terms of Sections 1 and 2
            above on a medium customarily used for software interchange.
            <br />
            If distribution of object code is made by offering access to copy
            from a designated place, then offering equivalent access to copy the
            source code from the same place satisfies the requirement to
            distribute the source code, even though third parties are not
            compelled to copy the source along with the object code.
            <br />
            5. A program that contains no derivative of any portion of the
            Library, but is designed to work with the Library by being compiled
            or linked with it, is called a &ldquo;work that uses the
            Library&rdquo;. Such a work, in isolation, is not a derivative work
            of the Library, and therefore falls outside the scope of this
            License.
            <br />
            However, linking a &ldquo;work that uses the Library&rdquo; with the
            Library creates an executable that is a derivative of the Library
            (because it contains portions of the Library), rather than a
            &ldquo;work that uses the library&rdquo;. The executable is
            therefore covered by this License. Section 6 states terms for
            distribution of such executables.
            <br />
            When a &ldquo;work that uses the Library&rdquo; uses material from a
            header file that is part of the Library, the object code for the
            work may be a derivative work of the Library even though the source
            code is not. Whether this is true is especially significant if the
            work can be linked without the Library, or if the work is itself a
            library. The threshold for this to be true is not precisely defined
            by law.
            <br />
            If such an object file uses only numerical parameters, data
            structure layouts and accessors, and small macros and small inline
            functions (ten lines or less in length), then the use of the object
            file is unrestricted, regardless of whether it is legally a
            derivative work. (Executables containing this object code plus
            portions of the Library will still fall under Section 6.)
            <br />
            Otherwise, if the work is a derivative of the Library, you may
            distribute the object code for the work under the terms of Section
            6. Any executables containing that work also fall under Section 6,
            whether or not they are linked directly with the Library itself.
            <br />
            6. As an exception to the Sections above, you may also combine or
            link a &ldquo;work that uses the Library&rdquo; with the Library to
            produce a work containing portions of the Library, and distribute
            that work under terms of your choice, provided that the terms permit
            modification of the work for the customer&rsquo;s own use and
            reverse engineering for debugging such modifications.
            <br />
            You must give prominent notice with each copy of the work that the
            Library is used in it and that the Library and its use are covered
            by this License. You must supply a copy of this License. If the work
            during execution displays copyright notices, you must include the
            copyright notice for the Library among them, as well as a reference
            directing the user to the copy of this License. Also, you must do
            one of these things:
            <br />
            a) Accompany the work with the complete corresponding
            machine-readable source code for the Library including whatever
            changes were used in the work (which must be distributed under
            Sections 1 and 2 above); and, if the work is an executable linked
            with the Library, with the complete machine-readable &ldquo;work
            that uses the Library&rdquo;, as object code and/or source code, so
            that the user can modify the Library and then relink to produce a
            modified executable containing the modified Library. (It is
            understood that the user who changes the contents of definitions
            files in the Library will not necessarily be able to recompile the
            application to use the modified definitions.)
            <br />
            b) Use a suitable shared library mechanism for linking with the
            Library. A suitable mechanism is one that (1) uses at run time a
            copy of the library already present on the user&rsquo;s computer
            system, rather than copying library functions into the executable,
            and (2) will operate properly with a modified version of the
            library, if the user installs one, as long as the modified version
            is interface-compatible with the version that the work was made
            with.
            <br />
            c) Accompany the work with a written offer, valid for at least three
            years, to give the same user the materials specified in Subsection
            6a, above, for a charge no more than the cost of performing this
            distribution.
            <br />
            d) If distribution of the work is made by offering access to copy
            from a designated place, offer equivalent access to copy the above
            specified materials from the same place.
            <br />
            e) Verify that the user has already received a copy of these
            materials or that you have already sent this user a copy.
            <br />
            For an executable, the required form of the &ldquo;work that uses
            the Library&rdquo; must include any data and utility programs needed
            for reproducing the executable from it. However, as a special
            exception, the materials to be distributed need not include anything
            that is normally distributed (in either source or binary form) with
            the major components (compiler, kernel, and so on) of the operating
            system on which the executable runs, unless that component itself
            accompanies the executable.
            <br />
            It may happen that this requirement contradicts the license
            restrictions of other proprietary libraries that do not normally
            accompany the operating system. Such a contradiction means you
            cannot use both them and the Library together in an executable that
            you distribute.
            <br />
            7. You may place library facilities that are a work based on the
            Library side-by-side in a single library together with other library
            facilities not covered by this License, and distribute such a
            combined library, provided that the separate distribution of the
            work based on the Library and of the other library facilities is
            otherwise permitted, and provided that you do these two things:
            <br />
            a) Accompany the combined library with a copy of the same work based
            on the Library, uncombined with any other library facilities. This
            must be distributed under the terms of the Sections above.
            <br />
            b) Give prominent notice with the combined library of the fact that
            part of it is a work based on the Library, and explaining where to
            find the accompanying uncombined form of the same work.
            <br />
            8. You may not copy, modify, sublicense, link with, or distribute
            the Library except as expressly provided under this License. Any
            attempt otherwise to copy, modify, sublicense, link with, or
            distribute the Library is void, and will automatically terminate
            your rights under this License. However, parties who have received
            copies, or rights, from you under this License will not have their
            licenses terminated so long as such parties remain in full
            compliance.
            <br />
            9. You are not required to accept this License, since you have not
            signed it. However, nothing else grants you permission to modify or
            distribute the Library or its derivative works. These actions are
            prohibited by law if you do not accept this License. Therefore, by
            modifying or distributing the Library (or any work based on the
            Library), you indicate your acceptance of this License to do so, and
            all its terms and conditions for copying, distributing or modifying
            the Library or works based on it.
            <br />
            10. Each time you redistribute the Library (or any work based on the
            Library), the recipient automatically receives a license from the
            original licensor to copy, distribute, link with or modify the
            Library subject to these terms and conditions. You may not impose
            any further restrictions on the recipients&rsquo; exercise of the
            rights granted herein. You are not responsible for enforcing
            compliance by third parties with this License.
            <br />
            11. If, as a consequence of a court judgment or allegation of patent
            infringement or for any other reason (not limited to patent issues),
            conditions are imposed on you (whether by court order, agreement or
            otherwise) that contradict the conditions of this License, they do
            not excuse you from the conditions of this License. If you cannot
            distribute so as to satisfy simultaneously your obligations under
            this License and any other pertinent obligations, then as a
            consequence you may not distribute the Library at all. For example,
            if a patent license would not permit royalty-free redistribution of
            the Library by all those who receive copies directly or indirectly
            through you, then the only way you could satisfy both it and this
            License would be to refrain entirely from distribution of the
            Library.
            <br />
            If any portion of this section is held invalid or unenforceable
            under any particular circumstance, the balance of the section is
            intended to apply, and the section as a whole is intended to apply
            in other circumstances.
            <br />
            It is not the purpose of this section to induce you to infringe any
            patents or other property right claims or to contest validity of any
            such claims; this section has the sole purpose of protecting the
            integrity of the free software distribution system which is
            implemented by public license practices. Many people have made
            generous contributions to the wide range of software distributed
            through that system in reliance on consistent application of that
            system; it is up to the author/donor to decide if he or she is
            willing to distribute software through any other system and a
            licensee cannot impose that choice.
            <br />
            This section is intended to make thoroughly clear what is believed
            to be a consequence of the rest of this License.
            <br />
            12. If the distribution and/or use of the Library is restricted in
            certain countries either by patents or by copyrighted interfaces,
            the original copyright holder who places the Library under this
            License may add an explicit geographical distribution limitation
            excluding those countries, so that distribution is permitted only in
            or among countries not thus excluded. In such case, this License
            incorporates the limitation as if written in the body of this
            License.
            <br />
            13. The Free Software Foundation may publish revised and/or new
            versions of the Lesser General Public License from time to time.
            Such new versions will be similar in spirit to the present version,
            but may differ in detail to address new problems or concerns.
            <br />
            Each version is given a distinguishing version number. If the
            Library specifies a version number of this License which applies to
            it and &ldquo;any later version&rdquo;, you have the option of
            following the terms and conditions either of that version or of any
            later version published by the Free Software Foundation. If the
            Library does not specify a license version number, you may choose
            any version ever published by the Free Software Foundation.
            <br />
            14. If you wish to incorporate parts of the Library into other free
            programs whose distribution conditions are incompatible with these,
            write to the author to ask for permission. For software which is
            copyrighted by the Free Software Foundation, write to the Free
            Software Foundation; we sometimes make exceptions for this. Our
            decision will be guided by the two goals of preserving the free
            status of all derivatives of our free software and of promoting the
            sharing and reuse of software generally.
            <br />
            NO WARRANTY
            <br />
            15. BECAUSE THE LIBRARY IS LICENSED FREE OF CHARGE, THERE IS NO
            WARRANTY FOR THE LIBRARY, TO THE EXTENT PERMITTED BY APPLICABLE LAW.
            EXCEPT WHEN OTHERWISE STATED IN WRITING THE COPYRIGHT HOLDERS AND/OR
            OTHER PARTIES PROVIDE THE LIBRARY &ldquo;AS IS&rdquo; WITHOUT
            WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
            FITNESS FOR A PARTICULAR PURPOSE. THE ENTIRE RISK AS TO THE QUALITY
            AND PERFORMANCE OF THE LIBRARY IS WITH YOU. SHOULD THE LIBRARY PROVE
            DEFECTIVE, YOU ASSUME THE COST OF ALL NECESSARY SERVICING, REPAIR OR
            CORRECTION.
            <br />
            16. IN NO EVENT UNLESS REQUIRED BY APPLICABLE LAW OR AGREED TO IN
            WRITING WILL ANY COPYRIGHT HOLDER, OR ANY OTHER PARTY WHO MAY MODIFY
            AND/OR REDISTRIBUTE THE LIBRARY AS PERMITTED ABOVE, BE LIABLE TO YOU
            FOR DAMAGES, INCLUDING ANY GENERAL, SPECIAL, INCIDENTAL OR
            CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE THE
            LIBRARY (INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR DATA BEING
            RENDERED INACCURATE OR LOSSES SUSTAINED BY YOU OR THIRD PARTIES OR A
            FAILURE OF THE LIBRARY TO OPERATE WITH ANY OTHER SOFTWARE), EVEN IF
            SUCH HOLDER OR OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES.
          </p>
        </>
      );
    }
    case "7": {
      return (
        <>
          <p>
            GNU LESSER GENERAL PUBLIC LICENSE
            <br />
            Version 3, 29 June 2007
            <br />
            Copyright (C) 2007 Free Software Foundation, Inc.
            &#60;http://fsf.org/&#62;
            <br />
            Everyone is permitted to copy and distribute verbatim copies of this
            license document, but changing it is not allowed.
            <br />
            This version of the GNU Lesser General Public License incorporates
            the terms and conditions of version 3 of the GNU General Public
            License, supplemented by the additional permissions listed below.
            <br />
            0. Additional Definitions.
            <br />
            As used herein, &ldquo;this License&rdquo; refers to version 3 of
            the GNU Lesser General Public License, and the &ldquo;GNU GPL&rdquo;
            refers to version 3 of the GNU General Public License.
            <br />
            &ldquo;The Library&rdquo; refers to a covered work governed by this
            License, other than an Application or a Combined Work as defined
            below.
            <br />
            An &ldquo;Application&rdquo; is any work that makes use of an
            interface provided by the Library, but which is not otherwise based
            on the Library. Defining a subclass of a class defined by the
            Library is deemed a mode of using an interface provided by the
            Library.
            <br />
            A &ldquo;Combined Work&rdquo; is a work produced by combining or
            linking an Application with the Library. The particular version of
            the Library with which the Combined Work was made is also called the
            &ldquo;Linked Version&rdquo;.
            <br />
            The &ldquo;Minimal Corresponding Source&rdquo; for a Combined Work
            means the Corresponding Source for the Combined Work, excluding any
            source code for portions of the Combined Work that, considered in
            isolation, are based on the Application, and not on the Linked
            Version.
            <br />
            The &ldquo;Corresponding Application Code&rdquo; for a Combined Work
            means the object code and/or source code for the Application,
            including any data and utility programs needed for reproducing the
            Combined Work from the Application, but excluding the System
            Libraries of the Combined Work.
            <br />
            1. Exception to Section 3 of the GNU GPL.
            <br />
            You may convey a covered work under sections 3 and 4 of this License
            without being bound by section 3 of the GNU GPL.
            <br />
            2. Conveying Modified Versions.
            <br />
            If you modify a copy of the Library, and, in your modifications, a
            facility refers to a function or data to be supplied by an
            Application that uses the facility (other than as an argument passed
            when the facility is invoked), then you may convey a copy of the
            modified version:
            <br />
            a) under this License, provided that you make a good faith effort to
            ensure that, in the event an Application does not supply the
            function or data, the facility still operates, and performs whatever
            part of its purpose remains meaningful, or
            <br />
            b) under the GNU GPL, with none of the additional permissions of
            this License applicable to that copy.
            <br />
            3. Object Code Incorporating Material from Library Header Files.
            <br />
            The object code form of an Application may incorporate material from
            a header file that is part of the Library. You may convey such
            object code under terms of your choice, provided that, if the
            incorporated material is not limited to numerical parameters, data
            structure layouts and accessors, or small macros, inline functions
            and templates (ten or fewer lines in length), you do both of the
            following:
            <br />
            a) Give prominent notice with each copy of the object code that the
            Library is used in it and that the Library and its use are covered
            by this License.
            <br />
            b) Accompany the object code with a copy of the GNU GPL and this
            license document.
            <br />
            4. Combined Works.
            <br />
            You may convey a Combined Work under terms of your choice that,
            taken together, effectively do not restrict modification of the
            portions of the Library contained in the Combined Work and reverse
            engineering for debugging such modifications, if you also do each of
            the following:
            <br />
            a) Give prominent notice with each copy of the Combined Work that
            the Library is used in it and that the Library and its use are
            covered by this License.
            <br />
            b) Accompany the Combined Work with a copy of the GNU GPL and this
            license document.
            <br />
            c) For a Combined Work that displays copyright notices during
            execution, include the copyright notice for the Library among these
            notices, as well as a reference directing the user to the copies of
            the GNU GPL and this license document.
            <br />
            d) Do one of the following:
            <br />
            0) Convey the Minimal Corresponding Source under the terms of this
            License, and the Corresponding Application Code in a form suitable
            for, and under terms that permit, the user to recombine or relink
            the Application with a modified version of the Linked Version to
            produce a modified Combined Work, in the manner specified by section
            6 of the GNU GPL for conveying Corresponding Source.
            <br />
            1) Use a suitable shared library mechanism for linking with the
            Library. A suitable mechanism is one that (a) uses at run time a
            copy of the Library already present on the user&rsquo;s computer
            system, and (b) will operate properly with a modified version of the
            Library that is interface-compatible with the Linked Version.
            <br />
            e) Provide Installation Information, but only if you would otherwise
            be required to provide such information under section 6 of the GNU
            GPL, and only to the extent that such information is necessary to
            install and execute a modified version of the Combined Work produced
            by recombining or relinking the Application with a modified version
            of the Linked Version. (If you use option 4d0, the Installation
            Information must accompany the Minimal Corresponding Source and
            Corresponding Application Code. If you use option 4d1, you must
            provide the Installation Information in the manner specified by
            section 6 of the GNU GPL for conveying Corresponding Source.)
            <br />
            5. Combined Libraries.
            <br />
            You may place library facilities that are a work based on the
            Library side by side in a single library together with other library
            facilities that are not Applications and are not covered by this
            License, and convey such a combined library under terms of your
            choice, if you do both of the following:
            <br />
            a) Accompany the combined library with a copy of the same work based
            on the Library, uncombined with any other library facilities,
            conveyed under the terms of this License.
            <br />
            b) Give prominent notice with the combined library that part of it
            is a work based on the Library, and explaining where to find the
            accompanying uncombined form of the same work.
            <br />
            6. Revised Versions of the GNU Lesser General Public License.
            <br />
            The Free Software Foundation may publish revised and/or new versions
            of the GNU Lesser General Public License from time to time. Such new
            versions will be similar in spirit to the present version, but may
            differ in detail to address new problems or concerns.
            <br />
            Each version is given a distinguishing version number. If the
            Library as you received it specifies that a certain numbered version
            of the GNU Lesser General Public License &ldquo;or any later
            version&rdquo; applies to it, you have the option of following the
            terms and conditions either of that published version or of any
            later version published by the Free Software Foundation. If the
            Library as you received it does not specify a version number of the
            GNU Lesser General Public License, you may choose any version of the
            GNU Lesser General Public License ever published by the Free
            Software Foundation.
            <br />
            If the Library as you received it specifies that a proxy can decide
            whether future versions of the GNU Lesser General Public License
            shall apply, that proxy&rsquo;s public statement of acceptance of
            any version is permanent authorization for you to choose that
            version for the Library.
          </p>
        </>
      );
    }
    case "8": {
      return (
        <>
          <p>
            Note: The Free Software Foundation, license steward of the LGPL,
            regards this license as having been superseded by LGPL version 3.
            <br />
            GNU LIBRARY GENERAL PUBLIC LICENSE
            <br />
            Version 2, June 1991
            <br />
            Copyright (C) 1991 Free Software Foundation, Inc.
            <br />
            51 Franklin Street, Fifth Floor, Boston, MA 02110-1301 USA
            <br />
            Everyone is permitted to copy and distribute verbatim copies
            <br />
            of this license document, but changing it is not allowed.
            <br />
            [This is the first released version of the library GPL. It is
            <br />
            numbered 2 because it goes with version 2 of the ordinary GPL.]
            <br />
            Preamble
            <br />
            The licenses for most software are designed to take away your
            <br />
            freedom to share and change it. By contrast, the GNU General Public
            <br />
            Licenses are intended to guarantee your freedom to share and change
            <br />
            free software&ndash;to make sure the software is free for all its
            users.
            <br />
            This license, the Library General Public License, applies to some
            <br />
            specially designated Free Software Foundation software, and to any
            <br />
            other libraries whose authors decide to use it. You can use it for
            <br />
            your libraries, too.
            <br />
            When we speak of free software, we are referring to freedom, not
            <br />
            price. Our General Public Licenses are designed to make sure that
            you
            <br />
            have the freedom to distribute copies of free software (and charge
            for
            <br />
            this service if you wish), that you receive source code or can get
            it
            <br />
            if you want it, that you can change the software or use pieces of it
            <br />
            in new free programs; and that you know you can do these things.
            <br />
            To protect your rights, we need to make restrictions that forbid
            <br />
            anyone to deny you these rights or to ask you to surrender the
            rights.
            <br />
            These restrictions translate to certain responsibilities for you if
            <br />
            you distribute copies of the library, or if you modify it.
            <br />
            For example, if you distribute copies of the library, whether gratis
            <br />
            or for a fee, you must give the recipients all the rights that we
            gave
            <br />
            you. You must make sure that they, too, receive or can get the
            source
            <br />
            code. If you link a program with the library, you must provide
            <br />
            complete object files to the recipients so that they can relink them
            <br />
            with the library, after making changes to the library and
            recompiling
            <br />
            it. And you must show them these terms so they know their rights.
            <br />
            Our method of protecting your rights has two steps: (1) copyright
            <br />
            the library, and (2) offer you this license which gives you legal
            <br />
            permission to copy, distribute and/or modify the library.
            <br />
            Also, for each distributor&rsquo;s protection, we want to make
            certain
            <br />
            that everyone understands that there is no warranty for this free
            <br />
            library. If the library is modified by someone else and passed on,
            we
            <br />
            want its recipients to know that what they have is not the original
            <br />
            version, so that any problems introduced by others will not reflect
            on
            <br />
            the original authors&rsquo; reputations.
            <br />
            Finally, any free program is threatened constantly by software
            <br />
            patents. We wish to avoid the danger that companies distributing
            free
            <br />
            software will individually obtain patent licenses, thus in effect
            <br />
            transforming the program into proprietary software. To prevent this,
            <br />
            we have made it clear that any patent must be licensed for
            everyone&rsquo;s
            <br />
            free use or not licensed at all.
            <br />
            Most GNU software, including some libraries, is covered by the
            ordinary
            <br />
            GNU General Public License, which was designed for utility programs.
            This
            <br />
            license, the GNU Library General Public License, applies to certain
            <br />
            designated libraries. This license is quite different from the
            ordinary
            <br />
            one; be sure to read it in full, and don&rsquo;t assume that
            anything in it is
            <br />
            the same as in the ordinary license.
            <br />
            The reason we have a separate public license for some libraries is
            that
            <br />
            they blur the distinction we usually make between modifying or
            adding to a<br />
            program and simply using it. Linking a program with a library,
            without
            <br />
            changing the library, is in some sense simply using the library, and
            is
            <br />
            analogous to running a utility program or application program.
            However, in
            <br />
            a textual and legal sense, the linked executable is a combined work,
            a<br />
            derivative of the original library, and the ordinary General Public
            License
            <br />
            treats it as such.
            <br />
            Because of this blurred distinction, using the ordinary General
            <br />
            Public License for libraries did not effectively promote software
            <br />
            sharing, because most developers did not use the libraries. We
            <br />
            concluded that weaker conditions might promote sharing better.
            <br />
            However, unrestricted linking of non-free programs would deprive the
            <br />
            users of those programs of all benefit from the free status of the
            <br />
            libraries themselves. This Library General Public License is
            intended to
            <br />
            permit developers of non-free programs to use free libraries, while
            <br />
            preserving your freedom as a user of such programs to change the
            free
            <br />
            libraries that are incorporated in them. (We have not seen how to
            achieve
            <br />
            this as regards changes in header files, but we have achieved it as
            regards
            <br />
            changes in the actual functions of the Library.) The hope is that
            this
            <br />
            will lead to faster development of free libraries.
            <br />
            The precise terms and conditions for copying, distribution and
            <br />
            modification follow. Pay close attention to the difference between a
            <br />
            &ldquo;work based on the library&rdquo; and a &ldquo;work that uses
            the library&rdquo;. The
            <br />
            former contains code derived from the library, while the latter only
            <br />
            works together with the library.
            <br />
            Note that it is possible for a library to be covered by the ordinary
            <br />
            General Public License rather than by this special one.
            <br />
            GNU LIBRARY GENERAL PUBLIC LICENSE
            <br />
            TERMS AND CONDITIONS FOR COPYING, DISTRIBUTION AND MODIFICATION
            <br />
            0. This License Agreement applies to any software library which
            <br />
            contains a notice placed by the copyright holder or other authorized
            <br />
            party saying it may be distributed under the terms of this Library
            <br />
            General Public License (also called &ldquo;this License&rdquo;).
            Each licensee is
            <br />
            addressed as &ldquo;you&rdquo;.
            <br />
            A &ldquo;library&rdquo; means a collection of software functions
            and/or data
            <br />
            prepared so as to be conveniently linked with application programs
            <br />
            (which use some of those functions and data) to form executables.
            <br />
            The &ldquo;Library&rdquo;, below, refers to any such software
            library or work
            <br />
            which has been distributed under these terms. A &ldquo;work based on
            the
            <br />
            Library&rdquo; means either the Library or any derivative work under
            <br />
            copyright law: that is to say, a work containing the Library or a
            <br />
            portion of it, either verbatim or with modifications and/or
            translated
            <br />
            straightforwardly into another language. (Hereinafter, translation
            is
            <br />
            included without limitation in the term &ldquo;modification&rdquo;.)
            <br />
            &ldquo;Source code&rdquo; for a work means the preferred form of the
            work for
            <br />
            making modifications to it. For a library, complete source code
            means
            <br />
            all the source code for all modules it contains, plus any associated
            <br />
            interface definition files, plus the scripts used to control
            compilation
            <br />
            and installation of the library.
            <br />
            Activities other than copying, distribution and modification are not
            <br />
            covered by this License; they are outside its scope. The act of
            <br />
            running a program using the Library is not restricted, and output
            from
            <br />
            such a program is covered only if its contents constitute a work
            based
            <br />
            on the Library (independent of the use of the Library in a tool for
            <br />
            writing it). Whether that is true depends on what the Library does
            <br />
            and what the program that uses the Library does.
            <br />
            1. You may copy and distribute verbatim copies of the
            Library&rsquo;s
            <br />
            complete source code as you receive it, in any medium, provided that
            <br />
            you conspicuously and appropriately publish on each copy an
            <br />
            appropriate copyright notice and disclaimer of warranty; keep intact
            <br />
            all the notices that refer to this License and to the absence of any
            <br />
            warranty; and distribute a copy of this License along with the
            <br />
            Library.
            <br />
            You may charge a fee for the physical act of transferring a copy,
            <br />
            and you may at your option offer warranty protection in exchange for
            a<br />
            fee.
            <br />
            2. You may modify your copy or copies of the Library or any portion
            <br />
            of it, thus forming a work based on the Library, and copy and
            <br />
            distribute such modifications or work under the terms of Section 1
            <br />
            above, provided that you also meet all of these conditions:
            <br />
            a) The modified work must itself be a software library.
            <br />
            b) You must cause the files modified to carry prominent notices
            <br />
            stating that you changed the files and the date of any change.
            <br />
            c) You must cause the whole of the work to be licensed at no
            <br />
            charge to all third parties under the terms of this License.
            <br />
            d) If a facility in the modified Library refers to a function or a
            <br />
            table of data to be supplied by an application program that uses
            <br />
            the facility, other than as an argument passed when the facility
            <br />
            is invoked, then you must make a good faith effort to ensure that,
            <br />
            in the event an application does not supply such function or
            <br />
            table, the facility still operates, and performs whatever part of
            <br />
            its purpose remains meaningful.
            <br />
            (For example, a function in a library to compute square roots has
            <br />
            a purpose that is entirely well-defined independent of the
            <br />
            application. Therefore, Subsection 2d requires that any
            <br />
            application-supplied function or table used by this function must
            <br />
            be optional: if the application does not supply it, the square
            <br />
            root function must still compute square roots.)
            <br />
            These requirements apply to the modified work as a whole. If
            <br />
            identifiable sections of that work are not derived from the Library,
            <br />
            and can be reasonably considered independent and separate works in
            <br />
            themselves, then this License, and its terms, do not apply to those
            <br />
            sections when you distribute them as separate works. But when you
            <br />
            distribute the same sections as part of a whole which is a work
            based
            <br />
            on the Library, the distribution of the whole must be on the terms
            of
            <br />
            this License, whose permissions for other licensees extend to the
            <br />
            entire whole, and thus to each and every part regardless of who
            wrote
            <br />
            it.
            <br />
            Thus, it is not the intent of this section to claim rights or
            contest
            <br />
            your rights to work written entirely by you; rather, the intent is
            to
            <br />
            exercise the right to control the distribution of derivative or
            <br />
            collective works based on the Library.
            <br />
            In addition, mere aggregation of another work not based on the
            Library
            <br />
            with the Library (or with a work based on the Library) on a volume
            of
            <br />
            a storage or distribution medium does not bring the other work under
            <br />
            the scope of this License.
            <br />
            3. You may opt to apply the terms of the ordinary GNU General Public
            <br />
            License instead of this License to a given copy of the Library. To
            do
            <br />
            this, you must alter all the notices that refer to this License, so
            <br />
            that they refer to the ordinary GNU General Public License, version
            2,
            <br />
            instead of to this License. (If a newer version than version 2 of
            the
            <br />
            ordinary GNU General Public License has appeared, then you can
            specify
            <br />
            that version instead if you wish.) Do not make any other change in
            <br />
            these notices.
            <br />
            Once this change is made in a given copy, it is irreversible for
            <br />
            that copy, so the ordinary GNU General Public License applies to all
            <br />
            subsequent copies and derivative works made from that copy.
            <br />
            This option is useful when you wish to copy part of the code of
            <br />
            the Library into a program that is not a library.
            <br />
            4. You may copy and distribute the Library (or a portion or
            <br />
            derivative of it, under Section 2) in object code or executable form
            <br />
            under the terms of Sections 1 and 2 above provided that you
            accompany
            <br />
            it with the complete corresponding machine-readable source code,
            which
            <br />
            must be distributed under the terms of Sections 1 and 2 above on a
            <br />
            medium customarily used for software interchange.
            <br />
            If distribution of object code is made by offering access to copy
            <br />
            from a designated place, then offering equivalent access to copy the
            <br />
            source code from the same place satisfies the requirement to
            <br />
            distribute the source code, even though third parties are not
            <br />
            compelled to copy the source along with the object code.
            <br />
            5. A program that contains no derivative of any portion of the
            <br />
            Library, but is designed to work with the Library by being compiled
            or
            <br />
            linked with it, is called a &ldquo;work that uses the
            Library&rdquo;. Such a<br />
            work, in isolation, is not a derivative work of the Library, and
            <br />
            therefore falls outside the scope of this License.
            <br />
            However, linking a &ldquo;work that uses the Library&rdquo; with the
            Library
            <br />
            creates an executable that is a derivative of the Library (because
            it
            <br />
            contains portions of the Library), rather than a &ldquo;work that
            uses the
            <br />
            library&rdquo;. The executable is therefore covered by this License.
            <br />
            Section 6 states terms for distribution of such executables.
            <br />
            When a &ldquo;work that uses the Library&rdquo; uses material from a
            header file
            <br />
            that is part of the Library, the object code for the work may be a
            <br />
            derivative work of the Library even though the source code is not.
            <br />
            Whether this is true is especially significant if the work can be
            <br />
            linked without the Library, or if the work is itself a library. The
            <br />
            threshold for this to be true is not precisely defined by law.
            <br />
            If such an object file uses only numerical parameters, data
            <br />
            structure layouts and accessors, and small macros and small inline
            <br />
            functions (ten lines or less in length), then the use of the object
            <br />
            file is unrestricted, regardless of whether it is legally a
            derivative
            <br />
            work. (Executables containing this object code plus portions of the
            <br />
            Library will still fall under Section 6.)
            <br />
            Otherwise, if the work is a derivative of the Library, you may
            <br />
            distribute the object code for the work under the terms of Section
            6.
            <br />
            Any executables containing that work also fall under Section 6,
            <br />
            whether or not they are linked directly with the Library itself.
            <br />
            6. As an exception to the Sections above, you may also compile or
            <br />
            link a &ldquo;work that uses the Library&rdquo; with the Library to
            produce a<br />
            work containing portions of the Library, and distribute that work
            <br />
            under terms of your choice, provided that the terms permit
            <br />
            modification of the work for the customer&rsquo;s own use and
            reverse
            <br />
            engineering for debugging such modifications.
            <br />
            You must give prominent notice with each copy of the work that the
            <br />
            Library is used in it and that the Library and its use are covered
            by
            <br />
            this License. You must supply a copy of this License. If the work
            <br />
            during execution displays copyright notices, you must include the
            <br />
            copyright notice for the Library among them, as well as a reference
            <br />
            directing the user to the copy of this License. Also, you must do
            one
            <br />
            of these things:
            <br />
            a) Accompany the work with the complete corresponding
            <br />
            machine-readable source code for the Library including whatever
            <br />
            changes were used in the work (which must be distributed under
            <br />
            Sections 1 and 2 above); and, if the work is an executable linked
            <br />
            with the Library, with the complete machine-readable &ldquo;work
            that
            <br />
            uses the Library&rdquo;, as object code and/or source code, so that
            the
            <br />
            user can modify the Library and then relink to produce a modified
            <br />
            executable containing the modified Library. (It is understood
            <br />
            that the user who changes the contents of definitions files in the
            <br />
            Library will not necessarily be able to recompile the application
            <br />
            to use the modified definitions.)
            <br />
            b) Accompany the work with a written offer, valid for at
            <br />
            least three years, to give the same user the materials
            <br />
            specified in Subsection 6a, above, for a charge no more
            <br />
            than the cost of performing this distribution.
            <br />
            c) If distribution of the work is made by offering access to copy
            <br />
            from a designated place, offer equivalent access to copy the above
            <br />
            specified materials from the same place.
            <br />
            d) Verify that the user has already received a copy of these
            <br />
            materials or that you have already sent this user a copy.
            <br />
            For an executable, the required form of the &ldquo;work that uses
            the
            <br />
            Library&rdquo; must include any data and utility programs needed for
            <br />
            reproducing the executable from it. However, as a special exception,
            <br />
            the source code distributed need not include anything that is
            normally
            <br />
            distributed (in either source or binary form) with the major
            <br />
            components (compiler, kernel, and so on) of the operating system on
            <br />
            which the executable runs, unless that component itself accompanies
            <br />
            the executable.
            <br />
            It may happen that this requirement contradicts the license
            <br />
            restrictions of other proprietary libraries that do not normally
            <br />
            accompany the operating system. Such a contradiction means you
            cannot
            <br />
            use both them and the Library together in an executable that you
            <br />
            distribute.
            <br />
            7. You may place library facilities that are a work based on the
            <br />
            Library side-by-side in a single library together with other library
            <br />
            facilities not covered by this License, and distribute such a
            combined
            <br />
            library, provided that the separate distribution of the work based
            on
            <br />
            the Library and of the other library facilities is otherwise
            <br />
            permitted, and provided that you do these two things:
            <br />
            a) Accompany the combined library with a copy of the same work
            <br />
            based on the Library, uncombined with any other library
            <br />
            facilities. This must be distributed under the terms of the
            <br />
            Sections above.
            <br />
            b) Give prominent notice with the combined library of the fact
            <br />
            that part of it is a work based on the Library, and explaining
            <br />
            where to find the accompanying uncombined form of the same work.
            <br />
            8. You may not copy, modify, sublicense, link with, or distribute
            <br />
            the Library except as expressly provided under this License. Any
            <br />
            attempt otherwise to copy, modify, sublicense, link with, or
            <br />
            distribute the Library is void, and will automatically terminate
            your
            <br />
            rights under this License. However, parties who have received
            copies,
            <br />
            or rights, from you under this License will not have their licenses
            <br />
            terminated so long as such parties remain in full compliance.
            <br />
            9. You are not required to accept this License, since you have not
            <br />
            signed it. However, nothing else grants you permission to modify or
            <br />
            distribute the Library or its derivative works. These actions are
            <br />
            prohibited by law if you do not accept this License. Therefore, by
            <br />
            modifying or distributing the Library (or any work based on the
            <br />
            Library), you indicate your acceptance of this License to do so, and
            <br />
            all its terms and conditions for copying, distributing or modifying
            <br />
            the Library or works based on it.
            <br />
            10. Each time you redistribute the Library (or any work based on the
            <br />
            Library), the recipient automatically receives a license from the
            <br />
            original licensor to copy, distribute, link with or modify the
            Library
            <br />
            subject to these terms and conditions. You may not impose any
            further
            <br />
            restrictions on the recipients&rsquo; exercise of the rights granted
            herein.
            <br />
            You are not responsible for enforcing compliance by third parties to
            <br />
            this License.
            <br />
            11. If, as a consequence of a court judgment or allegation of patent
            <br />
            infringement or for any other reason (not limited to patent issues),
            <br />
            conditions are imposed on you (whether by court order, agreement or
            <br />
            otherwise) that contradict the conditions of this License, they do
            not
            <br />
            excuse you from the conditions of this License. If you cannot
            <br />
            distribute so as to satisfy simultaneously your obligations under
            this
            <br />
            License and any other pertinent obligations, then as a consequence
            you
            <br />
            may not distribute the Library at all. For example, if a patent
            <br />
            license would not permit royalty-free redistribution of the Library
            by
            <br />
            all those who receive copies directly or indirectly through you,
            then
            <br />
            the only way you could satisfy both it and this License would be to
            <br />
            refrain entirely from distribution of the Library.
            <br />
            If any portion of this section is held invalid or unenforceable
            under any
            <br />
            particular circumstance, the balance of the section is intended to
            apply,
            <br />
            and the section as a whole is intended to apply in other
            circumstances.
            <br />
            It is not the purpose of this section to induce you to infringe any
            <br />
            patents or other property right claims or to contest validity of any
            <br />
            such claims; this section has the sole purpose of protecting the
            <br />
            integrity of the free software distribution system which is
            <br />
            implemented by public license practices. Many people have made
            <br />
            generous contributions to the wide range of software distributed
            <br />
            through that system in reliance on consistent application of that
            <br />
            system; it is up to the author/donor to decide if he or she is
            willing
            <br />
            to distribute software through any other system and a licensee
            cannot
            <br />
            impose that choice.
            <br />
            This section is intended to make thoroughly clear what is believed
            to
            <br />
            be a consequence of the rest of this License.
            <br />
            12. If the distribution and/or use of the Library is restricted in
            <br />
            certain countries either by patents or by copyrighted interfaces,
            the
            <br />
            original copyright holder who places the Library under this License
            may add
            <br />
            an explicit geographical distribution limitation excluding those
            countries,
            <br />
            so that distribution is permitted only in or among countries not
            thus
            <br />
            excluded. In such case, this License incorporates the limitation as
            if
            <br />
            written in the body of this License.
            <br />
            13. The Free Software Foundation may publish revised and/or new
            <br />
            versions of the Library General Public License from time to time.
            <br />
            Such new versions will be similar in spirit to the present version,
            <br />
            but may differ in detail to address new problems or concerns.
            <br />
            Each version is given a distinguishing version number. If the
            Library
            <br />
            specifies a version number of this License which applies to it and
            <br />
            &ldquo;any later version&rdquo;, you have the option of following
            the terms and
            <br />
            conditions either of that version or of any later version published
            by
            <br />
            the Free Software Foundation. If the Library does not specify a
            <br />
            license version number, you may choose any version ever published by
            <br />
            the Free Software Foundation.
            <br />
            14. If you wish to incorporate parts of the Library into other free
            <br />
            programs whose distribution conditions are incompatible with these,
            <br />
            write to the author to ask for permission. For software which is
            <br />
            copyrighted by the Free Software Foundation, write to the Free
            <br />
            Software Foundation; we sometimes make exceptions for this. Our
            <br />
            decision will be guided by the two goals of preserving the free
            status
            <br />
            of all derivatives of our free software and of promoting the sharing
            <br />
            and reuse of software generally.
            <br />
            NO WARRANTY
            <br />
            15. BECAUSE THE LIBRARY IS LICENSED FREE OF CHARGE, THERE IS NO
            <br />
            WARRANTY FOR THE LIBRARY, TO THE EXTENT PERMITTED BY APPLICABLE LAW.
            <br />
            EXCEPT WHEN OTHERWISE STATED IN WRITING THE COPYRIGHT HOLDERS AND/OR
            <br />
            OTHER PARTIES PROVIDE THE LIBRARY &ldquo;AS IS&rdquo; WITHOUT
            WARRANTY OF ANY
            <br />
            KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
            THE
            <br />
            IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            <br />
            PURPOSE. THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE
            <br />
            LIBRARY IS WITH YOU. SHOULD THE LIBRARY PROVE DEFECTIVE, YOU ASSUME
            <br />
            THE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION.
            <br />
            16. IN NO EVENT UNLESS REQUIRED BY APPLICABLE LAW OR AGREED TO IN
            <br />
            WRITING WILL ANY COPYRIGHT HOLDER, OR ANY OTHER PARTY WHO MAY MODIFY
            <br />
            AND/OR REDISTRIBUTE THE LIBRARY AS PERMITTED ABOVE, BE LIABLE TO YOU
            <br />
            FOR DAMAGES, INCLUDING ANY GENERAL, SPECIAL, INCIDENTAL OR
            <br />
            CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE THE
            <br />
            LIBRARY (INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR DATA BEING
            <br />
            RENDERED INACCURATE OR LOSSES SUSTAINED BY YOU OR THIRD PARTIES OR A
            <br />
            FAILURE OF THE LIBRARY TO OPERATE WITH ANY OTHER SOFTWARE), EVEN IF
            <br />
            SUCH HOLDER OR OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH
            <br />
            DAMAGES.
          </p>
        </>
      );
    }
    case "9": {
      return (
        <>
          <p>
            1. Definitions
            <br />
            1.1. &ldquo;Contributor&rdquo;
            <br />
            means each individual or legal entity that creates, contributes to
            the creation of, or owns Covered Software.
            <br />
            1.2. &ldquo;Contributor Version&rdquo;
            <br />
            means the combination of the Contributions of others (if any) used
            by a Contributor and that particular Contributor&rsquo;s
            Contribution.
            <br />
            1.3. &ldquo;Contribution&rdquo;
            <br />
            means Covered Software of a particular Contributor.
            <br />
            1.4. &ldquo;Covered Software&rdquo;
            <br />
            means Source Code Form to which the initial Contributor has attached
            the notice in Exhibit A, the Executable Form of such Source Code
            Form, and Modifications of such Source Code Form, in each case
            including portions thereof.
            <br />
            1.5. &ldquo;Incompatible With Secondary Licenses&rdquo;
            <br />
            means
            <br />
            that the initial Contributor has attached the notice described in
            Exhibit B to the Covered Software; or
            <br />
            that the Covered Software was made available under the terms of
            version 1.1 or earlier of the License, but not also under the terms
            of a Secondary License.
            <br />
            1.6. &ldquo;Executable Form&rdquo;
            <br />
            means any form of the work other than Source Code Form.
            <br />
            1.7. &ldquo;Larger Work&rdquo;
            <br />
            means a work that combines Covered Software with other material, in
            a separate file or files, that is not Covered Software.
            <br />
            1.8. &ldquo;License&rdquo;
            <br />
            means this document.
            <br />
            1.9. &ldquo;Licensable&rdquo;
            <br />
            means having the right to grant, to the maximum extent possible,
            whether at the time of the initial grant or subsequently, any and
            all of the rights conveyed by this License.
            <br />
            1.10. &ldquo;Modifications&rdquo;
            <br />
            means any of the following:
            <br />
            any file in Source Code Form that results from an addition to,
            deletion from, or modification of the contents of Covered Software;
            or
            <br />
            any new file in Source Code Form that contains any Covered Software.
            <br />
            1.11. &ldquo;Patent Claims&rdquo; of a Contributor
            <br />
            means any patent claim(s), including without limitation, method,
            process, and apparatus claims, in any patent Licensable by such
            Contributor that would be infringed, but for the grant of the
            License, by the making, using, selling, offering for sale, having
            made, import, or transfer of either its Contributions or its
            Contributor Version.
            <br />
            1.12. &ldquo;Secondary License&rdquo;
            <br />
            means either the GNU General Public License, Version 2.0, the GNU
            Lesser General Public License, Version 2.1, the GNU Affero General
            Public License, Version 3.0, or any later versions of those
            licenses.
            <br />
            1.13. &ldquo;Source Code Form&rdquo;
            <br />
            means the form of the work preferred for making modifications.
            <br />
            1.14. &ldquo;You&rdquo; (or &ldquo;Your&rdquo;)
            <br />
            means an individual or a legal entity exercising rights under this
            License. For legal entities, &ldquo;You&rdquo; includes any entity
            that controls, is controlled by, or is under common control with
            You. For purposes of this definition, &ldquo;control&rdquo; means
            (a) the power, direct or indirect, to cause the direction or
            management of such entity, whether by contract or otherwise, or (b)
            ownership of more than fifty percent (50%) of the outstanding shares
            or beneficial ownership of such entity.
            <br />
            2. License Grants and Conditions
            <br />
            2.1. Grants
            <br />
            Each Contributor hereby grants You a world-wide, royalty-free,
            non-exclusive license:
            <br />
            under intellectual property rights (other than patent or trademark)
            Licensable by such Contributor to use, reproduce, make available,
            modify, display, perform, distribute, and otherwise exploit its
            Contributions, either on an unmodified basis, with Modifications, or
            as part of a Larger Work; and
            <br />
            under Patent Claims of such Contributor to make, use, sell, offer
            for sale, have made, import, and otherwise transfer either its
            Contributions or its Contributor Version.
            <br />
            2.2. Effective Date
            <br />
            The licenses granted in Section 2.1 with respect to any Contribution
            become effective for each Contribution on the date the Contributor
            first distributes such Contribution.
            <br />
            2.3. Limitations on Grant Scope
            <br />
            The licenses granted in this Section 2 are the only rights granted
            under this License. No additional rights or licenses will be implied
            from the distribution or licensing of Covered Software under this
            License. Notwithstanding Section 2.1(b) above, no patent license is
            granted by a Contributor:
            <br />
            for any code that a Contributor has removed from Covered Software;
            or
            <br />
            for infringements caused by: (i) Your and any other third
            party&rsquo;s modifications of Covered Software, or (ii) the
            combination of its Contributions with other software (except as part
            of its Contributor Version); or
            <br />
            under Patent Claims infringed by Covered Software in the absence of
            its Contributions.
            <br />
            This License does not grant any rights in the trademarks, service
            marks, or logos of any Contributor (except as may be necessary to
            comply with the notice requirements in Section 3.4).
            <br />
            2.4. Subsequent Licenses
            <br />
            No Contributor makes additional grants as a result of Your choice to
            distribute the Covered Software under a subsequent version of this
            License (see Section 10.2) or under the terms of a Secondary License
            (if permitted under the terms of Section 3.3).
            <br />
            2.5. Representation
            <br />
            Each Contributor represents that the Contributor believes its
            Contributions are its original creation(s) or it has sufficient
            rights to grant the rights to its Contributions conveyed by this
            License.
            <br />
            2.6. Fair Use
            <br />
            This License is not intended to limit any rights You have under
            applicable copyright doctrines of fair use, fair dealing, or other
            equivalents.
            <br />
            2.7. Conditions
            <br />
            Sections 3.1, 3.2, 3.3, and 3.4 are conditions of the licenses
            granted in Section 2.1.
            <br />
            3. Responsibilities
            <br />
            3.1. Distribution of Source Form
            <br />
            All distribution of Covered Software in Source Code Form, including
            any Modifications that You create or to which You contribute, must
            be under the terms of this License. You must inform recipients that
            the Source Code Form of the Covered Software is governed by the
            terms of this License, and how they can obtain a copy of this
            License. You may not attempt to alter or restrict the
            recipients&rsquo; rights in the Source Code Form.
            <br />
            3.2. Distribution of Executable Form
            <br />
            If You distribute Covered Software in Executable Form then:
            <br />
            such Covered Software must also be made available in Source Code
            Form, as described in Section 3.1, and You must inform recipients of
            the Executable Form how they can obtain a copy of such Source Code
            Form by reasonable means in a timely manner, at a charge no more
            than the cost of distribution to the recipient; and
            <br />
            You may distribute such Executable Form under the terms of this
            License, or sublicense it under different terms, provided that the
            license for the Executable Form does not attempt to limit or alter
            the recipients&rsquo; rights in the Source Code Form under this
            License.
            <br />
            3.3. Distribution of a Larger Work
            <br />
            You may create and distribute a Larger Work under terms of Your
            choice, provided that You also comply with the requirements of this
            License for the Covered Software. If the Larger Work is a
            combination of Covered Software with a work governed by one or more
            Secondary Licenses, and the Covered Software is not Incompatible
            With Secondary Licenses, this License permits You to additionally
            distribute such Covered Software under the terms of such Secondary
            License(s), so that the recipient of the Larger Work may, at their
            option, further distribute the Covered Software under the terms of
            either this License or such Secondary License(s).
            <br />
            3.4. Notices
            <br />
            You may not remove or alter the substance of any license notices
            (including copyright notices, patent notices, disclaimers of
            warranty, or limitations of liability) contained within the Source
            Code Form of the Covered Software, except that You may alter any
            license notices to the extent required to remedy known factual
            inaccuracies.
            <br />
            3.5. Application of Additional Terms
            <br />
            You may choose to offer, and to charge a fee for, warranty, support,
            indemnity or liability obligations to one or more recipients of
            Covered Software. However, You may do so only on Your own behalf,
            and not on behalf of any Contributor. You must make it absolutely
            clear that any such warranty, support, indemnity, or liability
            obligation is offered by You alone, and You hereby agree to
            indemnify every Contributor for any liability incurred by such
            Contributor as a result of warranty, support, indemnity or liability
            terms You offer. You may include additional disclaimers of warranty
            and limitations of liability specific to any jurisdiction.
            <br />
            4. Inability to Comply Due to Statute or Regulation
            <br />
            If it is impossible for You to comply with any of the terms of this
            License with respect to some or all of the Covered Software due to
            statute, judicial order, or regulation then You must: (a) comply
            with the terms of this License to the maximum extent possible; and
            (b) describe the limitations and the code they affect. Such
            description must be placed in a text file included with all
            distributions of the Covered Software under this License. Except to
            the extent prohibited by statute or regulation, such description
            must be sufficiently detailed for a recipient of ordinary skill to
            be able to understand it.
            <br />
            5. Termination
            <br />
            5.1. The rights granted under this License will terminate
            automatically if You fail to comply with any of its terms. However,
            if You become compliant, then the rights granted under this License
            from a particular Contributor are reinstated (a) provisionally,
            unless and until such Contributor explicitly and finally terminates
            Your grants, and (b) on an ongoing basis, if such Contributor fails
            to notify You of the non-compliance by some reasonable means prior
            to 60 days after You have come back into compliance. Moreover, Your
            grants from a particular Contributor are reinstated on an ongoing
            basis if such Contributor notifies You of the non-compliance by some
            reasonable means, this is the first time You have received notice of
            non-compliance with this License from such Contributor, and You
            become compliant prior to 30 days after Your receipt of the notice.
            <br />
            5.2. If You initiate litigation against any entity by asserting a
            patent infringement claim (excluding declaratory judgment actions,
            counter-claims, and cross-claims) alleging that a Contributor
            Version directly or indirectly infringes any patent, then the rights
            granted to You by any and all Contributors for the Covered Software
            under Section 2.1 of this License shall terminate.
            <br />
            5.3. In the event of termination under Sections 5.1 or 5.2 above,
            all end user license agreements (excluding distributors and
            resellers) which have been validly granted by You or Your
            distributors under this License prior to termination shall survive
            termination.
            <br />
            6. Disclaimer of Warranty
            <br />
            Covered Software is provided under this License on an &ldquo;as
            is&rdquo; basis, without warranty of any kind, either expressed,
            implied, or statutory, including, without limitation, warranties
            that the Covered Software is free of defects, merchantable, fit for
            a particular purpose or non-infringing. The entire risk as to the
            quality and performance of the Covered Software is with You. Should
            any Covered Software prove defective in any respect, You (not any
            Contributor) assume the cost of any necessary servicing, repair, or
            correction. This disclaimer of warranty constitutes an essential
            part of this License. No use of any Covered Software is authorized
            under this License except under this disclaimer.
            <br />
            7. Limitation of Liability
            <br />
            Under no circumstances and under no legal theory, whether tort
            (including negligence), contract, or otherwise, shall any
            Contributor, or anyone who distributes Covered Software as permitted
            above, be liable to You for any direct, indirect, special,
            incidental, or consequential damages of any character including,
            without limitation, damages for lost profits, loss of goodwill, work
            stoppage, computer failure or malfunction, or any and all other
            commercial damages or losses, even if such party shall have been
            informed of the possibility of such damages. This limitation of
            liability shall not apply to liability for death or personal injury
            resulting from such party&rsquo;s negligence to the extent
            applicable law prohibits such limitation. Some jurisdictions do not
            allow the exclusion or limitation of incidental or consequential
            damages, so this exclusion and limitation may not apply to You.
            <br />
            8. Litigation
            <br />
            Any litigation relating to this License may be brought only in the
            courts of a jurisdiction where the defendant maintains its principal
            place of business and such litigation shall be governed by laws of
            that jurisdiction, without reference to its conflict-of-law
            provisions. Nothing in this Section shall prevent a party&rsquo;s
            ability to bring cross-claims or counter-claims.
            <br />
            9. Miscellaneous
            <br />
            This License represents the complete agreement concerning the
            subject matter hereof. If any provision of this License is held to
            be unenforceable, such provision shall be reformed only to the
            extent necessary to make it enforceable. Any law or regulation which
            provides that the language of a contract shall be construed against
            the drafter shall not be used to construe this License against a
            Contributor.
            <br />
            10. Versions of the License
            <br />
            10.1. New Versions
            <br />
            Mozilla Foundation is the license steward. Except as provided in
            Section 10.3, no one other than the license steward has the right to
            modify or publish new versions of this License. Each version will be
            given a distinguishing version number.
            <br />
            10.2. Effect of New Versions
            <br />
            You may distribute the Covered Software under the terms of the
            version of the License under which You originally received the
            Covered Software, or under the terms of any subsequent version
            published by the license steward.
            <br />
            10.3. Modified Versions
            <br />
            If you create software not governed by this License, and you want to
            create a new license for such software, you may create and use a
            modified version of this License if you rename the license and
            remove any references to the name of the license steward (except to
            note that such modified license differs from this License).
            <br />
            10.4. Distributing Source Code Form that is Incompatible With
            Secondary Licenses
            <br />
            If You choose to distribute Source Code Form that is Incompatible
            With Secondary Licenses under the terms of this version of the
            License, the notice described in Exhibit B of this License must be
            attached.
            <br />
            Exhibit A &ndash; Source Code Form License Notice
            <br />
            This Source Code Form is subject to the terms of the Mozilla Public
            License, v. 2.0. If a copy of the MPL was not distributed with this
            file, You can obtain one at https://mozilla.org/MPL/2.0/.
            <br />
            If it is not possible or desirable to put the notice in a particular
            file, then You may include the notice in a location (such as a
            LICENSE file in a relevant directory) where a recipient would be
            likely to look for such a notice.
            <br />
            You may add additional accurate notices of copyright ownership.
            <br />
            Exhibit B &ndash; &ldquo;Incompatible With Secondary Licenses&rdquo;
            Notice
            <br />
            This Source Code Form is &ldquo;Incompatible With Secondary
            Licenses&rdquo;, as defined by the Mozilla Public License, v. 2.0.
          </p>
        </>
      );
    }
    case "10": {
      return (
        <>
          <p>
            Note: This license has also been called the &ldquo;Simplified BSD
            License&rdquo; and the &ldquo;FreeBSD License&rdquo;. See also the
            3-clause BSD License.
            <br />
            Copyright [YEAR] [COPYRIGHT HOLDER]
            <br />
            Redistribution and use in source and binary forms, with or without
            modification, are permitted provided that the following conditions
            are met:
            <br />
            1. Redistributions of source code must retain the above copyright
            notice, this list of conditions and the following disclaimer.
            <br />
            2. Redistributions in binary form must reproduce the above copyright
            notice, this list of conditions and the following disclaimer in the
            documentation and/or other materials provided with the distribution.
            <br />
            THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
            &ldquo;AS IS&rdquo; AND ANY EXPRESS OR IMPLIED WARRANTIES,
            INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED.
            IN NO EVENT SHALL THE COPYRIGHT HOLDER OR CONTRIBUTORS BE LIABLE FOR
            ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
            CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF
            SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR
            BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF
            LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
            NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS
            SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
          </p>
        </>
      );
    }
    case "11": {
      return (
        <>
          <p>
            Note: This license has also been called the &ldquo;New BSD
            License&rdquo; or &ldquo;Modified BSD License&rdquo;. See also the
            2-clause BSD License.
            <br />
            Copyright [YEAR] [COPYRIGHT HOLDER]
            <br />
            Redistribution and use in source and binary forms, with or without
            modification, are permitted provided that the following conditions
            are met:
            <br />
            1. Redistributions of source code must retain the above copyright
            notice, this list of conditions and the following disclaimer.
            <br />
            2. Redistributions in binary form must reproduce the above copyright
            notice, this list of conditions and the following disclaimer in the
            documentation and/or other materials provided with the distribution.
            <br />
            3. Neither the name of the copyright holder nor the names of its
            contributors may be used to endorse or promote products derived from
            this software without specific prior written permission.
            <br />
            THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
            &ldquo;AS IS&rdquo; AND ANY EXPRESS OR IMPLIED WARRANTIES,
            INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED.
            IN NO EVENT SHALL THE COPYRIGHT HOLDER OR CONTRIBUTORS BE LIABLE FOR
            ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
            CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF
            SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR
            BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF
            LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
            NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS
            SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
          </p>
        </>
      );
    }
    case "12": {
      return (
        <>
          <p>
            Copyright [YEAR] [COPYRIGHT HOLDER]
            <br />
            Permission is hereby granted, free of charge, to any person
            obtaining a copy of this software and associated documentation files
            (the &ldquo;Software&rdquo;), to deal in the Software without
            restriction, including without limitation the rights to use, copy,
            modify, merge, publish, distribute, sublicense, and/or sell copies
            of the Software, and to permit persons to whom the Software is
            furnished to do so, subject to the following conditions:
            <br />
            The above copyright notice and this permission notice shall be
            included in all copies or substantial portions of the Software.
            <br />
            THE SOFTWARE IS PROVIDED &ldquo;AS IS&rdquo;, WITHOUT WARRANTY OF
            ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS
            BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN
            ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN
            CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE
            SOFTWARE.
          </p>
        </>
      );
    }
    default: {
      return (
        <>
          <p>Please provide details on your license.</p>
        </>
      );
    }
  }
};

export default getLicense;