import {
  UPDATE_MODEL_SUCCESS,
  DELETE_MODEL_SUCCESS,
  GET_MODEL_SUCCESS,
  GET_MODEL_FAIL,
  GET_MODEL_VERSION_SUCCESS,
  GET_MODEL_VERSION_FAIL,
  GET_ALL_MODELS_SUCCESS,
  GET_ALL_MODELS_FAIL,
  SET_MESSAGE,
  GET_ALL_MODELS_VERSIONS_SUCCESS,
  GET_ALL_MODELS_VERSIONS_FAIL,
  GET_ALL_ACCURACY_FAIL,
  GET_ALL_ACCURACY_SUCCESS,
  SUBSCRIBE_MODEL_SUCCESS,
  GET_ALL_SUBSCRIBE_MODELS_SUCCESS,
  GET_GROUP_MEMBERS_SUCCESS,
  GET_LIKES_SUCCESS,
  ADD_DOWNLOADS_SUCCESS,
  GET_SUBSCRIBE_MODEL_SUCCESS,
} from "./types";
import ModelService from "services/model.service";

/**
 * A module that sends success and error response action for model-related methods
 * @module actions/model
 */

/**
 * Add model information
 * success and error response action
 *
 *
 * @method addModel
 *
 * @param {string} modelName - A string for the model name
 * @param {string} modelTask - A string for the model task
 * @param {string} modelDescription - A string for the model description
 * @param {string} modelGroup - An object to list down group of the model
 *
 * @return {Promise}
 *
 */
export const addModel =
  (
    modelName,
    modelTask,
    modelDescription,
    modelGroup,
    modelPrimaryIntendedUses,
    modelPrimaryIntendedUsers,
    modelOutOfScopeUseCases,
    modelGroups,
    modelInstrumentation,
    modelEnvironment,
    modelPerformanceMeasures,
    modelDecisionThresholds,
    modelApproachesToUncertainty,
    modelData,
    modelHumanLife,
    modelMitigations,
    modelRisksAndHarms,
    modelUseCases
  ) =>
  (dispatch) => {
    return ModelService.addModel(
      modelName,
      modelTask,
      modelDescription,
      modelGroup,
      modelPrimaryIntendedUses,
      modelPrimaryIntendedUsers,
      modelOutOfScopeUseCases,
      modelGroups,
      modelInstrumentation,
      modelEnvironment,
      modelPerformanceMeasures,
      modelDecisionThresholds,
      modelApproachesToUncertainty,
      modelData,
      modelHumanLife,
      modelMitigations,
      modelRisksAndHarms,
      modelUseCases
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Model profile created.",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            (error.response.data.message ||
              (error.response.data?.model_profile_name &&
                error.response.data?.model_profile_name[0]) ||
              (error.response.data?.model_profile_task &&
                error.response.data?.model_profile_task[0]) ||
              (error.response.data?.model_profile_description &&
                error.response.data?.model_profile_description[0]) ||
              (error.response.data?.model_profile_group &&
                error.response.data?.model_profile_group[0]))) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject("error");
      }
    );
  };

/**
 * Edit model information
 * success and error response action
 *
 *
 * @method editModel
 *
 * @param {string} modelName - A string for the model name
 * @param {string} modelTask - A string for the model task
 * @param {string} modelDescription - A string for the model description
 * @param {string} modelGroup - An object to list down group of the model
 *
 * @return {Promise}
 *
 */
export const editModel =
  (
    modelID,
    modelName,
    modelTask,
    modelDescription,
    modelGroup,
    modelPrimaryIntendedUses,
    modelPrimaryIntendedUsers,
    modelOutOfScopeUseCases,
    modelGroups,
    modelInstrumentation,
    modelEnvironment,
    modelPerformanceMeasures,
    modelDecisionThresholds,
    modelApproachesToUncertainty,
    modelData,
    modelHumanLife,
    modelMitigations,
    modelRisksAndHarms,
    modelUseCases
  ) =>
  (dispatch) => {
    return ModelService.editModel(
      modelID,
      modelName,
      modelTask,
      modelDescription,
      modelGroup,
      modelPrimaryIntendedUses,
      modelPrimaryIntendedUsers,
      modelOutOfScopeUseCases,
      modelGroups,
      modelInstrumentation,
      modelEnvironment,
      modelPerformanceMeasures,
      modelDecisionThresholds,
      modelApproachesToUncertainty,
      modelData,
      modelHumanLife,
      modelMitigations,
      modelRisksAndHarms,
      modelUseCases
    ).then(
      (data) => {
        if (!data?.error) {
          dispatch({
            type: UPDATE_MODEL_SUCCESS,
            payload: data,
          });
        }

        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Model profile updated.",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          error.response.statusText ||
          error.response.data ||
          error.message ||
          error.toString();

        const status = error.response.data?.toString().includes("6379")
          ? "notif-in-app-network unreachable"
          : "error";

        dispatch({
          type: SET_MESSAGE,
          payload:
            status === "notif-in-app-network unreachable"
              ? "Model profile updated."
              : message,
        });

        return Promise.reject(status);
      }
    );
  };

export const editModelVersion =
  (
    modelFileID,
    modelProfileID,
    modelNumber,
    modelVersionName,
    modelVersionDescription,
    modelContact,
    modelDevelopmentDate,
    modelDevelopers,
    modelAccuracy,
    modelInput,
    modelOutput,
    modelType,
    modelPaper,
    modelCitationDetails,
    modelLicense,
    modelLicenseContent,
    modelOtherRelevantInfo,
    modelTrainingDataset,
    modelTrainingMotivation,
    modelTrainingPreprocessing,
    modelEvaluationDataset,
    modelEvaluationMotivation,
    modelEvaluationPreprocessing,
    modelUnitaryResults,
    modelIntersectionalResults,
    modelDetails
  ) =>
  (dispatch) => {
    return ModelService.editModelVersion(
      modelFileID,
      modelProfileID,
      modelNumber,
      modelVersionName,
      modelVersionDescription,
      modelContact,
      modelDevelopmentDate,
      modelDevelopers,
      modelAccuracy,
      modelInput,
      modelOutput,
      modelType,
      modelPaper,
      modelCitationDetails,
      modelLicense,
      modelLicenseContent,
      modelOtherRelevantInfo,
      modelTrainingDataset,
      modelTrainingMotivation,
      modelTrainingPreprocessing,
      modelEvaluationDataset,
      modelEvaluationMotivation,
      modelEvaluationPreprocessing,
      modelUnitaryResults,
      modelIntersectionalResults,
      modelDetails
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Version updated.",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          error.response.statusText ||
          error.response.data ||
          error.message ||
          error.toString();

        const status = error.response.data?.toString().includes("6379")
          ? "notif-in-app-network unreachable"
          : "error";

        dispatch({
          type: SET_MESSAGE,
          payload:
            status === "notif-in-app-network unreachable"
              ? "Version saved as draft."
              : message,
        });

        return Promise.reject(status);
      }
    );
  };

export const addModelVersionSaveDraft =
  (
    modelFileID,
    modelProfileID,
    modelNumber,
    modelVersionName,
    modelVersionDescription,
    modelContact,
    modelDevelopmentDate,
    modelDevelopers,
    modelAccuracy,
    modelInput,
    modelOutput,
    modelType,
    modelPaper,
    modelCitationDetails,
    modelLicense,
    modelLicenseContent,
    modelOtherRelevantInfo,
    modelTrainingDataset,
    modelTrainingMotivation,
    modelTrainingPreprocessing,
    modelEvaluationDataset,
    modelEvaluationMotivation,
    modelEvaluationPreprocessing,
    modelUnitaryResults,
    modelIntersectionalResults,
    modelDetails
  ) =>
  (dispatch) => {
    return ModelService.addModelVersionSaveDraft(
      modelFileID,
      modelProfileID,
      modelNumber,
      modelVersionName,
      modelVersionDescription,
      modelContact,
      modelDevelopmentDate,
      modelDevelopers,
      modelAccuracy,
      modelInput,
      modelOutput,
      modelType,
      modelPaper,
      modelCitationDetails,
      modelLicense,
      modelLicenseContent,
      modelOtherRelevantInfo,
      modelTrainingDataset,
      modelTrainingMotivation,
      modelTrainingPreprocessing,
      modelEvaluationDataset,
      modelEvaluationMotivation,
      modelEvaluationPreprocessing,
      modelUnitaryResults,
      modelIntersectionalResults,
      modelDetails
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Version saved as draft.",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          error.response.statusText ||
          error.response.data ||
          error.message ||
          error.toString();

        const status = error.response.data?.toString().includes("6379")
          ? "notif-in-app-network unreachable"
          : "error";

        dispatch({
          type: SET_MESSAGE,
          payload:
            status === "notif-in-app-network unreachable"
              ? "Version saved as draft."
              : message,
        });

        return Promise.reject(status);
      }
    );
  };

export const addModelVersionSaveAndPublish =
  (
    modelFileID,
    modelProfileID,
    modelNumber,
    modelVersionName,
    modelVersionDescription,
    modelContact,
    modelDevelopmentDate,
    modelDevelopers,
    modelAccuracy,
    modelInput,
    modelOutput,
    modelType,
    modelPaper,
    modelCitationDetails,
    modelLicense,
    modelLicenseContent,
    modelOtherRelevantInfo,
    modelTrainingDataset,
    modelTrainingMotivation,
    modelTrainingPreprocessing,
    modelEvaluationDataset,
    modelEvaluationMotivation,
    modelEvaluationPreprocessing,
    modelUnitaryResults,
    modelIntersectionalResults,
    modelDetails
  ) =>
  (dispatch) => {
    return ModelService.addModelVersionSaveAndPublish(
      modelFileID,
      modelProfileID,
      modelNumber,
      modelVersionName,
      modelVersionDescription,
      modelContact,
      modelDevelopmentDate,
      modelDevelopers,
      modelAccuracy,
      modelInput,
      modelOutput,
      modelType,
      modelPaper,
      modelCitationDetails,
      modelLicense,
      modelLicenseContent,
      modelOtherRelevantInfo,
      modelTrainingDataset,
      modelTrainingMotivation,
      modelTrainingPreprocessing,
      modelEvaluationDataset,
      modelEvaluationMotivation,
      modelEvaluationPreprocessing,
      modelUnitaryResults,
      modelIntersectionalResults,
      modelDetails
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Version is now published.",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          error.response.statusText ||
          error.response.data ||
          error.message ||
          error.toString();

        const status = error.response.data?.toString().includes("6379")
          ? "notif-in-app-network unreachable"
          : "error";

        dispatch({
          type: SET_MESSAGE,
          payload:
            status === "notif-in-app-network unreachable"
              ? "Version is now published."
              : message,
        });

        return Promise.reject(status);
      }
    );
  };

export const uploadModelFile =
  (modelProfileID, file, onUploadProgress, cancelToken) => (dispatch) => {
    return ModelService.uploadModelFile(
      modelProfileID,
      file,
      onUploadProgress,
      cancelToken
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error,
        });

        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const editUploadModelFile =
  (modelFileID, file, onUploadProgress, cancelToken) => (dispatch) => {
    return ModelService.editUploadModelFile(
      modelFileID,
      file,
      onUploadProgress,
      cancelToken
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error,
        });

        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const publishModelVersion = (modelFileID) => (dispatch) => {
  return ModelService.publishModelVersion(modelFileID).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data?.error || "Version is now published",
      });

      return Promise.resolve(data?.error ? "error" : "success");
    },
    (error) => {
      const message =
        error.response.statusText ||
        error.response.data ||
        error.message ||
        error.toString();

      const status = error.response.data?.toString().includes("6379")
        ? "notif-in-app-network unreachable"
        : "error";

      dispatch({
        type: SET_MESSAGE,
        payload:
          status === "notif-in-app-network unreachable"
            ? "Version is now published."
            : message,
      });

      return Promise.reject(status);
    }
  );
};

export const archiveModelVersion =
  (modelFileID, archiveReason) => (dispatch) => {
    return ModelService.archiveModelVersion(modelFileID, archiveReason).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Version archived",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          error.response.statusText ||
          error.response.data ||
          error.message ||
          error.toString();

        const status = error.response.data?.toString().includes("6379")
          ? "notif-in-app-network unreachable"
          : "error";

        dispatch({
          type: SET_MESSAGE,
          payload:
            status === "notif-in-app-network unreachable"
              ? "Version archived"
              : message,
        });

        return Promise.reject(status);
      }
    );
  };

export const deleteModelVersion = (modelFileID) => (dispatch) => {
  return ModelService.deleteModelVersion(modelFileID).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data?.error || "Version deleted",
      });

      return Promise.resolve(data?.error ? "error" : "success");
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject("error");
    }
  );
};

export const duplicateModelVersion =
  (modelProfileID, modelFileID) => (dispatch) => {
    return ModelService.duplicateModelVersion(modelProfileID, modelFileID).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error || "Version duplicated",
        });

        return Promise.resolve(data?.error ? "error" : "success");
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject("error");
      }
    );
  };

/**
 * Delete model information and model file path
 * success and error response action
 *
 *
 * @method deleteModel
 *
 * @param {string} id - A string for identifying model
 *
 * @return {Promise}
 *
 */
export const deleteModel = (id) => (dispatch) => {
  return ModelService.deleteModel(id).then(
    (data) => {
      dispatch({
        type: DELETE_MODEL_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: data?.error || "Model profile deleted.",
      });

      return Promise.resolve(data?.error ? "error" : "success");
    },
    (error) => {
      const message =
        error.response.statusText ||
        error.response.data ||
        error.message ||
        error.toString();

      const status = error.response.data?.toString().includes("6379")
        ? "notif-in-app-network unreachable"
        : "error";

      dispatch({
        type: SET_MESSAGE,
        payload:
          status === "notif-in-app-network unreachable"
            ? "Model profile deleted."
            : message,
      });

      return Promise.reject(status);
    }
  );
};

/**
 * Get model information
 * success and error response action
 *
 *
 * @method getModel
 *
 * @param {string} id - A string for identifying model
 *
 * @return {Promise}
 *
 */
export const getModel = (id) => (dispatch) => {
  return ModelService.getModel(id).then(
    (data) => {
      dispatch({
        type: GET_MODEL_SUCCESS,
        payload: data,
      });

      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages[0].message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_MODEL_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject("error");
    }
  );
};

/**
 * Get model version information
 * success and error response action
 *
 *
 * @method getModelVersion
 *
 * @param {string} id - A string for identifying model
 *
 * @return {Promise}
 *
 */
export const getModelVersion = (id) => (dispatch) => {
  return ModelService.getModelVersion(id).then(
    (data) => {
      dispatch({
        type: GET_MODEL_VERSION_SUCCESS,
        payload: data,
      });

      return Promise.resolve(data);
    },
    (error) => {
      const message =
        error.response.statusText ||
        error.response.data ||
        error.message ||
        error.toString();

      const status =
        error.response.data?.toString().includes("DoesNotExist") ||
        error.response.statusText.includes("Not Found")
          ? "not found"
          : "error";

      dispatch({
        type: GET_MODEL_VERSION_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(status);
    }
  );
};

/**
 * Get all models information
 * success and error response action
 *
 *
 * @method getAllModels
 *
 * @return {Promise}
 *
 */
export const getAllModels = () => (dispatch) => {
  return ModelService.getAllModels().then(
    (data) => {
      dispatch({
        type: GET_ALL_MODELS_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ALL_MODELS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject("error");
    }
  );
};

/**
 * Get all model versions information
 * success and error response action
 *
 *
 * @method getAllModelVersions
 *
 * @return {Promise}
 *
 */
export const getAllModelVersions = (modelProfileID) => (dispatch) => {
  return ModelService.getAllModelVersions(modelProfileID).then(
    (data) => {
      dispatch({
        type: GET_ALL_MODELS_VERSIONS_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ALL_MODELS_VERSIONS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

/**
 * Get all accuracy information
 * success and error response action
 *
 *
 * @method getAllModelVersions
 *
 * @return {Promise}
 *
 */
export const getAllAccuracy = (modelProfileID) => (dispatch) => {
  return ModelService.getAllAccuracy(modelProfileID).then(
    (data) => {
      dispatch({
        type: GET_ALL_ACCURACY_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ALL_ACCURACY_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

/**
 * Download model version information
 * success and error response action
 *
 *
 * @method downloadModelVersion
 *
 * @param {string} id - A string for identifying model
 *
 * @return {Promise}
 *
 */
export const downloadModelVersion =
  (modelFileID, onDownloadProgress, cancelToken) => (dispatch) => {
    return ModelService.downloadModelVersion(
      modelFileID,
      onDownloadProgress,
      cancelToken
    ).then(
      (data) => {
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages[0].message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

/**
 * Edit subscribed to the model profile
 * success and error response action
 *
 *
 * @method editSubscribeModelProfile
 *
 * @param {string} id - A string for identifying model
 *
 * @return {Promise}
 *
 */
export const editSubscribeModelProfile = (modelProfileID) => (dispatch) => {
  return ModelService.editSubscribeModelProfile(modelProfileID).then(
    (data) => {
      dispatch({
        type: SUBSCRIBE_MODEL_SUCCESS,
        payload: data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload:
          data?.error || data?.subscribe_status
            ? "Subscription added."
            : "Subscription removed.",
      });

      return Promise.resolve(data?.error ? "error" : "success");
    },
    (error) => {
      const message =
        error.response.statusText ||
        error.response.data ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject("error");
    }
  );
};

/**
 * Get all subscribed models information
 * success and error response action
 *
 *
 * @method getAllSubscribeModelProfile
 *
 * @return {Promise}
 *
 */
export const getAllSubscribeModelProfile = () => (dispatch) => {
  return ModelService.getAllSubscribeModelProfile().then(
    (data) => {
      dispatch({
        type: GET_ALL_SUBSCRIBE_MODELS_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getSubscribeModelProfile = (modelProfileID) => (dispatch) => {
  return ModelService.getSubscribeModelProfile(modelProfileID).then(
    (data) => {
      dispatch({
        type: GET_SUBSCRIBE_MODEL_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getGroupMembers = (id) => (dispatch) => {
  return ModelService.getGroupMembers(id).then(
    (data) => {
      dispatch({
        type: GET_GROUP_MEMBERS_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const addDownloads = (modelFileID) => (dispatch) => {
  return ModelService.addDownloads(modelFileID).then(
    (data) => {
      dispatch({
        type: ADD_DOWNLOADS_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getLikes = (modelFileID) => (dispatch) => {
  return ModelService.getLikes(modelFileID).then(
    (data) => {
      dispatch({
        type: GET_LIKES_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const inferenceChangeDetectionModel =
  (file1, file2, modelFile, weightFile, cancelToken) => (dispatch) => {
    return ModelService.inferenceChangeDetectionModel(
      file1,
      file2,
      modelFile,
      weightFile,
      cancelToken
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error,
        });

        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            (error.response.data.message ||
              (error.response.data?.before_image &&
                error.response.data?.before_image[0]) ||
              (error.response.data?.after_image &&
                error.response.data?.after_image[0]))) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject(message);
      }
    );
  };

export const inferenceObjectDetectionModel =
  (
    inputFile,
    modelWeightsFile,
    confidence,
    iou,
    imageSize,
    coordinates,
    cancelToken
  ) =>
  (dispatch) => {
    return ModelService.inferenceObjectDetectionModel(
      inputFile,
      modelWeightsFile,
      confidence,
      iou,
      imageSize,
      coordinates,
      cancelToken
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error,
        });

        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject(message);
      }
    );
  };

export const inferenceBuildingDelineationModel =
  (originalImage, modelWeight, isGrayscale, contourSize, rgb, cancelToken) =>
  (dispatch) => {
    return ModelService.inferenceBuildingDelineationModel(
      originalImage,
      modelWeight,
      isGrayscale,
      contourSize,
      rgb,
      cancelToken
    ).then(
      (data) => {
        dispatch({
          type: SET_MESSAGE,
          payload: data?.error,
        });

        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject(message);
      }
    );
  };
