import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Tab,
  Breadcrumb,
  Tabs,
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Offcanvas,
  Spinner,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "actions/group";
import {
  getAllDatasetVersions,
  getAllKeywords,
  getDataset,
  subscribeDatasetProfile,
  unsubscribeDatasetProfile,
} from "actions/dataset";
import { convertDate } from "common/constants";
import { GET_DATASET_FAIL } from "actions/types";
import EditDatasetProfileModal from "./EditDatasetProfileModal";
import DeleteDatasetModal from "./DeleteDatasetModal";
import ViewDatasetCreatorModal from "./ViewDatasetCreatorModal";
import FilterDropdown from "components/layout/FilterDropdown";
import { getCurrentUser } from "actions/auth";
import SaveAndPublishModal from "components/common-modals/SaveAndPublishModal";
import DeleteVersionModal from "components/common-modals/DeleteVersionModal";
import ArchiveModal from "components/datasets/ArchiveModal";
import { useMediaQuery } from "react-responsive";
import MobileLeftSidebar from "components/layout/MobileLeftSidebar";
import "styles/DatasetProfile.css";
import "styles/mobile/DatasetProfileMobile.css";
import CustomPagination from "components/layout/CustomPagination";

/**
 * A module for showing Dataset profile component
 * @module components/datasets/DatasetProfile
 */

const DatasetProfile = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const params = useParams();
  const id = params.id;
  const datasetProfileID = params.id;
  const searchParams = new URLSearchParams(location.search);
  const scrollRef = useRef();

  const tab = searchParams.get("tab");
  /**
   * -------------------
   * * Redux store state
   * -------------------
   */
  const { currentDataset, datasetVersions, currentSubscribedDataset } =
    useSelector((state) => state.dataset);
  const { users } = useSelector((state) => state.group);
  const { user, currentUser } = useSelector((state) => state.auth);

  const [datasetTitle, setDatasetTitle] = useState("");
  const [datasetDescription, setDatasetDescription] = useState("");
  const [datasetCreatedBy, setDatasetCreatedBy] = useState("");
  const [datasetDateCreated, setDatasetDateCreated] = useState("");

  const [filterText, setFilterText] = useState("");
  const [refreshCurrentDatasetCount, setRefreshCurrentDatasetCount] =
    useState(0);
  const [showDeleteDatasetModal, setShowDeleteDatasetModal] = useState(false);
  const [showEditDatasetModal, setShowEditDatasetModal] = useState(false);
  const [showViewDatasetCreatorModal, setShowViewDatasetCreatorModal] =
    useState(false);
  const [refreshSubscribe, setRefreshSubscribe] = useState(0);
  const [subscribed, setSubscribed] = useState(false);
  const [subscribedCount, setSubscribedCount] = useState(0);

  const [userAllow, setUserAllow] = useState(false);

  const [sortDirectionStatus, setSortDirectionStatus] = useState("asc");
  const [sortDirectionDateCreated, setSortDirectionDateCreated] =
    useState("desc");
  const [sortColumn, setSortColumn] = useState("Date created");

  const [selectedOptionsKeywords, setSelectedOptionsKeywords] = useState([]);
  const [selectedOptionsStatuses, setSelectedOptionsStatuses] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [data, setData] = useState([]); // your data array
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredKeywords, setFilteredKeywords] = useState([]);

  const [sortDirection, setSortDirection] = useState("desc");
  const [expandedDateCreated, setExpandedDateCreated] = useState(false);
  const [expandedStatus, setExpandedStatus] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [expandedVersionID, setExpandedVersionID] = useState("");
  const [showSaveAndPublishModal, setShowSaveAndPublishModal] = useState(false);
  const [showDeleteVersionModal, setShowDeleteVersionModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [currentRowID, setCurrentRowID] = useState("");
  const [loading, setLoading] = useState(false);
  const [expandedDatasetProfileBtnMobile, setExpandedDatasetProfileBtnMobile] =
    useState(false);

  const handleDeleteDatasetModalClose = () => {
    setShowDeleteDatasetModal(false);
  };

  const handleEditDatasetModalClose = () => {
    setShowEditDatasetModal(false);
  };

  const handleViewDatasetCreatorModalClose = () => {
    setShowViewDatasetCreatorModal(false);
  };

  useEffect(() => {
    props.setPreload(true);
    dispatch(getDataset(id))
      .then((res) => {
        res.length === 0 && navigate("/404");
      })
      .catch((status) => {
        if (status === "not found") {
          navigate("/404");
        } else {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        }
      })
      .finally(() => props.setPreload(false));
    dispatch(getAllKeywords(id))
      .then((res) => {
        const keywords = res.map((keyword) => {
          return keyword.name;
        });
        setFilteredKeywords(keywords);
      })
      .catch((err) => console.log(err));
  }, [id, refreshCurrentDatasetCount]);

  useEffect(() => {
    const ordering =
      sortColumn === "Date created"
        ? sortDirectionDateCreated === "asc"
          ? "created_on"
          : "-created_on"
        : sortDirectionStatus === "asc"
        ? "status"
        : "-status";

    const keywords = selectedOptionsKeywords
      .map((keyword) => keyword.trim())
      .toString();
    const statuses = selectedOptionsStatuses.toString().toUpperCase();
    const filterParams = `?ordering=${ordering}&keywords__name__in=${keywords}&status__in=${statuses}&search=${filterText}`;
    dispatch(getAllDatasetVersions(id, filterParams)).catch((error) =>
      console.log(error)
    );
  }, [
    id,
    props?.refreshDatasetVersionsCount,
    sortDirectionStatus,
    sortDirectionDateCreated,
    sortColumn,
    filterText,
    selectedOptionsKeywords,
    selectedOptionsStatuses,
  ]);

  useEffect(() => {
    if (
      currentDataset?.author?.email === user.email ||
      (datasetVersions?.results &&
        datasetVersions?.results[0]?.contributors?.find(
          (contributor) => contributor.email === user.email
        ))
    ) {
      setUserAllow(true);
    } else {
      setUserAllow(false);
    }
  }, [currentDataset, user, datasetVersions]);

  // useEffect(() => {
  //   dispatch(getSubscribeDatasetProfile(id))
  //     .catch((err) => console.log(err));
  // }, [id]);

  useEffect(() => {
    dispatch(getAllUsers()).catch((err) => console.log(err));
    dispatch(getCurrentUser()).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    resetValues();
    setDatasetTitle(currentDataset?.title);
    setDatasetDescription(currentDataset?.description);
    const dataset_user = Object.values(users).find(
      (user) => user.email === currentDataset?.author?.email
    );
    if (dataset_user) {
      setDatasetCreatedBy(
        dataset_user?.first_name + " " + dataset_user?.last_name
      );
    }

    if (currentDataset?.created_on) {
      const formattedDate = convertDate(
        currentDataset?.created_on,
        isMobile ? "MM DD, YYYY" : "MM/DD/YYYY"
      );
      setDatasetDateCreated(formattedDate);
    }

    const subscribe =
      currentUser?.dataset_profile_subscriptions?.length > 0 &&
      Object.values(currentUser?.dataset_profile_subscriptions).find(
        (i) => i === parseInt(id)
      );
    setSubscribed(subscribe);
    setSubscribedCount(currentDataset?.subscribers?.length || 0);
  }, [users, currentDataset, currentSubscribedDataset, currentUser]);

  const resetValues = () => {
    setSubscribed(false);
    setDatasetTitle("");
    setDatasetDescription("");
    setDatasetCreatedBy("");
    setDatasetDateCreated("");
  };

  useEffect(() => {
    dispatch({
      type: GET_DATASET_FAIL,
    });
    resetValues();
  }, []);

  const CustomDropdownToggle = React.forwardRef(
    ({ children, onClick }, ref) => {
      return (
        <span
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
        </span>
      );
    }
  );

  const handleToggleExpand = (column) => {
    switch (column) {
      case "status":
        setSortDirectionStatus(sortDirectionStatus === "asc" ? "desc" : "asc");
        setSortColumn("Status");
        break;
      case "date_created":
        setSortDirectionDateCreated(
          sortDirectionDateCreated === "asc" ? "desc" : "asc"
        );
        setSortColumn("Date created");
        break;
      default:
      //
    }
  };

  // Define your custom header component for Name column
  const CustomHeader = (props) => {
    const handleSortToggle = (direction) => {
      props?.setSortDirection(direction);
      props?.setSortColumn(props?.columnName);
    };

    return (
      <Dropdown
        show={props?.expanded}
        className="dropdown-version-list"
        drop="down"
      >
        <Dropdown.Toggle
          as={CustomDropdownToggle}
          variant="secondary"
          id="dropdown-basic"
        >
          {props?.columnName}
        </Dropdown.Toggle>

        <Dropdown.Menu
          className={
            props?.expanded ? "dropdown-menu slide-down" : "dropdown-menu"
          }
        >
          <Dropdown.Item onClick={() => handleSortToggle("asc")}>
            {props?.columnName === "Date created" ? "Older to newer" : "A to Z"}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleSortToggle("desc")}>
            {props?.columnName === "Date created" ? "Newer to older" : "Z to A"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (selectedItemsPerPage) => {
    setCurrentPage(1);
    setItemsPerPage(selectedItemsPerPage);
  };

  const handleToggleExpandStatus = (datasetVersionID) => {
    setExpanded(!expanded);
    setExpandedVersionID(datasetVersionID);
  };

  const onClickEdit = (datasetVersionID) => {
    navigate(
      "/datasets/profile/" +
        datasetProfileID +
        "/version/edit/" +
        datasetVersionID
    );
  };

  const onClickDeleteVersion = (datasetVersionID) => {
    props?.deleteDatasetVersion(datasetProfileID, datasetVersionID);
    setShowDeleteVersionModal(false);
  };

  const onClickSave = (isPublish, datasetVersionID) => {
    if (isPublish) {
      props?.saveAndPublish(datasetProfileID, datasetVersionID);
      setShowSaveAndPublishModal(false);
    }
  };

  const onClickDuplicateDatasetVersion = (datasetVersionID) => {
    props?.duplicateDatasetVersion(datasetProfileID, datasetVersionID);
  };

  const handleSaveAndPublishModalClose = () => {
    setShowSaveAndPublishModal(false);
  };

  const handleArchiveModalClose = () => {
    setShowArchiveModal(false);
  };

  const handleDeleteVersionModalClose = () => {
    setShowDeleteVersionModal(false);
  };

  useEffect(() => {
    dispatch(getAllKeywords(id))
      .then((res) => {
        const keywords = res.map((keyword) => {
          return keyword.name;
        });
        setFilteredKeywords(keywords);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const [clearFilter, setClearFilter] = useState(false);
  const handleClearFilterBtnClick = () => {
    setSelectedOptionsKeywords([]);
    setSelectedOptionsStatuses([]);
    setClearFilter(true);
  };

  const subHeaderComponentMemo = useMemo(() => {
    const options = !userAllow
      ? ["Published", "Archived"]
      : ["Draft", "Published", "Archived"];
    return (
      <>
        <div className="version-list-div w-100">
          <div className="search-input-container">
            <input
              type="text"
              placeholder="Search version"
              onChange={(e) => setFilterText(e.target.value)}
              className="search-input"
              name="search-dataset-version"
              id="search-dataset-version"
              data-cy="search-dataset-version"
            />
            <img
              src="/images/search-icon-gray.svg"
              className="search-icon img-fluid icon-list"
              alt="group"
            />
          </div>
          {currentDataset?.author?.email === user.email && (
            <Button
              className="add-version-btn button"
              onClick={() => handleAddVersionBtnClick()}
              name="add-dataset-version"
              id="add-dataset-version"
              data-cy="add-dataset-version"
            >
              Add version
            </Button>
          )}
          {!isMobile && (
            <>
              <FilterDropdown
                setSelectedOptions={setSelectedOptionsKeywords}
                clearFilter={clearFilter}
                setClearFilter={setClearFilter}
                filterTitle="Keywords"
                options={filteredKeywords}
                name="keywords"
                dropdownToggleID="filter-keyword"
              />
              <FilterDropdown
                setSelectedOptions={setSelectedOptionsStatuses}
                clearFilter={clearFilter}
                setClearFilter={setClearFilter}
                filterTitle="Status"
                options={options}
                name="status"
                dropdownToggleID="filter-statud"
              />
            </>
          )}
          {selectedOptionsKeywords.length + selectedOptionsStatuses.length >
            0 && (
            <Button
              className="ms-3 button"
              onClick={() => handleClearFilterBtnClick()}
              name="clear-filter"
              id="clear-filter"
              data-cy="clear-filter"
            >
              Clear filter
            </Button>
          )}
        </div>
      </>
    );
  }, [
    currentDataset?.author?.email,
    user,
    filteredKeywords,
    clearFilter,
    selectedOptionsKeywords.length,
    selectedOptionsStatuses.length,
  ]);

  /**
   * Columns template and configuration
   */
  const columns1 = [
    {
      name: <span className="th">No.</span>,
      selector: (row) => <div className="row-version-num">{row.version}</div>,
      width: "10%",
    },
    {
      name: <span className="th">Subtitle</span>,
      selector: (row) => (
        <div className="row-version-subtitle">{row.subtitle}</div>
      ),
      width: "25%",
    },
    {
      name: <span className="th">Keywords</span>,
      selector: (row) => (
        <div className="row-version-keywords">{row.keywords}</div>
      ),
      width: "15%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("date_created")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Date created</span>
          {sortColumn === "Date created" && (
            <img
              src={
                sortDirectionDateCreated === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      cell: (row) => {
        if (!isMobile) {
          return (
            <div className="row-version-date-created">{row.date_created}</div>
          );
        } else {
          return (
            <div onClick={() => handleRowClick(row)}>{row.contributors}</div>
          );
        }
      },
      width: "17%",
    },
    {
      name: <span className="th">Contributors</span>,
      selector: (row) => {
        if (isMobile) {
          return (
            <div className="row-version-date-created">{row.date_created}</div>
          );
        } else {
          return (
            <div onClick={() => handleRowClick(row)}>{row.contributors}</div>
          );
        }
      },
      width: "17%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("status")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Status</span>
          {sortColumn === "Status" && (
            <img
              src={
                sortDirectionStatus === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      selector: (row) => {
        let statusElement = "";
        switch (row.status) {
          case "Published": {
            statusElement = (
              <div className="labels">
                <div
                  className="m-0 p-1"
                  style={{ backgroundColor: "#99e6a1", color: "#2e2e2e" }}
                >
                  <span>
                    {row.status}
                    {userAllow && (
                      <img
                        src={
                          expandedVersionID === row.id && expanded
                            ? "/images/arrow-down-white.svg"
                            : "/images/arrow-down.svg"
                        }
                        className="arrow-down img-fluid"
                        alt="arrow down"
                      />
                    )}
                  </span>
                </div>
              </div>
            );
            break;
          }
          case "Draft": {
            statusElement = (
              <div className="labels">
                <div
                  className="m-0 p-1"
                  style={{ backgroundColor: "#35ceff", color: "#2e2e2e" }}
                >
                  <span>
                    {row.status}
                    <img
                      src={
                        expandedVersionID === row.id && expanded
                          ? "/images/arrow-down-white.svg"
                          : "/images/arrow-down.svg"
                      }
                      className="arrow-down img-fluid"
                      alt="arrow down"
                    />
                  </span>
                </div>
              </div>
            );
            break;
          }
          default: {
            statusElement = (
              <div className="labels">
                <div
                  className="m-0 p-1"
                  style={{ backgroundColor: "#2e2e2e", color: "#fff" }}
                >
                  <span>
                    {row.status}
                    {userAllow && (
                      <img
                        src={
                          expandedVersionID === row.id && expanded
                            ? "/images/arrow-down-white.svg"
                            : "/images/arrow-down.svg"
                        }
                        className="arrow-down img-fluid"
                        alt="arrow down"
                      />
                    )}
                  </span>
                </div>
              </div>
            );
            break;
          }
        }

        return (
          <>
            <Dropdown
              className="action-dropdown"
              show={
                !isMobile &&
                expandedVersionID === row.id &&
                row.status !== "Archived" &&
                expanded
                  ? true
                  : false
              }
              onToggle={() => {
                row.status !== "Archived" && userAllow
                  ? handleToggleExpandStatus(row.id)
                  : setExpanded(false);
              }}
            >
              <Dropdown.Toggle
                variant="secondary"
                className="three-dot-dropdown-table"
                id={`version-row-status-${row.id}`}
                data-cy="version-row-status"
              >
                {statusElement}
              </Dropdown.Toggle>
              <Dropdown.Menu className="action-dropdown-menu">
                {row.status !== "Published" && (
                  <>
                    {row.publishAllow && (
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentRowID(row.id);
                          setShowSaveAndPublishModal(true);
                        }}
                        id={`publish-dataset-version-${row.id}`}
                        data-cy="publish-dataset-version"
                      >
                        <img
                          src="/images/save_publish.svg"
                          className="img-fluid icon-list"
                          alt="save and publish"
                        />
                        Publish
                      </Dropdown.Item>
                    )}
                  </>
                )}
                {row.status === "Draft" && (
                  <>
                    <Dropdown.Item
                      onClick={() => onClickEdit(row.id)}
                      id={`edit-dataset-version-${row.id}`}
                      data-cy="edit-dataset-version"
                    >
                      <img
                        src="/images/edit.svg"
                        className="img-fluid icon-list"
                        alt="edit"
                      />
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        setCurrentRowID(row.id);
                        setShowDeleteVersionModal(true);
                      }}
                      id={`delete-dataset-version-${row.id}`}
                      data-cy="delete-dataset-version"
                    >
                      <img
                        src="/images/trash.svg"
                        className="img-fluid icon-list"
                        alt="discard"
                      />
                      Delete
                    </Dropdown.Item>
                  </>
                )}
                {row.status === "Published" && (
                  <>
                    <Dropdown.Item
                      onClick={() => onClickDuplicateDatasetVersion(row.id)}
                      id={`duplicate-dataset-version-${row.id}`}
                      data-cy="duplicate-dataset-version"
                    >
                      <img
                        src="/images/duplicate.svg"
                        className="img-fluid icon-list"
                        alt="duplicate"
                      />
                      Make a copy
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        setCurrentRowID(row.id);
                        setShowArchiveModal(true);
                      }}
                      id={`archive-dataset-version-${row.id}`}
                      data-cy="archive-dataset-version"
                    >
                      <img
                        src="/images/archive.svg"
                        className="img-fluid icon-list"
                        alt="archive"
                      />
                      Archive
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Offcanvas
              show={
                isMobile &&
                expandedVersionID === row.id &&
                row.status !== "Archived" &&
                expanded
                  ? true
                  : false
              }
              onHide={() => {
                row.status !== "Archived"
                  ? handleToggleExpandStatus(row.id)
                  : setExpanded(false);
              }}
              placement="bottom"
              className="offcanvas-choose-action"
            >
              <Offcanvas.Body>
                <p className="choose-action-title bold">
                  {row.version} {row.subtitle}
                </p>
                <p className="choose-action-subtitle">Choose an action</p>
                <ul className="choose-action-ul p-0">
                  {row.status !== "Published" && (
                    <>
                      {row.publishAllow && (
                        <li
                          onClick={() => {
                            setCurrentRowID(row.id);
                            setExpanded(false);
                            setShowSaveAndPublishModal(true);
                          }}
                          id={`publish-dataset-version-${row.id}`}
                          data-cy="publish-dataset-version"
                        >
                          <img
                            src="/images/save_publish.svg"
                            className="img-fluid icon-list"
                            alt="save and publish"
                          />
                          Publish
                        </li>
                      )}
                    </>
                  )}
                  {row.status === "Draft" && (
                    <>
                      <li
                        onClick={() => onClickEdit(row.id)}
                        id={`edit-dataset-version-${row.id}`}
                        data-cy="edit-dataset-version"
                      >
                        <img
                          src="/images/edit.svg"
                          className="img-fluid icon-list"
                          alt="edit"
                        />
                        Edit
                      </li>
                      <hr />
                      <li
                        onClick={() => {
                          setCurrentRowID(row.id);
                          setExpanded(false);
                          setShowDeleteVersionModal(true);
                        }}
                        id={`delete-dataset-version-${row.id}`}
                        data-cy="delete-dataset-version"
                      >
                        <img
                          src="/images/trash.svg"
                          className="img-fluid icon-list"
                          alt="discard"
                        />
                        Delete
                      </li>
                    </>
                  )}
                  {row.status === "Published" && (
                    <>
                      <li
                        onClick={() => onClickDuplicateDatasetVersion(row.id)}
                        id={`duplicate-dataset-version-${row.id}`}
                        data-cy="duplicate-dataset-version"
                      >
                        <img
                          src="/images/duplicate.svg"
                          className="img-fluid icon-list"
                          alt="duplicate"
                        />
                        Make a copy
                      </li>
                      <hr />
                      <li
                        onClick={() => {
                          setCurrentRowID(row.id);
                          setExpanded(false);
                          setShowArchiveModal(true);
                        }}
                        id={`archive-dataset-version-${row.id}`}
                        data-cy="archive-dataset-version"
                      >
                        <img
                          src="/images/archive.svg"
                          className="img-fluid icon-list"
                          alt="archive"
                        />
                        Archive
                      </li>
                    </>
                  )}
                </ul>
              </Offcanvas.Body>
            </Offcanvas>
          </>
        );
      },
      width: "16%",
    },
  ];

  const handleAddVersionBtnClick = () => {
    navigate("/datasets/version/add/" + id);
  };

  useEffect(() => {
    setTotalItems(datasetVersions?.count || 0);
    if (datasetVersions?.results) {
      setFilteredItems(
        Object.values(datasetVersions?.results).map((item) => {
          let publishAllow = false;
          if (
            item.description !== "<p><br></p>" &&
            item.citation_details !== "<p><br></p>" &&
            item.contributors.length > 0 &&
            item.keywords.length > 0 &&
            item.citation_details !== "<p><br></p>" &&
            item.other_relevant_information !== "<p><br></p>" &&
            item.file_attachments.length > 0 &&
            item.license !== ""
          ) {
            publishAllow = true;
          }
          if (item) {
            const contributors = !item.contributors ? "---" : item.contributors;

            const result = Object.values(contributors).map((contributor) => {
              return Object.values(users).filter(
                (user) => user.email === contributor.email
              )[0];
            });
            let contributor_name = "";
            const icon = (
              <img
                src="/images/user_list.svg"
                className="img-fluid icon-list"
                alt="user"
              />
            );
            if (result[0]) {
              contributor_name = (
                <span
                  key={`memberKey${result[0]?.id}`}
                  className="row-version-contributors"
                >
                  {icon}
                  {result[0]?.first_name + " " + result[0]?.last_name}
                  {contributors.length - 1 !== 0 && (
                    <span className="plus-members-count">
                      +{contributors.length - 1}
                    </span>
                  )}
                </span>
              );
            } else {
              contributor_name = "--";
            }

            const row = {
              id: item.id,
              dataset_profile: item.dataset_profile,
              version:
                item.version_id < 10
                  ? "0" + item.version_id
                  : item.version_id.toString(),
              subtitle: !item.subtitle ? "---" : item.subtitle,
              keywords: !item.keywords.toString()
                ? "---"
                : item.keywords.toString(),
              date_created: convertDate(
                item.created_on,
                isMobile ? "MM DD, YYYY" : "MM/DD/YYYY"
              ),
              contributors: contributor_name,
              status:
                item?.status.charAt(0).toUpperCase() +
                item?.status.toLowerCase().slice(1),
              publishAllow: publishAllow,
            };
            return row;
          } else {
            return;
          }
        })
        // .filter((row) => {
        //   if (row) {
        //     return (
        //       (row.version.toLowerCase().includes(filterText.toLowerCase()) ||
        //         row.subtitle
        //           .toLowerCase()
        //           .includes(filterText.toLowerCase()) ||
        //         row?.keywords
        //           .toString()
        //           ?.toLowerCase()
        //           .includes(filterText.toLowerCase()) ||
        //         row.date_created
        //           .toLowerCase()
        //           .includes(filterText.toLowerCase()) ||
        //         row.contributors
        //           .toString()
        //           .toLowerCase()
        //           .includes(filterText.toLowerCase()) ||
        //         row.status
        //           .toLowerCase()
        //           .includes(filterText.toLowerCase())) &&
        //       (!userAllow
        //         ? row.status.toLowerCase().includes("published")
        //         : row.status.toLowerCase().includes("draft") ||
        //           row.status.toLowerCase().includes("archived") ||
        //           row.status.toLowerCase().includes("published"))
        //     );
        //   }
        // })
        // .sort((a, b) => {
        //   switch (sortColumn) {
        //     case "Status":
        //       return sortDirectionStatus === "asc"
        //         ? a.status.toLowerCase().localeCompare(b.status)
        //         : b.status.toLowerCase().localeCompare(a.status);
        //     default:
        //       return compareValues(
        //         a.date_created,
        //         b.date_created,
        //         sortDirectionDateCreated
        //       );
        //   }
        // })
      );
    }
  }, [
    datasetVersions,
    filterText,
    // sortDirectionDateCreated,
    // sortDirectionStatus,
    // sortColumn,
    userAllow,
  ]);

  useEffect(() => {
    // Simulating data fetching
    // Replace this with your actual data fetching logic
    const fetchedData = filteredItems; // Replace this with your fetched data
    setData(fetchedData);
    // setTotalItems(fetchedData.length);
  }, [filteredItems]);

  useEffect(() => {
    let slicedData = data;
    if (itemsPerPage !== totalItems) {
      const start = (currentPage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      slicedData = data.slice(start, end);
    }
    setPaginatedData(slicedData);
  }, [currentPage, itemsPerPage, data, totalItems]);

  const handleRowClick = (row) => {
    // Handle row click event and navigate to a different page
    // const version = `DS-${row.dataset_profile}-${row.version}`;
    navigate("/datasets/profile/" + id + "/version/" + row.id);
  };

  const handleSubscribe = () => {
    const datasetProfileID = parseInt(id);
    if (subscribed) {
      dispatch(unsubscribeDatasetProfile(datasetProfileID))
        .then(() => {
          setSubscribed(!subscribed);
          setSubscribedCount(subscribedCount - 1);
          props.setShowToast(true);
          props.setToastStatus("error");
          props.setToastImage("/images/subscription-removed-success.svg");
          setRefreshSubscribe(refreshSubscribe + 1);
        })
        .catch((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        });
    } else {
      dispatch(subscribeDatasetProfile(datasetProfileID))
        .then(() => {
          props.setShowToast(true);
          props.setToastStatus("success");
          props.setToastImage("/images/subscription-added-success.svg");
          setSubscribed(!subscribed);
          setSubscribedCount(subscribedCount + 1);
          setRefreshSubscribe(refreshSubscribe + 1);
        })
        .catch((status) => {
          props.setShowToast(true);
          props.setToastStatus(status);
          props.setToastImage(null);
        });
    }
  };

  const onClickEditProfileBtn = () => {
    setShowEditDatasetModal(true);
    setExpandedDatasetProfileBtnMobile(false);
  };

  const onClickDeleteProfileBtn = () => {
    setShowDeleteDatasetModal(true);
    setExpandedDatasetProfileBtnMobile(false);
  };

  useEffect(() => {
    if (scrollRef.current) {
      // Target the rdt_Table div and add custom attributes
      const rdtTableDiv = scrollRef.current.querySelector(".rdt_Table");
      if (rdtTableDiv) {
        rdtTableDiv.setAttribute("id", "versions-table");
        rdtTableDiv.setAttribute("data-cy", "versions-table");
      }
      // Target the rdt_TableRow divs and add custom attributes
      const rdtTableRows = scrollRef.current.querySelectorAll(".rdt_TableRow");
      rdtTableRows.forEach((row, index) => {
        row.setAttribute("id", `version-row-item-${paginatedData[index].id}`);
        row.setAttribute("data-cy", "version-row-item");
      });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        if (itemsPerPage <= totalItems && isMobile) {
          if (scrollTop + clientHeight >= scrollHeight) {
            setLoading(true);
            // Simulate a network request
            setTimeout(() => {
              setLoading(false);
              // Load more content or perform any other actions
              setItemsPerPage(itemsPerPage + 25);
            }, 2000);
          }
        }
      }
    };

    const scrollDiv = scrollRef.current;
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [itemsPerPage, totalItems, isMobile]);

  return (
    <>
      <SaveAndPublishModal
        showSaveAndPublishModal={showSaveAndPublishModal}
        handleSaveAndPublishModalClose={handleSaveAndPublishModalClose}
        onClickSave={() => onClickSave(true, currentRowID)}
      />
      <DeleteVersionModal
        showDeleteVersionModal={showDeleteVersionModal}
        handleDeleteVersionModalClose={handleDeleteVersionModalClose}
        onClickDeleteVersion={() => onClickDeleteVersion(currentRowID)}
      />
      <ArchiveModal
        datasetProfileID={datasetProfileID}
        datasetVersionID={currentRowID}
        setShowToast={props?.setShowToast}
        setToastStatus={props?.setToastStatus}
        setToastImage={props?.setToastImage}
        setRefreshDatasetVersionsCount={props?.setRefreshDatasetVersionsCount}
        refreshDatasetVersionsCount={props?.refreshDatasetVersionsCount}
        showArchiveModal={showArchiveModal}
        handleArchiveModalClose={handleArchiveModalClose}
      />
      {currentDataset && currentDataset?.author?.email === user.email && (
        <DeleteDatasetModal
          datasetID={id}
          showDeleteDatasetModal={showDeleteDatasetModal}
          handleDeleteDatasetModalClose={handleDeleteDatasetModalClose}
          setShowToast={props?.setShowToast}
          setToastStatus={props?.setToastStatus}
          setToastImage={props?.setToastImage}
        />
      )}
      {currentDataset && currentDataset?.author?.email === user.email && (
        <EditDatasetProfileModal
          datasetID={id}
          datasetTitle={currentDataset?.title}
          datasetDescription={currentDataset?.description}
          datasetCreatedBy={currentDataset?.author}
          showEditDatasetModal={showEditDatasetModal}
          setShowEditDatasetModal={setShowEditDatasetModal}
          handleEditDatasetModalClose={handleEditDatasetModalClose}
          setShowToast={props?.setShowToast}
          setToastStatus={props?.setToastStatus}
          setToastImage={props?.setToastImage}
        />
      )}
      {currentDataset && (
        <ViewDatasetCreatorModal
          datasetCreatedBy={currentDataset?.author?.email}
          showViewDatasetCreatorModal={showViewDatasetCreatorModal}
          handleViewDatasetCreatorModalClose={
            handleViewDatasetCreatorModalClose
          }
          setShowToast={props?.setShowToast}
          setToastStatus={props?.setToastStatus}
          setToastImage={props?.setToastImage}
        />
      )}
      <div className="mw-100 dataset-profile-container">
        <div className="dataset-profile-mobile-header">
          <MobileLeftSidebar
            setUrlToRedirected={props.setUrlToRedirected}
            setShowDiscardChangesModal={props.setShowDiscardChangesModal}
            changesMade={props.changesMade}
          />
          <Breadcrumb id="dataset-breadcrumbs" data-cy="dataset-breadcrumbs">
            <Breadcrumb.Item active>Datasets</Breadcrumb.Item>
            <Breadcrumb.Item
              className="link"
              linkAs={Link}
              linkProps={{ to: "/datasets" }}
            >
              Dataset Profiles
            </Breadcrumb.Item>
          </Breadcrumb>
          {isMobile && (
            <>
              <p
                className="bold dataset-profile-title"
                id="dataset-name"
                data-cy="dataset-name"
              >
                {datasetTitle}
              </p>
              {userAllow && (
                <div>
                  <img
                    src="/images/three_dot_menu.svg"
                    className="mobile-three-dot-icon"
                    alt="menu"
                    onClick={() => setExpandedDatasetProfileBtnMobile(true)}
                    id="more-btn"
                    data-cy="more-btn"
                  />
                  <Offcanvas
                    show={expandedDatasetProfileBtnMobile}
                    onHide={() => setExpandedDatasetProfileBtnMobile(false)}
                    placement="bottom"
                    className="offcanvas-choose-action"
                  >
                    <Offcanvas.Body>
                      <p
                        className="choose-action-title bold"
                        id="dataset-name"
                        data-cy="dataset-name"
                      >
                        {datasetTitle}
                      </p>
                      <p className="choose-action-subtitle">Choose an action</p>
                      <ul className="choose-action-ul p-0">
                        <li
                          onClick={onClickEditProfileBtn}
                          id="edit-dataset"
                          data-cy="edit-dataset"
                        >
                          <img
                            src="/images/edit.svg"
                            className="img-fluid icon-list"
                            alt="edit"
                          />
                          Edit profile card
                        </li>
                        {(filteredItems?.find(
                          (item) =>
                            item.status === "Published" ||
                            item.status === "Archived"
                        ) === undefined ||
                          filteredItems.length === 0) &&
                          currentDataset &&
                          currentDataset?.author?.email === user.email && (
                            <li
                              onClick={onClickDeleteProfileBtn}
                              id="delete-dataset"
                              data-cy="delete-dataset"
                            >
                              <img
                                src="/images/trash.svg"
                                className="img-fluid icon-list"
                                alt="discard"
                              />
                              Delete profile
                            </li>
                          )}
                      </ul>
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
              )}
            </>
          )}
        </div>
        {!isMobile && (
          <div className="d-flex justify-content-between">
            <p
              className="name-profile"
              id="dataset-name"
              data-cy="dataset-name"
            >
              {datasetTitle}
            </p>
            <span className="d-flex align-items-center">
              {currentDataset &&
                currentDataset?.author?.email === user.email && (
                  <Dropdown>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>More</Tooltip>}
                      trigger={["hover", "focus"]}
                    >
                      <Dropdown.Toggle
                        variant="secondary"
                        className="three-dot-dropdown"
                        id="more-btn"
                        data-cy="more-btn"
                      >
                        <img
                          src="/images/three_dot_menu.svg"
                          className="img-fluid"
                          alt="menu"
                        />
                      </Dropdown.Toggle>
                    </OverlayTrigger>

                    <Dropdown.Menu className="three-dot-dropdown-menu">
                      <Dropdown.Item
                        onClick={() => setShowEditDatasetModal(true)}
                        id="edit-dataset"
                        data-cy="edit-dataset"
                      >
                        <img
                          src="/images/edit.svg"
                          className="img-fluid icon-list"
                          alt="edit"
                        />
                        Edit profile card
                      </Dropdown.Item>
                      {(filteredItems?.find(
                        (item) =>
                          item.status === "Published" ||
                          item.status === "Archived"
                      ) === undefined ||
                        filteredItems.length === 0) &&
                        currentDataset &&
                        currentDataset?.author?.email === user.email && (
                          <Dropdown.Item
                            onClick={() => setShowDeleteDatasetModal(true)}
                            id="delete-dataset"
                            data-cy="delete-dataset"
                          >
                            <img
                              src="/images/trash.svg"
                              className="img-fluid icon-list"
                              alt="discard"
                            />
                            Delete profile
                          </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
            </span>
          </div>
        )}
        {!isMobile && (
          <div className="d-flex labels">
            <div
              className="dataset-creator"
              onClick={() => setShowViewDatasetCreatorModal(true)}
            >
              <span id="owner" data-cy="owner">
                <img
                  src="/images/user_list.svg"
                  className="img-fluid icon-list"
                  alt="user"
                />
                {datasetCreatedBy}
              </span>
            </div>
            <div>
              <span>{datasetDateCreated}</span>
            </div>
            <div>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>{subscribed ? "Unsubscribe" : "Subscribe"}</Tooltip>
                }
                trigger={["hover", "focus"]}
              >
                <Button
                  variant="outline-light"
                  className="input-btn"
                  onClick={handleSubscribe}
                  name="subscribe"
                  id="subscribe"
                  data-cy="subscribe"
                >
                  <img
                    src={
                      subscribed
                        ? "/images/subscribed.svg"
                        : "/images/not-subscribed.svg"
                    }
                    className="heart-15p"
                    alt="subscribe"
                  />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>No. of subscriptions</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <span className="input-counter">{subscribedCount}</span>
              </OverlayTrigger>
            </div>
          </div>
        )}
        <Tabs
          defaultActiveKey={
            tab === "2" ? "dataset-files-and-versions" : "dataset-profile-card"
          }
          id="dataset-tabs"
          className="dataset-profile-tabs pt-85-px"
        >
          <Tab
            eventKey="dataset-profile-card"
            tabClassName="tabs"
            title="Profile card"
            tabAttrs={{ "data-cy": "profile-card-tab" }}
          >
            {isMobile && (
              <div>
                <div
                  className="bold mobile-dataset-profile-name mb-2"
                  id="dataset-name"
                  data-cy="dataset-name"
                >
                  {datasetTitle}
                </div>

                <div className="mb-2">
                  <div className="subscribe-div">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          {subscribed ? "Unsubscribe" : "Subscribe"}
                        </Tooltip>
                      }
                      trigger={["hover", "focus"]}
                    >
                      <Button
                        variant="outline-light"
                        className="input-btn"
                        onClick={handleSubscribe}
                        name="subscribe"
                        id="subscribe"
                        data-cy="subscribe"
                      >
                        <img
                          src={
                            subscribed
                              ? "/images/subscribed.svg"
                              : "/images/not-subscribed.svg"
                          }
                          className="heart-10p"
                          alt="subscribe"
                        />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>No. of subscriptions</Tooltip>}
                      trigger={["hover", "focus"]}
                    >
                      <span className="input-counter">{subscribedCount}</span>
                    </OverlayTrigger>
                  </div>
                </div>

                <div onClick={() => setShowViewDatasetCreatorModal(true)}>
                  <div className="bold">Created by</div>
                  <div
                    className="dataset-creator-span p-1"
                    id="owner"
                    data-cy="owner"
                  >
                    <img
                      src="/images/user_list.svg"
                      className="icon-list account-10p"
                      alt="user"
                    />
                    {datasetCreatedBy}
                  </div>
                </div>
                <div className="mt-2">
                  <div className="bold">Created on</div>
                  <p>{datasetDateCreated}</p>
                </div>
              </div>
            )}
            <p className="bold">Description</p>
            <div className="ql-snow">
              <div
                className="ql-editor px-0 pt-1"
                dangerouslySetInnerHTML={{
                  __html: datasetDescription,
                }}
              ></div>
            </div>
          </Tab>
          <Tab
            eventKey="dataset-files-and-versions"
            tabClassName="tabs"
            title="Versions"
            tabAttrs={{ "data-cy": "versions-tab" }}
          >
            <div ref={scrollRef} className="data-table-div">
              <p className="bold mt-3 ms-3">Versions</p>
              <DataTable
                className="dataset-versions-datatable"
                // columns={userAllow ? columns1 : columns2}
                columns={columns1}
                data={paginatedData}
                pagination={false}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                onRowClicked={handleRowClick}
                noTableHead={isMobile}
              />
              <div className="mb-4 d-flex justify-content-center">
                {loading && (
                  <Spinner
                    className="spinner"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </div>
              {!isMobile && (
                <CustomPagination
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onChange={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default DatasetProfile;
