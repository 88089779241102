import {
  downloadModelVersion,
  inferenceChangeDetectionModel,
} from "actions/model";
import { IP_ADDRESS, IP_ADDRESS_4, convertDate } from "common/constants";
import CancelDemoModal from "components/models/CancelDemoModal";
import ModelDemoStartModal from "components/models/ModelDemoStartModal";
import { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CancelToken } from "axios";
import JSZip from "jszip";
import { useMediaQuery } from "react-responsive";

const DemoChangeDetection = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [modelSelectedFileLandscape1, setModelSelectedFileLandscape1] =
    useState("");
  const [modelSelectedFileLandscape2, setModelSelectedFileLandscape2] =
    useState("");
  const [demoProgressPercentage, setDemoProgressPercentage] = useState(0);
  const [opacity, setOpacity] = useState(50);
  const [counter, setCounter] = useState(0);
  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [startDemoDisabled, setStartDemoDisabled] = useState(true);
  const [demoStarted, setDemoStarted] = useState(false);
  const [computationTime, setComputationTime] = useState(0);
  const [showModelDemoStartModal, setShowModelDemoStartModal] = useState(false);
  const [versions, setVersions] = useState([]);
  const [modelFileID, setModelFileID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const fileInputRefLandscape1 = useRef(null);
  const [outputImage, setOutputImage] = useState("");
  const [imageLandscape1, setImageLandscape1] = useState("");
  const [imageLandscape2, setImageLandscape2] = useState("");
  const [imageOutputOverlay, setimageOutputOverlay] = useState("");
  const [outputLayer, setOutputLayer] = useState("before");
  const [userCancelled, setUserCancelled] = useState(false);
  const [shouldWarn, setShouldWarn] = useState(false);

  // const cancelAction = useRef(null);
  const cancelAction1 = useRef(null);
  const cancelAction2 = useRef(null);

  const handleModelDemoStartModalClose = () => {
    setShowModelDemoStartModal(false);
  };

  useEffect(() => {
    const filteredVersions = props?.modelVersions;
    if (filteredVersions?.length > 0) {
      const filteredVersionsPublished = filteredVersions.filter((version) => {
        return version.status === "Published";
      });
      const filteredVersionsDraft = filteredVersions.filter((version) => {
        return version.status === "Draft";
      });
      setModelFileID(
        filteredVersionsPublished.length > 0
          ? filteredVersionsPublished[0]?.id
          : filteredVersionsDraft[0]?.id
      );
    }
    setVersions(filteredVersions);
  }, [props?.modelVersions]);

  const validate = (name, value) => {
    if (
      name === "modelSelectedFileLandscape1" ||
      name === "modelSelectedFileLandscape2"
    ) {
      const extensionName = value?.name?.split(".").at(-1).toLowerCase();
      if (!value) {
        setCounter(counter + 1);
        setIsValid(Object.assign(isValid, { [name]: false }));
        setError(
          Object.assign(error, {
            [name]: "Choose your image file",
          })
        );
        if (name === "modelSelectedFileLandscape1") {
          setModelSelectedFileLandscape1("");
        } else {
          setModelSelectedFileLandscape2("");
        }
        return;
      }

      if (value?.size > 25000000) {
        setCounter(counter + 1);
        setIsValid(Object.assign(isValid, { [name]: false }));
        setError(
          Object.assign(error, {
            [name]: "Your file is larger than 25 MB. Try another file.",
          })
        );
        if (name === "modelSelectedFileLandscape1") {
          setModelSelectedFileLandscape1("");
        } else {
          setModelSelectedFileLandscape2("");
        }
        return;
      }

      if (
        extensionName !== "jpeg" &&
        extensionName !== "jpg" &&
        extensionName !== "png" &&
        extensionName !== "gif" &&
        extensionName !== "bmp" &&
        extensionName !== "tiff" &&
        extensionName !== "raw" &&
        extensionName !== "webp" &&
        extensionName !== "svg" &&
        extensionName !== "heif" &&
        extensionName !== "heic" &&
        extensionName !== "dicom" &&
        extensionName !== "pgm" &&
        extensionName !== "ppm" &&
        extensionName !== "png" &&
        extensionName !== "pbm" &&
        extensionName !== "hdr" &&
        extensionName !== "exr"
      ) {
        setCounter(counter + 1);
        setIsValid(Object.assign(isValid, { [name]: false }));
        setError(
          Object.assign(error, {
            [name]: "File type not supported",
          })
        );
        if (name === "modelSelectedFileLandscape1") {
          setModelSelectedFileLandscape1("");
        } else {
          setModelSelectedFileLandscape2("");
        }
        return;
      }
      if (
        name === "modelSelectedFileLandscape1" &&
        Array.isArray(modelSelectedFileLandscape1) &&
        modelSelectedFileLandscape1?.find((file) => file.name === value.name)
      ) {
        setModelSelectedFileLandscape1(modelSelectedFileLandscape1);
        return;
      }
      if (
        name === "modelSelectedFileLandscape2" &&
        Array.isArray(modelSelectedFileLandscape2) &&
        modelSelectedFileLandscape2?.find((file) => file.name === value.name)
      ) {
        setModelSelectedFileLandscape2(modelSelectedFileLandscape2);
        return;
      }
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  const handleFileChangeLandscape1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      setModelSelectedFileLandscape1(event.target.files[0]);
      const extensionName = file?.name?.split(".").at(-1).toLowerCase();
      if (
        extensionName === "jpeg" ||
        extensionName === "jpg" ||
        extensionName === "png" ||
        extensionName === "gif" ||
        extensionName === "bmp" ||
        extensionName === "tiff" ||
        extensionName === "raw" ||
        extensionName === "webp" ||
        extensionName === "svg" ||
        extensionName === "heif" ||
        extensionName === "heic" ||
        extensionName === "dicom" ||
        extensionName === "pgm" ||
        extensionName === "ppm" ||
        extensionName === "png" ||
        extensionName === "pbm" ||
        extensionName === "hdr" ||
        extensionName === "exr"
      ) {
        const url = URL.createObjectURL(file);
        setImageLandscape1(url);
      }
      validate("modelSelectedFileLandscape1", file);
      setCounter(counter + 1);
    }
  };

  const handleDragOverLandscape1 = (event) => {
    event.preventDefault();
  };

  const handleDropLandscape1 = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && !imageLandscape1) {
      setModelSelectedFileLandscape1(file);
      const extensionName = file?.name?.split(".").at(-1).toLowerCase();
      if (
        extensionName === "jpeg" ||
        extensionName === "jpg" ||
        extensionName === "png" ||
        extensionName === "gif" ||
        extensionName === "bmp" ||
        extensionName === "tiff" ||
        extensionName === "raw" ||
        extensionName === "webp" ||
        extensionName === "svg" ||
        extensionName === "heif" ||
        extensionName === "heic" ||
        extensionName === "dicom" ||
        extensionName === "pgm" ||
        extensionName === "ppm" ||
        extensionName === "png" ||
        extensionName === "pbm" ||
        extensionName === "hdr" ||
        extensionName === "exr"
      ) {
        const url = URL.createObjectURL(file);
        setImageLandscape1(url);
      }
      validate("modelSelectedFileLandscape1", file);
      setCounter(counter + 1);
    }
  };

  const handleClickLandscape1 = () => {
    fileInputRefLandscape1.current.click();
  };

  const fileInputRefLandscape2 = useRef(null);

  const handleFileChangeLandscape2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      setModelSelectedFileLandscape2(file);
      const extensionName = file?.name?.split(".").at(-1).toLowerCase();
      if (
        extensionName === "jpeg" ||
        extensionName === "jpg" ||
        extensionName === "png" ||
        extensionName === "gif" ||
        extensionName === "bmp" ||
        extensionName === "tiff" ||
        extensionName === "raw" ||
        extensionName === "webp" ||
        extensionName === "svg" ||
        extensionName === "heif" ||
        extensionName === "heic" ||
        extensionName === "dicom" ||
        extensionName === "pgm" ||
        extensionName === "ppm" ||
        extensionName === "png" ||
        extensionName === "pbm" ||
        extensionName === "hdr" ||
        extensionName === "exr"
      ) {
        const url = URL.createObjectURL(file);
        setImageLandscape2(url);
      }
      validate("modelSelectedFileLandscape2", file);
      setCounter(counter + 1);
    }
  };

  const handleDragOverLandscape2 = (event) => {
    event.preventDefault();
  };

  const handleDropLandscape2 = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && !imageLandscape2) {
      setModelSelectedFileLandscape2(file);
      const extensionName = file?.name?.split(".").at(-1).toLowerCase();
      if (
        extensionName === "jpeg" ||
        extensionName === "jpg" ||
        extensionName === "png" ||
        extensionName === "gif" ||
        extensionName === "bmp" ||
        extensionName === "tiff" ||
        extensionName === "raw" ||
        extensionName === "webp" ||
        extensionName === "svg" ||
        extensionName === "heif" ||
        extensionName === "heic" ||
        extensionName === "dicom" ||
        extensionName === "pgm" ||
        extensionName === "ppm" ||
        extensionName === "png" ||
        extensionName === "pbm" ||
        extensionName === "hdr" ||
        extensionName === "exr"
      ) {
        const url = URL.createObjectURL(file);
        setImageLandscape2(url);
      }
    }
    validate("modelSelectedFileLandscape2", file);
    setCounter(counter + 1);
  };

  const handleClickLandscape2 = () => {
    fileInputRefLandscape2.current.click();
  };

  const handleRemoveLandscape1Btn = (e) => {
    validate("modelSelectedFileLandscape1", "");
    fileInputRefLandscape1.current.value = "";
    setImageLandscape1("");
    setModelSelectedFileLandscape1("");
    setStartDemoDisabled(true);
    e.stopPropagation();
  };

  const handleRemoveLandscape2Btn = (e) => {
    validate("modelSelectedFileLandscape2", "");
    fileInputRefLandscape2.current.value = "";
    setImageLandscape2("");
    setModelSelectedFileLandscape2("");
    setStartDemoDisabled(true);
    e.stopPropagation();
  };

  const handleDownloadOutputBtn = async (e) => {
    const url = outputImage; // Replace with your file URL
    const response = await fetch(url);

    if (!response.ok) {
      // Handle error here
      console.error("Failed to download file");
      return;
    }

    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = urlBlob;
    a.download = "file.png"; // Specify the desired file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(urlBlob);
  };

  const handleClearBtn = () => {
    setStartDemoDisabled(true);
    setImageLandscape1("");
    setImageLandscape2("");
    setOutputImage("");
    setDemoProgressPercentage(0);
    setErrorMessage("");
    setDemoStarted(false);
    setComputationTime(0);
    fileInputRefLandscape1.current.value = "";
    fileInputRefLandscape2.current.value = "";
  };

  // Effect to continuously check the condition and compute
  useEffect(() => {
    // Define a function to check and compute
    // function checkAndCompute() {
    //   setComputationTime((prevTime) => prevTime + 1); // Increment computation time
    //   // Check the condition
    //   if (demoStarted) {
    //     setTimeout(checkAndCompute, 1000); // Adjust the delay as needed
    //   }
    // }

    let timer = setTimeout(() => {
      setComputationTime((prevTime) => prevTime + 1); // Increment computation time
    }, 1000);
    if (!demoStarted || userCancelled) {
      props?.setDisableLayout(false);
      clearTimeout(timer);
    }

    // // Cleanup function (optional)
    // return () => {
    //   // Perform cleanup tasks if necessary
    //   clearTimeout(timer);
    // };
  }, [demoStarted, computationTime, userCancelled]); // Empty dependency array ensures this effect runs only once on component mount

  useEffect(() => {
    if (demoStarted) {
      const interval = setInterval(() => {
        if (demoProgressPercentage < 100) {
          setDemoProgressPercentage((prevProgress) => prevProgress + 1);
        } else {
          clearInterval(interval);
        }
      }, 50); // Adjust the interval for smoother or faster progress

      return () => clearInterval(interval);
    } else {
      setDemoProgressPercentage(0);
    }
  }, [demoStarted]);

  const resetValues = () => {
    props?.setDisableLayout(false);
    setDemoStarted(false);
    setDemoProgressPercentage(0);
    setStartDemoDisabled(true);
    setOpacity(50);
    setShouldWarn(false);
    setUserCancelled(false);
  };

  const startInference = (modelFile, weightFile) => {
    dispatch(
      inferenceChangeDetectionModel(
        modelSelectedFileLandscape1,
        modelSelectedFileLandscape2,
        modelFile,
        weightFile,
        new CancelToken((cancel) => (cancelAction2.current = cancel))
      )
    )
      .then((res) => {
        setOutputImage(IP_ADDRESS_4 + res.result_image);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err);
      })
      .finally(() => {
        resetValues();
      });
  };

  const handleStartDemoBtn = () => {
    setComputationTime(0);
    setDemoStarted(true);
    setStartDemoDisabled(true);
    setOutputImage("");
    setErrorMessage("");
    props?.setDisableLayout(true);
    setimageOutputOverlay(imageLandscape1);
    setShouldWarn(true);

    dispatch(
      downloadModelVersion(
        modelFileID,
        (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // console.log(percentage);
        },
        new CancelToken((cancel) => (cancelAction1.current = cancel))
      )
    )
      .then((content) => {
        if (!userCancelled) {
          const zipFile = new File([content], "output.zip", {
            type: content.type,
          });
          if (zipFile) {
            const reader = new FileReader();
            reader.onload = async (e) => {
              const arrayBuffer = e.target.result;
              try {
                const zip = await JSZip.loadAsync(arrayBuffer);
                await extractZipFile(zip);
              } catch (error) {
                console.error("Failed to load ZIP file:", error);
              }
            };
            reader.readAsArrayBuffer(zipFile);
          }
        } else {
          resetValues();
        }
      })
      .catch((err) => {
        console.log(err);
        resetValues();
      });
  };

  const extractZipFile = async (zip) => {
    if (!userCancelled) {
      for (const fileName of Object.keys(zip.files)) {
        const file = zip.files[fileName];
        if (!file.dir && fileName.endsWith(".zip")) {
          try {
            const nestedZipArrayBuffer = await file.async("arraybuffer");
            const nestedZip = await JSZip.loadAsync(nestedZipArrayBuffer);
            await extractNestedZipFile(nestedZip);
          } catch (error) {
            console.error("Failed to load nested ZIP file:", error);
            resetValues();
          }
        }
      }
    } else {
      resetValues();
    }
  };

  const extractNestedZipFile = async (zip) => {
    if (!userCancelled) {
      let modelFile = null;
      let weightFile = null;
      for (const fileName of Object.keys(zip.files)) {
        const file = zip.files[fileName];
        if (
          !file.dir &&
          (fileName.endsWith(".pth") || fileName.endsWith(".py"))
        ) {
          try {
            const content = await file.async("arraybuffer");
            if (fileName.endsWith(".py")) {
              modelFile = new File([content], "model.py", {
                type: "application/octet-stream",
              });
            }
            if (fileName.endsWith(".pth")) {
              weightFile = new File([content], "weight.pth", {
                type: "application/octet-stream",
              });
            }
            if (weightFile && modelFile) {
              startInference(modelFile, weightFile);
            }
          } catch (error) {
            console.error("Failed to extract .pt file:", error);
            resetValues();
          }
        }
      }
    } else {
      resetValues();
    }
  };

  const onChangeCompareOutputLayer = (e) => {
    setOutputLayer(e.target.value);
    if (e.target.value === "before") {
      setimageOutputOverlay(imageLandscape1);
    } else {
      setimageOutputOverlay(imageLandscape2);
    }
  };

  const onChangeOpacity = (e) => {
    setOpacity(e.target.value);
  };

  const onChangeRange = (e) => {
    setOpacity(e.target.value);
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  useEffect(() => {
    if (
      modelSelectedFileLandscape1 !== "" &&
      modelSelectedFileLandscape2 !== "" &&
      imageLandscape1 !== "" &&
      imageLandscape2 !== "" &&
      Object.keys(error).length === 0
    ) {
      setStartDemoDisabled(false);
    } else {
      setStartDemoDisabled(true);
    }
  }, [
    modelSelectedFileLandscape1,
    modelSelectedFileLandscape2,
    imageLandscape1,
    imageLandscape2,
    error,
  ]);

  const onChangeVersion = (e) => {
    setModelFileID(e.target.value);
  };

  const cancelInferencing = () => {
    if (cancelAction1.current) {
      setUserCancelled(true);
      console.log("User has canceled the action 1");
      cancelAction1.current("User has canceled the action.");
    }
    if (cancelAction2.current) {
      setUserCancelled(true);
      console.log("User has canceled the action 2");
      cancelAction2.current("User has canceled the action.");
    }
  };

  const onClickCancelDemo = () => {
    cancelInferencing();
    props?.handleCancelDemoModalClose();
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      props?.setShowCancelDemoModal(true);
      event.preventDefault();
      event.returnValue = ""; // Standard for most browsers
    };

    if (shouldWarn) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("unload", cancelInferencing);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", cancelInferencing);
    };
  }, [shouldWarn]);

  useEffect(() => {
    if (userCancelled) {
      setErrorMessage("User cancelled the action");
    }
  }, [userCancelled]);

  const handleSampleInput = () => {
    scrollToTop();
    handleClearBtn();
    const input1 = "/inferencing-inputs/change-detection/US-1.png";
    const input2 = "/inferencing-inputs/change-detection/US-2.png";
    setImageLandscape1(input1);
    setImageLandscape2(input2);
    simulateFileInput(input1, "landscape1");
    simulateFileInput(input2, "landscape2");
  };

  const simulateFileInput = async (input, landscape) => {
    const fetched = await fetch(`${process.env.PUBLIC_URL + input}`);
    const blob = await fetched.blob();
    const simulatedFile = new File(
      [blob],
      landscape === "landscape1" ? "US-1.png" : "US-2.png",
      {
        type: blob.type,
      }
    );
    // Create a new DataTransfer object and add the file
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(simulatedFile);

    if (landscape === "landscape1") {
      // Set the files property of the input element
      if (fileInputRefLandscape1.current) {
        fileInputRefLandscape1.current.files = dataTransfer.files;
        setModelSelectedFileLandscape1(fileInputRefLandscape1.current.files[0]);

        // Trigger the change event
        const event = new Event("change", { bubbles: true });
        fileInputRefLandscape1.current.dispatchEvent(event);
      }
    } else {
      if (fileInputRefLandscape2.current) {
        fileInputRefLandscape2.current.files = dataTransfer.files;
        setModelSelectedFileLandscape2(fileInputRefLandscape2.current.files[0]);

        // Trigger the change event
        const event = new Event("change", { bubbles: true });
        fileInputRefLandscape2.current.dispatchEvent(event);
      }
    }
  };

  // List of ref for the element to scroll to the top
  const sampleInputsRef = useRef(null);

  const scrollToTop = () => {
    // Use the current property of the ref to get the DOM node
    const sampleInputs = sampleInputsRef.current;

    // Scroll to the top of the element
    sampleInputs.scrollTop = 0;
  };

  return (
    <>
      <CancelDemoModal
        showCancelDemoModal={props?.showCancelDemoModal}
        handleCancelDemoModalClose={props?.handleCancelDemoModalClose}
        onClickCancelDemo={onClickCancelDemo}
      />
      <ModelDemoStartModal
        // modelID={id}
        handleStartDemoBtn={handleStartDemoBtn}
        showModelDemoStartModal={showModelDemoStartModal}
        handleModelDemoStartModalClose={handleModelDemoStartModalClose}
        setShowToast={props?.setShowToast}
        setToastStatus={props?.setToastStatus}
        setToastImage={props?.setToastImage}
      />
      <div className="demo-main-div" ref={sampleInputsRef}>
        <div
          className="demo-div-1"
          style={{
            pointerEvents: "auto",
            cursor: demoStarted ? "not-allowed" : "unset",
          }}
        >
          <div
            className={`${
              demoStarted ? "cursor-not-allowed pointer-events-none" : ""
            }`}
          >
            <p className="bold mb-0">Demo "{props?.modelName}"</p>
            <p>{props?.modelTask}</p>
            <p className="mb-0">Version</p>
            <Form.Select
              className="input-field"
              onChange={onChangeVersion}
              value={modelFileID}
              name="demo-version"
              id="demo-version"
              data-cy="demo-version"
            >
              {versions?.map((version) => {
                return (
                  <option value={version?.id} key={version?.id}>
                    {version?.status} - {version?.version}
                  </option>
                );
              })}
            </Form.Select>
            <span className="sub-note semi-bold">
              Select a version for demo.
            </span>
          </div>
          {!isMobile && (
            <DemoActionButtons
              setShowModelDemoStartModal={setShowModelDemoStartModal}
              startDemoDisabled={startDemoDisabled}
              imageLandscape1={imageLandscape1}
              imageLandscape2={imageLandscape2}
              handleClearBtn={handleClearBtn}
            />
          )}
        </div>
        <div
          className="demo-div-2"
          style={{
            pointerEvents: "auto",
            cursor: demoStarted ? "not-allowed" : "unset",
          }}
        >
          <div className="input-output-div">
            <div
              className={`input-demo-div ${
                demoStarted ? "cursor-not-allowed pointer-events-none" : ""
              }`}
            >
              <div className="landscape-1-div mb-4">
                <p>Input 1: Before landslide image</p>
                <input type="hidden" value={counter} readOnly />
                <div
                  onDragOver={handleDragOverLandscape1}
                  onDrop={handleDropLandscape1}
                  onClick={handleClickLandscape1}
                  className={`file-drop-area-demo form-control ${
                    isValid?.modelSelectedFileLandscape1
                      ? "is-valid"
                      : isValid?.modelSelectedFileLandscape1 !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  id="demo-input1"
                  data-cy="demo-input1"
                >
                  {imageLandscape1 ? (
                    <div className="position-relative">
                      <img src={imageLandscape1} className="w-100" />
                      <div
                        className="demo-remove-span"
                        onClick={handleRemoveLandscape1Btn}
                        id="remove-input"
                        data-cy="remove-input"
                      >
                        <span>REMOVE</span>
                      </div>
                    </div>
                  ) : (
                    <p>
                      Drag and drop a supported image file here, or click to
                      browse from your device up to 25 mb
                    </p>
                  )}
                  <input
                    ref={fileInputRefLandscape1}
                    type="file"
                    onChange={handleFileChangeLandscape1}
                    style={{ display: "none" }}
                    accept="
                    .jpeg,
                    .jpg,
                    .png,
                    .gif,
                    .bmp,
                    .tiff,
                    .raw,
                    .webp,
                    .svg,
                    .heif,
                    .heic,
                    .dicom,
                    .pgm,
                    .ppm,
                    .pbm,
                    .hdr,
                    .exr"
                  />
                </div>
                {getFormErrorMessage("modelSelectedFileLandscape1")}
              </div>
              <div className="landscape-2-div">
                <p>Input 2: After landslide image</p>
                <div
                  onDragOver={handleDragOverLandscape2}
                  onDrop={handleDropLandscape2}
                  onClick={handleClickLandscape2}
                  className={`file-drop-area-demo form-control ${
                    isValid?.modelSelectedFileLandscape2
                      ? "is-valid"
                      : isValid?.modelSelectedFileLandscape2 !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  id="demo-input2"
                  data-cy="demo-input2"
                >
                  {imageLandscape2 !== "" ? (
                    <div className="position-relative">
                      <img src={imageLandscape2} />
                      <div
                        className="demo-remove-span"
                        onClick={handleRemoveLandscape2Btn}
                        id="remove-input"
                        data-cy="remove-input"
                      >
                        <span>REMOVE</span>
                      </div>
                    </div>
                  ) : (
                    <p>
                      Drag and drop a supported image file here, or click to
                      browse from your device up to 25 mb
                    </p>
                  )}
                  <input
                    ref={fileInputRefLandscape2}
                    type="file"
                    onChange={handleFileChangeLandscape2}
                    style={{ display: "none" }}
                    accept="
                    .jpeg,
                    .jpg,
                    .png,
                    .gif,
                    .bmp,
                    .tiff,
                    .raw,
                    .webp,
                    .svg,
                    .heif,
                    .heic,
                    .dicom,
                    .pgm,
                    .ppm,
                    .pbm,
                    .hdr,
                    .exr"
                  />
                </div>
                {getFormErrorMessage("modelSelectedFileLandscape2")}
              </div>
              {isMobile && (
                <DemoActionButtons
                  setShowModelDemoStartModal={setShowModelDemoStartModal}
                  startDemoDisabled={startDemoDisabled}
                  imageLandscape1={imageLandscape1}
                  imageLandscape2={imageLandscape2}
                  handleClearBtn={handleClearBtn}
                />
              )}
            </div>
            <div className="output-demo-div">
              <p>Output</p>
              <div className="output-demo-image-div mb-3">
                <div
                  className={`${
                    demoProgressPercentage > 0 && !outputImage
                      ? "bg-light-color vh-20"
                      : ""
                  }`}
                  style={{
                    width:
                      demoProgressPercentage > 0 && demoProgressPercentage < 100
                        ? `${demoProgressPercentage}%`
                        : "100%",
                  }}
                >
                  {outputImage && (
                    <div className="position-relative">
                      <img
                        src={imageOutputOverlay}
                        style={{
                          opacity: (100 - opacity) / 100,
                          position: "absolute",
                          left: 0,
                        }}
                        className="w-100"
                      />
                      <div
                        className="demo-remove-span"
                        onClick={handleDownloadOutputBtn}
                        id="download-output"
                        data-cy="download-output"
                      >
                        <span>DOWNLOAD</span>
                      </div>
                    </div>
                  )}

                  {outputImage && <img src={outputImage} className="w-100" />}
                  {demoProgressPercentage === 0 &&
                    !outputImage &&
                    !userCancelled && (
                      <span
                        style={{
                          position: "absolute",
                          bottom: "50%",
                          right: "50%",
                        }}
                      >
                        <img src="/images/default-output-demo-icon.svg" />
                      </span>
                    )}
                  {demoProgressPercentage > 0 &&
                    !outputImage &&
                    !userCancelled && (
                      <div>
                        <Spinner
                          className="spinner-model"
                          as="span"
                          animation="border"
                          size="md"
                          role="status"
                          aria-hidden="true"
                        />
                        <p>Model result is loading...</p>
                      </div>
                    )}
                </div>
              </div>
              {(outputImage || errorMessage) && (
                <div className="output-demo-summary-div">
                  <p className="bold mb-0">Summary</p>
                  <p>Computation time: {computationTime} s</p>
                  {outputImage ? (
                    <>
                      <div>
                        <p className="semi-bold">Compare output layer with</p>
                        <div className="ms-3 mb-3">
                          <Form.Check
                            type="radio"
                            value="before"
                            checked={outputLayer === "before"}
                            onChange={onChangeCompareOutputLayer}
                            label="Input 1: Before landslide image"
                            className="me-5"
                            name="compare-before"
                            id="compare-before"
                            data-cy="compare-before"
                          />
                          <Form.Check
                            type="radio"
                            value="after"
                            checked={outputLayer === "after"}
                            onChange={onChangeCompareOutputLayer}
                            label="Input 2: After landslide image"
                            name="compare-after"
                            id="compare-after"
                            data-cy="compare-after"
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="semi-bold">Opacity</p>
                        <div>
                          <input
                            className="model-output-opacity"
                            type="number"
                            value={opacity}
                            min={0}
                            max={100}
                            onChange={onChangeOpacity}
                          />
                          %
                        </div>
                      </div>
                      <input
                        type="range"
                        onChange={onChangeRange}
                        value={opacity}
                        className="model-output-range"
                      />
                      <p className="sub-note">
                        Change the opacity of the output layer to compare with
                        the input 1 image.
                      </p>
                    </>
                  ) : (
                    <p className="error-demo-message">{errorMessage}</p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="demo-sample-inputs">
            <div>Try out these sample input!</div>
            <Table
              bordered
              hover
              className={`input-demo-table ${
                demoStarted ? "cursor-not-allowed pointer-events-none" : ""
              }`}
              onClick={handleSampleInput}
              id="sample-input"
              data-cy="sample-input"
            >
              <tbody>
                <tr>
                  <td>
                    <div>Input 1: Before landslide image</div>
                    <img
                      src="/inferencing-inputs/change-detection/US-1.png"
                      className="w-100"
                    />
                  </td>
                  <td>
                    <div>Input 2: After landslide image</div>
                    <img
                      src="/inferencing-inputs/change-detection/US-2.png"
                      className="w-100"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

const DemoActionButtons = (props) => {
  return (
    <div className="mt-5">
      <p className="sub-note semi-bold">
        Attach a supported image format before starting the demo
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip className="demo-supported-files">
              <ul>
                <li>JPEG (Joint Photographic Experts Group)</li>
                <li>PNG (Portable Network Graphics)</li>
                <li>GIF (Graphics Interchange Format)</li>
                <li>BMP (Bitmap)</li>
                <li>TIFF (Tagged Image File Format)</li>
                <li>RAW (Camera RAW Image)</li>
                <li>WebP (Web Picture format)</li>
                <li>SVG (Scalable Vector Graphics)</li>
                <li>HEIF / HEIC (High Efficiency Image Format)</li>
                <li>DICOM (Digital Imaging and Communications in Medicine)</li>
                <li>PGM (Portable Gray Map)</li>
                <li>PPM (Portable Pixmap)</li>
                <li>PBM (Portable Bitmap)</li>
                <li>HDR (High Dynamic Range)</li>
                <li>EXR (OpenEXR)</li>
              </ul>
            </Tooltip>
          }
          trigger={["hover", "focus", "click"]}
        >
          <img
            src="/images/question-gray.svg"
            className="ms-1 img-fluid"
            alt="question"
          />
        </OverlayTrigger>
      </p>
      <Button
        className="button w-100"
        onClick={() => props.setShowModelDemoStartModal(true)}
        disabled={props.startDemoDisabled}
        name="start"
        id="start"
        data-cy="start"
      >
        Start demo
      </Button>
      {(props.imageLandscape1 || props.imageLandscape2) && (
        <Button
          variant="outline-light"
          className="save-icon w-100 mt-2"
          onClick={props.handleClearBtn}
          name="clear"
          id="clear"
          data-cy="clear"
        >
          Clear
        </Button>
      )}
    </div>
  );
};

export default DemoChangeDetection;
