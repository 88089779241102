import axios from "axios";
import { IP_ADDRESS } from "common/constants";

/**
 * A module that manages dataset-related service
 * @module services/datasetServices
 */

const API_URL = IP_ADDRESS + "/api/dataset-profiles/";
const API_URL_2 = IP_ADDRESS + "/users/";
const API_URL_3 = IP_ADDRESS + "/api/user/dataset-profile-subscriptions/";
const API_URL_4 = IP_ADDRESS + "/api/dataset-files/";

/**
 * Add dataset information service that sends request and receives response data using axios
 *
 *
 * @method addDataset
 *
 * @param {string} datasetTitle - A string for the dataset title
 * @param {string} datasetDescription - A string for the dataset description
 *
 * @return {Promise}
 *
 */
const addDataset = (datasetTitle, datasetDescription) => {
  const params = {
    title: datasetTitle,
    description: datasetDescription,
  };

  return axios
    .post(API_URL, params, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * Edit dataset information service that sends request and receives response data using axios
 *
 *
 * @method editDataset
 *
 * @param {string} datasetTitle - A string for the dataset name
 * @param {string} datasetDescription - A string for the dataset description
 *
 * @return {Promise}
 *
 */
const editDataset = (datasetID, datasetTitle, datasetDescription) => {
  const params = {
    title: datasetTitle,
    description: datasetDescription,
  };

  return axios
    .put(API_URL + datasetID + "/", params, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * Delete dataset information service that sends request and receives response data using axios
 *
 *
 * @method deleteDataset
 *
 * @param {string} id - A string for identifying dataset
 *
 * @return {Promise}
 *
 */
const deleteDataset = (id) => {
  return axios
    .delete(API_URL + id, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * Get dataset information service that sends request and receives response data using axios
 *
 *
 * @method getDataset
 *
 * @param {string} id - A string for identifying dataset
 *
 * @return {Promise}
 *
 */
const getDataset = (id) => {
  return axios
    .get(API_URL + id, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * Get dataset version information service that sends request and receives response data using axios
 *
 *
 * @method getDatasetVersion
 *
 * @param {string} datasetProfileID - A string for identifying dataset profile
 * @param {string} datasetVersionID - A string for identifying dataset version
 *
 * @return {Promise}
 *
 */
const getDatasetVersion = (datasetProfileID, datasetVersionID) => {
  return axios
    .get(API_URL + datasetProfileID + "/dataset-versions/" + datasetVersionID, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * Get all dataset information service that sends request and receives response data using axios
 *
 *
 * @method getAllDatasets
 *
 * @return {Promise}
 *
 */
const getAllDatasets = () => {
  return axios
    .get(API_URL, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const deleteDatasetVersion = (datasetProfileID, datasetVersionID) => {
  return axios
    .delete(
      API_URL + datasetProfileID + "/dataset-versions/" + datasetVersionID,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

/**
 * Get all dataset information service that sends request and receives response data using axios
 *
 *
 * @method getAllDatasetVersions
 *
 * @return {Promise}
 *
 */
const getAllDatasetVersions = (datasetProfileID, filterParams) => {
  return axios
    .get(API_URL + datasetProfileID + "/dataset-versions/" + filterParams, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

// users/dataset_profiles/<int:profile_id>/
const getSubscribeDatasetProfile = (datasetProfileID) => {
  return axios
    .get(API_URL_2 + "dataset_profiles/" + datasetProfileID + "/", {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const editSubscribeDatasetProfile = (datasetProfileID) => {
  return axios
    .put(API_URL_2 + "dataset_profiles/" + datasetProfileID + "/", null, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const subscribeDatasetProfile = (datasetProfileID) => {
  return axios
    .put(API_URL_3 + datasetProfileID + "/", null, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const unsubscribeDatasetProfile = (datasetProfileID) => {
  return axios
    .delete(API_URL_3 + datasetProfileID + "/", {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getAllSubscribeDatasetProfile = () => {
  return axios
    .get(API_URL_3, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const addDatasetVersionSaveDraft = (
  datasetProfileID,
  datasetSubtitle,
  datasetVersionDescription,
  datasetLicense,
  datasetLicenseContent,
  datasetCitationDetails,
  datasetOtherRelevantInfo,
  datasetFile,
  datasetContributors,
  datasetKeywords,
  onUploadProgress,
  cancelToken
) => {
  var formData = new FormData();
  formData.append("dataset_profile", datasetProfileID);
  formData.append("subtitle", datasetSubtitle);
  formData.append("description", datasetVersionDescription);
  formData.append("license", datasetLicense);
  formData.append("license_content", datasetLicenseContent);
  formData.append("citation_details", datasetCitationDetails);
  formData.append("other_relevant_information", datasetOtherRelevantInfo);
  formData.append("status", "DRAFT");
  if (datasetContributors.length > 0) {
    datasetContributors.forEach((element, i) => {
      formData.append(`contributors`, element);
    });
  }
  if (datasetKeywords.length > 0) {
    datasetKeywords.forEach((element, i) => {
      formData.append(`keywords`, element);
    });
  }
  if (datasetFile.length > 0) {
    datasetFile.forEach((element, i) => {
      formData.append(`file_attachments[${i}]file_attachment`, element);
    });
  }

  return axios
    .post(API_URL + datasetProfileID + "/dataset-versions/", formData, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      onUploadProgress,
      cancelToken: cancelToken,
    })
    .then((response) => {
      return response.data;
    });
};

const addDatasetVersionSaveAndPublish = (
  datasetProfileID,
  datasetSubtitle,
  datasetVersionDescription,
  datasetLicense,
  datasetLicenseContent,
  datasetCitationDetails,
  datasetOtherRelevantInfo,
  datasetFile,
  datasetContributors,
  datasetKeywords,
  onUploadProgress,
  cancelToken
) => {
  var formData = new FormData();
  formData.append("dataset_profile", datasetProfileID);
  formData.append("subtitle", datasetSubtitle);
  formData.append("description", datasetVersionDescription);
  formData.append("license", datasetLicense);
  formData.append("license_content", datasetLicenseContent);
  formData.append("citation_details", datasetCitationDetails);
  formData.append("other_relevant_information", datasetOtherRelevantInfo);
  formData.append("status", "PUBLISHED");
  if (datasetContributors.length > 0) {
    datasetContributors.forEach((element, i) => {
      formData.append(`contributors`, element);
    });
  }
  if (datasetKeywords.length > 0) {
    datasetKeywords.forEach((element, i) => {
      formData.append(`keywords`, element);
    });
  }
  if (datasetFile.length > 0) {
    datasetFile.forEach((element, i) => {
      formData.append(`file_attachments[${i}]file_attachment`, element);
    });
  }

  return axios
    .post(API_URL + datasetProfileID + "/dataset-versions/", formData, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      onUploadProgress,
      cancelToken: cancelToken,
    })
    .then((response) => {
      return response.data;
    });
};

const uploadDatasetFile = (datasetFiles, onUploadProgress, cancelToken) => {
  var formData = new FormData();
  if (datasetFiles.length > 0) {
    datasetFiles.forEach((element, i) => {
      formData.append(`file_attachments[${i}]file_attachment`, element);
    });
  }
  return axios
    .post(API_URL_4 + "file-attachments/", formData, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      onUploadProgress,
      cancelToken: cancelToken,
    })
    .then((response) => {
      return response.data;
    });
};

const editDatasetVersionSaveDraft = (
  datasetProfileID,
  datasetVersionID,
  datasetSubtitle,
  datasetVersionDescription,
  datasetLicense,
  datasetLicenseContent,
  datasetCitationDetails,
  datasetOtherRelevantInfo,
  datasetFile,
  datasetContributors,
  datasetKeywords
) => {
  var formData = new FormData();
  formData.append("dataset_profile", datasetProfileID);
  formData.append("subtitle", datasetSubtitle);
  formData.append("description", datasetVersionDescription);
  formData.append("license", datasetLicense);
  formData.append("license_content", datasetLicenseContent);
  formData.append("citation_details", datasetCitationDetails);
  formData.append("other_relevant_information", datasetOtherRelevantInfo);
  formData.append("status", "DRAFT");
  // formData.append("file_attachments", datasetFile);
  if (datasetContributors.length > 0) {
    datasetContributors.forEach((element, i) => {
      formData.append(`contributors`, element);
    });
  }
  if (datasetKeywords.length > 0) {
    datasetKeywords.forEach((element, i) => {
      formData.append(`keywords`, element);
    });
  }
  if (datasetFile.length > 0) {
    datasetFile.forEach((element, i) => {
      formData.append(`file_attachments`, element);
    });
  }

  return axios
    .put(
      API_URL +
        datasetProfileID +
        "/dataset-versions/" +
        datasetVersionID +
        "/",
      formData,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const editDatasetVersionSaveAndPublish = (
  datasetProfileID,
  datasetVersionID,
  datasetSubtitle,
  datasetVersionDescription,
  datasetLicense,
  datasetLicenseContent,
  datasetCitationDetails,
  datasetOtherRelevantInfo,
  datasetFile,
  datasetContributors,
  datasetKeywords
) => {
  var formData = new FormData();
  formData.append("dataset_profile", datasetProfileID);
  formData.append("subtitle", datasetSubtitle);
  formData.append("description", datasetVersionDescription);
  formData.append("license", datasetLicense);
  formData.append("license_content", datasetLicenseContent);
  formData.append("citation_details", datasetCitationDetails);
  formData.append("other_relevant_information", datasetOtherRelevantInfo);
  formData.append("status", "PUBLISHED");
  if (datasetContributors.length > 0) {
    datasetContributors.forEach((element, i) => {
      formData.append(`contributors`, element);
    });
  }
  if (datasetKeywords.length > 0) {
    datasetKeywords.forEach((element, i) => {
      formData.append(`keywords`, element);
    });
  }
  if (datasetFile.length > 0) {
    datasetFile.forEach((element, i) => {
      formData.append(`file_attachments`, element);
    });
  }

  return axios
    .put(
      API_URL +
        datasetProfileID +
        "/dataset-versions/" +
        datasetVersionID +
        "/",
      formData,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getNextDatasetVersionNumber = (profileID) => {
  return axios
    .get(API_URL + profileID + "/next-version-id/", {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getAllKeywords = (profileID) => {
  return axios
    .get(API_URL + profileID + "/keywords/", {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const publishDatasetVersion = (datasetProfileID, datasetVersionID) => {
  const params = {
    status: "PUBLISHED",
  };
  return axios
    .patch(
      API_URL +
        datasetProfileID +
        "/dataset-versions/" +
        datasetVersionID +
        "/",
      params,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const archiveDatasetVersion = (
  datasetProfileID,
  datasetVersionID,
  archiveReason
) => {
  const params = {
    status: "ARCHIVED",
    reason: archiveReason,
  };
  return axios
    .patch(
      API_URL +
        datasetProfileID +
        "/dataset-versions/" +
        datasetVersionID +
        "/",
      params,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const duplicateDatasetVersion = (datasetProfileID, datasetVersionID) => {
  return axios
    .post(
      API_URL +
        datasetProfileID +
        "/dataset-versions/" +
        datasetVersionID +
        "/duplicates/",
      null,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

/**
 * Download dataset version file and pdf service that sends request and receives response data using axios
 *
 *
 * @method downloadDatasetVersion
 *
 * @param {string} datasetProfileID - A string for the dataset profile ID
 * @param {string} datasetVersionID - A string for the dataset version ID
 * @param {string} onDownloadProgress - A progress event that measures upload process
 * @param {string} cancelToken - A cancel the token of the current request in axios
 *
 * @return {Promise}
 *
 */
const downloadDatasetVersion = (
  datasetProfileID,
  datasetVersionID,
  onDownloadProgress,
  cancelToken
) => {
  return axios
    .post(
      API_URL +
        datasetProfileID +
        "/dataset-versions/" +
        datasetVersionID +
        "/downloads/",
      null,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        onDownloadProgress,
        cancelToken: cancelToken,
        responseType: "blob",
      }
    )
    .then((response) => {
      return response.data;
    });
};

const addLikes = (datasetProfileID, datasetVersionID) => {
  return axios
    .post(
      API_URL +
        datasetProfileID +
        "/dataset-versions/" +
        datasetVersionID +
        "/likes/",
      null,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteLikes = (datasetProfileID, datasetVersionID) => {
  return axios
    .delete(
      API_URL +
        datasetProfileID +
        "/dataset-versions/" +
        datasetVersionID +
        "/likes/",
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const datasetServices = {
  addDataset,
  editDataset,
  deleteDataset,
  getDataset,
  deleteDatasetVersion,
  getDatasetVersion,
  getAllDatasets,
  getAllDatasetVersions,
  editSubscribeDatasetProfile,
  getAllSubscribeDatasetProfile,
  getSubscribeDatasetProfile,
  addDatasetVersionSaveDraft,
  addDatasetVersionSaveAndPublish,
  uploadDatasetFile,
  editDatasetVersionSaveDraft,
  editDatasetVersionSaveAndPublish,
  getNextDatasetVersionNumber,
  getAllKeywords,
  subscribeDatasetProfile,
  unsubscribeDatasetProfile,
  publishDatasetVersion,
  archiveDatasetVersion,
  duplicateDatasetVersion,
  downloadDatasetVersion,
  addLikes,
  deleteLikes,
};

export default datasetServices;
