import axios from "axios";
import {
  convertDate,
  IP_ADDRESS,
  IP_ADDRESS_2,
  IP_ADDRESS_3,
  IP_ADDRESS_4,
} from "common/constants";

/**
 * A module that manages model-related service
 * @module services/modelServices
 */

const API_URL = IP_ADDRESS + "/api/feature/";
const API_URL_2 = IP_ADDRESS_4 + "/api/infer/";
const API_URL_3 = IP_ADDRESS_2 + "/api/object-detection/";
const API_URL_4 = IP_ADDRESS_3 + "/";

/**
 * Add model information service that sends request and receives response data using axios
 *
 *
 * @method addModel
 *
 * @param {string} modelName - A string for the model name
 * @param {string} modelTask - A string for the model task
 * @param {string} modelDescription - A string for the model description
 * @param {string} modelGroup - An object to list down group of the model
 *
 * @return {Promise}
 *
 */
const addModel = (
  modelName,
  modelTask,
  modelDescription,
  modelGroup,
  modelPrimaryIntendedUses,
  modelPrimaryIntendedUsers,
  modelOutOfScopeUseCases,
  modelGroups,
  modelInstrumentation,
  modelEnvironment,
  modelPerformanceMeasures,
  modelDecisionThresholds,
  modelApproachesToUncertainty,
  modelData,
  modelHumanLife,
  modelMitigations,
  modelRisksAndHarms,
  modelUseCases
) => {
  const params = {
    model_profile_name: modelName,
    model_profile_task: modelTask,
    model_profile_description: modelDescription,
    model_profile_group: modelGroup,
    primary_intended_uses: modelPrimaryIntendedUses,
    primary_intended_user: modelPrimaryIntendedUsers,
    out_of_scope_use_cases: modelOutOfScopeUseCases,
    groups: modelGroups,
    instrumentation: modelInstrumentation,
    environment: modelEnvironment,
    performance_measures: modelPerformanceMeasures,
    decision_thresholds: modelDecisionThresholds,
    approaches_to_uncertainty: modelApproachesToUncertainty,
    data: modelData,
    human_life: modelHumanLife,
    mitigations: modelMitigations,
    risks_and_harms: modelRisksAndHarms,
    use_cases: modelUseCases,
  };

  return axios
    .post(API_URL + "create/model/profile", params, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * Edit model information service that sends request and receives response data using axios
 *
 *
 * @method editModel
 *
 * @param {string} modelName - A string for the model name
 * @param {string} modelTask - A string for the model task
 * @param {string} modelDescription - A string for the model description
 * @param {string} modelGroup - An object to list down group of the model
 *
 * @return {Promise}
 *
 */
const editModel = (
  modelID,
  modelName,
  modelTask,
  modelDescription,
  modelGroup,
  modelPrimaryIntendedUses,
  modelPrimaryIntendedUsers,
  modelOutOfScopeUseCases,
  modelGroups,
  modelInstrumentation,
  modelEnvironment,
  modelPerformanceMeasures,
  modelDecisionThresholds,
  modelApproachesToUncertainty,
  modelData,
  modelHumanLife,
  modelMitigations,
  modelRisksAndHarms,
  modelUseCases
) => {
  const params = {
    model_profile_name: modelName,
    model_profile_task: modelTask,
    model_profile_description: modelDescription,
    model_profile_group: modelGroup,
    primary_intended_uses: modelPrimaryIntendedUses,
    primary_intended_user: modelPrimaryIntendedUsers,
    out_of_scope_use_cases: modelOutOfScopeUseCases,
    groups: modelGroups,
    instrumentation: modelInstrumentation,
    environment: modelEnvironment,
    performance_measures: modelPerformanceMeasures,
    decision_thresholds: modelDecisionThresholds,
    approaches_to_uncertainty: modelApproachesToUncertainty,
    data: modelData,
    human_life: modelHumanLife,
    mitigations: modelMitigations,
    risks_and_harms: modelRisksAndHarms,
    use_cases: modelUseCases,
  };

  return axios
    .put(API_URL + "edit/model/" + modelID, params, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const editModelVersion = (
  modelFileID,
  modelProfileID,
  modelNumber,
  modelVersionName,
  modelVersionDescription,
  modelContact,
  modelDevelopmentDate,
  modelDevelopers,
  modelAccuracy,
  modelInput,
  modelOutput,
  modelType,
  modelPaper,
  modelCitationDetails,
  modelLicense,
  modelLicenseContent,
  modelOtherRelevantInfo,
  modelTrainingDataset,
  modelTrainingMotivation,
  modelTrainingPreprocessing,
  modelEvaluationDataset,
  modelEvaluationMotivation,
  modelEvaluationPreprocessing,
  modelUnitaryResults,
  modelIntersectionalResults,
  modelDetails
) => {
  const params = {
    version: modelNumber,
    name: modelVersionName,
    contact_information: modelContact,
    description: modelVersionDescription,
    development_date: convertDate(modelDevelopmentDate, "YYYY-MM-DD"),
    developers: modelDevelopers,
    input: modelInput,
    output: modelOutput,
    type: modelType,
    paper: modelPaper,
    license: modelLicense,
    license_content: modelLicenseContent,
    accuracy: modelAccuracy,
    citation_details: modelCitationDetails,
    other_relevant_information: modelOtherRelevantInfo,
    td_datasets: modelTrainingDataset,
    td_motivation: modelTrainingMotivation,
    td_preprocessing: modelTrainingPreprocessing,
    ed_datasets: modelEvaluationDataset,
    ed_motivation: modelEvaluationMotivation,
    ed_preprocessing: modelEvaluationPreprocessing,
    unitary_result: modelUnitaryResults,
    intersectional_result: modelIntersectionalResults,
    details: modelDetails,
  };

  return axios
    .put(API_URL + "edit/model/version/" + modelFileID, params, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const addModelVersionSaveDraft = (
  modelFileID,
  modelProfileID,
  modelNumber,
  modelVersionName,
  modelVersionDescription,
  modelContact,
  modelDevelopmentDate,
  modelDevelopers,
  modelAccuracy,
  modelInput,
  modelOutput,
  modelType,
  modelPaper,
  modelCitationDetails,
  modelLicense,
  modelLicenseContent,
  modelOtherRelevantInfo,
  modelTrainingDataset,
  modelTrainingMotivation,
  modelTrainingPreprocessing,
  modelEvaluationDataset,
  modelEvaluationMotivation,
  modelEvaluationPreprocessing,
  modelUnitaryResults,
  modelIntersectionalResults,
  modelDetails
) => {
  const params = {
    version: modelNumber,
    name: modelVersionName,
    contact_information: modelContact,
    description: modelVersionDescription,
    development_date: convertDate(modelDevelopmentDate, "YYYY-MM-DD"),
    developers: modelDevelopers,
    input: modelInput,
    output: modelOutput,
    type: modelType,
    paper: modelPaper,
    license: modelLicense,
    license_content: modelLicenseContent,
    accuracy: modelAccuracy,
    citation_details: modelCitationDetails,
    other_relevant_information: modelOtherRelevantInfo,
    td_datasets: modelTrainingDataset,
    td_motivation: modelTrainingMotivation,
    td_preprocessing: modelTrainingPreprocessing,
    ed_datasets: modelEvaluationDataset,
    ed_motivation: modelEvaluationMotivation,
    ed_preprocessing: modelEvaluationPreprocessing,
    unitary_result: modelUnitaryResults,
    intersectional_result: modelIntersectionalResults,
    details: modelDetails,
  };

  return axios
    .post(
      API_URL + "draft/model/version/" + modelProfileID + "/" + modelFileID,
      params,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const addModelVersionSaveAndPublish = (
  modelFileID,
  modelProfileID,
  modelNumber,
  modelVersionName,
  modelVersionDescription,
  modelContact,
  modelDevelopmentDate,
  modelDevelopers,
  modelAccuracy,
  modelInput,
  modelOutput,
  modelType,
  modelPaper,
  modelCitationDetails,
  modelLicense,
  modelLicenseContent,
  modelOtherRelevantInfo,
  modelTrainingDataset,
  modelTrainingMotivation,
  modelTrainingPreprocessing,
  modelEvaluationDataset,
  modelEvaluationMotivation,
  modelEvaluationPreprocessing,
  modelUnitaryResults,
  modelIntersectionalResults,
  modelDetails
) => {
  const params = {
    model_profile: modelProfileID,
    version: modelNumber,
    name: modelVersionName,
    contact_information: modelContact,
    description: modelVersionDescription,
    development_date: convertDate(modelDevelopmentDate, "YYYY-MM-DD"),
    developers: modelDevelopers,
    input: modelInput,
    output: modelOutput,
    type: modelType,
    paper: modelPaper,
    license: modelLicense,
    license_content: modelLicenseContent,
    accuracy: modelAccuracy,
    citation_details: modelCitationDetails,
    other_relevant_information: modelOtherRelevantInfo,
    td_datasets: modelTrainingDataset,
    td_motivation: modelTrainingMotivation,
    td_preprocessing: modelTrainingPreprocessing,
    ed_datasets: modelEvaluationDataset,
    ed_motivation: modelEvaluationMotivation,
    ed_preprocessing: modelEvaluationPreprocessing,
    unitary_result: modelUnitaryResults,
    intersectional_result: modelIntersectionalResults,
    details: modelDetails,
  };

  return axios
    .post(API_URL + "add/model/details/" + modelFileID, params, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const uploadModelFile = (
  modelProfileID,
  file,
  onUploadProgress,
  cancelToken
) => {
  var formData = new FormData();
  file.forEach((element) => {
    formData.append("file", element);
  });
  return axios
    .post(API_URL + "upload/model/version/" + modelProfileID, formData, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      onUploadProgress,
      cancelToken: cancelToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

const editUploadModelFile = (
  modelFileID,
  file,
  onUploadProgress,
  cancelToken
) => {
  var formData = new FormData();

  file.forEach((element) => {
    if (element instanceof File) {
      formData.append(`file`, element);
    } else {
      const jsonBlob = new Blob([JSON.stringify(element)], {
        type: "application/json",
      });
      formData.append(`file`, jsonBlob, element.name);
    }
  });

  return axios
    .put(API_URL + "edit/file/upload/" + modelFileID, formData, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      onUploadProgress,
      cancelToken: cancelToken,
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * Delete model information service that sends request and receives response data using axios
 *
 *
 * @method deleteModel
 *
 * @param {string} id - A string for identifying model
 *
 * @return {Promise}
 *
 */
const deleteModel = (id) => {
  return axios
    .delete(API_URL + "delete/model/profile/" + id, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const publishModelVersion = (modelFileID) => {
  return axios
    .put(API_URL + "publish/model/version/" + modelFileID, null, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const archiveModelVersion = (modelFileID, archiveReason) => {
  return axios
    .put(
      API_URL + "archive/model/" + modelFileID,
      { is_archive: true, archive_reason: archiveReason },
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteModelVersion = (modelFileID) => {
  return axios
    .delete(API_URL + "delete/model/version/" + modelFileID, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const duplicateModelVersion = (modelProfileID, modelFileID) => {
  return axios
    .post(
      API_URL + "duplicate/model/version/" + modelProfileID + "/" + modelFileID,
      null,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

/**
 * Get model information service that sends request and receives response data using axios
 *
 *
 * @method getModel
 *
 * @param {string} id - A string for identifying model
 *
 * @return {Promise}
 *
 */
const getModel = (id) => {
  return axios
    .get(API_URL + "view/model/profile/" + id, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * Get model version information service that sends request and receives response data using axios
 *
 *
 * @method getModelVersion
 *
 * @param {string} id - A string for identifying model version
 *
 * @return {Promise}
 *
 */
const getModelVersion = (id) => {
  return axios
    .get(API_URL + "view/model/version/details/" + id, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * Get all models information service that sends request and receives response data using axios
 *
 *
 * @method getAllModels
 *
 * @return {Promise}
 *
 */
const getAllModels = () => {
  return axios
    .get(API_URL + "view/all/model/profile", {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * Get all model versions information service that sends request and receives response data using axios
 *
 *
 * @method getAllModelVersions
 *
 * @return {Promise}
 *
 */
const getAllModelVersions = (modelProfileID) => {
  return axios
    .get(API_URL + "view/model/version/" + modelProfileID, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * Get all accuracy information service that sends request and receives response data using axios
 *
 *
 * @method getAllAccuracy
 *
 * @return {Promise}
 *
 */
const getAllAccuracy = (modelProfileID) => {
  return axios
    .get(API_URL + "get/model/accuracy/" + modelProfileID, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * Download model version file and pdf service that sends request and receives response data using axios
 *
 *
 * @method downloadModelVersion
 *
 * @param {string} id - A string for identifying model version
 *
 * @return {Promise}
 *
 */
const downloadModelVersion = (modelFileID, onDownloadProgress, cancelToken) => {
  return axios
    .get(API_URL + "download/model/version/" + modelFileID, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      onDownloadProgress,
      cancelToken: cancelToken,
      responseType: "blob",
    })
    .then((response) => {
      return response.data;
    });
};

const editSubscribeModelProfile = (modelProfileID) => {
  // const data = {
  //   subscribe_model_profile: subsArr,
  // };
  return axios
    .post(API_URL + "subscribe/model_profile/" + modelProfileID, null, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getAllSubscribeModelProfile = () => {
  return axios
    .get(API_URL + "getall/subscribe/model_profile", {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getSubscribeModelProfile = (modelProfileID) => {
  return axios
    .get(API_URL + "get/subscribe/models/" + modelProfileID, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getGroupMembers = (id) => {
  return axios
    .get(API_URL + "get/group/members/" + id, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const addDownloads = (modelFileID) => {
  return axios
    .post(API_URL + "download/counter/" + modelFileID, null, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getLikes = (modelFileID) => {
  return axios
    .post(
      API_URL + "like/model/" + modelFileID,
      {},
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const inferenceChangeDetectionModel = (
  file1,
  file2,
  modelFile,
  weightFile,
  cancelToken
) => {
  var formData = new FormData();
  formData.append("before_image", file1);
  formData.append("after_image", file2);
  formData.append("model", modelFile);
  formData.append("weight", weightFile);
  return axios
    .post(API_URL_2 + "change/detection/", formData, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      return response.data;
    });
};

const inferenceObjectDetectionModel = (
  inputFile,
  modelWeightsFile,
  confidence,
  iou,
  imageSize,
  coordinates,
  cancelToken
) => {
  var formData = new FormData();
  formData.append("input_file", inputFile);
  formData.append("model_weights_file", modelWeightsFile);
  formData.append(
    "args",
    JSON.stringify({
      confidence: confidence,
      iou: iou,
      imageSize: imageSize,
      xyxy: coordinates,
    })
  );
  // formData.append(coordinates);
  return axios
    .post(API_URL_3, formData, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      return response.data;
    });
  // return fetch(API_URL_3, {
  //   body: formData,
  //   method: "post",
  //   headers: {
  //     Authorization: `JWT ${localStorage.getItem("access")}`,
  //   },
  //   cancelToken: cancelToken,
  // }).then((response) => {
  //   // console.log(response);
  //   return response;
  // });
};

const inferenceBuildingDelineationModel = (
  originalImage,
  modelWeight,
  isGrayscale,
  contourSize,
  rgb,
  cancelToken
) => {
  var formData = new FormData();
  formData.append("original_image", originalImage);
  formData.append("model_weight", modelWeight);
  formData.append("is_grayscale", isGrayscale);
  formData.append("contour_size", contourSize);
  formData.append("rgb", rgb);
  return axios
    .post(API_URL_4 + "infer/", formData, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      return response.data;
    })
};

const modelServices = {
  addModel,
  editModel,
  editModelVersion,
  publishModelVersion,
  archiveModelVersion,
  deleteModelVersion,
  duplicateModelVersion,
  addModelVersionSaveDraft,
  addModelVersionSaveAndPublish,
  editUploadModelFile,
  uploadModelFile,
  deleteModel,
  getModel,
  getModelVersion,
  getAllModels,
  getAllModelVersions,
  getAllAccuracy,
  downloadModelVersion,
  editSubscribeModelProfile,
  getAllSubscribeModelProfile,
  getSubscribeModelProfile,
  getGroupMembers,
  getLikes,
  addDownloads,
  inferenceChangeDetectionModel,
  inferenceObjectDetectionModel,
  inferenceBuildingDelineationModel,
};

export default modelServices;
