import ReactDOMServer from "react-dom/server";

const getLicense = (license) => {
  switch (license) {
    case "1": {
      // CC0-1.0
      return (
        <>
          <p>No Copyright</p>
          <p>
            The person who associated a work with this deed has dedicated the
            work to the public domain by waiving all of his or her rights to the
            work worldwide under copyright law, including all related and
            neighboring rights, to the extent allowed by law.
          </p>
          <p>
            You can copy, modify, distribute and perform the work, even for
            commercial purposes, all without asking permission. See Other
            Information below.
          </p>
          <p>
            <br />
          </p>
          <p>Other Information</p>
          <p>
            In no way are the patent or trademark rights of any person affected
            by CC0, nor are the rights that other persons may have in the work
            or in how the work is used, such as publicity or privacy rights.
          </p>
          <p>
            Unless expressly stated otherwise, the person who associated a work
            with this deed makes no warranties about the work, and disclaims
            liability for all uses of the work, to the fullest extent permitted
            by applicable law.
          </p>
          <p>
            When using or citing the work, you should not imply endorsement by
            the author or the affirmer.
          </p>
        </>
      );
    }
    case "2": {
      // CC-BY-SA-3.0
      return (
        <>
          <p>You are free to:</p>
          <p>
            Share &mdash; copy and redistribute the material in any medium or
            format for any purpose, even commercially.
          </p>
          <p>
            Adapt &mdash; remix, transform, and build upon the material for any
            purpose, even commercially.
          </p>
          <p>
            The licensor cannot revoke these freedoms as long as you follow the
            license terms.
          </p>
          <p>
            <br />
          </p>
          <p>Under the following terms:</p>
          <p>
            Attribution &mdash; You must give appropriate credit , provide a
            link to the license, and indicate if changes were made . You may do
            so in any reasonable manner, but not in any way that suggests the
            licensor endorses you or your use.
          </p>
          <p>
            ShareAlike &mdash; If you remix, transform, or build upon the
            material, you must distribute your contributions under the same
            license as the original.
          </p>
          <p>
            No additional restrictions &mdash; You may not apply legal terms or
            technological measures that legally restrict others from doing
            anything the license permits.
          </p>
          <p>
            <br />
          </p>
          <p>Notices:</p>
          <p>
            You do not have to comply with the license for elements of the
            material in the public domain or where your use is permitted by an
            applicable exception or limitation .
          </p>
          <p>
            No warranties are given. The license may not give you all of the
            permissions necessary for your intended use. For example, other
            rights such as publicity, privacy, or moral rights may limit how you
            use the material.
          </p>
        </>
      );
    }
    case "3": {
      // CC-BY-SA-4.0
      return (
        <>
          <p>You are free to:</p>
          <p>
            Share &mdash; copy and redistribute the material in any medium or
            format for any purpose, even commercially.
          </p>
          <p>
            Adapt &mdash; remix, transform, and build upon the material for any
            purpose, even commercially.
          </p>
          <p>
            The licensor cannot revoke these freedoms as long as you follow the
            license terms.
          </p>
          <p>
            <br />
          </p>
          <p>Under the following terms:</p>
          <p>
            Attribution &mdash; You must give appropriate credit , provide a
            link to the license, and indicate if changes were made . You may do
            so in any reasonable manner, but not in any way that suggests the
            licensor endorses you or your use.
          </p>
          <p>
            ShareAlike &mdash; If you remix, transform, or build upon the
            material, you must distribute your contributions under the same
            license as the original.
          </p>
          <p>
            No additional restrictions &mdash; You may not apply legal terms or
            technological measures that legally restrict others from doing
            anything the license permits.
          </p>
          <p>
            <br />
          </p>
          <p>Notices:</p>
          <p>
            You do not have to comply with the license for elements of the
            material in the public domain or where your use is permitted by an
            applicable exception or limitation .
          </p>
          <p>
            No warranties are given. The license may not give you all of the
            permissions necessary for your intended use. For example, other
            rights such as publicity, privacy, or moral rights may limit how you
            use the material.
          </p>
        </>
      );
    }
    case "4": {
      // CC-BY-NC-SA-4.0
      return (
        <>
          <p>You are free to:</p>
          <p>
            Share &mdash; copy and redistribute the material in any medium or
            format
          </p>
          <p>Adapt &mdash; remix, transform, and build upon the material</p>
          <p>
            The licensor cannot revoke these freedoms as long as you follow the
            license terms.
          </p>
          <p>
            <br />
          </p>
          <p>Under the following terms:</p>
          <p>
            Attribution &mdash; You must give appropriate credit , provide a
            link to the license, and indicate if changes were made . You may do
            so in any reasonable manner, but not in any way that suggests the
            licensor endorses you or your use.
          </p>
          <p>
            NonCommercial &mdash; You may not use the material for commercial
            purposes .
          </p>
          <p>
            ShareAlike &mdash; If you remix, transform, or build upon the
            material, you must distribute your contributions under the same
            license as the original.
          </p>
          <p>
            No additional restrictions &mdash; You may not apply legal terms or
            technological measures that legally restrict others from doing
            anything the license permits.
          </p>
          <p>
            <br />
          </p>
          <p>Notices:</p>
          <p>
            You do not have to comply with the license for elements of the
            material in the public domain or where your use is permitted by an
            applicable exception or limitation .
          </p>
          <p>
            No warranties are given. The license may not give you all of the
            permissions necessary for your intended use. For example, other
            rights such as publicity, privacy, or moral rights may limit how you
            use the material.
          </p>
        </>
      );
    }
    case "5": {
      // GPL-2.0
      return (
        <>
          <p>Version 2, June 1991</p>
          <p>Copyright (C) 1989, 1991 Free Software Foundation, Inc. &nbsp;</p>
          <p>
            51 Franklin Street, Fifth Floor, Boston, MA &nbsp;02110-1301, USA
          </p>
          <p>Everyone is permitted to copy and distribute verbatim copies</p>
          <p>of this license document, but changing it is not allowed.</p>
          <p>Preamble</p>
          <p>
            The licenses for most software are designed to take away your
            freedom to share and change it. By contrast, the GNU General Public
            License is intended to guarantee your freedom to share and change
            free software--to make sure the software is free for all its users.
            This General Public License applies to most of the Free Software
            Foundation&apos;s software and to any other program whose authors
            commit to using it. (Some other Free Software Foundation software is
            covered by the GNU Lesser General Public License instead.) You can
            apply it to your programs, too.
          </p>
          <p>
            When we speak of free software, we are referring to freedom, not
            price. Our General Public Licenses are designed to make sure that
            you have the freedom to distribute copies of free software (and
            charge for this service if you wish), that you receive source code
            or can get it if you want it, that you can change the software or
            use pieces of it in new free programs; and that you know you can do
            these things.
          </p>
          <p>
            To protect your rights, we need to make restrictions that forbid
            anyone to deny you these rights or to ask you to surrender the
            rights. These restrictions translate to certain responsibilities for
            you if you distribute copies of the software, or if you modify it.
          </p>
          <p>
            For example, if you distribute copies of such a program, whether
            gratis or for a fee, you must give the recipients all the rights
            that you have. You must make sure that they, too, receive or can get
            the source code. And you must show them these terms so they know
            their rights.
          </p>
          <p>
            We protect your rights with two steps: (1) copyright the software,
            and (2) offer you this license which gives you legal permission to
            copy, distribute and/or modify the software.
          </p>
          <p>
            Also, for each author&apos;s protection and ours, we want to make
            certain that everyone understands that there is no warranty for this
            free software. If the software is modified by someone else and
            passed on, we want its recipients to know that what they have is not
            the original, so that any problems introduced by others will not
            reflect on the original authors&apos; reputations.
          </p>
          <p>
            Finally, any free program is threatened constantly by software
            patents. We wish to avoid the danger that redistributors of a free
            program will individually obtain patent licenses, in effect making
            the program proprietary. To prevent this, we have made it clear that
            any patent must be licensed for everyone&apos;s free use or not
            licensed at all.
          </p>
          <p>
            The precise terms and conditions for copying, distribution and
            modification follow.
          </p>
          <p>TERMS AND CONDITIONS FOR COPYING, DISTRIBUTION AND MODIFICATION</p>
          <p>
            0. This License applies to any program or other work which contains
            a notice placed by the copyright holder saying it may be distributed
            under the terms of this General Public License. The
            &quot;Program&quot;, below, refers to any such program or work, and
            a &quot;work based on the Program&quot; means either the Program or
            any derivative work under copyright law: that is to say, a work
            containing the Program or a portion of it, either verbatim or with
            modifications and/or translated into another language. (Hereinafter,
            translation is included without limitation in the term
            &quot;modification&quot;.) Each licensee is addressed as
            &quot;you&quot;.
          </p>
          <p>
            Activities other than copying, distribution and modification are not
            covered by this License; they are outside its scope. The act of
            running the Program is not restricted, and the output from the
            Program is covered only if its contents constitute a work based on
            the Program (independent of having been made by running the
            Program). Whether that is true depends on what the Program does.
          </p>
          <p>
            1. You may copy and distribute verbatim copies of the Program&apos;s
            source code as you receive it, in any medium, provided that you
            conspicuously and appropriately publish on each copy an appropriate
            copyright notice and disclaimer of warranty; keep intact all the
            notices that refer to this License and to the absence of any
            warranty; and give any other recipients of the Program a copy of
            this License along with the Program.
          </p>
          <p>
            You may charge a fee for the physical act of transferring a copy,
            and you may at your option offer warranty protection in exchange for
            a fee.
          </p>
          <p>
            2. You may modify your copy or copies of the Program or any portion
            of it, thus forming a work based on the Program, and copy and
            distribute such modifications or work under the terms of Section 1
            above, provided that you also meet all of these conditions:
          </p>
          <p>
            a) You must cause the modified files to carry prominent notices
            stating that you changed the files and the date of any change.
          </p>
          <p>
            b) You must cause any work that you distribute or publish, that in
            whole or in part contains or is derived from the Program or any part
            thereof, to be licensed as a whole at no charge to all third parties
            under the terms of this License.
          </p>
          <p>
            c) If the modified program normally reads commands interactively
            when run, you must cause it, when started running for such
            interactive use in the most ordinary way, to print or display an
            announcement including an appropriate copyright notice and a notice
            that there is no warranty (or else, saying that you provide a
            warranty) and that users may redistribute the program under these
            conditions, and telling the user how to view a copy of this License.
            (Exception: if the Program itself is interactive but does not
            normally print such an announcement, your work based on the Program
            is not required to print an announcement.)
          </p>
          <p>
            These requirements apply to the modified work as a whole. If
            identifiable sections of that work are not derived from the Program,
            and can be reasonably considered independent and separate works in
            themselves, then this License, and its terms, do not apply to those
            sections when you distribute them as separate works. But when you
            distribute the same sections as part of a whole which is a work
            based on the Program, the distribution of the whole must be on the
            terms of this License, whose permissions for other licensees extend
            to the entire whole, and thus to each and every part regardless of
            who wrote it.
          </p>
          <p>
            Thus, it is not the intent of this section to claim rights or
            contest your rights to work written entirely by you; rather, the
            intent is to exercise the right to control the distribution of
            derivative or collective works based on the Program.
          </p>
          <p>
            In addition, mere aggregation of another work not based on the
            Program with the Program (or with a work based on the Program) on a
            volume of a storage or distribution medium does not bring the other
            work under the scope of this License.
          </p>
          <p>
            3. You may copy and distribute the Program (or a work based on it,
            under Section 2) in object code or executable form under the terms
            of Sections 1 and 2 above provided that you also do one of the
            following:
          </p>
          <p>
            a) Accompany it with the complete corresponding machine-readable
            source code, which must be distributed under the terms of Sections 1
            and 2 above on a medium customarily used for software interchange;
            or,
          </p>
          <p>
            b) Accompany it with a written offer, valid for at least three
            years, to give any third party, for a charge no more than your cost
            of physically performing source distribution, a complete
            machine-readable copy of the corresponding source code, to be
            distributed under the terms of Sections 1 and 2 above on a medium
            customarily used for software interchange; or,
          </p>
          <p>
            c) Accompany it with the information you received as to the offer to
            distribute corresponding source code. (This alternative is allowed
            only for noncommercial distribution and only if you received the
            program in object code or executable form with such an offer, in
            accord with Subsection b above.)
          </p>
          <p>
            The source code for a work means the preferred form of the work for
            making modifications to it. For an executable work, complete source
            code means all the source code for all modules it contains, plus any
            associated interface definition files, plus the scripts used to
            control compilation and installation of the executable. However, as
            a special exception, the source code distributed need not include
            anything that is normally distributed (in either source or binary
            form) with the major components (compiler, kernel, and so on) of the
            operating system on which the executable runs, unless that component
            itself accompanies the executable.
          </p>
          <p>
            If distribution of executable or object code is made by offering
            access to copy from a designated place, then offering equivalent
            access to copy the source code from the same place counts as
            distribution of the source code, even though third parties are not
            compelled to copy the source along with the object code.
          </p>
          <p>
            4. You may not copy, modify, sublicense, or distribute the Program
            except as expressly provided under this License. Any attempt
            otherwise to copy, modify, sublicense or distribute the Program is
            void, and will automatically terminate your rights under this
            License. However, parties who have received copies, or rights, from
            you under this License will not have their licenses terminated so
            long as such parties remain in full compliance.
          </p>
          <p>
            5. You are not required to accept this License, since you have not
            signed it. However, nothing else grants you permission to modify or
            distribute the Program or its derivative works. These actions are
            prohibited by law if you do not accept this License. Therefore, by
            modifying or distributing the Program (or any work based on the
            Program), you indicate your acceptance of this License to do so, and
            all its terms and conditions for copying, distributing or modifying
            the Program or works based on it.
          </p>
          <p>
            6. Each time you redistribute the Program (or any work based on the
            Program), the recipient automatically receives a license from the
            original licensor to copy, distribute or modify the Program subject
            to these terms and conditions. You may not impose any further
            restrictions on the recipients&apos; exercise of the rights granted
            herein. You are not responsible for enforcing compliance by third
            parties to this License.
          </p>
          <p>
            7. If, as a consequence of a court judgment or allegation of patent
            infringement or for any other reason (not limited to patent issues),
            conditions are imposed on you (whether by court order, agreement or
            otherwise) that contradict the conditions of this License, they do
            not excuse you from the conditions of this License. If you cannot
            distribute so as to satisfy simultaneously your obligations under
            this License and any other pertinent obligations, then as a
            consequence you may not distribute the Program at all. For example,
            if a patent license would not permit royalty-free redistribution of
            the Program by all those who receive copies directly or indirectly
            through you, then the only way you could satisfy both it and this
            License would be to refrain entirely from distribution of the
            Program.
          </p>
          <p>
            If any portion of this section is held invalid or unenforceable
            under any particular circumstance, the balance of the section is
            intended to apply and the section as a whole is intended to apply in
            other circumstances.
          </p>
          <p>
            It is not the purpose of this section to induce you to infringe any
            patents or other property right claims or to contest validity of any
            such claims; this section has the sole purpose of protecting the
            integrity of the free software distribution system, which is
            implemented by public license practices. Many people have made
            generous contributions to the wide range of software distributed
            through that system in reliance on consistent application of that
            system; it is up to the author/donor to decide if he or she is
            willing to distribute software through any other system and a
            licensee cannot impose that choice.
          </p>
          <p>
            This section is intended to make thoroughly clear what is believed
            to be a consequence of the rest of this License.
          </p>
          <p>
            8. If the distribution and/or use of the Program is restricted in
            certain countries either by patents or by copyrighted interfaces,
            the original copyright holder who places the Program under this
            License may add an explicit geographical distribution limitation
            excluding those countries, so that distribution is permitted only in
            or among countries not thus excluded. In such case, this License
            incorporates the limitation as if written in the body of this
            License.
          </p>
          <p>
            9. The Free Software Foundation may publish revised and/or new
            versions of the General Public License from time to time. Such new
            versions will be similar in spirit to the present version, but may
            differ in detail to address new problems or concerns.
          </p>
          <p>
            Each version is given a distinguishing version number. If the
            Program specifies a version number of this License which applies to
            it and &quot;any later version&quot;, you have the option of
            following the terms and conditions either of that version or of any
            later version published by the Free Software Foundation. If the
            Program does not specify a version number of this License, you may
            choose any version ever published by the Free Software Foundation.
          </p>
          <p>
            10. If you wish to incorporate parts of the Program into other free
            programs whose distribution conditions are different, write to the
            author to ask for permission. For software which is copyrighted by
            the Free Software Foundation, write to the Free Software Foundation;
            we sometimes make exceptions for this. Our decision will be guided
            by the two goals of preserving the free status of all derivatives of
            our free software and of promoting the sharing and reuse of software
            generally.
          </p>
          <p>NO WARRANTY</p>
          <p>
            11. BECAUSE THE PROGRAM IS LICENSED FREE OF CHARGE, THERE IS NO
            WARRANTY FOR THE PROGRAM, TO THE EXTENT PERMITTED BY APPLICABLE LAW.
            EXCEPT WHEN OTHERWISE STATED IN WRITING THE COPYRIGHT HOLDERS AND/OR
            OTHER PARTIES PROVIDE THE PROGRAM &quot;AS IS&quot; WITHOUT WARRANTY
            OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED
            TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
            PARTICULAR PURPOSE. THE ENTIRE RISK AS TO THE QUALITY AND
            PERFORMANCE OF THE PROGRAM IS WITH YOU. SHOULD THE PROGRAM PROVE
            DEFECTIVE, YOU ASSUME THE COST OF ALL NECESSARY SERVICING, REPAIR OR
            CORRECTION.
          </p>
          <p>
            12. IN NO EVENT UNLESS REQUIRED BY APPLICABLE LAW OR AGREED TO IN
            WRITING WILL ANY COPYRIGHT HOLDER, OR ANY OTHER PARTY WHO MAY MODIFY
            AND/OR REDISTRIBUTE THE PROGRAM AS PERMITTED ABOVE, BE LIABLE TO YOU
            FOR DAMAGES, INCLUDING ANY GENERAL, SPECIAL, INCIDENTAL OR
            CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE THE
            PROGRAM (INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR DATA BEING
            RENDERED INACCURATE OR LOSSES SUSTAINED BY YOU OR THIRD PARTIES OR A
            FAILURE OF THE PROGRAM TO OPERATE WITH ANY OTHER PROGRAMS), EVEN IF
            SUCH HOLDER OR OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES.
          </p>
          <p>END OF TERMS AND CONDITIONS</p>
          <p>How to Apply These Terms to Your New Programs</p>
          <p>
            If you develop a new program, and you want it to be of the greatest
            possible use to the public, the best way to achieve this is to make
            it free software which everyone can redistribute and change under
            these terms.
          </p>
          <p>
            To do so, attach the following notices to the program. It is safest
            to attach them to the start of each source file to most effectively
            convey the exclusion of warranty; and each file should have at least
            the &quot;copyright&quot; line and a pointer to where the full
            notice is found.
          </p>
          <p>
            one line to give the program&apos;s name and an idea of what it
            does.
          </p>
          <p>Copyright (C) yyyy &nbsp;name of author</p>
          <p>This program is free software; you can redistribute it and/or</p>
          <p>modify it under the terms of the GNU General Public License</p>
          <p>as published by the Free Software Foundation; either version 2</p>
          <p>of the License, or (at your option) any later version.</p>
          <p>This program is distributed in the hope that it will be useful,</p>
          <p>but WITHOUT ANY WARRANTY; without even the implied warranty of</p>
          <p>
            MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. &nbsp;See the
          </p>
          <p>GNU General Public License for more details.</p>
          <p>
            You should have received a copy of the GNU General Public License
          </p>
          <p>along with this program; if not, write to the Free Software</p>
          <p>
            Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA
            &nbsp;02110-1301, USA.
          </p>
          <p>
            Also add information on how to contact you by electronic and paper
            mail.
          </p>
          <p>
            If the program is interactive, make it output a short notice like
            this when it starts in an interactive mode:
          </p>
          <p>Gnomovision version 69, Copyright (C) year name of author</p>
          <p>Gnomovision comes with ABSOLUTELY NO WARRANTY; for details</p>
          <p>
            type `show w&apos;. &nbsp;This is free software, and you are welcome
          </p>
          <p>
            to redistribute it under certain conditions; type `show
            c&apos;&nbsp;
          </p>
          <p>for details.</p>
          <p>
            The hypothetical commands `show w&apos; and `show c&apos; should
            show the appropriate parts of the General Public License. Of course,
            the commands you use may be called something other than `show
            w&apos; and `show c&apos;; they could even be mouse-clicks or menu
            items--whatever suits your program.
          </p>
          <p>
            You should also get your employer (if you work as a programmer) or
            your school, if any, to sign a &quot;copyright disclaimer&quot; for
            the program, if necessary. Here is a sample; alter the names:
          </p>
          <p>Yoyodyne, Inc., hereby disclaims all copyright</p>
          <p>interest in the program `Gnomovision&apos;</p>
          <p>(which makes passes at compilers) written&nbsp;</p>
          <p>by James Hacker.</p>
          <p>signature of Ty Coon, 1 April 1989</p>
          <p>Ty Coon, President of Vice</p>
          <p>
            This General Public License does not permit incorporating your
            program into proprietary programs. If your program is a subroutine
            library, you may consider it more useful to permit linking
            proprietary applications with the library. If this is what you want
            to do, use the GNU Lesser General Public License instead of this
            License.
          </p>
        </>
      );
    }
    case "6": {
      // ODbL-1.0
      return (
        <>
          <p>PREAMBLE</p>
          <p>
            The Open Database License (ODbL) is a license agreement intended to
            allow users to freely share, modify, and use this Database while
            maintaining this same freedom for others. Many databases are covered
            by copyright, and therefore this document licenses these rights.
            Some jurisdictions, mainly in the European Union, have specific
            rights that cover databases, and so the ODbL addresses these rights,
            too. Finally, the ODbL is also an agreement in contract for users of
            this Database to act in certain ways in return for accessing this
            Database.
          </p>
          <p>
            Databases can contain a wide variety of types of content (images,
            audiovisual material, and sounds all in the same database, for
            example), and so the ODbL only governs the rights over the Database,
            and not the contents of the Database individually. Licensors should
            use the ODbL together with another license for the contents, if the
            contents have a single set of rights that uniformly covers all of
            the contents. If the contents have multiple sets of different
            rights, Licensors should describe what rights govern what contents
            together in the individual record or in some other way that
            clarifies what rights apply.
          </p>
          <p>
            Sometimes the contents of a database, or the database itself, can be
            covered by other rights not addressed here (such as private
            contracts, trade mark over the name, or privacy rights / data
            protection rights over information in the contents), and so you are
            advised that you may have to consult other documents or clear other
            rights before doing activities not covered by this License.
          </p>
          <p>The Licensor (as defined below)</p>
          <p>and</p>
          <p>You (as defined below)</p>
          <p>agree as follows:</p>
          <p>1.0 DEFINITIONS OF CAPITALISED WORDS</p>
          <p>
            &ldquo;Collective Database&rdquo; &ndash; Means this Database in
            unmodified form as part of a collection of independent databases in
            themselves that together are assembled into a collective whole. A
            work that constitutes a Collective Database will not be considered a
            Derivative Database.
          </p>
          <p>
            &ldquo;Convey&rdquo; &ndash; As a verb, means Using the Database, a
            Derivative Database, or the Database as part of a Collective
            Database in any way that enables a Person to make or receive copies
            of the Database or a Derivative Database. Conveying does not include
            interaction with a user through a computer network, or creating and
            Using a Produced Work, where no transfer of a copy of the Database
            or a Derivative Database occurs. &ldquo;Contents&rdquo; &ndash; The
            contents of this Database, which includes the information,
            independent works, or other material collected into the Database.
            For example, the contents of the Database could be factual data or
            works such as images, audiovisual material, text, or sounds.
          </p>
          <p>
            &ldquo;Database&rdquo; &ndash; A collection of material (the
            Contents) arranged in a systematic or methodical way and
            individually accessible by electronic or other means offered under
            the terms of this License.
          </p>
          <p>
            &ldquo;Database Directive&rdquo; &ndash; Means Directive 96/9/EC of
            the European Parliament and of the Council of 11 March 1996 on the
            legal protection of databases, as amended or succeeded.
          </p>
          <p>
            &ldquo;Database Right&rdquo; &ndash; Means rights resulting from the
            Chapter III (&ldquo;sui generis&rdquo;) rights in the Database
            Directive (as amended and as transposed by member states), which
            includes the Extraction and Re-utilisation of the whole or a
            Substantial part of the Contents, as well as any similar rights
            available in the relevant jurisdiction under Section 10.4.
          </p>
          <p>
            &ldquo;Derivative Database&rdquo; &ndash; Means a database based
            upon the Database, and includes any translation, adaptation,
            arrangement, modification, or any other alteration of the Database
            or of a Substantial part of the Contents. This includes, but is not
            limited to, Extracting or Re-utilising the whole or a Substantial
            part of the Contents in a new Database.
          </p>
          <p>
            &ldquo;Extraction&rdquo; &ndash; Means the permanent or temporary
            transfer of all or a Substantial part of the Contents to another
            medium by any means or in any form.
          </p>
          <p>
            &ldquo;License&rdquo; &ndash; Means this license agreement and is
            both a license of rights such as copyright and Database Rights and
            an agreement in contract.
          </p>
          <p>
            &ldquo;Licensor&rdquo; &ndash; Means the Person that offers the
            Database under the terms of this License.
          </p>
          <p>
            &ldquo;Person&rdquo; &ndash; Means a natural or legal person or a
            body of persons corporate or incorporate.
          </p>
          <p>
            &ldquo;Produced Work&rdquo; &ndash; a work (such as an image,
            audiovisual material, text, or sounds) resulting from using the
            whole or a Substantial part of the Contents (via a search or other
            query) from this Database, a Derivative Database, or this Database
            as part of a Collective Database.
          </p>
          <p>
            &ldquo;Publicly&rdquo; &ndash; means to Persons other than You or
            under Your control by either more than 50% ownership or by the power
            to direct their activities (such as contracting with an independent
            consultant).
          </p>
          <p>
            &ldquo;Re-utilisation&rdquo; &ndash; means any form of making
            available to the public all or a Substantial part of the Contents by
            the distribution of copies, by renting, by online or other forms of
            transmission.
          </p>
          <p>
            &ldquo;Substantial&rdquo; &ndash; Means substantial in terms of
            quantity or quality or a combination of both. The repeated and
            systematic Extraction or Re-utilisation of insubstantial parts of
            the Contents may amount to the Extraction or Re-utilisation of a
            Substantial part of the Contents.
          </p>
          <p>
            &ldquo;Use&rdquo; &ndash; As a verb, means doing any act that is
            restricted by copyright or Database Rights whether in the original
            medium or any other; and includes without limitation distributing,
            copying, publicly performing, publicly displaying, and preparing
            derivative works of the Database, as well as modifying the Database
            as may be technically necessary to use it in a different mode or
            format.
          </p>
          <p>
            &ldquo;You&rdquo; &ndash; Means a Person exercising rights under
            this License who has not previously violated the terms of this
            License with respect to the Database, or who has received express
            permission from the Licensor to exercise rights under this License
            despite a previous violation.
          </p>
          <p>Words in the singular include the plural and vice versa.</p>
          <p>2.0 WHAT THIS LICENSE COVERS</p>
          <p>2.1. Legal effect of this document. This License is:</p>
          <p>a. A license of applicable copyright and neighbouring rights;</p>
          <p>b. A license of the Database Right; and</p>
          <p>c. An agreement in contract between You and the Licensor.</p>
          <p>
            2.2 Legal rights covered. This License covers the legal rights in
            the Database, including:
          </p>
          <p>
            a. Copyright. Any copyright or neighbouring rights in the Database.
            The copyright licensed includes any individual elements of the
            Database, but does not cover the copyright over the Contents
            independent of this Database. See Section 2.4 for details. Copyright
            law varies between jurisdictions, but is likely to cover: the
            Database model or schema, which is the structure, arrangement, and
            organisation of the Database, and can also include the Database
            tables and table indexes; the data entry and output sheets; and the
            Field names of Contents stored in the Database;
          </p>
          <p>
            b. Database Rights. Database Rights only extend to the Extraction
            and Re-utilisation of the whole or a Substantial part of the
            Contents. Database Rights can apply even when there is no copyright
            over the Database. Database Rights can also apply when the Contents
            are removed from the Database and are selected and arranged in a way
            that would not infringe any applicable copyright; and
          </p>
          <p>
            c. Contract. This is an agreement between You and the Licensor for
            access to the Database. In return you agree to certain conditions of
            use on this access as outlined in this License.
          </p>
          <p>2.3 Rights not covered.</p>
          <p>
            a. This License does not apply to computer programs used in the
            making or operation of the Database;
          </p>
          <p>
            b. This License does not cover any patents over the Contents or the
            Database; and
          </p>
          <p>
            c. This License does not cover any trademarks associated with the
            Database.
          </p>
          <p>
            2.4 Relationship to Contents in the Database. The individual items
            of the Contents contained in this Database may be covered by other
            rights, including copyright, patent, data protection, privacy, or
            personality rights, and this License does not cover any rights
            (other than Database Rights or in contract) in individual Contents
            contained in the Database. For example, if used on a Database of
            images (the Contents), this License would not apply to copyright
            over individual images, which could have their own separate
            licenses, or one single license covering all of the rights over the
            images.
          </p>
          <p>3.0 RIGHTS GRANTED</p>
          <p>
            3.1 Subject to the terms and conditions of this License, the
            Licensor grants to You a worldwide, royalty-free, non-exclusive,
            terminable (but only under Section 9) license to Use the Database
            for the duration of any applicable copyright and Database Rights.
            These rights explicitly include commercial use, and do not exclude
            any field of endeavour. To the extent possible in the relevant
            jurisdiction, these rights may be exercised in all media and formats
            whether now known or created in the future.
          </p>
          <p>The rights granted cover, for example:</p>
          <p>
            a. Extraction and Re-utilisation of the whole or a Substantial part
            of the Contents;
          </p>
          <p>b. Creation of Derivative Databases;</p>
          <p>c. Creation of Collective Databases;</p>
          <p>
            d. Creation of temporary or permanent reproductions by any means and
            in any form, in whole or in part, including of any Derivative
            Databases or as a part of Collective Databases; and
          </p>
          <p>
            e. Distribution, communication, display, lending, making available,
            or performance to the public by any means and in any form, in whole
            or in part, including of any Derivative Database or as a part of
            Collective Databases.
          </p>
          <p>3.2 Compulsory license schemes. For the avoidance of doubt:</p>
          <p>
            a. Non-waivable compulsory license schemes. In those jurisdictions
            in which the right to collect royalties through any statutory or
            compulsory licensing scheme cannot be waived, the Licensor reserves
            the exclusive right to collect such royalties for any exercise by
            You of the rights granted under this License;
          </p>
          <p>
            b. Waivable compulsory license schemes. In those jurisdictions in
            which the right to collect royalties through any statutory or
            compulsory licensing scheme can be waived, the Licensor waives the
            exclusive right to collect such royalties for any exercise by You of
            the rights granted under this License; and,
          </p>
          <p>
            c. Voluntary license schemes. The Licensor waives the right to
            collect royalties, whether individually or, in the event that the
            Licensor is a member of a collecting society that administers
            voluntary licensing schemes, via that society, from any exercise by
            You of the rights granted under this License.
          </p>
          <p>
            3.3 The right to release the Database under different terms, or to
            stop distributing or making available the Database, is reserved.
            Note that this Database may be multiple-licensed, and so You may
            have the choice of using alternative licenses for this Database.
            Subject to Section 10.4, all other rights not expressly granted by
            Licensor are reserved.
          </p>
          <p>4.0 CONDITIONS OF USE</p>
          <p>
            4.1 The rights granted in Section 3 above are expressly made subject
            to Your complying with the following conditions of use. These are
            important conditions of this License, and if You fail to follow
            them, You will be in material breach of its terms.
          </p>
          <p>
            4.2 Notices. If You Publicly Convey this Database, any Derivative
            Database, or the Database as part of a Collective Database, then You
            must:
          </p>
          <p>
            a. Do so only under the terms of this License or another license
            permitted under Section 4.4;
          </p>
          <p>
            b. Include a copy of this License (or, as applicable, a license
            permitted under Section 4.4) or its Uniform Resource Identifier
            (URI) with the Database or Derivative Database, including both in
            the Database or Derivative Database and in any relevant
            documentation; and
          </p>
          <p>
            c. Keep intact any copyright or Database Right notices and notices
            that refer to this License.
          </p>
          <p>
            d. If it is not possible to put the required notices in a particular
            file due to its structure, then You must include the notices in a
            location (such as a relevant directory) where users would be likely
            to look for it.
          </p>
          <p>
            4.3 Notice for using output (Contents). Creating and Using a
            Produced Work does not require the notice in Section 4.2. However,
            if you Publicly Use a Produced Work, You must include a notice
            associated with the Produced Work reasonably calculated to make any
            Person that uses, views, accesses, interacts with, or is otherwise
            exposed to the Produced Work aware that Content was obtained from
            the Database, Derivative Database, or the Database as part of a
            Collective Database, and that it is available under this License.
          </p>
          <p>
            a. Example notice. The following text will satisfy notice under
            Section 4.3:
          </p>
          <p>
            Contains information from DATABASE NAME, which is made available
          </p>
          <p>here under the Open Database License (ODbL).</p>
          <p>
            DATABASE NAME should be replaced with the name of the Database and a
            hyperlink to the URI of the Database. &ldquo;Open Database
            License&rdquo; should contain a hyperlink to the URI of the text of
            this License. If hyperlinks are not possible, You should include the
            plain text of the required URI&rsquo;s with the above notice.
          </p>
          <p>4.4 Share alike.</p>
          <p>
            a. Any Derivative Database that You Publicly Use must be only under
            the terms of:
          </p>
          <p>i. This License;</p>
          <p>
            ii. A later version of this License similar in spirit to this
            License; or
          </p>
          <p>iii. A compatible license.</p>
          <p>
            If You license the Derivative Database under one of the licenses
            mentioned in (iii), You must comply with the terms of that license.
          </p>
          <p>
            b. For the avoidance of doubt, Extraction or Re-utilisation of the
            whole or a Substantial part of the Contents into a new database is a
            Derivative Database and must comply with Section 4.4.
          </p>
          <p>
            c. Derivative Databases and Produced Works. A Derivative Database is
            Publicly Used and so must comply with Section 4.4. if a Produced
            Work created from the Derivative Database is Publicly Used.
          </p>
          <p>
            d. Share Alike and additional Contents. For the avoidance of doubt,
            You must not add Contents to Derivative Databases under Section 4.4
            a that are incompatible with the rights granted under this License.
          </p>
          <p>
            e. Compatible licenses. Licensors may authorise a proxy to determine
            compatible licenses under Section 4.4 a iii. If they do so, the
            authorised proxy&rsquo;s public statement of acceptance of a
            compatible license grants You permission to use the compatible
            license.
          </p>
          <p>
            4.5 Limits of Share Alike. The requirements of Section 4.4 do not
            apply in the following:
          </p>
          <p>
            a. For the avoidance of doubt, You are not required to license
            Collective Databases under this License if You incorporate this
            Database or a Derivative Database in the collection, but this
            License still applies to this Database or a Derivative Database as a
            part of the Collective Database;
          </p>
          <p>
            b. Using this Database, a Derivative Database, or this Database as
            part of a Collective Database to create a Produced Work does not
            create a Derivative Database for purposes of Section 4.4; and
          </p>
          <p>
            c. Use of a Derivative Database internally within an organisation is
            not to the public and therefore does not fall under the requirements
            of Section 4.4.
          </p>
          <p>
            4.6 Access to Derivative Databases. If You Publicly Use a Derivative
            Database or a Produced Work from a Derivative Database, You must
            also offer to recipients of the Derivative Database or Produced Work
            a copy in a machine readable form of:
          </p>
          <p>a. The entire Derivative Database; or</p>
          <p>
            b. A file containing all of the alterations made to the Database or
            the method of making the alterations to the Database (such as an
            algorithm), including any additional Contents, that make up all the
            differences between the Database and the Derivative Database.
          </p>
          <p>
            The Derivative Database (under a.) or alteration file (under b.)
            must be available at no more than a reasonable production cost for
            physical distributions and free of charge if distributed over the
            internet.
          </p>
          <p>4.7 Technological measures and additional terms</p>
          <p>
            a. This License does not allow You to impose (except subject to
            Section 4.7 b.) any terms or any technological measures on the
            Database, a Derivative Database, or the whole or a Substantial part
            of the Contents that alter or restrict the terms of this License, or
            any rights granted under it, or have the effect or intent of
            restricting the ability of any person to exercise those rights.
          </p>
          <p>
            b. Parallel distribution. You may impose terms or technological
            measures on the Database, a Derivative Database, or the whole or a
            Substantial part of the Contents (a &ldquo;Restricted
            Database&rdquo;) in contravention of Section 4.74 a. only if You
            also make a copy of the Database or a Derivative Database available
            to the recipient of the Restricted Database:
          </p>
          <p>i. That is available without additional fee;</p>
          <p>
            ii. That is available in a medium that does not alter or restrict
            the terms of this License, or any rights granted under it, or have
            the effect or intent of restricting the ability of any person to
            exercise those rights (an &ldquo;Unrestricted Database&rdquo;); and
          </p>
          <p>
            iii. The Unrestricted Database is at least as accessible to the
            recipient as a practical matter as the Restricted Database.
          </p>
          <p>
            c. For the avoidance of doubt, You may place this Database or a
            Derivative Database in an authenticated environment, behind a
            password, or within a similar access control scheme provided that
            You do not alter or restrict the terms of this License or any rights
            granted under it or have the effect or intent of restricting the
            ability of any person to exercise those rights.
          </p>
          <p>
            4.8 Licensing of others. You may not sublicense the Database. Each
            time You communicate the Database, the whole or Substantial part of
            the Contents, or any Derivative Database to anyone else in any way,
            the Licensor offers to the recipient a license to the Database on
            the same terms and conditions as this License. You are not
            responsible for enforcing compliance by third parties with this
            License, but You may enforce any rights that You have over a
            Derivative Database. You are solely responsible for any
            modifications of a Derivative Database made by You or another Person
            at Your direction. You may not impose any further restrictions on
            the exercise of the rights granted or affirmed under this License.
          </p>
          <p>5.0 MORAL RIGHTS</p>
          <p>
            5.1 Moral rights. This section covers moral rights, including any
            rights to be identified as the author of the Database or to object
            to treatment that would otherwise prejudice the author&rsquo;s
            honour and reputation, or any other derogatory treatment:
          </p>
          <p>
            a. For jurisdictions allowing waiver of moral rights, Licensor
            waives all moral rights that Licensor may have in the Database to
            the fullest extent possible by the law of the relevant jurisdiction
            under Section 10.4;
          </p>
          <p>
            b. If waiver of moral rights under Section 5.1 a in the relevant
            jurisdiction is not possible, Licensor agrees not to assert any
            moral rights over the Database and waives all claims in moral rights
            to the fullest extent possible by the law of the relevant
            jurisdiction under Section 10.4; and
          </p>
          <p>
            c. For jurisdictions not allowing waiver or an agreement not to
            assert moral rights under Section 5.1 a and b, the author may retain
            their moral rights over certain aspects of the Database.
          </p>
          <p>
            Please note that some jurisdictions do not allow for the waiver of
            moral rights, and so moral rights may still subsist over the
            Database in some jurisdictions.
          </p>
          <p>
            6.0 FAIR DEALING, DATABASE EXCEPTIONS, AND OTHER RIGHTS NOT AFFECTED
          </p>
          <p>
            6.1 This License does not affect any rights that You or anyone else
            may independently have under any applicable law to make any use of
            this Database, including without limitation:
          </p>
          <p>
            a. Exceptions to the Database Right including: Extraction of
            Contents from non-electronic Databases for private purposes,
            Extraction for purposes of illustration for teaching or scientific
            research, and Extraction or Re-utilisation for public security or an
            administrative or judicial procedure.
          </p>
          <p>
            b. Fair dealing, fair use, or any other legally recognised
            limitation or exception to infringement of copyright or other
            applicable laws.
          </p>
          <p>
            6.2 This License does not affect any rights of lawful users to
            Extract and Re-utilise insubstantial parts of the Contents,
            evaluated quantitatively or qualitatively, for any purposes
            whatsoever, including creating a Derivative Database (subject to
            other rights over the Contents, see Section 2.4). The repeated and
            systematic Extraction or Re-utilisation of insubstantial parts of
            the Contents may however amount to the Extraction or Re-utilisation
            of a Substantial part of the Contents.
          </p>
          <p>7.0 WARRANTIES AND DISCLAIMER</p>
          <p>
            7.1 The Database is licensed by the Licensor &ldquo;as is&rdquo; and
            without any warranty of any kind, either express, implied, or
            arising by statute, custom, course of dealing, or trade usage.
            Licensor specifically disclaims any and all implied warranties or
            conditions of title, non-infringement, accuracy or completeness, the
            presence or absence of errors, fitness for a particular purpose,
            merchantability, or otherwise. Some jurisdictions do not allow the
            exclusion of implied warranties, so this exclusion may not apply to
            You.
          </p>
          <p>8.0 LIMITATION OF LIABILITY</p>
          <p>
            8.1 Subject to any liability that may not be excluded or limited by
            law, the Licensor is not liable for, and expressly excludes, all
            liability for loss or damage however and whenever caused to anyone
            by any use under this License, whether by You or by anyone else, and
            whether caused by any fault on the part of the Licensor or not. This
            exclusion of liability includes, but is not limited to, any special,
            incidental, consequential, punitive, or exemplary damages such as
            loss of revenue, data, anticipated profits, and lost business. This
            exclusion applies even if the Licensor has been advised of the
            possibility of such damages.
          </p>
          <p>
            8.2 If liability may not be excluded by law, it is limited to actual
            and direct financial loss to the extent it is caused by proved
            negligence on the part of the Licensor.
          </p>
          <p>9.0 TERMINATION OF YOUR RIGHTS UNDER THIS LICENSE</p>
          <p>
            9.1 Any breach by You of the terms and conditions of this License
            automatically terminates this License with immediate effect and
            without notice to You. For the avoidance of doubt, Persons who have
            received the Database, the whole or a Substantial part of the
            Contents, Derivative Databases, or the Database as part of a
            Collective Database from You under this License will not have their
            licenses terminated provided their use is in full compliance with
            this License or a license granted under Section 4.8 of this License.
            Sections 1, 2, 7, 8, 9 and 10 will survive any termination of this
            License.
          </p>
          <p>
            9.2 If You are not in breach of the terms of this License, the
            Licensor will not terminate Your rights under it.
          </p>
          <p>
            9.3 Unless terminated under Section 9.1, this License is granted to
            You for the duration of applicable rights in the Database.
          </p>
          <p>
            9.4 Reinstatement of rights. If you cease any breach of the terms
            and conditions of this License, then your full rights under this
            License will be reinstated:
          </p>
          <p>
            a. Provisionally and subject to permanent termination until the 60th
            day after cessation of breach;
          </p>
          <p>
            b. Permanently on the 60th day after cessation of breach unless
            otherwise reasonably notified by the Licensor; or
          </p>
          <p>
            c. Permanently if reasonably notified by the Licensor of the
            violation, this is the first time You have received notice of
            violation of this License from the Licensor, and You cure the
            violation prior to 30 days after your receipt of the notice.
          </p>
          <p>
            Persons subject to permanent termination of rights are not eligible
            to be a recipient and receive a license under Section 4.8.
          </p>
          <p>
            9.5 Notwithstanding the above, Licensor reserves the right to
            release the Database under different license terms or to stop
            distributing or making available the Database. Releasing the
            Database under different license terms or stopping the distribution
            of the Database will not withdraw this License (or any other license
            that has been, or is required to be, granted under the terms of this
            License), and this License will continue in full force and effect
            unless terminated as stated above.
          </p>
          <p>10.0 GENERAL</p>
          <p>
            10.1 If any provision of this License is held to be invalid or
            unenforceable, that must not affect the validity or enforceability
            of the remainder of the terms and conditions of this License and
            each remaining provision of this License shall be valid and enforced
            to the fullest extent permitted by law.
          </p>
          <p>
            10.2 This License is the entire agreement between the parties with
            respect to the rights granted here over the Database. It replaces
            any earlier understandings, agreements or representations with
            respect to the Database.
          </p>
          <p>
            10.3 If You are in breach of the terms of this License, You will not
            be entitled to rely on the terms of this License or to complain of
            any breach by the Licensor.
          </p>
          <p>
            10.4 Choice of law. This License takes effect in and will be
            governed by the laws of the relevant jurisdiction in which the
            License terms are sought to be enforced. If the standard suite of
            rights granted under applicable copyright law and Database Rights in
            the relevant jurisdiction includes additional rights not granted
            under this License, these additional rights are granted in this
            License in order to meet the terms of this License.
          </p>
        </>
      );
    }
    case "7": {
      // DbCL-1.0
      return (
        <>
          <p>PREAMBLE</p>
          <p>
            The Open Database License (ODbL) is a license agreement intended to
            allow users to freely share, modify, and use this Database while
            maintaining this same freedom for others. Many databases are covered
            by copyright, and therefore this document licenses these rights.
            Some jurisdictions, mainly in the European Union, have specific
            rights that cover databases, and so the ODbL addresses these rights,
            too. Finally, the ODbL is also an agreement in contract for users of
            this Database to act in certain ways in return for accessing this
            Database.
          </p>
          <p>
            Databases can contain a wide variety of types of content (images,
            audiovisual material, and sounds all in the same database, for
            example), and so the ODbL only governs the rights over the Database,
            and not the contents of the Database individually. Licensors should
            use the ODbL together with another license for the contents, if the
            contents have a single set of rights that uniformly covers all of
            the contents. If the contents have multiple sets of different
            rights, Licensors should describe what rights govern what contents
            together in the individual record or in some other way that
            clarifies what rights apply.
          </p>
          <p>
            Sometimes the contents of a database, or the database itself, can be
            covered by other rights not addressed here (such as private
            contracts, trade mark over the name, or privacy rights / data
            protection rights over information in the contents), and so you are
            advised that you may have to consult other documents or clear other
            rights before doing activities not covered by this License.
          </p>
          <p>The Licensor (as defined below)</p>
          <p>and</p>
          <p>You (as defined below)</p>
          <p>agree as follows:</p>
          <p>1.0 DEFINITIONS OF CAPITALISED WORDS</p>
          <p>
            &ldquo;Collective Database&rdquo; &ndash; Means this Database in
            unmodified form as part of a collection of independent databases in
            themselves that together are assembled into a collective whole. A
            work that constitutes a Collective Database will not be considered a
            Derivative Database.
          </p>
          <p>
            &ldquo;Convey&rdquo; &ndash; As a verb, means Using the Database, a
            Derivative Database, or the Database as part of a Collective
            Database in any way that enables a Person to make or receive copies
            of the Database or a Derivative Database. Conveying does not include
            interaction with a user through a computer network, or creating and
            Using a Produced Work, where no transfer of a copy of the Database
            or a Derivative Database occurs. &ldquo;Contents&rdquo; &ndash; The
            contents of this Database, which includes the information,
            independent works, or other material collected into the Database.
            For example, the contents of the Database could be factual data or
            works such as images, audiovisual material, text, or sounds.
          </p>
          <p>
            &ldquo;Database&rdquo; &ndash; A collection of material (the
            Contents) arranged in a systematic or methodical way and
            individually accessible by electronic or other means offered under
            the terms of this License.
          </p>
          <p>
            &ldquo;Database Directive&rdquo; &ndash; Means Directive 96/9/EC of
            the European Parliament and of the Council of 11 March 1996 on the
            legal protection of databases, as amended or succeeded.
          </p>
          <p>
            &ldquo;Database Right&rdquo; &ndash; Means rights resulting from the
            Chapter III (&ldquo;sui generis&rdquo;) rights in the Database
            Directive (as amended and as transposed by member states), which
            includes the Extraction and Re-utilisation of the whole or a
            Substantial part of the Contents, as well as any similar rights
            available in the relevant jurisdiction under Section 10.4.
          </p>
          <p>
            &ldquo;Derivative Database&rdquo; &ndash; Means a database based
            upon the Database, and includes any translation, adaptation,
            arrangement, modification, or any other alteration of the Database
            or of a Substantial part of the Contents. This includes, but is not
            limited to, Extracting or Re-utilising the whole or a Substantial
            part of the Contents in a new Database.
          </p>
          <p>
            &ldquo;Extraction&rdquo; &ndash; Means the permanent or temporary
            transfer of all or a Substantial part of the Contents to another
            medium by any means or in any form.
          </p>
          <p>
            &ldquo;License&rdquo; &ndash; Means this license agreement and is
            both a license of rights such as copyright and Database Rights and
            an agreement in contract.
          </p>
          <p>
            &ldquo;Licensor&rdquo; &ndash; Means the Person that offers the
            Database under the terms of this License.
          </p>
          <p>
            &ldquo;Person&rdquo; &ndash; Means a natural or legal person or a
            body of persons corporate or incorporate.
          </p>
          <p>
            &ldquo;Produced Work&rdquo; &ndash; a work (such as an image,
            audiovisual material, text, or sounds) resulting from using the
            whole or a Substantial part of the Contents (via a search or other
            query) from this Database, a Derivative Database, or this Database
            as part of a Collective Database.
          </p>
          <p>
            &ldquo;Publicly&rdquo; &ndash; means to Persons other than You or
            under Your control by either more than 50% ownership or by the power
            to direct their activities (such as contracting with an independent
            consultant).
          </p>
          <p>
            &ldquo;Re-utilisation&rdquo; &ndash; means any form of making
            available to the public all or a Substantial part of the Contents by
            the distribution of copies, by renting, by online or other forms of
            transmission.
          </p>
          <p>
            &ldquo;Substantial&rdquo; &ndash; Means substantial in terms of
            quantity or quality or a combination of both. The repeated and
            systematic Extraction or Re-utilisation of insubstantial parts of
            the Contents may amount to the Extraction or Re-utilisation of a
            Substantial part of the Contents.
          </p>
          <p>
            &ldquo;Use&rdquo; &ndash; As a verb, means doing any act that is
            restricted by copyright or Database Rights whether in the original
            medium or any other; and includes without limitation distributing,
            copying, publicly performing, publicly displaying, and preparing
            derivative works of the Database, as well as modifying the Database
            as may be technically necessary to use it in a different mode or
            format.
          </p>
          <p>
            &ldquo;You&rdquo; &ndash; Means a Person exercising rights under
            this License who has not previously violated the terms of this
            License with respect to the Database, or who has received express
            permission from the Licensor to exercise rights under this License
            despite a previous violation.
          </p>
          <p>Words in the singular include the plural and vice versa.</p>
          <p>2.0 WHAT THIS LICENSE COVERS</p>
          <p>2.1. Legal effect of this document. This License is:</p>
          <p>a. A license of applicable copyright and neighbouring rights;</p>
          <p>b. A license of the Database Right; and</p>
          <p>c. An agreement in contract between You and the Licensor.</p>
          <p>
            2.2 Legal rights covered. This License covers the legal rights in
            the Database, including:
          </p>
          <p>
            a. Copyright. Any copyright or neighbouring rights in the Database.
            The copyright licensed includes any individual elements of the
            Database, but does not cover the copyright over the Contents
            independent of this Database. See Section 2.4 for details. Copyright
            law varies between jurisdictions, but is likely to cover: the
            Database model or schema, which is the structure, arrangement, and
            organisation of the Database, and can also include the Database
            tables and table indexes; the data entry and output sheets; and the
            Field names of Contents stored in the Database;
          </p>
          <p>
            b. Database Rights. Database Rights only extend to the Extraction
            and Re-utilisation of the whole or a Substantial part of the
            Contents. Database Rights can apply even when there is no copyright
            over the Database. Database Rights can also apply when the Contents
            are removed from the Database and are selected and arranged in a way
            that would not infringe any applicable copyright; and
          </p>
          <p>
            c. Contract. This is an agreement between You and the Licensor for
            access to the Database. In return you agree to certain conditions of
            use on this access as outlined in this License.
          </p>
          <p>2.3 Rights not covered.</p>
          <p>
            a. This License does not apply to computer programs used in the
            making or operation of the Database;
          </p>
          <p>
            b. This License does not cover any patents over the Contents or the
            Database; and
          </p>
          <p>
            c. This License does not cover any trademarks associated with the
            Database.
          </p>
          <p>
            2.4 Relationship to Contents in the Database. The individual items
            of the Contents contained in this Database may be covered by other
            rights, including copyright, patent, data protection, privacy, or
            personality rights, and this License does not cover any rights
            (other than Database Rights or in contract) in individual Contents
            contained in the Database. For example, if used on a Database of
            images (the Contents), this License would not apply to copyright
            over individual images, which could have their own separate
            licenses, or one single license covering all of the rights over the
            images.
          </p>
          <p>3.0 RIGHTS GRANTED</p>
          <p>
            3.1 Subject to the terms and conditions of this License, the
            Licensor grants to You a worldwide, royalty-free, non-exclusive,
            terminable (but only under Section 9) license to Use the Database
            for the duration of any applicable copyright and Database Rights.
            These rights explicitly include commercial use, and do not exclude
            any field of endeavour. To the extent possible in the relevant
            jurisdiction, these rights may be exercised in all media and formats
            whether now known or created in the future.
          </p>
          <p>The rights granted cover, for example:</p>
          <p>
            a. Extraction and Re-utilisation of the whole or a Substantial part
            of the Contents;
          </p>
          <p>b. Creation of Derivative Databases;</p>
          <p>c. Creation of Collective Databases;</p>
          <p>
            d. Creation of temporary or permanent reproductions by any means and
            in any form, in whole or in part, including of any Derivative
            Databases or as a part of Collective Databases; and
          </p>
          <p>
            e. Distribution, communication, display, lending, making available,
            or performance to the public by any means and in any form, in whole
            or in part, including of any Derivative Database or as a part of
            Collective Databases.
          </p>
          <p>3.2 Compulsory license schemes. For the avoidance of doubt:</p>
          <p>
            a. Non-waivable compulsory license schemes. In those jurisdictions
            in which the right to collect royalties through any statutory or
            compulsory licensing scheme cannot be waived, the Licensor reserves
            the exclusive right to collect such royalties for any exercise by
            You of the rights granted under this License;
          </p>
          <p>
            b. Waivable compulsory license schemes. In those jurisdictions in
            which the right to collect royalties through any statutory or
            compulsory licensing scheme can be waived, the Licensor waives the
            exclusive right to collect such royalties for any exercise by You of
            the rights granted under this License; and,
          </p>
          <p>
            c. Voluntary license schemes. The Licensor waives the right to
            collect royalties, whether individually or, in the event that the
            Licensor is a member of a collecting society that administers
            voluntary licensing schemes, via that society, from any exercise by
            You of the rights granted under this License.
          </p>
          <p>
            3.3 The right to release the Database under different terms, or to
            stop distributing or making available the Database, is reserved.
            Note that this Database may be multiple-licensed, and so You may
            have the choice of using alternative licenses for this Database.
            Subject to Section 10.4, all other rights not expressly granted by
            Licensor are reserved.
          </p>
          <p>4.0 CONDITIONS OF USE</p>
          <p>
            4.1 The rights granted in Section 3 above are expressly made subject
            to Your complying with the following conditions of use. These are
            important conditions of this License, and if You fail to follow
            them, You will be in material breach of its terms.
          </p>
          <p>
            4.2 Notices. If You Publicly Convey this Database, any Derivative
            Database, or the Database as part of a Collective Database, then You
            must:
          </p>
          <p>
            a. Do so only under the terms of this License or another license
            permitted under Section 4.4;
          </p>
          <p>
            b. Include a copy of this License (or, as applicable, a license
            permitted under Section 4.4) or its Uniform Resource Identifier
            (URI) with the Database or Derivative Database, including both in
            the Database or Derivative Database and in any relevant
            documentation; and
          </p>
          <p>
            c. Keep intact any copyright or Database Right notices and notices
            that refer to this License.
          </p>
          <p>
            d. If it is not possible to put the required notices in a particular
            file due to its structure, then You must include the notices in a
            location (such as a relevant directory) where users would be likely
            to look for it.
          </p>
          <p>
            4.3 Notice for using output (Contents). Creating and Using a
            Produced Work does not require the notice in Section 4.2. However,
            if you Publicly Use a Produced Work, You must include a notice
            associated with the Produced Work reasonably calculated to make any
            Person that uses, views, accesses, interacts with, or is otherwise
            exposed to the Produced Work aware that Content was obtained from
            the Database, Derivative Database, or the Database as part of a
            Collective Database, and that it is available under this License.
          </p>
          <p>
            a. Example notice. The following text will satisfy notice under
            Section 4.3:
          </p>
          <p>
            Contains information from DATABASE NAME, which is made available
          </p>
          <p>here under the Open Database License (ODbL).</p>
          <p>
            DATABASE NAME should be replaced with the name of the Database and a
            hyperlink to the URI of the Database. &ldquo;Open Database
            License&rdquo; should contain a hyperlink to the URI of the text of
            this License. If hyperlinks are not possible, You should include the
            plain text of the required URI&rsquo;s with the above notice.
          </p>
          <p>4.4 Share alike.</p>
          <p>
            a. Any Derivative Database that You Publicly Use must be only under
            the terms of:
          </p>
          <p>i. This License;</p>
          <p>
            ii. A later version of this License similar in spirit to this
            License; or
          </p>
          <p>iii. A compatible license.</p>
          <p>
            If You license the Derivative Database under one of the licenses
            mentioned in (iii), You must comply with the terms of that license.
          </p>
          <p>
            b. For the avoidance of doubt, Extraction or Re-utilisation of the
            whole or a Substantial part of the Contents into a new database is a
            Derivative Database and must comply with Section 4.4.
          </p>
          <p>
            c. Derivative Databases and Produced Works. A Derivative Database is
            Publicly Used and so must comply with Section 4.4. if a Produced
            Work created from the Derivative Database is Publicly Used.
          </p>
          <p>
            d. Share Alike and additional Contents. For the avoidance of doubt,
            You must not add Contents to Derivative Databases under Section 4.4
            a that are incompatible with the rights granted under this License.
          </p>
          <p>
            e. Compatible licenses. Licensors may authorise a proxy to determine
            compatible licenses under Section 4.4 a iii. If they do so, the
            authorised proxy&rsquo;s public statement of acceptance of a
            compatible license grants You permission to use the compatible
            license.
          </p>
          <p>
            4.5 Limits of Share Alike. The requirements of Section 4.4 do not
            apply in the following:
          </p>
          <p>
            a. For the avoidance of doubt, You are not required to license
            Collective Databases under this License if You incorporate this
            Database or a Derivative Database in the collection, but this
            License still applies to this Database or a Derivative Database as a
            part of the Collective Database;
          </p>
          <p>
            b. Using this Database, a Derivative Database, or this Database as
            part of a Collective Database to create a Produced Work does not
            create a Derivative Database for purposes of Section 4.4; and
          </p>
          <p>
            c. Use of a Derivative Database internally within an organisation is
            not to the public and therefore does not fall under the requirements
            of Section 4.4.
          </p>
          <p>
            4.6 Access to Derivative Databases. If You Publicly Use a Derivative
            Database or a Produced Work from a Derivative Database, You must
            also offer to recipients of the Derivative Database or Produced Work
            a copy in a machine readable form of:
          </p>
          <p>a. The entire Derivative Database; or</p>
          <p>
            b. A file containing all of the alterations made to the Database or
            the method of making the alterations to the Database (such as an
            algorithm), including any additional Contents, that make up all the
            differences between the Database and the Derivative Database.
          </p>
          <p>
            The Derivative Database (under a.) or alteration file (under b.)
            must be available at no more than a reasonable production cost for
            physical distributions and free of charge if distributed over the
            internet.
          </p>
          <p>4.7 Technological measures and additional terms</p>
          <p>
            a. This License does not allow You to impose (except subject to
            Section 4.7 b.) any terms or any technological measures on the
            Database, a Derivative Database, or the whole or a Substantial part
            of the Contents that alter or restrict the terms of this License, or
            any rights granted under it, or have the effect or intent of
            restricting the ability of any person to exercise those rights.
          </p>
          <p>
            b. Parallel distribution. You may impose terms or technological
            measures on the Database, a Derivative Database, or the whole or a
            Substantial part of the Contents (a &ldquo;Restricted
            Database&rdquo;) in contravention of Section 4.74 a. only if You
            also make a copy of the Database or a Derivative Database available
            to the recipient of the Restricted Database:
          </p>
          <p>i. That is available without additional fee;</p>
          <p>
            ii. That is available in a medium that does not alter or restrict
            the terms of this License, or any rights granted under it, or have
            the effect or intent of restricting the ability of any person to
            exercise those rights (an &ldquo;Unrestricted Database&rdquo;); and
          </p>
          <p>
            iii. The Unrestricted Database is at least as accessible to the
            recipient as a practical matter as the Restricted Database.
          </p>
          <p>
            c. For the avoidance of doubt, You may place this Database or a
            Derivative Database in an authenticated environment, behind a
            password, or within a similar access control scheme provided that
            You do not alter or restrict the terms of this License or any rights
            granted under it or have the effect or intent of restricting the
            ability of any person to exercise those rights.
          </p>
          <p>
            4.8 Licensing of others. You may not sublicense the Database. Each
            time You communicate the Database, the whole or Substantial part of
            the Contents, or any Derivative Database to anyone else in any way,
            the Licensor offers to the recipient a license to the Database on
            the same terms and conditions as this License. You are not
            responsible for enforcing compliance by third parties with this
            License, but You may enforce any rights that You have over a
            Derivative Database. You are solely responsible for any
            modifications of a Derivative Database made by You or another Person
            at Your direction. You may not impose any further restrictions on
            the exercise of the rights granted or affirmed under this License.
          </p>
          <p>5.0 MORAL RIGHTS</p>
          <p>
            5.1 Moral rights. This section covers moral rights, including any
            rights to be identified as the author of the Database or to object
            to treatment that would otherwise prejudice the author&rsquo;s
            honour and reputation, or any other derogatory treatment:
          </p>
          <p>
            a. For jurisdictions allowing waiver of moral rights, Licensor
            waives all moral rights that Licensor may have in the Database to
            the fullest extent possible by the law of the relevant jurisdiction
            under Section 10.4;
          </p>
          <p>
            b. If waiver of moral rights under Section 5.1 a in the relevant
            jurisdiction is not possible, Licensor agrees not to assert any
            moral rights over the Database and waives all claims in moral rights
            to the fullest extent possible by the law of the relevant
            jurisdiction under Section 10.4; and
          </p>
          <p>
            c. For jurisdictions not allowing waiver or an agreement not to
            assert moral rights under Section 5.1 a and b, the author may retain
            their moral rights over certain aspects of the Database.
          </p>
          <p>
            Please note that some jurisdictions do not allow for the waiver of
            moral rights, and so moral rights may still subsist over the
            Database in some jurisdictions.
          </p>
          <p>
            6.0 FAIR DEALING, DATABASE EXCEPTIONS, AND OTHER RIGHTS NOT AFFECTED
          </p>
          <p>
            6.1 This License does not affect any rights that You or anyone else
            may independently have under any applicable law to make any use of
            this Database, including without limitation:
          </p>
          <p>
            a. Exceptions to the Database Right including: Extraction of
            Contents from non-electronic Databases for private purposes,
            Extraction for purposes of illustration for teaching or scientific
            research, and Extraction or Re-utilisation for public security or an
            administrative or judicial procedure.
          </p>
          <p>
            b. Fair dealing, fair use, or any other legally recognised
            limitation or exception to infringement of copyright or other
            applicable laws.
          </p>
          <p>
            6.2 This License does not affect any rights of lawful users to
            Extract and Re-utilise insubstantial parts of the Contents,
            evaluated quantitatively or qualitatively, for any purposes
            whatsoever, including creating a Derivative Database (subject to
            other rights over the Contents, see Section 2.4). The repeated and
            systematic Extraction or Re-utilisation of insubstantial parts of
            the Contents may however amount to the Extraction or Re-utilisation
            of a Substantial part of the Contents.
          </p>
          <p>7.0 WARRANTIES AND DISCLAIMER</p>
          <p>
            7.1 The Database is licensed by the Licensor &ldquo;as is&rdquo; and
            without any warranty of any kind, either express, implied, or
            arising by statute, custom, course of dealing, or trade usage.
            Licensor specifically disclaims any and all implied warranties or
            conditions of title, non-infringement, accuracy or completeness, the
            presence or absence of errors, fitness for a particular purpose,
            merchantability, or otherwise. Some jurisdictions do not allow the
            exclusion of implied warranties, so this exclusion may not apply to
            You.
          </p>
          <p>8.0 LIMITATION OF LIABILITY</p>
          <p>
            8.1 Subject to any liability that may not be excluded or limited by
            law, the Licensor is not liable for, and expressly excludes, all
            liability for loss or damage however and whenever caused to anyone
            by any use under this License, whether by You or by anyone else, and
            whether caused by any fault on the part of the Licensor or not. This
            exclusion of liability includes, but is not limited to, any special,
            incidental, consequential, punitive, or exemplary damages such as
            loss of revenue, data, anticipated profits, and lost business. This
            exclusion applies even if the Licensor has been advised of the
            possibility of such damages.
          </p>
          <p>
            8.2 If liability may not be excluded by law, it is limited to actual
            and direct financial loss to the extent it is caused by proved
            negligence on the part of the Licensor.
          </p>
          <p>9.0 TERMINATION OF YOUR RIGHTS UNDER THIS LICENSE</p>
          <p>
            9.1 Any breach by You of the terms and conditions of this License
            automatically terminates this License with immediate effect and
            without notice to You. For the avoidance of doubt, Persons who have
            received the Database, the whole or a Substantial part of the
            Contents, Derivative Databases, or the Database as part of a
            Collective Database from You under this License will not have their
            licenses terminated provided their use is in full compliance with
            this License or a license granted under Section 4.8 of this License.
            Sections 1, 2, 7, 8, 9 and 10 will survive any termination of this
            License.
          </p>
          <p>
            9.2 If You are not in breach of the terms of this License, the
            Licensor will not terminate Your rights under it.
          </p>
          <p>
            9.3 Unless terminated under Section 9.1, this License is granted to
            You for the duration of applicable rights in the Database.
          </p>
          <p>
            9.4 Reinstatement of rights. If you cease any breach of the terms
            and conditions of this License, then your full rights under this
            License will be reinstated:
          </p>
          <p>
            a. Provisionally and subject to permanent termination until the 60th
            day after cessation of breach;
          </p>
          <p>
            b. Permanently on the 60th day after cessation of breach unless
            otherwise reasonably notified by the Licensor; or
          </p>
          <p>
            c. Permanently if reasonably notified by the Licensor of the
            violation, this is the first time You have received notice of
            violation of this License from the Licensor, and You cure the
            violation prior to 30 days after your receipt of the notice.
          </p>
          <p>
            Persons subject to permanent termination of rights are not eligible
            to be a recipient and receive a license under Section 4.8.
          </p>
          <p>
            9.5 Notwithstanding the above, Licensor reserves the right to
            release the Database under different license terms or to stop
            distributing or making available the Database. Releasing the
            Database under different license terms or stopping the distribution
            of the Database will not withdraw this License (or any other license
            that has been, or is required to be, granted under the terms of this
            License), and this License will continue in full force and effect
            unless terminated as stated above.
          </p>
          <p>10.0 GENERAL</p>
          <p>
            10.1 If any provision of this License is held to be invalid or
            unenforceable, that must not affect the validity or enforceability
            of the remainder of the terms and conditions of this License and
            each remaining provision of this License shall be valid and enforced
            to the fullest extent permitted by law.
          </p>
          <p>
            10.2 This License is the entire agreement between the parties with
            respect to the rights granted here over the Database. It replaces
            any earlier understandings, agreements or representations with
            respect to the Database.
          </p>
          <p>
            10.3 If You are in breach of the terms of this License, You will not
            be entitled to rely on the terms of this License or to complain of
            any breach by the Licensor.
          </p>
          <p>
            10.4 Choice of law. This License takes effect in and will be
            governed by the laws of the relevant jurisdiction in which the
            License terms are sought to be enforced. If the standard suite of
            rights granted under applicable copyright law and Database Rights in
            the relevant jurisdiction includes additional rights not granted
            under this License, these additional rights are granted in this
            License in order to meet the terms of this License.
          </p>
        </>
      );
    }
    case "8": {
      // CC-BY-4.0
      return (
        <>
          <p>You are free to:</p>
          <p>
            Share &mdash; copy and redistribute the material in any medium or
            format for any purpose, even commercially.
          </p>
          <p>
            Adapt &mdash; remix, transform, and build upon the material for any
            purpose, even commercially.
          </p>
          <p>
            The licensor cannot revoke these freedoms as long as you follow the
            license terms.
          </p>
          <p>Under the following terms:</p>
          <p>
            Attribution &mdash; You must give appropriate credit , provide a
            link to the license, and indicate if changes were made . You may do
            so in any reasonable manner, but not in any way that suggests the
            licensor endorses you or your use.
          </p>
          <p>
            No additional restrictions &mdash; You may not apply legal terms or
            technological measures that legally restrict others from doing
            anything the license permits.
          </p>
          <p>Notices:</p>
          <p>
            You do not have to comply with the license for elements of the
            material in the public domain or where your use is permitted by an
            applicable exception or limitation .
          </p>
          <p>
            No warranties are given. The license may not give you all of the
            permissions necessary for your intended use. For example, other
            rights such as publicity, privacy, or moral rights may limit how you
            use the material.
          </p>
        </>
      );
    }
    case "9": {
      // CC-BY-NC-4.0
      return (
        <>
          <p>You are free to:</p>
          <p>
            Share &mdash; copy and redistribute the material in any medium or
            format
          </p>
          <p>Adapt &mdash; remix, transform, and build upon the material</p>
          <p>
            The licensor cannot revoke these freedoms as long as you follow the
            license terms.
          </p>
          <p>Under the following terms:</p>
          <p>
            Attribution &mdash; You must give appropriate credit , provide a
            link to the license, and indicate if changes were made . You may do
            so in any reasonable manner, but not in any way that suggests the
            licensor endorses you or your use.
          </p>
          <p>
            NonCommercial &mdash; You may not use the material for commercial
            purposes .
          </p>
          <p>
            No additional restrictions &mdash; You may not apply legal terms or
            technological measures that legally restrict others from doing
            anything the license permits.
          </p>
          <p>Notices:</p>
          <p>
            You do not have to comply with the license for elements of the
            material in the public domain or where your use is permitted by an
            applicable exception or limitation .
          </p>
          <p>
            No warranties are given. The license may not give you all of the
            permissions necessary for your intended use. For example, other
            rights such as publicity, privacy, or moral rights may limit how you
            use the material.
          </p>
        </>
      );
    }
    case "10": {
      // PDDL
      return (
        <>
          <p>
            PREAMBLE
            <br />
            The Open Data Commons &ndash; Public Domain Dedication and Licence
            is a document intended to allow you to freely share, modify, and use
            this work for any purpose and without any restrictions. This licence
            is intended for use on databases or their contents
            (&ldquo;data&rdquo;), either together or individually.
            <br />
            Many databases are covered by copyright. Some jurisdictions, mainly
            in Europe, have specific special rights that cover databases called
            the &ldquo;sui generis&rdquo; database right. Both of these sets of
            rights, as well as other legal rights used to protect databases and
            data, can create uncertainty or practical difficulty for those
            wishing to share databases and their underlying data but retain a
            limited amount of rights under a &ldquo;some rights reserved&rdquo;
            approach to licensing as outlined in the Science Commons Protocol
            for Implementing Open Access Data. As a result, this waiver and
            licence tries to the fullest extent possible to eliminate or fully
            license any rights that cover this database and data. Any Community
            Norms or similar statements of use of the database or data do not
            form a part of this document, and do not act as a contract for
            access or other terms of use for the database or data.
            <br />
            THE POSITION OF THE RECIPIENT OF THE WORK
            <br />
            Because this document places the database and its contents in or as
            close as possible within the public domain, there are no
            restrictions or requirements placed on the recipient by this
            document. Recipients may use this work commercially, use technical
            protection measures, combine this data or database with other
            databases or data, and share their changes and additions or keep
            them secret. It is not a requirement that recipients provide further
            users with a copy of this licence or attribute the original creator
            of the data or database as a source. The goal is to eliminate
            restrictions held by the original creator of the data and database
            on the use of it by others.
            <br />
            THE POSITION OF THE DEDICATOR OF THE WORK
            <br />
            Copyright law, as with most other law under the banner of
            &ldquo;intellectual property&rdquo;, is inherently national law.
            This means that there exists several differences in how copyright
            and other IP rights can be relinquished, waived or licensed in the
            many legal jurisdictions of the world. This is despite much
            harmonisation of minimum levels of protection. The internet and
            other communication technologies span these many disparate legal
            jurisdictions and thus pose special difficulties for a document
            relinquishing and waiving intellectual property rights, including
            copyright and database rights, for use by the global community.
            Because of this feature of intellectual property law, this document
            first relinquishes the rights and waives the relevant rights and
            claims. It then goes on to license these same rights for
            jurisdictions or areas of law that may make it difficult to
            relinquish or waive rights or claims.
            <br />
            The purpose of this document is to enable rightsholders to place
            their work into the public domain. Unlike licences for free and open
            source software, free cultural works, or open content licences,
            rightsholders will not be able to &ldquo;dual license&rdquo; their
            work by releasing the same work under different licences. This is
            because they have allowed anyone to use the work in whatever way
            they choose. Rightsholders therefore can&rsquo;t re-license it under
            copyright or database rights on different terms because they have
            nothing left to license. Doing so creates truly accessible data to
            build rich applications and advance the progress of science and the
            arts.
            <br />
            This document can cover either or both of the database and its
            contents (the data). Because databases can have a wide variety of
            content &ndash; not just factual data &ndash; rightsholders should
            use the Open Data Commons &ndash; Public Domain Dedication &amp;
            Licence for an entire database and its contents only if everything
            can be placed under the terms of this document. Because even factual
            data can sometimes have intellectual property rights, rightsholders
            should use this licence to cover both the database and its factual
            data when making material available under this document; even if it
            is likely that the data would not be covered by copyright or
            database rights.
            <br />
            Rightsholders can also use this document to cover any copyright or
            database rights claims over only a database, and leave the contents
            to be covered by other licences or documents. They can do this
            because this document refers to the &ldquo;Work&rdquo;, which can be
            either &ndash; or both &ndash; the database and its contents. As a
            result, rightsholders need to clearly state what they are dedicating
            under this document when they dedicate it.
            <br />
            Just like any licence or other document dealing with intellectual
            property, rightsholders should be aware that one can only license
            what one owns. Please ensure that the rights have been cleared to
            make this material available under this document.
            <br />
            This document permanently and irrevocably makes the Work available
            to the public for any use of any kind, and it should not be used
            unless the rightsholder is prepared for this to happen.
            <br />
            PART I: INTRODUCTION
            <br />
            The Rightsholder (the Person holding rights or claims over the Work)
            agrees as follows:
            <br />
            1.0 DEFINITIONS OF CAPITALISED WORDS
            <br />
            &ldquo;Copyright&rdquo; &ndash; Includes rights under copyright and
            under neighbouring rights and similarly related sets of rights under
            the law of the relevant jurisdiction under Section 6.4.
            <br />
            &ldquo;Data&rdquo; &ndash; The contents of the Database, which
            includes the information, independent works, or other material
            collected into the Database offered under the terms of this
            Document.
            <br />
            &ldquo;Database&rdquo; &ndash; A collection of Data arranged in a
            systematic or methodical way and individually accessible by
            electronic or other means offered under the terms of this Document.
            <br />
            &ldquo;Database Right&rdquo; &ndash; Means rights over Data
            resulting from the Chapter III (&ldquo;sui generis&rdquo;) rights in
            the Database Directive (Directive 96/9/EC of the European Parliament
            and of the Council of 11 March 1996 on the legal protection of
            databases) and any future updates as well as any similar rights
            available in the relevant jurisdiction under Section 6.4.
            <br />
            &ldquo;Document&rdquo; &ndash; means this relinquishment and waiver
            of rights and claims and back up licence agreement.
            <br />
            &ldquo;Person&rdquo; &ndash; Means a natural or legal person or a
            body of persons corporate or incorporate.
            <br />
            &ldquo;Use&rdquo; &ndash; As a verb, means doing any act that is
            restricted by Copyright or Database Rights whether in the original
            medium or any other; and includes modifying the Work as may be
            technically necessary to use it in a different mode or format. This
            includes the right to sublicense the Work.
            <br />
            &ldquo;Work&rdquo; &ndash; Means either or both of the Database and
            Data offered under the terms of this Document.
            <br />
            &ldquo;You&rdquo; &ndash; the Person acquiring rights under the
            licence elements of this Document.
            <br />
            Words in the singular include the plural and vice versa.
            <br />
            2.0 WHAT THIS DOCUMENT COVERS
            <br />
            2.1. Legal effect of this Document. This Document is:
            <br />
            a. A dedication to the public domain and waiver of Copyright and
            Database Rights over the Work; and
            <br />
            b. A licence of Copyright and Database Rights over the Work in
            jurisdictions that do not allow for relinquishment or waiver.
            <br />
            2.2. Legal rights covered.
            <br />
            a. Copyright. Any copyright or neighbouring rights in the Work.
            Copyright law varies between jurisdictions, but is likely to cover:
            the Database model or schema, which is the structure, arrangement,
            and organisation of the Database, and can also include the Database
            tables and table indexes; the data entry and output sheets; and the
            Field names of Data stored in the Database. Copyright may also cover
            the Data depending on the jurisdiction and type of Data; and
            <br />
            b. Database Rights. Database Rights only extend to the extraction
            and re-utilisation of the whole or a substantial part of the Data.
            Database Rights can apply even when there is no copyright over the
            Database. Database Rights can also apply when the Data is removed
            from the Database and is selected and arranged in a way that would
            not infringe any applicable copyright.
            <br />
            2.2 Rights not covered.
            <br />
            a. This Document does not apply to computer programs used in the
            making or operation of the Database;
            <br />
            b. This Document does not cover any patents over the Data or the
            Database. Please see Section 4.2 later in this Document for further
            details; and
            <br />
            c. This Document does not cover any trade marks associated with the
            Database. Please see Section 4.3 later in this Document for further
            details.
            <br />
            Users of this Database are cautioned that they may have to clear
            other rights or consult other licences.
            <br />
            2.3 Facts are free. The Rightsholder takes the position that factual
            information is not covered by Copyright. This Document however
            covers the Work in jurisdictions that may protect the factual
            information in the Work by Copyright, and to cover any information
            protected by Copyright that is contained in the Work.
            <br />
            PART II: DEDICATION TO THE PUBLIC DOMAIN
            <br />
            3.0 Dedication, waiver, and licence of Copyright and Database Rights
            <br />
            3.1 Dedication of Copyright and Database Rights to the public
            domain. The Rightsholder by using this Document, dedicates the Work
            to the public domain for the benefit of the public and relinquishes
            all rights in Copyright and Database Rights over the Work.
            <br />
            a. The Rightsholder realises that once these rights are
            relinquished, that the Rightsholder has no further rights in
            Copyright and Database Rights over the Work, and that the Work is
            free and open for others to Use.
            <br />
            b. The Rightsholder intends for their relinquishment to cover all
            present and future rights in the Work under Copyright and Database
            Rights, whether they are vested or contingent rights, and that this
            relinquishment of rights covers all their heirs and successors.
            <br />
            The above relinquishment of rights applies worldwide and includes
            media and formats now known or created in the future.
            <br />
            3.2 Waiver of rights and claims in Copyright and Database Rights
            when Section 3.1 dedication inapplicable. If the dedication in
            Section 3.1 does not apply in the relevant jurisdiction under
            Section 6.4, the Rightsholder waives any rights and claims that the
            Rightsholder may have or acquire in the future over the Work in:
            <br />
            a. Copyright; and
            <br />
            b. Database Rights.
            <br />
            To the extent possible in the relevant jurisdiction, the above
            waiver of rights and claims applies worldwide and includes media and
            formats now known or created in the future. The Rightsholder agrees
            not to assert the above rights and waives the right to enforce them
            over the Work.
            <br />
            3.3 Licence of Copyright and Database Rights when Sections 3.1 and
            3.2 inapplicable. If the dedication and waiver in Sections 3.1 and
            3.2 does not apply in the relevant jurisdiction under Section 6.4,
            the Rightsholder and You agree as follows:
            <br />
            a. The Licensor grants to You a worldwide, royalty-free,
            non-exclusive, licence to Use the Work for the duration of any
            applicable Copyright and Database Rights. These rights explicitly
            include commercial use, and do not exclude any field of endeavour.
            To the extent possible in the relevant jurisdiction, these rights
            may be exercised in all media and formats whether now known or
            created in the future.
            <br />
            3.4 Moral rights. This section covers moral rights, including the
            right to be identified as the author of the Work or to object to
            treatment that would otherwise prejudice the author&rsquo;s honour
            and reputation, or any other derogatory treatment:
            <br />
            a. For jurisdictions allowing waiver of moral rights, Licensor
            waives all moral rights that Licensor may have in the Work to the
            fullest extent possible by the law of the relevant jurisdiction
            under Section 6.4;
            <br />
            b. If waiver of moral rights under Section 3.4 a in the relevant
            jurisdiction is not possible, Licensor agrees not to assert any
            moral rights over the Work and waives all claims in moral rights to
            the fullest extent possible by the law of the relevant jurisdiction
            under Section 6.4; and
            <br />
            c. For jurisdictions not allowing waiver or an agreement not to
            assert moral rights under Section 3.4 a and b, the author may retain
            their moral rights over the copyrighted aspects of the Work.
            <br />
            Please note that some jurisdictions do not allow for the waiver of
            moral rights, and so moral rights may still subsist over the work in
            some jurisdictions.
            <br />
            4.0 RELATIONSHIP TO OTHER RIGHTS
            <br />
            4.1 No other contractual conditions. The Rightsholder makes this
            Work available to You without any other contractual obligations,
            either express or implied. Any Community Norms statement associated
            with the Work is not a contract and does not form part of this
            Document.
            <br />
            4.2 Relationship to patents. This Document does not grant You a
            licence for any patents that the Rightsholder may own. Users of this
            Database are cautioned that they may have to clear other rights or
            consult other licences.
            <br />
            4.3 Relationship to trade marks. This Document does not grant You a
            licence for any trade marks that the Rightsholder may own or that
            the Rightsholder may use to cover the Work. Users of this Database
            are cautioned that they may have to clear other rights or consult
            other licences.
            <br />
            PART III: GENERAL PROVISIONS
            <br />
            5.0 WARRANTIES, DISCLAIMER, AND LIMITATION OF LIABILITY
            <br />
            5.1 The Work is provided by the Rightsholder &ldquo;as is&rdquo; and
            without any warranty of any kind, either express or implied, whether
            of title, of accuracy or completeness, of the presence of absence of
            errors, of fitness for purpose, or otherwise. Some jurisdictions do
            not allow the exclusion of implied warranties, so this exclusion may
            not apply to You.
            <br />
            5.2 Subject to any liability that may not be excluded or limited by
            law, the Rightsholder is not liable for, and expressly excludes, all
            liability for loss or damage however and whenever caused to anyone
            by any use under this Document, whether by You or by anyone else,
            and whether caused by any fault on the part of the Rightsholder or
            not. This exclusion of liability includes, but is not limited to,
            any special, incidental, consequential, punitive, or exemplary
            damages. This exclusion applies even if the Rightsholder has been
            advised of the possibility of such damages.
            <br />
            5.3 If liability may not be excluded by law, it is limited to actual
            and direct financial loss to the extent it is caused by proved
            negligence on the part of the Rightsholder.
            <br />
            6.0 GENERAL
            <br />
            6.1 If any provision of this Document is held to be invalid or
            unenforceable, that must not affect the validity or enforceability
            of the remainder of the terms of this Document.
            <br />
            6.2 This Document is the entire agreement between the parties with
            respect to the Work covered here. It replaces any earlier
            understandings, agreements or representations with respect to the
            Work not specified here.
            <br />
            6.3 This Document does not affect any rights that You or anyone else
            may independently have under any applicable law to make any use of
            this Work, including (for jurisdictions where this Document is a
            licence) fair dealing, fair use, database exceptions, or any other
            legally recognised limitation or exception to infringement of
            copyright or other applicable laws.
            <br />
            6.4 This Document takes effect in the relevant jurisdiction in which
            the Document terms are sought to be enforced. If the rights waived
            or granted under applicable law in the relevant jurisdiction
            includes additional rights not waived or granted under this
            Document, these additional rights are included in this Document in
            order to meet the intent of this Document.
          </p>
        </>
      );
    }
    case "11": {
      // CC-BY-3.0
      return (
        <>
          <p>
            You are free to:
            <br />
            Share &mdash; copy and redistribute the material in any medium or
            format for any purpose, even commercially.
            <br />
            Adapt &mdash; remix, transform, and build upon the material for any
            purpose, even commercially.
            <br />
            The licensor cannot revoke these freedoms as long as you follow the
            license terms.
            <br />
            Under the following terms:
            <br />
            Attribution &mdash; You must give appropriate credit , provide a
            link to the license, and indicate if changes were made . You may do
            so in any reasonable manner, but not in any way that suggests the
            licensor endorses you or your use.
            <br />
            No additional restrictions &mdash; You may not apply legal terms or
            technological measures that legally restrict others from doing
            anything the license permits.
            <br />
            Notices:
            <br />
            You do not have to comply with the license for elements of the
            material in the public domain or where your use is permitted by an
            applicable exception or limitation .<br />
            No warranties are given. The license may not give you all of the
            permissions necessary for your intended use. For example, other
            rights such as publicity, privacy, or moral rights may limit how you
            use the material.
          </p>
        </>
      );
    }
    case "12": {
      // CC-BY-3.0-IGO
      return (
        <>
          <p>
            You are free to:
            <br />
            Share &mdash; copy and redistribute the material in any medium or
            format for any purpose, even commercially.
            <br />
            Adapt &mdash; remix, transform, and build upon the material for any
            purpose, even commercially.
            <br />
            The licensor cannot revoke these freedoms as long as you follow the
            license terms.
            <br />
            Under the following terms:
            <br />
            Attribution &mdash; You must give appropriate credit , provide a
            link to the license, and indicate if changes were made . You may do
            so in any reasonable manner, but not in any way that suggests the
            licensor endorses you or your use.
            <br />
            No additional restrictions &mdash; You may not apply legal terms or
            technological measures that legally restrict others from doing
            anything the license permits.
            <br />
            Notices:
            <br />
            When the Licensor is an intergovernmental organization, disputes
            will be resolved by mediation and arbitration unless otherwise
            agreed.
            <br />
            You do not have to comply with the license for elements of the
            material in the public domain or where your use is permitted by an
            applicable exception or limitation .<br />
            No warranties are given. The license may not give you all of the
            permissions necessary for your intended use. For example, other
            rights such as publicity, privacy, or moral rights may limit how you
            use the material.
          </p>
        </>
      );
    }
    case "13": {
      // US-Government-Works
      return (
        <>
          <p>
            Restrictions on using U.S. government materials
            <br />
            Not everything that appears on a federal government website is a
            government work (something created by a U.S. government officer or
            employee as part of their official duties). Content on federal
            websites may include protected intellectual property used with the
            right holder's permission. Before using U.S. government materials
            such as text, trademarks, logos, or images, check with the federal
            agency or program that manages the website to make sure the
            materials are not restricted.
            <br />
            Publicity and privacy rights
            <br />
            On federal websites, other people may have rights to the work itself
            or how it is used, such as publicity or privacy rights under state
            law. These rights protect the interests of the person or people who
            may be the subject of the work. Learn more about copyright, privacy,
            and publicity rights from the Library of Congress.
            <br />
            Endorsement, trademarks, and agency logos
            <br />
            You cannot use government materials in a way that implies
            endorsement by a government agency, official, or employee. For
            example, using a photo in your advertisement of a government
            official wearing or using your product is not permitted.
            <br />
            You also cannot use federal government trademarks or federal
            government agency logos without permission. For example, in general,
            you cannot use an agency logo or trademark on your social media page
            to suggest endorsement or sponsorship by the agency.
            <br />
            Attribution
            <br />
            In some cases, agencies will have specific language that they
            request you include with the materials they allow you to use. In
            other cases, agencies may release copyright-protected materials
            under a Creative Commons &ldquo;CC-BY&rdquo; or similar license,
            which requires attribution for use.
            <br />
            State and local government materials
            <br />
            The U.S. government work designation does not apply to works of
            state and local governments, which may be protected by copyright.
            Contact your state or local government for more information about
            their copyrighted works.
            <br />
            Copyright laws differ internationally
            <br />
            U.S. copyright laws may not protect U.S. government works outside
            the country. But the work may be protected under the copyright laws
            of other jurisdictions when used in these jurisdictions. The U.S.
            government may assert copyright outside of the United States for
            U.S. government works.
          </p>
        </>
      );
    }
    case "14": {
      // CC-BY-NC-SA-3.0-IGO
      return (
        <>
          <p>
            You are free to:
            <br />
            Share &mdash; copy and redistribute the material in any medium or
            format
            <br />
            Adapt &mdash; remix, transform, and build upon the material
            <br />
            The licensor cannot revoke these freedoms as long as you follow the
            license terms.
            <br />
            Under the following terms:
            <br />
            Attribution &mdash; You must give appropriate credit , provide a
            link to the license, and indicate if changes were made . You may do
            so in any reasonable manner, but not in any way that suggests the
            licensor endorses you or your use.
            <br />
            NonCommercial &mdash; You may not use the material for commercial
            purposes .<br />
            ShareAlike &mdash; If you remix, transform, or build upon the
            material, you must distribute your contributions under the same
            license as the original.
            <br />
            No additional restrictions &mdash; You may not apply legal terms or
            technological measures that legally restrict others from doing
            anything the license permits.
            <br />
            Notices:
            <br />
            When the Licensor is an intergovernmental organization, disputes
            will be resolved by mediation and arbitration unless otherwise
            agreed.
            <br />
            You do not have to comply with the license for elements of the
            material in the public domain or where your use is permitted by an
            applicable exception or limitation .<br />
            No warranties are given. The license may not give you all of the
            permissions necessary for your intended use. For example, other
            rights such as publicity, privacy, or moral rights may limit how you
            use the material.
          </p>
        </>
      );
    }
    case "15": {
      // CDLA-Permissive-1.0
      return (
        <>
          <p>
            Community Data License Agreement &ndash; Permissive &ndash; Version
            1.0
            <br />
            This is the Community Data License Agreement &ndash; Permissive,
            Version 1.0 (&ldquo;Agreement&rdquo;). Data is provided to You under
            this Agreement by each of the Data Providers. Your exercise of any
            of the rights and permissions granted below constitutes Your
            acceptance and agreement to be bound by the terms and conditions of
            this Agreement.
            <br />
            The benefits that each Data Provider receives from making Data
            available and that You receive from Data or otherwise under these
            terms and conditions shall be deemed sufficient consideration for
            the formation of this Agreement. Accordingly, Data Provider(s) and
            You (the &ldquo;Parties&rdquo;) agree as follows:
            <br />
            Section 1. Definitions
            <br />
            1.1 &ldquo;Add&rdquo; means to supplement Data with Your own or
            someone else&rsquo;s Data, resulting in Your
            &ldquo;Additions.&rdquo; Additions do not include Results.
            <br />
            1.2 &ldquo;Computational Use&rdquo; means Your analysis (through the
            use of computational devices or otherwise) or other interpretation
            of Data. By way of example and not limitation, &ldquo;Computational
            Use&rdquo; includes the application of any computational analytical
            technique, the purpose of which is the analysis of any Data in
            digital form to generate information about Data such as patterns,
            trends, correlations, inferences, insights and attributes.
            <br />
            1.3 &ldquo;Data&rdquo; means the information (including
            copyrightable information, such as images or text), collectively or
            individually, whether created or gathered by a Data Provider or an
            Entity acting on its behalf, to which rights are granted under this
            Agreement.
            <br />
            1.4 &ldquo;Data Provider&rdquo; means any Entity (including any
            employee or contractor of such Entity authorized to Publish Data on
            behalf of such Entity) that Publishes Data under this Agreement
            prior to Your Receiving it.
            <br />
            1.5 &ldquo;Enhanced Data&rdquo; means the subset of Data that You
            Publish and that is composed of (a) Your Additions and/or (b)
            Modifications to Data You have received under this Agreement.
            <br />
            1.6 &ldquo;Entity&rdquo; means any natural person or organization
            that exists under the laws of the jurisdiction in which it is
            organized, together with all other entities that control, are
            controlled by, or are under common control with that entity. For the
            purposes of this definition, &ldquo;control&rdquo; means (a) the
            power, directly or indirectly, to cause the direction or management
            of such entity, whether by contract or otherwise, (b) the ownership
            of more than fifty percent (50%) of the outstanding shares or
            securities, (c) the beneficial ownership of such entity or, (d) the
            ability to appoint, whether by agreement or right, the majority of
            directors of an Entity.
            <br />
            1.7 &ldquo;Modify&rdquo; means to delete, erase, correct or
            re-arrange Data, resulting in &ldquo;Modifications.&rdquo;
            Modifications do not include Results.
            <br />
            1.8 &ldquo;Publish&rdquo; means to make all or a subset of Data
            (including Your Enhanced Data) available in any manner which enables
            its Use, including by providing a copy on physical media or remote
            access. For any form of Entity, that is to make the Data available
            to any individual who is not employed by that Entity or engaged as a
            contractor or agent to perform work on that Entity&rsquo;s behalf. A
            &ldquo;Publication&rdquo; occurs each time You Publish Data.
            <br />
            1.9 &ldquo;Receive&rdquo; or &ldquo;Receives&rdquo; means to have
            been given access to Data, locally or remotely.
            <br />
            1.10 &ldquo;Results&rdquo; means the outcomes or outputs that You
            obtain from Your Computational Use of Data. Results shall not
            include more than a de minimis portion of the Data on which the
            Computational Use is based.
            <br />
            1.11 &ldquo;Sui Generis Database Rights&rdquo; means rights, other
            than copyright, resulting from Directive 96/9/EC of the European
            Parliament and of the Council of 11 March 1996 on the legal
            protection of databases, as amended and/or succeeded, as well as
            other equivalent rights anywhere in the world.
            <br />
            1.12 &ldquo;Use&rdquo; means using Data (including accessing,
            copying, studying, reviewing, adapting, analyzing, evaluating, or
            making Computational Use of it), either by machines or humans, or a
            combination of both.
            <br />
            1.13 &ldquo;You&rdquo; or &ldquo;Your&rdquo; means any Entity that
            Receives Data under this Agreement.
            <br />
            Section 2. Right and License to Use and to Publish
            <br />
            2.1 Subject to the conditions set forth in Section 3 of this
            Agreement, Data Provider(s) hereby grant(s) to You a worldwide,
            non-exclusive, irrevocable (except as provided in Section 5) right
            to: (a) Use Data; and (b) Publish Data.
            <br />
            2.2 To the extent that the Data or the coordination, selection or
            arrangement of Data is protected or protectable under copyright, Sui
            Generis Database Rights, or other law, Data Provider(s) further
            agree(s) that such Data or coordination, selection or arrangement is
            hereby licensed to You and to anyone else who Receives Data under
            this Agreement for Use and Publication, subject to the conditions
            set forth in Section 3 of this Agreement.
            <br />
            2.3 Except for these rights and licenses expressly granted, no other
            intellectual property rights are granted or should be implied.
            <br />
            Section 3. Conditions on Rights Granted
            <br />
            3.1 If You Publish Data You Receive or Enhanced Data:
            <br />
            (a) You may do so under a license of Your choice provided that You
            give anyone who Receives the Data from You the text of this
            Agreement, the name of this Agreement and/or a hyperlink or other
            method reasonably likely to provide a copy of the text of this
            Agreement; and
            <br />
            (b) You must cause any Data files containing Enhanced Data to carry
            prominent notices that You have changed those files; and
            <br />
            (c) If You Publish Data You Receive, You must preserve all credit or
            attribution to the Data Provider(s). Such retained credit or
            attribution includes any of the following to the extent they exist
            in Data as You have Received it: legal notices or metadata;
            identification of the Data Provider(s); or hyperlinks to Data to the
            extent it is practical to do so.
            <br />
            3.2 You may provide additional or different license terms and
            conditions for use, reproduction, or distribution of that Enhanced
            Data, or for any combination of Data and Enhanced Data as a whole,
            provided that Your Use and Publication of that combined Data
            otherwise complies with the conditions stated in this License.
            <br />
            3.3 You and each Data Provider agree that Enhanced Data shall not be
            considered a work of joint authorship by virtue of its relationship
            to Data licensed under this Agreement and shall not require either
            any obligation of accounting to or the consent of any Data Provider.
            <br />
            3.4 This Agreement imposes no obligations or restrictions on Your
            Use or Publication of Results.
            <br />
            Section 4. Data Provider(s)&rsquo; Representations
            <br />
            4.1 Each Data Provider represents that the Data Provider has
            exercised reasonable care, to assure that: (a) the Data it Publishes
            was created or generated by it or was obtained from others with the
            right to Publish the Data under this Agreement; and (b) Publication
            of such Data does not violate any privacy or confidentiality
            obligation undertaken by the Data Provider.
            <br />
            Section 5. Termination
            <br />
            5.1 All of Your rights under this Agreement will terminate, and Your
            right to Receive, Use or Publish the Data will be revoked or
            modified if You materially fail to comply with the terms and
            conditions of this Agreement and You do not cure such failure in a
            reasonable period of time after becoming aware of such
            noncompliance. If Your rights under this Agreement terminate, You
            agree to cease Receipt, Use and Publication of Data. However, Your
            obligations and any rights and permissions granted by You under this
            Agreement relating to Data that You Published prior to such
            termination will continue and survive.
            <br />
            5.2 If You institute litigation against a Data Provider or anyone
            else who Receives the Data (including a cross-claim in a lawsuit)
            based on the Data, other than a claim asserting breach of this
            Agreement, then any rights previously granted to You to Receive, Use
            and Publish Data under this Agreement will terminate as of the date
            such litigation is filed.
            <br />
            Section 6. Disclaimer of Warranties and Limitation of Liability
            <br />
            6.1 EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, THE DATA
            (INCLUDING ENHANCED DATA) IS PROVIDED ON AN &ldquo;AS IS&rdquo;
            BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS
            OR IMPLIED INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OR
            CONDITIONS OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS
            FOR A PARTICULAR PURPOSE.
            <br />
            6.2 NEITHER YOU NOR ANY DATA PROVIDERS SHALL HAVE ANY LIABILITY FOR
            ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
            CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT LIMITATION LOST PROFITS),
            HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT,
            STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE)
            ARISING IN ANY WAY OUT OF THE USE OR DISTRIBUTION OF THE DATA OR THE
            EXERCISE OF ANY RIGHTS GRANTED HEREUNDER, EVEN IF ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
            <br />
            Section 7. Miscellaneous
            <br />
            7.1 You agree that it is solely Your responsibility to comply with
            all applicable laws with regard to Your Use or Publication of Data,
            including any applicable privacy, data protection, security and
            export laws. You agree to take reasonable steps to assist a Data
            Provider fulfilling responsibilities to comply with applicable laws
            with regard to Use or Publication of Data Received hereunder.
            <br />
            7.2 You and Data Provider(s), collectively and individually, waive
            and/or agree not to assert, to the extent permitted by law, any
            moral rights You or they hold in Data.
            <br />
            7.3 This Agreement confers no rights or remedies upon any person or
            entity other than the Parties and their respective heirs, executors,
            successors and assigns.
            <br />
            7.4 The Data Provider(s) reserve no right or expectation of privacy,
            data protection or confidentiality in any Data that they Publish
            under this Agreement. If You choose to Publish Data under this
            Agreement, You similarly do so with no reservation or expectation of
            any rights of privacy or confidentiality in that Data.
            <br />
            7.5 The Community Data License Agreement workgroup under The Linux
            Foundation is the steward of this Agreement (&ldquo;Steward&rdquo;).
            No one other than the Steward has the right to modify or publish new
            versions of this Agreement. Each version will be given a
            distinguishing version number. You may Use and Publish Data Received
            hereunder under the terms of the version of the Agreement under
            which You originally Received the Data, or under the terms of any
            subsequent version published by the Steward.
          </p>
        </>
      );
    }
    case "16": {
      // CDLA-Sharing-1.0
      return (
        <>
          <p>
            Community Data License Agreement &ndash; Sharing &ndash; Version 1.0
            <br />
            This is the Community Data License Agreement &ndash; Sharing,
            Version 1.0 (&ldquo;Agreement&rdquo;). Data is provided to You under
            this Agreement by each of the Data Providers. Your exercise of any
            of the rights and permissions granted below constitutes Your
            acceptance and agreement to be bound by the terms and conditions of
            this Agreement.
            <br />
            The benefits that each Data Provider receives from making Data
            available and that You receive from Data or otherwise under these
            terms and conditions shall be deemed sufficient consideration for
            the formation of this Agreement. Accordingly, Data Provider(s) and
            You (the &ldquo;Parties&rdquo;) agree as follows:
            <br />
            Section 1. Definitions
            <br />
            1.1 &ldquo;Add&rdquo; means to supplement Data with Your own or
            someone else&rsquo;s Data, resulting in Your
            &ldquo;Additions.&rdquo; Additions do not include Results.
            <br />
            1.2 &ldquo;Computational Use&rdquo; means Your analysis (through the
            use of computational devices or otherwise) or other interpretation
            of Data. By way of example and not limitation, &ldquo;Computational
            Use&rdquo; includes the application of any computational analytical
            technique, the purpose of which is the analysis of any Data in
            digital form to generate information about Data such as patterns,
            trends, correlations, inferences, insights and attributes.
            <br />
            1.3 &ldquo;Data&rdquo; means the information (including
            copyrightable information, such as images or text), collectively or
            individually, whether created or gathered by a Data Provider or an
            Entity acting on its behalf, to which rights are granted under this
            Agreement.
            <br />
            1.4 &ldquo;Data Provider&rdquo; means any Entity (including any
            employee or contractor of such Entity authorized to Publish Data on
            behalf of such Entity) that Publishes Data under this Agreement
            prior to Your Receiving it.
            <br />
            1.5 &ldquo;Enhanced Data&rdquo; means the subset of Data that You
            Publish and that is composed of (a) Your Additions and/or (b)
            Modifications to Data You have received under this Agreement.
            <br />
            1.6 &ldquo;Entity&rdquo; means any natural person or organization
            that exists under the laws of the jurisdiction in which it is
            organized, together with all other entities that control, are
            controlled by, or are under common control with that entity. For the
            purposes of this definition, &ldquo;control&rdquo; means (a) the
            power, directly or indirectly, to cause the direction or management
            of such entity, whether by contract or otherwise, (b) the ownership
            of more than fifty percent (50%) of the outstanding shares or
            securities, (c) the beneficial ownership of such entity or, (d) the
            ability to appoint, whether by agreement or right, the majority of
            directors of an Entity.
            <br />
            1.7 &ldquo;Ledger&rdquo; means a digital record of Data or grants of
            rights in Data governed by this Agreement, using any technology
            having functionality to record and store Data or grants,
            contributions, or licenses to Data governed by this Agreement.
            <br />
            1.8 &ldquo;Modify&rdquo; means to delete, erase, correct or
            re-arrange Data, resulting in &ldquo;Modifications.&rdquo;
            Modifications do not include Results.
            <br />
            1.9 &ldquo;Publish&rdquo; means to make all or a subset of Data
            (including Your Enhanced Data) available in any manner which enables
            its Use, including by providing a copy on physical media or remote
            access. For any form of Entity, that is to make the Data available
            to any individual who is not employed by that Entity or engaged as a
            contractor or agent to perform work on that Entity&rsquo;s behalf. A
            &ldquo;Publication&rdquo; occurs each time You Publish Data.
            <br />
            1.10 &ldquo;Receive&rdquo; or &ldquo;Receives&rdquo; means to have
            been given access to Data, locally or remotely.
            <br />
            1.11 &ldquo;Results&rdquo; means the outcomes or outputs that You
            obtain from Your Computational Use of Data. Results shall not
            include more than a de minimis portion of the Data on which the
            Computational Use is based.
            <br />
            1.12 &ldquo;Sui Generis Database Rights&rdquo; means rights, other
            than copyright, resulting from Directive 96/9/EC of the European
            Parliament and of the Council of 11 March 1996 on the legal
            protection of databases, as amended and/or succeeded, as well as
            other equivalent rights anywhere in the world.
            <br />
            1.13 &ldquo;Use&rdquo; means using Data (including accessing,
            copying, studying, reviewing, adapting, analyzing, evaluating, or
            making Computational Use of it), either by machines or humans, or a
            combination of both.
            <br />
            1.14 &ldquo;You&rdquo; or &ldquo;Your&rdquo; means any Entity that
            Receives Data under this Agreement.
            <br />
            Section 2. Right and License to Use and to Publish
            <br />
            2.1 Subject to the conditions set forth in Section 3 of this
            Agreement, Data Provider(s) hereby grant(s) to You a worldwide,
            non-exclusive, irrevocable (except as provided in Section 5) right
            to: (a) Use Data; and (b) Publish Data.
            <br />
            2.2 To the extent that the Data or the coordination, selection or
            arrangement of Data is protected or protectable under copyright, Sui
            Generis Database Rights, or other law, Data Provider(s) further
            agree(s) that such Data or coordination, selection or arrangement is
            hereby licensed to You and to anyone else who Receives Data under
            this Agreement for Use and Publication, subject to the conditions
            set forth in Section 3 of this Agreement.
            <br />
            2.3 Except for these rights and licenses expressly granted, no other
            intellectual property rights are granted or should be implied.
            <br />
            Section 3. Conditions on Rights Granted
            <br />
            3.1 If You Publish Data You Receive or Enhanced Data:
            <br />
            (a) The Data (including the Enhanced Data) must be Published under
            this Agreement in accordance with this Section 3; and
            <br />
            (b) You must cause any Data files containing Enhanced Data to carry
            prominent notices that You have changed those files; and
            <br />
            (c) If You Publish Data You Receive, You must preserve all credit or
            attribution to the Data Provider(s). Such retained credit or
            attribution includes any of the following to the extent they exist
            in Data as You have Received it: legal notices or metadata;
            identification of the Data Provider(s); or hyperlinks to Data to the
            extent it is practical to do so.
            <br />
            3.2 You may not restrict or deter the ability of anyone who Receives
            the Data (a) to Publish the Data in a publicly-accessible manner or
            (b) if the project has designated a Ledger for recording Data or
            grants of rights in Data for purposes of this Agreement, to record
            the Data or grants of rights in Data in the Ledger.
            <br />
            3.3 If You Publish Data You Receive, You must do so under an
            unmodified form of this Agreement and include the text of this
            Agreement, the name of this Agreement and/or a hyperlink or other
            method reasonably likely to provide a copy of the text of this
            Agreement. You may not modify this Agreement or impose any further
            restrictions on the exercise of the rights granted under this
            Agreement, including by adding any restriction on commercial or
            non-commercial Use of Data (including Your Enhanced Data) or by
            limiting permitted Use of such Data to any particular platform,
            technology or field of endeavor. Notices that purport to modify this
            Agreement shall be of no effect.
            <br />
            3.4 You and each Data Provider agree that Enhanced Data shall not be
            considered a work of joint authorship by virtue of its relationship
            to Data licensed under this Agreement and shall not require either
            any obligation of accounting to or the consent of any Data Provider.
            <br />
            3.5 This Agreement imposes no obligations or restrictions on Your
            Use or Publication of Results.
            <br />
            Section 4. Data Provider(s)&rsquo; Representations
            <br />
            4.1 Each Data Provider represents that the Data Provider has
            exercised reasonable care, to assure that: (a) the Data it Publishes
            was created or generated by it or was obtained from others with the
            right to Publish the Data under this Agreement; and (b) Publication
            of such Data does not violate any privacy or confidentiality
            obligation undertaken by the Data Provider.
            <br />
            Section 5. Termination
            <br />
            5.1 All of Your rights under this Agreement will terminate, and Your
            right to Receive, Use or Publish the Data will be revoked or
            modified if You materially fail to comply with the terms and
            conditions of this Agreement and You do not cure such failure in a
            reasonable period of time after becoming aware of such
            noncompliance. If Your rights under this Agreement terminate, You
            agree to cease Receipt, Use and Publication of Data. However, Your
            obligations and any rights and permissions granted by You under this
            Agreement relating to Data that You Published prior to such
            termination will continue and survive.
            <br />
            5.2 If You institute litigation against a Data Provider or anyone
            else who Receives the Data (including a cross-claim in a lawsuit)
            based on the Data, other than a claim asserting breach of this
            Agreement, then any rights previously granted to You to Receive, Use
            and Publish Data under this Agreement will terminate as of the date
            such litigation is filed.
            <br />
            Section 6. Disclaimer of Warranties and Limitation of Liability
            <br />
            6.1 EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, THE DATA
            (INCLUDING ENHANCED DATA) IS PROVIDED ON AN &ldquo;AS IS&rdquo;
            BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS
            OR IMPLIED INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OR
            CONDITIONS OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS
            FOR A PARTICULAR PURPOSE.
            <br />
            6.2 NEITHER YOU NOR ANY DATA PROVIDERS SHALL HAVE ANY LIABILITY FOR
            ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
            CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT LIMITATION LOST PROFITS),
            HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT,
            STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE)
            ARISING IN ANY WAY OUT OF THE USE OR DISTRIBUTION OF THE DATA OR THE
            EXERCISE OF ANY RIGHTS GRANTED HEREUNDER, EVEN IF ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
            <br />
            Section 7. Miscellaneous
            <br />
            7.1 You agree that it is solely Your responsibility to comply with
            all applicable laws with regard to Your Use or Publication of Data,
            including any applicable privacy, data protection, security and
            export laws. You agree to take reasonable steps to assist a Data
            Provider fulfilling responsibilities to comply with applicable laws
            with regard to Use or Publication of Data Received hereunder.
            <br />
            7.2 You and Data Provider(s), collectively and individually, waive
            and/or agree not to assert, to the extent permitted by law, any
            moral rights You or they hold in Data.
            <br />
            7.3 This Agreement confers no rights or remedies upon any person or
            entity other than the Parties and their respective heirs, executors,
            successors and assigns.
            <br />
            7.4 The Data Provider(s) reserve no right or expectation of privacy,
            data protection or confidentiality in any Data that they Publish
            under this Agreement. If You choose to Publish Data under this
            Agreement, You similarly do so with no reservation or expectation of
            any rights of privacy or confidentiality in that Data.
            <br />
            7.5 The Community Data License Agreement workgroup under The Linux
            Foundation is the steward of this Agreement (&ldquo;Steward&rdquo;).
            No one other than the Steward has the right to modify or publish new
            versions of this Agreement. Each version will be given a
            distinguishing version number. You may Use and Publish Data Received
            hereunder under the terms of the version of the Agreement under
            which You originally Received the Data, or under the terms of any
            subsequent version published by the Steward.
          </p>
        </>
      );
    }
    case "17": {
      // CC-BY-ND-4.0
      return (
        <>
          <p>
            You are free to:
            <br />
            Share &mdash; copy and redistribute the material in any medium or
            format for any purpose, even commercially.
            <br />
            The licensor cannot revoke these freedoms as long as you follow the
            license terms.
            <br />
            Under the following terms:
            <br />
            Attribution &mdash; You must give appropriate credit , provide a
            link to the license, and indicate if changes were made . You may do
            so in any reasonable manner, but not in any way that suggests the
            licensor endorses you or your use.
            <br />
            NoDerivatives &mdash; If you remix, transform, or build upon the
            material, you may not distribute the modified material.
            <br />
            No additional restrictions &mdash; You may not apply legal terms or
            technological measures that legally restrict others from doing
            anything the license permits.
            <br />
            Notices:
            <br />
            You do not have to comply with the license for elements of the
            material in the public domain or where your use is permitted by an
            applicable exception or limitation .<br />
            No warranties are given. The license may not give you all of the
            permissions necessary for your intended use. For example, other
            rights such as publicity, privacy, or moral rights may limit how you
            use the material.
          </p>
        </>
      );
    }
    case "18": {
      // CC-BY-NC-ND-4.0
      return (
        <>
          <p>
            You are free to:
            <br />
            Share &mdash; copy and redistribute the material in any medium or
            format
            <br />
            The licensor cannot revoke these freedoms as long as you follow the
            license terms.
            <br />
            Under the following terms:
            <br />
            Attribution &mdash; You must give appropriate credit , provide a
            link to the license, and indicate if changes were made . You may do
            so in any reasonable manner, but not in any way that suggests the
            licensor endorses you or your use.
            <br />
            NonCommercial &mdash; You may not use the material for commercial
            purposes .<br />
            NoDerivatives &mdash; If you remix, transform, or build upon the
            material, you may not distribute the modified material.
            <br />
            No additional restrictions &mdash; You may not apply legal terms or
            technological measures that legally restrict others from doing
            anything the license permits.
            <br />
            Notices:
            <br />
            You do not have to comply with the license for elements of the
            material in the public domain or where your use is permitted by an
            applicable exception or limitation .<br />
            No warranties are given. The license may not give you all of the
            permissions necessary for your intended use. For example, other
            rights such as publicity, privacy, or moral rights may limit how you
            use the material.
          </p>
        </>
      );
    }
    case "19": {
      // ODC-BY-1.0
      return (
        <>
          <p>
            PREAMBLE
            <br />
            The Open Data Commons Attribution License is a license agreement
            intended to allow users to freely share, modify, and use this
            Database subject only to the attribution requirements set out in
            Section 4.
            <br />
            Databases can contain a wide variety of types of content (images,
            audiovisual material, and sounds all in the same database, for
            example), and so this license only governs the rights over the
            Database, and not the contents of the Database individually.
            Licensors may therefore wish to use this license together with
            another license for the contents.
            <br />
            Sometimes the contents of a database, or the database itself, can be
            covered by other rights not addressed here (such as private
            contracts, trademark over the name, or privacy rights / data
            protection rights over information in the contents), and so you are
            advised that you may have to consult other documents or clear other
            rights before doing activities not covered by this License.
            <br />
            The Licensor (as defined below)
            <br />
            and
            <br />
            You (as defined below)
            <br />
            agree as follows:
            <br />
            1.0 DEFINITIONS OF CAPITALISED WORDS
            <br />
            &ldquo;Collective Database&rdquo; &ndash; Means this Database in
            unmodified form as part of a collection of independent databases in
            themselves that together are assembled into a collective whole. A
            work that constitutes a Collective Database will not be considered a
            Derivative Database.
            <br />
            &ldquo;Convey&rdquo; &ndash; As a verb, means Using the Database, a
            Derivative Database, or the Database as part of a Collective
            Database in any way that enables a Person to make or receive copies
            of the Database or a Derivative Database. Conveying does not include
            interaction with a user through a computer network, or creating and
            Using a Produced Work, where no transfer of a copy of the Database
            or a Derivative Database occurs.
            <br />
            &ldquo;Contents&rdquo; &ndash; The contents of this Database, which
            includes the information, independent works, or other material
            collected into the Database. For example, the contents of the
            Database could be factual data or works such as images, audiovisual
            material, text, or sounds.
            <br />
            &ldquo;Database&rdquo; &ndash; A collection of material (the
            Contents) arranged in a systematic or methodical way and
            individually accessible by electronic or other means offered under
            the terms of this License.
            <br />
            &ldquo;Database Directive&rdquo; &ndash; Means Directive 96/9/EC of
            the European Parliament and of the Council of 11 March 1996 on the
            legal protection of databases, as amended or succeeded.
            <br />
            &ldquo;Database Right&rdquo; &ndash; Means rights resulting from the
            Chapter III (&ldquo;sui generis&rdquo;) rights in the Database
            Directive (as amended and as transposed by member states), which
            includes the Extraction and Re-utilisation of the whole or a
            Substantial part of the Contents, as well as any similar rights
            available in the relevant jurisdiction under Section 10.4.
            <br />
            &ldquo;Derivative Database&rdquo; &ndash; Means a database based
            upon the Database, and includes any translation, adaptation,
            arrangement, modification, or any other alteration of the Database
            or of a Substantial part of the Contents. This includes, but is not
            limited to, Extracting or Re-utilising the whole or a Substantial
            part of the Contents in a new Database.
            <br />
            &ldquo;Extraction&rdquo; &ndash; Means the permanent or temporary
            transfer of all or a Substantial part of the Contents to another
            medium by any means or in any form.
            <br />
            &ldquo;License&rdquo; &ndash; Means this license agreement and is
            both a license of rights such as copyright and Database Rights and
            an agreement in contract.
            <br />
            &ldquo;Licensor&rdquo; &ndash; Means the Person that offers the
            Database under the terms of this License.
            <br />
            &ldquo;Person&rdquo; &ndash; Means a natural or legal person or a
            body of persons corporate or incorporate.
            <br />
            &ldquo;Produced Work&rdquo; &ndash; a work (such as an image,
            audiovisual material, text, or sounds) resulting from using the
            whole or a Substantial part of the Contents (via a search or other
            query) from this Database, a Derivative Database, or this Database
            as part of a Collective Database.
            <br />
            &ldquo;Publicly&rdquo; &ndash; means to Persons other than You or
            under Your control by either more than 50% ownership or by the power
            to direct their activities (such as contracting with an independent
            consultant).
            <br />
            &ldquo;Re-utilisation&rdquo; &ndash; means any form of making
            available to the public all or a Substantial part of the Contents by
            the distribution of copies, by renting, by online or other forms of
            transmission.
            <br />
            &ldquo;Substantial&rdquo; &ndash; Means substantial in terms of
            quantity or quality or a combination of both. The repeated and
            systematic Extraction or Re-utilisation of insubstantial parts of
            the Contents may amount to the Extraction or Re-utilisation of a
            Substantial part of the Contents.
            <br />
            &ldquo;Use&rdquo; &ndash; As a verb, means doing any act that is
            restricted by copyright or Database Rights whether in the original
            medium or any other; and includes without limitation distributing,
            copying, publicly performing, publicly displaying, and preparing
            derivative works of the Database, as well as modifying the Database
            as may be technically necessary to use it in a different mode or
            format.
            <br />
            &ldquo;You&rdquo; &ndash; Means a Person exercising rights under
            this License who has not previously violated the terms of this
            License with respect to the Database, or who has received express
            permission from the Licensor to exercise rights under this License
            despite a previous violation.
            <br />
            Words in the singular include the plural and vice versa.
            <br />
            2.0 WHAT THIS LICENSE COVERS
            <br />
            2.1. Legal effect of this document. This License is:
            <br />
            a. A license of applicable copyright and neighbouring rights;
            <br />
            b. A license of the Database Right; and
            <br />
            c. An agreement in contract between You and the Licensor.
            <br />
            2.2 Legal rights covered. This License covers the legal rights in
            the Database, including:
            <br />
            a. Copyright. Any copyright or neighbouring rights in the Database.
            The copyright licensed includes any individual elements of the
            Database, but does not cover the copyright over the Contents
            independent of this Database. See Section 2.4 for details. Copyright
            law varies between jurisdictions, but is likely to cover: the
            Database model or schema, which is the structure, arrangement, and
            organisation of the Database, and can also include the Database
            tables and table indexes; the data entry and output sheets; and the
            Field names of Contents stored in the Database;
            <br />
            b. Database Rights. Database Rights only extend to the Extraction
            and Re-utilisation of the whole or a Substantial part of the
            Contents. Database Rights can apply even when there is no copyright
            over the Database. Database Rights can also apply when the Contents
            are removed from the Database and are selected and arranged in a way
            that would not infringe any applicable copyright; and
            <br />
            c. Contract. This is an agreement between You and the Licensor for
            access to the Database. In return you agree to certain conditions of
            use on this access as outlined in this License.
            <br />
            2.3 Rights not covered.
            <br />
            a. This License does not apply to computer programs used in the
            making or operation of the Database;
            <br />
            b. This License does not cover any patents over the Contents or the
            Database; and
            <br />
            c. This License does not cover any trademarks associated with the
            Database.
            <br />
            2.4 Relationship to Contents in the Database. The individual items
            of the Contents contained in this Database may be covered by other
            rights, including copyright, patent, data protection, privacy, or
            personality rights, and this License does not cover any rights
            (other than Database Rights or in contract) in individual Contents
            contained in the Database.
            <br />
            For example, if used on a Database of images (the Contents), this
            License would not apply to copyright over individual images, which
            could have their own separate licenses, or one single license
            covering all of the rights over the images.
            <br />
            3.0 RIGHTS GRANTED
            <br />
            3.1 Subject to the terms and conditions of this License, the
            Licensor grants to You a worldwide, royalty-free, non-exclusive,
            terminable (but only under Section 9) license to Use the Database
            for the duration of any applicable copyright and Database Rights.
            These rights explicitly include commercial use, and do not exclude
            any field of endeavour. To the extent possible in the relevant
            jurisdiction, these rights may be exercised in all media and formats
            whether now known or created in the future.
            <br />
            The rights granted cover, for example:
            <br />
            a. Extraction and Re-utilisation of the whole or a Substantial part
            of the Contents;
            <br />
            b. Creation of Derivative Databases;
            <br />
            c. Creation of Collective Databases;
            <br />
            d. Creation of temporary or permanent reproductions by any means and
            in any form, in whole or in part, including of any Derivative
            Databases or as a part of Collective Databases; and
            <br />
            e. Distribution, communication, display, lending, making available,
            or performance to the public by any means and in any form, in whole
            or in part, including of any Derivative Database or as a part of
            Collective Databases.
            <br />
            3.2 Compulsory license schemes. For the avoidance of doubt:
            <br />
            a. Non-waivable compulsory license schemes. In those jurisdictions
            in which the right to collect royalties through any statutory or
            compulsory licensing scheme cannot be waived, the Licensor reserves
            the exclusive right to collect such royalties for any exercise by
            You of the rights granted under this License;
            <br />
            b. Waivable compulsory license schemes. In those jurisdictions in
            which the right to collect royalties through any statutory or
            compulsory licensing scheme can be waived, the Licensor waives the
            exclusive right to collect such royalties for any exercise by You of
            the rights granted under this License; and,
            <br />
            c. Voluntary license schemes. The Licensor waives the right to
            collect royalties, whether individually or, in the event that the
            Licensor is a member of a collecting society that administers
            voluntary licensing schemes, via that society, from any exercise by
            You of the rights granted under this License.
            <br />
            3.3 The right to release the Database under different terms, or to
            stop distributing or making available the Database, is reserved.
            Note that this Database may be multiple-licensed, and so You may
            have the choice of using alternative licenses for this Database.
            Subject to Section 10.4, all other rights not expressly granted by
            Licensor are reserved.
            <br />
            4.0 CONDITIONS OF USE
            <br />
            4.1 The rights granted in Section 3 above are expressly made subject
            to Your complying with the following conditions of use. These are
            important conditions of this License, and if You fail to follow
            them, You will be in material breach of its terms.
            <br />
            4.2 Notices. If You Publicly Convey this Database, any Derivative
            Database, or the Database as part of a Collective Database, then You
            must:
            <br />
            a. Do so only under the terms of this License;
            <br />
            b. Include a copy of this License or its Uniform Resource Identifier
            (URI) with the Database or Derivative Database, including both in
            the Database or Derivative Database and in any relevant
            documentation;
            <br />
            c. Keep intact any copyright or Database Right notices and notices
            that refer to this License; and
            <br />
            d. If it is not possible to put the required notices in a particular
            file due to its structure, then You must include the notices in a
            location (such as a relevant directory) where users would be likely
            to look for it.
            <br />
            4.3 Notice for using output (Contents). Creating and Using a
            Produced Work does not require the notice in Section 4.2. However,
            if you Publicly Use a Produced Work, You must include a notice
            associated with the Produced Work reasonably calculated to make any
            Person that uses, views, accesses, interacts with, or is otherwise
            exposed to the Produced Work aware that Content was obtained from
            the Database, Derivative Database, or the Database as part of a
            Collective Database, and that it is available under this License.
            <br />
            a. Example notice. The following text will satisfy notice under
            Section 4.3:
            <br />
            Contains information from DATABASE NAME which is made available
            <br />
            under the ODC Attribution License.
            <br />
            DATABASE NAME should be replaced with the name of the Database and a
            hyperlink to the location of the Database. &ldquo;ODC Attribution
            License&rdquo; should contain a hyperlink to the URI of the text of
            this License. If hyperlinks are not possible, You should include the
            plain text of the required URI&rsquo;s with the above notice.
            <br />
            4.4 Licensing of others. You may not sublicense the Database. Each
            time You communicate the Database, the whole or Substantial part of
            the Contents, or any Derivative Database to anyone else in any way,
            the Licensor offers to the recipient a license to the Database on
            the same terms and conditions as this License. You are not
            responsible for enforcing compliance by third parties with this
            License, but You may enforce any rights that You have over a
            Derivative Database. You are solely responsible for any
            modifications of a Derivative Database made by You or another Person
            at Your direction. You may not impose any further restrictions on
            the exercise of the rights granted or affirmed under this License.
            <br />
            5.0 MORAL RIGHTS
            <br />
            5.1 Moral rights. This section covers moral rights, including any
            rights to be identified as the author of the Database or to object
            to treatment that would otherwise prejudice the author&rsquo;s
            honour and reputation, or any other derogatory treatment:
            <br />
            a. For jurisdictions allowing waiver of moral rights, Licensor
            waives all moral rights that Licensor may have in the Database to
            the fullest extent possible by the law of the relevant jurisdiction
            under Section 10.4;
            <br />
            b. If waiver of moral rights under Section 5.1 a in the relevant
            jurisdiction is not possible, Licensor agrees not to assert any
            moral rights over the Database and waives all claims in moral rights
            to the fullest extent possible by the law of the relevant
            jurisdiction under Section 10.4; and
            <br />
            c. For jurisdictions not allowing waiver or an agreement not to
            assert moral rights under Section 5.1 a and b, the author may retain
            their moral rights over certain aspects of the Database.
            <br />
            Please note that some jurisdictions do not allow for the waiver of
            moral rights, and so moral rights may still subsist over the
            Database in some jurisdictions.
            <br />
            6.0 FAIR DEALING, DATABASE EXCEPTIONS, AND OTHER RIGHTS NOT AFFECTED
            <br />
            6.1 This License does not affect any rights that You or anyone else
            may independently have under any applicable law to make any use of
            this Database, including without limitation:
            <br />
            a. Exceptions to the Database Right including: Extraction of
            Contents from non-electronic Databases for private purposes,
            Extraction for purposes of illustration for teaching or scientific
            research, and Extraction or Re-utilisation for public security or an
            administrative or judicial procedure.
            <br />
            b. Fair dealing, fair use, or any other legally recognised
            limitation or exception to infringement of copyright or other
            applicable laws.
            <br />
            6.2 This License does not affect any rights of lawful users to
            Extract and Re-utilise insubstantial parts of the Contents,
            evaluated quantitatively or qualitatively, for any purposes
            whatsoever, including creating a Derivative Database (subject to
            other rights over the Contents, see Section 2.4). The repeated and
            systematic Extraction or Re-utilisation of insubstantial parts of
            the Contents may however amount to the Extraction or Re-utilisation
            of a Substantial part of the Contents.
            <br />
            7.0 WARRANTIES AND DISCLAIMER
            <br />
            7.1 The Database is licensed by the Licensor &ldquo;as is&rdquo; and
            without any warranty of any kind, either express, implied, or
            arising by statute, custom, course of dealing, or trade usage.
            Licensor specifically disclaims any and all implied warranties or
            conditions of title, non-infringement, accuracy or completeness, the
            presence or absence of errors, fitness for a particular purpose,
            merchantability, or otherwise. Some jurisdictions do not allow the
            exclusion of implied warranties, so this exclusion may not apply to
            You.
            <br />
            8.0 LIMITATION OF LIABILITY
            <br />
            8.1 Subject to any liability that may not be excluded or limited by
            law, the Licensor is not liable for, and expressly excludes, all
            liability for loss or damage however and whenever caused to anyone
            by any use under this License, whether by You or by anyone else, and
            whether caused by any fault on the part of the Licensor or not. This
            exclusion of liability includes, but is not limited to, any special,
            incidental, consequential, punitive, or exemplary damages such as
            loss of revenue, data, anticipated profits, and lost business. This
            exclusion applies even if the Licensor has been advised of the
            possibility of such damages.
            <br />
            8.2 If liability may not be excluded by law, it is limited to actual
            and direct financial loss to the extent it is caused by proved
            negligence on the part of the Licensor.
            <br />
            9.0 TERMINATION OF YOUR RIGHTS UNDER THIS LICENSE
            <br />
            9.1 Any breach by You of the terms and conditions of this License
            automatically terminates this License with immediate effect and
            without notice to You. For the avoidance of doubt, Persons who have
            received the Database, the whole or a Substantial part of the
            Contents, Derivative Databases, or the Database as part of a
            Collective Database from You under this License will not have their
            licenses terminated provided their use is in full compliance with
            this License or a license granted under Section 4.8 of this License.
            Sections 1, 2, 7, 8, 9 and 10 will survive any termination of this
            License.
            <br />
            9.2 If You are not in breach of the terms of this License, the
            Licensor will not terminate Your rights under it.
            <br />
            9.3 Unless terminated under Section 9.1, this License is granted to
            You for the duration of applicable rights in the Database.
            <br />
            9.4 Reinstatement of rights. If you cease any breach of the terms
            and conditions of this License, then your full rights under this
            License will be reinstated:
            <br />
            a. Provisionally and subject to permanent termination until the 60th
            day after cessation of breach;
            <br />
            b. Permanently on the 60th day after cessation of breach unless
            otherwise reasonably notified by the Licensor; or
            <br />
            c. Permanently if reasonably notified by the Licensor of the
            violation, this is the first time You have received notice of
            violation of this License from the Licensor, and You cure the
            violation prior to 30 days after your receipt of the notice.
            <br />
            9.5 Notwithstanding the above, Licensor reserves the right to
            release the Database under different license terms or to stop
            distributing or making available the Database. Releasing the
            Database under different license terms or stopping the distribution
            of the Database will not withdraw this License (or any other license
            that has been, or is required to be, granted under the terms of this
            License), and this License will continue in full force and effect
            unless terminated as stated above.
            <br />
            10.0 GENERAL
            <br />
            10.1 If any provision of this License is held to be invalid or
            unenforceable, that must not affect the validity or enforceability
            of the remainder of the terms and conditions of this License and
            each remaining provision of this License shall be valid and enforced
            to the fullest extent permitted by law.
            <br />
            10.2 This License is the entire agreement between the parties with
            respect to the rights granted here over the Database. It replaces
            any earlier understandings, agreements or representations with
            respect to the Database.
            <br />
            10.3 If You are in breach of the terms of this License, You will not
            be entitled to rely on the terms of this License or to complain of
            any breach by the Licensor.
            <br />
            10.4 Choice of law. This License takes effect in and will be
            governed by the laws of the relevant jurisdiction in which the
            License terms are sought to be enforced. If the standard suite of
            rights granted under applicable copyright law and Database Rights in
            the relevant jurisdiction includes additional rights not granted
            under this License, these additional rights are granted in this
            License in order to meet the terms of this License.
          </p>
        </>
      );
    }
    case "20": {
      // LGPL-3.0
      return (
        <>
          <p>
            Version 3, 29 June 2007
            <br />
            Copyright &copy; 2007 Free Software Foundation, Inc.
            &lt;https://fsf.org/&gt;
            <br />
            Everyone is permitted to copy and distribute verbatim copies of this
            license document, but changing it is not allowed.
            <br />
            This version of the GNU Lesser General Public License incorporates
            the terms and conditions of version 3 of the GNU General Public
            License, supplemented by the additional permissions listed below.
            <br />
            0. Additional Definitions.
            <br />
            As used herein, &ldquo;this License&rdquo; refers to version 3 of
            the GNU Lesser General Public License, and the &ldquo;GNU GPL&rdquo;
            refers to version 3 of the GNU General Public License.
            <br />
            &ldquo;The Library&rdquo; refers to a covered work governed by this
            License, other than an Application or a Combined Work as defined
            below.
            <br />
            An &ldquo;Application&rdquo; is any work that makes use of an
            interface provided by the Library, but which is not otherwise based
            on the Library. Defining a subclass of a class defined by the
            Library is deemed a mode of using an interface provided by the
            Library.
            <br />A &ldquo;Combined Work&rdquo; is a work produced by combining
            or linking an Application with the Library. The particular version
            of the Library with which the Combined Work was made is also called
            the &ldquo;Linked Version&rdquo;.
            <br />
            The &ldquo;Minimal Corresponding Source&rdquo; for a Combined Work
            means the Corresponding Source for the Combined Work, excluding any
            source code for portions of the Combined Work that, considered in
            isolation, are based on the Application, and not on the Linked
            Version.
            <br />
            The &ldquo;Corresponding Application Code&rdquo; for a Combined Work
            means the object code and/or source code for the Application,
            including any data and utility programs needed for reproducing the
            Combined Work from the Application, but excluding the System
            Libraries of the Combined Work.
            <br />
            1. Exception to Section 3 of the GNU GPL.
            <br />
            You may convey a covered work under sections 3 and 4 of this License
            without being bound by section 3 of the GNU GPL.
            <br />
            2. Conveying Modified Versions.
            <br />
            If you modify a copy of the Library, and, in your modifications, a
            facility refers to a function or data to be supplied by an
            Application that uses the facility (other than as an argument passed
            when the facility is invoked), then you may convey a copy of the
            modified version:
            <br />
            a) under this License, provided that you make a good faith effort to
            ensure that, in the event an Application does not supply the
            function or data, the facility still operates, and performs whatever
            part of its purpose remains meaningful, or
            <br />
            b) under the GNU GPL, with none of the additional permissions of
            this License applicable to that copy.
            <br />
            3. Object Code Incorporating Material from Library Header Files.
            <br />
            The object code form of an Application may incorporate material from
            a header file that is part of the Library. You may convey such
            object code under terms of your choice, provided that, if the
            incorporated material is not limited to numerical parameters, data
            structure layouts and accessors, or small macros, inline functions
            and templates (ten or fewer lines in length), you do both of the
            following:
            <br />
            a) Give prominent notice with each copy of the object code that the
            Library is used in it and that the Library and its use are covered
            by this License.
            <br />
            b) Accompany the object code with a copy of the GNU GPL and this
            license document.
            <br />
            4. Combined Works.
            <br />
            You may convey a Combined Work under terms of your choice that,
            taken together, effectively do not restrict modification of the
            portions of the Library contained in the Combined Work and reverse
            engineering for debugging such modifications, if you also do each of
            the following:
            <br />
            a) Give prominent notice with each copy of the Combined Work that
            the Library is used in it and that the Library and its use are
            covered by this License.
            <br />
            b) Accompany the Combined Work with a copy of the GNU GPL and this
            license document.
            <br />
            c) For a Combined Work that displays copyright notices during
            execution, include the copyright notice for the Library among these
            notices, as well as a reference directing the user to the copies of
            the GNU GPL and this license document.
            <br />
            d) Do one of the following:
            <br />
            0) Convey the Minimal Corresponding Source under the terms of this
            License, and the Corresponding Application Code in a form suitable
            for, and under terms that permit, the user to recombine or relink
            the Application with a modified version of the Linked Version to
            produce a modified Combined Work, in the manner specified by section
            6 of the GNU GPL for conveying Corresponding Source.
            <br />
            1) Use a suitable shared library mechanism for linking with the
            Library. A suitable mechanism is one that (a) uses at run time a
            copy of the Library already present on the user's computer system,
            and (b) will operate properly with a modified version of the Library
            that is interface-compatible with the Linked Version.
            <br />
            e) Provide Installation Information, but only if you would otherwise
            be required to provide such information under section 6 of the GNU
            GPL, and only to the extent that such information is necessary to
            install and execute a modified version of the Combined Work produced
            by recombining or relinking the Application with a modified version
            of the Linked Version. (If you use option 4d0, the Installation
            Information must accompany the Minimal Corresponding Source and
            Corresponding Application Code. If you use option 4d1, you must
            provide the Installation Information in the manner specified by
            section 6 of the GNU GPL for conveying Corresponding Source.)
            <br />
            5. Combined Libraries.
            <br />
            You may place library facilities that are a work based on the
            Library side by side in a single library together with other library
            facilities that are not Applications and are not covered by this
            License, and convey such a combined library under terms of your
            choice, if you do both of the following:
            <br />
            a) Accompany the combined library with a copy of the same work based
            on the Library, uncombined with any other library facilities,
            conveyed under the terms of this License.
            <br />
            b) Give prominent notice with the combined library that part of it
            is a work based on the Library, and explaining where to find the
            accompanying uncombined form of the same work.
            <br />
            6. Revised Versions of the GNU Lesser General Public License.
            <br />
            The Free Software Foundation may publish revised and/or new versions
            of the GNU Lesser General Public License from time to time. Such new
            versions will be similar in spirit to the present version, but may
            differ in detail to address new problems or concerns.
            <br />
            Each version is given a distinguishing version number. If the
            Library as you received it specifies that a certain numbered version
            of the GNU Lesser General Public License &ldquo;or any later
            version&rdquo; applies to it, you have the option of following the
            terms and conditions either of that published version or of any
            later version published by the Free Software Foundation. If the
            Library as you received it does not specify a version number of the
            GNU Lesser General Public License, you may choose any version of the
            GNU Lesser General Public License ever published by the Free
            Software Foundation.
            <br />
            If the Library as you received it specifies that a proxy can decide
            whether future versions of the GNU Lesser General Public License
            shall apply, that proxy's public statement of acceptance of any
            version is permanent authorization for you to choose that version
            for the Library.
          </p>
        </>
      );
    }
    case "21": {
      // AGPL-3.0
      return (
        <>
          <p>
            Version 3, 19 November 2007
            <br />
            Copyright &copy; 2007 Free Software Foundation, Inc.
            &lt;https://fsf.org/&gt;
            <br />
            Everyone is permitted to copy and distribute verbatim copies of this
            license document, but changing it is not allowed.
            <br />
            Preamble
            <br />
            The GNU Affero General Public License is a free, copyleft license
            for software and other kinds of works, specifically designed to
            ensure cooperation with the community in the case of network server
            software.
            <br />
            The licenses for most software and other practical works are
            designed to take away your freedom to share and change the works. By
            contrast, our General Public Licenses are intended to guarantee your
            freedom to share and change all versions of a program--to make sure
            it remains free software for all its users.
            <br />
            When we speak of free software, we are referring to freedom, not
            price. Our General Public Licenses are designed to make sure that
            you have the freedom to distribute copies of free software (and
            charge for them if you wish), that you receive source code or can
            get it if you want it, that you can change the software or use
            pieces of it in new free programs, and that you know you can do
            these things.
            <br />
            Developers that use our General Public Licenses protect your rights
            with two steps: (1) assert copyright on the software, and (2) offer
            you this License which gives you legal permission to copy,
            distribute and/or modify the software.
            <br />A secondary benefit of defending all users' freedom is that
            improvements made in alternate versions of the program, if they
            receive widespread use, become available for other developers to
            incorporate. Many developers of free software are heartened and
            encouraged by the resulting cooperation. However, in the case of
            software used on network servers, this result may fail to come
            about. The GNU General Public License permits making a modified
            version and letting the public access it on a server without ever
            releasing its source code to the public.
            <br />
            The GNU Affero General Public License is designed specifically to
            ensure that, in such cases, the modified source code becomes
            available to the community. It requires the operator of a network
            server to provide the source code of the modified version running
            there to the users of that server. Therefore, public use of a
            modified version, on a publicly accessible server, gives the public
            access to the source code of the modified version.
            <br />
            An older license, called the Affero General Public License and
            published by Affero, was designed to accomplish similar goals. This
            is a different license, not a version of the Affero GPL, but Affero
            has released a new version of the Affero GPL which permits
            relicensing under this license.
            <br />
            The precise terms and conditions for copying, distribution and
            modification follow.
            <br />
            TERMS AND CONDITIONS
            <br />
            0. Definitions.
            <br />
            "This License" refers to version 3 of the GNU Affero General Public
            License.
            <br />
            "Copyright" also means copyright-like laws that apply to other kinds
            of works, such as semiconductor masks.
            <br />
            "The Program" refers to any copyrightable work licensed under this
            License. Each licensee is addressed as "you". "Licensees" and
            "recipients" may be individuals or organizations.
            <br />
            To "modify" a work means to copy from or adapt all or part of the
            work in a fashion requiring copyright permission, other than the
            making of an exact copy. The resulting work is called a "modified
            version" of the earlier work or a work "based on" the earlier work.
            <br />A "covered work" means either the unmodified Program or a work
            based on the Program.
            <br />
            To "propagate" a work means to do anything with it that, without
            permission, would make you directly or secondarily liable for
            infringement under applicable copyright law, except executing it on
            a computer or modifying a private copy. Propagation includes
            copying, distribution (with or without modification), making
            available to the public, and in some countries other activities as
            well.
            <br />
            To "convey" a work means any kind of propagation that enables other
            parties to make or receive copies. Mere interaction with a user
            through a computer network, with no transfer of a copy, is not
            conveying.
            <br />
            An interactive user interface displays "Appropriate Legal Notices"
            to the extent that it includes a convenient and prominently visible
            feature that (1) displays an appropriate copyright notice, and (2)
            tells the user that there is no warranty for the work (except to the
            extent that warranties are provided), that licensees may convey the
            work under this License, and how to view a copy of this License. If
            the interface presents a list of user commands or options, such as a
            menu, a prominent item in the list meets this criterion.
            <br />
            1. Source Code.
            <br />
            The "source code" for a work means the preferred form of the work
            for making modifications to it. "Object code" means any non-source
            form of a work.
            <br />A "Standard Interface" means an interface that either is an
            official standard defined by a recognized standards body, or, in the
            case of interfaces specified for a particular programming language,
            one that is widely used among developers working in that language.
            <br />
            The "System Libraries" of an executable work include anything, other
            than the work as a whole, that (a) is included in the normal form of
            packaging a Major Component, but which is not part of that Major
            Component, and (b) serves only to enable use of the work with that
            Major Component, or to implement a Standard Interface for which an
            implementation is available to the public in source code form. A
            "Major Component", in this context, means a major essential
            component (kernel, window system, and so on) of the specific
            operating system (if any) on which the executable work runs, or a
            compiler used to produce the work, or an object code interpreter
            used to run it.
            <br />
            The "Corresponding Source" for a work in object code form means all
            the source code needed to generate, install, and (for an executable
            work) run the object code and to modify the work, including scripts
            to control those activities. However, it does not include the work's
            System Libraries, or general-purpose tools or generally available
            free programs which are used unmodified in performing those
            activities but which are not part of the work. For example,
            Corresponding Source includes interface definition files associated
            with source files for the work, and the source code for shared
            libraries and dynamically linked subprograms that the work is
            specifically designed to require, such as by intimate data
            communication or control flow between those subprograms and other
            parts of the work.
            <br />
            The Corresponding Source need not include anything that users can
            regenerate automatically from other parts of the Corresponding
            Source.
            <br />
            The Corresponding Source for a work in source code form is that same
            work.
            <br />
            2. Basic Permissions.
            <br />
            All rights granted under this License are granted for the term of
            copyright on the Program, and are irrevocable provided the stated
            conditions are met. This License explicitly affirms your unlimited
            permission to run the unmodified Program. The output from running a
            covered work is covered by this License only if the output, given
            its content, constitutes a covered work. This License acknowledges
            your rights of fair use or other equivalent, as provided by
            copyright law.
            <br />
            You may make, run and propagate covered works that you do not
            convey, without conditions so long as your license otherwise remains
            in force. You may convey covered works to others for the sole
            purpose of having them make modifications exclusively for you, or
            provide you with facilities for running those works, provided that
            you comply with the terms of this License in conveying all material
            for which you do not control copyright. Those thus making or running
            the covered works for you must do so exclusively on your behalf,
            under your direction and control, on terms that prohibit them from
            making any copies of your copyrighted material outside their
            relationship with you.
            <br />
            Conveying under any other circumstances is permitted solely under
            the conditions stated below. Sublicensing is not allowed; section 10
            makes it unnecessary.
            <br />
            3. Protecting Users' Legal Rights From Anti-Circumvention Law.
            <br />
            No covered work shall be deemed part of an effective technological
            measure under any applicable law fulfilling obligations under
            article 11 of the WIPO copyright treaty adopted on 20 December 1996,
            or similar laws prohibiting or restricting circumvention of such
            measures.
            <br />
            When you convey a covered work, you waive any legal power to forbid
            circumvention of technological measures to the extent such
            circumvention is effected by exercising rights under this License
            with respect to the covered work, and you disclaim any intention to
            limit operation or modification of the work as a means of enforcing,
            against the work's users, your or third parties' legal rights to
            forbid circumvention of technological measures.
            <br />
            4. Conveying Verbatim Copies.
            <br />
            You may convey verbatim copies of the Program's source code as you
            receive it, in any medium, provided that you conspicuously and
            appropriately publish on each copy an appropriate copyright notice;
            keep intact all notices stating that this License and any
            non-permissive terms added in accord with section 7 apply to the
            code; keep intact all notices of the absence of any warranty; and
            give all recipients a copy of this License along with the Program.
            <br />
            You may charge any price or no price for each copy that you convey,
            and you may offer support or warranty protection for a fee.
            <br />
            5. Conveying Modified Source Versions.
            <br />
            You may convey a work based on the Program, or the modifications to
            produce it from the Program, in the form of source code under the
            terms of section 4, provided that you also meet all of these
            conditions:
            <br />
            a) The work must carry prominent notices stating that you modified
            it, and giving a relevant date.
            <br />
            b) The work must carry prominent notices stating that it is released
            under this License and any conditions added under section 7. This
            requirement modifies the requirement in section 4 to "keep intact
            all notices".
            <br />
            c) You must license the entire work, as a whole, under this License
            to anyone who comes into possession of a copy. This License will
            therefore apply, along with any applicable section 7 additional
            terms, to the whole of the work, and all its parts, regardless of
            how they are packaged. This License gives no permission to license
            the work in any other way, but it does not invalidate such
            permission if you have separately received it.
            <br />
            d) If the work has interactive user interfaces, each must display
            Appropriate Legal Notices; however, if the Program has interactive
            interfaces that do not display Appropriate Legal Notices, your work
            need not make them do so.
            <br />A compilation of a covered work with other separate and
            independent works, which are not by their nature extensions of the
            covered work, and which are not combined with it such as to form a
            larger program, in or on a volume of a storage or distribution
            medium, is called an "aggregate" if the compilation and its
            resulting copyright are not used to limit the access or legal rights
            of the compilation's users beyond what the individual works permit.
            Inclusion of a covered work in an aggregate does not cause this
            License to apply to the other parts of the aggregate.
            <br />
            6. Conveying Non-Source Forms.
            <br />
            You may convey a covered work in object code form under the terms of
            sections 4 and 5, provided that you also convey the machine-readable
            Corresponding Source under the terms of this License, in one of
            these ways:
            <br />
            a) Convey the object code in, or embodied in, a physical product
            (including a physical distribution medium), accompanied by the
            Corresponding Source fixed on a durable physical medium customarily
            used for software interchange.
            <br />
            b) Convey the object code in, or embodied in, a physical product
            (including a physical distribution medium), accompanied by a written
            offer, valid for at least three years and valid for as long as you
            offer spare parts or customer support for that product model, to
            give anyone who possesses the object code either (1) a copy of the
            Corresponding Source for all the software in the product that is
            covered by this License, on a durable physical medium customarily
            used for software interchange, for a price no more than your
            reasonable cost of physically performing this conveying of source,
            or (2) access to copy the Corresponding Source from a network server
            at no charge.
            <br />
            c) Convey individual copies of the object code with a copy of the
            written offer to provide the Corresponding Source. This alternative
            is allowed only occasionally and noncommercially, and only if you
            received the object code with such an offer, in accord with
            subsection 6b.
            <br />
            d) Convey the object code by offering access from a designated place
            (gratis or for a charge), and offer equivalent access to the
            Corresponding Source in the same way through the same place at no
            further charge. You need not require recipients to copy the
            Corresponding Source along with the object code. If the place to
            copy the object code is a network server, the Corresponding Source
            may be on a different server (operated by you or a third party) that
            supports equivalent copying facilities, provided you maintain clear
            directions next to the object code saying where to find the
            Corresponding Source. Regardless of what server hosts the
            Corresponding Source, you remain obligated to ensure that it is
            available for as long as needed to satisfy these requirements.
            <br />
            e) Convey the object code using peer-to-peer transmission, provided
            you inform other peers where the object code and Corresponding
            Source of the work are being offered to the general public at no
            charge under subsection 6d.
            <br />A separable portion of the object code, whose source code is
            excluded from the Corresponding Source as a System Library, need not
            be included in conveying the object code work.
            <br />A "User Product" is either (1) a "consumer product", which
            means any tangible personal property which is normally used for
            personal, family, or household purposes, or (2) anything designed or
            sold for incorporation into a dwelling. In determining whether a
            product is a consumer product, doubtful cases shall be resolved in
            favor of coverage. For a particular product received by a particular
            user, "normally used" refers to a typical or common use of that
            class of product, regardless of the status of the particular user or
            of the way in which the particular user actually uses, or expects or
            is expected to use, the product. A product is a consumer product
            regardless of whether the product has substantial commercial,
            industrial or non-consumer uses, unless such uses represent the only
            significant mode of use of the product.
            <br />
            "Installation Information" for a User Product means any methods,
            procedures, authorization keys, or other information required to
            install and execute modified versions of a covered work in that User
            Product from a modified version of its Corresponding Source. The
            information must suffice to ensure that the continued functioning of
            the modified object code is in no case prevented or interfered with
            solely because modification has been made.
            <br />
            If you convey an object code work under this section in, or with, or
            specifically for use in, a User Product, and the conveying occurs as
            part of a transaction in which the right of possession and use of
            the User Product is transferred to the recipient in perpetuity or
            for a fixed term (regardless of how the transaction is
            characterized), the Corresponding Source conveyed under this section
            must be accompanied by the Installation Information. But this
            requirement does not apply if neither you nor any third party
            retains the ability to install modified object code on the User
            Product (for example, the work has been installed in ROM).
            <br />
            The requirement to provide Installation Information does not include
            a requirement to continue to provide support service, warranty, or
            updates for a work that has been modified or installed by the
            recipient, or for the User Product in which it has been modified or
            installed. Access to a network may be denied when the modification
            itself materially and adversely affects the operation of the network
            or violates the rules and protocols for communication across the
            network.
            <br />
            Corresponding Source conveyed, and Installation Information
            provided, in accord with this section must be in a format that is
            publicly documented (and with an implementation available to the
            public in source code form), and must require no special password or
            key for unpacking, reading or copying.
            <br />
            7. Additional Terms.
            <br />
            "Additional permissions" are terms that supplement the terms of this
            License by making exceptions from one or more of its conditions.
            Additional permissions that are applicable to the entire Program
            shall be treated as though they were included in this License, to
            the extent that they are valid under applicable law. If additional
            permissions apply only to part of the Program, that part may be used
            separately under those permissions, but the entire Program remains
            governed by this License without regard to the additional
            permissions.
            <br />
            When you convey a copy of a covered work, you may at your option
            remove any additional permissions from that copy, or from any part
            of it. (Additional permissions may be written to require their own
            removal in certain cases when you modify the work.) You may place
            additional permissions on material, added by you to a covered work,
            for which you have or can give appropriate copyright permission.
            <br />
            Notwithstanding any other provision of this License, for material
            you add to a covered work, you may (if authorized by the copyright
            holders of that material) supplement the terms of this License with
            terms:
            <br />
            a) Disclaiming warranty or limiting liability differently from the
            terms of sections 15 and 16 of this License; or
            <br />
            b) Requiring preservation of specified reasonable legal notices or
            author attributions in that material or in the Appropriate Legal
            Notices displayed by works containing it; or
            <br />
            c) Prohibiting misrepresentation of the origin of that material, or
            requiring that modified versions of such material be marked in
            reasonable ways as different from the original version; or
            <br />
            d) Limiting the use for publicity purposes of names of licensors or
            authors of the material; or
            <br />
            e) Declining to grant rights under trademark law for use of some
            trade names, trademarks, or service marks; or
            <br />
            f) Requiring indemnification of licensors and authors of that
            material by anyone who conveys the material (or modified versions of
            it) with contractual assumptions of liability to the recipient, for
            any liability that these contractual assumptions directly impose on
            those licensors and authors.
            <br />
            All other non-permissive additional terms are considered "further
            restrictions" within the meaning of section 10. If the Program as
            you received it, or any part of it, contains a notice stating that
            it is governed by this License along with a term that is a further
            restriction, you may remove that term. If a license document
            contains a further restriction but permits relicensing or conveying
            under this License, you may add to a covered work material governed
            by the terms of that license document, provided that the further
            restriction does not survive such relicensing or conveying.
            <br />
            If you add terms to a covered work in accord with this section, you
            must place, in the relevant source files, a statement of the
            additional terms that apply to those files, or a notice indicating
            where to find the applicable terms.
            <br />
            Additional terms, permissive or non-permissive, may be stated in the
            form of a separately written license, or stated as exceptions; the
            above requirements apply either way.
            <br />
            8. Termination.
            <br />
            You may not propagate or modify a covered work except as expressly
            provided under this License. Any attempt otherwise to propagate or
            modify it is void, and will automatically terminate your rights
            under this License (including any patent licenses granted under the
            third paragraph of section 11).
            <br />
            However, if you cease all violation of this License, then your
            license from a particular copyright holder is reinstated (a)
            provisionally, unless and until the copyright holder explicitly and
            finally terminates your license, and (b) permanently, if the
            copyright holder fails to notify you of the violation by some
            reasonable means prior to 60 days after the cessation.
            <br />
            Moreover, your license from a particular copyright holder is
            reinstated permanently if the copyright holder notifies you of the
            violation by some reasonable means, this is the first time you have
            received notice of violation of this License (for any work) from
            that copyright holder, and you cure the violation prior to 30 days
            after your receipt of the notice.
            <br />
            Termination of your rights under this section does not terminate the
            licenses of parties who have received copies or rights from you
            under this License. If your rights have been terminated and not
            permanently reinstated, you do not qualify to receive new licenses
            for the same material under section 10.
            <br />
            9. Acceptance Not Required for Having Copies.
            <br />
            You are not required to accept this License in order to receive or
            run a copy of the Program. Ancillary propagation of a covered work
            occurring solely as a consequence of using peer-to-peer transmission
            to receive a copy likewise does not require acceptance. However,
            nothing other than this License grants you permission to propagate
            or modify any covered work. These actions infringe copyright if you
            do not accept this License. Therefore, by modifying or propagating a
            covered work, you indicate your acceptance of this License to do so.
            <br />
            10. Automatic Licensing of Downstream Recipients.
            <br />
            Each time you convey a covered work, the recipient automatically
            receives a license from the original licensors, to run, modify and
            propagate that work, subject to this License. You are not
            responsible for enforcing compliance by third parties with this
            License.
            <br />
            An "entity transaction" is a transaction transferring control of an
            organization, or substantially all assets of one, or subdividing an
            organization, or merging organizations. If propagation of a covered
            work results from an entity transaction, each party to that
            transaction who receives a copy of the work also receives whatever
            licenses to the work the party's predecessor in interest had or
            could give under the previous paragraph, plus a right to possession
            of the Corresponding Source of the work from the predecessor in
            interest, if the predecessor has it or can get it with reasonable
            efforts.
            <br />
            You may not impose any further restrictions on the exercise of the
            rights granted or affirmed under this License. For example, you may
            not impose a license fee, royalty, or other charge for exercise of
            rights granted under this License, and you may not initiate
            litigation (including a cross-claim or counterclaim in a lawsuit)
            alleging that any patent claim is infringed by making, using,
            selling, offering for sale, or importing the Program or any portion
            of it.
            <br />
            11. Patents.
            <br />A "contributor" is a copyright holder who authorizes use under
            this License of the Program or a work on which the Program is based.
            The work thus licensed is called the contributor's "contributor
            version".
            <br />A contributor's "essential patent claims" are all patent
            claims owned or controlled by the contributor, whether already
            acquired or hereafter acquired, that would be infringed by some
            manner, permitted by this License, of making, using, or selling its
            contributor version, but do not include claims that would be
            infringed only as a consequence of further modification of the
            contributor version. For purposes of this definition, "control"
            includes the right to grant patent sublicenses in a manner
            consistent with the requirements of this License.
            <br />
            Each contributor grants you a non-exclusive, worldwide, royalty-free
            patent license under the contributor's essential patent claims, to
            make, use, sell, offer for sale, import and otherwise run, modify
            and propagate the contents of its contributor version.
            <br />
            In the following three paragraphs, a "patent license" is any express
            agreement or commitment, however denominated, not to enforce a
            patent (such as an express permission to practice a patent or
            covenant not to sue for patent infringement). To "grant" such a
            patent license to a party means to make such an agreement or
            commitment not to enforce a patent against the party.
            <br />
            If you convey a covered work, knowingly relying on a patent license,
            and the Corresponding Source of the work is not available for anyone
            to copy, free of charge and under the terms of this License, through
            a publicly available network server or other readily accessible
            means, then you must either (1) cause the Corresponding Source to be
            so available, or (2) arrange to deprive yourself of the benefit of
            the patent license for this particular work, or (3) arrange, in a
            manner consistent with the requirements of this License, to extend
            the patent license to downstream recipients. "Knowingly relying"
            means you have actual knowledge that, but for the patent license,
            your conveying the covered work in a country, or your recipient's
            use of the covered work in a country, would infringe one or more
            identifiable patents in that country that you have reason to believe
            are valid.
            <br />
            If, pursuant to or in connection with a single transaction or
            arrangement, you convey, or propagate by procuring conveyance of, a
            covered work, and grant a patent license to some of the parties
            receiving the covered work authorizing them to use, propagate,
            modify or convey a specific copy of the covered work, then the
            patent license you grant is automatically extended to all recipients
            of the covered work and works based on it.
            <br />A patent license is "discriminatory" if it does not include
            within the scope of its coverage, prohibits the exercise of, or is
            conditioned on the non-exercise of one or more of the rights that
            are specifically granted under this License. You may not convey a
            covered work if you are a party to an arrangement with a third party
            that is in the business of distributing software, under which you
            make payment to the third party based on the extent of your activity
            of conveying the work, and under which the third party grants, to
            any of the parties who would receive the covered work from you, a
            discriminatory patent license (a) in connection with copies of the
            covered work conveyed by you (or copies made from those copies), or
            (b) primarily for and in connection with specific products or
            compilations that contain the covered work, unless you entered into
            that arrangement, or that patent license was granted, prior to 28
            March 2007.
            <br />
            Nothing in this License shall be construed as excluding or limiting
            any implied license or other defenses to infringement that may
            otherwise be available to you under applicable patent law.
            <br />
            12. No Surrender of Others' Freedom.
            <br />
            If conditions are imposed on you (whether by court order, agreement
            or otherwise) that contradict the conditions of this License, they
            do not excuse you from the conditions of this License. If you cannot
            convey a covered work so as to satisfy simultaneously your
            obligations under this License and any other pertinent obligations,
            then as a consequence you may not convey it at all. For example, if
            you agree to terms that obligate you to collect a royalty for
            further conveying from those to whom you convey the Program, the
            only way you could satisfy both those terms and this License would
            be to refrain entirely from conveying the Program.
            <br />
            13. Remote Network Interaction; Use with the GNU General Public
            License.
            <br />
            Notwithstanding any other provision of this License, if you modify
            the Program, your modified version must prominently offer all users
            interacting with it remotely through a computer network (if your
            version supports such interaction) an opportunity to receive the
            Corresponding Source of your version by providing access to the
            Corresponding Source from a network server at no charge, through
            some standard or customary means of facilitating copying of
            software. This Corresponding Source shall include the Corresponding
            Source for any work covered by version 3 of the GNU General Public
            License that is incorporated pursuant to the following paragraph.
            <br />
            Notwithstanding any other provision of this License, you have
            permission to link or combine any covered work with a work licensed
            under version 3 of the GNU General Public License into a single
            combined work, and to convey the resulting work. The terms of this
            License will continue to apply to the part which is the covered
            work, but the work with which it is combined will remain governed by
            version 3 of the GNU General Public License.
            <br />
            14. Revised Versions of this License.
            <br />
            The Free Software Foundation may publish revised and/or new versions
            of the GNU Affero General Public License from time to time. Such new
            versions will be similar in spirit to the present version, but may
            differ in detail to address new problems or concerns.
            <br />
            Each version is given a distinguishing version number. If the
            Program specifies that a certain numbered version of the GNU Affero
            General Public License "or any later version" applies to it, you
            have the option of following the terms and conditions either of that
            numbered version or of any later version published by the Free
            Software Foundation. If the Program does not specify a version
            number of the GNU Affero General Public License, you may choose any
            version ever published by the Free Software Foundation.
            <br />
            If the Program specifies that a proxy can decide which future
            versions of the GNU Affero General Public License can be used, that
            proxy's public statement of acceptance of a version permanently
            authorizes you to choose that version for the Program.
            <br />
            Later license versions may give you additional or different
            permissions. However, no additional obligations are imposed on any
            author or copyright holder as a result of your choosing to follow a
            later version.
            <br />
            15. Disclaimer of Warranty.
            <br />
            THERE IS NO WARRANTY FOR THE PROGRAM, TO THE EXTENT PERMITTED BY
            APPLICABLE LAW. EXCEPT WHEN OTHERWISE STATED IN WRITING THE
            COPYRIGHT HOLDERS AND/OR OTHER PARTIES PROVIDE THE PROGRAM "AS IS"
            WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. THE ENTIRE
            RISK AS TO THE QUALITY AND PERFORMANCE OF THE PROGRAM IS WITH YOU.
            SHOULD THE PROGRAM PROVE DEFECTIVE, YOU ASSUME THE COST OF ALL
            NECESSARY SERVICING, REPAIR OR CORRECTION.
            <br />
            16. Limitation of Liability.
            <br />
            IN NO EVENT UNLESS REQUIRED BY APPLICABLE LAW OR AGREED TO IN
            WRITING WILL ANY COPYRIGHT HOLDER, OR ANY OTHER PARTY WHO MODIFIES
            AND/OR CONVEYS THE PROGRAM AS PERMITTED ABOVE, BE LIABLE TO YOU FOR
            DAMAGES, INCLUDING ANY GENERAL, SPECIAL, INCIDENTAL OR CONSEQUENTIAL
            DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE THE PROGRAM
            (INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR DATA BEING RENDERED
            INACCURATE OR LOSSES SUSTAINED BY YOU OR THIRD PARTIES OR A FAILURE
            OF THE PROGRAM TO OPERATE WITH ANY OTHER PROGRAMS), EVEN IF SUCH
            HOLDER OR OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES.
            <br />
            17. Interpretation of Sections 15 and 16.
            <br />
            If the disclaimer of warranty and limitation of liability provided
            above cannot be given local legal effect according to their terms,
            reviewing courts shall apply local law that most closely
            approximates an absolute waiver of all civil liability in connection
            with the Program, unless a warranty or assumption of liability
            accompanies a copy of the Program in return for a fee.
            <br />
            END OF TERMS AND CONDITIONS
          </p>
        </>
      );
    }
    case "22": {
      // FDL-1.3
      return (
        <>
          <p>
            Version 1.3, 3 November 2008
            <br />
            Copyright &copy; 2000, 2001, 2002, 2007, 2008 Free Software
            Foundation, Inc. &lt;https://fsf.org/&gt;
            <br />
            Everyone is permitted to copy and distribute verbatim copies of this
            license document, but changing it is not allowed.
            <br />
            0. PREAMBLE
            <br />
            The purpose of this License is to make a manual, textbook, or other
            functional and useful document "free" in the sense of freedom: to
            assure everyone the effective freedom to copy and redistribute it,
            with or without modifying it, either commercially or
            noncommercially. Secondarily, this License preserves for the author
            and publisher a way to get credit for their work, while not being
            considered responsible for modifications made by others.
            <br />
            This License is a kind of "copyleft", which means that derivative
            works of the document must themselves be free in the same sense. It
            complements the GNU General Public License, which is a copyleft
            license designed for free software.
            <br />
            We have designed this License in order to use it for manuals for
            free software, because free software needs free documentation: a
            free program should come with manuals providing the same freedoms
            that the software does. But this License is not limited to software
            manuals; it can be used for any textual work, regardless of subject
            matter or whether it is published as a printed book. We recommend
            this License principally for works whose purpose is instruction or
            reference.
            <br />
            1. APPLICABILITY AND DEFINITIONS
            <br />
            This License applies to any manual or other work, in any medium,
            that contains a notice placed by the copyright holder saying it can
            be distributed under the terms of this License. Such a notice grants
            a world-wide, royalty-free license, unlimited in duration, to use
            that work under the conditions stated herein. The "Document", below,
            refers to any such manual or work. Any member of the public is a
            licensee, and is addressed as "you". You accept the license if you
            copy, modify or distribute the work in a way requiring permission
            under copyright law.
            <br />A "Modified Version" of the Document means any work containing
            the Document or a portion of it, either copied verbatim, or with
            modifications and/or translated into another language.
            <br />A "Secondary Section" is a named appendix or a front-matter
            section of the Document that deals exclusively with the relationship
            of the publishers or authors of the Document to the Document's
            overall subject (or to related matters) and contains nothing that
            could fall directly within that overall subject. (Thus, if the
            Document is in part a textbook of mathematics, a Secondary Section
            may not explain any mathematics.) The relationship could be a matter
            of historical connection with the subject or with related matters,
            or of legal, commercial, philosophical, ethical or political
            position regarding them.
            <br />
            The "Invariant Sections" are certain Secondary Sections whose titles
            are designated, as being those of Invariant Sections, in the notice
            that says that the Document is released under this License. If a
            section does not fit the above definition of Secondary then it is
            not allowed to be designated as Invariant. The Document may contain
            zero Invariant Sections. If the Document does not identify any
            Invariant Sections then there are none.
            <br />
            The "Cover Texts" are certain short passages of text that are
            listed, as Front-Cover Texts or Back-Cover Texts, in the notice that
            says that the Document is released under this License. A Front-Cover
            Text may be at most 5 words, and a Back-Cover Text may be at most 25
            words.
            <br />A "Transparent" copy of the Document means a machine-readable
            copy, represented in a format whose specification is available to
            the general public, that is suitable for revising the document
            straightforwardly with generic text editors or (for images composed
            of pixels) generic paint programs or (for drawings) some widely
            available drawing editor, and that is suitable for input to text
            formatters or for automatic translation to a variety of formats
            suitable for input to text formatters. A copy made in an otherwise
            Transparent file format whose markup, or absence of markup, has been
            arranged to thwart or discourage subsequent modification by readers
            is not Transparent. An image format is not Transparent if used for
            any substantial amount of text. A copy that is not "Transparent" is
            called "Opaque".
            <br />
            Examples of suitable formats for Transparent copies include plain
            ASCII without markup, Texinfo input format, LaTeX input format, SGML
            or XML using a publicly available DTD, and standard-conforming
            simple HTML, PostScript or PDF designed for human modification.
            Examples of transparent image formats include PNG, XCF and JPG.
            Opaque formats include proprietary formats that can be read and
            edited only by proprietary word processors, SGML or XML for which
            the DTD and/or processing tools are not generally available, and the
            machine-generated HTML, PostScript or PDF produced by some word
            processors for output purposes only.
            <br />
            The "Title Page" means, for a printed book, the title page itself,
            plus such following pages as are needed to hold, legibly, the
            material this License requires to appear in the title page. For
            works in formats which do not have any title page as such, "Title
            Page" means the text near the most prominent appearance of the
            work's title, preceding the beginning of the body of the text.
            <br />
            The "publisher" means any person or entity that distributes copies
            of the Document to the public.
            <br />A section "Entitled XYZ" means a named subunit of the Document
            whose title either is precisely XYZ or contains XYZ in parentheses
            following text that translates XYZ in another language. (Here XYZ
            stands for a specific section name mentioned below, such as
            "Acknowledgements", "Dedications", "Endorsements", or "History".) To
            "Preserve the Title" of such a section when you modify the Document
            means that it remains a section "Entitled XYZ" according to this
            definition.
            <br />
            The Document may include Warranty Disclaimers next to the notice
            which states that this License applies to the Document. These
            Warranty Disclaimers are considered to be included by reference in
            this License, but only as regards disclaiming warranties: any other
            implication that these Warranty Disclaimers may have is void and has
            no effect on the meaning of this License.
            <br />
            2. VERBATIM COPYING
            <br />
            You may copy and distribute the Document in any medium, either
            commercially or noncommercially, provided that this License, the
            copyright notices, and the license notice saying this License
            applies to the Document are reproduced in all copies, and that you
            add no other conditions whatsoever to those of this License. You may
            not use technical measures to obstruct or control the reading or
            further copying of the copies you make or distribute. However, you
            may accept compensation in exchange for copies. If you distribute a
            large enough number of copies you must also follow the conditions in
            section 3.
            <br />
            You may also lend copies, under the same conditions stated above,
            and you may publicly display copies.
            <br />
            3. COPYING IN QUANTITY
            <br />
            If you publish printed copies (or copies in media that commonly have
            printed covers) of the Document, numbering more than 100, and the
            Document's license notice requires Cover Texts, you must enclose the
            copies in covers that carry, clearly and legibly, all these Cover
            Texts: Front-Cover Texts on the front cover, and Back-Cover Texts on
            the back cover. Both covers must also clearly and legibly identify
            you as the publisher of these copies. The front cover must present
            the full title with all words of the title equally prominent and
            visible. You may add other material on the covers in addition.
            Copying with changes limited to the covers, as long as they preserve
            the title of the Document and satisfy these conditions, can be
            treated as verbatim copying in other respects.
            <br />
            If the required texts for either cover are too voluminous to fit
            legibly, you should put the first ones listed (as many as fit
            reasonably) on the actual cover, and continue the rest onto adjacent
            pages.
            <br />
            If you publish or distribute Opaque copies of the Document numbering
            more than 100, you must either include a machine-readable
            Transparent copy along with each Opaque copy, or state in or with
            each Opaque copy a computer-network location from which the general
            network-using public has access to download using public-standard
            network protocols a complete Transparent copy of the Document, free
            of added material. If you use the latter option, you must take
            reasonably prudent steps, when you begin distribution of Opaque
            copies in quantity, to ensure that this Transparent copy will remain
            thus accessible at the stated location until at least one year after
            the last time you distribute an Opaque copy (directly or through
            your agents or retailers) of that edition to the public.
            <br />
            It is requested, but not required, that you contact the authors of
            the Document well before redistributing any large number of copies,
            to give them a chance to provide you with an updated version of the
            Document.
            <br />
            4. MODIFICATIONS
            <br />
            You may copy and distribute a Modified Version of the Document under
            the conditions of sections 2 and 3 above, provided that you release
            the Modified Version under precisely this License, with the Modified
            Version filling the role of the Document, thus licensing
            distribution and modification of the Modified Version to whoever
            possesses a copy of it. In addition, you must do these things in the
            Modified Version:
            <br />
            A. Use in the Title Page (and on the covers, if any) a title
            distinct from that of the Document, and from those of previous
            versions (which should, if there were any, be listed in the History
            section of the Document). You may use the same title as a previous
            version if the original publisher of that version gives permission.
            <br />
            B. List on the Title Page, as authors, one or more persons or
            entities responsible for authorship of the modifications in the
            Modified Version, together with at least five of the principal
            authors of the Document (all of its principal authors, if it has
            fewer than five), unless they release you from this requirement.
            <br />
            C. State on the Title page the name of the publisher of the Modified
            Version, as the publisher.
            <br />
            D. Preserve all the copyright notices of the Document.
            <br />
            E. Add an appropriate copyright notice for your modifications
            adjacent to the other copyright notices.
            <br />
            F. Include, immediately after the copyright notices, a license
            notice giving the public permission to use the Modified Version
            under the terms of this License, in the form shown in the Addendum
            below.
            <br />
            G. Preserve in that license notice the full lists of Invariant
            Sections and required Cover Texts given in the Document's license
            notice.
            <br />
            H. Include an unaltered copy of this License.
            <br />
            I. Preserve the section Entitled "History", Preserve its Title, and
            add to it an item stating at least the title, year, new authors, and
            publisher of the Modified Version as given on the Title Page. If
            there is no section Entitled "History" in the Document, create one
            stating the title, year, authors, and publisher of the Document as
            given on its Title Page, then add an item describing the Modified
            Version as stated in the previous sentence.
            <br />
            J. Preserve the network location, if any, given in the Document for
            public access to a Transparent copy of the Document, and likewise
            the network locations given in the Document for previous versions it
            was based on. These may be placed in the "History" section. You may
            omit a network location for a work that was published at least four
            years before the Document itself, or if the original publisher of
            the version it refers to gives permission.
            <br />
            K. For any section Entitled "Acknowledgements" or "Dedications",
            Preserve the Title of the section, and preserve in the section all
            the substance and tone of each of the contributor acknowledgements
            and/or dedications given therein.
            <br />
            L. Preserve all the Invariant Sections of the Document, unaltered in
            their text and in their titles. Section numbers or the equivalent
            are not considered part of the section titles.
            <br />
            M. Delete any section Entitled "Endorsements". Such a section may
            not be included in the Modified Version.
            <br />
            N. Do not retitle any existing section to be Entitled "Endorsements"
            or to conflict in title with any Invariant Section.
            <br />
            O. Preserve any Warranty Disclaimers.
            <br />
            If the Modified Version includes new front-matter sections or
            appendices that qualify as Secondary Sections and contain no
            material copied from the Document, you may at your option designate
            some or all of these sections as invariant. To do this, add their
            titles to the list of Invariant Sections in the Modified Version's
            license notice. These titles must be distinct from any other section
            titles.
            <br />
            You may add a section Entitled "Endorsements", provided it contains
            nothing but endorsements of your Modified Version by various
            parties&mdash;for example, statements of peer review or that the
            text has been approved by an organization as the authoritative
            definition of a standard.
            <br />
            You may add a passage of up to five words as a Front-Cover Text, and
            a passage of up to 25 words as a Back-Cover Text, to the end of the
            list of Cover Texts in the Modified Version. Only one passage of
            Front-Cover Text and one of Back-Cover Text may be added by (or
            through arrangements made by) any one entity. If the Document
            already includes a cover text for the same cover, previously added
            by you or by arrangement made by the same entity you are acting on
            behalf of, you may not add another; but you may replace the old one,
            on explicit permission from the previous publisher that added the
            old one.
            <br />
            The author(s) and publisher(s) of the Document do not by this
            License give permission to use their names for publicity for or to
            assert or imply endorsement of any Modified Version.
            <br />
            5. COMBINING DOCUMENTS
            <br />
            You may combine the Document with other documents released under
            this License, under the terms defined in section 4 above for
            modified versions, provided that you include in the combination all
            of the Invariant Sections of all of the original documents,
            unmodified, and list them all as Invariant Sections of your combined
            work in its license notice, and that you preserve all their Warranty
            Disclaimers.
            <br />
            The combined work need only contain one copy of this License, and
            multiple identical Invariant Sections may be replaced with a single
            copy. If there are multiple Invariant Sections with the same name
            but different contents, make the title of each such section unique
            by adding at the end of it, in parentheses, the name of the original
            author or publisher of that section if known, or else a unique
            number. Make the same adjustment to the section titles in the list
            of Invariant Sections in the license notice of the combined work.
            <br />
            In the combination, you must combine any sections Entitled "History"
            in the various original documents, forming one section Entitled
            "History"; likewise combine any sections Entitled
            "Acknowledgements", and any sections Entitled "Dedications". You
            must delete all sections Entitled "Endorsements".
            <br />
            6. COLLECTIONS OF DOCUMENTS
            <br />
            You may make a collection consisting of the Document and other
            documents released under this License, and replace the individual
            copies of this License in the various documents with a single copy
            that is included in the collection, provided that you follow the
            rules of this License for verbatim copying of each of the documents
            in all other respects.
            <br />
            You may extract a single document from such a collection, and
            distribute it individually under this License, provided you insert a
            copy of this License into the extracted document, and follow this
            License in all other respects regarding verbatim copying of that
            document.
            <br />
            7. AGGREGATION WITH INDEPENDENT WORKS
            <br />A compilation of the Document or its derivatives with other
            separate and independent documents or works, in or on a volume of a
            storage or distribution medium, is called an "aggregate" if the
            copyright resulting from the compilation is not used to limit the
            legal rights of the compilation's users beyond what the individual
            works permit. When the Document is included in an aggregate, this
            License does not apply to the other works in the aggregate which are
            not themselves derivative works of the Document.
            <br />
            If the Cover Text requirement of section 3 is applicable to these
            copies of the Document, then if the Document is less than one half
            of the entire aggregate, the Document's Cover Texts may be placed on
            covers that bracket the Document within the aggregate, or the
            electronic equivalent of covers if the Document is in electronic
            form. Otherwise they must appear on printed covers that bracket the
            whole aggregate.
            <br />
            8. TRANSLATION
            <br />
            Translation is considered a kind of modification, so you may
            distribute translations of the Document under the terms of section
            4. Replacing Invariant Sections with translations requires special
            permission from their copyright holders, but you may include
            translations of some or all Invariant Sections in addition to the
            original versions of these Invariant Sections. You may include a
            translation of this License, and all the license notices in the
            Document, and any Warranty Disclaimers, provided that you also
            include the original English version of this License and the
            original versions of those notices and disclaimers. In case of a
            disagreement between the translation and the original version of
            this License or a notice or disclaimer, the original version will
            prevail.
            <br />
            If a section in the Document is Entitled "Acknowledgements",
            "Dedications", or "History", the requirement (section 4) to Preserve
            its Title (section 1) will typically require changing the actual
            title.
            <br />
            9. TERMINATION
            <br />
            You may not copy, modify, sublicense, or distribute the Document
            except as expressly provided under this License. Any attempt
            otherwise to copy, modify, sublicense, or distribute it is void, and
            will automatically terminate your rights under this License.
            <br />
            However, if you cease all violation of this License, then your
            license from a particular copyright holder is reinstated (a)
            provisionally, unless and until the copyright holder explicitly and
            finally terminates your license, and (b) permanently, if the
            copyright holder fails to notify you of the violation by some
            reasonable means prior to 60 days after the cessation.
            <br />
            Moreover, your license from a particular copyright holder is
            reinstated permanently if the copyright holder notifies you of the
            violation by some reasonable means, this is the first time you have
            received notice of violation of this License (for any work) from
            that copyright holder, and you cure the violation prior to 30 days
            after your receipt of the notice.
            <br />
            Termination of your rights under this section does not terminate the
            licenses of parties who have received copies or rights from you
            under this License. If your rights have been terminated and not
            permanently reinstated, receipt of a copy of some or all of the same
            material does not give you any rights to use it.
            <br />
            10. FUTURE REVISIONS OF THIS LICENSE
            <br />
            The Free Software Foundation may publish new, revised versions of
            the GNU Free Documentation License from time to time. Such new
            versions will be similar in spirit to the present version, but may
            differ in detail to address new problems or concerns. See
            https://www.gnu.org/licenses/.
            <br />
            Each version of the License is given a distinguishing version
            number. If the Document specifies that a particular numbered version
            of this License "or any later version" applies to it, you have the
            option of following the terms and conditions either of that
            specified version or of any later version that has been published
            (not as a draft) by the Free Software Foundation. If the Document
            does not specify a version number of this License, you may choose
            any version ever published (not as a draft) by the Free Software
            Foundation. If the Document specifies that a proxy can decide which
            future versions of this License can be used, that proxy's public
            statement of acceptance of a version permanently authorizes you to
            choose that version for the Document.
            <br />
            11. RELICENSING
            <br />
            "Massive Multiauthor Collaboration Site" (or "MMC Site") means any
            World Wide Web server that publishes copyrightable works and also
            provides prominent facilities for anybody to edit those works. A
            public wiki that anybody can edit is an example of such a server. A
            "Massive Multiauthor Collaboration" (or "MMC") contained in the site
            means any set of copyrightable works thus published on the MMC site.
            <br />
            "CC-BY-SA" means the Creative Commons Attribution-Share Alike 3.0
            license published by Creative Commons Corporation, a not-for-profit
            corporation with a principal place of business in San Francisco,
            California, as well as future copyleft versions of that license
            published by that same organization.
            <br />
            "Incorporate" means to publish or republish a Document, in whole or
            in part, as part of another Document.
            <br />
            An MMC is "eligible for relicensing" if it is licensed under this
            License, and if all works that were first published under this
            License somewhere other than this MMC, and subsequently incorporated
            in whole or in part into the MMC, (1) had no cover texts or
            invariant sections, and (2) were thus incorporated prior to November
            1, 2008.
            <br />
            The operator of an MMC Site may republish an MMC contained in the
            site under CC-BY-SA on the same site at any time before August 1,
            2009, provided the MMC is eligible for relicensing.
            <br />
            ADDENDUM: How to use this License for your documents
            <br />
            To use this License in a document you have written, include a copy
            of the License in the document and put the following copyright and
            license notices just after the title page:
            <br /> Copyright (C) YEAR YOUR NAME.
            <br /> Permission is granted to copy, distribute and/or modify this
            document
            <br /> under the terms of the GNU Free Documentation License,
            Version 1.3
            <br /> or any later version published by the Free Software
            Foundation;
            <br /> with no Invariant Sections, no Front-Cover Texts, and no
            Back-Cover Texts.
            <br /> A copy of the license is included in the section entitled
            "GNU
            <br /> Free Documentation License".
            <br />
            If you have Invariant Sections, Front-Cover Texts and Back-Cover
            Texts, replace the "with &hellip; Texts." line with this:
            <br /> with the Invariant Sections being LIST THEIR TITLES, with the
            <br /> Front-Cover Texts being LIST, and with the Back-Cover Texts
            being LIST.
            <br />
            If you have Invariant Sections without Cover Texts, or some other
            combination of the three, merge those two alternatives to suit the
            situation.
            <br />
            If your document contains nontrivial examples of program code, we
            recommend releasing these examples in parallel under your choice of
            free software license, such as the GNU General Public License, to
            permit their use in free software.
          </p>
        </>
      );
    }
    case "23": {
      // EU-ODP-Legal-Notice
      return (
        <>
          <p>
            Disclaimer
            <br />
            The European Commission maintains this website to enhance public
            access to information about its initiatives and European Union
            policies in general. Our goal is to keep this information timely and
            accurate. If errors are brought to our attention, we will try to
            correct them. However, the Commission accepts no responsibility or
            liability whatsoever with regard to the information on this site.
            <br />
            This information is
            <br />
            of a general nature only and is not intended to address the specific
            circumstances of any particular individual or entity
            <br />
            not necessarily comprehensive, complete, accurate or up to date
            <br />
            sometimes linked to external sites over which the Commission
            services have no control and for which the Commission assumes no
            responsibility
            <br />
            not professional or legal advice (if you need specific advice, you
            should always consult a suitably qualified professional).
            <br />
            Please note that it cannot be guaranteed that a document available
            online exactly reproduces an officially adopted text. Only the
            Official Journal of the European Union (the printed edition or,
            since 1 July 2013, the electronic edition on the EUR-Lex website) is
            authentic and produces legal effects.
            <br />
            It is our goal to minimise disruption caused by technical errors.
            However some data or information on our site may have been created
            or structured in files or formats that are not error-free, and we
            cannot guarantee that our service will not be interrupted or
            otherwise affected by such problems. The Commission accepts no
            responsibility with regard to such problems incurred as a result of
            using this site or any linked external sites.
            <br />
            This disclaimer is not intended to limit the liability of the
            Commission in contravention of any requirements laid down in
            applicable national law nor to exclude its liability for matters
            which may not be excluded under that law.
            <br />
            External link disclaimer
            <br />
            European Commission websites in the europa.eu domain may contain
            &ldquo;external links&rdquo; to websites in domains other than
            europa.eu, which may not be owned or funded by the European
            Commission, over which Commission services have no control and for
            which the Commission assumes no responsibility.
            <br />
            When visitors to European Commission websites choose to follow a
            link to any external website, they leave the official domain of the
            European Commission, and are subject to the cookie, privacy and
            legal policies of the external website.
            <br />
            Compliance with applicable data protection and accessibility
            requirements of external websites linked to from Commission websites
            in the europa.eu domain, falls outside the control of the European
            Commission and is the explicit responsibility of the external
            website.
            <br />
            Disclaimer of endorsement
            <br />
            The purpose of any linked reference from European Commission
            websites to any specific external resource, online service or a
            website, is to enhance the information available on European
            Commission websites and help visitors to those websites.
            <br />
            Such linked references do not constitute endorsement by the European
            Commission of the specific external resource, online service or a
            website, nor endorsement of the information, contained in the linked
            reference, nor endorsement of the organisations owning the external
            websites.
            <br />
            Privacy policy
            <br />
            The European Union is committed to user privacy. The privacy policy
            covers the European Union's websites within the europa.eu domain.
            <br />
            Privacy policy
            <br />
            Copyright notice
            <br />
            &copy; European Union, 1995-2024
            <br />
            The Commission's reuse policy is implemented by the Commission
            Decision of 12 December 2011 on the reuse of Commission documents.
            <br />
            Unless otherwise indicated (e.g. in individual copyright notices),
            content owned by the EU on this website is licensed under the
            Creative Commons Attribution 4.0 International (CC BY 4.0) licence.
            This means that reuse is allowed, provided appropriate credit is
            given and changes are indicated.
            <br />
            You may be required to clear additional rights if a specific content
            depicts identifiable private individuals or includes third-party
            works. To use or reproduce content that is not owned by the EU, you
            may need to seek permission directly from the rightholders. Software
            or documents covered by industrial property rights, such as patents,
            trade marks, registered designs, logos and names, are excluded from
            the Commission's reuse policy and are not licensed to you.
          </p>
        </>
      );
    }
    case "24": {
      // apache-2.0
      return (
        <>
          <p>
            TERMS AND CONDITIONS FOR USE, REPRODUCTION, AND DISTRIBUTION
            <br />
            1. Definitions.
            <br />
            "License" shall mean the terms and conditions for use, reproduction,
            and distribution as defined by Sections 1 through 9 of this
            document.
            <br />
            "Licensor" shall mean the copyright owner or entity authorized by
            the copyright owner that is granting the License.
            <br />
            "Legal Entity" shall mean the union of the acting entity and all
            other entities that control, are controlled by, or are under common
            control with that entity. For the purposes of this definition,
            "control" means (i) the power, direct or indirect, to cause the
            direction or management of such entity, whether by contract or
            otherwise, or (ii) ownership of fifty percent (50%) or more of the
            outstanding shares, or (iii) beneficial ownership of such entity.
            <br />
            "You" (or "Your") shall mean an individual or Legal Entity
            exercising permissions granted by this License.
            <br />
            "Source" form shall mean the preferred form for making
            modifications, including but not limited to software source code,
            documentation source, and configuration files.
            <br />
            "Object" form shall mean any form resulting from mechanical
            transformation or translation of a Source form, including but not
            limited to compiled object code, generated documentation, and
            conversions to other media types.
            <br />
            "Work" shall mean the work of authorship, whether in Source or
            Object form, made available under the License, as indicated by a
            copyright notice that is included in or attached to the work (an
            example is provided in the Appendix below).
            <br />
            "Derivative Works" shall mean any work, whether in Source or Object
            form, that is based on (or derived from) the Work and for which the
            editorial revisions, annotations, elaborations, or other
            modifications represent, as a whole, an original work of authorship.
            For the purposes of this License, Derivative Works shall not include
            works that remain separable from, or merely link (or bind by name)
            to the interfaces of, the Work and Derivative Works thereof.
            <br />
            "Contribution" shall mean any work of authorship, including the
            original version of the Work and any modifications or additions to
            that Work or Derivative Works thereof, that is intentionally
            submitted to Licensor for inclusion in the Work by the copyright
            owner or by an individual or Legal Entity authorized to submit on
            behalf of the copyright owner. For the purposes of this definition,
            "submitted" means any form of electronic, verbal, or written
            communication sent to the Licensor or its representatives, including
            but not limited to communication on electronic mailing lists, source
            code control systems, and issue tracking systems that are managed
            by, or on behalf of, the Licensor for the purpose of discussing and
            improving the Work, but excluding communication that is
            conspicuously marked or otherwise designated in writing by the
            copyright owner as "Not a Contribution."
            <br />
            "Contributor" shall mean Licensor and any individual or Legal Entity
            on behalf of whom a Contribution has been received by Licensor and
            subsequently incorporated within the Work.
            <br />
            2. Grant of Copyright License. Subject to the terms and conditions
            of this License, each Contributor hereby grants to You a perpetual,
            worldwide, non-exclusive, no-charge, royalty-free, irrevocable
            copyright license to reproduce, prepare Derivative Works of,
            publicly display, publicly perform, sublicense, and distribute the
            Work and such Derivative Works in Source or Object form.
            <br />
            3. Grant of Patent License. Subject to the terms and conditions of
            this License, each Contributor hereby grants to You a perpetual,
            worldwide, non-exclusive, no-charge, royalty-free, irrevocable
            (except as stated in this section) patent license to make, have
            made, use, offer to sell, sell, import, and otherwise transfer the
            Work, where such license applies only to those patent claims
            licensable by such Contributor that are necessarily infringed by
            their Contribution(s) alone or by combination of their
            Contribution(s) with the Work to which such Contribution(s) was
            submitted. If You institute patent litigation against any entity
            (including a cross-claim or counterclaim in a lawsuit) alleging that
            the Work or a Contribution incorporated within the Work constitutes
            direct or contributory patent infringement, then any patent licenses
            granted to You under this License for that Work shall terminate as
            of the date such litigation is filed.
            <br />
            4. Redistribution. You may reproduce and distribute copies of the
            Work or Derivative Works thereof in any medium, with or without
            modifications, and in Source or Object form, provided that You meet
            the following conditions:
            <br />
            You must give any other recipients of the Work or Derivative Works a
            copy of this License; and
            <br />
            You must cause any modified files to carry prominent notices stating
            that You changed the files; and
            <br />
            You must retain, in the Source form of any Derivative Works that You
            distribute, all copyright, patent, trademark, and attribution
            notices from the Source form of the Work, excluding those notices
            that do not pertain to any part of the Derivative Works; and
            <br />
            If the Work includes a "NOTICE" text file as part of its
            distribution, then any Derivative Works that You distribute must
            include a readable copy of the attribution notices contained within
            such NOTICE file, excluding those notices that do not pertain to any
            part of the Derivative Works, in at least one of the following
            places: within a NOTICE text file distributed as part of the
            Derivative Works; within the Source form or documentation, if
            provided along with the Derivative Works; or, within a display
            generated by the Derivative Works, if and wherever such third-party
            notices normally appear. The contents of the NOTICE file are for
            informational purposes only and do not modify the License. You may
            add Your own attribution notices within Derivative Works that You
            distribute, alongside or as an addendum to the NOTICE text from the
            Work, provided that such additional attribution notices cannot be
            construed as modifying the License.
            <br />
            You may add Your own copyright statement to Your modifications and
            may provide additional or different license terms and conditions for
            use, reproduction, or distribution of Your modifications, or for any
            such Derivative Works as a whole, provided Your use, reproduction,
            and distribution of the Work otherwise complies with the conditions
            stated in this License.
            <br />
            5. Submission of Contributions. Unless You explicitly state
            otherwise, any Contribution intentionally submitted for inclusion in
            the Work by You to the Licensor shall be under the terms and
            conditions of this License, without any additional terms or
            conditions. Notwithstanding the above, nothing herein shall
            supersede or modify the terms of any separate license agreement you
            may have executed with Licensor regarding such Contributions.
            <br />
            6. Trademarks. This License does not grant permission to use the
            trade names, trademarks, service marks, or product names of the
            Licensor, except as required for reasonable and customary use in
            describing the origin of the Work and reproducing the content of the
            NOTICE file.
            <br />
            7. Disclaimer of Warranty. Unless required by applicable law or
            agreed to in writing, Licensor provides the Work (and each
            Contributor provides its Contributions) on an "AS IS" BASIS, WITHOUT
            WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied,
            including, without limitation, any warranties or conditions of
            TITLE, NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A
            PARTICULAR PURPOSE. You are solely responsible for determining the
            appropriateness of using or redistributing the Work and assume any
            risks associated with Your exercise of permissions under this
            License.
            <br />
            8. Limitation of Liability. In no event and under no legal theory,
            whether in tort (including negligence), contract, or otherwise,
            unless required by applicable law (such as deliberate and grossly
            negligent acts) or agreed to in writing, shall any Contributor be
            liable to You for damages, including any direct, indirect, special,
            incidental, or consequential damages of any character arising as a
            result of this License or out of the use or inability to use the
            Work (including but not limited to damages for loss of goodwill,
            work stoppage, computer failure or malfunction, or any and all other
            commercial damages or losses), even if such Contributor has been
            advised of the possibility of such damages.
            <br />
            9. Accepting Warranty or Additional Liability. While redistributing
            the Work or Derivative Works thereof, You may choose to offer, and
            charge a fee for, acceptance of support, warranty, indemnity, or
            other liability obligations and/or rights consistent with this
            License. However, in accepting such obligations, You may act only on
            Your own behalf and on Your sole responsibility, not on behalf of
            any other Contributor, and only if You agree to indemnify, defend,
            and hold each Contributor harmless for any liability incurred by, or
            claims asserted against, such Contributor by reason of your
            accepting any such warranty or additional liability.
            <br />
            END OF TERMS AND CONDITIONS
          </p>
        </>
      );
    }
    default: {
      return (
        <>
          <p>Please provide details on your license.</p>
        </>
      );
    }
  }
};

export default getLicense;
