import { Modal, Button, Spinner, Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { updateProfile } from "actions/auth";
import { useMediaQuery } from "react-responsive";

/**
 * A module for the EditBasicInfoModal Component
 * @module components/users/EditBasicInfoModal
 */

/**
 * Displaying  modal for editing account basic info
 * @method EditBasicInfoModal
 *
 * @return {JSX.Element}
 *
 */
const EditBasicInfoModal = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const [firstname, setFirstName] = useState("");
  const [middlename, setMiddleName] = useState("");
  const [lastname, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [orcidID, setOrcidID] = useState("");
  // const [introduction, setIntroduction] = useState("");
  // const [skills, setSkills] = useState("");

  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const optionalFields = [
    "middlename",
    "suffix",
    "orcidID",
    // "introduction",
    // "skills",
  ];

  const validate = (name, value) => {
    // For input with spaces only
    if (value) {
      value = value.toString().trim();
    }

    if (optionalFields.findIndex((field) => name === field) < 0 && !value) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "Please fill out this field" }));
      return;
    }

    if (value) {
      switch (name) {
        case "firstname":
        case "middlename":
          setIsValid(Object.assign(isValid, { [name]: false }));
          // const validateNames = new RegExp("/^[ña-z .'-]+$/i");
          if (!/^[ña-z .-]+$/i.test(value)) {
            setError(
              Object.assign(error, {
                [name]: "Accepts a-z, A-Z, Ñ/ñ, space, -, and period only",
              })
            );
            return;
          }
          break;
        case "lastname":
          setIsValid(Object.assign(isValid, { [name]: false }));
          // const validateNames = new RegExp("/^[ña-z .'-]+$/i");
          if (!/^[ña-z -]+$/i.test(value)) {
            setError(
              Object.assign(error, {
                [name]: "Accepts a-z, A-Z, Ñ/ñ, space, and - only",
              })
            );
            return;
          }
          break;
        case "suffix":
          setIsValid(Object.assign(isValid, { [name]: false }));
          if (!/^[ña-z .]+$/i.test(value)) {
            setError(
              Object.assign(error, {
                [name]: "Accepts a-z, A-Z, Ñ/ñ, and period only",
              })
            );
            return;
          }
          break;
        case "orcidID":
          setIsValid(Object.assign(isValid, { [name]: false }));
          // const validateNames = new RegExp("/^[ña-z .'-]+$/i");
          if (
            !/^[0-9]{0,4}(-[0-9]{0,4}(-[0-9]{0,4}(-[0-9]{0,4}(-)?)?)?)?$/.test(
              value
            )
          ) {
            setError(
              Object.assign(error, {
                [name]: "Accepts numeric (0-9) numbers only",
              })
            );
            return;
          }
          if (value.length < 19) {
            setError(
              Object.assign(error, {
                [name]: "Accepts only 16 digits",
              })
            );
            return;
          }
          break;
        default:
        // code block
      }
    }

    if (name !== "orcidID" && value.length < 2 && value.length > 0) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(
        Object.assign(error, {
          [name]: "Minimum of 2 characters",
        })
      );
      return;
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  /**
   * Gets value of the first name input field
   * @method onChangeFirstName
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeFirstName = (e) => {
    const firstname = e.target.value;
    setFirstName(firstname);
    validate("firstname", firstname);
  };

  /**
   * Gets value of the middle name input field
   * @method onChangeMiddleName
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeMiddleName = (e) => {
    const middlename = e.target.value;
    setMiddleName(middlename);
    validate("middlename", middlename);
  };

  /**
   * Gets value of the last name input field
   * @method onChangeLastName
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeLastName = (e) => {
    const lastname = e.target.value;
    setLastName(lastname);
    validate("lastname", lastname);
  };

  /**
   * Gets value of the suffix input field
   * @method onChangeSuffix
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeSuffix = (e) => {
    const suffix = e.target.value;
    setSuffix(suffix);
    validate("suffix", suffix);
  };

  /**
   * Gets value of the ORCID ID input field
   * @method onChangeOrcidID
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangeOrcidID = (e) => {
    const orcidID = e.target.value;

    // Create a regular expression pattern to match only digits and hyphen
    var digitPattern = /^[\d-]+$/;

    // Use the test method to check if the input matches the pattern
    if (digitPattern.test(orcidID)) {
      const cleanedValue = orcidID.replace(/[^0-9]/g, "").slice(0, 16);
      let formattedValue = "";

      for (let i = 0; i < cleanedValue.length; i++) {
        if (i > 0 && i % 4 === 0) {
          formattedValue += "-";
        }
        formattedValue += cleanedValue[i];
      }

      setOrcidID(formattedValue);
      validate("orcidID", formattedValue);
    } else {
      setOrcidID(orcidID);
      validate("orcidID", orcidID);
    }
  };

  const handleUpdate = () => {
    validate("firstname", firstname);
    validate("middlename", middlename);
    validate("lastname", lastname);
    validate("suffix", suffix);
    validate("orcidID", orcidID);

    if (Object.keys(error).length === 0) {
      setLoading(true);
      setDisabled(true);
      dispatch(
        updateProfile(
          firstname,
          middlename,
          lastname,
          suffix,
          orcidID,
          props?.introduction,
          // skills,
          props?.orgAcronym,
          props?.organization,
          props?.designation,
          props?.division,
          props?.orgAddressProvince,
          props?.orgAddressMunicipality,
          "Introduction"
        )
      )
        .then((status) => {
          props.setToastStatus(status);
          props.setToastImage("/images/account-update-success.svg");
        })
        .catch((status) => {
          props.setToastStatus(status);
          props.setToastImage(null);
        })
        .finally(() => {
          props.setShowToast(true);
          props.handleEditBasicInfoModalClose();
          props?.setRefreshProfileInfoCount(
            props?.refreshPersonalInfoCount + 1
          );
        });
    }
  };

  useEffect(() => {
    if (
      firstname !== "" &&
      lastname !== "" &&
      Object.keys(error).length === 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [Object.keys(error), firstname, lastname]);

  const resetModalForm = () => {
    setFirstName(props?.firstname);
    setMiddleName(props?.middlename || "");
    setLastName(props?.lastname);
    setSuffix(props?.suffix);
    setOrcidID(props?.orcidID);
    setLoading(false);
    setDisabled(false);
    setIsValid({});
    setError({});
  };

  useEffect(() => {
    resetModalForm();
  }, [props.showEditBasicInfoModal]);

  return (
    <>
      <Offcanvas
        show={props.showEditBasicInfoModal && isMobile}
        onHide={props.handleEditBasicInfoModalClose}
        placement="bottom"
        className="offcanvas-personal-info"
      >
        <Offcanvas.Body>
          <Form className="w-100">
            <p className="bold" id="intro-modal" data-cy="intro-modal">
              Edit introduction
            </p>
            <div className="personal-info-form">
              <p className="required-field">
                Required fields are marked with an asterisk
              </p>
              <Form.Group className="mb-3">
                <Form.Label className="required-field">First name</Form.Label>
                <Form.Control
                  value={firstname}
                  onChange={onChangeFirstName}
                  className={`input-field ${
                    isValid?.firstname
                      ? "is-valid"
                      : isValid.firstname !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="first-name"
                  id="first-name"
                  data-cy="first-name"
                />
                {getFormErrorMessage("firstname")}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Middle name</Form.Label>
                <Form.Control
                  value={middlename}
                  onChange={onChangeMiddleName}
                  className={`input-field ${
                    isValid?.middlename
                      ? "is-valid"
                      : isValid.middlename !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="middle-name"
                  id="middle-name"
                  data-cy="middle-name"
                />
                {getFormErrorMessage("middlename")}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required-field">Last name</Form.Label>
                <Form.Control
                  value={lastname}
                  onChange={onChangeLastName}
                  className={`input-field ${
                    isValid?.lastname
                      ? "is-valid"
                      : isValid.lastname !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="last-name"
                  id="last-name"
                  data-cy="last-name"
                />
                {getFormErrorMessage("lastname")}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Suffix</Form.Label>
                <Form.Control
                  value={suffix}
                  onChange={onChangeSuffix}
                  className={`input-field ${
                    isValid?.suffix
                      ? "is-valid"
                      : isValid.suffix !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="suffix"
                  id="suffix"
                  data-cy="suffix"
                />
                {getFormErrorMessage("suffix")}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>ORCID ID</Form.Label>
                <Form.Control
                  value={orcidID}
                  onChange={onChangeOrcidID}
                  placeholder="0000-0000-0000-0000"
                  maxLength="19"
                  className={`input-field ${
                    isValid?.orcidID
                      ? "is-valid"
                      : isValid.orcidID !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="orcid"
                  id="orcid"
                  data-cy="orcid"
                />
                {getFormErrorMessage("orcidID")}
              </Form.Group>
            </div>
          </Form>
          <div className="buttons-div">
            <Button
              variant="outline-light"
              onClick={props.handleEditBasicInfoModalClose}
              className="light-btn"
              name="cancel"
              id="cancel"
              data-cy="cancel"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="submit-btn button"
              disabled={disabled}
              onClick={handleUpdate}
              name="update-btn"
              id="update-btn"
              data-cy="update-btn"
            >
              Update
              {loading && (
                <Spinner
                  className="spinner"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={props.showEditBasicInfoModal && !isMobile}
        onHide={props.handleEditBasicInfoModalClose}
        dialogClassName="modal-45w"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title
            className="bold ms-3"
            id="intro-modal"
            data-cy="intro-modal"
          >
            Edit introduction
          </Modal.Title>
          <Button
            variant="close"
            onClick={props.handleEditBasicInfoModalClose}
            name="close-btn"
            id="close-btn"
            data-cy="close-btn"
          />
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <Form>
            <p className="required-field ms-5">
              Required fields are marked with an asterisk
            </p>
            <hr />
            <div className="height-70">
              <Form.Group className="mb-3 mx-5">
                <Form.Label className="required-field">First name</Form.Label>
                <Form.Control
                  value={firstname}
                  onChange={onChangeFirstName}
                  className={`input-field ${
                    isValid?.firstname
                      ? "is-valid"
                      : isValid.firstname !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="first-name"
                  id="first-name"
                  data-cy="first-name"
                />
                {getFormErrorMessage("firstname")}
              </Form.Group>
              <Form.Group className="mb-3 mx-5">
                <Form.Label>Middle name</Form.Label>
                <Form.Control
                  value={middlename}
                  onChange={onChangeMiddleName}
                  className={`input-field ${
                    isValid?.middlename
                      ? "is-valid"
                      : isValid.middlename !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="middle-name"
                  id="middle-name"
                  data-cy="middle-name"
                />
                {getFormErrorMessage("middlename")}
              </Form.Group>
              <Form.Group className="mb-3 mx-5">
                <Form.Label className="required-field">Last name</Form.Label>
                <Form.Control
                  value={lastname}
                  onChange={onChangeLastName}
                  className={`input-field ${
                    isValid?.lastname
                      ? "is-valid"
                      : isValid.lastname !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="last-name"
                  id="last-name"
                  data-cy="last-name"
                />
                {getFormErrorMessage("lastname")}
              </Form.Group>
              <Form.Group className="mb-3 mx-5">
                <Form.Label>Suffix</Form.Label>
                <Form.Control
                  value={suffix}
                  onChange={onChangeSuffix}
                  className={`input-field ${
                    isValid?.suffix
                      ? "is-valid"
                      : isValid.suffix !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="suffix"
                  id="suffix"
                  data-cy="suffix"
                />
                {getFormErrorMessage("suffix")}
              </Form.Group>
              <Form.Group className="mb-3 mx-5">
                <Form.Label>ORCID ID</Form.Label>
                <Form.Control
                  value={orcidID}
                  onChange={onChangeOrcidID}
                  placeholder="0000-0000-0000-0000"
                  maxLength="19"
                  className={`input-field ${
                    isValid?.orcidID
                      ? "is-valid"
                      : isValid.orcidID !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="orcid"
                  id="orcid"
                  data-cy="orcid"
                />
                {getFormErrorMessage("orcidID")}
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-light"
            onClick={props.handleEditBasicInfoModalClose}
            className="light-btn"
            name="cancel"
            id="cancel"
            data-cy="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="submit-btn button"
            disabled={disabled}
            onClick={handleUpdate}
            name="update-btn"
            id="update-btn"
            data-cy="update-btn"
          >
            Update
            {loading && (
              <Spinner
                className="spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditBasicInfoModal;
