import React, { useState, useMemo, useEffect, useRef } from "react";
import { Container, Breadcrumb, Button, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "actions/group";
import CustomPagination from "components/layout/CustomPagination";
import FilterDropdown from "components/layout/FilterDropdown";
import {
  getAllDatasets,
  getAllSubscribeDatasetProfile,
  getDataset,
  subscribeDatasetProfile,
  unsubscribeDatasetProfile,
} from "actions/dataset";
import { compareValues, convertDate } from "common/constants";
import { getCurrentUser } from "actions/auth";
import { useMediaQuery } from "react-responsive";
import MobileLeftSidebar from "components/layout/MobileLeftSidebar";
import "styles/DatasetList.css";
import "styles/mobile/DatasetListMobile.css";

/**
 * A module for Listing Datasets component
 * @module components/datasets/DatasetList
 */

/**
 * Listing Datasets component
 * @method DatasetList
 *
 * @return {JSX.Element}
 *
 */

const DatasetList = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const scrollRef = useRef(null);

  /**
   * -------------------
   * * Redux store state
   * -------------------
   */
  const { datasets, subscribedDatasets, currentDataset } = useSelector(
    (state) => state.dataset
  );
  const { users } = useSelector((state) => state.group);
  const { user, currentUser } = useSelector((state) => state.auth);

  const [sortDirection, setSortDirection] = useState("desc");
  const [sortDirectionTitle, setSortDirectionTitle] = useState("asc");
  const [sortDirectionCreatedBy, setSortDirectionCreatedBy] = useState("asc");
  const [sortDirectionDateCreated, setSortDirectionDateCreated] =
    useState("desc");
  const [sortColumn, setSortColumn] = useState("");
  const [refreshSubscribe, setRefreshSubscribe] = useState(0);
  const [reloadCounter, setReloadCounter] = useState(0);
  const [displayMobileSearchList, setDisplayMobileSearchList] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggleExpand = (column) => {
    switch (column) {
      case "title":
        setSortDirectionTitle(sortDirectionTitle === "asc" ? "desc" : "asc");
        setSortColumn("Title");
        break;
      case "created_by":
        setSortDirectionCreatedBy(
          sortDirectionCreatedBy === "asc" ? "desc" : "asc"
        );
        setSortColumn("Created by");
        break;
      case "date_created":
        setSortDirectionDateCreated(
          sortDirectionDateCreated === "asc" ? "desc" : "asc"
        );
        setSortColumn("Date created");
        break;
      default:
      //
    }
  };

  const handleSubscribe = (id, isSubscribed, event) => {
    dispatch(getDataset(id))
      .then((res) => {
        res.length === 0 && navigate("/404");
      })
      .catch((status) => {
        props.setShowToast(true);
        props.setToastStatus(status);
        props.setToastImage(null);
      });
    if (isSubscribed) {
      dispatch(unsubscribeDatasetProfile(id))
        .then((status) => {
          props.setToastStatus("error");
          props.setToastImage("/images/subscription-removed-success.svg");
          setRefreshSubscribe(refreshSubscribe + 1);
        })
        .catch((status) => {
          if (status === "notif-in-app-network unreachable") {
            console.log("notif-in-app-network unreachable");
            props.setToastStatus("error");
            props.setToastImage("/images/subscription-removed-success.svg");
            setRefreshSubscribe(refreshSubscribe + 1);
          } else {
            props.setToastStatus(status);
            props.setToastImage(null);
          }
        })
        .finally(() => {
          props.setShowToast(true);
        });
    } else {
      dispatch(subscribeDatasetProfile(id))
        .then((status) => {
          props.setToastStatus("success");
          props.setToastImage("/images/subscription-added-success.svg");
          setRefreshSubscribe(refreshSubscribe + 1);
        })
        .catch((status) => {
          if (status === "notif-in-app-network unreachable") {
            console.log("notif-in-app-network unreachable");
            props.setToastStatus("success");
            props.setToastImage("/images/subscription-removed-success.svg");
            setRefreshSubscribe(refreshSubscribe + 1);
          } else {
            props.setToastStatus(status);
            props.setToastImage(null);
          }
        })
        .finally(() => {
          props.setShowToast(true);
        });
    }
    event.stopPropagation();
  };
  /**
   * Columns template and configuration
   */
  const columns = [
    {
      name: (
        <div
          onClick={() => handleToggleExpand("title")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Title</span>
          {sortColumn === "Title" && (
            <img
              src={
                sortDirectionTitle === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      cell: (row) => (
        <div
          className={`flex d-flex justify-content-between align-items-center row-profile-title`}
          onClick={() => handleRowClick(row)}
        >
          <div>{row.title}</div>
          <div
            onClick={(event) =>
              handleSubscribe(row.id, row.dataset_subscribe, event)
            }
            id={`subscribe-${row.id}`}
            data-cy="subscribe"
            className="heart-div"
          >
            <img
              src={
                row?.dataset_subscribe
                  ? "/images/subscribed.svg"
                  : "/images/not-subscribed.svg"
              }
              className="img-fluid heart"
              alt="menu"
            />
          </div>
        </div>
      ),
      width: "70%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("created_by")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Created by</span>
          {sortColumn === "Created by" && (
            <img
              src={
                sortDirectionCreatedBy === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      selector: (row) => {
        const dataset_user = Object.values(users).find(
          (user) => user.email === row.author
        );
        const icon = (
          <img
            src="/images/user_list.svg"
            className="img-fluid icon-list"
            alt="user"
          />
        );
        const dataset_user_name =
          user.email === row.author
            ? "me"
            : dataset_user?.first_name + " " + dataset_user?.last_name;

        return (
          <div className="row-profile-created-by">
            <span
              onClick={() => handleRowClick(row)}
              key={`createdByKey${row.profile_id}`}
            >
              {icon}
              {dataset_user_name}
            </span>
          </div>
        );
      },
      width: "15%",
    },
    {
      name: (
        <div
          onClick={() => handleToggleExpand("date_created")}
          className="w-100 h-100 d-flex align-items-center"
        >
          <span className="list-title">Date created</span>
          {sortColumn === "Date created" && (
            <img
              src={
                sortDirectionDateCreated === "asc"
                  ? "/images/arrow-down-sort.svg"
                  : "/images/arrow-up-sort.svg"
              }
              className="img-fluid m-2"
              alt="sort"
            />
          )}
        </div>
      ),
      selector: (row) => {
        const dateString = row.created_on;
        const date = new Date(dateString);
        const formattedDate = isMobile
          ? convertDate(date, "MM DD, YYYY")
          : convertDate(date, "MM/DD/YYYY");

        return <div className="row-profile-date-created">{formattedDate}</div>;
      },
      width: "15%",
    },
  ];

  /**
   * -------------------------
   * * Component state
   *  ------------------------
   */
  const [filterText, setFilterText] = useState("");

  const [selectedOptionsCreatedBy, setSelectedOptionsCreatedBy] = useState([]);
  const [selectedOptionsSubscriptions, setSelectedOptionsSubscriptions] =
    useState([]);
  const [clearFilter, setClearFilter] = useState(false);

  const handleClearFilterBtnClick = () => {
    setSelectedOptionsCreatedBy([]);
    setSelectedOptionsSubscriptions([]);
    setClearFilter(true);
  };

  useEffect(() => {
    // handleItemsPerPageChange(itemsPerPage);
    setFilteredItems(
      Object.values(datasets)
        .map((item) => {
          let dataset_subscribe = false;
          if (currentUser?.dataset_profile_subscriptions?.length > 0) {
            const subscribe = Object.values(
              currentUser?.dataset_profile_subscriptions
            ).find((i) => i === item.id);
            dataset_subscribe = subscribe ? true : false;
          }
          // console.log(item.subscribers.length)
          const updatedItem = {
            id: item.id,
            profile_id: item.profile_id,
            author: item.author.email,
            created_on: item.created_on,
            description: item.description,
            title: item.title,
            dataset_subscribe: dataset_subscribe,
          };

          return updatedItem;
        })
        .filter((item) => {
          const dateString = item.created_on;
          const date = new Date(dateString);

          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const year = String(date.getFullYear());

          const formattedDate = `${month}/${day}/${year}`;

          return (
            (item.title.toLowerCase().includes(filterText.toLowerCase()) ||
              formattedDate.toLowerCase().includes(filterText.toLowerCase()) ||
              Object.values(users)
                .find((user) => user.email === item.author.email)
                ?.first_name.concat(
                  " ",
                  Object.values(users).find(
                    (user) => user.email === item.author.email
                  )?.last_name
                )
                .toLowerCase()
                .includes(filterText.toLowerCase())) &&
            (selectedOptionsCreatedBy.length > 0
              ? selectedOptionsCreatedBy.includes(item.author)
              : true) &&
            (selectedOptionsSubscriptions.length > 0
              ? selectedOptionsSubscriptions.includes(item.title)
              : true)
          );
        })
        .sort((a, b) => {
          if (filterText) {
            return compareValues(a.title, b.title, "asc");
          }
          switch (sortColumn) {
            case "Title":
              return compareValues(a.title, b.title, sortDirectionTitle);
            case "Created by":
              return compareValues(a.author, b.author, sortDirectionCreatedBy);
            default:
              return sortDirectionDateCreated === "asc"
                ? a.created_on.toLowerCase().localeCompare(b.created_on)
                : b.created_on.toLowerCase().localeCompare(a.created_on);
          }
        })
    );
  }, [
    datasets,
    filterText,
    selectedOptionsCreatedBy,
    selectedOptionsSubscriptions,
    sortDirectionTitle,
    sortDirectionCreatedBy,
    sortDirectionDateCreated,
    sortColumn,
    subscribedDatasets,
    currentUser,
  ]);

  useEffect(() => {
    if (reloadCounter > 0) {
      props.setDatasetCurrentPage(1);
    }
    setReloadCounter(reloadCounter + 1);
  }, [
    selectedOptionsCreatedBy.length,
    selectedOptionsSubscriptions.length,
    filterText,
    sortColumn,
    sortDirectionTitle,
    sortDirectionCreatedBy,
    sortDirectionDateCreated,
  ]);

  const subHeaderComponentMemo = useMemo(() => {
    // Create an empty Set to store unique users
    const uniqueUsers = new Set();
    const datasetProfiles = new Set();
    const createdByArray = [];

    // Iterate through the array and add each dataset to the Set
    Object.values(datasets).forEach((item) => {
      const dataset_user = Object.values(users).find(
        (user) => user.email === item.author.email
      );
      const dataset_user_name =
        user.email === item.author.email
          ? "me"
          : dataset_user?.first_name + " " + dataset_user?.last_name;

      if (!uniqueUsers.has(item.author.email)) {
        uniqueUsers.add(item.author.email);
        createdByArray.push({
          email: item.author.email,
          created_by: dataset_user_name,
        });
      }
    });

    if (subscribedDatasets.length > 0) {
      // Iterate through the inputArray and add each subscribed dataset profiles to the Set
      Object.values(subscribedDatasets).forEach((subscribedID) => {
        const dataset = Object.values(datasets).find(
          (dataset) => dataset.id === subscribedID.id
        );
        dataset && datasetProfiles.add(subscribedID.title);
      });
    }

    // Convert the Set back to an array
    const filteredCreatedBy = Array.from(createdByArray);
    const filteredSubscriptions = Array.from(datasetProfiles);


    return (
      <div className="list-header">
        <div className="search-input-container">
          <input
            type="text"
            placeholder="Search datasets"
            onChange={(e) => setFilterText(e.target.value)}
            className="search-input"
            name="search-dataset"
            id="search-dataset"
            data-cy="search-dataset"
          />
          <img
            src="/images/search-icon-gray.svg"
            className="search-icon img-fluid icon-list"
            alt="dataset"
          />
        </div>
        <div className="search-filter-dropdowns-div">
          <FilterDropdown
            setSelectedOptions={setSelectedOptionsCreatedBy}
            clearFilter={clearFilter}
            setClearFilter={setClearFilter}
            filterTitle="Created by"
            options={filteredCreatedBy}
            name="created_by"
            isMobile={isMobile}
            dropdownToggleID="filter-created-by"
          />
          <FilterDropdown
            setSelectedOptions={setSelectedOptionsSubscriptions}
            clearFilter={clearFilter}
            setClearFilter={setClearFilter}
            filterTitle="Subscriptions"
            options={filteredSubscriptions}
            name="subscriptions_dataset"
            isMobile={isMobile}
            dropdownToggleID="filter-subscription"
          />
          <div>
            {selectedOptionsCreatedBy.length +
              selectedOptionsSubscriptions.length >
              0 && (
              <Button
                className="ms-3 button clear-filter-btn"
                onClick={() => handleClearFilterBtnClick()}
                name="clear-filter"
                id="clear-filter"
                data-cy="clear-filter"
              >
                Clear filter
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }, [
    isMobile,
    datasets,
    users,
    subscribedDatasets,
    clearFilter,
    selectedOptionsCreatedBy.length,
    selectedOptionsSubscriptions.length,
  ]);

  useEffect(() => {
    props.setPreload(true);
    dispatch(getAllDatasets())
      .catch((status) => {
        props.setShowToast(true);
        props.setToastStatus(status);
        props.setToastImage(null);
      })
      .finally(() => props.setPreload(false));
  }, []);

  useEffect(() => {
    dispatch(getAllUsers()).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    dispatch(getCurrentUser()).catch((err) => console.log(err));
  }, [refreshSubscribe]);

  useEffect(() => {
    const dataProfileSubscriptions =
      currentUser?.dataset_profile_subscriptions?.toString();
    dispatch(getAllSubscribeDatasetProfile(dataProfileSubscriptions)).catch(
      (err) => console.log(err)
    );
  }, [currentUser]);

  // const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [data, setData] = useState([]); // your data array
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filteredItems, setFilteredItems] = useState([]);

  const handlePageChange = (pageNumber) => {
    props.setDatasetCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (selectedItemsPerPage) => {
    props.setDatasetCurrentPage(1);
    setItemsPerPage(selectedItemsPerPage);
  };

  useEffect(() => {
    // Simulating data fetching
    // Replace this with your actual data fetching logic
    const fetchedData = filteredItems; // Replace this with your fetched data
    setData(fetchedData);
    setTotalItems(fetchedData.length);
  }, [filteredItems, sortDirection]);

  useEffect(() => {
    let slicedData = data;
    if (itemsPerPage !== totalItems) {
      const start = (props.datasetCurrentPage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      slicedData = data.slice(start, end);
    }
    setPaginatedData(slicedData);
  }, [props.datasetCurrentPage, itemsPerPage, data, totalItems]);

  const handleRowClick = (row) => {
    // Handle row click event and navigate to a different page
    navigate("/datasets/profile/" + row.id);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.profile_id === currentDataset[0]?.profile_id,
      style: {
        backgroundColor: "#CFCEE3",
      },
    },
  ];

  const onClickMobileSearchIcon = () => {
    setDisplayMobileSearchList(true);
  };

  const onClickMobileBackIcon = () => {
    setDisplayMobileSearchList(false);
  };

  useEffect(() => {
    if (scrollRef.current) {
      // Target the rdt_Table div and add custom attributes
      const rdtTableDiv = scrollRef.current.querySelector(".rdt_Table");
      if (rdtTableDiv) {
        rdtTableDiv.setAttribute("id", "dataset-table");
        rdtTableDiv.setAttribute("data-cy", "dataset-table");
      }
      // Target the rdt_TableRow divs and add custom attributes
      const rdtTableRows = scrollRef.current.querySelectorAll(".rdt_TableRow");
      rdtTableRows.forEach((row, index) => {
        row.setAttribute("id", `dataset-row-item-${paginatedData[index].id}`);
        row.setAttribute("data-cy", "dataset-row-item");
      });
    }
  }, []);

  return (
    <>
      <div className="mw-100 dataset-list-container">
        <div className="dataset-list-mobile-header">
          {!displayMobileSearchList ? (
            <>
              <MobileLeftSidebar
                setUrlToRedirected={props.setUrlToRedirected}
                setShowDiscardChangesModal={props.setShowDiscardChangesModal}
                changesMade={props.changesMade}
              />
              <Breadcrumb>
                <Breadcrumb.Item active>Datasets</Breadcrumb.Item>
              </Breadcrumb>
              {isMobile && (
                <>
                  <p className="bold dataset-list-title">Dataset Profiles</p>
                  <img
                    src="/images/mobile-search-icon.svg"
                    alt="search"
                    className="mobile-search-list-icon"
                    onClick={onClickMobileSearchIcon}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {isMobile && (
                <div>
                  <img
                    src="/images/mobile-back-icon.svg"
                    alt="back"
                    className="mobile-search-back-icon"
                    onClick={onClickMobileBackIcon}
                  />
                  {subHeaderComponentMemo}
                </div>
              )}
            </>
          )}
        </div>
        <div
          ref={scrollRef}
          className={`data-table-div data-table-scrollable ${
            displayMobileSearchList ? "pt-150-px" : "pt-95-px"
          }`}
        >
          <DataTable
            className="dataset-list-datatable"
            title={"Dataset Profiles"}
            conditionalRowStyles={conditionalRowStyles}
            columns={columns}
            data={paginatedData}
            pagination={false}
            subHeader={!isMobile}
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            onRowClicked={handleRowClick}
            noTableHead={isMobile}
          />
          <div className="mb-4 d-flex justify-content-center">
            {loading && (
              <Spinner
                className="spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </div>
          {!isMobile && (
            <CustomPagination
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={props.datasetCurrentPage}
              onChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DatasetList;
