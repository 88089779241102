import NotificationService from "services/notification.service";
import {
  SET_NOTIF_COUNT_SUCCESS,
  GET_ALL_NOTIFiCATIONS_SUCCESS,
  SET_MESSAGE,
} from "./types";

/**
 * A module that manage messages
 * @module actions/message
 */

/**
 * Set message action
 *
 * @method setNotifCount
 *
 * @param {string} notifCount - An integer for the notification count response
 *
 *
 * @return {Object} {type: string, payload: integer}
 *
 */
export const setNotifCount = (notifCount) => ({
  type: SET_NOTIF_COUNT_SUCCESS,
  payload: notifCount,
});

/**
 * Get all notifications information
 * success and error response action
 *
 *
 * @method getAllNotifications
 *
 * @param {string} id - A string for identifying model
 *
 * @return {Promise}
 *
 */
export const getAllNotifications = (userID) => (dispatch) => {
  return NotificationService.getAllNotifications(userID).then(
    (data) => {
      dispatch({
        type: GET_ALL_NOTIFiCATIONS_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages[0].message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readNotification = (notifID) => (dispatch) => {
  return NotificationService.readNotification(notifID).then(
    (data) => {
      dispatch({
        type: GET_ALL_NOTIFiCATIONS_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages[0].message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
