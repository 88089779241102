import { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner, Button, Container, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { resetPassword } from "actions/auth";
import Home from "components/Home";

/**
 * A module for Set Password Page component
 * @module components/SetPassword
 */

/**
 * Set Password Page component
 * @method SetPassword
 *
 * @return {JSX.Element}
 *
 */
const SetPassword = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const uid = params.uid;
  const token = params.token;

  /**
   * --------------------
   * * Component state
   *  -------------------
   */
  const [successful, setSuccessful] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isValid, setIsValid] = useState({});
  const [error, setError] = useState({});

  /**
   * Validates name and value of the input field
   * @method validate
   *
   * @param {string} name - A string for name of the input field
   * @param {string} value - A string for the value of the input field
   *
   * @return {void}
   */
  const validate = (name, value) => {
    if (!value) {
      setIsValid(Object.assign(isValid, { [name]: false }));
      setError(Object.assign(error, { [name]: "This field is required!" }));
      return;
    }
    setIsValid(Object.assign(isValid, { [name]: false }));
    const regexPattern = /^[a-zA-Z0-9 Ññ~`!@#$%^&*()_\-+={[}\]|\\:;'"<,>.?/]+$/;
    if (regexPattern.test(value) === false) {
      setError(
        Object.assign(error, {
          [name]:
            "Accepts a-z, A-Z, 0-9, Ñ/ñ, space, and special characters like ~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/",
        })
      );
      return;
    }
    const validatePassword =
      /^(?=.*[ Ññ~`!@#$%^&*()_\-+={[}\]|\\:;'"<,>.?/])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9 Ññ~`!@#$%^&*()_\-+={[}\]|\\:;'"<,>.?/]{8,}$/; // Minimum of 8 characters with atleast 1 symbol, 1 uppercase letter and 1 number
    const validatePassword2 =
      /^(?=.*[ Ññ~`!@#$%^&*(\)_\-+={[}\]|\\:;'"<,>.?/])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9 Ññ~`!@#$%^&*()_\-+={[}\]|\\:;'"<,>.?/]{8,64}$/;
    if (validatePassword.test(value) === false) {
      setError(
        Object.assign(error, {
          [name]:
            "Minimum of 8 characters with atleast 1 symbol, 1 uppercase letter and 1 number",
        })
      );
      return;
    }

    if (validatePassword2.test(value) === false) {
      setError(
        Object.assign(error, {
          [name]:
            "Maximum of 64 characters with atleast 1 symbol, 1 uppercase letter and 1 number",
        })
      );
      return;
    }

    delete error[name];
    setIsValid(Object.assign(isValid, { [name]: true }));
  };

  /**
   * Gets the corresponding error message
   * @method getFormErrorMessage
   *
   * @param {string} name - A string for the name of the input field
   *
   * @return {HTMLElement}
   */
  const getFormErrorMessage = (name) => {
    return <div className="invalid-feedback">{error[name]}</div>;
  };

  /**
   * Gets value of the password input field
   * @method onChangePassword
   *
   * @param {event} e - An event object containing information about the action
   */
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    validate("password", password);
  };

  /**
   * Change hidden state of password
   * @method onClickPasswordBtn
   */
  const onClickPasswordBtn = useCallback(() => {
    setPasswordShow(passwordShow ? false : true);
  }, [passwordShow]);

  const handleSetPassword = (e) => {
    e.preventDefault();
    setDisabled(true);
    setLoading(true);
    dispatch(resetPassword(uid, token, password, password))
      .then(() => {
        setSuccessful(true);
      })
      .catch(() => {
        setSuccessful(false);
      })
      .finally(() => {
        setLoading(false);
        setDisabled(false);
      });
  };

  /**
   * Navigates to sign in page
   * @method onClickSignIn
   */
  const onClickSignIn = () => {
    navigate("/signin");
  };

  useEffect(() => {
    if (password !== "" && Object.keys(error).length === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password, error]);

  return (
    <Home>
      <div className="d-flex align-items-center justify-content-around h-75">
        {!successful ? (
          <Container className="text-left mx-5">
            <p className="h3 mx-1 mt-4 bold">Set new password</p>
            <p>
              Please make sure your new password must be different from
              previously used passwords
            </p>
            <div className="form-outline mb-3">
              <label className="form-label" htmlFor="form3Example4">
                New password
              </label>
              <div className="password-container mb-3">
                <img
                  src="/images/password.svg"
                  className="lock"
                  alt="password"
                />
                <Form.Control
                  type={passwordShow ? "text" : "password"}
                  className={`password input-field ${
                    isValid?.password
                      ? "is-valid"
                      : isValid.password !== undefined
                      ? "is-invalid"
                      : ""
                  }`}
                  name="password"
                  id="password"
                  data-cy="password"
                  value={password}
                  onChange={onChangePassword}
                />
                <img
                  id="show-btn"
                  data-cy="show-btn"
                  onClick={onClickPasswordBtn}
                  src={
                    passwordShow ? "/images/eye.svg" : "/images/eye-slash.svg"
                  }
                  className="eye"
                  alt="hide"
                />
                {getFormErrorMessage("password")}
                <span>
                  Minimum of 8 characters with atleast 1 symbol, 1 uppercase
                  letter and 1 number
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-around pt-5">
              <Button
                variant="primary"
                type="submit"
                className="submit-btn button"
                disabled={disabled}
                onClick={handleSetPassword}
                id="update-btn"
                name="update-btn"
                data-cy="update-btn"
              >
                Update Password
                {loading && (
                  <Spinner
                    className="spinner"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </div>
          </Container>
        ) : (
          <Container className="text-left">
            <p className="h3 mx-1 mt-4 bold">Password update successful</p>
            <p>You've successfully updated your password</p>
            <div className="d-flex justify-content-around pt-5">
              <Button
                variant="primary"
                type="submit"
                className="submit-btn button"
                onClick={onClickSignIn}
                name="signin"
                id="signin"
                data-cy="signin"
              >
                Sign In
              </Button>
            </div>
          </Container>
        )}
      </div>
    </Home>
  );
};

export default SetPassword;
