import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getAllUsers, getUser } from "actions/group";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import "styles/ViewDatasetCreatorModal.css";
import "styles/mobile/ViewDatasetCreatorModalMobile.css";

/**
 * A module for the ViewDatasetCreatorModal Component
 * @module components/datasets/ViewDatasetCreatorModal
 */

/**
 * Displaying  modal for viewing the details of the dataset creator
 * @method ViewDatasetCreatorModal
 *
 * @return {JSX.Element}
 *
 */
const ViewDatasetCreatorModal = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const { users, currentUser } = useSelector((state) => state.group);

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [orcidID, setOrcidID] = useState("");
  const [introduction, setIntroduction] = useState("");
  // const [skills, setSkills] = useState([]);
  const [orgAcronym, setOrgAcronym] = useState("");
  const [organization, setOrganization] = useState("");

  useEffect(() => {
    if (props?.datasetCreatedBy) {
      const filteredUsers = Object.values(users).filter(
        (user) => user.email === props?.datasetCreatedBy
      );
      if (filteredUsers[0]?.id) {
        dispatch(getUser(filteredUsers[0]?.id)).catch((error) => {
          console.log(error);
        });
      }
    }
  }, [users, props?.datasetCreatedBy, props.showViewDatasetCreatorModal]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    if (currentUser?.user_data) {
      setFirstName(currentUser?.user_data[0].first_name);
      setLastName(currentUser?.user_data[0].last_name);
      setOrgAcronym(currentUser?.user_data[0].acronym_organization);
      setOrganization(currentUser?.user_data[0].organization);
      setIntroduction(currentUser?.user_data[0].introduction);
      // if (currentUser?.user_data[0].skills_expertise !== "") {
      //   setSkills(currentUser?.user_data[0].skills_expertise.split(", "));
      // }
      setOrcidID(currentUser?.user_data[0].orcid);
    }
  }, [currentUser]);

  useEffect(() => {
    setFirstName("");
    setLastName("");
    setOrgAcronym("");
    setOrganization("");
    setIntroduction("");
    // setSkills([]);
    setOrcidID("");
  }, [props.showViewDatasetCreatorModal]);

  return (
    <>
      <Modal
        show={props.showViewDatasetCreatorModal}
        onHide={props.handleViewDatasetCreatorModalClose}
        dialogClassName="view-dataset-creator-modal modal-45w"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="bold">
            {firstname + " " + lastname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <Form className={isMobile ? "ms-3" : "ms-5"}>
            <p className="mb-0 mt-2">{orgAcronym}</p>
            <p>{organization}</p>
            <p className="mb-0 mt-2 semi-bold">ORCID ID</p>
            <p>
              {orcidID ? (
                <Link
                  to={"https://orcid.org/" + orcidID}
                  className="nav-link"
                  target="_blank"
                >
                  {orcidID}
                </Link>
              ) : (
                "--"
              )}
            </p>

            <div className="view-dataset-creator">
              <p className="mb-0 mt-4 semi-bold">About</p>
              <p>{introduction || "--"}</p>
              <p className="mb-0 mt-2 semi-bold">Groups</p>
              <div className="d-flex view-creator-groups mt-1">
                {currentUser?.groups
                  ? Object.values(currentUser?.groups).map((group, index) => {
                      return (
                        <div className="me-3" key={`groupListKey${index}`}>
                          <span>{group}</span>
                        </div>
                      );
                    })
                  : "--"}
              </div>
            </div>
            <p className="mb-0 mt-2 semi-bold">Contributions</p>
            <div className="d-flex">
              <span className="mb-0 mt-2">
                <span>{currentUser?.model_count || "--"}</span>
                <p>Models</p>
              </span>
              {/* <span className="mb-0 mt-2">
                  <span>--</span>
                  <p>Datasets</p>
                </span> */}
            </div>
            {isMobile && (
              <div className="buttons-div">
                <Button
                  variant="primary"
                  onClick={props.handleViewDatasetCreatorModalClose}
                  className="submit-btn button"
                >
                  Close
                </Button>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewDatasetCreatorModal;
